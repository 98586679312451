<template>
  <div class="row mx-0" v-if="tiene_productos && !es_regalo">
    <div class="col-10 px-1 text-left">
      <div v-if="grupo?.productos_nombres.length <= 2">
        <p
          class="font-10 mb-1 line-limit-1"
          v-for="producto in grupo?.productos_nombres"
          :key="producto"
        >
          <b>
            {{ producto.split("-")[0] }}
          </b>
          - {{ producto.split("-")[1] }}
        </p>
      </div>
      <div v-else>
        <p class="font-10 mb-1">
          <b> {{ grupo?.productos_nombres.length }} Seleccionados </b>
        </p>
      </div>
    </div>
    <div class="col-2 px-1"> <b class="font-11">{{ grupo?.unidades }}</b> </div>
  </div>
  <div
    class="row mx-0"
    v-else-if="es_regalo && grupo.tipo == constantes.TIPO_REGALO_PRODUCTO"
  >
    <div class="col-10 px-1 text-left">
      <div v-if="grupo?.productos_nombres.length <= 1">
        <p
          class="font-10 mb-1 line-limit-1"
          v-for="producto in grupo?.productos_nombres"
          :key="producto"
        >
          <b>
            {{ producto.split("-")[0] }}
          </b>
          - {{ producto.split("-")[1] }}
        </p>
      </div>
      <div v-else>
        <p class="font-10 mb-1">
          <b> Varios </b>
        </p>
      </div>
    </div>
    <div class="col-2 px-1"> <b class="font-11">{{ grupo?.unidades }}</b> </div>
  </div>

  <div
    class="row mx-0"
    v-else-if="es_regalo && grupo.tipo != constantes.TIPO_REGALO_PRODUCTO"
  >
    <div class="col-12 px-1 text-left">
      <div>
        <p class="font-10 mb-1 line-limit-1" >
          <b>Bonificación </b>
          <span v-if="grupo.tipo == constantes.TIPO_REGALO_SOLES">
            {{simbolo}}{{ grupo.valor }}
          </span>
          <span v-else>
            {{ grupo.valor }}{{simbolo}}
          </span>
        </p>
      </div>
    </div>
  </div>

  <div class="row mx-0" v-else>
    <div class="col-10 px-1 text-left">
      <div>
        <p class="font-10 mb-1 line-limit-1">
          <b> Combinación </b>
        </p>
      </div>
    </div>
    <div class="col-2 px-1">-</div>
  </div>
</template>

<script setup>
import constantes from "../../../model/constantes";
import { computed } from "vue";

const { grupo } = defineProps({
  grupo: {
    type: Object,
  },
  es_regalo: {
    type: Boolean,
  },
});

const tiene_productos = computed(() => {
  return grupo?.productos_nombres.length > 0;
});

const simbolo = computed(() => {
  return grupo.tipo == constantes.TIPO_REGALO_SOLES ? "S/." : "%";
});
</script>

<style lang="scss" scoped></style>
