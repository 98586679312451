<template>
  <div class="th-select" :class="[customClass ? customClass : 'default-select', className]" v-click-outside="close">
    <button class="th-select-option" aria-expanded="true" aria-haspopup="listbox" @click="toggleShow"
      :disabled="disabled">
      <span>
        {{ selectedOption }}
      </span>
      <i class="laive-i icon-arrow-down" :class="{ open: show }"></i>
    </button>
    <ul v-if="show && options.length > 0" class="th-select-listbox scroller" role="listbox" aria-orientation="vertical"
      tabindex="0">
      <li v-for="option in options" @click.stop="handleSelect(option.value)">
        {{ option.text }}
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  props: {
    customClass: [String, Object],
    className: [String, Object],
    isOpen: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: []
    },
    automatic: {
      type: Boolean,
      default: true
    },
    selected: [String, Number]
  },
  emits: ["update:selected", "update:isOpen"],
  data() {
    return {
      internalOpen: this.isOpen
    }
  },
  computed: {
    show() {
      return this.automatic ? this.internalOpen : this.isOpen
    },
    selectedOption() {
      return this.options.find((opt) => opt.value == this.selected)?.text ?? ""
    }
  },
  methods: {
    handleSelect(value) {
      this.$emit("update:selected", value);
      this.close();
    },
    close() {
      if (this.automatic) {
        this.internalOpen = false;
      } else {
        this.$emit("update:isOpen", false);
      }
    },
    toggleShow() {
      if (this.disabled) return;
      if (this.automatic) {
        this.internalOpen = !this.internalOpen;
      } else {
        this.$emit("update:isOpen", !this.show);
      }
    }
  }
}
</script>

<style scoped>
i {
  transition: transform 0.750ms ease;
}

i.open {
  transform: rotate(180deg);
}

/* Default Select   */
.default-select>.th-select-option:disabled {
  background-color: #E3E3E3;
}

.default-select>.th-select-option {
  border: 1px solid #E3E3E3;
  border-radius: 4px;
  padding-inline: 0.7em;
  padding-block: 0.3em;
}

.default-select>.th-select-listbox {
  border: 1px solid #E3E3E3;
  border-radius: 0.25em;
  box-shadow: 2px 5px 4px 0px rgba(0, 0, 0, 0.1);
}

.default-select>.th-select-listbox li {
  padding-inline: 1em;
  padding-block: 0.6em;
  cursor: pointer;
}

.default-select>.th-select-listbox li:not(:last-of-type) {
  border-bottom: 1px solid #E3E3E3;
}

.default-select>.th-select-listbox li:hover {
  background-color: #F1F1F1;
}
</style>
