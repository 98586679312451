<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block only_hidden_after">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive validateClient">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template v-slot:header>
            <div class="row">
              <div class="col-12 col-md-6">
                <h3 class="mb-0">Listado de Versiones del APP</h3>
              </div>
            </div>
          </template>
          <div>
            <div class="row px-3">
              <div class="col-12 col-md-8 mb-3">
                <div class="row">
                  <div class="col-12 col-md-6 d-none">
                    <el-input
                      type="search"
                      clearable
                      prefix-icon="el-icon-search"
                      placeholder="Buscar Supervisor"
                      v-model="searchQuery"
                      aria-controls="datatables"
                    >
                    </el-input>
                  </div>
                  <div class="col-12 col-md-4">
                    <span class="title_filter mr-2">Tipo de App:  </span>
                    <select class="form-control" style="display: inline-block;height: 43px;" name="typeapp_select" @change="changeTypes">
                      <option value="" selected disabled>Seleccionar</option>
                      <option
                        v-for="item in typesApp"
                        :value="item.codigoDato"
                        :key="item.idRegistro"
                        >
                        {{ item.nombre }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <!-- Real Table -->
            <el-table
              :data="queriedData"
              row-key="versionapp"
              :empty-text="emptyText"
              class=""
              header-row-class-name="thead-light"
            >
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                v-bind="column"
              >
              </el-table-column>
              <el-table-column label="Act. Forzada" min-width="130" align="left">
                <template v-slot:default="props">
                  <template v-if="props.row.forceUpdate == true">
                    Sí
                  </template>
                  <template v-else>
                    No
                  </template>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <template v-slot:footer>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category" style="display:none">
                  Mostrando {{ from + 1 }} a {{ to }} de {{ total }} Supervisores
                </p>
              </div>
              <default-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              >
              </default-pagination>
            </div>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
let fixClickOpenModal = 1
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import axios from "axios";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BasePagination from "@/components/BasePagination";
import swal from "sweetalert2";
import {mapGetters} from 'vuex';
import DashboardNavbar from "../Layout/DashboardNavbar";
export default {
  name: "ListSupervisores",
  components: {
    DashboardNavbar,
    BasePagination,
    RouteBreadCrumb,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  computed: {
    pagedData() {
      this.getTypeApp
      if(this.tableData.length){
        return this.tableData;
      }
    },
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString().toLowerCase();
          if (rowValue.includes && rowValue.includes(this.searchQuery.toLowerCase())) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    }),
    getTypeApp(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let urlApiTypesVersion = process.env.VUE_APP_API_URL+"/maestro/lista/tipoaplicacion"
      const requestTypesVersion = axios.get(urlApiTypesVersion, config);
      axios.all([requestTypesVersion]).then(axios.spread((...responses) => {
        const data = responses[0].data
        $this.typesApp = data
        console.log({data})
        document.querySelector(".form_laive").classList.remove("validateClient")
      })).catch(errors => {
        // react on errors.
        document.querySelector(".form_laive").classList.remove("validateClient")
      })
    },
  },
  methods:{
    changeTypes(evn){
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let selected = evn.target.value
      const parameters = {
        "codigoTipo": selected,
      }
      const apiGetVersions = process.env.VUE_APP_API_URL+`/appversion/listar`
      document.querySelector(".form_laive").classList.add("validateClient")
      axios.post(apiGetVersions,parameters,config)
      .then(function (response){
        const data = response.data
        $this.tableData = data
        document.querySelector(".form_laive").classList.remove("validateClient")
        console.log({data})
      }).catch(errors => {
        // react on errors.
        $this.emptyText = "No se encontraron resultados"
        document.querySelector(".form_laive").classList.remove("validateClient")
      })
    }
  },
  mounted() {
    if(this.user.rol == 3){
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
  },
  data() {
    return {
      initload: true,
      distribuidoraIdCurrent: null,
      distribuidoraNameCurrent: null,
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: [],
      tableColumns: [
        {
          prop: "tipo",
          label: "Tipo",
          minWidth: 150,
        },
        {
          prop: "nombre",
          label: "Nombre",
          minWidth: 200,
        },
        {
          prop: "version",
          label: "Versión",
          minWidth: 100,
        },
        {
          prop: "fechaDespliegue",
          label: "Fecha Despliegue",
          minWidth: 160,
        },
      ],
      tableData: [],
      emptyText: "Debe Seleccionar un Tipo de app",
      fuseSearch: null,
      searchedData: [],
      expands: [],
      typesApp:[]
    };
  },
};
</script>
