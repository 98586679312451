<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive validateClient">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1 pt-0 mt-0"
          footer-classes="pb-2"
        >
          <template v-slot:header>
            <h3 class="mb-0">Creación de Usuario</h3>
          </template>
          <div class="card-body">
            <form @submit.prevent="submitCreate" autocomplete="off">
              <div class="form-group row mb-0">
                <div class="col-md-6">
                  <label class="col-form-label form-control-label">Roles:</label>
                  <select class="form-control" style="height: 43px;" name="roles_select" required @change="changeRol">
                    <option value="" disabled selected style="display:none">Seleccionar un Rol</option>
                    <option
                        v-for="item in roles"
                        :value="item.codigoDato"
                        :key="item.codigoDato"
                        >
                        {{ item.nombre }}
                      </option>
                  </select>
                </div>
                <template v-if="user.codigoRol == 3 || user.codigoRol == 4">
                  <div class="col-md-6" >
                    <label class="col-form-label form-control-label">Distribuidoras:</label>
                    <select class="form-control" style="height: 43px;" name="distribuidoras_select" required @change="changeDistributor">
                      <option value="" disabled selected style="display:none">Seleccionar una Distribuidora</option>
                      <option
                          v-for="item in distribuidoras"
                          :value="item.idDistribuidora"
                          :key="item.idDistribuidora"                          >
                          {{ item.razonSocial }}
                        </option>
                    </select>
                  </div>
                </template>
                <template v-if="user.codigoRol == 4">
                  <div class="col-md-6" >
                    <label class="col-form-label form-control-label">Supervisor:</label>
                    <select class="form-control" style="height: 43px;" name="supervisor_select" required @change="changeSupervisor">
                      <option value="" disabled selected style="display:none">Seleccionar un Supervisor</option>
                      <option
                        v-for="item in supervisores"
                        :value="item.codigoSupervisor"
                        :key="item.codigoSupervisor"
                        >
                        {{ item.nombre }}
                      </option>
                    </select>
                  </div>
                </template>
                <div class="col-md-6">
                  <label class="col-form-label form-control-label">Nombres y Apellidos:</label>
                  <base-input
                    name="user_name"
                    placeholder="Nombres y Apellidos"
                    required="required"
                    minlength="8"
                    :value="user.nombre"
                    v-model="user.nombre"
                    :disabled="user.codigoRol == 4 ? true : false"
                    @keyup="generateUsername"
                  ></base-input>
                </div>
                <div class="col-md-6">
                  <label class="col-form-label form-control-label">Username:</label>
                  <base-input
                    name="user_username"
                    placeholder="Username"
                    required="required"
                    autocomplete="off"
                    :value="user.userName"
                    v-model="user.userName"
                    :disabled="user.codigoRol == 4 ? true : false"
                  ></base-input>
                </div>
                <div class="col-md-6">
                  <label class="col-form-label form-control-label">Contraseña:</label>
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                          <span class="input-group-text"><i class="ni ni-lock-circle-open"></i></span>
                      </div>
                      <input class="form-control" v-model="user.pass" name="user_pass" id="user_pass" minlength="6" maxlength="" type="password" placeholder="Contraseña" required autocomplete="new-password">
                      <svg @click="showPassword" class="show_pass" width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.16917 0.589166L1.75833 0L15.7167 13.9583L15.1275 14.5475L12.3375 11.7575C11.1983 12.2358 9.94583 12.5 8.63333 12.5C6.83049 12.5006 5.06404 11.9927 3.5369 11.0345C2.00975 10.0763 0.783844 8.70686 0 7.08333C0.804692 5.4224 2.06995 4.02784 3.645 3.06583L1.16917 0.589166ZM8.63333 11.6667C9.71083 11.6667 10.7425 11.4717 11.6958 11.1158L10.755 10.1758C10.033 10.6711 9.16054 10.8982 8.28874 10.8177C7.41693 10.7371 6.60078 10.3541 5.98174 9.73499C5.36271 9.11586 4.97982 8.29964 4.89944 7.42783C4.81907 6.55601 5.04627 5.68354 5.54167 4.96167L4.25333 3.67333C2.85543 4.48363 1.70787 5.66349 0.936667 7.08333C1.68741 8.47004 2.799 9.62805 4.15386 10.4349C5.50871 11.2417 7.05645 11.6673 8.63333 11.6667ZM16.3292 7.08333C15.5785 5.69675 14.467 4.53882 13.1123 3.73202C11.7576 2.92521 10.2101 2.49953 8.63333 2.5C7.69755 2.49886 6.76766 2.64798 5.87917 2.94167L5.22667 2.29C6.31481 1.87659 7.4693 1.66535 8.63333 1.66667C10.4362 1.66606 12.2026 2.17401 13.7298 3.13217C15.2569 4.09032 16.4828 5.45981 17.2667 7.08333C16.5068 8.65215 15.3351 9.98514 13.8767 10.94L13.2733 10.3367C14.5548 9.53309 15.6073 8.41255 16.3292 7.08333ZM8.63333 3.33333C9.29086 3.33317 9.93687 3.50589 10.5066 3.83418C11.0763 4.16248 11.5497 4.63479 11.8792 5.20376C12.2088 5.77274 12.383 6.41836 12.3843 7.07589C12.3856 7.73341 12.214 8.37972 11.8867 8.95L11.27 8.33333C11.5287 7.78938 11.6127 7.17866 11.5103 6.58507C11.408 5.99148 11.1244 5.44413 10.6985 5.0182C10.2725 4.59228 9.72519 4.30867 9.1316 4.20634C8.53801 4.104 7.92729 4.18795 7.38333 4.44667L6.76667 3.83C7.33454 3.50356 7.97831 3.33227 8.63333 3.33333ZM5.71667 7.08333C5.71654 7.60146 5.85443 8.11026 6.11617 8.55742C6.3779 9.00458 6.75402 9.37394 7.20585 9.62752C7.65768 9.8811 8.16891 10.0097 8.68694 10.0002C9.20498 9.9907 9.71113 9.84335 10.1533 9.57333L6.14333 5.56333C5.86344 6.02086 5.71576 6.54698 5.71667 7.08333Z" fill="#6E6E6E" />
                      </svg>
                    </div>
                    <div class="text-danger invalid-feedback mt-2" style="display: none;"></div>
                  </div>
                </div>

                <div class="col-md-6">
                  <label class="col-form-label form-control-label">Email:</label>
                  <base-input
                    name="user_email"
                    placeholder="Email"
                    autocomplete="off"
                    :value="user.email"
                    v-model="user.email"
                  ></base-input>
                </div>

              </div>
              <base-button type="primary" native-type="submit" class="btn-default my-0">Crear Usuario</base-button>
            </form>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
import DashboardNavbar from "../Layout/DashboardNavbar";
export default {
  components: {
    DashboardNavbar,
    RouteBreadCrumb,
  },
  created(){
    const $this = this
    const token = localStorage.getItem("token")
    const config = {
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    };
    let urlApiDistribuidoras = process.env.VUE_APP_API_URL+"/distribuidoras/lista"
    let urlApiRoles = process.env.VUE_APP_API_URL+"/maestro/lista/rolusuario"
    const requesDistribuidora = axios.get(urlApiDistribuidoras, config);
    const requestRoles = axios.get(urlApiRoles, config);
    axios.all([requesDistribuidora, requestRoles]).then(axios.spread((...responses) => {
      const responseDistribuidoras = responses[0].data
      const responseRoles = responses[1].data
      $this.roles = responseRoles.filter((item)=>{return item.nombre !== "Supervisor"})
      $this.distribuidoras = responseDistribuidoras.filter((item)=>{return item.estado == "1"})
      document.querySelector(".form_laive").classList.remove("validateClient")
    })).catch(errors => {
      // react on errors.
    })
  },
  methods: {
    generateUsername(evn){
      const $this = this
      let timer;
      clearTimeout(timer);
      timer = setTimeout(function(){
          let getusername = document.querySelector("#user_name").value.toLowerCase().replace(/\s/g, '.');
          $this.user.userName = getusername
      },1000); //onkeyup event works with a 10000 millisecond delay
    },
    changeRol(evn){
      const select = evn.target
      const value = select.value;
      this.user.codigoRol = value
      if(value == 4){
        this.user.nombre = ""
        this.user.userName = ""
      }
    },
    changeDistributor(evn){
      const $this = this
      const select = evn.target
      const value = select.value;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      this.user.idDistribuidora = Number(value)
      const urlApiSupervisores = process.env.VUE_APP_API_URL+`/supervisor/lista/${value}`
      document.querySelector(".form_laive").classList.add("validateClient")
      axios.get(urlApiSupervisores,config)
      .then(function (response){
        let dataSupervisores= response.data
        document.querySelector(".form_laive").classList.remove("validateClient")
        return $this.supervisores = dataSupervisores
      })
    },
    changeSupervisor(evn){
      const $this = this
      const select = evn.target
      const textSelected = select.options[select.selectedIndex].text
      const codeSupervisor = select.value;
      this.user.nombre = textSelected
      this.user.userName = codeSupervisor
    },
    submitCreate(values) {
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let createUser = process.env.VUE_APP_API_URL+"/usuario/crear"
      document.querySelector(".form_laive").classList.add("validateClient")
      const rolesSelect = document.querySelector("select[name='roles_select']")
      const disTribuidorasSelect = document.querySelector("select[name='distribuidoras_select']")
      const supervisorSelect = document.querySelector("select[name='supervisor_select']")
      axios.post(createUser, this.user,config)
      .then(function (response){
        document.querySelector(".form_laive").classList.remove("validateClient")
        const text = (response.status == 200) ? "Se creó el usuario" : "Hubo un error";
        const icon = "ni ni-bell-55";
        const content = {
          component: Notification,
          props: {
            icon: icon,
            text: text,
            type: (response.status == 200) ? "success" : "danger",
          },
        };
        const toast = useToast();
        toast(content, {
          hideProgressBar: true,
          icon: false,
          closeButton: false,
          position: "top-right",
        });
        if(rolesSelect) rolesSelect.value = ""
        if(disTribuidorasSelect) disTribuidorasSelect.value = ""
        if(supervisorSelect) supervisorSelect.value = ""
        $this.user.nombre = ""
        $this.user.userName = ""
        $this.user.pass = ""
        $this.user.codigoRol = ""
        $this.user.idDistribuidora = 0
        $this.user.email = ""
        // setTimeout(() => {
        //   $this.$router.replace({name: 'Listado de Usuarios'})
        // }, 2000);
      })
    },
    showPassword(){
      const inputPass = document.querySelector("#user_pass")
      let getAttr = inputPass.getAttribute("type")
      if(getAttr == 'password'){
        inputPass.setAttribute("type", "text")
        document.querySelector(".show_pass").classList.add("active")
      }else{
        inputPass.setAttribute("type", "password")
        document.querySelector(".show_pass").classList.remove("active")
      }
    }
  },
  data() {
    return {
      distribuidoras: [],
      supervisores: [],
      roles:[],
      user: {
        nombre: "",
        userName: "",
        pass: "",
        codigoRol: "",
        idDistribuidora: 0,
        estado: "1"
      },
    };
  },
}
</script>
<style lang="scss">
  .dropzone{
    width:200px;
  }
  .dz-message{
    padding: 1rem 1rem !important;
  }
  .dropzone-single.dz-max-files-reached .dz-message{
    background-color: rgba(219, 219, 219, 0.349) !important;
    border: 1px dashed #dee2e6 !important;
    opacity: 1 !important;
  }
  #product_prewview{
    .dz-preview-cover{
      position: relative;
      width:200px;
      padding-bottom: 0.5rem;
    }
  }
</style>
