<template>
  <component :is="as" class="ui-grid" :style="[cssProps]">
    <slot></slot>
  </component>
</template>

<script>
import { PropsHandler } from '../utils';
export default {
  props: {
    as: {
      type: String,
      default: "div",
      valiadtor(typ) {
        return ["div", "ul"].includes(typ);
      }
    },
    columnGap: String,
    rowGap: String,
    columns: [String, Number, Array],
    rows: [String, Number, Array],
    gap: {
      type: String,
      default: "0"
    },
  },
  computed: {
    cssProps() {
      let cols = "initial";
      let rows = "initial";
      if (this.columns) {
        if (Array.isArray(this.columns)) {
          cols = this.columns.join(",");
        } else if (typeof this.columns == "number") {
          cols = `repeat(${this.columns},1fr)`
        } else if (typeof this.columns == "string") {
          cols = this.columns
        }
      }
      if (this.rows) {
        if (Array.isArray(this.rows)) {
          rows = this.rows.join(",");
        } else if (typeof this.rows == "number") {
          rows = `repeat(${this.rows},1fr)`
        } else if (typeof this.rows == "string") {
          rows = this.rows
        }
      }
      return {
        ["--ui-grid-cols"]: cols,
        ["--ui-grid-rows"]: rows,
        ["--ui-gap-col"]: PropsHandler.spacing(this.columnGap ?? this.gap),
        ["--ui-gap-row"]: PropsHandler.spacing(this.rowGap ?? this.gap),
      }
    }
  }
}

</script>


<style scoped>
.ui-grid {
  display: grid;
  grid-template-columns: var(--ui-grid-cols);
  grid-template-rows: var(--ui-grid-rows);
  row-gap: var(--ui-gap-row);
  column-gap: var(--ui-gap-col);
}
</style>
