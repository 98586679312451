<template>
  <div class="ui-accordion" :class="[{ expanded: show, joined: joined }]" :style="[cssProps]">
    <button :disabled="disabled" @click="toggleShow">
      <slot name="header">
        {{ header }}
        <i class="laive-i icon-arrow-down"></i>
      </slot>
    </button>
    <div v-if="show">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { PropsHandler, ThemeProps } from "./utils";
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    header: String,
    open: {
      type: Boolean,
      default: false
    },
    joined: {
      type: Boolean,
      default: false
    },
    automatic: {
      type: Boolean,
      default: false
    },
    btnColor: {
      type: String,
      default: "unset"
    },
    contentColor: {
      type: String,
      default: "unset"
    },
    maxH: {
      type: String,
      default: "10em",
    },
    ...ThemeProps.containerProps
  },
  emits: ["update:open"],
  data() {
    return {
      internalOpen: this.isOpen
    }
  },
  computed: {
    cssProps() {
      return {
        ...ThemeProps.styleContainer(this.$props),
        "--ui-accordion-max-h": PropsHandler.size(this.maxH),
        "--ui-accordion-color-cont": PropsHandler.color(this.contentColor),
        "--ui-accordion-color-btn": PropsHandler.color(this.btnColor),
      }
    },
    show() {
      return this.automatic ? this.internalOpen : this.open
    }
  },
  methods: {
    toggleShow() {
      if (this.automatic) this.internalOpen = !this.internalOpen
      else this.$emit("update:open", false)
    }
  }
}
</script>


<style scoped>
button,
button~div {
  width: 100%;
  padding-inline: var(--ui-px);
  padding-block: var(--ui-py);
}

.ui-accordion {
  border-radius: 8px;
  overflow: hidden
}

.ui-accordion>div {
  max-height: var(--ui-accordion-max-h, inherit);
  background-color: var(--ui-accordion-color-cont);
}

.ui-accordion>button {
  background-color: var(--ui-accordion-color-btn);
  border: 0;
  outline: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.7em;
}

.ui-accordion>button i {
  transition: transform 300ms ease;
}

.ui-accordion.expanded>button i {
  transform: rotate(180deg);
}

.ui-accordion>button {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.ui-accordion.joined>button {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
</style>
