<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <!-- HTML5 inputs -->
            <div class="card">
              <!-- Card header -->
              <div class="card-header">
                <h3 class="mb-0">Estos campos se reflejarán en la aplicación</h3>
              </div>
              <!-- Card body -->
              <div class="row">
                <!-- Productos Búsqueda Predictiva -->
                <div class="col-md-6">
                  <div class="card-body form_laive validateClient">
                    <div class="box_loader">
                      <div class="loader"></div>
                    </div>
                    <form @submit.prevent="submitMinCharacter">
                      <div class="form-group row">
                        <label class="col-md-12 col-form-label form-control-label"
                          >Min. de caracteres en Búsqueda predictiva</label
                        >
                        <div class="col-md-12">
                          <base-input
                            required
                            name="minimo_caracteres_busqueda"
                            placeholder="Indicar el mínimo de caracteres"
                            v-model="minimo_caracteres_busqueda.valor"
                            @keypress="isNumber($event)"
                          ></base-input>
                          <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar búsqueda  predictiva</base-button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <!-- Productos Resultados de Búsqueda -->
                <div class="col-md-6">
                  <div class="card-body form_laive validateClient">
                    <div class="box_loader">
                      <div class="loader"></div>
                    </div>
                    <form @submit.prevent="submitMaxProducts">
                      <div class="form-group row">
                        <label class="col-md-12 col-form-label form-control-label"
                          >Max. de productos en el resultado de Búsqueda</label
                        >
                        <div class="col-md-12">
                          <base-input
                            required
                            name="maxproducts"
                            placeholder="Indicar el mínimo de caracteres"
                            v-model="maximo_productos_busqueda.valor"
                            @keypress="isNumber($event)"
                          ></base-input>
                          <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar resultado de búsqueda</base-button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                <!-- Productos Promocion -->
                <div class="col-md-6">
                  <div class="card-body form_laive validateClient">
                    <div class="box_loader">
                      <div class="loader"></div>
                    </div>
                    <form @submit.prevent="submitMaxProductsPromo">
                      <div class="form-group row">
                        <label class="col-md-12 col-form-label form-control-label">Max. de Productos en Promociones</label>
                        <div class="col-md-12">
                          <base-input
                            required
                            name="maxproducts"
                            placeholder="Indicar el max. de productos en promociones"
                            v-model="maximo_productos_promociones.valor"
                            @keypress="isNumber($event)"
                          ></base-input>
                          <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar Productos Promociones</base-button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <!-- Productos Cluster -->
                <div class="col-md-6">
                  <div class="card-body form_laive validateClient">
                    <div class="box_loader">
                      <div class="loader"></div>
                    </div>
                    <form @submit.prevent="submitMaxProductsCluster">
                      <div class="form-group row">
                        <label class="col-md-12 col-form-label form-control-label">Max. de Productos Cluster</label>
                        <div class="col-md-12">
                          <base-input
                            required
                            name="maxproducts"
                            placeholder="Indicar el max. de productos cluster"
                            v-model="maximo_productos_clusters.valor"
                            @keypress="isNumber($event)"
                          ></base-input>
                          <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar Productos Cluster</base-button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <!-- Porcentaje Pareto Cluster -->
                <div class="col-md-6">
                  <div class="card-body form_laive validateClient">
                    <div class="box_loader">
                      <div class="loader"></div>
                    </div>
                    <form @submit.prevent="submitParetoCluster">
                      <div class="form-group row">
                        <label class="col-md-12 col-form-label form-control-label">Porcentaje de Pareto  Prod. Cluster</label>
                        <div class="col-md-12">
                          <base-input
                            required
                            name="pareto_products_cluster"
                            placeholder="Porcentaje de Pareto Cluster"
                            v-model="porc_pareto_producto_cluster.valor"
                            @keypress="isNumber($event)"
                          >
                            <template v-slot:addonLeft>%</template>
                          </base-input>
                          <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar Pareto de Prod. Cluster</base-button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <!-- Historial de Productos Cluaster -->
                <div class="col-md-6">
                  <div class="card-body form_laive validateClient">
                    <div class="box_loader">
                      <div class="loader"></div>
                    </div>
                    <form @submit.prevent="submitHistorialCluster">
                      <div class="form-group row">
                        <label class="col-md-12 col-form-label form-control-label">Historial de Productos Cluaster</label>
                        <div class="col-md-12">
                          <base-input
                            required
                            name="pareto_products_cluster"
                            placeholder="Porcentaje de Pareto Cluster"
                            v-model="dias_historial_producto_cluster.valor"
                            @keypress="isNumber($event)"
                          >
                            <template v-slot:addonLeft>Días</template>
                          </base-input>
                          <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar Historial Prod. Cluster</base-button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                 <!-- Productos Especiales -->
                <div class="col-md-6">
                  <div class="card-body form_laive validateClient">
                    <div class="box_loader">
                      <div class="loader"></div>
                    </div>
                    <form @submit.prevent="submitHistorialCluster">
                      <div class="form-group row">
                        <label class="col-md-12 col-form-label form-control-label">Max. de Productos Especiales</label>
                        <div class="col-md-12">
                          <base-input
                            required
                            name="maxproducts"
                            placeholder="Indicar el max. de productos especiales"
                            v-model="maximo_productos_especiales.valor"
                            @keypress="isNumber($event)"
                          ></base-input>
                          <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar Max. Productos</base-button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <!-- Frecuencia Productos Especiales -->
                <div class="col-md-6">
                  <div class="card-body form_laive validateClient">
                    <div class="box_loader">
                      <div class="loader"></div>
                    </div>
                    <form @submit.prevent="submitFrecuencia">
                      <div class="form-group row">
                        <label class="col-md-12 col-form-label form-control-label">Frecuencia Productos Especiales</label>
                        <div class="col-md-12">
                          <base-input
                            required
                            name="pareto_products_cluster"
                            placeholder="Porcentaje de Pareto Cluster"
                            v-model="frecuencia_productos_especiales.valor"
                            @keypress="isNumber($event)"
                          >
                            <template v-slot:addonLeft>Sem.</template>
                          </base-input>
                          <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar Frecuencia</base-button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <!-- Productos Frecuentes -->
                <div class="col-md-6">
                  <div class="card-body form_laive validateClient">
                    <div class="box_loader">
                      <div class="loader"></div>
                    </div>
                    <form @submit.prevent="submitMaxProductsFrecuentes">
                      <div class="form-group row">
                        <label class="col-md-12 col-form-label form-control-label">Max. de Productos Frecuentes</label>
                        <p class="text-muted col-md-12" style="font-size:12px">Configura el número de semanas para el cálculo de los productos frecuentes.</p>
                        <div class="col-md-12">
                          <base-input
                            required
                            name="maxproducts_freq"
                            placeholder="Indicar el max. de productos frecuentes"
                            v-model="maximo_productos_frecuentes.valor"
                            @keypress="isNumber($event)"
                          >
                            <template v-slot:addonLeft>Sem.</template>
                          </base-input>
                          <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar Prod. Frecuentes</base-button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <!-- Max Productos Foco -->
                <div class="col-md-6">
                  <div class="card-body form_laive validateClient">
                    <div class="box_loader">
                      <div class="loader"></div>
                    </div>
                    <form @submit.prevent="submitMaxProductsFoco">
                      <div class="form-group row">
                        <label class="col-md-12 col-form-label form-control-label">Max. de Productos Foco</label>
                        <p class="text-muted col-md-12" style="font-size:12px">Configura la cantidad de productos foco a mostrar.</p>
                        <div class="col-md-12">
                          <base-input
                            required
                            name="maxproducts_foco"
                            placeholder="Indicar el max. de productos foco"
                            v-model="maximo_productos_focos.valor"
                            @keypress="isNumber($event)"
                          >
                          </base-input>
                          <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar Max. Productos</base-button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <!-- Frecuencia productos foco -->
                <div class="col-md-6">
                  <div class="card-body form_laive validateClient">
                    <div class="box_loader">
                      <div class="loader"></div>
                    </div>
                    <form @submit.prevent="submitFreqProductsFoco">
                      <div class="form-group row">
                        <label class="col-md-12 col-form-label form-control-label">Frecuencia de Productos Foco</label>
                        <p class="text-muted col-md-12" style="font-size:12px">Configura la frecuencia de venta (una vez colocado el producto, no se vuelve a mostrar dentro de X semanas)</p>
                        <div class="col-md-12">
                          <base-input
                            required
                            name="maxproducts_freq_foco"
                            placeholder="Indicar el max. de productos foco"
                            v-model="frecuencia_productos_focos.valor"
                            @keypress="isNumber($event)"
                          >
                            <template v-slot:addonLeft>Sem.</template>
                          </base-input>
                          <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar Frecuencia</base-button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

              </div>
              <div class="card-body">
                <el-form :model="model" :rules="rules" @submit.prevent="submitOrdenProduct('model')"  ref="model" prop="name">
                  <div class="form-group row">
                    <label
                      for="example-search-input"
                      class="col-md-12 col-form-label form-control-label"
                      >Orden de las categorías de productos</label
                    >
                    <div class="col-md-12">
                      <el-form-item prop="select_cat">
                        <el-select
                          multiple
                          v-model="model.select_cat"
                          placeholder="Seleccione el orden">
                          <el-option v-for="option in model.categories"
                                  :value="option.value"
                                  :label="option.label"
                                  :key="option.label">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                  <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar orden de Productos</base-button>
                </el-form>
              </div>
              <div class="card-body">
                <el-form :model="model" :rules="rules" @submit.prevent="submitOrdenFilter('model')"  ref="model" prop="name">
                  <div class="form-group row">
                    <label
                      for="example-search-input"
                      class="col-md-12 col-form-label form-control-label"
                      >Orden para los filtros del catálogo de productos</label
                    >
                    <div class="col-md-12">
                      <el-form-item prop="select_filter">
                        FILTER
                        <el-select multiple
                                  name="ordenfilter"
                                  v-model="model.select_filter"
                                  placeholder="Seleccione el orden">
                          <el-option v-for="option in model.filters"
                                    :value="option.value"
                                    :label="option.label"
                                    :key="option.label">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                  <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar orden de filtros</base-button>
                </el-form>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from "axios";
  import { useToast } from "vue-toastification";
  import Notification from "@/components/Notification";
  import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
  import { ElSelect, ElOption, ElForm, ElFormItem} from "element-plus";
  import DashboardNavbar from "../Layout/DashboardNavbar";
  export default {
    components: {
      DashboardNavbar,
      RouteBreadCrumb,
      [ElSelect.name]: ElSelect,
      [ElOption.name]: ElOption,
      [ElForm.name]: ElForm,
      [ElFormItem.name]: ElFormItem,
    },
    created(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const urlApiParameters = process.env.VUE_APP_API_URL+"/parametro/lista"
      axios.post(urlApiParameters,"",config)
      .then(function (response){
        const data = response.data;
        // Min. de caracteres
        let minimo_caracteres_busqueda = data.filter((item)=>{return item.codigoParametro == "minimo_caracteres_busqueda"})
        $this.minimo_caracteres_busqueda.idParametro = minimo_caracteres_busqueda[0].idParametro
        $this.minimo_caracteres_busqueda.codigoParametro = minimo_caracteres_busqueda[0].codigoParametro
        $this.minimo_caracteres_busqueda.valor = minimo_caracteres_busqueda[0].valor
        // Max. de productos en el resultado de Búsqueda
        let maximo_productos_busqueda = data.filter((item)=>{return item.codigoParametro == "maximo_productos_busqueda"})
        $this.maximo_productos_busqueda.idParametro = maximo_productos_busqueda[0].idParametro
        $this.maximo_productos_busqueda.codigoParametro = maximo_productos_busqueda[0].codigoParametro
        $this.maximo_productos_busqueda.valor = maximo_productos_busqueda[0].valor
        // Max. de Productos Foco
        let maximo_productos_focos = data.filter((item)=>{return item.codigoParametro == "maximo_productos_focos"})
        $this.maximo_productos_focos = maximo_productos_focos[0]
        // Max. de Productos Cluster
        let maximo_productos_clusters = data.filter((item)=>{return item.codigoParametro == "maximo_productos_clusters"})
        $this.maximo_productos_clusters = maximo_productos_clusters[0]
        // Max. de Productos Promocion
        let maximo_productos_promociones = data.filter((item)=>{return item.codigoParametro == "maximo_productos_promociones"})
        $this.maximo_productos_promociones = maximo_productos_promociones[0]

        // Pareto Productos clusters
        let porc_pareto_producto_cluster = data.filter((item)=>{return item.codigoParametro == "porc_pareto_producto_cluster"})
        $this.porc_pareto_producto_cluster = porc_pareto_producto_cluster[0]

        // Historial Productos clusters
        let dias_historial_producto_cluster = data.filter((item)=>{return item.codigoParametro == "dias_historial_producto_cluster"})
        console.log({dias_historial_producto_cluster})
        $this.dias_historial_producto_cluster = dias_historial_producto_cluster[0]

        // Maximo productos especiales
        let maximo_productos_especiales = data.filter((item)=>{return item.codigoParametro == "maximo_productos_especiales"})
        $this.maximo_productos_especiales = maximo_productos_especiales[0]

        // Maximo productos especiales
        let frecuencia_productos_especiales = data.filter((item)=>{return item.codigoParametro == "frecuencia_productos_especiales"})
        $this.frecuencia_productos_especiales = frecuencia_productos_especiales[0]

        // Maximo productos especiales
        let maximo_productos_frecuentes = data.filter((item)=>{return item.codigoParametro == "maximo_productos_frecuentes"})
        $this.maximo_productos_frecuentes = maximo_productos_frecuentes[0]

        // Frecuencia Productos Foco
        let frecuencia_productos_focos = data.filter((item)=>{return item.codigoParametro == "frecuencia_productos_focos"})
        $this.frecuencia_productos_focos = frecuencia_productos_focos[0]

        const getFormsLaive = document.querySelectorAll(".form_laive")
        getFormsLaive.forEach(element => {
          element.classList.remove("validateClient")
        });
      })
    },
    methods: {
      isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault();;
        } else {
          return true;
        }
      },
      submitMinCharacter(evn) {
        const $parent = evn.target.parentNode
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        const dataSend = this.minimo_caracteres_busqueda
        const urlApiUpdated = process.env.VUE_APP_API_URL+"/parametro/actualizar"
        $parent.classList.add("validateClient")
        axios.put(urlApiUpdated,dataSend,config)
        .then(function (response){
          $parent.classList.remove("validateClient")
          const text = (response.status == 200) ? "Se actualizó el Min. de caracteres en Búsqueda predictiva" : "Hubo un error";
          const icon = "ni ni-bell-55";
          const content = {
            component: Notification,
            props: {
              icon: icon,
              text: text,
              type: (response.status == 200) ? "success" : "danger",
            },
          };
          const toast = useToast();
          toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
        })
      },
      submitMaxProducts(evn) {
        const $parent = evn.target.parentNode
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        const dataSend = this.maximo_productos_busqueda
        const urlApiUpdated=process.env.VUE_APP_API_URL+"/parametro/actualizar"
        $parent.classList.add("validateClient")
        axios.put(urlApiUpdated,dataSend,config)
        .then(function (response){
          $parent.classList.remove("validateClient")
          const text =(response.status == 200) ? "Se actualizó el Max. de productos en el resultado de Búsqueda" : "Hubo un error";
          const icon ="ni ni-bell-55";
          const content={
            component: Notification,
            props: {
              icon: icon,
              text: text,
              type: (response.status == 200) ? "success" : "danger",
            },
          };
          const toast = useToast();
          toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
        }).catch(function(error){
          console.log({error});
        })
      },
      submitMaxProductsFoco(evn) {
        const $parent = evn.target.parentNode
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        const dataSend = this.maximo_productos_focos
        const urlApiUpdated = process.env.VUE_APP_API_URL+"/parametro/actualizar"
        $parent.classList.add("validateClient")
        axios.put(urlApiUpdated,dataSend,config)
        .then(function (response){
          $parent.classList.remove("validateClient")
          const text =(response.status == 200) ? "Se actualizó el Max. de Productos Foco" : "Hubo un error";
          const icon ="ni ni-bell-55";
          const content={
            component: Notification,
            props: {
              icon: icon,
              text: text,
              type: (response.status == 200) ? "success" : "danger",
            },
          };
          const toast = useToast();
          toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
        }).catch(function(error){
          console.log({error});
        })
      },
      submitMaxProductsCluster(evn) {
        const $parent = evn.target.parentNode
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        const dataSend = this.maximo_productos_clusters
        const urlApiUpdated = process.env.VUE_APP_API_URL+"/parametro/actualizar"
        $parent.classList.add("validateClient")
        axios.put(urlApiUpdated,dataSend,config)
        .then(function (response){
          $parent.classList.remove("validateClient")
          const text =(response.status == 200) ? "Se actualizó el Max. de Productos Cluster" : "Hubo un error";
          const icon ="ni ni-bell-55";
          const content={
            component: Notification,
            props: {
              icon: icon,
              text: text,
              type: (response.status == 200) ? "success" : "danger",
            },
          };
          const toast = useToast();
          toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
        }).catch(function(error){
          console.log({error});
        })
      },
      submitMaxProductsPromo(evn) {
        const $parent = evn.target.parentNode
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        const dataSend = this.maximo_productos_promociones
        const urlApiUpdated = process.env.VUE_APP_API_URL+"/parametro/actualizar"
        $parent.classList.add("validateClient")
        axios.put(urlApiUpdated,dataSend,config)
        .then(function (response){
          $parent.classList.remove("validateClient")
          const text =(response.status == 200) ? "Se actualizó el Max. de Productos en Promoción" : "Hubo un error";
          const icon ="ni ni-bell-55";
          const content={
            component: Notification,
            props: {
              icon: icon,
              text: text,
              type: (response.status == 200) ? "success" : "danger",
            },
          };
          const toast = useToast();
          toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
        }).catch(function(error){
          console.log({error});
        })
      },
      submitOrdenProduct(formName) {
        // console.log(this.model.select_cat);
        this.$refs[formName].validate((valid) => {
          if (valid) {
            alert('submit!');
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      submitOrdenFilter(formName) {
        // console.log(this.model.select_cat);
        this.$refs[formName].validate((valid) => {
          if (valid) {
            alert('submit!');
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      submitParetoCluster(evn) {
        const $parent = evn.target.parentNode
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        const dataSend = this.porc_pareto_producto_cluster
        const urlApiUpdated = process.env.VUE_APP_API_URL+"/parametro/actualizar"
        $parent.classList.add("validateClient")
        axios.put(urlApiUpdated,dataSend,config)
        .then(function (response){
          $parent.classList.remove("validateClient")
          const text =(response.status == 200) ? "Se actualizó el Porcentaje de Pareto" : "Hubo un error";
          const icon ="ni ni-bell-55";
          const content={
            component: Notification,
            props: {
              icon: icon,
              text: text,
              type: (response.status == 200) ? "success" : "danger",
            },
          };
          const toast = useToast();
          toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
        }).catch(function(error){
          console.log({error});
        })
      },
      submitHistorialCluster(evn) {
        const $parent = evn.target.parentNode
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        const dataSend = this.dias_historial_producto_cluster
        const urlApiUpdated = process.env.VUE_APP_API_URL+"/parametro/actualizar"
        $parent.classList.add("validateClient")
        axios.put(urlApiUpdated,dataSend,config)
        .then(function (response){
          $parent.classList.remove("validateClient")
          const text =(response.status == 200) ? "Se actualizó el Hostirla de Prod. Cluster" : "Hubo un error";
          const icon ="ni ni-bell-55";
          const content={
            component: Notification,
            props: {
              icon: icon,
              text: text,
              type: (response.status == 200) ? "success" : "danger",
            },
          };
          const toast = useToast();
          toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
        }).catch(function(error){
          console.log({error});
        })
      },
      submitMaxProductsEspecials(evn) {
        const $parent = evn.target.parentNode
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        const dataSend = this.maximo_productos_especiales
        const urlApiUpdated = process.env.VUE_APP_API_URL+"/parametro/actualizar"
        $parent.classList.add("validateClient")
        axios.put(urlApiUpdated,dataSend,config)
        .then(function (response){
          $parent.classList.remove("validateClient")
          const text =(response.status == 200) ? "Se actualizó el Max. de Productos especiales" : "Hubo un error";
          const icon ="ni ni-bell-55";
          const content={
            component: Notification,
            props: {
              icon: icon,
              text: text,
              type: (response.status == 200) ? "success" : "danger",
            },
          };
          const toast = useToast();
          toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
        }).catch(function(error){
          console.log({error});
          $parent.classList.remove("validateClient")
        })
      },
      submitFrecuencia(evn) {
        const $parent = evn.target.parentNode
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        const dataSend = this.frecuencia_productos_especiales
        const urlApiUpdated = process.env.VUE_APP_API_URL+"/parametro/actualizar"
        $parent.classList.add("validateClient")
        axios.put(urlApiUpdated,dataSend,config)
        .then(function (response){
          $parent.classList.remove("validateClient")
          const text =(response.status == 200) ? "Se actualizó la Frecuencia" : "Hubo un error";
          const icon ="ni ni-bell-55";
          const content={
            component: Notification,
            props: {
              icon: icon,
              text: text,
              type: (response.status == 200) ? "success" : "danger",
            },
          };
          const toast = useToast();
          toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
        }).catch(function(error){
          $parent.classList.remove("validateClient")
          console.log({error});
        })
      },
      submitMaxProductsFrecuentes(evn) {
        const $parent = evn.target.parentNode
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        const dataSend = this.maximo_productos_frecuentes
        const urlApiUpdated = process.env.VUE_APP_API_URL+"/parametro/actualizar"
        $parent.classList.add("validateClient")
        axios.put(urlApiUpdated,dataSend,config)
        .then(function (response){
          $parent.classList.remove("validateClient")
          const text =(response.status == 200) ? "Se actualizó el Max. de Productos frecuentes" : "Hubo un error";
          const icon ="ni ni-bell-55";
          const content={
            component: Notification,
            props: {
              icon: icon,
              text: text,
              type: (response.status == 200) ? "success" : "danger",
            },
          };
          const toast = useToast();
          toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
        }).catch(function(error){
          $parent.classList.remove("validateClient")
          console.log({error});
        })
      },
      submitFreqProductsFoco(evn) {
        const $parent = evn.target.parentNode
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        const dataSend = this.frecuencia_productos_focos
        const urlApiUpdated = process.env.VUE_APP_API_URL+"/parametro/actualizar"
        $parent.classList.add("validateClient")
        axios.put(urlApiUpdated,dataSend,config)
        .then(function (response){
          $parent.classList.remove("validateClient")
          const text =(response.status == 200) ? "Se actualizó el Max. de Productos frecuentes" : "Hubo un error";
          const icon ="ni ni-bell-55";
          const content={
            component: Notification,
            props: {
              icon: icon,
              text: text,
              type: (response.status == 200) ? "success" : "danger",
            },
          };
          const toast = useToast();
          toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
        }).catch(function(error){
          console.log({error});
        })
      },
    },
    data() {
      return {
        maximo_productos_busqueda: {
          idParametro: "",
          codigoParametro: "",
          valor: ""
        },
        minimo_caracteres_busqueda: {
          idParametro: "",
          codigoParametro: "",
          valor: ""
        },
        maximo_productos_focos:{
          idParametro: "",
          codigoParametro: "",
          valor: ""
        },
        maximo_productos_clusters:{
          idParametro: "",
          codigoParametro: "",
          valor: ""
        },
        maximo_productos_promociones:{
          idParametro: "",
          codigoParametro: "",
          valor: ""
        },
        porc_pareto_producto_cluster:{
          idParametro: "",
          codigoParametro: "",
          valor: ""
        },
        dias_historial_producto_cluster:{
          idParametro: "",
          codigoParametro: "",
          valor: ""
        },
        maximo_productos_especiales: {
          idParametro: "",
          codigoParametro: "",
          valor: ""
        },
        frecuencia_productos_especiales: {
          idParametro: "",
          codigoParametro: "",
          valor: ""
        },
        maximo_productos_frecuentes: {
          idParametro: "",
          codigoParametro: "",
          valor: ""
        },
        frecuencia_productos_focos: {
          idParametro: "",
          codigoParametro: "",
          valor: ""
        },
        model: {
          searchpredivtive: "",
          maxproducts: "",
          select_cat: [
            "09",
            "02"
          ],
          select_filter: [
            "categorias",
            "stock"
          ],
          categories: [
            {value: '09', label: 'Bebidas'},
            {value: '02', label: 'Leches Frescas'},
            {value: '04', label: 'Yogurts'},
            {value: 'Dansk', label: 'Programa Social'},
            {value: 'yogurt', label: 'Evaporadas'},
            {value: 'adasd', label: 'Cremas De Leche'},
            {value: '1213', label: 'Quesos'},
            {value: '123123', label: 'Manjares'},
            {value: 'evaporadas', label: 'Evaporadas'},
            {value: 'mantequillas', label: 'Mantequillas'},
          ],
          filters: [
            {value: 'categorias', label: 'Categorias'},
            {value: 'brand', label: 'Marca'},
            {value: 'price', label: 'Precio'},
            {value: 'codeproduct', label: 'Código Producto'},
            {value: 'stock', label: 'Stock'},
            {value: 'codefabrica', label: 'Codigo Fabrica'},
            {value: 'united', label: 'Unidad Base'},
          ],
        },
        rules: {
          select_cat: [
            {
              required: true,
              message:"Este campo es requerido",
              trigger:"change"
            },
          ],
          select_filter: [
            {
              required: true,
              message:"Este campo es requerido",
              trigger:"change"
            },
          ],
        },
      };
    },
  };
</script>

