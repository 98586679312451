import setting from "./setting";

import { downloadFile } from "../util";
import { request } from "../util/request";

const getDetailRequestReport = async (id) => {
  const { data } = await request.get(
    `v2/Equipment/requests/detailReported/${id}`
  );
  return data;
};

const getDetail = async (id) => {
  const { data } = await request.get(`v2/Equipment/requests/detail/${id}`);
  return data;
};

const update = async (idSolicitud, updateData) => {
  const { data } = await request.put(
    `v2/Equipment/requests/update/${idSolicitud}`,
    updateData
  );
  return data;
};

const getByFilters = async (filters = { page: 1 }) => {
  const { data } = await request.post("Equipos/solicitudes/lista", filters);
  return data;
};

const uploadBulk = async (body) => {
  const { data } = await request.post("v2/Equipment/requests/UploadBulk", body);
  return data;
};

const uploadImages = async ({ idSolicitud, body }) => {
  const { data } = await request.put(
    `v2/Equipment/requests/updateImage/${idSolicitud}`,
    body
  );
  return data;
};

const downloadList = async () => {
  const { data } = await request.post("v2/Equipment/requests/export", {});
  await downloadFile(`${new URL(setting.http.base_url).origin}/${data}`, data);

  return data;
};

export default {
  downloadList,
  getByFilters,
  getDetail,
  getDetailRequestReport,
  update,
  uploadBulk,
  uploadImages,
};
