export const Theme = {
	spacing: {
		DEFAULT: "0",
		xxs: "0.35rem",
		xs: "0.650rem",
		sm: "0.750rem", // 12px
		base: "1rem",
		md: "1.125rem",
		lg: "1.25rem", // 20px
		xl: "1.5rem",
		"2xl": "1.875rem",
	},
	fontWeights: {
		medium: "500",
		smBold: "600",
		bold: "700",
		light: "300",
	},
	sizes: {
		DEFAULT: "auto",
		max: "max-content",
		min: "min-content",
		full: "100%",
	},
	fontSizes: {
		DEFAULT: "1rem",
		xxs: "0.625rem",
		xs: "0.750rem", //12px
		sm: "0.875rem",
		base: "1rem",
		md: "1.125rem",
		lg: "1.25rem",
		xl: "1.50rem",
	},
	radius: {
		DEFAULT: "5px",
		base: "5px",
		btn: "8px",
	},
	colors: {
		black: {
			DEFAULT: "#000000",
			light: "#1A1818",
		},
		brand: {
			DEFAULT: "#02A54F",
		},
		second: {
			DEFAULT: "#F7F9FC",
		},
		gray: {
			DEFAULT: "#B8B8B8",
			hover: "#F3F3F3",
		},
	},
};
