<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive validateClient">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
          >
          <template v-slot:header>
            <div class="row">
              <div class="col-12 col-md-6">
                <h3 class="mb-0">Productos Refrigerados</h3>
              </div>
              <div class="col-12 col-md-6 text-right">
                <button type="button" @click="exportExcel" class="btn base-button btn-primary btn-sm" role="button"><i class="fas fa-file-export"></i>Exportar </button>
              </div>
            </div>
          </template>
          <div>
            <div class="row px-4">
              <div class="col-12 mb-3">
                <div class="row">
                  <div class="col-12 col-md-4 d-none">
                    <el-input
                      type="search"
                      clearable
                      prefix-icon="el-icon-search"
                      placeholder="Buscar producto"
                      v-model="searchQuery"
                      aria-controls="datatables"
                    >
                    </el-input>
                  </div>
                  <div class="col-12 col-md-4">
                    <template v-if="distribuidoraIdCurrent">
                      <span class="title_filter mr-2">Distribuidora:  <strong>{{distribuidoraNameCurrent}}</strong></span>
                    </template>
                    <template v-else>
                      <span class="title_filter mr-2">Distribuidoras:  </span>
                      <select v-model="filter.iddistribuidora" class="form-control" style="display: inline-block;height: 43px;" name="distributor_select" @change="changeDistributor">
                        <option value="0">Todos</option>
                        <option
                          v-for="item in distribuidoras"
                          :value="item.idDistribuidora"
                          :key="item.idDistribuidora"
                          >
                          {{ item.razonSocial }}
                        </option>
                      </select>
                    </template>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-2 d-none">
                <div class="mr-2">
                  <!-- <span class="title_filter">Paginación:  </span> -->
                  <el-select
                    class="select-primary pagination-select"
                    v-model="pagination.perPage"
                    style="width:80px"
                    placeholder="Per page"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <!-- Real Table -->
            <el-table
              :data="queriedData"
              row-key="idProducto"
              empty-text="Debe seleccionar una Distibuidora"
              header-row-class-name="thead-light"
            >
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                v-bind="column"
              >
              </el-table-column>
            </el-table>
          </div>
          <template v-slot:footer>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category d-none">
                  Mostrando {{ from + 1 }} a {{ to }} de {{ total }} productos
                </p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="filter.page"
                :per-page="pagination.perPage"
                :total="total"
                @click="changePaginate"
              >
              </base-pagination>
            </div>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import swal from "sweetalert2";
import axios from "axios";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BasePagination from "@/components/BasePagination";
import {mapGetters,mapActions} from 'vuex';
import DashboardNavbar from "../Layout/DashboardNavbar";
export default {
  components: {
    DashboardNavbar,
    BasePagination,
    RouteBreadCrumb,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  computed: {
    pagedData() {
      this.getDistributor
      this.getDataCluster
      return this.tableData
      if(this.tableData.length){
        this.searchedData = this.tableData
      }
    },
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString().toLowerCase();
          if (rowValue.includes && rowValue.includes(this.searchQuery.toLowerCase())) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });
      console.log(result.length);
      this.searchedData = result
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    }),
    async getDistributor(){
      try{
        let result = await this.getDistributorList();
        this.distribuidoras = result.data ? result.data.filter((item) => { return item.estado == "1" }) : [];
        if(this.distribuidoraIdCurrent){
          let dataDistibuitorCurrent =  this.distribuidoras.filter((item)=>{return item.idDistribuidora == this.distribuidoraIdCurrent})
          this.distribuidoraNameCurrent = dataDistibuitorCurrent[0].razonSocial
        }
      }
      catch(ex){ex}
    },
    getDataCluster(){
      const $this = this
      if(this.distribuidoraIdCurrent && this.initload){
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        axios.post(process.env.VUE_APP_API_URL+"/producto/refrigerado/lista", this.filter,config)
        .then(function (response){
          document.querySelector(".form_laive").classList.remove("validateClient")
          $this.initload = false
          $this.pagination.perPage = response.data.data.length
          $this.pagination.total = response.data.totalPage
          return $this.tableData = response.data.data
        })
      }else{
        setTimeout(() => {
          document.querySelector(".form_laive").classList.remove("validateClient")
        }, 300);
      }
    },
  },
  methods: {
    ...mapActions({
        getDistributorList: 'distribuidora/getList',
    }),
    changeDistributor(event) {
      const $this = this;
      const select = event.target
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      this.filter.page = 1
      this.resetPagination(this.filter.page);
      document.querySelector(".form_laive").classList.add("validateClient")
      axios.post(process.env.VUE_APP_API_URL+"/producto/refrigerado/lista",this.filter,config)
      .then(function (response){
        document.querySelector(".form_laive").classList.remove("validateClient")
        console.log(response.data)
        $this.pagination.perPage = response.data.data.length
        $this.pagination.total = response.data.totalPage
        return $this.tableData = response.data.data
      })
    },
    changePaginate(evn){
      const $this = this
      const listPaginator = document.querySelectorAll("ul.pagination .page-item")
      let elm = evn.target;
      let sendAjax = false;
      let parentEl = elm.parentNode
      if(elm.classList.contains("prev-page")){
        if(!elm.classList.contains("disabled")){
          let linkActivePrev = document.querySelectorAll("ul.pagination .page-item.active")
          linkActivePrev[0].classList.remove("active")
          linkActivePrev[0].previousSibling.classList.add("active")
          sendAjax = true
        }
      }else if(elm.classList.contains("next-page")){
      }else{
        if(elm.getAttribute("aria-label") == "Previous"){
          let linkActivePrev = document.querySelectorAll("ul.pagination .page-item.active")
          linkActivePrev[0].classList.remove("active")
          linkActivePrev[0].previousSibling.classList.add("active")
          sendAjax = true
        }else if(elm.getAttribute("aria-label") == "Next"){
          let linkActiveNext = document.querySelectorAll("ul.pagination .page-item.active")
            linkActiveNext[0].classList.remove("active")
            linkActiveNext[0].nextSibling.classList.add("active")
            sendAjax = true
        }else{
          if(!parentEl.classList.contains("active")){
            listPaginator.forEach(item => {
              item.classList.remove("active")
            });
            parentEl.classList.add("active")
            sendAjax = true
          }
        }
      }
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      if(sendAjax){
        document.querySelector(".form_laive").classList.add("validateClient")
        axios.post(process.env.VUE_APP_API_URL+"/producto/refrigerado/lista",this.filter,config)
        .then(function (response){
          document.querySelector(".form_laive").classList.remove("validateClient")
          console.log(response.data.data)
          sendAjax = false
          $this.filter.page = response.data.page
          $this.pagination.perPage = response.data.data.length
          $this.pagination.total = response.data.totalPage
          return $this.tableData = response.data.data
        })
      }
    },
    exportExcel(){
      const token = localStorage.getItem("token")
      const urlDomain = process.env.VUE_APP_API_URL.replace("api", "")
      const configExport = {
        headers: {
          "Authorization": `Bearer ${token}`,
        }
      };
      document.querySelector(".form_laive").classList.add("validateClient")
      axios.post(process.env.VUE_APP_API_URL+`/producto/refrigerado/exportar`,this.filter,configExport)
      .then(function (response){
        document.querySelector(".form_laive").classList.remove("validateClient")
        const urlFile = urlDomain + response.data.fileName
        const link = document.createElement('a');
        link.href = urlFile;
        document.body.appendChild(link);
        link.click();
      })
    },
    resetPagination(value){
      const paginate = document.querySelectorAll(".page-item.number")
      let index = value - 1
      paginate.forEach(item => {
        if(item.classList.contains("active")){
          item.classList.remove("active")
        }
      });
      paginate[index].classList.add("active");
    }
  },
  mounted() {
    if(this.user.rol == 3){
      this.distribuidoraIdCurrent = this.user.idDistribuidora
      this.filter.iddistribuidora = this.user.idDistribuidora
    }
    if(this.user.rol == 4){
      this.supervisorIdCurrent = this.user.codigo
      this.supervisorNameCurrent = this.user.nombre
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
  },
  data() {
    return {
      distribuidoraIdCurrent: null,
      distribuidoraNameCurrent: null,
      supervisorIdCurrent: null,
      supervisorNameCurrent: null,
      initload: true,
      pagination: {
        perPage: 25,
        currentPage: 1,
        newcurrentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      filter: {
        iddistribuidora: "0",
        page: 1
      },
      distribuidoras:[],
      tableColumns: [
        {
          prop: "distribuidora",
          label: "Distribuidora",
          minWidth: 120,
        },
        {
          prop: "producto",
          label: "Producto",
          minWidth: 220,
        },
        {
          prop: "codigoProducto",
          label: "Cod. Producto",
          minWidth: 120,
        },
        {
          prop: "fechaCarga",
          label: "Fecha Carga",
          minWidth: 120,
        },
      ],
      searchQuery: "",
      propsToSearch: ["codigoGrupo"],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      expands: [],
    };
  },
};
</script>
