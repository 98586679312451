<template>
  <div>
    <div class="row mx-0">
      <Chip
        :etiqueta="`${dex.fin}:`"
        :texto="dex.labels"
        v-for="dex in dexs"
        :key="dex"
      ></Chip>
    </div>
  </div>
</template>
<script setup>
import Chip from "./Chip.vue";

import { ref, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
const store = useStore();

const { bonificacion } = defineProps({
  bonificacion: {
    type: Object,
  },
});

onMounted(() => {});

const distribuidoras_options = computed(() =>
  store.state.bonificacionUtil.distribuidoras.map((item) => {
    return {
      value: item.iddistribuidora,
      label: item.codigodistribuidora,
    };
  })
);

const dexs = computed(() =>
  obtenerDistribuidoras(
    bonificacion.distribuidoras,
    distribuidoras_options.value
  )
);

const obtenerLabels = (seleccionados, fuente) => {
  if (seleccionados?.length > 0) {
    if (seleccionados?.length == fuente.length) {
      return "Todos";
    } else {
      let result = [];
      fuente.forEach((element) => {
        if (seleccionados?.includes(`${element.value}`)) {
          result.push(element.label);
        }
      });
      return result.join(", ");
    }
  }
};

const obtenerDistribuidoras = (seleccionados, fuente) => {
  const fechas = seleccionados.map((item) => item.fin);
  const fechas_fin_unicas = fechas.filter((valor, indice, self) => {
    return self.indexOf(valor) === indice;
  });

  const result = [];
  fechas_fin_unicas.forEach((fecha_fin) => {
    const identifiers = seleccionados
      .filter((item) => item.fin == fecha_fin)
      .map((item) => item.id);
    result.push({
      labels: obtenerLabels(identifiers, fuente),
      fin_raw: fecha_fin,
      fin: formatearFecha(new Date(fecha_fin)),
    });
  });
  return result;
};

const formatearFecha = (fecha) => {
  if (!fecha) {
    return "-";
  }
  const meses = [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ];

  const dia = fecha.getDate();
  const mes = meses[fecha.getMonth()];
  const anio = fecha.getFullYear();
  const dia_st = dia < 10 ? `0${dia}` : dia;
  return `${dia_st} ${mes} ${anio}`;
};
</script>
<style lang="scss" scoped></style>
