<template>
  <header
    aria-label="breadcrumb"
    class="justify-content-between align-items-center d-none d-md-flex"
  >
    <route-bread-crumb></route-bread-crumb>
    <dashboard-navbar></dashboard-navbar>
  </header>
  <div class="card-table-container th-bg-slate py-1 px-3">
    <CardTable
      headerTitle="Maestros de activos"
      :showButton="user.rol === 1 || user.rol === 2 || user.rol === 8"
      buttonText="Carga de Equipos"
      buttonType="link"
      buttonLink="/equipos-frio/maestro-activos?tab=carga"
      :loading="loading"
    >
      <template #filters>
        <SelectButton
          label="Oficina de ventas"
          name="oficina"
          v-model="filters.oficinaVentas.selected"
          :options="filters.oficinaVentas.options"
          :keyFilter="'nombre'"
          :hasId="true"
        />
        <SelectButton
          label="DEX"
          name="dex"
          v-model="filters.dexs.selected"
          :options="filters.dexs.options"
          :keyFilter="'nombre'"
          :hasId="true"
          @update:modelValue="fetchListByDex"
        />
        <TextInput
          type="text"
          label="Buscar"
          placeholder="Nro de placa"
          name="placa"
          :isSearch="true"
          :optional="true"
          v-model="filters.placa"
        />
      </template>
      <div class="b-table sticky-header scroller">
        <div class="t-header">
          <div class="t-row">
            <div
              class="t-cell justify-content-center"
              v-for="header in tabla.headers"
              :key="header.label"
              :style="{ minWidth: header.minWidth }"
            >
              <span>{{ header.label }}</span>
            </div>
          </div>
        </div>
        <div v-if="tabla.rows.length > 0" class="t-body text-uppercase">
          <div class="t-row" v-for="(row, i) in tabla.rows">
            <template v-for="{ key } in tabla.headers.slice(0, -2)">
              <div class="t-cell justify-content-center" v-if="key == 'estado'">
                <ui-badge
                  type="chip"
                  :bg="getStatusColor(row['estado']).bg"
                  :text-color="getStatusColor(row['estado']).text"
                  class="text-uppercase"
                  fs="-xxs"
                  fw="500"
                  px="-xs"
                  py="0.4em"
                  w="90%"
                >
                  {{ row["estado"] ?? "--" }}
                </ui-badge>
              </div>
              <div class="t-cell justify-content-center" v-else>
                {{ row[key] }}
              </div>
            </template>

            <div class="t-cell justify-content-center">
              <button
                class="qr-open-button"
                role="button"
                @click.prevent="showModal('qr', i)"
                style="--style: none"
              >
                {{ row["codigoQr"] ?? "--" }}
                <i class="ml-2 laive-i icon-qr-code" />
              </button>
            </div>
            <div class="t-cell justify-content-center">
              <button
                class="th-link-pry see-details-btn"
                role="button"
                @click.prevent="showModal('details', i)"
                style="--style: none"
              >
                Ver detalle
              </button>
            </div>
          </div>
        </div>
        <div v-if="tabla.rows.length == 0" class="t-nt-found">
          <img src="/img/shared/search.svg" alt="No encontrado" />
          <p class="text">No hay datos que mostrar</p>
        </div>
      </div>
      <Pagination
        :currentPage="currentPage"
        :totalPages="totalPages"
        @page-changed="changePage"
      />
    </CardTable>
    <div class="mt-3 mb-3 th-flex-r" style="--justify: flex-end">
      <button
        v-if="
          user.rol === 1 ||
          user.rol === 2 ||
          user.rol === 3 ||
          user.rol === 4 ||
          user.rol === 8
        "
        class="th-btn-border-pry th-btn btn-download"
        @click="downloadListQr"
        :disabled="loading"
      >
        Descargar lista de QRs
        <i class="ml-2 laive-i icon-download" />
      </button>
      <button
        v-if="
          user.rol === 1 ||
          user.rol === 2 ||
          user.rol === 3 ||
          user.rol === 4 ||
          user.rol === 8
        "
        class="th-btn-pry th-btn btn-download"
        @click="downloadList()"
        :disabled="loading"
      >
        Descargar lista
        <i class="ml-2 laive-i icon-download-white" />
      </button>
    </div>
  </div>
  <custom-modal
    :show="modals.qr.show"
    :isHeader="true"
    width="550"
    contentClass="p-3 pt-4"
    @modal:close="closeModal('qr')"
    showCloseIcon
  >
    <template #header>
      <h3 class="th-text-primary">Placa {{ modals.qr.placa }}</h3>
    </template>
    <template #body>
      <box-loader
        class="px-2 qr-section"
        :loading="modals.qr.loading"
        minHeight="130px"
      >
        <div class="qr-img">
          <QrCode
            :code="modals.qr.qrCode"
            :size="150"
            v-if="modals.qr.qrCode"
          />
          <p v-else>No tiene codigo qr</p>
          <span class="th-btn">
            {{ modals.qr.qrCode }}
          </span>
        </div>
        <hr />
        <div class="th-flex-r top-border" style="--justify: flex-end">
          <button class="th-btn th-btn-border-pry" @click="closeModal('qr')">
            Cancelar
          </button>
          <button
            class="th-btn-float th-btn-pry"
            @click="downloadQr"
            :disabled="!modals.qr.qrCode"
          >
            Descargar
            <i class="ml-2 laive-i icon-download-white" />
          </button>
        </div>
      </box-loader>
    </template>
  </custom-modal>
  <MaestroActivosModal
    :show="modals.details.show"
    :equipoId="modals.details.equipoId"
    @update:show="closeModal('details')"
    :distribuidoras="filters.totalDexs"
    @save="updateList"
  />
</template>
<script>
import { useToast } from "vue-toastification";
import { mapGetters } from "vuex";
import SelectButton from "@/components/Shared/SelectButton.vue";
import TextInput from "@/components/Shared/TextInput.vue";
import Pagination from "../../../components/Pagination.vue";
import { QrCode } from "../../../components";
import CustomModal from "@/views/Components/CustomModal.vue";
import { CardTable } from "../../../components/Cards";
import RouteBreadCrumb from "../../../components/Breadcrumb/RouteBreadcrumb.vue";
import DashboardNavbar from "../../Layout/DashboardNavbar.vue";
import MaestroActivosModal from "./MaestroActivosModal.vue";
import { Distribuidoras, Equipos } from "../../../services";
import { debounce, parseNormalDate } from "../../../util";

const STATUS_COLORS = {
  asignado: { bg: "#CAFDE0", text: "#02A54F" },
  activo: { bg: "#CAFDE0", text: "#02A54F" },
  stock: { bg: "#ECF4FF", text: "#1D4193" },
  perdido: { bg: "#FEF0F1", text: "#FF3143" },
  baja: { bg: "#EDEDED", text: "#B1B0B0" },
  desuso: { bg: "#FFF4E0", text: "#B7975A" },
};

const OFICINAS = {
  ALL: -1,
  LIMA: 1,
  PROV: 2,
};

const OFICINAS_CODES = {
  LIMA: 1001,
  PROV: 1002,
};

export default {
  components: {
    RouteBreadCrumb,
    DashboardNavbar,
    SelectButton,
    TextInput,
    CustomModal,
    MaestroActivosModal,
    CardTable,
    QrCode,
    Pagination,
  },
  data: () => ({
    loading: true,
    currentPage: 1,
    totalPages: 0,
    filters: {
      // Todos los dexs, para filtrarlos por
      // oficina de ventas.
      totalDexs: [],
      placa: "",
      oficinaVentas: {
        selected: OFICINAS.ALL,
        options: [
          { texto: "Todas (Lima y Provincias) ", valor: OFICINAS.ALL },
          { texto: "Lima", valor: OFICINAS.LIMA },
          { texto: "Provincias", valor: OFICINAS.PROV },
        ],
      },
      dexs: {
        selected: -1,
        options: [{ texto: "Todas", valor: -1 }],
      },
    },
    tabla: {
      headers: [
        { key: "placa", label: "Placa", minWidth: "150" },
        { key: "modelo", label: "Modelo", minWidth: "120" },
        { key: "lote", label: "Lote", minWidth: "120" },
        { key: "estado", label: "Estado", minWidth: "120" },
        { key: "fechaAct", label: "Fecha de Actualización", minWidth: "180" },
        { key: "condicion", label: "Condición", minWidth: "120" },
        { key: "dex", label: "DEX", minWidth: "130" },
        { key: "", label: "QR", minWidth: "130" },
        { key: "", label: "", minWidth: "130" },
      ],
      rows: [],
    },
    modals: {
      qr: {
        qrCode: null,
        placa: null,
        equipoId: null,
        show: false,
        loading: false,
      },
      details: {
        equipoId: null,
        show: false,
      },
    },
  }),
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        if (!this.$store.state.auth.token) return;
        const { token } = this.$store.state.auth;

        const dataDexs = await Distribuidoras.getAll(token);
        const dataEquipos = await Equipos.getByFilters(token, {
          page: this.currentPage,
        });

        const dexs = dataDexs
          .filter(({ estado }) => estado == "1")
          .map((dx) => ({
            texto:
              dx.razonSocial.charAt(0).toUpperCase() +
              dx.razonSocial.slice(1).toLowerCase(),
            id: dx.idDistribuidora,
            valor: dx.idDistribuidora,
          }));
        this.filters.totalDexs = dexs;

        this.filters.dexs = {
          selected: -1,
          options: [{ texto: "Todas", valor: -1 }, ...dexs],
        };

        const { data: equipos, page, totalPage } = dataEquipos;
        this.parseEquipos(equipos);
        this.currentPage = page;
        this.totalPages = totalPage;
      } catch (error) {
        this.toast("No se pudo cargar la información", "error");
      } finally {
        this.loading = false;
      }
    },
    getStatusColor(st) {
      return STATUS_COLORS[st.trim()];
    },
    toast(title, type = "success") {
      const toast = useToast();
      if (type == "success") {
        toast.success(title, {
          position: "top-right",
          closeOnClick: true,
          hideProgressBar: true,
          timeout: 6000,
          toastClassName: "th-alert th-alert-pry",
          showCloseButtonOnHover: true,
        });
      } else if (type == "error") {
        toast.error(title, {
          position: "top-right",
          closeOnClick: true,
          hideProgressBar: true,
          timeout: 6000,
          toastClassName: "th-alert th-alert-error",
          showCloseButtonOnHover: true,
        });
      }
    },
    parseEquipos(equipos) {
      if (Array.isArray(equipos)) {
        const parseds = equipos.map(
          ({ fechaActualizacion, distribuidora, ...rest }) => {
            let fechaAct = "--";
            if (fechaActualizacion) {
              const date = parseNormalDate(fechaActualizacion.split(" ")[0]);
              fechaAct = date;
            }

            return {
              ...rest,
              fechaAct,
              dex: rest.idDistribuidora ? distribuidora : "--",
              condicion: rest.condicion ?? "--",
              codigoQr: rest.codigoEquipoQr,
            };
          }
        );
        this.tabla.rows = parseds;
      } else {
        this.tabla.rows = [];
      }
    },
    showModal(selected, index) {
      const equipoRow = this.tabla.rows[index];
      if (!equipoRow) return;
      if (selected == "details") {
        this.modals.details = {
          equipoId: equipoRow.idEquipo,
          show: true,
        };
      } else if (selected == "qr") {
        this.modals.qr = {
          equipoId: equipoRow.idEquipo,
          placa: equipoRow.placa,
          qrCode: equipoRow.codigoQr,
          show: true,
        };
      }
    },
    closeModal(selected) {
      if (selected == "details") {
        this.modals.details = {
          equipoId: null,
          show: false,
        };
      } else if (selected == "qr") {
        this.modals.qr = {
          equipoId: null,
          placa: null,
          show: false,
        };
      }
    },
    filtersToSend(selectedOficina, seletedDex, placa) {
      const codigoOficinaVenta =
        selectedOficina === OFICINAS.LIMA
          ? OFICINAS_CODES.LIMA
          : OFICINAS_CODES.PROV;
      const idDistribuidora = seletedDex === -1 ? 0 : seletedDex;

      const oficina =
        selectedOficina === OFICINAS.ALL ? null : codigoOficinaVenta.toString();

      const filters = {
        page: this.currentPage,
        oficina,
        idDistribuidora,
        placa: placa?.length > 0 ? placa : null,
      };

      return filters;
    },
    async fetchList(oficina, dexSelected, placa) {
      try {
        this.loading = true;

        const token = this.$store.state.auth.token;
        const { data: equipos, page, totalPage } = await Equipos.getByFilters(
          token,
          this.filtersToSend(oficina, dexSelected, placa)
        );

        this.parseEquipos(equipos);
        this.currentPage = page;
        this.totalPages = totalPage;
      } catch (error) {
        this.toast("Error al obtener la información", "error");
      } finally {
        this.loading = false;
      }
    },
    updateList() {
      this.fetchList(
        this.filters.oficinaVentas.selected,
        this.filters.dexs.selected,
        this.filters.placa
      );
    },
    performSearch: debounce(function (oficina, dex, placa) {
      this.resetPagination();
      this.fetchList(oficina, dex, placa);
    }, 450),
    fetchListByDex(selected) {
      this.resetPagination();

      this.fetchList(
        this.filters.oficinaVentas.selected,
        selected,
        this.filters.placa
      );
    },
    changePage(page) {
      this.currentPage = page;

      this.fetchList(
        this.filters.oficinaVentas.selected,
        this.filters.dexs.selected,
        this.filters.placa
      );
    },
    resetPagination() {
      this.currentPage = 1;
      this.totalPages = 0;
    },
    downloadQr() {
      if (!this.modals.qr.qrCode) return;
      const token = this.$store.state.auth.token;
      if (!token) return;
      this.modals.qr.loading = true;
      Equipos.downloadSingleQr(token, this.modals.qr.equipoId)
        .then(() => {
          this.toast("Se descargó el QR.");
          this.closeModal("qr");
        })
        .catch(() => {
          this.toast("Falló la descarga", "error");
        })
        .finally(() => (this.modals.qr.loading = false));
    },
    async downloadListQr() {
      try {
        const token = this.$store.state.auth.token;
        if (!token) return;

        this.loading = true;

        const selectedOficina = this.filters.oficinaVentas.selected;
        const selectedDex = this.filters.dexs.selected;
        const textPlaca = this.filters.placa;

        const codigoOficinaVenta =
          selectedOficina === OFICINAS.LIMA
            ? OFICINAS_CODES.LIMA
            : OFICINAS_CODES.PROV;
        const idDistribuidora = selectedDex === -1 ? 0 : selectedDex;
        const placa = textPlaca.length === 0 ? null : textPlaca;

        const filter = {
          codigoOficinaVenta:
            selectedOficina === OFICINAS.ALL ? 0 : codigoOficinaVenta,
          idDistribuidora,
          placa,
        };

        await Equipos.downloadListQr(token, filter);
        this.toast("Se descargó la lista");
      } catch (error) {
        this.toast("Falló la descarga", "error");
      } finally {
        this.loading = false;
      }
    },
    downloadList() {
      const token = this.$store.state.auth.token;
      if (!token) return;
      this.loading = true;
      const filtersData = this.filtersToSend(
        this.filters.dexs.selected,
        this.filters.placa
      );
      Equipos.downloadList(token, filtersData)
        .then(() => {
          this.toast("Se descargó la lista");
        })
        .catch(() => {
          this.toast("Error al descargar la información", "error");
        })
        .finally(() => (this.loading = false));
    },
  },
  watch: {
    "filters.oficinaVentas.selected"(selectedOf) {
      let dexs = [...this.filters.totalDexs];
      if (selectedOf == OFICINAS.PROV) {
        dexs = this.filters.totalDexs.filter(({ id }) => id > 6);
      } else if (selectedOf === OFICINAS.LIMA) {
        dexs = this.filters.totalDexs.filter(({ id }) => id <= 6);
      }

      dexs.push({ texto: "Todas", valor: -1 });
      dexs.unshift(dexs.pop());

      this.filters.dexs.options = dexs;
      this.filters.dexs.selected = -1;

      this.resetPagination();

      // Fetch all
      this.fetchList(
        selectedOf,
        this.filters.dexs.selected,
        this.filters.placa
      );
    },
    "filters.placa"(placa) {
      this.performSearch(
        this.filters.oficinaVentas.selected,
        this.filters.dexs.selected,
        placa
      );
    },
  },
};
</script>

<style scoped>
header {
  padding-inline: 1em;
  padding-block: 1.2em;
  padding-bottom: 4.5em;
}

header :deep(nav:last-child) {
  padding: 0 !important;
}

.card-table-container :deep(.card-table) {
  margin-top: -3.5em;
}

@media screen and (max-width: 760px) {
  .card-table-container {
    margin-top: 4.5em;
  }
}

.b-table {
  --columns: repeat(auto-fit, minmax(120px, 1fr));
  --columns-sm: repeat(auto-fit, minmax(100px, 1fr));
}

.see-details-btn {
  font-weight: 500;
}

:deep(.qr-section .qr-img) {
  width: 60%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
  margin-bottom: 2em;
  margin-top: 1em;
}

hr {
  height: 1px;
  margin: 1em 0;
  border-top: 0;
  background-color: #b8b8b8;
}

:deep(.qr-section span) {
  border: 0;
  width: 100%;
  color: black;
  text-align: center;
  background-color: #ebebeb;
  font-size: 0.875rem;
  font-weight: 500;
}

:deep(.qr-section button:last-of-type) {
  padding-inline: 2.8em;
}

.qr-open-button {
  border: 0;
  background-color: transparent;
  text-decoration-line: underline;
  text-underline-offset: 2px;
}

.qr-open-button:hover {
  color: #02a54f;
}

button,
header a {
  display: flex;
  align-items: center;
}

section span {
  display: block;
}

.btn-download {
  font-size: 0.875rem;
}

header a i,
.btn-download i {
  height: 18px;
  aspect-ratio: 1;
  display: block;
}
</style>
