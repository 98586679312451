<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1 pt-0 mt-0"
          footer-classes="pb-2"
        >
          <template v-slot:header>
            <div class="row">
              <div class="col-12 col-md-6">
                <h3 class="mb-0">Detalle de {{vendedor.nombre}}</h3>
              </div>
              <div class="col-12 col-md-6 text-right">
                <base-button size="sm" type="primary">
                  <i class="ni ni-single-02"></i>Clientes Asignados
                </base-button>
                <router-link to="crear/"><base-button size="sm" type="info">Crear Producto</base-button></router-link>
              </div>
            </div>
          </template>
          <div class="card-body">
            <form @submit.prevent="submiCreate">
              <div class="form-group row mb-0">
                <div class="col-md-4">
                  <label class="col-form-label form-control-label">Jerarquías:</label>
                  <select class="form-control" style="text-transform: capitalize;;height: 43px;" name="jerarquias_select" required @change="changeJerarquia">
                    <option value="" disabled selected style="display:none">Seleccionar</option>
                    <option value="marca">marcas</option>
                    <option value="categoria">categorias</option>
                    <option value="presentacion">presentaciones</option>
                    <option value="familia">familias</option>
                  </select>
                </div>
                <div class="col-md-4">
                  <label class="col-form-label form-control-label">Código:</label>

                </div>
                <div class="col-md-4">
                  <label class="col-md-12 col-form-label form-control-label">Nombre:</label>

                </div>
              </div>
              <base-button type="primary" native-type="submit" class="btn-default my-0">Crear Jerarquía</base-button>
            </form>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
export default {
  components: {
    RouteBreadCrumb,
  },
  methods: {
    changeJerarquia(evn){
      const select = evn.target
      const value = select.value;
      this.category.codigoTipo = value
    },
    submiCreate(values) {
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let createProduct = process.env.VUE_APP_API_URL+"/maestro/crear"
      axios.post(createProduct, this.category,config)
      .then(function (response){
        document.querySelector(".form_laive").classList.remove("validateClient")
        const text = (response.status == 200) ? "Se creó la Categoría" : "Hubo un error";
        const icon = "ni ni-bell-55";
        const content = {
          component: Notification,
          props: {
            icon: icon,
            text: text,
            type: (response.status == 200) ? "success" : "danger",
          },
        };
        const toast = useToast();
        toast(content, {
          hideProgressBar: true,
          icon: false,
          closeButton: false,
          position: "top-right",
        });
        setTimeout(() => {
          $this.$router.replace({name: 'Maestro Categorias'})
        }, 2000);
      })
      // alert(JSON.stringify(values, null, 2));
    }
  },
  data() {
    return {
      vendedor: {
        nombre: "Nombre 001",
        apellidos: "Apellido 001",
        codigo: "241241",
        telefono:"98218312",
        dni:"4t235255",
        email: "email@email.com",
        supervisor_assignado: "Supervisor 001",
        distribuidora: "3a",
        grupo_venta: "Azul",
        mesa: "922",
      },
    };
  },
}
</script>
<style lang="scss">
</style>
