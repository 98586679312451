<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <!-- HTML5 inputs -->
            <div class="card">
              <!-- Card header -->
              <div class="card-header">
                <h3 class="mb-0">Parametros Ticket Esperado</h3>
              </div>
              <!-- Card body -->
              <div class="row">
                <!-- Número de ventas Ticket-->
                <div class="col-md-3">
                  <div class="card-body form_laive validateClient">
                    <div class="box_loader">
                      <div class="loader"></div>
                    </div>
                    <form @submit.prevent="submitVentasTicket">
                      <div class="form-group row">
                        <label class="col-md-12 col-form-label form-control-label">Tickets históricos</label>
                        <div class="col-md-12">
                          <base-input
                            required
                            name="maxproducts"
                            placeholder="N° de ventas"
                            v-model="ventas_ticket_esperado.valor"
                            @keypress="isNumber($event)"
                          >
                          </base-input>
                          <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar N° de tickets</base-button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <!-- Número de Ultimos tickets -->
                <div class="col-md-3">
                  <div class="card-body form_laive validateClient">
                    <div class="box_loader">
                      <div class="loader"></div>
                    </div>
                    <form @submit.prevent="submitNumeroTicket">
                      <div class="form-group row">
                        <label class="col-md-12 col-form-label form-control-label">Ticket esperados</label>
                        <div class="col-md-12">
                          <base-input
                            required
                            name="numeroTicket"
                            placeholder="N° de últimos tickets esperados"
                            v-model="numero_tickets_esperado.valor"
                            @keypress="isNumber($event)"
                          >
                          </base-input>
                          <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar N° de Tickets</base-button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <template v-for="(item, k) in segmentoTicket" :key="item.idParametro">
                  <div class="col-md-3">
                  <div class="card-body form_laive validateClient">
                    <div class="box_loader">
                      <div class="loader"></div>
                    </div>
                    <form @submit.prevent="submitSegmento" :data-key="k">
                      <div class="form-group row">
                        <label class="col-md-12 col-form-label form-control-label">{{item.nombre}}</label>
                        <div class="col-md-12">
                          <base-input
                            required
                            :name="`${item.idParametro}-segmento`"
                            placeholder="N° de últimos tickets esperados"
                            v-model="item.valor"
                            @keypress="isNumber($event)"
                          >
                          </base-input>
                          <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar Segmento</base-button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                </template>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from "axios";
  import { useToast } from "vue-toastification";
  import Notification from "@/components/Notification";
  import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
  import { ElSelect, ElOption, ElForm, ElFormItem} from "element-plus";
  import DashboardNavbar from "../Layout/DashboardNavbar";
  export default {
    components: {
      DashboardNavbar,
      RouteBreadCrumb,
      [ElSelect.name]: ElSelect,
      [ElOption.name]: ElOption,
      [ElForm.name]: ElForm,
      [ElFormItem.name]: ElFormItem,
    },
    created(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const urlVentasTiket = process.env.VUE_APP_API_URL+"/parametro/lista/ventas_ticket_esperado"
      const getVentasTiket = axios.get(urlVentasTiket,config)
      const urlNumeroTiket = process.env.VUE_APP_API_URL+"/parametro/lista/numero_tickets_esperado"
      const getNumeroTiket = axios.get(urlNumeroTiket,config)
      const urlPorcentajeSegmento = process.env.VUE_APP_API_URL+"/parametro/segmento"
      const getPorcentajeSegmento = axios.get(urlPorcentajeSegmento,config)
      axios.all([getVentasTiket,getNumeroTiket,getPorcentajeSegmento]).then(axios.spread((...responses) => {
        const resVentaTicket = responses[0].data[0]
        $this.ventas_ticket_esperado = resVentaTicket
        const resNumeroTicket = responses[1].data[0]
        $this.numero_tickets_esperado = resNumeroTicket
        const resPorcentajeSegmento = responses[2].data
        $this.segmentoTicket = resPorcentajeSegmento
        console.log({resPorcentajeSegmento})
        setTimeout(() => {
          const getFormsLaive = document.querySelectorAll(".form_laive")
          getFormsLaive.forEach(element => {
            element.classList.remove("validateClient")
          });
        }, 350);
      })).catch(errors => {
        const getFormsLaive = document.querySelectorAll(".form_laive")
        getFormsLaive.forEach(element => {
          element.classList.remove("validateClient")
        });
      })
    },
    methods: {
      isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault();;
        } else {
          return true;
        }
      },
      submitVentasTicket(evn) {
        const $parent = evn.target.parentNode
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        const dataSend = this.ventas_ticket_esperado
        const urlApiUpdated = process.env.VUE_APP_API_URL+"/parametro/actualizar"
        $parent.classList.add("validateClient")
        axios.put(urlApiUpdated,dataSend,config)
        .then(function (response){
          $parent.classList.remove("validateClient")
          const text =(response.status == 200) ? "Se actualizó el N° de Ventas" : "Hubo un error";
          const icon ="ni ni-bell-55";
          const content={
            component: Notification,
            props: {
              icon: icon,
              text: text,
              type: (response.status == 200) ? "success" : "danger",
            },
          };
          const toast = useToast();
          toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
        }).catch(function(error){
          console.log({error});
        })
      },
      submitNumeroTicket(evn) {
        const $parent = evn.target.parentNode
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        const dataSend = this.numero_tickets_esperado
        const urlApiUpdated = process.env.VUE_APP_API_URL+"/parametro/actualizar"
        $parent.classList.add("validateClient")
        axios.put(urlApiUpdated,dataSend,config)
        .then(function (response){
          $parent.classList.remove("validateClient")
          const text =(response.status == 200) ? "Se actualizó el N° de Ventas" : "Hubo un error";
          const icon ="ni ni-bell-55";
          const content={
            component: Notification,
            props: {
              icon: icon,
              text: text,
              type: (response.status == 200) ? "success" : "danger",
            },
          };
          const toast = useToast();
          toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
        }).catch(function(error){
          console.log({error});
        })
      },
      submitSegmento(evn){
        const keyArray = evn.target.getAttribute("data-key")
        const $parent = evn.target.parentNode
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        const dataSend = this.segmentoTicket[keyArray]
        const urlApiUpdated = process.env.VUE_APP_API_URL+"/parametro/segmento/guardar"
        $parent.classList.add("validateClient")
        axios.post(urlApiUpdated,dataSend,config)
        .then(function (response){
          $parent.classList.remove("validateClient")
          const text =(response.status == 200) ? "Se actualizó el Segmento" : "Hubo un error";
          const icon ="ni ni-bell-55";
          const content={
            component: Notification,
            props: {
              icon: icon,
              text: text,
              type: (response.status == 200) ? "success" : "danger",
            },
          };
          const toast = useToast();
          toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
        }).catch(function(error){
          console.log({error});
        })
      }
    },
    data() {
      return {
        ventas_ticket_esperado: {
          idParametro: "",
          codigoParametro: "",
          valor: ""
        },
        numero_tickets_esperado:{
          idParametro: "",
          codigoParametro: "",
          valor: ""
        },
        segmentoTicket: []
      };
    },
  };
</script>

