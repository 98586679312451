<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template v-slot:header>
            <div class="row">
              <div class="col-12 col-md-6">
                <h3 class="mb-0">Objetivo mensual de Crecimiento por Categoria</h3>
              </div>
              <div class="col-12 col-md-6 text-right">
                <button type="button" class="btn base-button btn-primary btn-sm" role="button" @click="exportDepositos"><i class="fas fa-file-export"></i>Exportar</button>
              </div>
            </div>
          </template>
          <div>
            <div class="row px-3">
              <div class="col-12 col-md-10 mb-3">
                <div class="row">
                  <div class="col-12 col-md-3 ">
                    <template v-if="distribuidoraIdCurrent">
                      <div class="title_filter mr-2"><div>Distribuidora:</div>  <strong>{{distribuidoraNameCurrent}}</strong></div>
                    </template>
                    <template v-else>
                      <span class="title_filter mr-2">Distribuidoras:  </span>
                      <select class="form-control" style="display: inline-block;height: 43px;" name="distributor_select" @change="changeListbyDistribuidora">
                        <option value="0">Todos</option>
                        <option
                          v-for="item in distribuidoras"
                          :value="item.idDistribuidora"
                          :key="item.idDistribuidora"
                          :selected="item.idDistribuidora == parameters.distribuidora"
                          >
                          {{ item.razonSocial }}
                        </option>
                      </select>
                    </template>
                  </div>
                  <div class="col-12 col-md-3 ">
                    <span class="title_filter mr-2">Mesa:  </span>
                    <select class="form-control" style="display: inline-block;height: 43px;" name="mesa_select">
                      <option value="0">Todos</option>
                      <option
                          v-for="item in mesas"
                          :value="item.codigoDato"
                          :key="item.codigoDato"
                          >
                          {{ item.nombre }}
                        </option>
                    </select>
                  </div>
                  <div class="col-12 col-md-3 ">
                    <span class="title_filter mr-2">Meses:  </span>
                    <select class="form-control" style="display: inline-block;height: 43px;" name="order_select" @change="changeOrder">
                      <option value="" disabled style="display:none" selected>Seleccionar</option>
                      <option
                          v-for="item in meses"
                          :value="item"
                          :key="item"
                          >
                          {{ item }}
                        </option>
                    </select>
                  </div>
                  <div class="col-12 col-md-3 ">
                    <span class="title_filter mr-2">Ordenar por:  </span>
                    <select class="form-control" style="display: inline-block;height: 43px;" name="order_select" @change="changeOrder">
                      <option value="" disabled style="display:none" selected>Seleccionar</option>
                      <option value="1">Ordenar de mayor a menor</option>
                      <option value="2">Ordenar de menor a mayor</option>
                    </select>
                  </div>

                </div>
              </div>

            </div>
            <!-- Real Table -->
            <el-table
              :data="queriedData"
              row-key="idProducto"
              header-row-class-name="thead-light"
              :empty-text="emptyText"
            >
              <el-table-column label="Categoria" min-width="270px" prop="categoria">
                <template v-slot="{ row }">
                  {{ row.categoria }}
                </template>
              </el-table-column>
              <el-table-column label="Venta mes Anterior" min-width="160px" prop="mesanterior">
                <template v-slot="{ row }">
                  S/ {{ row.mesanterior }}
                </template>
              </el-table-column>
              <el-table-column label="Venta mes Actual" min-width="160px" prop="mesactual">
                <template v-slot="{ row }">
                  S/ {{ row.mesactual }}
                </template>
              </el-table-column>
              <el-table-column label="Crecimiento" min-width="160px" prop="crecimiento">
                <template v-slot="{ row }">
                  S/ {{ row.crecimiento }}
                </template>
              </el-table-column>
              <el-table-column label="Estado" min-width="170px" prop="estado">
                <template v-slot="{row}">
                  <template v-if="row.estado == 1">
                    <div class="circle_state green"></div>
                  </template>
                  <template v-else-if="row.estado == 2">
                    <div class="circle_state yellow"></div>
                  </template>
                  <template v-else-if="row.estado == 3">
                    <div class="circle_state red"></div>
                  </template>
                </template>
              </el-table-column>
              <el-table-column min-width="200px" align="right">
                <template v-slot:default="props">
                  <div class="d-flex">
                    <base-button type="info" tag="a" class="edit" size="sm" role="button" :data-index="props.$index" aria-pressed="true">Detalle</base-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <template v-slot:footer>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category d-none">
                  Mostrando {{ from + 1 }} a {{ to }} de {{ total }} Depositos
                </p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.newcurrentPage"
                :per-page="pagination.perPage"
                :total="total"
                @click="changePaginate"
              >
              </base-pagination>
            </div>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import axios from "axios";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import TabPane from "@/components/Tabs/TabPane";
import BasePagination from "@/components/BasePagination";
import {mapGetters} from 'vuex';
import swal from "sweetalert2";
function mayorToMenor( a, b ) {
  if (a.proyeccion < b.proyeccion) {
    return 1;
  }
  if (a.proyeccion > b.proyeccion) {
    return -1;
  }
  // a must be equal to b
  return 0;
}
function menorToMayor( a, b ) {
  if (a.proyeccion > b.proyeccion) {
    return 1;
  }
  if (a.proyeccion < b.proyeccion) {
    return -1;
  }
  // a must be equal to b
  return 0;
}
export default {
  components: {
    BasePagination,
    RouteBreadCrumb,
    TabPane,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  computed: {
    pagedData() {
      this.getDistributor
      this.getMesas
      //this.getDataDepositos
      return this.tableData
    },
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString().toLowerCase();
          if (rowValue.includes && rowValue.includes(this.searchQuery.toLowerCase())) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.pagination.total;
    },
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    }),
    async getDistributor(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let urlApiDistribuidoras = process.env.VUE_APP_API_URL+"/distribuidoras/lista"
      const requestDistribuidoras = axios.get(urlApiDistribuidoras, config);
      await axios.all([requestDistribuidoras]).then(axios.spread((...responses) => {
        const responseDistribuidoras = responses[0].data
        if($this.distribuidoraIdCurrent){
          let dataDistibuitorCurrent =  responseDistribuidoras.filter((item)=>{return item.idDistribuidora == $this.distribuidoraIdCurrent})
          $this.distribuidoraNameCurrent = dataDistibuitorCurrent[0].razonSocial
        }
        $this.distribuidoras = responseDistribuidoras.filter((item)=>{return item.estado == "1"})
        return $this.distribuidoras;
      })).catch(errors => {
        // react on errors.
      })
    },
    async getMesas(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const urlApiFechas = process.env.VUE_APP_API_URL+"/maestro/lista/mesa"
      if($this.mesas.length == 0){
        await axios.get(urlApiFechas,config)
        .then(function (response){
          console.log(response.data, " ====lista de mesas");
          return $this.mesas = response.data
        })
      }
    },
    getDataDepositos() {
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let getParams = this.$route.query
      let idDistribuidora = ""
      let idSupervisor = ""
      let idVendedor = ""
      if(Object.keys(getParams).length && $this.initload){
        idDistribuidora = Number(getParams.distribuidora)
        idSupervisor = getParams.supervisor
        idVendedor = getParams.vendedor
        this.parameters.distribuidora = idDistribuidora
        this.parameters.supervisor = idSupervisor
        this.parameters.vendedor = idVendedor
        const parameters = {
          "iddistribuidora": idDistribuidora,
            "codigosupervisor": idSupervisor,
            "codigovendedor": idVendedor,
            "periodo": "0",
            "page": 1,
        }
        axios.post(process.env.VUE_APP_API_URL+"/deposito",parameters,config)
        .then(function (response){
          console.log(response.data, " ====lista de depositos");
          document.querySelector(".form_laive").classList.remove("validateClient")
          $this.emptyText = "No se encontraron resultados"
          $this.pagination.perPage = response.data.data.length
          $this.pagination.total = response.data.totalPage
          return $this.tableData = response.data.data
        })
      }
    },
  },
  methods:{
    changeListbyDistribuidora(evn){
      const $this = this
      let selected = Number(evn.target.value)
      if (selected == 0) document.querySelector("select[name='supervisor_select']").value = ""
      let idSupervisor = document.querySelector("select[name='supervisor_select']").value
      let idVendedor = document.querySelector("select[name='vendedor_select']").value
      let idPeriodo = document.querySelector("select[name='fecha_select']").value
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const parameters = {
          "page": 1,
          "iddistribuidora": selected,
          "codigosupervisor": idSupervisor,
          "codigovendedor": idVendedor,
          "periodo": idPeriodo
      }
      document.querySelector(".form_laive").classList.add("validateClient")
      const urlApiSupervisores = process.env.VUE_APP_API_URL+`/supervisor/lista/${selected}`
      const urlApiDepositos = process.env.VUE_APP_API_URL+`/deposito`
      const dataDepositos = axios.post(urlApiDepositos,parameters,config)
      const getSupervisores = axios.get(urlApiSupervisores,config)
      axios.all([dataDepositos, getSupervisores]).then(axios.spread((...responses) => {
        const resDataByDistribuidora = responses[0].data
        const resListSupervisores = responses[1].data
        $this.tableData = resDataByDistribuidora.data
        $this.pagination.perPage = resDataByDistribuidora.data.length
        $this.pagination.total = resDataByDistribuidora.totalPage
        $this.supervisores = resListSupervisores
        document.querySelector(".form_laive").classList.remove("validateClient")
      })).catch(errors => {
        // react on errors.
      })
    },
    changePaginate(evn){
      const $this = this
      const listPaginator = document.querySelectorAll("ul.pagination .page-item")
      let idDistribuidora = (this.distribuidoraIdCurrent) ? this.distribuidoraIdCurrent : Number(document.querySelector("select[name='distributor_select']").value)
      let idSupervisor = ($this.supervisorIdCurrent) ? $this.supervisorIdCurrent : document.querySelector("select[name='supervisor_select']").value
      let idVendedor = document.querySelector("select[name='vendedor_select']").value
      let idPeriodo = document.querySelector("select[name='fecha_select']").value
      let elm = evn.target;
      let sendAjax = false;
      let parentEl = elm.parentNode
      if(elm.classList.contains("prev-page")){
        if(!elm.classList.contains("disabled")){
          let linkActivePrev = document.querySelectorAll("ul.pagination .page-item.active")
          linkActivePrev[0].classList.remove("active")
          linkActivePrev[0].previousSibling.classList.add("active")
          sendAjax = true
        }
      }else if(elm.classList.contains("next-page")){
      }else{
        if(elm.getAttribute("aria-label") == "Previous"){
          let linkActivePrev = document.querySelectorAll("ul.pagination .page-item.active")
          linkActivePrev[0].classList.remove("active")
          linkActivePrev[0].previousSibling.classList.add("active")
          sendAjax = true
        }else if(elm.getAttribute("aria-label") == "Next"){
          let linkActiveNext = document.querySelectorAll("ul.pagination .page-item.active")
            linkActiveNext[0].classList.remove("active")
            linkActiveNext[0].nextSibling.classList.add("active")
            sendAjax = true
        }else{
          if(!parentEl.classList.contains("active")){
            listPaginator.forEach(item => {
              item.classList.remove("active")
            });
            parentEl.classList.add("active")
            sendAjax = true
          }
        }
      }
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const parameters = {
          "page": this.pagination.newcurrentPage,
          "iddistribuidora": idDistribuidora,
          "codigosupervisor": idSupervisor,
          "codigovendedor": idVendedor,
          "periodo": idPeriodo
      }
      if(sendAjax){
        document.querySelector(".form_laive").classList.add("validateClient")
        axios.post(process.env.VUE_APP_API_URL+"/deposito",parameters,config)
        .then(function (response){
          document.querySelector(".form_laive").classList.remove("validateClient")
          console.log(response.data.data)
          sendAjax = false
          $this.pagination.currentPage = 1
          return $this.tableData = response.data.data
        })
      }
    },
    changeOrder(evn){
      let getDataTable = this.tableData;
      if(evn.target.value == 1){
        getDataTable.sort(mayorToMenor)
      }else if(evn.target.value == 2){
        getDataTable.sort(menorToMayor)
      }
      this.tableData = getDataTable
    },
    exportDepositos(){
      let idDistribuidora = (this.distribuidoraIdCurrent) ? this.distribuidoraIdCurrent : Number(document.querySelector("select[name='distributor_select']").value)
      let idSupervisor = (this.supervisorIdCurrent) ? this.supervisorIdCurrent : document.querySelector("select[name='supervisor_select']").value
      let idVendedor = document.querySelector("select[name='vendedor_select']").value
      let idPeriodo = document.querySelector("select[name='fecha_select']").value
      const token = localStorage.getItem("token")
      const config = {
        responseType: 'blob',
        headers: {
          "Authorization": `Bearer ${token}`,
        }
      };
      const parameters = {
        "iddistribuidora": idDistribuidora,
        "codigosupervisor": idSupervisor,
        "codigovendedor": idVendedor,
        "periodo": idPeriodo,
      }
      console.log(parameters, " selectt export");
      document.querySelector(".form_laive").classList.add("validateClient")
      axios.post(process.env.VUE_APP_API_URL+"/deposito/exportar",parameters,config)
      .then(function (response){
        document.querySelector(".form_laive").classList.remove("validateClient")
        const url = window.URL.createObjectURL(new Blob([response.data], {type:'application/vnd.ms-excel'}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download',`depositos-${Date.now()}.csv`);
        document.body.appendChild(link);
        link.click();
      })
    }
  },
  mounted() {
    if(this.user.rol == 3){
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
    if(this.user.rol == 4){
      this.supervisorIdCurrent = this.user.codigo
      this.supervisorNameCurrent = this.user.nombre
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
  },
  data() {
    return {
      initload: true,
      distribuidoraIdCurrent: null,
      distribuidoraNameCurrent: null,
      supervisorIdCurrent: null,
      supervisorNameCurrent: null,
      emptyText: "Debe Selecionar una distribuidora",
      pagination: {
        perPage: 2,
        currentPage: 1,
        newcurrentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 1,
      },
      parameters:{
        distribuidora: "",
        supervisor: "",
        vendedor: ""
      },
      meses:["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio",
            "Agosto","Setiembre","Octubre","Noviembre","Diciembre"],
      distribuidoras:[],
      supervisores:[],
      vendedores:[],
      mesas:[],
      fechas:[],
      fechaSelected: "1",
      searchQuery: "",
      propsToSearch: ["cliente", "codigo"],
      tableData: [
        {
          categoria: "Categoria 001",
          real: "1300",
          mesanterior: "2245",
          avance: "23",
          mesactual: "2133",
          crecimiento: "11",
          necesidad: "466",
          subtotal: "600",
          total: "760",
          fecha: "11/07/2021 10:49 PM",
          estado: 1
        },
        {
          categoria: "Categoria 001",
          real: "1300",
          mesanterior: "2245",
          avance: "23",
          mesactual: "1133",
          crecimiento: "11",
          necesidad: "466",
          subtotal: "600",
          total: "760",
          fecha: "11/07/2021 10:49 PM",
          estado: 2
        },
        {
          categoria: "Categoria 001",
          real: "1300",
          mesanterior: "2245",
          avance: "23",
          mesactual: "4133",
          crecimiento: "11",
          necesidad: "466",
          subtotal: "600",
          total: "760",
          fecha: "11/07/2021 10:49 PM",
          estado: 2
        },
        {
          categoria: "Categoria 004",
          real: "1300",
          mesanterior: "2245",
          avance: "23",
          mesactual: "4170",
          crecimiento: "11",
          necesidad: "466",
          subtotal: "600",
          total: "760",
          fecha: "11/07/2021 10:49 PM",
          estado: 3
        }
      ],
      fuseSearch: null,
      searchedData: [],
      expands: [],
    };
  },
};
</script>
<style lang="scss">
  .swal-wide{
    .swal2-popup{
      width: 52.5em;
    }
  }
  .page-item .page-link .fa{
    z-index: 0;
  }
</style>
