<template>
  <div
    class="form_laive"
    :class="[loading ? 'validateClient' : '', containerClass]"
    :style="{ minHeight }"
  >
    <div class="box_loader" ref="loader" tabindex="0">
      <div class="loader"></div>
    </div>
    <slot v-if="show"></slot>
  </div>
</template>
<script>
export default {
  props: {
    loading: Boolean,
    hiddenInLoading: {
      type: Boolean,
      default: false,
    },
    containerClass: [String, Object],
    minHeight: String,
  },
  computed: {
    show() {
      if (this.hiddenInLoading) return !this.loading;
      return true;
    },
  },
  watch: {
    loading: {
      handler(val) {
        if (val) {
          this.$nextTick(() => this.$refs.loader.focus());
        }
      },
    },
  },
};
</script>
<style scoped>
.box-loader {
  outline: 0;
  pointer-events: auto;
}
</style>
