<template>
  <form ref="dropZoneForm">
    <div @dragenter.prevent="toggleActive" @dragleave.prevent="toggleActive" @dragover.prevent
      @drop.prevent="uploadFileByDrop" :class="{ 'active-dropzone': active, 'active-click': allowClickContainer }"
      class="dropzone" @change="uploadFileBySelect" @click="openFiles()">

      <div v-show="!hasFile" class="documento">

        <div class="documento-preview mb-2">
          <img src="/img/shared/file.svg" alt="Formato permitido" /><br>
        </div>

        <div class="info-text">
          <span class="s-upload-format mt-0 mb-1">Formato permitido .xlsx</span>
          <u class="u-upload">{{ customText ? customText : 'Para carga masiva selecciona un archivo para subir' }}</u>
          <span class="span-upload">Arrastra y suelta aquí</span>
        </div>

        <div class="info-text mt-2" v-if="showButton">
          <button class="th-btn-pry th-btn" @click.prevent.stop="openFiles()">
            Seleccionar archivo
          </button>
        </div>
        <input type="file" id="dropzoneFile" class="dropzoneFile" :multiple="multiple" ref="import-file-input"
          :accept="typeFiles" />

      </div>

      <div v-show="hasFile" class="documento adjuntado">

        <div class="documento-preview mb-2">
          <img src="/img/shared/file.svg" alt="Formato permitido" /><br>
        </div>

        <div class="info-text">
          <span class="s-upload-format mt-0 mb-1">Formato permitido .xlsx</span>
          <u class="u-upload">{{ customText ? customText : 'Para carga masiva selecciona un archivo para subir' }}</u>
          <span class="span-upload">o arrastra y suelta aquí</span>
        </div>

        <div class="preview-container mt-4" v-for="file in files" :key="file.name">
          <div>
            <!-- <img class="preview-img" :src="generateThumbnail(file)" alt="preview image" /> -->
            <p :title="file.name">
              {{ makeName(file.name) }}
            </p>
          </div>
        </div>

        <!--<div class="info-text">
          <h4 v-if="multiple">{{ dropzoneFileSize == 1 ? dropzoneFileSize + ' archivo' : dropzoneFileSize + ' archivos seleccionados' }}</h4>
          <h4 v-else>{{ dropzoneFile.name }}</h4>
        </div>-->

        <!--<label for="dropzoneFile">cambiar archivo</label>
        <input type="file" id="dropzoneFile" class="dropzoneFile" :multiple="multiple"/>-->

      </div>

    </div>
  </form>
</template>

<script>
export default {
  name: "DropZone",
  expose: ["files"],
  props: {
    showButton: {
      type: Boolean,
      default: true
    },
    allowClickContainer: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    typeFiles: {
      type: String,
      default: ""
    },
    clearDropzone: {
      type: Boolean,
      default: false,
      prop: true,
    },
    customText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      files: [],
      dropzoneFile: '',
      dropzoneFileSize: '',
      active: false,
      hasFile: false
    }
  },
  watch: {
    clearDropzone(val) {
      if (val === true) {
        this.clearDropzoneHandler();
      }
    },
  },
  methods: {
    openFiles() {
      if (this.allowClickContainer) {
        this.$refs["import-file-input"]?.click();
      }
    },
    toggleActive() {
      this.active = !this.active;
    },
    makeName(name) {
      return (
        name.split(".")[0] +
        "." +
        name.split(".")[name.split(".").length - 1]
      );
    },
    uploadFileByDrop(e) {

      this.files = e.dataTransfer.files;

      if (this.multiple) {
        this.dropzoneFile = e.dataTransfer.files;
        this.dropzoneFileSize = e.dataTransfer.files.length;
      } else {
        this.dropzoneFile = e.dataTransfer.files[0];
      }

      this.hasFile = true;
      this.active = false;

    },
    uploadFileBySelect() {

      this.files = document.querySelector(".dropzoneFile").files;

      if (this.multiple) {
        this.dropzoneFile = document.querySelector(".dropzoneFile").files;
        this.dropzoneFileSize = document.querySelector(".dropzoneFile").files.length;
      } else {
        this.dropzoneFile = document.querySelector(".dropzoneFile").files[0];
      }

      this.hasFile = true;
      this.active = false;

    },
    clearDropzoneHandler() {
      console.log('limpiar componente');
      this.$refs.dropZoneForm.reset();
      this.hasFile = false;
    },
  },
  created() {

  }
};
</script>

<style lang="scss">
@import "@/assets/sass/laive-components/icons.scss";

.active-click {
  cursor: pointer;
}
</style>
