<template>
  <component :is="as" class="ui-box-info" :style="[cssProps]">
    <component :is="titleAs" class="ui-box-info-header" :class="[titleClass]">
      {{ title }}
    </component>
    <slot>
      <component :is="contentAs" class="ui-box-info-content" :class="[contentClass]">
        {{ content }}
      </component>
    </slot>
  </component>
</template>
  
<script>
import { PropsHandler } from './utils';

export default {
  props: {
    as: {
      type: String,
      default: "div",
      validator(typ) {
        return ["div", "li", "section", "aside"].includes(typ);
      }
    },
    titleAs: {
      type: String,
      default: "span",
      validator(typ) {
        return ["h2", "h3", "h4", "h5", "span"].includes(typ);
      }
    },
    contentAs: {
      type: String,
      default: "div",
      validator(typ) {
        return ["div", "p", "span"].includes(typ);
      }
    },
    title: String,
    content: String,
    titleClass: String,
    contentClass: String,
    // Computable styles
    spacing: String,
    titleFw: String,
    titleFs: String,
    titleColor: String,
    contentFw: String,
    contentFs: String,
    contentColor: String,
  },
  computed: {
    cssProps() {
      return {
        "--ui-boxinfo-space": PropsHandler.size(this.spacing ?? "0.5em"),
        "--ui-boxinfo-title-fw": PropsHandler.fontWeight(this.titleFw ?? "500"),
        "--ui-boxinfo-title-fs": PropsHandler.fontSize(this.titleFs ?? "1rem"),
        "--ui-boxinfo-title-color": PropsHandler.color(this.titleColor ?? "black"),
        "--ui-boxinfo-content-fw": PropsHandler.fontWeight(this.contentFw ?? "300"),
        "--ui-boxinfo-content-fs": PropsHandler.fontSize(this.contentFs ?? "0.750rem"),
        "--ui-boxinfo-content-color": PropsHandler.color(this.contentColor ?? "black"),
      }
    }
  }
}

</script>

<style scoped>
.ui-box-info-header {
  margin-bottom: var(--ui-boxinfo-space);
  font-weight: var(--ui-boxinfo-title-fw);
  font-size: var(--ui-boxinfo-title-fs);
  color: var(--ui-boxinfo-title-color);
}

.ui-box-info-content {
  font-weight: var(--ui-boxinfo-content-fw);
  font-size: var(--ui-boxinfo-content-fs);
  color: var(--ui-boxinfo-content-color);
}
</style>
