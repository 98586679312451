import setting from './setting';
export default {
    getTipoPregunta: ($axios,data)=>{
        return $axios.get(`${setting.http.base_url}/maestro/lista/tipopregunta`, {headers:setting.http.headers()});
    },

    getDataPreguntas: ($axios,data)=>{
        return $axios.get(`${setting.http.base_url}/estudio/data-preguntas/${data}`, {headers:setting.http.headers()});
    },

    setDataPreguntas: ($axios,data)=>{
        return $axios.post(`${setting.http.base_url}/estudio/actualizar/pregunta`,data, {headers:setting.http.headers()});
    },
    insDataPreguntas: ($axios,data)=>{
        return $axios.post(`${setting.http.base_url}/estudio/publicar`,data, {headers:setting.http.headers()});
    },

}