
import setting from './setting';
export default {
    getList: ($axios,data)=>{
        return $axios.get(`${setting.http.base_url}/maestro/lista/mesa`,{headers:setting.http.headers()});
    },

    getListByDistribuidora: ($axios,data)=>{
        return $axios.post(`${setting.http.base_url}/maestro/lista/pordistribuidora`,data,{headers:setting.http.headers()});
    }
}