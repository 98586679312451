<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive validateClient">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template v-slot:header>
            <div class="row">
              <div class="col-12 col-md-6">
                <h3 class="mb-0">Listado de Maestros</h3>
              </div>
            </div>
          </template>
          <div>
            <div class="row px-2 list_access">
              <template v-for="item in tableData" :key="item.idRegistro">
                <div class="col-md-3">
                  <router-link :to="{name:'Editar Maestro', params: {id:item.codigoDato}, query:{type:'default', name:item.nombre}}">
                    <stats-card
                      :title="item.nombre"
                      type="gradient-laive"
                      sub-title=""
                      icon="fas fa-arrow-right"
                    >
                    </stats-card>
                  </router-link>
                </div>
              </template>
              <template v-if="tableData.length">
                <div class="col-md-3" v-for="item in aditionalMaestro" :key="item.codigoDato">
                  <router-link :to="{name:'Editar Maestro', params: {id:item.codigoDato}, query:{type:item.type, name:item.nombre}}">
                    <stats-card
                      :title="item.nombre"
                      type="gradient-laive"
                      sub-title=""
                      icon="fas fa-arrow-right"
                    >
                    </stats-card>
                  </router-link>
                </div>
              </template>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BasePagination from "@/components/BasePagination";
import DashboardNavbar from "../Layout/DashboardNavbar";
export default {
  components: {
    DashboardNavbar,
    BasePagination,
    RouteBreadCrumb,
  },
  created(){
    const $this = this
    const token = localStorage.getItem("token")
    const config = {
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    };
    axios.get(process.env.VUE_APP_API_URL+`/maestro/lista/maestro `,config)
    .then(function (response){
      const data = response.data
      document.querySelector(".form_laive").classList.remove("validateClient")
      return $this.tableData = data
    })
  },
  methods: {
  },
  data() {
    return {
      tableData: [],
      aditionalMaestro: [
        {
          codigoDato: "ubigeo",
          codigoTipo: "maestro",
          nombre: "Ubigeo",
          type: "ubigeo"
        },
        {
          codigoDato: "mercados",
          codigoTipo: "maestro",
          nombre: "Mercados",
          type: "mercados"
        }
      ],
    };
  },
};
</script>
