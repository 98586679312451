<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive" :class="{ 'validateClient' : isLoading}">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template v-slot:header>
            <div class="row">
              <div class="col-12 col-md-6">
                <h3 class="mb-0">Productos Recomendados</h3>
              </div>
              <div class="col-12 col-md-6 text-right">
                <button type="button" class="btn base-button btn-primary btn-sm" role="button" @click="exportReport"><i class="fas fa-file-export"></i>Exportar</button>
              </div>
            </div>
          </template>
          <div>
            <div class="row px-4">
              <div class="col-12 col-md-10 mb-3">
                <div class="row">
                  <div class="col-12 col-md-3 ">
                    <template v-if="distribuidoraIdCurrent">
                      <div class="title_filter mr-2"><div>Distribuidora:</div>  <strong>{{distribuidoraNameCurrent}}</strong></div>
                    </template>
                    <template v-else>
                      <span class="title_filter mr-2">Distribuidoras:  </span>
                      <select v-model="dataReport.iddistribuidora" class="form-control" name="distributor_select" @change="changeListbyDistribuidora">
                        <option value="0">Todos</option>
                        <option
                          v-for="item in distribuidoras"
                          :value="item.idDistribuidora"
                          :key="item.idDistribuidora"
                          >
                          {{ item.razonSocial }}
                        </option>
                      </select>
                    </template>
                  </div>
                  <div class="col-12 col-md-3">
                    <template v-if="mesaCurrent">
                      <div class="title_filter mr-2"><div>Mesa:</div>  <strong>{{mesaCurrent}}</strong></div>
                    </template>
                    <template v-else>
                      <span class="title_filter mr-2">Mesa:  </span>
                      <select v-model="dataReport.mesa" class="form-control" name="mesa_select" @change="changeListbyMesa">
                        <option value="">Todos</option>
                        <option
                            v-for="item in mesas"
                            :value="item.codigoDato"
                            :key="item.codigoDato"
                            >
                            {{ item.nombre }}
                          </option>
                      </select>
                    </template>
                  </div>
                  <div class="col-12 col-md-3">
                    <span class="title_filter mr-2">Vendedor:  </span>
                    <select v-model="dataReport.codigovendedor" class="form-control" id="vendedor_select" name="vendedor_select" @change="changeListbyVendedor">
                      <option value="">Todos</option>
                      <option
                        v-for="item in vendedores"
                        :value="item.codigoVendedor"
                        :key="item.codigoVendedor+item.distribuidora"
                        >
                        {{ item.nombre }} {{ item.apellidos }}
                      </option>
                    </select>
                  </div>
                  <div class="col-12 col-md-3">
                    <span class="title_filter mr-2">Cod. Cliente:  </span>
                    <input type="text" class="form-control" v-model="dataReport.codigocliente" v-on:keyup.enter="changeListByCode"/>
                  </div>
                </div>
              </div>

            </div>
            <!-- Real Table -->
            <el-table
              :data="queriedData"
              row-key="idReportPromo"
              empty-text="Debe seleccionar una Distibuidora"
              header-row-class-name="thead-light"
              style="width: 100%"
            >
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                v-bind="column"
              >
              </el-table-column>
            </el-table>
          </div>
          <template v-slot:footer>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div class="">
                <p class="card-category d-none">
                  Mostrando {{ from + 1 }} a {{ to }} de {{ total }} Depositos
                </p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="dataReport.page"
                :per-page="pagination.perPage"
                :total="total"
                @click="changePaginate"
              >
              </base-pagination>
            </div>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import axios from "axios";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import TabPane from "@/components/Tabs/TabPane";
import BasePagination from "@/components/BasePagination";
import {mapGetters, mapActions} from 'vuex';
import DashboardNavbar from "./../Layout/DashboardNavbar";
export default {
  components: {
    DashboardNavbar,
    BasePagination,
    RouteBreadCrumb,
    TabPane,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  computed: {
    pagedData() {
      this.getDistributor
      this.getVendedor
      this.getMesas
      this.getDataRecomendados
      return this.tableData
    },
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString().toLowerCase();
          if (rowValue.includes && rowValue.includes(this.searchQuery.toLowerCase())) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.pagination.total;
    },
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    }),
    async getDistributor(){
      try{
        let result = await this.getDistributorList();
        this.distribuidoras = result.data ? result.data.filter((item) => { return item.estado == "1" }) : [];
        if(this.distribuidoraIdCurrent){
          let dataDistibuitorCurrent =  this.distribuidoras.filter((item)=>{return item.idDistribuidora == this.distribuidoraIdCurrent})
          this.distribuidoraNameCurrent = dataDistibuitorCurrent[0].razonSocial
        }
      }
      catch(ex){ex}
    },
    async getMesas(){
      if(this.distribuidoraIdCurrent){
        let result = await this.getMesaByDistribuidora(this.dataReport);
        this.mesas = result.data ? result.data : [];
      }else{
        console.info("no hay id de distribuidora para mesas")
      }
    },
    async getVendedor(){
      if(this.supervisorIdCurrent){
        this.dataReport.codigosupervisor = this.supervisorIdCurrent
        this.dataReport.codigomesa = this.mesaCurrent
        let result = await this.getVendedorByMesa(this.dataReport);
        this.vendedores = result.data ? result.data : [];
      }else{
        console.info("no hay id de supervisor")
      }
    },
    getDataRecomendados() {
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      if(this.dataReport.year && this.dataReport.month && this.initload){
        if(this.distribuidoraIdCurrent){
          this.dataReport.iddistribuidora = Number(this.distribuidoraIdCurrent)
        }
        axios.post(process.env.VUE_APP_API_URL+"/producto/recomendado/lista",this.dataReport,config)
        .then(function (response){
          document.querySelector(".form_laive").classList.remove("validateClient")
          $this.emptyText = "No se encontraron resultados"
          $this.pagination.perPage = response.data.data.length
          $this.pagination.total = response.data.totalPage
          $this.initload = false
          $this.isLoading = false
          return $this.tableData = response.data.data
        })
      }
    },
  },
  methods:{
    ...mapActions({
        getDistributorList: 'distribuidora/getList',
        getMesaByDistribuidora: "mesa/getListByDistribuidora",
        getVendedorByMesa:'vendedor/getListByMesa',
    }),
    changeListbyDistribuidora(evn){
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      this.isLoading = true
      this.dataReport.page = 1

      this.getMesaByDistribuidora(this.dataReport).then((result) => {
        $this.mesas = result.data ? result.data : [];
      });

      this.dataReport.codigomesa = this.dataReport.mesa
      this.getVendedorByMesa(this.dataReport).then((result) => {
        $this.vendedores = result.data ? result.data : [];
      });

      this.resetPagination(this.dataReport.page);
      axios.post(process.env.VUE_APP_API_URL+"/producto/recomendado/lista",this.dataReport,config)
      .then(function (response){
        console.log(response.data, " ====lista de reporte");
        $this.emptyText = "No se encontraron resultados"
        $this.pagination.perPage = response.data.data.length
        $this.pagination.total = response.data.totalPage
        $this.initload = false
        $this.isLoading = false
        return $this.tableData = response.data.data
      }).catch(errors => {
        $this.isLoading = false
      })
    },
    changeListbyMesa(env){
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      this.isLoading = true
      this.dataReport.page = 1
      this.dataReport.codigomesa = this.dataReport.mesa
      this.getVendedorByMesa(this.dataReport).then((result) => {
        $this.vendedores = result.data ? result.data : [];
      });
      this.resetPagination(this.dataReport.page);
      axios.post(process.env.VUE_APP_API_URL+"/producto/recomendado/lista",this.dataReport,config)
      .then(function (response){
        console.log(response.data, " ====lista de reporte");
        $this.emptyText = "No se encontraron resultados"
        $this.pagination.perPage = response.data.data.length
        $this.pagination.total = response.data.totalPage
        $this.initload = false
        $this.isLoading = false
        return $this.tableData = response.data.data
      }).catch(errors => {
        $this.isLoading = false
      })
    },
    changeListbyVendedor(){
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      this.isLoading = true
      this.dataReport.page = 1
      this.resetPagination(this.dataReport.page);
      axios.post(process.env.VUE_APP_API_URL+"/producto/recomendado/lista",this.dataReport,config)
      .then(function (response){
        console.log(response.data, " ====lista de reporte");
        $this.emptyText = "No se encontraron resultados"
        $this.pagination.perPage = response.data.data.length
        $this.pagination.total = response.data.totalPage
        $this.initload = false
        $this.isLoading = false
        return $this.tableData = response.data.data
      }).catch(errors => {
        $this.isLoading = false
      })
    },
    changeListByCode(){
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      this.isLoading = true
      this.dataReport.page = 1
      this.resetPagination(this.dataReport.page);
      axios.post(process.env.VUE_APP_API_URL+"/producto/recomendado/lista",this.dataReport,config)
      .then(function (response){
        console.log(response.data, " ====lista de reporte");
        $this.emptyText = "No se encontraron resultados"
        $this.pagination.perPage = response.data.data.length
        $this.pagination.total = response.data.totalPage
        $this.initload = false
        $this.isLoading = false
        return $this.tableData = response.data.data
      }).catch(errors => {
        $this.isLoading = false
      })
    },
    changePaginate(evn){
      const $this = this
      const listPaginator = document.querySelectorAll("ul.pagination .page-item")
      let elm = evn.target;
      let sendAjax = false;
      let parentEl = elm.parentNode
      if(elm.classList.contains("prev-page")){
        if(!elm.classList.contains("disabled")){
          let linkActivePrev = document.querySelectorAll("ul.pagination .page-item.active")
          linkActivePrev[0].classList.remove("active")
          linkActivePrev[0].previousSibling.classList.add("active")
          sendAjax = true
        }
      }else if(elm.classList.contains("next-page")){
      }else{
        if(elm.getAttribute("aria-label") == "Previous"){
          let linkActivePrev = document.querySelectorAll("ul.pagination .page-item.active")
          linkActivePrev[0].classList.remove("active")
          linkActivePrev[0].previousSibling.classList.add("active")
          sendAjax = true
        }else if(elm.getAttribute("aria-label") == "Next"){
          let linkActiveNext = document.querySelectorAll("ul.pagination .page-item.active")
            linkActiveNext[0].classList.remove("active")
            linkActiveNext[0].nextSibling.classList.add("active")
            sendAjax = true
        }else{
          if(!parentEl.classList.contains("active")){
            listPaginator.forEach(item => {
              item.classList.remove("active")
            });
            parentEl.classList.add("active")
            sendAjax = true
          }
        }
      }
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      if(sendAjax){
        this.isLoading = true
        axios.post(process.env.VUE_APP_API_URL+"/producto/recomendado/lista",this.dataReport,config)
        .then(function (response){
          console.log(response.data.data)
          sendAjax = false
          $this.dataReport.page = response.data.page
          $this.pagination.perPage = response.data.data.length
          $this.pagination.total = response.data.totalPage
          $this.isLoading = false
          return $this.tableData = response.data.data
        })
      }
    },
    exportReport(){
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
        }
      };
      const urlDomain = process.env.VUE_APP_API_URL.replace("api", "")
      this.isLoading = true
      axios.post(process.env.VUE_APP_API_URL+"/producto/recomendado/exportar",this.dataReport,config)
      .then(function (response){
        const urlFile = urlDomain + response.data.fileName
        const link = document.createElement('a');
        link.href = urlFile;
        console.log({urlFile})
        document.body.appendChild(link);
        link.click();
        $this.isLoading = false
      })
    },
    formatNumber(num) {
      var nf = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      return nf.format(num)
		  // return new Intl.NumberFormat("en-IN").format(num)
	  },
    resetPagination(value){
      const paginate = document.querySelectorAll(".page-item.number")
      let index = value - 1
      paginate.forEach(item => {
        if(item.classList.contains("active")){
          item.classList.remove("active")
        }
      });
      paginate[index].classList.add("active");
    }
  },
  mounted() {
    if(this.user.rol == 3){
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
    if(this.user.rol == 4){
      this.supervisorIdCurrent = this.user.codigo
      this.supervisorNameCurrent = this.user.nombre
      this.distribuidoraIdCurrent = this.user.idDistribuidora
      this.mesaCurrent = this.user.mesa
    }
    let currentDate = new Date();
    let month = currentDate.getMonth() + 1
    this.dataReport.year = currentDate.getFullYear()
    this.dataReport.month = month
  },
  data() {
    return {
      initload: true,
      isLoading: true,
      distribuidoraIdCurrent: null,
      distribuidoraNameCurrent: null,
      supervisorIdCurrent: null,
      supervisorNameCurrent: null,
      mesaCurrent : null,
      emptyText: "Debe Selecionar una distribuidora",
      pagination: {
        perPage: 2,
        currentPage: 1,
        newcurrentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 1,
      },
      dataReport:{
        iddistribuidora: 0,       // Obligatorio
        codigosupervisor: "",     // Opcional
        mesa: "",                 // Opcional
        codigovendedor: "",       // Obligatorio
        codigocliente: "",
        page : 1,
        tipo: "mesas",
        codigomesa: ""
      },
      distribuidoras:[],
      supervisores:[],
      vendedores:[],
      mesas:[],
      searchQuery: "",
      propsToSearch: ["cliente", "codigo"],
      tableColumns: [
        {
          prop: "codigoCliente",
          label: "Cod. Cliente",
          width: 150,
          fixed: true
        },
        {
          prop: "cliente",
          label: "Cliente",
          width: 300,
        },
        {
          prop: "distribuidora",
          label: "Distribuidora",
          width: 160,
        },
        {
          prop: "producto",
          label: "Producto",
          width: 300,
        },
        {
          prop: "tipoProducto",
          label: "Tipo Producto",
          width: 200,
        },
        {
          prop: "grupoVenta",
          label: "Grupo Venta",
          width: 160,
        },
      ],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      expands: [],
    };
  },
};
</script>
