<template>
  <component :is="as" class="ui-grid-item" :style="[cssProps]">
    <slot></slot>
  </component>
</template>

<script>
import { PropsHandler } from '../utils';
export default {
  props: {
    as: {
      type: String,
      default: "div",
      valiadtor(typ) {
        return ["div", "li"].includes(typ);
      }
    },
    rowSpan: Number,
    colSpan: Number,
  },
  computed: {
    cssProps() {
      return {
        ["--ui-grid-item-spancol"]: `span ${this.colSpan}` ?? "span 1",
        ["--ui-grid-item-spanrow"]: `span ${this.rowSpan}` ?? "span 1",
      }
    }
  }
}

</script>


<style scoped>
.ui-grid-item {
  grid-column: var(--ui-grid-item-spancol);
  grid-row: var(--ui-grid-item-spanrow);
}
</style>
