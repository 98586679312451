<template>
  <div v-if="user.rol === 1 || user.rol === 2" class="p-4 th-bg-slate">
    <h2 class="fs-4 text-dark">Carga masiva</h2>
    <box-loader
      containerClass="card shadow-lg p-3"
      :loading="loading"
      :hiddenInLoading="false"
    >
      <Dropzone
        customText="Para realizar la carga selecciona el formato con los equipos agregados"
        :clearDropzone="clearFiles"
        :multiple="false"
        :showButton="false"
        :allowClickContainer="true"
        ref="dropzone"
      />
      <div class="d-flex justify-content-between align-item-center mt-3">
        <a
          class="th-btn th-btn-border-pry text-sm align-items-center d-flex flex-row"
          href="/plantillas/pedido-minimo-plantilla.xlsx"
          download
          @click="download"
        >
          Descargar plantilla
          <i class="laive-i icon-download ml-2" />
        </a>
        <div class="d-flex flex-row">
          <button
            class="th-btn th-btn-border-pry text-sm mr-2"
            @click.stop="cancel()"
          >
            Cancelar
          </button>
          <button
            class="th-btn-pry th-btn-float text-sm align-items-center d-flex flex-row"
            @click.stop="upload()"
          >
            Cargar
            <i class="laive-i icon-upload-white ml-2" />
          </button>
        </div>
      </div>
    </box-loader>
  </div>
  <div v-else class="p-4 th-bg-slate">
    <h2 class="fs-4 text-dark">No tienes permisos para ver esta sección</h2>
  </div>
</template>
<script>
import { useToast } from "vue-toastification";
import { mapGetters } from "vuex";

import Dropzone from "../../../components/Shared/Dropzone.vue";

import { PedidoMinimo } from "../../../services";

export default {
  components: {
    Dropzone,
  },
  data() {
    return {
      loading: false,
      clearFiles: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    cancel() {
      this.clearFiles = true;
      setTimeout(() => {
        this.clearFiles = false;
      }, 500);
    },
    toast(title, type = "success") {
      const toast = useToast();
      if (type == "success") {
        toast.success(title, {
          position: "top-right",
          closeOnClick: true,
          hideProgressBar: true,
          timeout: 6000,
          toastClassName: "th-alert th-alert-pry",
          showCloseButtonOnHover: true,
        });
      } else if (type == "error") {
        toast.error(title, {
          position: "top-right",
          closeOnClick: true,
          hideProgressBar: true,
          timeout: 6000,
          toastClassName: "th-alert th-alert-error",
          showCloseButtonOnHover: true,
        });
      }
    },
    download() {
      this.toast("Plantilla descargada");
    },
    upload() {
      const token = this.$store.state.auth.token;
      const file = this.$refs["dropzone"].files[0];
      if (!file) return;
      const typeFile = file.name.split(".").pop();
      if (!["xlsx", "xls"].includes(typeFile)) return;

      const formData = new FormData();
      formData.append("file", file);

      this.loading = true;
      PedidoMinimo.loadDataByFile(token, formData)
        .then(({ status }) => {
          if (status == 200) {
            this.toast("Plantilla cargada");
            this.cancel();
          } else {
            this.toast("Falló la carga", "error");
          }
        })
        .catch(() => {
          this.toast("Falló la carga", "error");
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style scoped>
header :deep(nav:last-child) {
  padding: 0 !important;
}

h2 {
  font-size: 1.7rem;
  font-weight: bold;
  margin-bottom: 1.3em;
}

:deep(.dropzone) {
  padding-block: 3em;
}
</style>
