<template>
  <div class="row">
    <div class="col-lg-12 px-0">
      <div class="row mx-0">
        <div class="col-md-5 px-1">
          <h3 for="">Datos generales</h3>
        </div>
      </div>
    </div>
  </div>
  <div class="row" :class="bonificacion.tipo ? '' : 'laive-disabled'">
    <div class="col-lg-12 px-0">
      <div class="row mx-0">
        <div class="col-md-6 col-sm-12 col-12 col-lg-5 pr-2">
          <div class="row">
            <div class="col-md-7 my-1 px-1">
              <GeneralInput
                v-model="bonificacion.descripcion"
                tipo="text"
                label="Descripción"
              ></GeneralInput>
            </div>
            <div class="col-md-5">
              <div class="row h-100">
                <div class="col-md-6 my-1 px-1">
                  <DateInput
                    v-model="bonificacion.inicio"
                    tipo="date"
                    label="Inicio"
                    :disabled-date="disabledDate"
                    :disabled="es_edicion && !bonificacion.puede_modificar_inicio"
                  ></DateInput>
                </div>
                <div class="col-md-6 my-1 px-1">
                  <DateInput
                    v-model="bonificacion.fin"
                    tipo="date"
                    label="Fin"
                    :disabled-date="disabledDate"
                    :disabled="es_edicion && !bonificacion.puede_modificar_fin"
                  ></DateInput>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12 col-12 col-lg-7 pr-0">
          <div class="row">
            <div class="col-md-11 my-1" v-if="bonificacion.escala_individual">
              <GeneralDropdown
                v-model="producto"
                tipo="date"
                label="Prod. Bonificacion"
                :options="productos_options"
                :is_multiple="false"
                :collapse_tags="false"
                :filterable="true"
                placeholder="Selecciona un producto"
                :disabled="es_edicion"
              ></GeneralDropdown>
            </div>
            <div class="col-md-11 my-1" v-else>
              <div class="row mx-0">
                <div class="col-md-4 px-1">
                  <GeneralDropdown
                    v-model="categorias"
                    label="Caterogías"
                    aditional_class="bg-white"
                    :options="categorias_options"
                    :is_multiple="true"
                    :collapse_tags="true"
                    :puede_seleccionar_todos="true"
                    :resumir_tags="true"
                    :disabled="es_edicion"
                  ></GeneralDropdown>
                </div>
                <div class="col-md-4 px-1">
                  <GeneralDropdown
                    v-model="familias"
                    label="Familias"
                    aditional_class="bg-white"
                    :options="familias_options"
                    :is_multiple="true"
                    :collapse_tags="true"
                    :puede_seleccionar_todos="true"
                    :resumir_tags="true"
                    :disabled="es_edicion"
                  ></GeneralDropdown>
                </div>
                <div class="col-md-4 px-1">
                  <GeneralDropdown
                    v-model="presentaciones"
                    label="Presentaciones"
                    aditional_class="bg-white"
                    :options="presentaciones_options"
                    :is_multiple="true"
                    :collapse_tags="true"
                    :puede_seleccionar_todos="true"
                    :resumir_tags="true"
                    :disabled="es_edicion"
                  ></GeneralDropdown>
                </div>
              </div>
            </div>

            <div class="col-md-1 d-flex align-items-center px-0">
              <button
                @click.prevent="agregar"
                type="button"
                class="btn circle-btn bg-laive-green text-white p-0"
                :disabled="!puedeAgregarDetalle || es_edicion"
              >
                +
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-lg-12 px-0">
      <div class="row mx-0">
        <div class="col-md-5 px-1">
          <h3 for="">Definir escala</h3>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-lg-12 px-0">
      <div class="row mx-0">
        <div class="col-md-12">
          <EscalaTable :bonificacion="bonificacion" :can_edit="!es_edicion"></EscalaTable>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import GeneralInput from "./GeneralInput.vue";
import DateInput from "./DateInput.vue";
import GeneralDropdown from "./GeneralDropdown.vue";
import EscalaRow from "./EscalaRow.vue";
import EscalaTable from "./EscalaTable.vue"
import constantes from '@/model/constantes.js';
const store = useStore();

const disabledDate = (time) => {
  let today = new Date().setHours(0, 0, 0, 0)
  return time.getTime() < today
}

const { bonificacion, es_edicion} = defineProps({
  bonificacion: {
    type: Object,
  },
  productos_options: Array,
  es_edicion: {
    type: Boolean,
    default: false,
  },
});

const limpiarValores = async () => {
  presentaciones.value = [];

  await setTimeout(() => {
    familias.value = [];
  }, 100);

  await setTimeout(() => {
    categorias.value = [];
  }, 100);
};
const agregar = () => {
  bonificacion.prods_promo.push({
    tipo: bonificacion.escala_individual
      ? constantes.INDIVIDUAL
      : constantes.COMBINACION,
    productos: bonificacion.escala_individual ? [producto.value] : [],
    categorias: categorias.value,
    proposito: 'material',
    familias: familias.value,
    presentaciones: presentaciones.value,
    detalles: [
      { bultos: 0, descuento: 0 },
      { bultos: 0, descuento: 0 },
      { bultos: 0, descuento: 0 },
      { bultos: 0, descuento: 0 },
    ],
  });
  limpiarValores();
};
const eliminarFila = (index) => {
  bonificacion.prods_promo.splice(index, 1);
};
const eliminarGrupoProducto = () => {
  bonificacion.prods_promo.pop();
};
const producto = ref(null);
const categorias = ref([]);
const familias = ref([]);
const presentaciones = ref([]);
onMounted(() => {
  if (!es_edicion) {
    bonificacion.prods_promo = [];
    bonificacion.prods_regalo = [];
    
  }
});

const categorias_raw = computed(() => store.state.bonificacionUtil.categorias);
const familias_raw = computed(() => {
  let fuente = store.state.bonificacionUtil.familias;

  return fuente.filter((item) => categorias.value.includes(item.idcategoria));
});

const presentaciones_raw = computed(() => {
  let fuente = store.state.bonificacionUtil.presentaciones;
  fuente = fuente.filter((item) => familias.value.includes(item.idfamilia));

  const result = [];
  fuente.forEach((item) => {
    const existe = result.find(
      (elemento) => elemento.codigodato == item.codigodato
    );
    if (!existe) {
      result.push(item);
    }
  });
  return result;
});

const puedeAgregarDetalle = computed(() => {
  return bonificacion.escala_individual
    ? producto.value != null
    : categorias.value.length > 0 &&
        familias.value.length > 0 &&
        presentaciones.value.length > 0;
});

const categorias_options = computed(() => {
  return categorias_raw.value.map((item) => {
    return {
      value: item.codigodato,
      label: item.nombre,
    };
  });
});
const familias_options = computed(() => {
  return familias_raw.value.map((item) => {
    return {
      value: item.codigodato,
      label: item.nombre,
    };
  });
});
const presentaciones_options = computed(() => {
  return presentaciones_raw.value.map((item) => {
    return {
      value: item.codigodato,
      label: item.nombre,
    };
  });
});
</script>
<style lang="scss" scoped>
.bonificacion-producto-btn {
  font-size: 10px;
  font-weight: lighter;
  background: transparent;
  border: none;
  span {
    text-decoration: underline;
  }
}
.circle-btn:hover {
  box-shadow: none !important;
  transform: none !important;
  color: white !important;
}

thead {
  background: #f7f9fc;
}
.table thead th {
  text-transform: none;
  font-size: 11px;
  border: none;
}
.table {
  border-radius: 10px;
  overflow: hidden;
}
</style>
