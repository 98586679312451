<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive validateClient">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template v-slot:header>
            <div class="row">
              <div class="col-12 col-md-6">
                <h3 class="mb-0">Reporte de Clientes</h3>
              </div>
              <div class="col-12 col-md-6 text-right">
                <button type="button" @click="exportClientes" class="btn base-button btn-primary btn-sm" role="button"><!--v-if--><i class="fas fa-file-export"></i>Exportar </button>
              </div>
            </div>
          </template>
          <div>
            <div class="row px-3">
              <div class="col-12 col-md-9 mb-3">
                <div class="row">
                  <div class="col-12 col-md-4">
                    <template v-if="distribuidoraIdCurrent">
                      <span class="title_filter mr-2">Distribuidora:  <strong>{{distribuidoraNameCurrent}}</strong></span>
                    </template>
                    <template v-else>
                      <span class="title_filter mr-2">Distribuidoras:  </span>
                      <select class="form-control" style="display: inline-block;height: 43px;" name="distributor_select" @change="changeListbyDistribuidora">
                        <option value="0">Todos</option>
                        <option
                          v-for="item in distribuidoras"
                          :value="item.idDistribuidora"
                          :key="item.idDistribuidora"
                          :selected="item.idDistribuidora == parameters.distribuidora"
                          >
                          {{ item.razonSocial }}
                        </option>
                      </select>
                    </template>
                  </div>
                  <div class="col-12 col-md-4">
                    <template v-if="supervisorIdCurrent">
                      <span class="title_filter mr-2">Supervisor:  <strong>{{supervisorNameCurrent}}</strong></span>
                    </template>
                    <template v-else>
                      <span class="title_filter mr-2">Supervisor:  </span>
                      <select class="form-control" id="supervisor_select" style="display: inline-block;height: 43px;" name="supervisor_select" @change="changeListbySupverisor">
                        <option value="">Todos</option>
                        <option
                          v-for="item in supervisores"
                          :value="item.codigoSupervisor"
                          :key="item.codigoSupervisor+item.distribuidora"
                          :selected="item.codigoSupervisor == parameters.supervisor"
                          >
                          {{ item.nombre }}
                        </option>
                      </select>
                    </template>
                  </div>
                  <div class="col-12 col-md-4">
                    <span class="title_filter mr-2">Vendedor:  </span>
                    <select class="form-control" id="vendedor_select" style="display: inline-block;height: 43px;" name="vendedor_select" @change="changeListbyVendedor">
                      <option value="">Todos</option>
                      <option
                        v-for="item in vendedores"
                        :value="item.codigoVendedor"
                        :key="item.codigoVendedor+item.distribuidora"
                        :selected="item.codigoVendedor == parameters.vendedor"
                        >
                        {{ item.nombre }} {{ item.apellidos }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-3 align-items-center justify-content-end mb-3 d-none">
                <div class="mr-2">
                  <span class="title_filter">Paginación:  </span>
                  <el-select
                    class="select-primary pagination-select"
                    v-model="pagination.perPage"
                    style="width:80px"
                    placeholder="Per page"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <!-- Real Table -->
            <div class="el-table table_report pesos notoverflow">
              <table width="100%" border="0">
                <thead class="el-table__header-wrapper">
                  <th v-for="(item) in tableColumns" :key="item.prop">
                    {{ item.label }}
                  </th>
                  <th></th>
                </thead>
                <tbody>
                  <template v-if="queriedData.length">
                    <tr v-for="value in queriedData" :key="value.nombre">
                      <td v-if="value.codigoCliente" >{{value.codigoCliente}}</td><td v-else>---</td>
                      <td v-if="value.nombre" >{{value.nombre}}</td><td v-else>---</td>
                      <td v-if="value.numeroDocumento">{{value.numeroDocumento}}</td><td v-else>---</td>
                      <td v-if="value.gironegocio">{{value.gironegocio}}</td><td v-else>---</td>
                      <td v-if="value.segmento">{{value.segmento}}</td><td v-else>---</td>
                      <td v-if="value.estadoCliente">{{value.estadoCliente}}</td><td v-else>---</td>
                      <td>
                        <base-button type="info" tag="a" class="detail" size="sm" role="button" @click="openDetail(1, value)" aria-pressed="true">Detalle</base-button>
                      </td>
                    </tr>
                  </template>
                  <tr v-else><td>No se encontraron Clientes</td></tr>
                </tbody>
              </table>
            </div>
          </div>
          <template v-slot:footer>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category d-none">
                  Mostrando {{ from + 1 }} a {{ to }} de {{ total }} Clientes
                </p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.newcurrentPage"
                :per-page="pagination.perPage"
                :total="total"
                @click="changePaginate"
              >
              </base-pagination>
            </div>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
let fixClickOpenModal = 1
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import axios from "axios";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import TabPane from "@/components/Tabs/TabPane";
import BasePagination from "@/components/BasePagination";
import {mapGetters} from 'vuex';
import swal from "sweetalert2";
import DashboardNavbar from "../Layout/DashboardNavbar";
export default {
  components: {
    DashboardNavbar,
    BasePagination,
    RouteBreadCrumb,
    TabPane,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  computed: {
    pagedData() {
      this.getDistributor
      this.getSupervisor
      this.getVendedor
      this.getDataClients
      return this.tableData;
    },
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString().toLowerCase();
          if (rowValue.includes && rowValue.includes(this.searchQuery.toLowerCase())) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.pagination.total;
    },
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    }),
    getDistributor(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let urlApiDistribuidoras = process.env.VUE_APP_API_URL+"/distribuidoras/lista"
      const requestDistribuidoras = axios.get(urlApiDistribuidoras, config);
      axios.all([requestDistribuidoras]).then(axios.spread((...responses) => {
        const responseDistribuidoras = responses[0].data
        if($this.distribuidoraIdCurrent){
          let dataDistibuitorCurrent =  responseDistribuidoras.filter((item)=>{return item.idDistribuidora == $this.distribuidoraIdCurrent})
          $this.distribuidoraNameCurrent = dataDistibuitorCurrent[0].razonSocial
        }
        $this.distribuidoras = responseDistribuidoras.filter((item)=>{return item.estado == "1"})
        return $this.distribuidoras
      })).catch(errors => {
        // react on errors.
      })
    },
    getSupervisor(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      if($this.distribuidoras.length){
        let getParams = this.$route.query
        let selectDistribuidora = ""
        if(Object.keys(getParams).length){
          selectDistribuidora = Number(getParams.distribuidora)
        }else{
          selectDistribuidora = ($this.distribuidoraIdCurrent) ? $this.distribuidoraIdCurrent : Number(document.querySelector("select[name='distributor_select']").value)
        }
        const urlApiSupervisores = process.env.VUE_APP_API_URL+`/supervisor/lista/${selectDistribuidora}`
        axios.get(urlApiSupervisores,config)
        .then(function (response){
          let dataSupervisores= response.data
          console.log(response.data, " ====lista de supervisores");
          return $this.supervisores = dataSupervisores
        })
      }
    },
    getVendedor(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      if($this.supervisores.length){
        let getParams = this.$route.query
        let selectDistribuidora = ""
        let selectSupervisor = ""
        if(Object.keys(getParams).length){
          selectDistribuidora = Number(getParams.distribuidora)
          selectSupervisor = getParams.supervisor
          console.log({selectSupervisor})
        }else{
          selectDistribuidora = ($this.distribuidoraIdCurrent) ? $this.distribuidoraIdCurrent : Number(document.querySelector("select[name='distributor_select']").value)
          selectSupervisor = ($this.supervisorIdCurrent) ? $this.supervisorIdCurrent : document.querySelector("select[name='supervisor_select']").value
        }
        const parameters = {
          "iddistribuidora": selectDistribuidora,
          "codigosupervisor": selectSupervisor
        }
        const urlApiVendedores = process.env.VUE_APP_API_URL+`/vendedor/lista`
        axios.post(urlApiVendedores,parameters,config)
        .then(function (response){
          console.log(response.data, " ====lista de vendedores");
          return $this.vendedores = response.data
        })
      }
    },
    getDataClients() {
      const $this = this
      let getParams = this.$route.query
      if($this.distribuidoras.length && $this.vendedores.length && $this.initload){
        let idDistribuidora = ""
        let idSupervisor = ""
        let idVendedor = ""
        if(Object.keys(getParams).length){
          idDistribuidora = Number(getParams.distribuidora)
          idSupervisor = getParams.supervisor
          idVendedor = getParams.vendedor
          this.parameters.distribuidora = idDistribuidora
          this.parameters.supervisor = idSupervisor
          this.parameters.vendedor = idVendedor
        }else{
          idDistribuidora = ($this.distribuidoraIdCurrent) ? $this.distribuidoraIdCurrent : Number(document.querySelector("select[name='distributor_select']").value)
          idSupervisor = ($this.supervisorIdCurrent) ? $this.supervisorIdCurrent : document.querySelector("select[name='supervisor_select']").value
          idVendedor = document.querySelector("select[name='vendedor_select']").value
        }
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        const parameters = {
          "idDistribuidora": idDistribuidora,
          "codigoSupervisor": idSupervisor,
          "codigoVendedor": idVendedor,
          "page": 1
        }

        axios.post(process.env.VUE_APP_API_URL+"/cliente/lista",parameters,config)
        .then(function (response){
          document.querySelector(".form_laive").classList.remove("validateClient")
          let dataClient = response.data
          $this.initload = false
          $this.pagination.perPage = dataClient.data.length
          $this.pagination.total = dataClient.totalPage
          return $this.tableData = dataClient.data
        })
      }
    },
  },
  methods:{
    changeListbyDistribuidora(evn){
      const $this = this
      let selected = Number(evn.target.value)
      if (selected == 0) document.querySelector("select[name='supervisor_select']").value = ""
      let idSupervisor = document.querySelector("select[name='supervisor_select']").value
      let idVendedor = document.querySelector("select[name='vendedor_select']").value
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const parameters = {
        "idDistribuidora": selected,
        "codigoSupervisor": idSupervisor,
        "codigoVendedor": idVendedor,
        "page": 1
      }
      document.querySelector(".form_laive").classList.add("validateClient")
      const urlApiSupervisores = process.env.VUE_APP_API_URL+`/supervisor/lista/${selected}`
      const urlApiClientes = process.env.VUE_APP_API_URL+`/cliente/lista`
      const dataClientes = axios.post(urlApiClientes,parameters,config)
      const getSupervisores = axios.get(urlApiSupervisores,config)
      axios.all([dataClientes, getSupervisores]).then(axios.spread((...responses) => {
        const resDataByDistribuidora = responses[0].data
        const resListSupervisores = responses[1].data
        $this.tableData = resDataByDistribuidora.data
        $this.pagination.currentPage = 1
        $this.pagination.perPage = resDataByDistribuidora.data.length
        $this.pagination.total = resDataByDistribuidora.totalPage
        $this.supervisores = resListSupervisores
        document.querySelector(".form_laive").classList.remove("validateClient")
      })).catch(errors => {
        // react on errors.
      })
    },
    changeListbySupverisor(evn){
      const $this = this
      let selected = evn.target.value
      if (selected == 0) document.querySelector("select[name='vendedor_select']").value = ""
      let idDistribuidora = ($this.distribuidoraIdCurrent) ? $this.distribuidoraIdCurrent : Number(document.querySelector("select[name='distributor_select']").value)
      let idVendedor = document.querySelector("select[name='vendedor_select']").value
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      if(idDistribuidora != 0){
        const parametersDataClients = {
          "idDistribuidora": idDistribuidora,
          "codigoSupervisor": selected,
          "codigoVendedor": idVendedor,
          "page": 1
        }
        const parameters = {
          "iddistribuidora": idDistribuidora,
          "codigosupervisor": selected
        }
        document.querySelector(".form_laive").classList.add("validateClient")
        const urlApiVendedores = process.env.VUE_APP_API_URL+`/vendedor/lista/`
        const urlApiClientes = process.env.VUE_APP_API_URL+`/cliente/lista`
        const dataClientes = axios.post(urlApiClientes,parametersDataClients,config)
        const getVendedores = axios.post(urlApiVendedores,parameters,config)
        axios.all([dataClientes, getVendedores]).then(axios.spread((...responses) => {
          const resDataBySupervisor = responses[0].data
          const resListVendedores = responses[1].data
          $this.tableData = resDataBySupervisor.data
          $this.pagination.currentPage = 1
          $this.pagination.perPage = resDataBySupervisor.data.length
          $this.pagination.total = resDataBySupervisor.totalPage
          $this.vendedores = resListVendedores
          document.querySelector(".form_laive").classList.remove("validateClient")
          document.querySelector("select[name='vendedor_select']").value = ""
        })).catch(errors => {
          // react on errors.
        })
      }else{
        document.querySelector("select[name='supervisor_select']").value = ""
        const swalWithBootstrapButtons1 = swal.mixin({
          customClass: {
            confirmButton: "btn btn-success btn-fill",
          },
          buttonsStyling: false,
        });
        swalWithBootstrapButtons1.fire({
          title: `Seleccionar una distribuidora`,
          confirmButtonText: "Ok",
        });
      }
    },
    changeListbyVendedor(evn){
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let selected = evn.target.value
      let idDistribuidora = ($this.distribuidoraIdCurrent) ? $this.distribuidoraIdCurrent : Number(document.querySelector("select[name='distributor_select']").value)
      let idSupervisor = ($this.supervisorIdCurrent) ? $this.supervisorIdCurrent : document.querySelector("select[name='supervisor_select']").value
      if(idDistribuidora != 0){
        const parameters = {
          "idDistribuidora": idDistribuidora,
          "codigoSupervisor": idSupervisor,
          "codigoVendedor": selected,
          "page": 1
        }
        document.querySelector(".form_laive").classList.add("validateClient")
        console.log({parameters});

        axios.post(process.env.VUE_APP_API_URL+"/cliente/lista",parameters,config)
        .then(function (response){
          document.querySelector(".form_laive").classList.remove("validateClient")
          console.log(response.data, " ====lista de vendedores");
          $this.pagination.currentPage = 1
          $this.pagination.perPage = response.data.data.length
          $this.pagination.total = response.data.totalPage
          return $this.tableData = response.data.data
        })
      }else{
        document.querySelector("select[name='vendedor_select']").value = ""
        const swalWithBootstrapButtons1 = swal.mixin({
          customClass: {
            confirmButton: "btn btn-success btn-fill",
          },
          buttonsStyling: false,
        });
        swalWithBootstrapButtons1.fire({
          title: `Seleccionar una distribuidora`,
          confirmButtonText: "Ok",
        });
      }
    },
    changePaginate(evn){
      const $this = this
      const listPaginator = document.querySelectorAll("ul.pagination .page-item")
      let idDistribuidora = (this.distribuidoraIdCurrent) ? this.distribuidoraIdCurrent : Number(document.querySelector("select[name='distributor_select']").value)
      let idSupervisor = ($this.supervisorIdCurrent) ? $this.supervisorIdCurrent : document.querySelector("select[name='supervisor_select']").value
      let idVendedor = document.querySelector("select[name='vendedor_select']").value
      let elm = evn.target;
      let sendAjax = false;
      let parentEl = elm.parentNode
      if(elm.classList.contains("prev-page")){
        if(!elm.classList.contains("disabled")){
          let linkActivePrev = document.querySelectorAll("ul.pagination .page-item.active")
          linkActivePrev[0].classList.remove("active")
          linkActivePrev[0].previousSibling.classList.add("active")
          sendAjax = true
        }
      }else if(elm.classList.contains("next-page")){
      }else{
        if(elm.getAttribute("aria-label") == "Previous"){
          let linkActivePrev = document.querySelectorAll("ul.pagination .page-item.active")
          linkActivePrev[0].classList.remove("active")
          linkActivePrev[0].previousSibling.classList.add("active")
          sendAjax = true
        }else if(elm.getAttribute("aria-label") == "Next"){
          let linkActiveNext = document.querySelectorAll("ul.pagination .page-item.active")
            linkActiveNext[0].classList.remove("active")
            linkActiveNext[0].nextSibling.classList.add("active")
            sendAjax = true
        }else{
          if(!parentEl.classList.contains("active")){
            listPaginator.forEach(item => {
              item.classList.remove("active")
            });
            parentEl.classList.add("active")
            sendAjax = true
          }
        }
      }
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const parameters = {
          "page": this.pagination.newcurrentPage,
          "idDistribuidora": idDistribuidora,
          "codigoSupervisor": idSupervisor,
          "codigoVendedor": idVendedor,
      }
      if(sendAjax){
        document.querySelector(".form_laive").classList.add("validateClient")
        axios.post(process.env.VUE_APP_API_URL+"/cliente/lista",parameters,config)
        .then(function (response){
          document.querySelector(".form_laive").classList.remove("validateClient")
          console.log(response.data.data)
          sendAjax = false
          $this.pagination.currentPage = 1
          return $this.tableData = response.data.data
        })
      }
    },
    openDetail(index, row){
      function getTeams(data) {
        let dataResp = "";
        data.forEach(item => {
          dataResp += `<div class="col-12">
                          <div class="box_team">
                            ${item.nombre ? `<p>${item.nombre}</p>` : ''}
                            <p>
                              Modelo: ${item.modelo ? item.modelo : '--'}<br>
                              Lote: ${item.lote ? item.lote: '--'}<br>
                              Placa: ${item.placa ? item.placa : '--'}
                            </p>
                          </div>
                        </div>`
        });
        return dataResp
      }
      let templateBasic = function(datosBasicos, isCumple){
        return `
          <h3 class="d-flex align-items-center mb-3 mt-3">
            <i class="ni ni-bullet-list-67 mr-2"></i>
            Basicos
          </h3>
          <div class="description">
            <div class="row">
              <div class="col-12 col-md-3">
                <h4 class="title_head">Teléfonos</h4>
                <p class="text">${datosBasicos.telefono ? datosBasicos.telefono : '--'}</p>
              </div>
              <div class="col-12 col-md-3">
                <h4 class="title_head">RUC</h4>
                <p class="text">${datosBasicos.ruc ? datosBasicos.ruc : '--'}</p>
              </div>
              <div class="col-12 col-md-3">
                <h4 class="title_head">Tipo de cliente</h4>
                <p class="text">${datosBasicos.tipoCliente ? datosBasicos.tipoCliente : '--'}</p>
              </div>
              <div class="col-12 col-md-3">
                <h4 class="title_head">Giro</h4>
                <p class="text">${datosBasicos.giro ? datosBasicos.giro : '--'}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-3">
                <h4 class="title_head">Nombre del mercado</h4>
                <p class="text">${datosBasicos.mercado ? datosBasicos.mercado : '--'}</p>
              </div>

              <div class="col-12 col-md-3">
                <h4 class="title_head">E-mail</h4>
                <p class="text">${datosBasicos.email ? datosBasicos.email : '--'}</p>
              </div>

              <div class="col-12 col-md-3">
                <h4 class="title_head">Direccion</h4>
                <p class="text">${datosBasicos.direccion ? datosBasicos.direccion: '--'}</p>
              </div>

              <div class="col-12 col-md-3">
                <h4 class="title_head">Distrito</h4>
                <p class="text">${datosBasicos.distrito ? datosBasicos.distrito : '--'}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-3">
                <h4 class="title_head">Latitud</h4>
                <p class="text">${datosBasicos.latitud ? datosBasicos.latitud : '--'}</p>
              </div>
              <div class="col-12 col-md-3">
                <h4 class="title_head">Longitud</h4>
                <p class="text">${datosBasicos.longitud ? datosBasicos.longitud : '--'}</p>
              </div>
              ${isCumple ? `<div class="col-12 col-md-3"> <h4 class="title_head">Cumpleaños</h4> <p class="text">${datosBasicos.fechaNac ? datosBasicos.fechaNac : '--'}</p></div>` : ''}
            </div>

          </div>
          <hr>
        `
      };

      let templateGestion = function(datosGestion, principal){
        return `
          <h3 class="d-flex align-items-center mb-3">
            <i class="ni ni-briefcase-24 mr-2"></i>
            Gestion
          </h3>
          <div class="description">
            <div class="row">
              <div class="col-12 col-md-3">
                <h4 class="title_head">Segmento</h4>
                <p class="text">${principal.segmento ? principal.segmento : '--'}</p>
              </div>
              <div class="col-12 col-md-3">
                <h4 class="title_head">Clúster</h4>
                <p class="text">${principal.cluster ? principal.cluster : '--'}</p>
              </div>
              <div class="col-12 col-md-3">
                <h4 class="title_head">Ruta</h4>
                <p class="text">${datosGestion.ruta ? datosGestion.ruta : '--'}</p>
              </div>
              <div class="col-12 col-md-3">
                <h4 class="title_head">Mesa</h4>
                <p class="text">${datosGestion.mesa ? datosGestion.mesa : '--'}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-3">
                <h4 class="title_head">Frencuencia de visitas</h4>
                <p class="text">${datosGestion.frecuenciaVisita ? datosGestion.frecuenciaVisita : '--'}</p>
              </div>
              <div class="col-12 col-md-3">
                <h4 class="title_head">Día de atención</h4>
                <p class="text">${datosGestion.diaAtencion ? datosGestion.diaAtencion : '--'}</p>
              </div>
              <div class="col-12 col-md-3">
                <h4 class="title_head">Vendedor/ra a cargo</h4>
                <p class="text">${datosGestion.vendedor ? datosGestion.vendedor : '--'}</p>
              </div>
              <div class="col-12 col-md-3">
                <h4 class="title_head">Tenencia y datos de equipos</h4>
                <p class="text">Cantidad de equipos: ${datosGestion.equipos.length}</p>
                <div class="row">${getTeams(datosGestion.equipos)}</div>
              </div>
            </div>
          </div>

          <hr>
        `
      };

      let templateImages = function(dataImages){
        const allImages = dataImages.map((image) => {
          return `<div class="col-md-2"><div class="box_img"><img src="${image}" /> </div>
              </div>`;
        });
        return `
          <h3 class="d-flex align-items-center mb-3">
            <i class="ni ni-album-2 mr-2"></i>
            Imágenes
          </h3>
          <div class="description">
            <div class="row">
              ${allImages}
            </div>
          </div>
          <hr>
        `
      };

      let templateCluster = function (datosGestion){
        if(datosGestion.cluster){
          return `
            <h3 class="d-flex align-items-center mb-3">
              <i class="ni ni-briefcase-24 mr-2"></i>
              Caracteristicas del Cluster
            </h3>
            <div class="row">
              <div class="col-12 col-md-3">
                <h4 class="title_head">¿Acepta pago con crédito o débito?</h4>
                <p class="text">${datosGestion.detalleCluster.aceptaDebitoCredito.trim().length ? datosGestion.detalleCluster.aceptaDebitoCredito : "No"}</p>
              </div>
              <div class="col-12 col-md-3">
                <h4 class="title_head">¿Cliente permite que active su bodega?</h4>
                <p class="text">${datosGestion.detalleCluster.permiteActivarBodega.trim().length ? datosGestion.detalleCluster.permiteActivarBodega : "No"}</p>
              </div>
              <div class="col-12 col-md-3">
                <h4 class="title_head">El negocio se encuentra en</h4>
                <p class="text">${datosGestion.detalleCluster.negocioUbicadoEn ? datosGestion.detalleCluster.negocioUbicadoEn : '--'}</p>
              </div>
              <div class="col-12 col-md-3">
                <h4 class="title_head">Estructura del negocio</h4>
                <p class="text">${datosGestion.detalleCluster.estructuraNegocio}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-3">
                <h4 class="title_head">¿Existe otro negocio con un mejor mix cerca?</h4>
                <p class="text">${datosGestion.detalleCluster.negocioMejorMix.trim().length ? datosGestion.detalleCluster.negocioMejorMix : "No"}</p>
              </div>
              <div class="col-12 col-md-3">
                <h4 class="title_head">Mix de producto que vende</h4>
                <p class="text">${datosGestion.detalleCluster.mixProducto}</p>
              </div>
              <div class="col-12 col-md-3">
                <h4 class="title_head">Nivel de mix de productos (No solo Laive)</h4>
                <p class="text">${datosGestion.detalleCluster.nivelMix}</p>
              </div>
              <div class="col-12 col-md-3">
                <h4 class="title_head">Tamaño del negocio</h4>
                <p class="text">${datosGestion.detalleCluster.tamanioNegocio}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-3">
                <h4 class="title_head">¿Tiene buena relacion con el cliente?</h4>
                <p class="text">${datosGestion.detalleCluster.buenaRelacionCliente.trim().length ? datosGestion.detalleCluster.buenaRelacionCliente : "No"}</p>
              </div>
            </div>
          `
        }
      };

      const swalWithBootstrapButtons1 = swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          container: "swal-clients",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons1.fire({
        title: `Detalle de cliente: ${row.nombre}`,
        html: `
              <div class="form_laive validateClient text-left">
                <div class="box_loader">
                  <div class="loader"></div>
                </div>
                <br>
                <br>
                <br>
                <br>
                <br>
              </div>`,
        showConfirmButton: false,
      });
      // get Detail Client
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      if(fixClickOpenModal == 1){
        ++fixClickOpenModal
        axios.get(process.env.VUE_APP_API_URL+`/cliente/detalle/${row.idCliente}`,config)
        .then(function (response){
          fixClickOpenModal = 1
          const dataClient = response.data
          swalWithBootstrapButtons1.fire({
            title: `Detalle de cliente: ${row.nombre}`,
            html: `
              <div class="form_laive text-left">
                <div class="box_loader">
                  <div class="loader"></div>
                </div>
                ${templateBasic(dataClient.datosBasicos, dataClient.principal.isCumple)}
                ${templateGestion(dataClient.datosGestion, dataClient.principal)}
                ${dataClient.datosGestion.cluster ? templateCluster(dataClient.datosGestion) : ''}
                ${dataClient.imagenes.length ? templateImages(dataClient.imagenes) : ''}
              </div>`,
            confirmButtonText: "Cerrar",
            showConfirmButton: true,
          });
          console.log(dataClient, " detalleeeee")
        })
      }
    },
    exportClientes(){
      let idDistribuidora = (this.distribuidoraIdCurrent) ? this.distribuidoraIdCurrent : Number(document.querySelector("select[name='distributor_select']").value)
      let idSupervisor = (this.supervisorIdCurrent) ? this.supervisorIdCurrent : document.querySelector("select[name='supervisor_select']").value
      let idVendedor = document.querySelector("select[name='vendedor_select']").value
      const token = localStorage.getItem("token")
      const urlDomain = process.env.VUE_APP_API_URL.replace("api", "")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
        }
      };
      const parameters = {
        "iddistribuidora": idDistribuidora,
        "codigoSupervisor": idSupervisor,
        "codigoVendedor": idVendedor
      }
      console.log(parameters, " selectt export");
      document.querySelector(".form_laive").classList.add("validateClient")
      axios.post(process.env.VUE_APP_API_URL+"/cliente/exportar",parameters,config)
      .then(function (response){
        document.querySelector(".form_laive").classList.remove("validateClient")
        const urlFile = urlDomain + response.data.fileName
        const link = document.createElement('a');
        link.href = urlFile;
        document.body.appendChild(link);
        link.click();
      })
    }
  },
  mounted() {
    if(this.user.rol == 3){
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
    if(this.user.rol == 4){
      this.supervisorIdCurrent = this.user.codigo
      this.supervisorNameCurrent = this.user.nombre
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
  },
  data() {
    return {
      initload: true,
      distribuidoraIdCurrent: null,
      distribuidoraNameCurrent: null,
      supervisorIdCurrent: null,
      supervisorNameCurrent: null,
      pagination: {
        perPage: 1,
        currentPage: 1,
        newcurrentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 1,
      },
      parameters:{
        distribuidora: "",
        supervisor: "",
        vendedor: ""
      },
      distribuidoras:[],
      supervisores:[],
      vendedores:[],
      searchQuery: "",
      propsToSearch: ["nombre", "segmento", "gironegocio"],
      tableColumns: [
        {
          prop: "idCliente",
          label: "Código",
          minWidth: 255,
        },
        {
          prop: "nombre",
          label: "Nombre",
          minWidth: 255,
        },
        {
          prop: "numeroDocumento",
          label: "Documento",
          minWidth: 200,
        },
        {
          prop: "gironegocio",
          label: "Giro de Negocio",
          minWidth: 200,
        },
        {
          prop: "segmento",
          label: "Segmento",
          minWidth: 170,
        },
        {
          prop: "estadoCliente",
          label: "Estado",
          minWidth: 170,
        },
      ],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      expands: [],
    };
  },
};
</script>
<style>
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
.no-border-card .card-footer {
  border-top: 0;
}
.swal-clients .swal2-popup{
  width:70.5em;
}
</style>
