<template>
    <div class="row">
      <div class="col-md-3 py-3" v-for="item in dataFiels" :key="item.codigo">
        <div class="list_group">
          <div class="custom-control custom-checkbox">
            <input :id="item.nombre" class="custom-control-input item" :value="item.codigo" type="checkbox" v-model="dataFilter" @change="getFilters">
            <label :for="item.nombre" class="custom-control-label"><strong> {{ item.nombre }}</strong></label>
          </div>
      </div>
    </div>
    </div>
</template>
<script>
export default {
    props:{
      dataFiels:{
        type: Array,
        default: "",
      },
      currentFields:{
        type: [String, Array],
        default: "",
        required: false
      },
    },
    mounted(){
      let dataTmp = [];
      if(this.currentFields.length){
        this.currentFields.forEach(item => {
          dataTmp.push(item.codigoDato)
          // console.log(item.codigoDato)
        });
        this.dataFilter = dataTmp
      }
      console.log(this.dataFilter)
    },
    methods:{
      getFilters(){
        this.$emit('returnFilter',this.dataFilter)
        //console.log(this.dataFilter)
      }
    },
    data(){
        return {
          dataFilter: [],
        }
    },

};
</script>
<style lang="scss">
</style>
