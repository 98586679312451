
import setting from './setting';
const API_URL = setting.http.base_url;

export default {
    getList: async ($axios, request = null) => {
        const { data } = await $axios.post(`${API_URL}/BonificacionBon/ListarBonificacionEstado`, request,{ headers: setting.http.headers() });
        return data;
    },
    crearBonificacion: async ($axios, payload) => {
        try {
            let data = null;
            data = (await $axios.post(`${API_URL}/BonificacionBon/Create`, payload, { headers: setting.http.headers() }));
            return data;
        } catch (err) {
            console.log(err);
            return err.response;
        }
    },
    editarBonificacion: async ($axios, payload) => {
        try {
            let data = null;
            data = (await $axios.post(`${API_URL}/BonificacionBon/Edit`, payload, { headers: setting.http.headers() }));
            return data;
        } catch (err) {
            console.log(err);
            return err.response;
        }
    },
    subiArchivo: async ($axios, payload) => {
        try {
            const { data } = (await $axios.post(`${API_URL}/BonificacionBon/CargarPromocion/Excel`, payload, { headers: setting.http.multipart_headers() }));
            return data;
        } catch (err) {
            console.log(err);
        }
    },
    validarClientes: async ($axios, payload) => {
        try {
            const { data } = (await $axios.post(`${API_URL}/BonificacionBon/ValidarClientes`, payload, { headers: setting.http.headers() }));
            return data;
        } catch (err) {
            console.log(err);
        }
    },
    cambiarEstado: async ($axios, payload) => {
        try {
            return await $axios.post(`${API_URL}/BonificacionBon/ActualizarDistribuidoraEstado`, payload, { headers: setting.http.headers() });
        } catch (err) {
            console.log(err);
        }
    },
    cambiarfecha: async ($axios, payload) => {
        try {
            return await $axios.post(`${API_URL}/BonificacionBon/ActualizarDistribuidoraFecha`, payload, { headers: setting.http.headers() });
        } catch (err) {
            console.log(err);
        }
    },
}
