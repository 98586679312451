<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block only_hidden_after">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive validateClient">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template v-slot:header>
            <div class="row">
              <div class="col-12 col-md-6">
                <h3 class="mb-0">Vendedores</h3>
              </div>
              <div class="col-12 col-md-6 text-right">
                <button type="button" @click="exportVendedor" class="btn base-button btn-primary btn-sm" role="button"><i class="fas fa-file-export"></i>Exportar </button>
              </div>
            </div>
          </template>
          <div>
            <div class="row px-3">
              <div class="col-12 col-md-8 mb-3">
                <div class="row">
                  <div class="col-12 col-md-4 d-none">
                    <el-input
                      type="search"
                      clearable
                      prefix-icon="el-icon-search"
                      placeholder="Buscar Supervisor"
                      v-model="searchQuery"
                      aria-controls="datatables"
                    >
                    </el-input>
                  </div>
                  <div class="col-12 col-md-6">
                     <template v-if="distribuidoraIdCurrent">
                      <span class="title_filter mr-2">Distribuidora:  <strong>{{distribuidoraNameCurrent}}</strong></span>
                    </template>
                    <template v-else>
                      <span class="title_filter mr-2">Distribuidoras:  </span>
                      <select class="form-control" style="display: inline-block;height: 43px;" name="distributor_select" @change="changeListbyDistribuidor">
                        <option value="0">Todos</option>
                        <option
                          v-for="item in distribuidoras"
                          :value="item.idDistribuidora"
                          :key="item.idDistribuidora"
                          >
                          {{ item.razonSocial }}
                        </option>
                      </select>
                    </template>
                  </div>
                  <div class="col-12 col-md-6">
                    <template v-if="supervisorIdCurrent">
                      <span class="title_filter mr-2">Supervisor:  <strong>{{supervisorNameCurrent}}</strong></span>
                    </template>
                    <template v-else>
                      <span class="title_filter mr-2">Supervisor:  </span>
                      <select class="form-control" id="supervisor_select" style="display: inline-block;height: 43px;" name="supervisor_select" @change="changeListbySupverisor">
                        <option value="">Todos</option>
                        <option
                          v-for="item in supervisores"
                          :value="item.codigoSupervisor"
                          :key="item.codigoSupervisor+item.distribuidora"
                          >
                          {{ item.nombre }}
                        </option>
                      </select>
                    </template>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4 align-items-center justify-content-end mb-3 d-none">
                <div class="mr-2">
                  <span class="title_filter">Paginación:  </span>
                  <el-select
                    class="select-primary pagination-select"
                    v-model="pagination.perPage"
                    style="width:80px"
                    placeholder="Per page"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <!-- Real Table -->
            <div class="el-table table_report pesos notoverflow">
              <table width="100%" border="0">
                <thead class="el-table__header-wrapper">
                  <th v-for="(item) in tableColumns" :key="item.prop">
                    {{ item.label }}
                  </th>
                  <th></th>
                </thead>
                <tbody>
                  <template v-if="queriedData">
                    <tr v-for="value in queriedData" :key="value.monto">
                      <td v-if="value.codigoVendedor" >{{value.codigoVendedor}}</td><td v-else>---</td>
                      <td v-if="value.nombre">{{value.nombre}}</td><td v-else>---</td>
                      <td v-if="value.apellidos">{{value.apellidos}}</td><td v-else>---</td>
                      <td>
                        <base-button type="info" tag="a" class="detail" size="sm" role="button" @click="openDetail(1, value)" aria-pressed="true">Detalle</base-button>
                        <base-button type="success" tag="a" class="clientes" size="sm" role="button" aria-pressed="true" :href="`../clientes/listado?distribuidora=${value.idDistribuidora}&supervisor=${value.codigoSupervisor}&vendedor=${value.codigoVendedor}`">Clientes</base-button>
                        <base-button outline tag="a" size="sm" type="warning" :href="`../pedidos/listado?distribuidora=${value.idDistribuidora}&supervisor=${value.codigoSupervisor}&vendedor=${value.codigoVendedor}`">Pedidos</base-button>
                        <base-button outline tag="a" size="sm" type="success" :href="`../cobranzas/listado?distribuidora=${value.idDistribuidora}&supervisor=${value.codigoSupervisor}&vendedor=${value.codigoVendedor}`">Cobranzas</base-button>
                        <base-button outline tag="a" size="sm" type="info" :href="`../depositos/listado?distribuidora=${value.idDistribuidora}&supervisor=${value.codigoSupervisor}&vendedor=${value.codigoVendedor}`">Depositos</base-button>
                        <base-button outline tag="a" size="sm" type="primary" :href="`../devoluciones/listado?distribuidora=${value.idDistribuidora}&supervisor=${value.codigoSupervisor}&vendedor=${value.codigoVendedor}`">Devoluciones</base-button>
                      </td>
                    </tr>
                  </template>
                  <tr v-else><td>No se encontraron Vendedores</td></tr>
                </tbody>
              </table>
            </div>
          </div>
          <template v-slot:footer>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category d-none">
                  Mostrando {{ from + 1 }} a {{ to }} de {{ total }} Vendedores
                </p>
              </div>
              <default-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              >
              </default-pagination>
            </div>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import axios from "axios";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import TabPane from "@/components/Tabs/TabPane";
import BasePagination from "@/components/BasePagination";
import swal from "sweetalert2";
import {mapGetters} from 'vuex';
import DashboardNavbar from "../Layout/DashboardNavbar";
export default {
  name: "ListVendedores",
  components: {
    DashboardNavbar,
    BasePagination,
    RouteBreadCrumb,
    TabPane,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  computed: {
    pagedData() {
      this.getDistributor
      this.getSupervisor
      this.getDataVendedor
      if(this.tableData.length){
        return this.tableData.slice(this.from, this.to);
      }
    },
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString().toLowerCase();
          if (rowValue.includes && rowValue.includes(this.searchQuery.toLowerCase())) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    }),
    async getDistributor(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let urlApiDistribuidoras = process.env.VUE_APP_API_URL+"/distribuidoras/lista"
      const requestDistribuidoras = axios.get(urlApiDistribuidoras, config);
      await axios.all([requestDistribuidoras]).then(axios.spread((...responses) => {
        const responseDistribuidoras = responses[0].data
        if($this.distribuidoraIdCurrent){
          let dataDistibuitorCurrent =  responseDistribuidoras.filter((item)=>{return item.idDistribuidora == $this.distribuidoraIdCurrent})
          $this.distribuidoraNameCurrent = dataDistibuitorCurrent[0].razonSocial
        }
        $this.distribuidoras = responseDistribuidoras.filter((item)=>{return item.estado == "1"})
        return $this.distribuidoras;
      })).catch(errors => {
        // react on errors.
      })
    },
    async getSupervisor(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      if($this.distribuidoras.length){
        const selectDistribuodra = ($this.distribuidoraIdCurrent) ? $this.distribuidoraIdCurrent : Number(document.querySelector("select[name='distributor_select']").value)
        const urlApiSupervisores = process.env.VUE_APP_API_URL+`/supervisor/lista/${selectDistribuodra}`
        await axios.get(urlApiSupervisores,config)
        .then(function (response){
          let dataSupervisores= response.data
          console.log(response.data, " ====lista de supervisores");
          return $this.supervisores = dataSupervisores
        })
      }
    },
    async getDataVendedor() {
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      console.log($this.tableData.length, " = 1111111111111111");
      if($this.distribuidoras.length && $this.tableData.length == 0 && $this.initload){
        const selectDistribuodra = ($this.distribuidoraIdCurrent) ? $this.distribuidoraIdCurrent : Number(document.querySelector("select[name='distributor_select']").value)
        const selectVendedor = ($this.supervisorIdCurrent) ? $this.supervisorIdCurrent : document.querySelector("select[name='supervisor_select']").value
        const parameters = {
          "iddistribuidora": selectDistribuodra,
          "codigosupervisor": selectVendedor
        }
        await axios.post(process.env.VUE_APP_API_URL+"/vendedor/lista",parameters,config)
        .then(function (response){
          $this.initload = false
          document.querySelector(".form_laive").classList.remove("validateClient")
          console.log(response.data, " ==== INIT lista de vendedores");
          return $this.tableData = response.data
        })
      }
    }
  },
  methods:{
    changeListbyDistribuidor(evn){
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let selected = Number(evn.target.value)
      const selectSupervisor = document.querySelector("select[name='supervisor_select']").value
      const parameters = {
        "iddistribuidora": selected,
        "codigosupervisor": selectSupervisor
      }
      document.querySelector(".form_laive").classList.add("validateClient")
      const urlApiSupervisores = process.env.VUE_APP_API_URL+`/supervisor/lista/${selected}`
      const urlApiDistribuidora = process.env.VUE_APP_API_URL+`/vendedor/lista`
      const datataDistribuidoras = axios.post(urlApiDistribuidora,parameters,config)
      const getSupervisores = axios.get(urlApiSupervisores,config)
      axios.all([datataDistribuidoras, getSupervisores]).then(axios.spread((...responses) => {
        const resDataByDistribuidora = responses[0].data
        const resListSupervisores = responses[1].data
        $this.tableData = resDataByDistribuidora
        $this.supervisores = resListSupervisores
        document.querySelector(".form_laive").classList.remove("validateClient")
      })).catch(errors => {
        // react on errors.
      })
    },
    changeListbySupverisor(evn){
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let selected = evn.target.value
      const selectDistribuodra = ($this.distribuidoraIdCurrent) ? $this.distribuidoraIdCurrent : Number(document.querySelector("select[name='distributor_select']").value)
      if(selectDistribuodra != 0){
        const parameters = {
          "iddistribuidora": selectDistribuodra,
          "codigosupervisor": selected
        }
        document.querySelector(".form_laive").classList.add("validateClient")
        console.log({parameters});

        axios.post(process.env.VUE_APP_API_URL+"/vendedor/lista",parameters,config)
        .then(function (response){
          document.querySelector(".form_laive").classList.remove("validateClient")
          console.log(response.data, " ====lista de vendedores");
          return $this.tableData = response.data
        })
      }else{
        const swalWithBootstrapButtons1 = swal.mixin({
          customClass: {
            confirmButton: "btn btn-success btn-fill",
          },
          buttonsStyling: false,
        });
        swalWithBootstrapButtons1.fire({
          title: `Seleccionar una distribuidora`,
          confirmButtonText: "Ok",
        });
      }
    },
    openDetail(index, row){
      const swalWithBootstrapButtons1 = swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons1.fire({
        title: `Datos de ${row.nombre}`,
        html: `<table class="table_laive">
                <tr>
                  <th>Nombre:</th>
                  <td>${row.nombre}</td>
                </tr>
                <tr>
                  <th>Apellidos:</th>
                  <td>${row.apellidos ? row.apellidos : '--'}</td>
                </tr>
                <tr>
                  <th>Codigo:</th>
                  <td>${row.codigoVendedor}</td>
                </tr>
                <tr>
                  <th>Teléfono:</th>
                  <td>${row.telefono ? row.telefono : '--'}</td>
                </tr>
                <tr>
                  <th>DNI:</th>
                  <td>${row.numeroDocumento}</td>
                </tr>
                <tr>
                  <th>Email:</th>
                  <td>${row.email ? row.email : '--'}</td>
                </tr>
                <tr>
                  <th>Supervisor Asignado:</th>
                  <td>${row.supervisor}</td>
                </tr>
                <tr>
                  <th>Distribuidora Asociada:</th>
                  <td>${row.distribuidora}</td>
                </tr>
                <tr>
                  <th>Grupo de Venta:</th>
                  <td>${row.grupoVenta}</td>
                </tr>
                <tr>
                  <th>Mesa Asociada:</th>
                  <td>${row.mesa}</td>
                </tr>
              </table>`,
        confirmButtonText: "Cerrar"
      });
    },
    exportVendedor(){
      const selectDistribuidra = (this.distribuidoraIdCurrent) ? this.distribuidoraIdCurrent : Number(document.querySelector("select[name='distributor_select']").value)
      const selectSupervisor = (this.supervisorIdCurrent) ? this.supervisorIdCurrent : document.querySelector("select[name='supervisor_select']").value
      const token = localStorage.getItem("token")
      const urlDomain = process.env.VUE_APP_API_URL.replace("api", "")
      const configExport = {
        headers: {
          "Authorization": `Bearer ${token}`,
        }
      };
      const parameters = {
        "iddistribuidora": selectDistribuidra,
        "codigosupervisor": selectSupervisor,
      }
      document.querySelector(".form_laive").classList.add("validateClient")
      axios.post(process.env.VUE_APP_API_URL+`/vendedor/exportar`,parameters,configExport)
      .then(function (response){
        document.querySelector(".form_laive").classList.remove("validateClient")
        const urlFile = urlDomain + response.data.fileName
        const link = document.createElement('a');
        link.href = urlFile;
        document.body.appendChild(link);
        link.click();
      })
    }
  },
  mounted() {
    if(this.user.rol == 3){
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
    if(this.user.rol == 4){
      this.supervisorIdCurrent = this.user.codigo
      this.supervisorNameCurrent = this.user.nombre
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
  },
  data() {
    return {
      initload: true,
      distribuidoraIdCurrent: null,
      distribuidoraNameCurrent: null,
      supervisorIdCurrent: null,
      supervisorNameCurrent: null,
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      distribuidoras:[],
      supervisores:[],
      searchQuery: "",
      propsToSearch: ["nombre", "codigoVendedor"],
      tableColumns: [
        {
          prop: "codigoVendedor",
          label: "Codigo",
          minWidth: 170,
        },
        {
          prop: "nombre",
          label: "Nombre",
          minWidth: 200,
        },
        {
          prop: "apellidos",
          label: "Apellidos",
          minWidth: 200,
        },
        {
          prop: "telefono",
          label: "Accesos Directos",
          minWidth: 160,
        },
      ],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      expands: [],
    };
  },
};
</script>
