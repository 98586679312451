<template>
  <PdfBreakPage v-if="bonificacion.esNuevaPaginaPdf"></PdfBreakPage>
  <card
    class="w-100 border shadow-none"
    headerClasses="laive-bg-clean-gray p-2"
    bodyClasses="px-1 py-2"
  >
    <template v-slot:header>
      <div class="row mx-0 px-0">
        <div class="col-1 px-1 section-title d-flex align-items-center">
          {{ bonificacion.codigo }}
        </div>
        <div
          class="col-3 px-1 border-left pl-3 section-title d-flex align-items-center"
        >
          {{ bonificacion.nombre }}
        </div>
        <div class="col-8 pl-1 pr-0">
          <div class="row mx-0 px-0 w-100">
            <div class="px-1 d-flex align-items-center" :class="puede_aprobar ? 'col-5' : 'col-9'"  >
              <div class="row mx-0 w-100">
                <div class="col-6 px-1 border-right">
                  {{ bonificacion.tipo_label }}
                </div>
                <div class="col-6 px-1 border-right">{{ bonificacion.usuario }}</div>
              </div>
            </div>
            <div class="px-1 border-right d-flex align-items-center" :class="puede_aprobar ? 'col-3' : 'col-3'"
            >
              {{ rango }}
            </div>
            <div class="col-4 pl-1 pr-0" v-if="puede_aprobar">
              <div class="row mx-0 h-100">
                <div class="col-12 d-flex align-items-center" v-if="is_submit">
                  <div class="laive-loader"></div>
                </div>
                <div class="col-6 pl-0 pr-1" v-if="!is_submit">
                  <base-button
                    type="danger"
                    :outline="true"
                    class="m-auto px-2 py-2 d-flex w-100 bonificacion-text"
                    @click.prevent="
                      cambiarEstado(constantes.BONIFICACION_ESTADO_RECHAZADA)
                    "
                  >
                    <span class="d-flex align-items-center">
                      <i class="fas fa-times"></i>
                      Rechazar</span
                    >
                  </base-button>
                </div>
                <div class="col-6 px-0" v-if="!is_submit">
                  <base-button
                    type="success"
                    :outline="true"
                    class="m-auto px-2 py-2 d-flex w-100 bonificacion-text"
                    @click.prevent="
                      cambiarEstado(constantes.BONIFICACION_ESTADO_APROBADA)
                    "
                  >
                    <span class="">
                      <i class="fas fa-check"></i>
                      Aceptar</span
                    >
                  </base-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <DetailCard :bonificacion="bonificacion"></DetailCard>
  </card>
</template>

<script setup>
import DetailCard from "./DetailCard.vue";
import PdfBreakPage from "./PdfBreakPage.vue";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import constantes from "@/model/constantes.js";
const store = useStore();

const { bonificacion, index } = defineProps({
  bonificacion: {
    type: Object,
  },
  index: {
    type: Number,
  },
});
const puede_aprobar = computed(() => {
  return store.state.auth.user.rol > 5;
})
const is_submit = ref(false);
const cambiarEstado = async (estado) => {
  is_submit.value = true;
  const payload = {
    idbonificacionbonhistoricodistribuidora: bonificacion.relaciones_ids.join(","),
    estado,
  };
  const resultado = await store.dispatch("bonificacion/cambiarEstado", payload);

  if (resultado.status == constantes.RESPONSE_OK) {
    store.commit(
      "bonificacion/removerDePorAprobar",
      bonificacion.relaciones_ids
    );
  }
  is_submit.value = false;
};
const formatearFecha = (fecha) => {
    if (!fecha) {
      return "-";
    }
    const meses = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];

    const dia = fecha.getDate();
    const mes = meses[fecha.getMonth()];
    const anio = fecha.getFullYear();
    const dia_st = dia < 10 ? `0${dia}` : dia;
    return `${dia_st} ${mes} ${anio}`;
  },
  rango = computed(() => {
    const inicio = new Date(bonificacion.inicio);
    const fin = new Date(bonificacion.fin);

    return `${formatearFecha(inicio)} a ${formatearFecha(fin)}`;
  });
</script>
