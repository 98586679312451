export const BTN_TYPE = {
	outline: "outline",
	solid: "solid",
	link: "link",
};

export const BADGE_TYPE = {
	chip: "chip",
	solid: "solid",
};
