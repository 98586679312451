<template>
  <template v-for="tag in tags">
    <component :is="tag" v-if="tag == as" :style="[cssProps]" class="ui-title" v-bind="$attrs">
      <slot>
        {{ text }}
      </slot>
    </component>
  </template>
</template>

<script>
import { ThemeProps } from "./utils";
export default {
  data() {
    return {
      tags: ["h1", "h2", "h3", "h4"]
    }
  },
  props: {
    as: {
      type: String,
      default: "h1",
      validator(as) {
        return ["h1", "h2", "h3", "h4"].includes(as);
      }
    },
    text: String,
    ...ThemeProps.textProps
  },
  computed: {
    cssProps() {
      return ThemeProps.styleText(this.$props);
    }
  }
}

</script>

<style scoped>
.ui-title {
  font-size: var(--ui-fs);
  font-weight: var(--ui-fw);
  color: var(--ui-text-color);
}
</style>





