<template>
<div class="content">
    <base-header class="pb-6">
        <div class="row align-items-center pb-4">
            <div class="col-lg-6 col-7">
                <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                    <route-bread-crumb></route-bread-crumb>
                </nav>
            </div>
            <div class="col-lg-6 col-5 d-none d-md-inline-block">
                <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
            </div>
        </div>
    </base-header>
    <div class="container-fluid mt--6">
        <div class="form_laive " :class="{validateClient:isLoading}">
            <!--validateClient-->
            <div class="box_loader"> <div class="loader"></div></div>
            <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
                <template v-slot:header>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <h3 class="mb-0">Listado de Notificaciones</h3>
                        </div>
                        <div class="col-12 col-md-6 text-right">

                        </div>
                    </div>
                </template>
                <div>
                    <div class="row px-3">
                        <div class="col-12 mb-3">
                            <div class="row">
                                <div class="col">
                                    <template v-if="this.user.rol == 3 || this.user.rol == 4">
                                        <div class="title_filter mr-2">
                                            <div>Distribuidora:</div> <strong>{{distribuidoraName}}</strong>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <span class="title_filter mr-2">Distribuidoras: </span>
                                        <select v-model='filter.idDistribuidora' class="form-control" style="display: inline-block;height: 43px;" name="distributor_select" @change="selectChangeDistribuidora">
                                            <option value="0" selected>Todos</option>
                                            <option v-for="item in distribuidoras" :value="item.idDistribuidora" :key="item.idDistribuidora">
                                                {{ item.razonSocial }}
                                            </option>
                                        </select>
                                    </template>
                                </div>
                                <div class="col">
                                    <template v-if="this.user.rol == 4">
                                        <span class="title_filter mr-2">Supervisor:<br><strong>{{supervisorName}}</strong></span>
                                    </template>
                                    <template v-else>
                                        <span class="title_filter mr-2">Supervisor: </span>
                                        <select v-model='filter.idSupervisor' class="form-control" id="supervisor_select" style="display: inline-block;height: 43px;" name="supervisor_select" @change="selectChangeSupervisor">
                                            <option value="">Todos</option>
                                            <option v-for="item in supervisores" :value="item.codigoSupervisor" :key="item.codigoSupervisor">
                                                {{ item.nombre }}
                                            </option>
                                        </select>
                                    </template>
                                </div>
                                <div class="col">
                                    <span class="title_filter mr-2">Vendedor: </span>
                                    <select v-model='filter.idVendedor' class="form-control" id="vendedor_select" style="display: inline-block;height: 43px;" name="vendedor_select" @change="selectChangeVendedor">
                                        <option value="">Todos</option>
                                        <option v-for="item in vendedores" :value="item.codigoVendedor" :key="item.codigoVendedor">
                                            {{ item.nombre }} {{ item.apellidos }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-2 mb-3 d-none">
                            <div class="mr-2">
                                <span class="title_filter">Paginación: </span>
                                <el-select class="select-primary pagination-select" v-model="lTable.pagination.perPage" style="width:80px" placeholder="Per page">
                                    <el-option class="select-primary" v-for="item in lTable.pagination.perPageOptions" :key="item" :label="item" :value="item">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <!-- Real Table -->
                    <div class="el-table table_report pesos notoverflow">
                        <table width="100%" border="0">
                            <thead class="el-table__header-wrapper">
                                <th v-for="(item, i) in lTable.columns" :key="item.prop" :style="(i == 1) ? `` : ``">
                                    {{ item.label }}
                                </th>
                                <th></th>
                            </thead>
                            <tbody>
                                <template v-if="lTable.data">
                                    <tr v-for="(value) in lTable.data" :key="value.razonSocial">
                                        <td class='font-weight-bold'>{{value.codigoVendedor ? value.codigoVendedor : '---'}}</td>
                                        <td>{{value.nombre ? value.nombre : '---'}}</td>
                                        <td>{{value.apellido ? value.apellido : '---'}}</td>
                                        <td class='text-laive'>{{value.total ? value.total : '---'}}</td>
                                        <td>
                                            <a type="info" style="cursor:pointer!important" class="detail" size="sm" @click="showDetail(value)" aria-pressed="true"><i class='fa fa-search text-laive'></i> Detalle</a>
                                        </td>
                                    </tr>
                                </template>
                                <tr v-else>
                                    <td>No se encontraron notificaciones</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <template v-slot:footer>
                    <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                        <div class="">
                            <p class="card-category d-none">
                                Mostrando {{ lTable.from + 1 }} a {{ lTable.to }} de {{ lTable.total }} Notificaciones
                            </p>
                        </div>
                        <default-pagination class="pagination-no-border" v-model="lTable.pagination.currentPage" :per-page="lTable.pagination.perPage" :total="lTable.total" @click="changePaginate">
                        </default-pagination>
                    </div>
                </template>
            </card>
        </div>
    </div>
</div>
</template>

<script>
let fixClickOpenModal = 1
import {
    ElTable,
    ElTableColumn,
    ElSelect,
    ElOption,
    ElInput,
} from "element-plus";
import axios from "axios";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import TabPane from "@/components/Tabs/TabPane";
import BasePagination from "@/components/BasePagination";
import swal from "sweetalert2";
import DashboardNavbar from "../Layout/DashboardNavbar";
import {
    LiquidTable,
    LiquidAlert
} from "../../util/liquid";
import {
    mapActions,
    mapGetters
} from 'vuex';
export default {
    components: {
        DashboardNavbar,
        BasePagination,
        RouteBreadCrumb,
        TabPane,
        [ElSelect.name]: ElSelect,
        [ElOption.name]: ElOption,
        [ElTable.name]: ElTable,
        [ElInput.name]: ElInput,
        [ElTableColumn.name]: ElTableColumn,
    },
    async created() {
        this.lTable = new LiquidTable();
        this.lTable.pagination = {
            perPage: 2,
            currentPage: 1,
            newcurrentPage: 1,
            perPageOptions: [5, 10, 25, 50],
            total: 1,
        };
        this.lTable.columns = [{
                prop: "codigo",
                label: "Código",
                minWidth: 120,
            },
            {
                prop: "Nombre",
                label: "nombre",
                minWidth: 160,
            },
            {
                prop: "Apellido",
                label: "Apellido",
                minWidth: 160,
            },
            {
                prop: "notificaciones",
                label: "Notificaciones",
                minWidth: 100,
                minWidth:100
            },
            {
                prop: "Detalle",
                label: "Detalle",
                minWidth: 100,
                minWidth:100
            },
        ];

    },
    computed: {
        ...mapGetters({
            authenticated: 'auth/authenticated',
            user: 'auth/user',
        }),
        async getDistribuidora() {

            try{
                let result = await this.getDistributorList();
                this.distribuidoras = result.data ? result.data : [];
            }
            catch(ex){ex}
        },

        async getSupervisor() {

            try{
                this.supervisores =[];
                if(this.filter.idDistribuidora != 0){
                    let result = await this.getSupervisorList(this.filter.idDistribuidora != 0 ? this.filter.idDistribuidora :undefined);
                    this.supervisores = result.data ? result.data : [];
                }
            }
            catch(ex){ex}
        },

        async getVendedor() {
            try{
                this.vendedores=[];
                if(this.filter.idSupervisor  != '' ){
                    let result = await this.getVendedorByDistSuperList({
                        iddistribuidora: this.filter.idDistribuidora,
                        codigosupervisor: this.filter.idSupervisor
                    });
                    this.vendedores = result.data ? result.data : [];
                }
            }
            catch(ex){console.log(ex)}
        },

        async getNotificacion() {
            try{
                this.isLoading = true;
                let result = await this.getNotificacionList({
                    page: this.lTable.pagination.currentPage,
                    idDistribuidora: this.filter.idDistribuidora != 0 ? this.filter.idDistribuidora :undefined,
                    codigoSupervisor: this.filter.idSupervisor,
                    codigoVendedor: this.filter.idVendedor

                });
                this.isLoading = false;
                if (result.data) {
                    this.lTable.pagination.perPage =  result.data.data.length;
                    this.lTable.data = result.data.data;
                }
            }
            catch(ex){console.log(ex);this.isLoading = false;}
        },

        async getNotificacionDetalle() {
            let result = await this.getNotificacionDetalleList({
                id: '1.001',
                horaOrden: 'desc'
            });
            console.log(result)
        },

        distribuidoraName() {
            let x = this.distribuidoras.find(x => x.idDistribuidora == this.filter.idDistribuidora);
            return x ? x.razonSocial : '---';
        },
        supervisorName() {
            let x = this.supervisores.find(x => x.codigoSupervisor == this.filter.idSupervisor);
            /*return x ? x.nombre: 'loading...';*/
            return this.user.nombre;
        },

    },
    methods: {
        ...mapActions({
            getNotificacionList: 'notification/getList',
            getNotificacionDetalleList: 'notification/getDetalle',
            getDistributorList: 'distribuidora/getList',
            getSupervisorList: 'supervisor/getListById',
            getVendedorByDistSuperList: 'vendedor/getListByDistribuidoraSupervisor',
        }),
        async selectChangeDistribuidora() {
            if (this.filter.idDistribuidora == 0) {
                this.filter.idSupervisor = '';
                this.filter.idVendedor = '';
            }
            await this.getSupervisor;
            await this.getVendedor;
            await this.getNotificacion;

        },

        async selectChangeSupervisor() {
            if (this.filter.idSupervisor == '') this.filter.idVendedor = '';
            await this.getVendedor;
            await this.getNotificacion;

        },

        async selectChangeVendedor() {
            await this.getNotificacion;
            await this.getNotificacionDetalle;
        },

        async showDetail(row) {
            LiquidAlert.loading('Mis notificaciones');
            let result = await this.getNotificacionDetalleList({
                id: row.id,
                horaOrden: 'desc'
            });
            console.log(result.data)
            if (result.data) {
                let rows = ``;
                    for (let item of result.data) {
                        rows += `<tr>
                        <td class='text-center'><i class='ni ${(item.tipoMensaje == 2 ? 'ni-danger-exclamation' :'ni-warning-exclamation')}'><i></td>
                        <td>${item.mensaje}</td>
                        <td class='font-weight-bold'>${item.hora}</td>
                        </tr>`
                    }
                let html = `
                    <table class="table_laive">
                        <tr>
                        <th class='text-center'>Alerta</th>
                        <th>Descripción</th>
                        <th>Hora</th>
                        </tr>
                        ${rows}
                    </table>`;

                LiquidAlert.html('Notificaciones',html );
            } else {

            }
        },
    },

    async mounted() {

        try{
            if (this.user.rol == 3) {
                this.filter.idDistribuidora = this.user.idDistribuidora;
                await this.getDistribuidora;
                await this.getSupervisor;
                await this.getVendedor;
            }
            if (this.user.rol == 4) {
                this.filter.idDistribuidora = this.user.idDistribuidora
                this.filter.idSupervisor = this.user.codigo
                await this.getDistribuidora;
                await this.getSupervisor;
                await this.getVendedor;
            }
            else{
                await this.getDistribuidora;
                await this.getSupervisor;
                await this.getVendedor;
            }
            await this.getNotificacion;
        }
        catch(ex){ }
    },
    data() {
        return {
            isLoading: false,
            lTable: LiquidTable,
            distribuidoras: [],
            supervisores: [],
            vendedores: [],
            filter: {
                idDistribuidora: 0,
                idSupervisor: '',
                idVendedor: ''
            }
        };
    },
};
</script>
