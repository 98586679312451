<template>
  <div
    v-show="type !== 'hidden'"
    class="TextInput"
    :class="{ 'has-error': !!errorMessage || !!userError, success: meta.valid }"
    :style="{ paddingTop: helpMessage !== '' ? '4px' : '0' }"
  >

    <span class="title_filter mr-2">{{ label }}</span>

    <div class="text-container position-relative overflow-hidden">

        <input
          ref="textInputRef" 
          class="field"
          :class="inputValue !== '' ? setClass + ' with-content' : setClass, error && 'error'"

          :name="name"
          :id="name"
          :type="type"
          :value="inputValue"
          :modelValue="inputValue"
          :placeholder="placeholder"
          @input="onInput($event)"
          @blur="handleInputBlur"
          :maxlength="maximumLength ? maximumLength : ''"
          :readonly="readonly"
          :style="styleFormat"
        />

        <i class="laive-i icon-search" v-if="isSearch"></i>

    </div>

    <p class="help-message text-gray" v-show="helpMessage && !error && !userError">
      {{ helpMessage }}
    </p>

    <p class="error-message" v-show="userError || !meta.valid">
      {{ customErrorMessage || errorMessage }}
    </p>
  </div>
</template>

<script>
import { ref, onMounted, watch, watchEffect, nextTick, toRef } from "vue";
import { useField } from "vee-validate";

export default {
  data() {
    return {
      error: false
    };
  },
  props: {
    setClass: {
      type: String
    },
    styleFormat: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: "text",
    },
    value: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Number],
      description: "Input value",
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    userError: {
      type: Boolean,
      default: false,
    },
    customErrorMessage: {
      type: String,
      default: "",
    },
    errorMessage: {
      type: String,
      default: "",
    },
    successMessage: {
      type: String,
      default: "",
    },
    helpMessage: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    optional: {
      type: Boolean,
      default: false,
    },
    validateOnMount: {
      type: Boolean,
      default: false,
    },
    showCurrency: {
      type: Boolean,
      default: false,
    },
    maximumLength: {
      type: [Boolean, Number, String],
      default: false,
    },
  },
  setup(props, {emit}) {
    // use `toRef` to create reactive references to `name` prop which is passed to `useField`
    // this is important because vee-validte needs to know if the field name changes
    // https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
    const name = toRef(props, "name");
    
    const textInputRef = ref(null);

    // we don't provide any rules here because we are using form-level validation
    // https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(name, undefined, {
      validateOnMount: props.validateOnMount,
      initialValue: props.value,
    });

    watchEffect(() => {
      if (props.modelValue !== '') {
        inputValue.value = props.modelValue;
      }
    });

    
    const handleInputBlur = (event) => {      
      if (event.target.value === '') {
        textInputRef.value.classList.remove("with-content");
      }
    };

    const onInput = (event) => {
      handleChange(event, true);
      emit('update:modelValue', event.target.value);
    };

    onMounted(() => {
      watch(() => props.modelValue, (val) => {
        if (val !== '') {
          nextTick(() => {
            inputValue.value = val;
          });
        }
      });
    });



    if (props.readonly) {
      meta.valid = true;
    }

    if (props.optional) {
      meta.valid = true;
    }

    return {
      onInput,
      textInputRef,
      handleInputBlur,
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      modelValue: props.modelValue,
      meta,
    };
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    /*clickLabel($event) {
      const parent = $event.target.parentNode;
      parent.querySelector(".laive-inputField").classList.add("laive-inputField--withContent");
      parent.querySelector(".laive-inputField").focus()
    },*/

    clickLabel() {
      this.inputValue = this.modelValue; // Actualiza el valor del campo de entrada
      const parent = this.$el.querySelector(".field");
      parent.classList.add("with-content");
      parent.focus();
    },

  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/sass/laive-components/input-field.scss";
@import "../../assets/sass/laive-components/icons.scss";

.TextInput {
  width: 100%;

  // Agrega un padding derecho para dar espacio al label
  .label-wrapper {
    padding-right: 10px; // Ajusta el valor según tus necesidades
  }

  .laive-inputLabel {
    // Trunca el texto con tres puntos si el espacio es insuficiente
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.has-error {

    input {
      border: 1px solid #ed1c24;
    }

  }

  .error-message {
    color: #ed1c24;
    padding-top: 4px;
    font-size: 9px;
    line-height: 10px;

    @media (min-width: 1600px) {
      font-size: 12px;
    }
  }

  .help-message {
    padding-top: 4px;
    font-size: 9px;
    line-height: 10px;

    @media (min-width: 1600px) {
      font-size: 12px;
    }
  }
}
</style>