<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive validateClient">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
          >
          <template v-slot:header>
            <div class="row">
              <div class="col-12 col-md-6">
                <h3 class="mb-0">Productos Cluster</h3>
              </div>
              <div class="col-12 col-md-6 text-right">
                <button type="button" @click="exportProductosCluster" class="btn base-button btn-primary btn-sm" role="button"><i class="fas fa-file-export"></i>Exportar </button>
                <template v-if="user.rol < 3">
                  <!-- <router-link to="listado-productos-cluster/crear/"><base-button size="sm" type="info">Crear Producto Cluster</base-button></router-link> -->
                </template>
              </div>
            </div>
          </template>
          <div>
            <div class="row px-4">
              <div class="col-12 mb-3">
                <div class="row">
                  <div class="col-12 col-md-4 d-none">
                    <el-input
                      type="search"
                      clearable
                      prefix-icon="el-icon-search"
                      placeholder="Buscar producto"
                      v-model="searchQuery"
                      aria-controls="datatables"
                    >
                    </el-input>
                  </div>
                  <div class="col-12 col-md-4">
                    <template v-if="distribuidoraIdCurrent">
                      <span class="title_filter mr-2">Distribuidora:  <strong>{{distribuidoraNameCurrent}}</strong></span>
                    </template>
                    <template v-else>
                      <span class="title_filter mr-2">Distribuidoras:  </span>
                      <select class="form-control" style="display: inline-block;height: 43px;" name="distributor_select" @change="changeDistributor">
                        <option value="0">Todos</option>
                        <option
                          v-for="item in distribuidoras"
                          :value="item.idDistribuidora"
                          :key="item.idDistribuidora"
                          >
                          {{ item.razonSocial }}
                        </option>
                      </select>
                    </template>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-2 d-none">
                <div class="mr-2">
                  <!-- <span class="title_filter">Paginación:  </span> -->
                  <el-select
                    class="select-primary pagination-select"
                    v-model="pagination.perPage"
                    style="width:80px"
                    placeholder="Per page"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <!-- Real Table -->
            <el-table
              :data="queriedData"
              row-key="idProducto"
              empty-text="Debe seleccionar una Distibuidora"
              header-row-class-name="thead-light"
            >
              <el-table-column label="Código" min-width="134px" prop="codigoGrupo">
                <template v-slot="{ row }">
                  {{ (row.codigoGrupo.length) ? row.codigoGrupo : '---'}}
                </template>
              </el-table-column>

              <el-table-column label="Griro de Negocio" min-width="220px" prop="giroNegocio">
                <template v-slot="{ row }">
                  {{ (row.giroNegocio) ? row.giroNegocio : '---'}}
                </template>
              </el-table-column>

              <el-table-column label="Cluster" min-width="220px" prop="cluster">
                <template v-slot="{ row }">
                  {{ (row.cluster) ? row.cluster : '---'}}
                </template>
              </el-table-column>

              <el-table-column label="Segmento" min-width="220px" prop="segmento">
                <template v-slot="{ row }">
                  {{ (row.segmento) ? row.segmento : '---'}}
                </template>
              </el-table-column>

              <el-table-column label="Grupo Venta" min-width="220px" prop="grupoVenta">
                <template v-slot="{ row }">
                  {{ (row.grupoVenta) ? row.grupoVenta : '---'}}
                </template>
              </el-table-column>

              <el-table-column label="Fecha Creación" min-width="220px" prop="fechaCrea">
                <template v-slot="{ row }">
                  {{ (row.fechaCrea) ? row.fechaCrea : '---'}}
                </template>
              </el-table-column>

              <el-table-column min-width="120px" align="right">
                <template v-slot:default="props">
                  <base-button type="info" class="detail" size="sm" role="button" @click="openDetail(props.$index, props.row)" aria-pressed="true">Detalle</base-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <template v-slot:footer>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category d-none">
                  Mostrando {{ from + 1 }} a {{ to }} de {{ total }} productos
                </p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.newcurrentPage"
                :per-page="pagination.perPage"
                :total="total"
                @click="changePaginate"
              >
              </base-pagination>
            </div>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
let fixClickOpenModal = 1
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import swal from "sweetalert2";
import axios from "axios";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BasePagination from "@/components/BasePagination";
import {mapGetters} from 'vuex';
import DashboardNavbar from "../Layout/DashboardNavbar";
export default {
  components: {
    DashboardNavbar,
    BasePagination,
    RouteBreadCrumb,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  computed: {
    pagedData() {
      this.getDistributor
      this.getDataCluster
      if(this.tableData.length){
        this.searchedData = this.tableData
        return this.tableData
        // return this.tableData.slice(this.from, this.to);
      }
    },
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString().toLowerCase();
          if (rowValue.includes && rowValue.includes(this.searchQuery.toLowerCase())) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });
      console.log(result.length);
      this.searchedData = result
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    }),
    getDistributor(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let urlApiDistribuidoras = process.env.VUE_APP_API_URL+"/distribuidoras/lista"
      const requestDistribuidoras = axios.get(urlApiDistribuidoras, config);
      axios.all([requestDistribuidoras]).then(axios.spread((...responses) => {
        const responseDistribuidoras = responses[0].data
        document.querySelector(".form_laive").classList.remove("validateClient")
        if($this.distribuidoraIdCurrent){
          let dataDistibuitorCurrent =  responseDistribuidoras.filter((item)=>{return item.idDistribuidora == $this.distribuidoraIdCurrent})
          $this.distribuidoraNameCurrent = dataDistibuitorCurrent[0].razonSocial
        }
        $this.distribuidoras = responseDistribuidoras.filter((item)=>{return item.estado == "1"})
        return $this.distribuidoras
      })).catch(errors => {
        // react on errors.
      })
    },
    getDataCluster(){
      const $this = this
      let idDistribuidora = Number(this.distribuidoraIdCurrent)
      if(idDistribuidora){
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        const parameters = {
          "iddistribuidora": idDistribuidora,
          "page": "1"
        }
        axios.post(process.env.VUE_APP_API_URL+"/producto/listar-producto-cluster-grupo", parameters,config)
        .then(function (response){
          document.querySelector(".form_laive").classList.remove("validateClient")
          $this.pagination.perPage = response.data.data.length
          $this.pagination.total = response.data.totalPage
          return $this.tableData = response.data.data
        })
      }
    },
  },
  methods: {
    openDetail(index, row){
      let tableHtML = ""
      const swalWithBootstrapButtons1 = swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          container: "swal-cluster",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons1.fire({
        title: `Lista de Productos Cluster`,
        html: `
              <div class="form_laive validateClient">
                <div class="box_loader">
                  <div class="loader"></div>
                </div>
                <br>
                <br>
                <br>
                <br>
                <br>
              </div>`,
        showConfirmButton: false,
      });
      // get Detail Pedido
      const token = localStorage.getItem("token")
      const idDistribuidora = (this.distribuidoraIdCurrent) ? this.distribuidoraIdCurrent : Number(document.querySelector("select[name='distributor_select']").value)
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const parameters = {
        "idDistribuidora": idDistribuidora,
        "codigoGrupo": row.codigoGrupo
      }
      if(fixClickOpenModal == 1){
        ++fixClickOpenModal
        axios.post(process.env.VUE_APP_API_URL+`/producto/listar-producto-cluster`,parameters,config)
        .then(function (response){
          fixClickOpenModal = 1
          const dataGroupCluaster = response.data
          dataGroupCluaster.forEach(item => {
            tableHtML += `
                    <tr>
                      <td>${item.codigoProducto}</td>
                      <td>${item.codigoFabrica}</td>
                      <td>${item.codigoSunat}</td>
                      <td>${item.descripcion}</td>
                      <td>${item.unidadBase}</td>
                      <td>${item.unidadReferencia}</td>
                      <td>${item.unidadCompra}</td>
                      <td>${item.categoria}</td>
                      <td>${item.familia}</td>
                      <td>${item.presentacion}</td>
                      <td>${item.marca}</td>
                    </tr>`
          });
          if(dataGroupCluaster.length){
            swalWithBootstrapButtons1.fire({
              title: `Lista de Productos Cluster`,
              html: `
                  <table class="table_laive">
                    <tr>
                      <th>Código</th>
                      <th>C. Fábrica</th>
                      <th>C. Sunat</th>
                      <th style="width:260px;">Descripción</th>
                      <th>U. Base</th>
                      <th>U. Referencia</th>
                      <th>U. Compra</th>
                      <th>Categoria</th>
                      <th>Familia</th>
                      <th>Presentación</th>
                      <th>Marca</th>
                    </tr>
                    ${tableHtML}
                  </table>`,
              confirmButtonText: "Cerrar",
              showConfirmButton: true,
            });
          }else{
            swalWithBootstrapButtons1.fire({
              title: `No se encontraron resultados`,
              confirmButtonText: "Cerrar",
              showConfirmButton: true,
            });
          }
        })
      }
    },
    changeDistributor(event) {
      const $this = this;
      const select = event.target
      const value = Number(select.value);
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const parameters = {
        "iddistribuidora": value,
        "page": "1"
      }
      document.querySelector(".form_laive").classList.add("validateClient")
      axios.post(process.env.VUE_APP_API_URL+"/producto/listar-producto-cluster-grupo", parameters,config)
      .then(function (response){
        document.querySelector(".form_laive").classList.remove("validateClient")
        $this.pagination.perPage = response.data.data.length
        $this.pagination.total = response.data.totalPage
        return $this.tableData = response.data.data
      })
    },
    changeGrupoVenta(event) {
      const $this = this;
      const select = event.target
      const value = select.value
      const selectDistribuodra = Number(document.querySelector("[name='distributor_select']").value)
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const parameters = {
        "iddistribuidora": selectDistribuodra,
        "codigocategoria": "",
        "grupoventa": value
      }
      document.querySelector(".form_laive").classList.add("validateClient")
      axios.post(process.env.VUE_APP_API_URL+"/producto/lista-producto-foco", parameters,config)
      .then(function (response){
        document.querySelector(".form_laive").classList.remove("validateClient")
        $this.pagination.perPage = response.data.data.length
        $this.pagination.total = response.data.totalPage
        return $this.tableData = response.data.data
      })
    },
    changePaginate(evn){
      const $this = this
      const listPaginator = document.querySelectorAll("ul.pagination .page-item")
      const idDistribuidora = (this.distribuidoraIdCurrent) ? this.distribuidoraIdCurrent : Number(document.querySelector("select[name='distributor_select']").value)
      let elm = evn.target;
      let sendAjax = false;
      let parentEl = elm.parentNode
      if(elm.classList.contains("prev-page")){
        if(!elm.classList.contains("disabled")){
          let linkActivePrev = document.querySelectorAll("ul.pagination .page-item.active")
          linkActivePrev[0].classList.remove("active")
          linkActivePrev[0].previousSibling.classList.add("active")
          sendAjax = true
        }
      }else if(elm.classList.contains("next-page")){
      }else{
        if(elm.getAttribute("aria-label") == "Previous"){
          let linkActivePrev = document.querySelectorAll("ul.pagination .page-item.active")
          linkActivePrev[0].classList.remove("active")
          linkActivePrev[0].previousSibling.classList.add("active")
          sendAjax = true
        }else if(elm.getAttribute("aria-label") == "Next"){
          let linkActiveNext = document.querySelectorAll("ul.pagination .page-item.active")
            linkActiveNext[0].classList.remove("active")
            linkActiveNext[0].nextSibling.classList.add("active")
            sendAjax = true
        }else{
          if(!parentEl.classList.contains("active")){
            listPaginator.forEach(item => {
              item.classList.remove("active")
            });
            parentEl.classList.add("active")
            sendAjax = true
          }
        }
      }
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const parameters = {
          "page": this.pagination.newcurrentPage,
          "idDistribuidora": idDistribuidora,
      }
      if(sendAjax){
        document.querySelector(".form_laive").classList.add("validateClient")
        axios.post(process.env.VUE_APP_API_URL+"/producto/listar-producto-cluster-grupo",parameters,config)
        .then(function (response){
          document.querySelector(".form_laive").classList.remove("validateClient")
          console.log(response.data.data)
          sendAjax = false
          $this.pagination.currentPage = 1
          return $this.tableData = response.data.data
        })
      }
    },
    exportProductosCluster(){
      const selectDistribuidra = (this.distribuidoraIdCurrent) ? this.distribuidoraIdCurrent : Number(document.querySelector("select[name='distributor_select']").value)
      const token = localStorage.getItem("token")
      const urlDomain = process.env.VUE_APP_API_URL.replace("api", "")
      const configExport = {
        headers: {
          "Authorization": `Bearer ${token}`,
        }
      };
      const parameters = {
        "idDistribuidora": selectDistribuidra,
      }
      console.log(parameters, " selectt export");
      document.querySelector(".form_laive").classList.add("validateClient")
      axios.post(process.env.VUE_APP_API_URL+`/producto/exportar-producto-cluster`,parameters,configExport)
      .then(function (response){
        document.querySelector(".form_laive").classList.remove("validateClient")
        const urlFile = urlDomain + response.data.fileName
        // const url = window.URL.createObjectURL(new Blob([response.data], {type:'application/vnd.ms-excel'}));
        const link = document.createElement('a');
        link.href = urlFile;
        // link.setAttribute('download',`productos-foco-${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
    }
  },
  mounted() {
    if(this.user.rol == 3){
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
    if(this.user.rol == 4){
      this.supervisorIdCurrent = this.user.codigo
      this.supervisorNameCurrent = this.user.nombre
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
  },
  data() {
    return {
      distribuidoraIdCurrent: null,
      distribuidoraNameCurrent: null,
      supervisorIdCurrent: null,
      supervisorNameCurrent: null,
      pagination: {
        perPage: 25,
        currentPage: 1,
        newcurrentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      distribuidoras:[],
      gruposventa:[],
      categories:[],
      searchQuery: "",
      propsToSearch: ["codigoGrupo"],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      expands: [],
    };
  },
};
</script>
<style>
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
.no-border-card .card-footer {
  border-top: 0;
}
/* .card-body{
  padding-top: 0 !important;
} */
.card-body .title_filter{
  font-size: 14px;
  font-weight:500;
}
.swal2-container .swal2-header{
  padding: 0;
}
.swal2-container .swal2-content{
  padding: 0;
}
.swal2-container .swal2-popup #swal2-title{
  font-size: 1.2rem;
}
.base-button i{
  position: relative;
  top: 1px;
}
.swal-cluster .swal2-popup{
  max-width: 1400px;
  width: 100%;
}
</style>
