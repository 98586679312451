<template>
  <div
    class="border rounded px-2"
    style="line-height: 0.5"
    :class="{
      [aditional_class]: aditional_class,
      'bonificacion-form-wrapper': !fit_content,
      'fit-content': fit_content,
      'bonificacion-form-wrapper-sm': fit_content,
      'bonificacion-multiple-stacked': is_multiple && !collapse_tags,
    }"
    :id="componenteId"
  >
    <label class="col-form-label form-control-label pb-0" v-if="label">
      {{ label }}
    </label>
    <el-form>
      <el-form-item
        class="mb-0"
        :class="{
          'px-2': fit_content,
          'el-form-item-top-12': !!label,
        }"
      >
        <el-select
          v-if="show_dropdown"
          :multiple="is_multiple"
          :collapse-tags="collapse_tags"
          :filterable="filterable"
          v-model="selecteds"
          class="w-100"
          :placeholder="placeholder ?? 'Selecciona'"
          @change="cambiaSelect"
          :disabled="disabled"
        >
          <el-option
            v-if="is_multiple && puede_seleccionar_todos"
            value="todos"
            label="Todos"
            class="h-auto border-bottom"
            :class="todos_seleccionados ? 'selected' : ''"
            @click="seleccionarTodos"
          >
            <div class="form-check py-2 d-flex align-items-center">
              <input
                type="checkbox"
                class="form-check-input bonificacion-form-check-input"
                :id="`el-todos`"
              />
              <label
                class="pl-2 form-check-label bonificacion-form-check-label w-100"
                >Todos</label
              >
            </div>
          </el-option>
          <el-option
            v-for="option in options"
            :value="option.value"
            :label="option.label"
            :key="option + label"
            class="h-auto"
          >
            <div
              class="form-check py-2 d-flex align-items-center"
              v-if="is_multiple"
            >
              <input
                type="checkbox"
                class="form-check-input bonificacion-form-check-input"
                :id="`el-${option.value}`"
              />
              <label
                class="pl-2 form-check-label bonificacion-form-check-label w-100"
                v-html="option.label"
              ></label>
            </div>
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { nextTick } from "vue";
import { ElSelect, ElOption, ElForm, ElFormItem } from "element-plus";

export default {
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElForm.name]: ElForm,
    [ElFormItem.name]: ElFormItem,
  },
  props: {
    modelValue: { type: [Object, Number, String], required: true },
    label: String,
    aditional_class: String,
    placeholder: String,
    options: Array,
    is_multiple: Boolean,
    collapse_tags: Boolean,
    filterable: Boolean,
    puede_seleccionar_todos: Boolean,
    resumir_tags: Boolean,
    disabled: Boolean,
    fit_content: Boolean,
  },

  methods: {
    seleccionarTodos() {
      if (this.todos_seleccionados == true) {
        this.selecteds = [];
      } else {
        this.selecteds = this.options.map((item) => item.value);
      }
    },
    cambiaSelect() {
      this.comprobarChecks();
    },
    comprobarTags() {
      if (this.collapse_tags && this.resumir_tags == true) {
        const envoltorio = document.getElementById(`${this.componenteId}`);

        if (this.options.length > 1) {
          const elementos = envoltorio.querySelectorAll(".el-tag");

          if (elementos.length == 2) {
            elementos[0].classList.add("d-none");
            const contenedor_texto = elementos[1].querySelectorAll(
              ".el-select__tags-text"
            )[0];

            if (this.todos_seleccionados == true) {
              contenedor_texto.textContent = `Todos`;
            } else {
              const nuevoContenido = this.selecteds.length;
              contenedor_texto.textContent = `${nuevoContenido}`;
            }
          } else if (elementos.length == 1) {
            elementos[0].classList.remove("d-none");
            const contenedor_texto = elementos[0].querySelectorAll(
              ".el-select__tags-text"
            )[0];
            contenedor_texto.textContent = `1`;
          }
        }
      }
    },
    async comprobarChecks() {
      await nextTick();
      const elementos = document.querySelectorAll(".el-select-dropdown__item");
      setTimeout(() => {
        elementos.forEach((elemento) => {
          const checkbox = elemento.querySelector('input[type="checkbox"]');
          if (checkbox) {
            if (elemento.classList.contains("selected")) {
              checkbox.checked = true;
            } else {
              checkbox.checked = false;
            }
          }
        });
        this.comprobarTags();
        let elSelectTagsText = document.querySelector(".el-select__tags-text");
        if (elSelectTagsText) {
          elSelectTagsText.style.maxWidth = "none";
        }
      }, 100);
    },
  },
  data() {
    return {
      selecteds: this.modelValue,
      componenteId: `componente_${new Date().getTime()}-mt-${Math.random().toString(
        36
      )}`,
      show_dropdown: false,
    };
  },
  watch: {
    selecteds(nuevo, antiguo) {
      this.$emit("update:modelValue", nuevo);
    },
    options(nuevo, antiguo) {
      this.comprobarChecks();
    },
    modelValue(nuevo) {
      this.selecteds = nuevo;
    },
  },
  mounted() {
    setTimeout(() => {
      this.show_dropdown = true;
      setTimeout(() => {
        this.comprobarChecks();
      }, 200);
    }, 100);
  },
  computed: {
    todos_seleccionados() {
      const selecteds = this.selecteds.filter(item => item!="todos");
      return selecteds.length >= this.options.length;
    },
  },
};
</script>
<style lang="scss">
.el-input__inner {
  border: none !important;
  padding-left: 0px !important;
  font-size: 11px !important;
}
.el-tag__close {
  color: #535f7f !important;
}
.bonificacion-form-wrapper {
  .el-select .el-select__tags span.el-tag {
    display: flex !important;
    background: #f7f9fc !important;
    color: #535f7f !important;
    box-shadow: none !important;
    padding: 0px 3px !important;
  }
  .el-select .el-select__tags span.el-tag.d-none {
    display: none !important;
  }
}

.bonificacion-form-wrapper-sm {
  .el-select .el-select__tags span.el-tag {
    background: transparent !important;
    color: #535f7f !important;
    box-shadow: none !important;
    padding: 0px 3px !important;
  }
  .el-form-item {
    height: 30px;
    overflow: hidden;
  }
}

.el-select .el-select__tags-text,
.bonificacion-form-check-label,
.el-select-dropdown__item span {
  font-size: 11px !important;
}

.el-select-dropdown__item.selected {
  color: #02a54f !important;
}
.el-select__tags {
  padding: 0px !important;
}

input[type="checkbox"].bonificacion-form-check-input {
  appearance: none; /* Restablecer los estilos nativos del sistema operativo */
  -webkit-appearance: none; /* Para navegadores basados en WebKit como Chrome y Safari */
  -moz-appearance: none; /* Para Firefox */

  width: 18px; /* Ancho personalizado */
  height: 18px; /* Altura personalizada */
  border: 2px solid #b8b8b8; /* Borde más ancho y verde */
  outline: none; /* Eliminar el contorno predeterminado */
  cursor: pointer;
  border-radius: 4px;
}
input[type="checkbox"].bonificacion-form-check-input:checked {
  background-color: #02a54f;
  border-color: #02a54f; /* Borde más ancho y verde */
}
input[type="checkbox"].bonificacion-form-check-input:checked::before {
  content: "\2713"; /* Carácter Unicode para el check */
  font-size: 16px; /* Tamaño del icono */
  color: white; /* Color del icono */
  position: absolute;
  top: 50%; /* Centrar verticalmente */
  left: 50%; /* Centrar horizontalmente */
  transform: translate(-50%, -50%); /* Centrar completamente */
}
.el-select .el-input .el-input__inner:disabled {
  background: transparent !important;
}
.bonificacion-form-wrapper-sm.fit-content {
  .col-form-label {
    z-index: 1;
    position: absolute;
  }
  .el-form-item {
    position: absolute;
    z-index: 0;
    left: 0;
    width: 100%;
    &-top-12 {
      top: 12px;
    }
  }
  .el-input__inner {
    padding-left: 5px !important;
    padding-top: 0px !important;
  }
  .el-input.el-input--suffix, .el-input__inner, .select-trigger, .el-select {
    line-height: normal!important;
  }
  .el-select{
    display: block;
  }
}

.bonificacion-multiple-stacked {
  .el-input__suffix {
    height: fit-content;
    position: absolute;
    margin-top: -25px;
  }

  .el-select__tags {
    min-width: 100%;
  }

  .el-tag--info {
    width: 100%;
    height: fit-content !important;
  }

  .el-select__tags-text {
    min-width: 90%;
    width: 90%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: initial;
  }

  .el-select__tags span {
    width: 100%;
  }

}
</style>
