<template>
  <header aria-label="breadcrumb" class="justify-content-between align-items-center d-none d-md-flex" :class="{
    'expanded': currentTab ? currentTab ==
      'incidencias' : false
  }">
    <route-bread-crumb></route-bread-crumb>
    <dashboard-navbar></dashboard-navbar>
  </header>
  <DetalleIncidencia v-if="currentTab == 'detalle'" />
  <ListaIncidencias v-else />
</template>
<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import DashboardNavbar from "../../Layout/DashboardNavbar";
import ListaIncidencias from "./ListaIncidencias";
import DetalleIncidencia from "./DetalleIncidencia.vue";
export default {
  components: {
    DashboardNavbar,
    RouteBreadCrumb,
    ListaIncidencias,
    DetalleIncidencia,
  },
  computed: {
    currentTab() {
      return this.$route.query["tab"] ?? "incidencias"
    }
  }
}
</script>
<style scoped>
.expanded {
  padding-bottom: 4.50em;
}

header {
  padding-inline: 1em;
  padding-block: 1.2em;
}

header :deep(nav:last-child) {
  padding: 0 !important;
}
</style>
