<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <div class="card form_laive" :class="{ validateClient: loading }">
            <div class="box_loader">
              <div class="loader"></div>
            </div>
            <div class="card-header">
              <h3 class="mb-0">Parametros Productos recomendados</h3>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <form
                    @submit.prevent="
                      updateParameter(
                        'clientes_sin_recomendacion_cantidad_semanas'
                      )
                    "
                    class="row"
                  >
                    <label class="col-md-12 col-form-label form-control-label">
                      Clientes con pedido sin recomendación
                    </label>
                    <p class="col-md-12" style="font-size: 14px">
                      Intervalo en semanas, se indica el rango de semanas que
                      debe considerarse para mostrar el listado de clientes con
                      pedido sin recomendación.
                    </p>
                    <div class="col-md-6">
                      <base-input
                        required
                        name="clientes_sin_recomendacion_cantidad_semanas"
                        v-model="
                          clientes_sin_recomendacion_cantidad_semanas.valor
                        "
                        placeholder="Clientes con pedido sin recomendación"
                        maxlength="3"
                        @keypress="isNumber($event)"
                      >
                        <template v-slot:addonLeft>Sem.</template>
                      </base-input>
                    </div>
                    <div class="col-md 6">
                      <base-button
                        type="primary"
                        native-type="submit"
                        class="btn-default my-0"
                      >
                        Actualizar
                      </base-button>
                    </div>
                  </form>
                </div>
                <div class="col-md-12">
                  <form
                    @submit.prevent="
                      updateParameter('frecuencia_productos_recomendados')
                    "
                    class="row"
                  >
                    <label class="col-md-12 col-form-label form-control-label"
                      >Frecuencia de compra</label
                    >
                    <p class="col-md-12" style="font-size: 14px">
                      Intervalo en semanas, se indica si se compra no se vuelve
                      a mostrar después de N semanas
                    </p>
                    <div class="col-md-6">
                      <base-input
                        required
                        name="frecuencia_productos_recomendados"
                        v-model="frecuencia_productos_recomendados.valor"
                        placeholder="Frecuencia de compra"
                        maxlength="3"
                        @keypress="isNumber($event)"
                      >
                        <template v-slot:addonLeft>Sem.</template>
                      </base-input>
                    </div>
                    <div class="col-md 6">
                      <base-button
                        type="primary"
                        native-type="submit"
                        class="btn-default my-0"
                        >Actualizar</base-button
                      >
                    </div>
                  </form>
                </div>
                <div class="col-md-12">
                  <form
                    @submit.prevent="
                      updateParameter('cantidad_productos_recomendados')
                    "
                    class="row"
                  >
                    <label class="col-md-12 col-form-label form-control-label"
                      >Cantidad de productos</label
                    >
                    <p class="col-md-12" style="font-size: 14px">
                      Cantidad de productos que se mostrarán en la pantalla de
                      sugeridos.
                    </p>
                    <div class="col-md-6">
                      <base-input
                        required
                        name="cantidad_productos_recomendados"
                        v-model="cantidad_productos_recomendados.valor"
                        placeholder="Cantidad de productos"
                        maxlength="3"
                        @keypress="isNumber($event)"
                      >
                        <template v-slot:addonLeft>Cant.</template>
                      </base-input>
                    </div>
                    <div class="col-md 6">
                      <base-button
                        type="primary"
                        native-type="submit"
                        class="btn-default my-0"
                        >Actualizar</base-button
                      >
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import { useAppToast } from "../../composables";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import DashboardNavbar from "../Layout/DashboardNavbar";
import CustomModal from "../Components/CustomModal.vue";
import { serviceParametro } from "../../services/parametros";

const toast = useAppToast();

const PARAMETER_KEYS = {
  CLIENTES_SIN_RECOMENDACION_CANTIDAD_SEMANAS:
    "clientes_sin_recomendacion_cantidad_semanas",
  FRECUENCIA_PRODUCTOS_RECOMENDADOS: "frecuencia_productos_recomendados",
  CANTIDAD_PRODUCTOS_RECOMENDADOS: "cantidad_productos_recomendados",
};

export default {
  components: {
    DashboardNavbar,
    RouteBreadCrumb,
    CustomModal,
  },
  async created() {
    this.fetchData();
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
  mounted() {
    if (this.user.rol == 3) {
      this.distribuidoraIdCurrent = this.user.idDistribuidora;
    }
  },
  methods: {
    ...mapActions({
      getDistributorList: "distribuidora/getList",
    }),
    async fetchData() {
      try {
        this.loading = true;

        const getClientesPromise = serviceParametro.getParameterByKey(
          PARAMETER_KEYS.CLIENTES_SIN_RECOMENDACION_CANTIDAD_SEMANAS
        );
        const getFrecuenciaPromise = serviceParametro.getParameterByKey(
          PARAMETER_KEYS.FRECUENCIA_PRODUCTOS_RECOMENDADOS
        );
        const getCantidadPromise = serviceParametro.getParameterByKey(
          PARAMETER_KEYS.CANTIDAD_PRODUCTOS_RECOMENDADOS
        );

        const [
          dataClientsWithoutRecomendation,
          dataFrecuencyProductsRecommended,
          dataQuantityProductsRecommended,
        ] = await axios.all([
          getClientesPromise,
          getFrecuenciaPromise,
          getCantidadPromise,
        ]);

        this.clientes_sin_recomendacion_cantidad_semanas =
          dataClientsWithoutRecomendation[0];
        this.frecuencia_productos_recomendados =
          dataFrecuencyProductsRecommended[0];
        this.cantidad_productos_recomendados =
          dataQuantityProductsRecommended[0];
      } catch (error) {
        toast.error("Hubo un problema al mostrar la información");
      } finally {
        this.loading = false;
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async updateParameter(key) {
      if (this.loading) return;

      try {
        let idParametro = "";
        let valor = "";

        this.loading = true;

        if (
          key === PARAMETER_KEYS.CLIENTES_SIN_RECOMENDACION_CANTIDAD_SEMANAS
        ) {
          idParametro = this.clientes_sin_recomendacion_cantidad_semanas
            .idParametro;
          valor = this.clientes_sin_recomendacion_cantidad_semanas.valor;
        } else if (key === PARAMETER_KEYS.FRECUENCIA_PRODUCTOS_RECOMENDADOS) {
          idParametro = this.frecuencia_productos_recomendados.idParametro;
          valor = this.frecuencia_productos_recomendados.valor;
        } else if (key === PARAMETER_KEYS.CANTIDAD_PRODUCTOS_RECOMENDADOS) {
          idParametro = this.cantidad_productos_recomendados.idParametro;
          valor = this.cantidad_productos_recomendados.valor;
        }

        const body = { idParametro, valor };
        await serviceParametro.updateParemeter(body);

        toast.success("Se actualizó correctamente el parámetro");
      } catch (error) {
        toast.error("Hubo un problema en actualizar el parámetro");
      } finally {
        this.loading = false;
      }
    },
  },
  data() {
    return {
      distribuidoraIdCurrent: null,
      loading: false,
      frecuencia_productos_recomendados: {},
      cantidad_productos_recomendados: {},
      clientes_sin_recomendacion_cantidad_semanas: {},
    };
  },
};
</script>
