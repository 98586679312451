<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive" :class="{ 'validateClient' : isLoading}">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template v-slot:header>
            <div class="row">
              <div class="col-12 col-md-6">
                <h3 class="mb-0">Métodos de pago QR</h3>
              </div>
              <div class="col-12 col-md-6 text-right">
                <router-link :to="{name:'Crear metodo de pago'}"><base-button size="sm" type="info">Crear Metodo de Pago</base-button></router-link>
              </div>
            </div>
          </template>
          <div>
            <div class="row px-4">
              <div class="col-12 col-md-10 mb-3">
                <div class="row">
                  <div class="col-12 col-md-3 ">
                    <template v-if="distribuidoraIdCurrent">
                      <div class="title_filter mr-2"><div>Distribuidora:</div>  <strong>{{distribuidoraNameCurrent}}</strong></div>
                    </template>
                    <template v-else>
                      <span class="title_filter mr-2">Distribuidoras:  </span>
                      <select v-model="dataReport.iddistribuidora" class="form-control" name="distributor_select" @change="changeListbyDistribuidora">
                        <option value="0">Todos</option>
                        <option
                          v-for="item in distribuidoras"
                          :value="item.idDistribuidora"
                          :key="item.idDistribuidora"
                          >
                          {{ item.razonSocial }}
                        </option>
                      </select>
                    </template>
                  </div>
                </div>
              </div>

            </div>
            <!-- Real Table -->
            <el-table
              :data="tableData"
              row-key="tableMetodoPago"
              :empty-text="emptyText"
              header-row-class-name="thead-light"
              style="width: 100%"
            >
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                v-bind="column"
              >
              </el-table-column>
              <el-table-column width="240" align="right">
                <template v-slot:default="props">
                  <div class="d-flex">
                    <base-button type="info" class="detail mr-2" size="sm" @click="detailMetodo(props.row.idRegistro)">Detalle</base-button>
                    <base-button type="info" class="delete" size="sm" @click="deleteMetodo(props.row.idRegistro)">Eliminar</base-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <template v-slot:footer>
          </template>
        </card>
      </div>
    </div>
  </div>
  <CustomModal
    :show="detailModal.showModal"
    :isHeader="true"
    :isFooter="false"
    :width="640"
    :title="`Detalle de ${detailModal.data.nombre}`"
    v-on:modal:close="detailModal.showModal = $event" >
    <template v-slot:body>
      <form @submit.prevent="updateMethod" class="form_laive" :class="{ 'validateClient' : detailModal.loading}">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="col-form-label form-control-label">Método de Pago:</label>
              <input v-model="detailModal.data.nombre" name="name_method" class="form-control" type="text" placeholder="Método de Pago" required>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="col-form-label form-control-label">Estado:</label>
              <select v-model.number="detailModal.data.estado" class="form-control" name="state_method" id="state_method" required>
                <option value="" selected disabled style="display:none">Seleccionar</option>
                <option value="1">Activo</option>
                <option value="0">Inactivo</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="col-form-label form-control-label">Número Teléfono:</label>
              <input v-model="detailModal.data.numeroTelefono" name="number_method" class="form-control" type="text" placeholder="Método de Pago" required @keypress="isNumber($event)">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="dropzone_laive">
              <div class="dropzone-previews image_logo">
                <label class="col-form-label form-control-label">Logo:</label>
                <div class="dz-preview-cover dz-image-preview">
                  <img class="dz-preview-img" alt="image" :src="detailModal.data.previewLogo">
                </div>
              </div>
              <dropzone-file-upload
                v-model="fileLogo"
                :options="dropzoneOptions"
                @addedfile="addFileLogo"
              ></dropzone-file-upload>
            </div>
          </div>
          <div class="col-md-6">
            <div class="dropzone_laive">
              <div class="dropzone-previews image_qr">
                <label class="col-form-label form-control-label">QR:</label>
                <div class="dz-preview-cover dz-image-preview">
                  <img class="dz-preview-img" alt="image" :src="detailModal.data.previewImage">
                </div>
              </div>
              <dropzone-file-upload
                v-model="fileImage"
                :options="dropzoneOptions"
                @addedfile="addFileImage"
              ></dropzone-file-upload>
            </div>
          </div>
          <div class="col-md-12 pt-1" style="font-size:12px">*Solo se permiten formatos PNG. El código QR deberá tener una propoción de 1080x1080 (recomendado) y el Logo una propoción de 400x400 (recomendado).</div>
        </div>
        <base-button type="primary" native-type="submit" class="btn-default my-0 mt-3">Actualizar método de pago</base-button>
      </form>
    </template>
  </CustomModal>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import axios from "axios";
import {mapGetters, mapActions} from 'vuex';
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BasePagination from "@/components/BasePagination";
import DropzoneFileUpload from "@/components/Inputs/DropzoneFileUpload";
import DashboardNavbar from "./../Layout/DashboardNavbar";
import CustomModal from "../Components/CustomModal.vue";
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
export default {
  components: {
    DashboardNavbar,
    BasePagination,
    RouteBreadCrumb,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    CustomModal,
    DropzoneFileUpload
  },
  created() {
    this.getDistributor()
    this.changeListbyDistribuidora()
  },
  computed:{
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    }),
  },
  methods:{
    ...mapActions({
        getDistributorList: 'distribuidora/getList',
    }),
    async addFileLogo(file) {
      const customContainer = document.querySelector(".dropzone_laive .image_logo");
      const template = (data) => {return `<label class="col-form-label form-control-label">Logo:</label><div class="dz-preview-cover dz-image-preview"><img class="dz-preview-img" data-dz-thumbnail="" alt="vue.jpg" src="${data}"></div>`};
      const reader = new FileReader();
      reader.addEventListener("load", function () {
        customContainer.innerHTML = ""
        customContainer.innerHTML = template(reader.result);
      }, false);
      if (file) {
        const fileSize = file.size / 1024 / 1024;
        if (fileSize <= 1.1) {
          reader.readAsDataURL(file);
          const logoUpload = await this.uploadImage(file)
          console.log({logoUpload})
          if (logoUpload.data.status == 200) {
            this.createMethod.imagenLogo = logoUpload.data.message
            this.isLoading = false
          } else {
            this.isLoading = false
          }
        } else {
          const text = "La imagen no debe ser mayor a 1MB"
          const icon = "ni ni-bell-55";
          const content = {
            component: Notification,
            props: {
              icon: icon,
              text: text,
              type:"danger",
            },
          };
          const toast = useToast();
          toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
          this.isLoading = false
        }
      }
    },
    async addFileImage(file) {
      const customContainer = document.querySelector(".dropzone_laive .image_qr");
      const template = (data) => {return `<label class="col-form-label form-control-label">QR:</label><div class="dz-preview-cover dz-image-preview"><img class="dz-preview-img" data-dz-thumbnail="" alt="imagen.jpg" src="${data}"></div>`};
      const reader = new FileReader();
      if (file) {
        const fileSize = file.size / 1024 / 1024;
        if (fileSize <= 1.1) {
          reader.addEventListener("load", function () {
            customContainer.innerHTML = ""
            customContainer.innerHTML = template(reader.result);
          }, false);
          reader.readAsDataURL(file);
          const imageUpload = await this.uploadImage(file)
          console.log({imageUpload})
          if (imageUpload.data.status == 200) {
            this.createMethod.imagen = imageUpload.data.message
            this.isLoading = false
          } else {
            this.isLoading = false
          }
        } else {
          const text = "La imagen no debe ser mayor a 1MB"
          const icon = "ni ni-bell-55";
          const content = {
            component: Notification,
            props: {
              icon: icon,
              text: text,
              type:"danger",
            },
          };
          const toast = useToast();
          toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
          this.isLoading = false
        }
      }
    },
    async getDistributor(){
      try{
        let result = await this.getDistributorList();
        this.distribuidoras = result.data ? result.data.filter((item) => { return item.estado == "1" }) : [];
        this.isLoading = false
      }
      catch(ex){ex}
    },
    changeListbyDistribuidora(evn){
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      this.isLoading = true
      axios.post(process.env.VUE_APP_API_URL+"/distribuidoras/codigoqr/lista",this.dataReport,config)
      .then(function (response){
        console.log(response.data, " ====lista de reporte");
        $this.emptyText = "No se encontraron resultados"
        $this.initload = false
        $this.isLoading = false
        return $this.tableData = response.data
      }).catch(errors => {
        $this.isLoading = false
        $this.emptyText = "No se encontraron resultados"
      })
    },
    detailMetodo(id) {
      const $this = this
      const dataID = id
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      this.isLoading = true
      axios.get(process.env.VUE_APP_API_URL+"/distribuidoras/codigoqr/"+dataID,config)
      .then(function (response){
        $this.detailModal.showModal = true
        $this.detailModal.loading = false
        $this.isLoading = false
        const data = response.data
        data.previewLogo = data.imagenLogo
        data.imagenLogo = data.imagenLogo.split("codigosqr/")[1]
        data.previewImage = data.imagen
        data.imagen = data.imagen.split("codigosqr/")[1]
        $this.detailModal.data = data
        console.log(response.data, " ====detalle metodo");
      }).catch(errors => {
        $this.isLoading = false
      })
    },
    deleteMetodo(id) {
      const $this = this
      const dataID = id
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      this.isLoading = true
      axios.get(process.env.VUE_APP_API_URL+"/distribuidoras/codigoqr/eliminar/"+dataID,config)
      .then(function (response){
        $this.isLoading = false
        const data = response.data
        var indexArr = $this.tableData.map(function(el) {return el.idRegistro;}).indexOf(dataID);
        console.log({ data })
        console.log({ indexArr })
        $this.tableData.splice(indexArr, 1)
        const text = "Se eliminó el método de pago"
        const icon = "ni ni-bell-55";
        const content = {
          component: Notification,
          props: {
            icon: icon,
            text: text,
            type:"success",
          },
        };
        const toast = useToast();
        toast(content, {
          hideProgressBar: true,
          icon: false,
          closeButton: false,
          position: "top-right",
        });
        return
      }).catch(errors => {
        $this.isLoading = false
      })
    },
    updateMethod() {
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      this.detailModal.loading = true
      axios.post(process.env.VUE_APP_API_URL+"/distribuidoras/codigoqr/actualizar", this.detailModal.data,config)
      .then(function (response) {
        const text = "Se actualizó el método de pago"
        const icon = "ni ni-bell-55";
        const content = {
          component: Notification,
          props: {
            icon: icon,
            text: text,
            type:"info",
          },
        };
        const toast = useToast();
        toast(content, {
          hideProgressBar: true,
          icon: false,
          closeButton: false,
          position: "top-right",
        });
        $this.detailModal.loading = false
        $this.detailModal.showModal = false
        const dataUpload = response.data
        console.log({ dataUpload })
      }).catch(errors => {
        $this.detailModal.loading = false
      })
    },
    uploadImage(file) {
      const token = localStorage.getItem("token")
      const config = {
        headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
        }
      };
      this.detailModal.loading = true
      let uploadImage = process.env.VUE_APP_API_URL+"/distribuidoras/codigoqr/cargar-imagen"
      let formData = new FormData();
      formData.append("file", file);
      return axios.post(uploadImage, formData, config)
    },
    formatNumber(num) {
      var nf = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      return nf.format(num)
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
  },
  mounted() {
    if(this.user.rol === 3){
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
    if(this.user.rol === 4){
      this.supervisorIdCurrent = this.user.codigo
      this.supervisorNameCurrent = this.user.nombre
      this.distribuidoraIdCurrent = this.user.idDistribuidora
      this.mesaCurrent = this.user.mesa
    }
  },
  data() {
    return {
      initload: true,
      isLoading: true,
      distribuidoraIdCurrent: null,
      distribuidoraNameCurrent: null,
      supervisorIdCurrent: null,
      supervisorNameCurrent: null,
      mesaCurrent : null,
      emptyText: "Debe seleccionar una Distibuidora",
      dataReport:{
        iddistribuidora: 0,       // Obligatorio
      },
      distribuidoras:[],
      searchQuery: "",
      propsToSearch: ["cliente", "codigo"],
      tableColumns: [
        {
          prop: "nombre",
          label: "Metodo de Pago",
          width: 250,
        },
        {
          prop: "estado",
          label: "Estado",
          width: 200,
        },
        {
          prop: "distribuidora",
          label: "Distribuidora",
          width: 160,
        },
      ],
      detailModal: {
        showModal: false,
        loading: true,
        data: {
          "idDistribuidora": 1,
          "idRegistro": 2519,
          "nombre": "Yape",
          "estado": 1,
          "numeroTelefono": "984321234",
          "imagen": "https://storage.googleapis.com/laive-ctd-storage-dev/codigosqr/55b8f43b-de36-4782-b13f-4005be79cb1c.png"
        }
      },
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      fileLogo: [],
      fileImage: [],
      dropzoneOptions: {
        paramName: "image_product",
        maxFilesize: .1, // MB
        autoQueue: false,
        previewsContainer: ".dropzone-previews",
        dictDefaultMessage: "Actualizar imagen",
        acceptedFiles: 'image/png',
      },
    };
  },
};
</script>
