<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <div class="card form_laive" :class="{ 'validateClient' : loading}">
            <div class="box_loader">
              <div class="loader"></div>
            </div>
            <div class="card-header">
              <h3 class="mb-0">Editar pedído mínimo: {{filter.nombreCombinacion}}</h3>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-3 col-lg-2">
                  <ul class="progressbar_vertical">
                    <li class="step_grupo" :class="{'active': steps.one}" data-text="Actualizar combinación">1</li>
                    <li class="step_grupo" :class="{'active': steps.two}" data-text="Clientes afectados">2</li>
                  </ul>
                </div>
                <div class="col-md-9 col-lg-10">
                  <form @submit.prevent="calculateCombination" v-if="steps.two == false">
                    <div class="row">
                      <div class="col-md-4 d-none">
                        <label class="form-control-label">Nombre de la combinación</label>
                        <base-input
                          required
                          name="name_combinacion"
                          placeholder="Nombre de la combinación"
                          v-model="filter.nombreCombinacion"
                        >
                        </base-input>
                      </div>
                      <div class="col-md-4 d-none">
                        <label class="form-control-label">Distribuidoras</label>
                        <div class="form-group">
                          <select v-model="filter.idDistribuidora" required class="form-control" name="distributor_select">
                            <option value="0">Seleccione una distribuidora</option>
                            <option
                              v-for="item in distribuidoras"
                              :value="String(item.idDistribuidora)"
                              :key="item.idDistribuidora"
                              >
                              {{ item.razonSocial }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <label class="form-control-label">Monto mínimo</label>
                        <base-input
                          required
                          name="name_montominimo"
                          placeholder="Ingrese el monto"
                          v-model.number="filter.montoMinimo"
                          @keypress="isNumber($event)"
                        >
                          <template v-slot:addonLeft>S/</template>
                        </base-input>
                      </div>
                      <!-- Data combinacion -->
                      <div class="col-md-12">
                        <template v-if="dataCombinacion['antiguedad']">
                          <div class="row">
                            <div class="col-md-12">
                              <label class="form-control-label">Antiguedad</label>
                            </div>
                            <div class="col-md-2 col-lg-3" v-for="item in dataCombinacion['antiguedad']" :key="item.nombre">
                              <div class="radio">
                                <input type="radio" required v-model="filter.antiguedad" name="antiguedad" :id="`antiguedad-${item.codigo}`" class="radio__input" :value="item.codigo">
                                <label :for="`antiguedad-${item.codigo}`" class="radio__label">{{item.nombre}}</label>
                              </div>
                            </div>
                            <!-- <div class="col-md-2 col-lg-3 pl-0">
                              <base-button type="info" class="detail" size="sm" @click="openModal">Actualizar antiguedad</base-button>
                            </div> -->
                          </div>
                        </template>
                        <template v-if="dataCombinacion['giros']">
                          <div class="row">
                            <div class="col-md-12">
                              <label class="form-control-label">Giros</label>
                            </div>
                            <div class="col-md-2 col-lg-3">
                              <div class="radio custom-control custom-checkbox" @change="selectAll($event,'giros')">
                                <input type="checkbox" name="girosAll" id="girosAll" class="custom-control-input" value="1">
                                <label for="girosAll" class="custom-control-label">Todos</label>
                              </div>
                            </div>
                            <div class="col-md-2 col-lg-3" v-for="item in dataCombinacion['giros']" :key="item.nombre">
                              <div class="radio custom-control custom-checkbox">
                                <input type="checkbox" v-model="formatGiroNegocio" name="giros" :id="`giros-${item.codigo}`" class="custom-control-input" :value="item.codigo">
                                <label :for="`giros-${item.codigo}`" class="custom-control-label">{{item.nombre}}</label>
                              </div>
                            </div>
                          </div>
                        </template>
                        <template v-if="dataCombinacion['segmentos']">
                          <div class="row">
                            <div class="col-md-12">
                              <label class="form-control-label">Segmentos</label>
                            </div>
                            <div class="col-md-2 col-lg-3">
                              <div class="radio custom-control custom-checkbox" @change="selectAll($event,'segmentos')">
                                <input type="checkbox" name="segmentosAll" id="segmentosAll" class="custom-control-input" value="1">
                                <label for="segmentosAll" class="custom-control-label">Todos</label>
                              </div>
                            </div>
                            <div class="col-md-2 col-lg-3" v-for="item in dataCombinacion['segmentos']" :key="item.nombre">
                              <div class="radio custom-control custom-checkbox">
                                <input type="checkbox" v-model="formatSegmentos" name="segmentos" :id="`segmentos-${item.codigo}`" class="custom-control-input" :value="item.codigo">
                                <label :for="`segmentos-${item.codigo}`" class="custom-control-label">{{item.nombre}}</label>
                              </div>
                            </div>
                          </div>
                        </template>
                        <template v-if="dataCombinacion['tipoCombinacion']">
                          <div class="row">
                            <div class="col-md-12">
                              <label class="form-control-label">Tipo de Combinacion</label>
                            </div>
                            <div class="col-md-2 col-lg-3" v-for="item in dataCombinacion['tipoCombinacion']" :key="item.nombre">
                              <div class="radio">
                                <input type="radio" required v-model="filter.tipo" name="tipoCombinacion" :id="`tipoCombinacion-${item.codigo}`" class="radio__input" :value="item.codigo">
                                <label :for="`tipoCombinacion-${item.codigo}`" class="radio__label">{{item.nombre}}</label>
                              </div>
                            </div>
                          </div>
                        </template>
                        <template v-if="dataCombinacion['equiposFrio']">
                          <div class="row">
                            <div class="col-md-12">
                              <label class="form-control-label">Equipos de Frio</label>
                            </div>
                            <div class="col-md-2 col-lg-3">
                              <div class="radio custom-control custom-checkbox" @change="selectAll($event,'equiposFrio')">
                                <input type="checkbox" name="equiposAll" id="equiposAll" class="custom-control-input" value="1">
                                <label for="equiposAll" class="custom-control-label">Todos</label>
                              </div>
                            </div>
                            <div class="col-md-2 col-lg-3" v-for="item in dataCombinacion['equiposFrio']" :key="item.nombre">
                              <div class="radio custom-control custom-checkbox">
                                <input type="checkbox" v-model="formatEquipoFrio" name="equiposFrio" :id="`equiposFrio-${item.codigo}`" class="custom-control-input" :value="item.codigo">
                                <label :for="`equiposFrio-${item.codigo}`" class="custom-control-label">{{item.nombre}}</label>
                              </div>
                            </div>
                          </div>
                        </template>
                      </div>
                      <div class="col">
                        <base-button type="primary" native-type="submit" class="btn-default my-0 mt-2">Actualizar pedido mínimo</base-button>
                      </div>
                    </div>
                  </form>
                  <template v-if="steps.two == true">
                    <div class="row">
                      <div class="col-md-12" v-if="usersAffected.created == false">
                        <form @submit.prevent="updateCreate">

                          <p class="col-form-label form-control-label">{{usersAffected.message}}</p>
                          <base-button type="primary" native-type="submit" class="btn-default my-0 mt-2">Continuar con la actualización</base-button>
                        </form>
                      </div>
                      <template v-if="usersAffected.created">
                        <div class="col-md-12">
                          <template v-if="usersInsideFilter.data.length">
                            <p class="form-control-label">Clientes que ya se encuentran en otra combinación</p>
                            <router-link :to="{name: 'Pedido minimo'}" class="btn base-button btn-primary btn-default my-0 mt-1 mb-3">Ir al listado </router-link>
                            <el-table
                              :data="usersInsideFilter.data"
                              row-key="idUsersInsideFilter"
                              empty-text="No se encontraron resultados"
                              header-row-class-name="thead-light"
                            >
                              <el-table-column
                                v-for="column in usersInsideFilter.tableColumns"
                                :key="column.label"
                                v-bind="column"
                              >
                              </el-table-column>
                            </el-table>
                          </template>
                          <template v-else>
                            <p class="form-control-label">No hay clientes duplicados en otra combinación</p>
                          </template>
                        </div>
                      </template>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CustomModal
    :show="modal.open"
    :isHeader="true"
    :isFooter="false"
    :width="600"
    title="Actualizar los meses de antiguedad de clientes"
    v-on:modal:close="modal.open = $event" >
    <template v-slot:body>
      <form @submit.prevent="updatedAntiguedad" class="form_laive" :class="{ 'validateClient' : modal.loading}">
        <div class="box_loader">
            <div class="loader"></div>
          </div>
        <label class="col-form-label form-control-label">Meses de antiguedad de clientes</label>
        <base-input
          required
          name="meses_antiguedad"
          placeholder="Antiguedad de cliente"
          v-model="mesesAntiguedad.valor"
          @keypress="isNumber($event)"
        >
        </base-input>
        <base-button type="primary" native-type="submit" class="btn-default my-0 mt-2">Actualizar meses</base-button>
      </form>
    </template>
  </CustomModal>
</template>
<script>
  import axios from "axios";
  import {
    ElTable,
    ElTableColumn,
    ElSelect,
    ElOption,
    ElInput,
  } from "element-plus";
  import {mapGetters, mapActions} from 'vuex';
  import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
  import DashboardNavbar from "../Layout/DashboardNavbar";
  import { useToast } from "vue-toastification";
  import Notification from "@/components/Notification";
  import CustomModal from "../Components/CustomModal.vue";
  export default {
    components: {
      DashboardNavbar,
      RouteBreadCrumb,
      [ElSelect.name]: ElSelect,
      [ElOption.name]: ElOption,
      [ElTable.name]: ElTable,
      [ElInput.name]: ElInput,
      [ElTableColumn.name]: ElTableColumn,
      CustomModal
    },
    created(){
      const $this = this;
      const idPedido = this.$route.params.id;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let urlDistribuidoras = process.env.VUE_APP_API_URL+"/distribuidoras/lista"
      const getDistribuidoras = axios.get(urlDistribuidoras, config);
      let urlDataCombinacion = process.env.VUE_APP_API_URL+"/pedidominimo/data-para-combinacion"
      const getDataCombinacion = axios.get(urlDataCombinacion, config);
      const urlMesesAntiguedad = process.env.VUE_APP_API_URL+"/parametro/lista/meses_antiguedad_cliente"
      const getMesesAntiguedad = axios.get(urlMesesAntiguedad,config)
      const urlDetailPedido = process.env.VUE_APP_API_URL+"/pedidominimo/detalle/"+idPedido
      const getDetailPedido = axios.get(urlDetailPedido,config)
      axios.all([getDistribuidoras, getDataCombinacion, getMesesAntiguedad, getDetailPedido]).then(axios.spread((...responses) => {
        const resDistribuidoras = responses[0].data
        $this.distribuidoras = resDistribuidoras ? resDistribuidoras.filter((item)=>{return item.estado == "1"}) : []
        const resDataCombinacion = responses[1].data
        $this.dataCombinacion = resDataCombinacion
        const resMesesAntiguedad = responses[2].data[0]
        $this.mesesAntiguedad = resMesesAntiguedad
        const resDetailPedido = responses[3].data
        console.log({resDetailPedido})
        $this.filter = resDetailPedido
        $this.filter.tempGiroNegocio = resDetailPedido.giroNegocio.split(',')
        $this.filter.tempSegmento = resDetailPedido.segmento.split(',')
        $this.filter.tempEquipoFrio = resDetailPedido.equipoFrio.split(',')
        $this.filter.idPedidoMinimo = idPedido
        $this.loading = false
      })).catch(errors => {
      })
    },
    computed:{
      ...mapGetters({
        authenticated: 'auth/authenticated',
        user: 'auth/user',
      }),
      formatGiroNegocio: {
      	get () {
	      	return this.filter.tempGiroNegocio;
        },
        set (val) {
					this.filter.tempGiroNegocio = val
          this.filter.giroNegocio = this.filter.tempGiroNegocio.join(",");
          document.querySelector("input[name='girosAll']").checked = false;
        }
      },
      formatSegmentos: {
      	get () {
	      	return this.filter.tempSegmento;
        },
        set (val) {
					this.filter.tempSegmento = val
          this.filter.segmento = this.filter.tempSegmento.join(",");
          document.querySelector("input[name='segmentosAll']").checked = false;
        }
      },
      formatEquipoFrio: {
      	get () {
	      	return this.filter.tempEquipoFrio;
        },
        set (val) {
					this.filter.tempEquipoFrio = val
          this.filter.equipoFrio = this.filter.tempEquipoFrio.join(",");
          document.querySelector("input[name='equiposAll']").checked = false;
        }
      },
    },
    mounted() {
      if(this.user.rol == 3){
        this.distribuidoraIdCurrent = this.user.idDistribuidora
        this.filter.iddistribuidora = this.user.idDistribuidora
      }
    },
    methods: {
      ...mapActions({
        getDistributorList: 'distribuidora/getList',
      }),
      selectAll(evn, name) {
        console.log({name})
        if (evn.target.checked) {
          const selected = this.dataCombinacion[name].map((u) => u.codigo);
          console.log(selected)
          if(name === "giros"){
            this.filter.tempGiroNegocio = selected
            this.filter.giroNegocio = selected.join(",");
          }
          if(name === "segmentos"){
            this.filter.tempSegmento = selected
            this.filter.segmento = selected.join(",");
          }
          if(name === "equiposFrio"){
            this.filter.tempEquipoFrio = selected
            this.filter.equipoFrio = selected.join(",");
          }
        } else {
          if(name === "giros"){
            this.filter.tempGiroNegocio = []
            this.filter.giroNegocio = ""
          }
          if(name === "segmentos"){
            this.filter.tempSegmento = []
            this.filter.segmento = [];
          }
          if(name === "equiposFrio"){
            this.filter.tempEquipoFrio = []
            this.filter.equipoFrio = [];
          }
        }
      },
      isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault();;
        } else {
          return true;
        }
      },
      calculateCombination(evn){
        const $this = this
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        this.loading = true
        const dataSend = this.filter
        const urlApiCalculate = process.env.VUE_APP_API_URL+"/pedidominimo/calcular"
        axios.post(urlApiCalculate,dataSend,config)
          .then(function (response) {
            console.log(response.data.length)
            $this.loading = false
            $this.steps.two = true
            $this.usersAffected.open = true
            if (response.data.length) {
              $this.usersAffected.clientes = response.data[0].nroClientes
              $this.usersAffected.distribuidora = response.data[0].distribuidora
              $this.usersAffected.message = `Se han encontrado ${response.data[0].nroClientes} clientes que pertenecen a otra combinación.`
            } else {
              $this.usersAffected.message = "No se han encontrado clientes afectados"
            }
          }).catch(function(error){
            console.log({error});
            $this.loading = false
          })
      },
      updateCreate(evn){
        const $this = this
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        this.loading = true
        const dataSend = this.filter
        const urlApiCreate = process.env.VUE_APP_API_URL+"/pedidominimo/actualizar"
        axios.post(urlApiCreate,dataSend,config)
          .then(function (response){
            const text =(response.status == 200) ? "Se creo el pedido minimo" : "Hubo un error";
            const icon ="ni ni-bell-55";
            const content={
              component: Notification,
              props: {
                icon: icon,
                text: text,
                type: (response.status == 200) ? "success" : "danger",
              },
            };
            const toast = useToast();
            toast(content, {
              hideProgressBar: true,
              icon: false,
              closeButton: false,
              position: "top-right",
            });
            $this.loading = false
            $this.usersAffected.created = true
            $this.usersInsideFilter.data = response.data.resumen.length ? response.data.resumen : []
          }).catch(function(error){
            console.log({error});
            $this.loading = false
        })
      },
      openModal(){
        this.modal.open = true
      },
      updatedAntiguedad(){
        const $this = this
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        this.modal.loading = true
        const dataSend = this.mesesAntiguedad
        const urlApiUpdated = process.env.VUE_APP_API_URL+"/parametro/actualizar"
        axios.put(urlApiUpdated,dataSend,config)
        .then(function (response){
          const text =(response.status == 200) ? "Se actualizó la cobranza fuera de ruta" : "Hubo un error";
          const icon ="ni ni-bell-55";
          const content={
            component: Notification,
            props: {
              icon: icon,
              text: text,
              type: (response.status == 200) ? "success" : "danger",
            },
          };
          const toast = useToast();
          toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
          $this.modal.loading = false
          setTimeout(() => {
            $this.modal.open = false
            location.reload();
          }, 350);
        }).catch(function(error){
          $this.modal.loading = false
          $this.modal.open = false
        })
      }
    },
    data() {
      return {
        statusProxy: null,
        initLoad: true,
        loading: true,
        distribuidoraIdCurrent: null,
        distribuidoras: [],
        dataCombinacion: [],
        steps:{
          one: true,
          two: false
        },
        modal:{
          title: "",
          open: false,
          loading: false
        },
        usersAffected:{
          created: false,
          clientes: "",
          distribuidora: "",
          message: ""
        },
        mesesAntiguedad: {},
        filter: {
          nombreCombinacion: "",
          idDistribuidora: "0",
          antiguedad: "",
          giroNegocio: "",
          tempGiroNegocio: [],
          segmento: "",
          tempSegmento: [],
          tipo: [],
          tempTipo: [],
          equipoFrio: "",
          tempEquipoFrio: [],
          montoMinimo: ""
        },
        usersInsideFilter:{
          tableColumns: [
            {
              prop: "distribuidora",
              label: "Distribuidora",
              minWidth: 120,
            },
            {
              prop: "codigoCliente",
              label: "Cog. Cliente",
              minWidth: 120,
            },
            {
              prop: "nombreCliente",
              label: "Cliente",
              minWidth: 120,
            },
          ],
          data:[]
        }
      };
    },
  };
</script>

