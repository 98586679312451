<template>
    <card
      class="no-border-card"
      body-classes="px-0 pb-1 pt-0 mt-0"
      footer-classes="pb-2"
    >
      <template v-slot:header>
        <div class="row">
          <div class="col-12 col-md-6">
            <h3 class="mb-0">{{title}}</h3>
          </div>
          <div class="col-12 col-md-6 text-right">
            <router-link to="/" @click.prevent="createItem"><base-button size="sm" type="info">Crear {{title}}</base-button></router-link>
          </div>
        </div>
      </template>
      <div class="card-body">
        <div class="row mb-3">
          <div class="col-md-3">
            <span class="title_filter mr-2">Distribuidoras:  </span>
            <select v-model.number="dataSend.idDistribuidora" class="form-control" name="deparment_select" @change="changeDistribuidora">
              <option selected disabled value="">Todos</option>
              <option
                v-for="item in distribuidoras"
                :value="item.idDistribuidora"
                :key="item.idDistribuidora"
              >
                {{ item.razonSocial }}
              </option>
            </select>
          </div>
        </div>
        <el-table
          :data="dataTable"
          row-key="idListMaestros"
          header-row-class-name="thead-light"
          empty-text="Debe seleccionar una Distribuidora"
        >
          <el-table-column
            v-for="column in tableColumns"
            :key="column.label"
            v-bind="column"
          >
          </el-table-column>
          <el-table-column min-width="100px" align="right">
            <template v-slot:default="props">
              <div class="d-flex">
                <router-link to="/" @click.prevent="editItem(props.row)" class="btn base-button btn-success btn-sm edit">Editar</router-link>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="col-12 d-flex justify-content-end pt-2">
          <base-pagination
            class="pagination-no-border"
            v-model="pagination.newcurrentPage"
            :per-page="pagination.perPage"
            :total="pagination.total"
            @click="changePaginate"
          >
          </base-pagination>
        </div>
      </div>
    </card>
    <!-- Edit -->
    <CustomModal
      :show="dataModal.showModal"
      :isHeader="true"
      :isFooter="false"
      :width="800"
      :title="dataModal.title"
      v-on:modal:close="dataModal.showModal = $event"
    >
      <template v-slot:body>
        <form @submit.prevent="submitEdit" :class="'form_laive '+dataModal.active">
          <div class="box_loader">
            <div class="loader"></div>
          </div>
          <div class="row mb-0">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label">Distribuidora:</label>
                <select v-model.number="dataSend.idDistribuidora" class="form-control" name="distribuidora_select">
                  <option selected disabled value="">Todos</option>
                  <option
                    v-for="item in distribuidoras"
                    :value="item.idDistribuidora"
                    :key="item.idDistribuidora"
                  >
                    {{ item.razonSocial }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <label class="form-control-label">Código:</label>
              <base-input
                name="codigo_mercado"
                placeholder="Código"
                required="required"
                :value="dataSend.codigoMercado"
                v-model="dataSend.codigoMercado"
              ></base-input>
            </div>
            <div class="col-md-6">
              <label class="form-control-label">Nombre:</label>
              <base-input
                name="name_mercado"
                placeholder="Nombre"
                required="required"
                :value="dataSend.nombre"
                v-model="dataSend.nombre"
              ></base-input>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label">Departamento:</label>
                <select v-model="dataSend.departamento" class="form-control" name="deparment_select" @change="changeDeparment($event,'edit')">
                  <option selected disabled value="">Todos</option>
                  <option
                    v-for="item in departamentos"
                    :value="item.codigo"
                    :key="item.nombre"
                    >
                    {{ item.nombre }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label">Provincia:</label>
                <select v-model="dataSend.provincia" class="form-control" name="province_select" @change="changeProvince($event,'edit')">
                  <option selected disabled value="">Todos</option>
                  <option
                    v-for="item in provincias"
                    :value="item.codigo"
                    :key="item.nombre"
                    >
                    {{ item.nombre }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label">Distrito:</label>
                <select v-model="dataSend.ubigeo" class="form-control" name="province_select" required>
                  <option selected disabled value="">Todos</option>
                  <option
                    v-for="item in distritos"
                    :value="item.codigo"
                    :key="item.nombre"
                    >
                    {{ item.nombre }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <base-checkbox @change="changeStateEdit($event)" :modelValue="dataModal.estadoEdit"><strong>Activo</strong></base-checkbox>
              </div>
            </div>
          </div>
          <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar Mercado</base-button>
        </form>
      </template>
    </CustomModal>
    <!-- Create -->
    <CustomModal
      :show="dataModal.showCreate"
      :isHeader="true"
      :isFooter="false"
      :width="800"
      title="Crear Mercado"
      v-on:modal:close="dataModal.showCreate = $event"
    >
      <template v-slot:body>
        <form @submit.prevent="submitCreate" :class="'form_laive '+dataModal.activeCreate">
          <div class="box_loader">
            <div class="loader"></div>
          </div>
          <div class="row mb-0">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label">Distribuidora:</label>
                <select v-model.number="dataCreate.idDistribuidora" class="form-control" name="distribuidora_create" required>
                  <option selected disabled value="">Todos</option>
                  <option
                    v-for="item in distribuidoras"
                    :value="item.idDistribuidora"
                    :key="item.idDistribuidora"
                  >
                    {{ item.razonSocial }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <label class="form-control-label">Código:</label>
              <base-input
                name="codigo_mercado_create"
                placeholder="Código"
                required="required"
                :value="dataCreate.codigoMercado"
                v-model="dataCreate.codigoMercado"
              ></base-input>
            </div>
            <div class="col-md-6">
              <label class="form-control-label">Nombre:</label>
              <base-input
                name="name_mercado_create"
                placeholder="Nombre"
                required="required"
                :value="dataCreate.nombre"
                v-model="dataCreate.nombre"
              ></base-input>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label">Departamento:</label>
                <select v-model="dataUbigeo.departamento" class="form-control" name="deparment_select" @change="changeDeparment">
                  <option selected disabled value="">Todos</option>
                  <option
                    v-for="item in departamentos"
                    :value="item.codigo"
                    :key="item.nombre"
                    >
                    {{ item.nombre }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label">Provincia:</label>
                <select v-model="dataUbigeo.provincia" class="form-control" name="province_select" @change="changeProvince">
                  <option selected disabled value="">Todos</option>
                  <option
                    v-for="item in provincias"
                    :value="item.codigo"
                    :key="item.nombre"
                    >
                    {{ item.nombre }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label">Distrito:</label>
                <select v-model="dataCreate.ubigeo" class="form-control" name="province_select">
                  <option selected disabled value="">Todos</option>
                  <option
                    v-for="item in distritos"
                    :value="item.codigo"
                    :key="item.nombre"
                    >
                    {{ item.nombre }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <base-checkbox @change="changeStateCreate($event)" :modelValue="dataModal.estadoCreate"><strong>Activo</strong></base-checkbox>
              </div>
            </div>
          </div>
          <base-button type="primary" native-type="submit" class="btn-default my-0">Crear Mercado</base-button>
        </form>
      </template>
    </CustomModal>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import axios from "axios";
import { useToast } from "vue-toastification";
import BasePagination from "@/components/BasePagination";
import Notification from "@/components/Notification";
import CustomModal from "./CustomModal.vue";
export default {
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    useToast,
    BasePagination,
    Notification,
    CustomModal,
  },
  created(){
    const $this = this;
    const token = localStorage.getItem("token")
    const config = {
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    };
    this.title = this.$route.query.name
    let urlApiDistribuidoras = process.env.VUE_APP_API_URL+"/distribuidoras/lista"
    axios.get(urlApiDistribuidoras,config)
    .then(function(response){
      let data = response.data
      $this.distribuidoras = data.filter((item)=>{return item.estado == "1"})
      document.querySelector(".form_laive").classList.remove("validateClient")
    }).catch(errors => {
      // react on errors.
      document.querySelector(".form_laive").classList.remove("validateClient")
    })
  },
  methods: {
    changeStateEdit(evn){
      this.dataSend.estado = Number(evn.target.checked)
    },
    changeStateCreate(evn){
      this.dataCreate.estado = Number(evn.target.checked)
    },
    changeDistribuidora(evn){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      this.dataSend.page = 1
      const listPagination = document.querySelectorAll("ul.pagination .page-item")
      document.querySelector(".form_laive").classList.add("validateClient")
      let urlApiMercado = process.env.VUE_APP_API_URL+"/mercado/lista/pordistribuidora"
      axios.post(urlApiMercado,this.dataSend,config)
      .then(function(response){
        let data = response.data
        console.log({data})
        $this.pagination.perPage = data.data.length
        $this.pagination.total = data.totalPage
        $this.dataTable = data.data
        $this.pagination.newcurrentPage = 1
        document.querySelector(".form_laive").classList.remove("validateClient")
        listPagination.forEach(item => {
          item.classList.remove("active")
        });
        listPagination[1].classList.add("active")
      }).catch(errors => {
        // react on errors.
        document.querySelector(".form_laive").classList.remove("validateClient")
      })
    },
    editItem(data){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      this.dataModal.title = 'Editar: ' + data.nombre
      const parameters = {
        "idDistribuidora": Number(data.idDistribuidora),
        "codigoMercado": data.codigoMercado
      }
      document.querySelector(".form_laive").classList.add("validateClient")
      let urlApiDetalle = process.env.VUE_APP_API_URL+"/mercado/detalle"
      axios.post(urlApiDetalle,parameters,config)
      .then(function(response){
        let data = response.data
        $this.dataSend = data
        $this.dataModal.showModal = true
        $this.dataModal.estadoEdit = Boolean(data.estado)
        $this.dataSend.estado = Number(data.estado)
        $this.dataUbigeo.provincia = data.provincia
        $this.dataUbigeo.departamento = data.departamento

        document.querySelector(".form_laive").classList.remove("validateClient")
        $this.getDeparments()
        $this.changeDeparment()
        $this.changeProvince()
      }).catch(errors => {
        // react on errors.
        document.querySelector(".form_laive").classList.remove("validateClient")
      })
    },
    submitEdit() {
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let updatedMercado = process.env.VUE_APP_API_URL+"/mercado/actualizar"
      this.dataModal.active = "validateClient"
      axios.post(updatedMercado, this.dataSend,config)
      .then(function (response){
        const text = (response.status == 200) ? "Se actualizó el mercado" : "Hubo un error";
        const icon = "ni ni-bell-55";
        const content = {
          component: Notification,
          props: {
            icon: icon,
            text: text,
            type: (response.status == 200) ? "success" : "danger",
          },
        };
        const toast = useToast();
        toast(content, {
          hideProgressBar: true,
          icon: false,
          closeButton: false,
          position: "top-right",
        });
        $this.dataModal.active = ""
        setTimeout(() => {
          location.reload();
        }, 500);
      }).catch(errors => {
        $this.dataModal.active = ""
      })
    },
    createItem(){
      this.dataModal.showCreate = true
      this.dataUbigeo.departamento = ""
      this.dataUbigeo.provincia = ""
      this.departamentos = []
      this.provincias = []
      this.distritos = []
      this.getDeparments()
    },
    getDeparments(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      this.dataModal.activeCreate = "validateClient"
      this.dataModal.active = "validateClient"
      let apiDepartamentos = process.env.VUE_APP_API_URL+"/ubigeo/departamentos"
      axios.get(apiDepartamentos,config)
      .then(function(response){
        let data = response.data
        $this.departamentos = data
        $this.dataModal.activeCreate = ""
        $this.dataModal.active = ""
      }).catch(errors => {
        // react on errors.
        $this.dataModal.activeCreate = ""
        $this.dataModal.active = ""
      })
    },
    changeDeparment(event=null, type=null){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      if(type == 'edit'){
        $this.dataUbigeo.departamento = event.target.value
      }
      console.log($this.dataUbigeo)
      this.dataModal.activeCreate = "validateClient"
      this.dataModal.active = "validateClient"
      let apiProvincias = process.env.VUE_APP_API_URL+"/ubigeo/provincias"
      axios.post(apiProvincias,this.dataUbigeo,config)
      .then(function(response){
        let data = response.data
        $this.provincias = data
        $this.dataUbigeo.provincia = ""
        $this.dataModal.activeCreate = ""
        $this.dataModal.active = ""
        if(type == 'edit'){
          $this.dataSend.provincia = ""
          $this.dataSend.ubigeo = ""
          $this.distritos = []
        }
      }).catch(errors => {
        $this.dataModal.activeCreate = ""
        $this.dataModal.active = ""
      })
    },
    changeProvince(event=null, type=null){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      if(type == 'edit'){
        $this.dataUbigeo.provincia = event.target.value
      }
      this.dataModal.activeCreate = "validateClient"
      this.dataModal.active = "validateClient"
      let apiProvincias = process.env.VUE_APP_API_URL+"/ubigeo/distritos"
      axios.post(apiProvincias,this.dataUbigeo,config)
      .then(function(response){
        let data = response.data
        $this.distritos = data
        $this.dataModal.activeCreate = ""
        $this.dataModal.active = ""
      }).catch(errors => {
        $this.dataModal.activeCreate = ""
        $this.dataModal.active = ""
      })
    },
    submitCreate() {
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let createdMercado = process.env.VUE_APP_API_URL+"/mercado/crear"
      this.dataModal.activeCreate = "validateClient"
      axios.post(createdMercado, this.dataCreate,config)
      .then(function (response){
        const text = (response.status == 200) ? "Se creó el mercado" : "Hubo un error";
        const icon = "ni ni-bell-55";
        const content = {
          component: Notification,
          props: {
            icon: icon,
            text: text,
            type: (response.status == 200) ? "success" : "danger",
          },
        };
        const toast = useToast();
        toast(content, {
          hideProgressBar: true,
          icon: false,
          closeButton: false,
          position: "top-right",
        });
        $this.dataModal.activeCreate = ""
        setTimeout(() => {
          location.reload();
        }, 500);
      }).catch(errors => {
        $this.dataModal.activeCreate = ""
      })
    },
    changePaginate(evn){
      const $this = this
      const listPaginator = document.querySelectorAll("ul.pagination .page-item")
      let idDistribuidora = this.dataSend.idDistribuidora
      let elm = evn.target;
      let sendAjax = false;
      let parentEl = elm.parentNode
      if(elm.classList.contains("prev-page")){
        if(!elm.classList.contains("disabled")){
          let linkActivePrev = document.querySelectorAll("ul.pagination .page-item.active")
          linkActivePrev[0].classList.remove("active")
          linkActivePrev[0].previousSibling.classList.add("active")
          sendAjax = true
        }
      }else if(elm.classList.contains("next-page")){
      }else{
        if(elm.getAttribute("aria-label") == "Previous"){
          let linkActivePrev = document.querySelectorAll("ul.pagination .page-item.active")
          linkActivePrev[0].classList.remove("active")
          linkActivePrev[0].previousSibling.classList.add("active")
          sendAjax = true
        }else if(elm.getAttribute("aria-label") == "Next"){
          let linkActiveNext = document.querySelectorAll("ul.pagination .page-item.active")
            linkActiveNext[0].classList.remove("active")
            linkActiveNext[0].nextSibling.classList.add("active")
            sendAjax = true
        }else{
          if(!parentEl.classList.contains("active")){
            listPaginator.forEach(item => {
              item.classList.remove("active")
            });
            parentEl.classList.add("active")
            sendAjax = true
          }
        }
      }
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      if(sendAjax){
        this.dataSend.page = this.pagination.newcurrentPage
        document.querySelector(".form_laive").classList.add("validateClient")
        let urlApiMercado = process.env.VUE_APP_API_URL+"/mercado/lista/pordistribuidora"
        axios.post(urlApiMercado,this.dataSend,config)
        .then(function(response){
          let data = response.data
          console.log({data})
          $this.pagination.perPage = data.data.length
          $this.pagination.total = data.totalPage
          $this.dataTable = data.data
          document.querySelector(".form_laive").classList.remove("validateClient")
        }).catch(errors => {
          // react on errors.
          document.querySelector(".form_laive").classList.remove("validateClient")
        })
      }
    },
  },
  data() {
    return {
      title: "",
      distribuidoras: [],
      departamentos: [],
      provincias: [],
      distritos: [],
      tableColumns: [
        {
          prop: "codigoMercado",
          label: "Código",
          width: 122,
        },
        {
          prop: "nombre",
          label: "Nombre",
          width: 320,
        },
        {
          prop: "estado",
          label: "Estado",
          width: 120,
        }
      ],
      dataTable: [],
      dataUbigeo: {
        departamento: "",
        provincia: ""
      },
      dataSend: {
        idDistribuidora: "",
        codigoMercado: "",
        nombre: "",
        ubigeo: "",
        provincia: "",
        departamento: "",
      },
      dataCreate:{
        idDistribuidora: "",
        codigoMercado: "",
        nombre: "",
        ubigeo: ""
      },
      dataModal:{
        showModal: false,
        title: "",
        active: "",
        showCreate: false,
        activeCreate: "",
        estadoEdit: "",
        estadoCreate: true
      },
      pagination: {
        perPage: 2,
        currentPage: 1,
        newcurrentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 1,
      },
    };
  },
}
</script>
