import axios from "axios";

import setting from "../services/setting";

const defaultConfig = {
  baseURL: `${setting.http.base_url}`,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
};

const instance = axios.create(defaultConfig);

instance.interceptors.request.use(
  async (config) => {
    const token = window.localStorage.getItem("token")

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    // Do here anything, for example: logout, o redirect
    return Promise.reject(error);
  }
);

export const request = instance;
