import html2pdf from 'html2pdf.js';

export const descargarBonificacionesPdf = (elementoHtml) => {
  let procesoDescargaActivas = descargarTabPdf(elementoHtml.cloneNode(true), "activas");
  let procesoDescargaPendientes = descargarTabPdf(elementoHtml.cloneNode(true), "pendientes");
  let procesoDescargaInactivas = descargarTabPdf(elementoHtml.cloneNode(true), "inactivas");
  return Promise.all([procesoDescargaActivas, procesoDescargaPendientes, procesoDescargaInactivas]);
}

const descargarTabPdf = (elementoHtml, tabExportar) => {
  formatearPdf(elementoHtml, tabExportar);
  let opt = {
    filename:     `${tabExportar}.pdf`,
    margin:       1,
  };
  return html2pdf().from(elementoHtml).set(opt).save();
}

const formatearPdf = (elementoHtml, tabExportar) => {
  elegirPestana(elementoHtml, tabExportar);
  let elementsToShow = elementoHtml.getElementsByClassName('show-export');
  let elementsToHide = elementoHtml.getElementsByClassName('hide-export');
  elementsToShow.forEach(e => {
    e.style.display = 'block';
  });
  elementsToHide.forEach(e => {
    e.style.display = 'none';
  });
  elementoHtml.querySelectorAll('[class*="change-unit-export"]').forEach(e => {
    e.textContent = "Unid";
  });
  elementoHtml.querySelectorAll('[class*="remove-flex-export"]').forEach(e => {
    e.classList.remove('d-flex');
  });
  elementoHtml.querySelectorAll('[class*="add-px0-export"]').forEach(e => {
    cambiarClasePx(e,'px-0');
  });
  elementoHtml.querySelectorAll('[class*="add-px1-export"]').forEach(e => {
    cambiarClasePx(e,'px-1');
  });
  elementoHtml.querySelectorAll('[class*="add-mt1-export"]').forEach(e => {
    e.classList.remove('mt-1');
    e.classList.remove('mt-3');
    e.classList.remove('mt-4');
    e.classList.add('mt-1');
  });
  elementoHtml.querySelectorAll('[class*="add-mb2-export"]').forEach(e => {
    e.classList.remove('mb-2');
    e.classList.remove('mb-3');
    e.classList.add('mb-2');
  });
  elementoHtml.querySelectorAll('[class*="remove-py-export"]').forEach(e => {
    e.classList.remove('py-2');
    e.classList.remove('py-3');
    e.classList.remove('py-4');
  });
  elementoHtml.querySelectorAll('[class*="remove-margin-export"]').forEach(e => {
    e.style.setProperty('margin-top', '0px', 'important');
    e.style.setProperty('margin-bottom', '0px', 'important');
  });
  elementoHtml.querySelectorAll('[class*="add-mr0-export"]').forEach(e => {
    e.style.setProperty('margin-right', '0px', 'important');
  });
  elementoHtml.querySelectorAll('[class*="add-py0-export"]').forEach(e => {
    e.style.setProperty('padding-top', '0px', 'important');
    e.style.setProperty('padding-bottom', '0px', 'important');
  });

  elementoHtml.querySelectorAll('[class*="col-1-export"]').forEach(e => {
    cambiarClaseCol(e, 'col-1');
  });
  elementoHtml.querySelectorAll('[class*="col-3-export"]').forEach(e => {
    cambiarClaseCol(e, 'col-3');
  });
  elementoHtml.querySelectorAll('[class*="col-4-export"]').forEach(e => {
    cambiarClaseCol(e, 'col-4');
  });
  elementoHtml.querySelectorAll('[class*="col-5-export"]').forEach(e => {
    cambiarClaseCol(e, 'col-5');
  });
  elementoHtml.querySelectorAll('[class*="col-6-export"]').forEach(e => {
    cambiarClaseCol(e, 'col-6');
  });
  elementoHtml.querySelectorAll('[class*="col-11-export"]').forEach(e => {
    cambiarClaseCol(e, 'col-11');
  });
}

export const agregarSaltosPdf = (bonificaciones, limitePaginaInicial, limitePaginaRegular) =>{
  let totalElementosPagina = 0;
  let totalElementosBloque = 0;
  let totalElementosBloque1 = 0;
  let totalElementosBloque2 = 0;
  let totalBloques = 0;
  let limitePagina = limitePaginaInicial;
  bonificaciones.forEach((bonificacion) => {
    totalBloques = totalBloques + 1;
    totalElementosBloque1 = bonificacion.prods_promo
      .map(prod => prod.productos.length === 0 ? 1 : prod.productos.length)
      .reduce((sum, size) => sum + size, 0) + bonificacion.prods_promo.length - 1;
    totalElementosBloque2 = bonificacion.prods_regalo
      .map(prod => prod.productos.length === 0 ? 1 : prod.productos.length)
      .reduce((sum, size) => sum + size, 0) + bonificacion.prods_regalo.length - 1;
    totalElementosBloque = 4 + Math.max(totalElementosBloque1, totalElementosBloque2);
    if(totalElementosPagina + totalElementosBloque > limitePagina && totalBloques > 1){
      limitePagina = limitePaginaRegular;
      totalElementosPagina = totalElementosBloque;
      bonificacion.esNuevaPaginaPdf = true;
      totalBloques = 0;
    }else{
      totalElementosPagina = totalElementosPagina + totalElementosBloque;
    }
  });
}

const elegirPestana = (elementoHtml, tabExportar) => {
  let navTabActivas = elementoHtml.querySelector('#nav-activas-tab');
  let navTabPendientes = elementoHtml.querySelector('#nav-pending-tab');
  let navTabInactivas = elementoHtml.querySelector('#nav-inactivas-tab');
  let tabActivas = elementoHtml.querySelector('#activas-tab');
  let tabPendientes = elementoHtml.querySelector('#pending-tab');
  let tabInactivas = elementoHtml.querySelector('#inactivas-tab');
  navTabActivas.classList.remove("active");
  tabActivas.classList.remove("active");
  tabActivas.classList.remove("show");
  navTabPendientes.classList.remove("active");
  tabPendientes.classList.remove("active");
  tabPendientes.classList.remove("show");
  navTabInactivas.classList.remove("active");
  tabInactivas.classList.remove("active");
  tabInactivas.classList.remove("show");
  if(tabExportar === "activas"){
    navTabActivas.classList.add("active");
    tabActivas.classList.add("active");
    tabActivas.classList.add("show");
  }else if(tabExportar === "pendientes"){
    navTabPendientes.classList.add("active");
    tabPendientes.classList.add("active");
    tabPendientes.classList.add("show");
  } else if(tabExportar === "inactivas"){
    navTabInactivas.classList.add("active");
    tabInactivas.classList.add("active");
    tabInactivas.classList.add("show");
  }
}

const cambiarClaseCol = (elementoHtml, nuevoCol) => {
  elementoHtml.classList.remove('col-1');
  elementoHtml.classList.remove('col-2');
  elementoHtml.classList.remove('col-3');
  elementoHtml.classList.remove('col-4');
  elementoHtml.classList.remove('col-9');
  elementoHtml.classList.remove('col-10');
  elementoHtml.classList.remove('col-11');
  elementoHtml.classList.add(nuevoCol);
}
const cambiarClasePx = (elementoHtml, nuevoPx) => {
  elementoHtml.classList.remove('px-0');
  elementoHtml.classList.remove('px-1');
  elementoHtml.classList.remove('px-2');
  elementoHtml.classList.remove('px-3');
  elementoHtml.classList.remove('px-4');
  elementoHtml.classList.add(nuevoPx);
}
