<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <div class="card form_laive" :class="{ 'validateClient' : loading}">
            <div class="box_loader">
              <div class="loader"></div>
            </div>
            <div class="card-header">
              <h3 class="mb-0">Parametros Recencia de compra</h3>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <form @submit.prevent="updateRecencia($event, 'clientes_riesgo')" class="row">
                    <label class="col-md-12 col-form-label form-control-label">Clientes en riesgo</label>
                    <p class="col-md-12" style="font-size: 14px;">Intervalo en semanas de tiempo sin comprar, para considerar que un cliente está en riesgo</p>
                    <div class="col-md-6">
                      <base-input
                        required
                        name="clientes_riesgo"
                        v-model="clientes_riesgo.valor"
                        placeholder="Intervalo de semanas"
                      >
                        <template v-slot:addonLeft>Sem.</template>
                      </base-input>
                    </div>
                    <div class="col-md 6">
                      <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar</base-button>
                    </div>
                  </form>
                </div>
                <div class="col-md-6">
                  <form @submit.prevent="updateRecencia($event, 'clientes_perdidos')" class="row">
                    <label class="col-md-12 col-form-label form-control-label">Clientes perdidos</label>
                    <p class="col-md-12" style="font-size: 14px;">Número de semanas sin comprar para considerar que un cliente está perdido</p>
                    <div class="col-md-6">
                      <base-input
                        required
                        name="clientes_perdidos"
                        v-model="clientes_perdidos.valor"
                        placeholder="Semanas sin comprar"
                        @keypress="isNumber($event)"
                      >
                        <template v-slot:addonLeft>Sem.</template>
                      </base-input>
                    </div>
                    <div class="col-md 6">
                      <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar</base-button>
                    </div>
                  </form>
                </div>
                <div class="col-md-6">
                  <form @submit.prevent="updateRecencia($event, 'clientes_nopedidos')" class="row">
                    <label class="col-md-12 col-form-label form-control-label">Alerta No pedidos</label>
                    <p class="col-md-12" style="font-size: 14px;">Cantidad de no pedidos para enviar la alerta al app de supervisión.</p>
                    <div class="col-md-6">
                      <base-input
                        required
                        name="clientes_nopedidos"
                        v-model="clientes_nopedidos.valor"
                        placeholder="Cantidad No pedidos"
                        @keypress="isNumber($event)"
                      >
                        <template v-slot:addonLeft>Cant.</template>
                      </base-input>
                    </div>
                    <div class="col-md 6">
                      <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar</base-button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from "axios";
  import {mapGetters, mapActions} from 'vuex';
  import { useToast } from "vue-toastification";
  import Notification from "@/components/Notification";
  import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
  import DashboardNavbar from "../Layout/DashboardNavbar";
  import CustomModal from "../Components/CustomModal.vue";
  export default {
    components: {
      DashboardNavbar,
      RouteBreadCrumb,
      CustomModal
    },
    created(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      this.loading = true
      let urlClientesRiesgo = process.env.VUE_APP_API_URL+"/parametro/lista/recencia_clientes_riesgo"
      const getClientesRiesgo = axios.get(urlClientesRiesgo,config)
      let urlClientesPerdidos = process.env.VUE_APP_API_URL+"/parametro/lista/recencia_clientes_perdidos"
      const getClientesPerdidos = axios.get(urlClientesPerdidos,config)
      let urlClientesNoPedidos = process.env.VUE_APP_API_URL+"/parametro/lista/recencia_numero_nopedidos_alerta"
      const getClientesNoPedidos = axios.get(urlClientesNoPedidos,config)

      axios.all([getClientesRiesgo, getClientesPerdidos, getClientesNoPedidos]).then(axios.spread((...responses) => {
        const resClientesRiesgo = responses[0].data[0]
        $this.clientes_riesgo = resClientesRiesgo
        const resClientesPerdidos = responses[1].data[0]
        $this.clientes_perdidos = resClientesPerdidos
        const resClientesNoPedidos = responses[2].data[0]
        $this.clientes_nopedidos = resClientesNoPedidos
        $this.loading = false
      })).catch(errors => {
        $this.loading = false
      })
    },
    computed:{
      ...mapGetters({
        authenticated: 'auth/authenticated',
        user: 'auth/user',
      }),
    },
    mounted() {
      if(this.user.rol == 3){
        this.distribuidoraIdCurrent = this.user.idDistribuidora
      }
    },
    methods: {
      ...mapActions({
        getDistributorList: 'distribuidora/getList',
      }),
      isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault();;
        } else {
          return true;
        }
      },
      updateRecencia(evn, name){
        const $this = this
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        let dataSend = ""
        if(name === "clientes_riesgo"){
          dataSend = this.clientes_riesgo
        }
        if(name === "clientes_perdidos"){
          dataSend = this.clientes_perdidos
        }
        if(name === "clientes_nopedidos"){
          dataSend = this.clientes_nopedidos
        }
        this.loading = true
        const urlApiUpdated = process.env.VUE_APP_API_URL+"/parametro/actualizar"
        axios.put(urlApiUpdated,dataSend,config)
        .then(function (response){
          const text =(response.status == 200) ? "Se actulizaron la recencia de compra" : "Hubo un error";
          const icon ="ni ni-bell-55";
          const content={
            component: Notification,
            props: {
              icon: icon,
              text: text,
              type: (response.status == 200) ? "success" : "danger",
            },
          };
          const toast = useToast();
          toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
          $this.loading = false
        }).catch(function(error){
          console.log({error});
          $this.loading = false
        })
      },
    },
    data() {
      return {
        distribuidoraIdCurrent: null,
        loading: false,
        clientes_riesgo:{},
        clientes_perdidos:{},
        clientes_nopedidos:{},
      };
    },
  };
</script>

