import { Theme } from "../theme";

export const PropsHandler = {
	_fromTheme(category, str) {
		const [root, leaf] = str.split("-");
		const rootObj = Theme[category][root];
		if (typeof rootObj == "string") return rootObj;
		if (typeof rootObj == "object" && !leaf) return rootObj.DEFAULT;
		if (rootObj == null) {
			throw new Error(
				`The property ${str} on the ${category} category theme does not exit. Please create the property before using it.`,
			);
		}
		return rootObj[leaf];
	},
	_validator(str, category) {
		if (!str) return str;
		return str[0] == "-" ? this._fromTheme(category, str.slice(1)) : str;
	},
	size(size) {
		return this._validator(size, "sizes");
	},
	spacing(sp) {
		return this._validator(sp, "spacing");
	},
	fontSize(fs) {
		return this._validator(fs, "fontSizes");
	},
	fontWeight(fw) {
		return this._validator(fw, "fontWeights");
	},
	color(color) {
		return this._validator(color, "colors");
	},
};

export const ThemeProps = {
	styleText(props) {
		return {
			"--ui-text-color": PropsHandler.color(props.textColor ?? "inherit"),
			"--ui-fs": PropsHandler.fontSize(props.fs ?? "inherit"),
			"--ui-fw": PropsHandler.fontWeight(props.fw ?? "inherit"),
		};
	},
	styleColor(props) {
		return {
			"--ui-color": PropsHandler.color(props.color ?? "inherit"),
			"--ui-bg": PropsHandler.color(props.bg ?? "inherit"),
		};
	},
	styleContainer(props) {
		return {
			"--ui-px": PropsHandler.spacing(props.px ?? "inherit"),
			"--ui-py": PropsHandler.spacing(props.py ?? "inherit"),
			"--ui-w": PropsHandler.size(props.w ?? "inherit"),
			"--ui-h": PropsHandler.size(props.h ?? "inherit"),
		};
	},
	style(props) {
		return {
			...this.styleColor(props),
			...this.styleContainer(props),
			...this.styleText(props),
		};
	},
	get textProps() {
		return {
			textColor: String,
			fs: String,
			fw: String,
		};
	},
	get colorProps() {
		return {
			color: String,
			bg: String,
		};
	},
	get containerProps() {
		return {
			px: String,
			py: String,
			w: String,
			h: String,
		};
	},
	get props() {
		return {
			...this.colorProps,
			...this.containerProps,
			...this.textProps,
		};
	},
};
