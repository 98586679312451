<template>
  <card
    class="no-border-card"
    body-classes="px-0 pb-1 pt-0 mt-0"
    footer-classes="pb-2"
  >
    <template v-slot:header>
      <div class="row">
        <div class="col-12 col-md-6">
          <h3 class="mb-0">{{title}}</h3>
        </div>
      </div>
    </template>
    <div class="card-body">
      <p class="text" style="font-size: 14px;">Selecciona la pregunta que deseas actualizar</p>
      <div class="row list_access">
        <template v-for="item in dataList" :key="item.idRegistro">
          <div class="col-md-3">
            <router-link :to="{name:'Editar Pregunta del Cluster', params: {id:item.codigoDato}, query:{type:'preguntaCluster', name:item.nombre}}">
              <stats-card
                :title="item.nombre"
                type="gradient-laive"
                sub-title=""
                icon="fas fa-angle-right"
                class="subitem"
              >
              </stats-card>
            </router-link>
          </div>
        </template>
      </div>
    </div>
  </card>
</template>
<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BasePagination from "@/components/BasePagination";
import DashboardNavbar from "../Layout/DashboardNavbar";
export default {
  components: {
    DashboardNavbar,
    BasePagination,
    RouteBreadCrumb,
  },
  props:{
      dataList:{
        type: [Array],
      },
  },
  created(){
    this.title = this.$route.query.name
  },
  methods: {
  },
  data() {
    return {
      title: ""
    };
  },
};
</script>
