<template>
  <div class="row mx-0 mt-2">
    <div class="col-9 px-0">
      <div class="row mx-0 h-100">
        <div
          class="col-2 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
        >
          <span class="px-1"> {{ memo.codigo }} </span>
        </div>

        <div
          class="col-2 px-0 font-10 py-2 text-center d-flex align-items-center justify-content-center"
        >
          <span class="px-1"> {{ memo.descripcion }} </span>
        </div>
        <div
          class="col-1 px-0 font-10 py-2 text-center d-flex align-items-center justify-content-center"
        >
          <span class="px-1"> {{ memo.oficinaventa }} </span>
        </div>
        <div
          class="col-1 px-0 font-10 py-2 text-center d-flex align-items-center justify-content-center"
        >
          <span class="px-1"> {{ mes_liquidacion }} </span>
        </div>
        <div
          class="col-1 px-0 font-10 py-2 text-center d-flex align-items-center justify-content-center"
        >
          <span class="px-1"> {{ memo.fecha_aprobacion }} </span>
        </div>
        <div
          class="col-1 px-0 font-10 py-2 text-center d-flex align-items-center justify-content-center"
        >
          <span class="px-1"> {{  formatearCantidades(memo.costo_total) }} </span>
        </div>
        <div
          class="col-2 px-0 font-10 py-2 text-center d-flex align-items-center justify-content-center"
        >
          <Chip
            :texto="memo.estado"
            :aditional_class="`chip-message-${memo.estado} text-capitalize`"
          ></Chip>
        </div>
      </div>
    </div>
    <div class="col-3 px-0">
      <div class="row mx-0 d-flex justify-content-end">
        <button
          type="button"
          @click.prevent="descargarExcel"
          class="btn text-color-blue p-0 d-flex align-items-center justify-content-center font-10 border-right pr-2 ml-autp"
          :disabled="procesando_descarga_excel"
        >
          <i class="fas fa-download fon-11 mr-1" v-if="!procesando_descarga_excel"></i>
          <div v-else class="laive-loader"></div>
           Excel
        </button>
        <button
          type="button"
          @click.prevent="descargarWord"
          class="btn text-color-blue p-0 d-flex align-items-center justify-content-center font-10 border-right pr-2 ml-autp"
          :disabled="procesando_descarga_word"
        >
          <i class="fas fa-file-word fon-11 mr-1" v-if="!procesando_descarga_word"></i>
          <div v-else class="laive-loader"></div>
           Word
        </button>

        <button
          type="button"
          @click.prevent="confirmarObservacion"
          class="btn circle-btn-sm text-danger mx-1 p-0 d-flex align-items-center justify-content-center"
          :disabled="memo.estado!=constantes.PENDIENTE || esta_actualizando"
        >
          <i class="fas fa-times font-11"></i>
        </button>

        <button
          @click.prevent="confirmarAprobacion"
          type="button"
          :disabled="[constantes.APROBADO, constantes.OBSERVADO].includes(memo.estado) || esta_actualizando"
          class="btn circle-btn-sm bonificacion-primary-text mx-1 p-0 d-flex align-items-center justify-content-center"
        >
          <i class="fas fa-check font-11"></i>
        </button>

        <button
          type="button"
          class="btn circle-btn-sm bonificacion-primary-text mx-1 p-0 d-flex align-items-center justify-content-center"
          data-toggle="collapse"
          :data-target="`#memo-${memo.idmemo}`"
        >
          <i class="fas fa-chevron-circle-down"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="collapse row mx-0 px-1" :id="`memo-${memo.idmemo}`">
    <div class="col-12 px-0 rounded border border-gray overflow-hidden">
      <div class="row mx-0 header">
        <div class="col-2 py-2 font-10">Dex</div>
        <div class="col-1 py-2 font-10 text-center">Total</div>
      </div>

      <div
        class="row mx-0"
        v-for="distribuidora in memo.distribuidoras"
        :key="distribuidora"
      >
        <div class="col-2 py-1 font-10">
          {{ distribuidora.codigodistribuidora }}
        </div>
        <div class="col-1 py-1 font-10 text-center">
          {{ distribuidora.costo_total }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Chip from "../../../Bonificaciones/components/Chip.vue";
import Swal from "sweetalert2";

import { ref, computed, watch, onMounted } from "vue";
import constantes from "@/model/constantes.js";
import { useStore } from "vuex";
const store = useStore();
const { memo } = defineProps({
  memo: {
    type: Object,
  },
});

const diccionario_mes = {};

// Agregar elementos al diccionario
diccionario_mes[1] = "Enero";
diccionario_mes[2] = "Febrero";
diccionario_mes[3] = "Marzo";
diccionario_mes[4] = "Abril";
diccionario_mes[5] = "Mayo";
diccionario_mes[6] = "Junio";
diccionario_mes[7] = "Julio";
diccionario_mes[8] = "Agosto";
diccionario_mes[9] = "Marzo";
diccionario_mes[10] = "Setiembre";
diccionario_mes[11] = "Noviembre";
diccionario_mes[12] = "Diciembre";

const mes_liquidacion = computed(() => {
  const fecha = new Date(memo.fecha_creacion);
  const mes = fecha.getMonth() + 1;
  return `${diccionario_mes[mes]} ${fecha.getFullYear()}`;
});

const formatearCantidades = (param) => {
  const cantidad = parseFloat(param);
  return cantidad.toLocaleString("en-US");
};

const esta_actualizando = ref(false);

const inicializarMensaje = () => {
  return {
    id: `msj_${new Date().getTime()}`,
    opacidad: 1,
  };
};

const mostrarMensaje = (mensaje) => {
  store.commit("liquidacion/agregarMensaje", mensaje);
  setTimeout(() => {
    store.commit("liquidacion/cambiarOpacity", mensaje.id);
    store.commit("liquidacion/eliminarMensaje", mensaje.id);
  }, 5000);
};

const aprobarEnviados = () => {
  const liquidaciones = distribuidora.liquidaciones.filter(
    (item) => item.estado == constantes.ENVIADO
  );
  liquidaciones.forEach((liquidacion) => {
    enviarLiquidacion(liquidacion);
  });
};

const enviarLiquidacion = (liquidacion) => {
  esta_actualizando.value = true;
  const mensaje = inicializarMensaje();

  const payload = {
    idbonificacionbonhistoricodistribuidora:
      liquidacion.idbonificacionbonhistoricodistribuidora,
    rango_promo_inicio: liquidacion.rango_promo_inicio,
    rango_promo_fin: liquidacion.rango_promo_fin,
    estado: constantes.APROBADO,
  };

  store.dispatch("liquidacion/actualizarEstado", payload).then((result) => {
    if (result.status == 200) {
      mensaje.clase = "chip-message-success";
      mensaje.texto = "Cambios guardados exitosamente.";
      liquidacion.estado = constantes.APROBADO;
    } else {
      mensaje.clase = "chip-message-error";
      mensaje.texto = "Ocurrió un error.";
    }
    esta_actualizando.value = false;
    mostrarMensaje(mensaje);
  });
};


const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-success",
    cancelButton: "btn btn-danger",
  },
  buttonsStyling: false,
});

const confirmarAprobacion = ()=>{
swalWithBootstrapButtons
    .fire({
      title: "Aprobar",
      text: `¿Esta seguro de aprobar el memo ${memo.codigo}?`,
      showCancelButton: true,
      confirmButtonText: "Aprobar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    })
    .then((result) => {
      if (result.isConfirmed) {
        cambiarEstado(constantes.APROBADO);
      }
    });

};
const confirmarObservacion = ()=>{

  swalWithBootstrapButtons
    .fire({
      title: "Observación",
      text: `¿Esta seguro de observar el memo ${memo.codigo}?`,
      showCancelButton: true,
      confirmButtonText: "Observar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    })
    .then((result) => {
      if (result.isConfirmed) {
        cambiarEstado(constantes.OBSERVADO);
      }
    });

};

const cambiarEstado = (estado)=>{

  esta_actualizando.value = true;
  const mensaje = inicializarMensaje();

  const payload = {
    idmemo: memo.idmemo,
    estado
  };

  store.dispatch("liquidacion/actualizarEstadoMemo", payload).then((result) => {
    if (result.status == 200) {
      mensaje.clase = "chip-message-success";
      mensaje.texto = "Cambios guardados exitosamente.";
      memo.estado = estado;
    } else {
      mensaje.clase = "chip-message-error";
      mensaje.texto = "Ocurrió un error.";
    }
    esta_actualizando.value = false;
    mostrarMensaje(mensaje);
  });


};

const procesando_descarga_excel = ref(false);
const procesando_descarga_word = ref(false);

const descargarExcel = () =>{
  procesando_descarga_excel.value = true;
  const request = {
    memoid: memo.idmemo
  };
  store.dispatch("liquidacion/DescargarMemoExcel", request).then((result) => {
    procesando_descarga_excel.value = false;
    abrirEnlace(result);

  });
}

const descargarWord = () =>{
  procesando_descarga_word.value = true;
  const request = {
    memoid: memo.idmemo
  };
  store.dispatch("liquidacion/DescargarMemoWord", request).then((result) => {
    procesando_descarga_word.value = false;
    abrirEnlace(result);
  });
}

const abrirEnlace = (objetivo) =>{
  const link = document.createElement('a');
        link.href = objetivo;
        link.target = '_blank';
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
}

</script>

<style scoped>
.btn:disabled {
  color: #b8b8b8 !important;
  background: transparent !important;
}

</style>
