<template>
    <div class='right-sidebar show' v-if='show'>
        <!-- filtros avanzados -->
        <div  class='card no-border advance-filter'>
            <div class='card-header d-flex position-relative'>
                <h4 class="card-title m-0">Filtros avanzados</h4>
                <a class="btn-close ml-auto" @click='close()'><i class='fa fa-times'></i></a>
            </div>
            <div class='card-body p-0'>
                <div id="accordion">
                    <div class="card mb-0">
                        <div class="card-header  position-relative" id="heading1" data-toggle="collapse" data-target="#collapse1" :aria-expanded="filterA.option1.show" aria-controls="collapse1" :is-filter="filterA.option1.isFilter">
                            <h5 class="mb-0" >
                                Filtrar según la venta de sus clientes
                            </h5>
                            <i class="fas fa-angle-down"></i>
                        </div>
                        <div id="collapse1" class="collapse" :class="{showI:filterA.option1.show}" aria-labelledby="heading1" data-parent="#accordion" >
                            <div class="card-body ">
                                <div class='row'>
                                    <div class='col-12'>
                                        <SelectButton :buttonCollection='filterA.option1.clases'></SelectButton>
                                    </div>
                                    <div class='col-12 mt-3'>
                                        <div class='form-group'>
                                            <base-input class='left-icon' v-model ='filterA.option1.number' :label='filterA.option1.label_number' @keypress="isNumber($event)">
                                                <template v-slot:addonLeft>S/</template>
                                            </base-input>
                                        </div>
                                    </div>
                                    <div class='col-12 d-flex'>
                                        <a class='btn-filtro' @click='aplicarFiltro(filterA.option1,1,"collapse1")'>Aplicar filtros</a>
                                        <a class='btn-limpiar ml-2' @click='limpiarFiltro(filterA.option1)'>Limpiar filtros</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--option 2-->
                    <div class="card mb-0">
                        <div class="card-header position-relative" id="heading2" data-toggle="collapse" data-target="#collapse2" aria-expanded="false" aria-controls="collapse2" :is-filter="filterA.option2.isFilter">
                            <h5 class="mb-0" >
                                Filtrar según la cobranza de sus clientes
                            </h5>
                            <i class="fas fa-angle-down"></i>
                        </div>
                        <div id="collapse2" class="collapse" aria-labelledby="heading2" data-parent="#accordion">
                            <div class="card-body">
                                <div class='row'>
                                    <div class='col-12'>
                                        <SelectButton :buttonCollection='filterA.option2.clases'></SelectButton>
                                    </div>
                                    <div class='col-12 mt-2'>
                                        <!--@keypress="isNumber($event)"-->
                                        <base-input class='right-icon' v-model ='filterA.option2.number' :label='filterA.option2.label_number' @keypress="isNumber($event)">
                                            <template v-slot:addonRight>%</template>
                                        </base-input>
                                    </div>
                                    <div class='col-12 d-flex'>
                                        <a class='btn-filtro' @click='aplicarFiltro(filterA.option2,2,"collapse2")'>Aplicar filtros</a>
                                        <a class='btn-limpiar ml-2' @click='limpiarFiltro(filterA.option2)'>Limpiar filtros</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--option 3 -->
                    <div class="card mb-0">
                        <div class="card-header position-relative" id="heading3" data-toggle="collapse" data-target="#collapse3" aria-expanded="false" aria-controls="collapse3" :is-filter="filterA.option3.isFilter">
                            <h5 class="mb-0" >
                                Filtrar según la efectividad en sus visitas
                            </h5>
                            <i class="fas fa-angle-down"></i>
                        </div>
                        <div id="collapse3" class="collapse" aria-labelledby="heading3" data-parent="#accordion">
                            <div class="card-body">
                                <div class='row'>
                                    <div class='col-12'>
                                        <SelectButton :buttonCollection='filterA.option3.clases'></SelectButton>
                                    </div>
                                    <div class='col-12 mt-2'>
                                        <div class='form-group mb-0'>
                                            <label class=''>{{filterA.option3.label_number}}</label>
                                            <select v-model ='filterA.option3.number' class='form-control'>
                                                <option value=''>Elige una opción</option>
                                                <option v-for='item in this.getEfectividad' :key="item.codigo" :value ='item.codigo'>{{item.nombre}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class='col-12 mt-4 d-flex'>
                                        <a class='btn-filtro' @click='aplicarFiltro(filterA.option3,3,"collapse3")'>Aplicar filtros</a>
                                        <a class='btn-limpiar ml-2' @click='limpiarFiltro(filterA.option3)'>Limpiar filtros</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mb-0">
                        <div class="card-header position-relative" id="heading4" data-toggle="collapse" data-target="#collapse4" aria-expanded="false" aria-controls="collapse4" :is-filter="filterA.option4.isFilter">
                            <h5 class="mb-0" >
                                Filtro según la devolución de sus clientes
                            </h5>
                            <i class="fas fa-angle-down"></i>
                        </div>
                        <div id="collapse4" class="collapse" aria-labelledby="heading4" data-parent="#accordion">
                            <div class="card-body">
                                <div class='row'>
                                    <div class='col-12'>
                                        <SelectButton :buttonCollection='filterA.option4.clases'></SelectButton>
                                    </div>
                                    <div class='col-12 mt-2'>
                                        <!--@keypress="isNumber($event)"-->
                                        <base-input class='right-icon' v-model ='filterA.option4.number' :label='filterA.option4.label_number' @keypress="isNumber($event)">
                                            <template v-slot:addonRight>%</template>
                                        </base-input>
                                    </div>
                                    <div class='col-12 d-flex'>
                                        <a class='btn-filtro' @click='aplicarFiltro(filterA.option4,4,"collapse4")' >Aplicar filtros</a>
                                        <a class='btn-limpiar ml-2' @click='limpiarFiltro(filterA.option4)'>Limpiar filtros</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mb-0">
                        <div class="card-header position-relative" id="heading5" data-toggle="collapse" data-target="#collapse5" aria-expanded="false" aria-controls="collapse5" :is-filter="filterA.option5.isFilter">
                            <h5 class="mb-0" >
                                Filtro según la compra de surtido de sus clientes
                            </h5>
                            <i class="fas fa-angle-down"></i>
                        </div>
                        <div id="collapse5" class="collapse" aria-labelledby="heading5" data-parent="#accordion">
                            <div class="card-body">
                                <div class='row'>
                                    <div class='col-12'>
                                        <SelectButton :buttonCollection='filterA.option5.clases'></SelectButton>
                                    </div>
                                    <div class='col-12 mt-2'>
                                        <!--@keypress="isNumber($event)"-->
                                        <base-input  v-model ='filterA.option5.number' :label='filterA.option5.label_number' @keypress="isNumber($event)">

                                        </base-input>
                                    </div>
                                    <div class='col-12 d-flex'>
                                        <a class='btn-filtro' @click='aplicarFiltro(filterA.option5,5,"collapse5")'>Aplicar filtros</a>
                                        <a class='btn-limpiar ml-2' @click='limpiarFiltro(filterA.option5)'>Limpiar filtros</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mb-0">
                      <div class="card-header position-relative" id="heading6" data-toggle="collapse" data-target="#collapse6" aria-expanded="false" aria-controls="collapse6" :is-filter="filterA.option6.isFilter">
                          <h5 class="mb-0" >
                              Filtro según los pedidos de sus clientes
                          </h5>
                          <i class="fas fa-angle-down"></i>
                      </div>
                      <div id="collapse6" class="collapse" aria-labelledby="heading6" data-parent="#accordion">
                        <div class="card-body">
                          <div class='row'>
                            <div class='col-12'>
                                <SelectButton :buttonCollection='filterA.option6.clases'></SelectButton>
                            </div>
                            <div class='col-12 mt-2'>
                              <div class='form-group mb-0'>
                                <label class=''>{{filterA.option6.label_number}}</label>
                                <select v-model ='filterA.option6.number' class='form-control'>
                                  <option value=''>Elige una opción</option>
                                  <option v-for='item in this.getPedidos' :key="item.codigo" :value ='item.codigo'>{{item.nombre}}</option>
                                </select>
                              </div>
                            </div>
                            <div class='col-12 mt-4 d-flex'>
                                <a class='btn-filtro' @click='aplicarFiltro(filterA.option6,6,"collapse6")'>Aplicar filtros</a>
                                <a class='btn-limpiar ml-2' @click='limpiarFiltro(filterA.option6)'>Limpiar filtros</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                <!--checkingFiltered-->

                <div v-if="this.checkingFiltered.length > 0" class='col-12'>
                    <a class='btn btn-primary space d-block' @click='clearFilter()'>Limpiar todos los filtros</a>
                </div>
            </div>
        </div>

        <!--lipiar filtros -->
        <!--<div v-if='false' class='card no-border'>
            <div class='card-header d-flex position-relative'>
                <h4 class="card-title m-0">Filtrar Clientes</h4>
                <a class="btn-close ml-auto" @click='close()'><i class='fa fa-times'></i></a>
            </div>
            <div class='card-body'>
                <a v-for='item in filtered_options' class='filtered-label'>{{item.text }} <strong>{{item.value}}</strong></a>

            </div>
        </div>-->
    </div>
    <div class='overflow-right-sidebar' @click='close()' v-if='show'></div>

    <!-- tags selects -->
    <div class ='filter-tags pt-2'>
        <label class='d-none'>Filtros aplicados({{this.checkingFiltered?.length}}):</label>
        <div class='tag-container'>
            <div v-for="item of this.checkingFiltered" class='tag' :key="item.filtro.label" >
                <span>
                    <p class='segmento'>Segmento: <strong>{{item.segmento}}</strong></p>
                    <p class='filtro'>{{item.filtro.label}} <strong>{{item.filtro.value}}</strong></p>
                </span>
                <i class='fa fa-times' @click='removeTag(item)'></i>
            </div>
        </div>
    </div>
</template>


<script>
import SelectButton from './SelectButton';
export default {
    props: {
      show: false,
      suggest:false,
      reset:false,
      efectividad: [],
      pedido:[]
    },
    components: {SelectButton},
    computed: {
        getEfectividad(){
            if(!this.efectividad) return [];
            if(this.suggest) {return this.efectividad;}
            else { return this.efectividad.filter(x=>x.codigo != '02');}
        },
      getPedidos() {
          console.log(this.pedido)
          if(!this.pedido) return [];
          if(this.suggest) {return this.pedido;}
          else { return this.pedido.filter(x=>x.codigo != '02');}
        },
        /*devuelve los filtros seleccionados*/
        checkingFiltered(){
            let result = [];
            let f1 = this.genereFilteredInfo(this.filterA.option1,'S/');
            if(f1 ? f1.tag.show : false) {result.push(f1.tag); this.filterA.option1.isFilter = f1.tag.show;} else {this.filterA.option1.isFilter = false;}

            let f2 = this.genereFilteredInfo(this.filterA.option2,'%');
            if(f2 ? f2.tag.show : false) {result.push(f2.tag); this.filterA.option2.isFilter = f2.tag.show;} else {this.filterA.option2.isFilter = false;}

            let f3 = this.genereFilteredInfo1(this.filterA.option3,'');
            if(f3 ? f3.tag.show : false) {result.push(f3.tag); this.filterA.option3.isFilter = f3.tag.show;} else {this.filterA.option3.isFilter = false;}

            let f4 = this.genereFilteredInfo(this.filterA.option4,'%');
            if(f4 ? f4.tag.show : false) {result.push(f4.tag); this.filterA.option4.isFilter = f4.tag.show;} else {this.filterA.option4.isFilter = false;}

            let f5 = this.genereFilteredInfo(this.filterA.option5,'');
            if (f5 ? f5.tag.show : false) { result.push(f5.tag); this.filterA.option5.isFilter = f5.tag.show; } else { this.filterA.option5.isFilter = false; }

            let f6 = this.genereFilteredInfoTelef(this.filterA.option6, '');
            if (f6 ? f6.tag.show : false) { result.push(f6.tag); this.filterA.option6.isFilter = f6.tag.show; } else { this.filterA.option6.isFilter = false; }

            return result;
        },
    },
    methods: {
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
            evt.preventDefault();
            } else {
            return true;
            }
        },
        close() {
            let x = this.checkingFiltered;
            this.$emit("filter", 'close',x.length);
            //this.$emit("filter",'filtered',this.filterSelect());
        },

        /*elimina el filtro seleccionado*/
        removeTag(item){
            item.option.clases.map(x=>{x.select = false; return x;});
            item.option.number = undefined;
            let x = this.checkingFiltered;
            this.$emit("filter",'filtered',{length:x.length,filtro:this.filterSelect()});
        },
        limpiarFiltro(option){
            option.clases.map(x=>{x.select = false; return x;});
            option.number = undefined;
            let x = this.checkingFiltered;
            this.$emit("filter",'filtered',{length:x.length,filtro:this.filterSelect()});
        },
        aplicarFiltro(filter,pos,name){
            let elm = document.getElementById(name).classList.remove('show');
            filter.show = false;
            this.filterA.current = pos;
            let x = this.checkingFiltered;
            this.$emit("filter",'filtered',{length:x.length,filtro:this.filterSelect()});
        },


        clearFilter() {
            this.filterA.option1.clases.map(x=>{ x.select = false; return x; })
            this.filterA.option1.number ='';

            this.filterA.option2.clases.map(x=>{ x.select = false; return x; })
            this.filterA.option2.number ='';

            this.filterA.option3.clases.map(x=>{ x.select = false; return x; })
            this.filterA.option3.number ='';

            this.filterA.option4.clases.map(x=>{ x.select = false; return x; })
            this.filterA.option4.number ='';

            this.filterA.option5.clases.map(x=>{ x.select = false; return x; })
            this.filterA.option5.number = '';

            this.filterA.option6.clases.map(x=>{ x.select = false; return x; })
            this.filterA.option6.number ='';

            this.is_filtered = false;
            this.filtered_options = [];
            let x = this.checkingFiltered;
            this.$emit("filter",'filtered',{length:x.length,filtro:this.filterSelect()});
        },

        buttonSelectChange(i) {
            i.select = i.select === true ? false : true
        },


        changeFilter(){

        },
        genereSegmento(buttons){
            let _value ='';
            for(let o of buttons){ _value += o.value + ','; }
            _value = _value != '' ? (_value + '@').replace(',@','') :'';
            return _value;
        },
        genereFilteredInfo(option, simbol) {
          if(!option) return undefined;
            if(!this.efectividad) return undefined;
            let buttons = option.clases.filter(x=>x.select == true);

            let btn_str='';
            let _value ='';
            for(let o of buttons){ btn_str += o.text + ','; _value += o.value + ',';}
            btn_str = btn_str != '' ? (btn_str + '@').replace(',@','') :'';
            _value = _value != '' ? (_value + '@').replace(',@','') :'';

             let filtro_build = {};
            if(_value != '') filtro_build['segmento']= _value;
            if(option.number ? (option.number !='' ? option.number :false) : false) filtro_build['filtro']= option.number;
            return {
                tag:{
                    show: (btn_str !='' || (option.number ? (option.number != '' ? true : false) :false)),
                    segmento: `${btn_str}`,
                    filtro: {
                        label:`${option.label_tag}`,
                        value:`${(simbol == 'S/' ? simbol:'')+(option.number ? option.number : '') + (simbol != 'S/' ? simbol :'')}`
                    },
                    option:option
                },
                filtro : filtro_build
            }

        },
        genereFilteredInfo1(option,simbol){
            if(!option) return undefined;
            if(!this.efectividad) return undefined;
            let buttons = option.clases.filter(x=>x.select == true);

            let btn_str='';
            let _value ='';
            for(let o of buttons){ btn_str += o.text + ','; _value += o.value + ',';}
            btn_str = btn_str != '' ? (btn_str + '@').replace(',@','') :'';
            _value = _value != '' ? (_value + '@').replace(',@','') :'';
            let a = this.efectividad.find(x=>x.codigo == option.number)
            console.log({a})
            let filtro_build = {};
            if(_value != '') filtro_build['segmento']= _value;
            if(option.number ? (option.number !='' ? option.number :false) : false) filtro_build['filtro']= option.number;
            return {
                tag:{
                    show: (btn_str !='' || (option.number ? (option.number != '' ? true : false) :false)),
                    segmento: `${btn_str}`,
                    filtro: {
                        label:`${option.label_tag}`,
                        value:`${a ? a.nombre : ''}`
                    },
                    option:option
                },
                filtro : filtro_build
            }
        },

        genereFilteredInfoTelef(option,simbol){
            if(!option) return undefined;
            if(!this.pedido) return undefined;
            let buttons = option.clases.filter(x=>x.select == true);

            let btn_str='';
            let _value ='';
            for(let o of buttons){ btn_str += o.text + ','; _value += o.value + ',';}
            btn_str = btn_str != '' ? (btn_str + '@').replace(',@','') :'';
            _value = _value != '' ? (_value + '@').replace(',@','') :'';
            let a = this.pedido.find(x=>x.codigo == option.number)
            console.log({a})
            let filtro_build = {};
            if(_value != '') filtro_build['segmento']= _value;
            if(option.number ? (option.number !='' ? option.number :false) : false) filtro_build['filtro']= option.number;
            return {
                tag:{
                    show: (btn_str !='' || (option.number ? (option.number != '' ? true : false) :false)),
                    segmento: `${btn_str}`,
                    filtro: {
                        label:`${option.label_tag}`,
                        value:`${a ? a.nombre : ''}`
                    },
                    option:option
                },
                filtro : filtro_build
            }
        },

        filterSelect(){
            let filtro = {};
            let f1 = this.genereFilteredInfo(this.filterA.option1,'');
            if(f1.filtro.segmento || f1.filtro.filtro) filtro['venta'] = f1.filtro;

            let f2 = this.genereFilteredInfo(this.filterA.option2,'');
            if(f2.filtro.segmento || f2.filtro.filtro) filtro['cobranza'] = f2.filtro;

            let f3 = this.genereFilteredInfo1(this.filterA.option3,'');
            if(f3.filtro.segmento || f3.filtro.filtro) filtro['efectividad'] = f3.filtro;

            let f4 = this.genereFilteredInfo(this.filterA.option4,'');
            if(f4.filtro.segmento || f4.filtro.filtro) filtro['devolucion'] = f4.filtro;

            let f5 = this.genereFilteredInfo(this.filterA.option5,'');
            if (f5.filtro.segmento || f5.filtro.filtro) filtro['surtido'] = f5.filtro;

            let f6 = this.genereFilteredInfoTelef(this.filterA.option6,'');
            if(f6.filtro.segmento || f6.filtro.filtro) filtro['pedido'] = f6.filtro;

            return filtro;
            /*return  {
                venta: this.genereFilteredInfo(this.filterA.option1,'').filtro,
                cobranza: this.genereFilteredInfo(this.filterA.option2,'').filtro,
                efectividad: this.genereFilteredInfo1(this.filterA.option3,'').filtro,
                devolucion: this.genereFilteredInfo(this.filterA.option4,'').filtro,
                surtido: this.genereFilteredInfo(this.filterA.option5,'').filtro
            };*/
        }
    },
    watch: {
      	show: function(a, b) {
          if(a == true){
            this.filtered_options = this.checkingFiltered;
            this.is_filtered = this.filtered_options.length > 0;
          }
        },
        reset:{
            handler(a, b) {
                if(a==true){
                    this.clearFilter();
                }
            },
            deep: true
        }

    },
    data: () => {
        return {
            is_filtered:false,
            filtered_options:[],
            segmentos:[
                { text: 'Top', select: false,value:'04' },
                { text: 'Oro', select: false,value:'03' },
                { text: 'Regular', select: false,value:'05' },
                { text: 'Especial', select: false,value:'02' },
                { text: 'Clásico', select: false,value:'01' },
            ],
            filterA: {
                current:0,
                option1: {
                    show:false,
                    isFilter:false,
                    label:'Filtrar según la venta de sus clientes',
                    label_number:'Con un pedido menor o igual a ',
                    label_tag:'Pedido menor a:',
                    clases: [
                        { text: 'Top', select: false,value:'04' },
                        { text: 'Oro', select: false,value:'03' },
                        { text: 'Regular', select: false,value:'05' },
                        { text: 'Especial', select: false,value:'02' },
                        { text: 'Clásico', select: false,value:'01' },
                    ],
                    number:undefined,
                    filtered:{
                        segmento :'',
                        filtro:undefined
                    }
                },
                option2: {
                    show:false,
                    isFilter:false,
                    label:'Filtrar según la cobranza de sus clientes',
                    label_number:'Que hayan pagado menos del ',
                    label_tag:'Pagaron menos de:',
                    clases: [
                        { text: 'Top', select: false,value:'04' },
                        { text: 'Oro', select: false,value:'03' },
                        { text: 'Regular', select: false,value:'05' },
                        { text: 'Especial', select: false,value:'02' },
                        { text: 'Clásico', select: false,value:'01' },
                    ],
                    number:undefined,

                },
                option3: {
                    show:false,
                    isFilter:false,
                    label:'Filtrar según la efectividad en sus visitas',
                    label_number:'Que tienen registrados ',
                    label_tag:'Registraron:',
                    clases: [
                        { text: 'Top', select: false,value:'04' },
                        { text: 'Oro', select: false,value:'03' },
                        { text: 'Regular', select: false,value:'05' },
                        { text: 'Especial', select: false,value:'02' },
                        { text: 'Clásico', select: false,value:'01' },
                    ],
                    number:undefined,
                },
                option4: {
                    show:false,
                    isFilter:false,
                    label:'Filtro según la devolución de sus clientes',
                    label_number:'Porcentaje de devolución mayor a ',
                    label_tag:'Devolvieron:',
                    clases: [
                        { text: 'Top', select: false,value:'04' },
                        { text: 'Oro', select: false,value:'03' },
                        { text: 'Regular', select: false,value:'05' },
                        { text: 'Especial', select: false,value:'02' },
                        { text: 'Clásico', select: false,value:'01' },
                    ],
                    number:undefined,

                },
                option5: {
                    show:false,
                    isFilter:false,
                    label:'Filtro según la compra de surtido de sus clientes',
                    label_number:'SKU’s promedio menor a ',
                    label_tag:"SKU's menor a:",
                    clases: [
                        { text: 'Top', select: false,value:'04' },
                        { text: 'Oro', select: false,value:'03' },
                        { text: 'Regular', select: false,value:'05' },
                        { text: 'Especial', select: false,value:'02' },
                        { text: 'Clásico', select: false,value:'01' },
                    ],
                    number:undefined
                },
                option6: {
                    show:false,
                    isFilter:false,
                    label:'Filtro según los pedidos de sus clientes',
                    label_number:'Que tienen registrados',
                    label_tag:'Registraron:',
                    clases: [
                        { text: 'Top', select: false,value:'04' },
                        { text: 'Oro', select: false,value:'03' },
                        { text: 'Regular', select: false,value:'05' },
                        { text: 'Especial', select: false,value:'02' },
                        { text: 'Clásico', select: false,value:'01' },
                    ],
                    number:undefined,
                },
            },
            pedidoValues: [
                20, 30, 40, 50, 60, 70, 80, 90
            ]
        }
    }
};
</script>

<style lang="scss">
.overflow-right-sidebar {
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: #00000054;
    position: fixed;
    left: 0px;
    top: 0px;
}
.right-sidebar {
    position: fixed;
    top: 0px;
    right: 0px;
    width: 390px;
    height: 100%;
    background: white;
    z-index: 10000;
    border-left: 1px solid #dee2e6;
    display: none;

    .btn-close {
        i {
            background: #F3F3F3!important;
            padding: 7px 9px!important;
            border-radius: 100px!important;
            cursor: pointer!important;
            color: #1A1818!important;
        }
    }

    .btn-secondary {
        color: #1A1818 !important;
        font-style: normal !important;
        font-weight: 300 !important;
        font-size: 12px !important;
        line-height: 16px !important;
        letter-spacing: 0.2px !important;
        background: rgba(229, 229, 229, 0.5) !important;
        border-radius: 8px;
        border: none !important;
        height: 40px !important;
    }

    .btn-primary {
        padding: 10px 16px !important;
        height: 40px !important;
        background: #E6F0FF !important;
        border-radius: 8px !important;
        color: #337AD0;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.2px;
        border: 1px solid #337AD0!important;
    }
    .btn-primary:hover {
         color: #337AD0!important;
         background:#c4dcff!important;
    }

    .space {
        margin-top: 40px;
    }

    &.show {
        display: block !important;
    }

    .filtered-label {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 12px;
        background: rgba(229, 229, 229, 0.5);
        border-radius: 8px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #1A1818;
        margin-top: 10px;

        strong {
            margin-left:4px;
        }
    }
}

[aria-expanded="true"] .fa-angle-down {
    transform: rotate(180deg);
}
#accordion {
    .card-header {
        cursor:pointer!important;
        i.fa-angle-down {
            position: absolute !important;
            right: 25px !important;
            top: 40% !important;
        }
        &[is-filter="true"] {
            h5 {
                color: #337AD0;
            }
            i{
                color: #337AD0;
            }

        }
    }
    .card-header:hover{
        background: #0bff0008!important;
    }
}


.select-button {
    label {
        display: block;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #6E6E6E;
    }

    div {
        display: flex;

        a {
            color: #FFFFFF;
            background: #007841;
            border-radius: 4px;
            align-items: center;
            padding: 5px 10px;
            text-align: center;
            font-style: normal;
            font-weight: 300;
            font-size: 11px;
            line-height: 16px;
            letter-spacing: 0.2px;
            cursor: pointer;
        }

        [select="false"] {
            background: #FFFFFF;
            color: #6E6E6E !important;
            border: 0.5px solid #B8B8B8;
            box-sizing: border-box;
            border-radius: 4px;
        }
    }
}

.advance-filter {
    .form-group {
        label {
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.2px;
            color: #6E6E6E;
            margin-bottom: 4px;
        }
        select {
            height:40px!important;
        }
        .input-group {
            .input-group-prepend {

            }

            input {
                height: 40px !important;
                font-style: normal !important;
                font-weight: 300 !important;
                font-size: 14px !important;
                line-height: 20px !important;
                letter-spacing: 0.2px !important;
                color: #6E6E6E !important;
            }
        }

        &.left-icon {
            .input-group-text {
                height: 40px !important;
                padding-right: 9px !important;
            }
            input {
                border-left: none;
                padding-left: 0px;
            }
        }

        &.right-icon {
            .input-group-text {
                height: 40px !important;
                padding-left: 9px !important;
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
            }
            input {
                border-right: none;
                padding-right: 0px;
            }
        }
    }

    .btn-filtro {
        padding: 10px 16px;
        width: 117px;
        height: 40px;
        /* left: 33px; */
        /* top: 278px; */
        border: 1px solid #02A54F;
        /* box-sizing: border-box; */
        border-radius: 8px;
        background: #FFFFFF;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #02A54F;
        display: block;
        cursor:pointer;
    }

    .btn-filtro:hover {
        background: #2dce8917;
        cursor: pointer;
    }

    .btn-limpiar {
        padding: 10px 16px;
        height: 40px;
        /*border: 1px solid #02A54F;*/
        border-radius: 8px;
        /*background: #FFFFFF;*/
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #6E6E6E;
        display: block;
        cursor:pointer;
    }
    .btn-limpiar:hover {
        border: 1px solid #ED1C24;
        color:#ED1C24;
    }
}

/*tags de filtro seleccionado*/
.filter-tags {
  display: flex;
  align-items: center;
  label {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #52648B;
    margin-bottom: 0px;
  }

  .tag-container {
    display: flex;
    align-items: center;
    .tag {
        font-family: Poppins;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        letter-spacing: 0.2px;
        color: #1A1818;
        margin-right: 10px;
        display: flex;
        align-items: center;
      span {
        background: rgba(229, 229, 229, 0.6);
        border-radius: 5px;
        padding: 4px 6px;
        .segmento {
            font-family: Poppins;
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.2px;
            margin-bottom:0px;
        }
        .filtro {
            font-family: Poppins;
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.2px;
            margin-bottom:0px;
        }
      }
      i {
        margin-left: 5px;
        cursor: pointer;
        font-size: 15px!important;
      }
      i:hover {
        background: #edebe8;
        border-radius: 20px;
        padding: 2px 3px;
        margin-left: 2px;
      }
    }
  }
}
</style>
