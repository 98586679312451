<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <!-- HTML5 inputs -->
            <div class="card">
              <!-- Card header -->
              <div class="card-header">
                <h3 class="mb-0">Estos campos se reflejarán en la aplicación</h3>
              </div>
              <!-- Card body -->
              <div class="row">
                <div class="col-md-6">
                  <form @submit.prevent="submitUpdatedCronJob" class="card-body pt-0 form_laive validateClient">
                    <div class="box_loader">
                      <div class="loader"></div>
                    </div>
                    <div class="row">
                      <label class="col-md-12 col-form-label form-control-label">Configuración de Correos</label>
                      <div class="col-md-12">
                        <span class="title_filter mr-2">Seleccione una Distribuidora:  </span>
                        <select class="form-control" name="distributor_select" id="distributor_select" style="display: inline-block;height:43px;max-width: 280px;" @change="changeDistribuidora">
                          <option value="" selected disabled style="display:none">Seleccionar</option>
                          <option
                            v-for="item in distribuidoras"
                            :value="item.idDistribuidora"
                            :key="item.idDistribuidora"
                            >
                            {{ item.razonSocial }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-9 mt-4" v-if="cronjob.id">
                        <div>
                          <span class="title_filter mr-2">Intervalos de tiempo:  </span>
                          <select class="form-control" name="distributor_select" id="distributor_select" style="display: inline-block;height:43px;max-width: 280px;" @change="changeInterval">
                            <option value="" selected disabled style="display:none">Seleccionar</option>
                            <option
                              v-for="item in intervalos"
                              :value="item.id"
                              :key="item.id"
                              :selected="item.id == cronjob.intervalo"
                              >
                              {{ item.value }}
                            </option>
                          </select>
                        </div>
                        <div class="mt-4">
                          <span class="title_filter mr-2">Asunto del Correo:  </span>
                          <input style="display: inline-block;height:43px;max-width: 280px;" class="form-control mb-2" name="cronjob_asunto" id="cronjob_asunto" minlength="4" type="text" placeholder="Asunto del Correo" v-model="cronjob.asunto" required>
                        </div>
                        <div class="mt-4 mb-4">
                          <div class="title_filter mb-2">Agregar correos:  </div>
                          <div class="form-group">
                            <input class="form-control cronjob_phome mb-2" name="cronjob_email_1" id="cronjob_email_1" minlength="" maxlength="" type="email" placeholder="Agregar correo Electronico" required>
                            <input class="form-control cronjob_phome mb-2 d-none" name="cronjob_email_2" id="cronjob_email_2" minlength="" maxlength="" type="email" placeholder="Agregar correo Electronico">
                            <input class="form-control cronjob_phome mb-2 d-none" name="cronjob_email_3" id="cronjob_email_3" minlength="" maxlength="" type="email" placeholder="Agregar correo Electronico">
                            <input class="form-control cronjob_phome mb-2 d-none" name="cronjob_email_4" id="cronjob_email_4" minlength="" maxlength="" type="email" placeholder="Agregar correo Electronico">
                            <input class="form-control cronjob_phome mb-2 d-none" name="cronjob_email_5" id="cronjob_email_5" minlength="" maxlength="" type="email" placeholder="Agregar correo Electronico">
                          </div>
                          <div class="box_addphone" @click="addEmail" v-if="emailsActived < 5"><span>+ Agregar otro correo</span></div>
                        </div>
                        <button type="submit" class="btn base-button btn-primary btn-default my-0">Actualizar Configuración de Correo</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from "axios";
  import { useToast } from "vue-toastification";
  import Notification from "@/components/Notification";
  import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
  import DashboardNavbar from "../Layout/DashboardNavbar";
  export default {
    components: {
      DashboardNavbar,
      RouteBreadCrumb,
    },
    created(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const urlApiParameters = process.env.VUE_APP_API_URL+"/parametro/lista"
      axios.post(urlApiParameters,"",config)
      .then(function (response){
        const data = response.data;
        // Tope de efectivo
        let tope_efectivo = data.filter((item)=>{return item.codigoParametro == "tope_efectivo"})
        $this.tope_efectivo.idParametro = tope_efectivo[0].idParametro
        $this.tope_efectivo.codigoParametro = tope_efectivo[0].codigoParametro
        $this.tope_efectivo.valor = tope_efectivo[0].valor
        // Monto de efectivo para cambio
        let porc_monto_efectivo_change = data.filter((item)=>{return item.codigoParametro == "porc_monto_efectivo_change"})
        $this.porc_monto_efectivo_change.idParametro = porc_monto_efectivo_change[0].idParametro
        $this.porc_monto_efectivo_change.codigoParametro = porc_monto_efectivo_change[0].codigoParametro
        $this.porc_monto_efectivo_change.valor = porc_monto_efectivo_change[0].valor
      })
      let urlApiDistribuidoras = process.env.VUE_APP_API_URL+"/distribuidoras/lista"
      const requestDistribuidoras = axios.get(urlApiDistribuidoras, config);
      axios.all([requestDistribuidoras]).then(axios.spread((...responses) => {
        const responseDistribuidoras = responses[0].data
        $this.distribuidoras = responseDistribuidoras.filter((item)=>{return item.estado == "1"})
        const getFormsLaive = document.querySelectorAll(".form_laive")
        getFormsLaive.forEach(element => {
          element.classList.remove("validateClient")
        });
        return $this.distribuidoras;
      })).catch(errors => {
        // react on errors.
      })
    },
    methods: {
      isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault();;
        } else {
          return true;
        }
      },
      submitUpdatedCronJob(evn){
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        let form = evn.target;
        let tempEmail = [];
        const getAllEmailsDisplay = document.querySelectorAll(".cronjob_phome:not(.d-none)")
        getAllEmailsDisplay.forEach(element => {
          tempEmail.push(element.value)
        });
        this.cronjob.email = tempEmail.join(";");
        form.classList.add("validateClient")
        console.log(this.cronjob);
        let messageSuccess = "Se actualizó la Configuración de Correo"
        let urlApiCronJobUpdated = process.env.VUE_APP_API_URL+"/parametro/cronjob/incidencia/actualizar"
        axios.post(urlApiCronJobUpdated,this.cronjob,config)
        .then(function (response){
          form.classList.remove("validateClient")
          const text = (response.status == 200) ? messageSuccess : "Hubo un error";
          const icon = "ni ni-bell-55";
          const content = {
            component: Notification,
            props: {
              icon: icon,
              text: text,
              type: (response.status == 200) ? "success" : "danger",
            },
          };
          const toast = useToast();
          toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
        })
      },
      changeDistribuidora(evn){
        const $this = this;
        const selected = Number(evn.target.value);
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        const parameters = {
          "idDistribuidora": selected,
        }
        console.log({selected})
        let urlApiCronJob = process.env.VUE_APP_API_URL+"/parametro/cronjob/incidencias"
        axios.post(urlApiCronJob,parameters,config)
        .then(function (response){
          const data = response.data[0];
          $this.cronjob = data
          let index = 0
          console.log({data})
          // Set Emails
          const listemails = $this.cronjob.email.split(";")
          setTimeout(() => {
            const getAllEmailsDisplay = document.querySelectorAll(".cronjob_phome:not(.d-none)")
            getAllEmailsDisplay.forEach(element => {
              element.value = ""
              element.classList.add("d-none")
            });
            listemails.forEach((el, i) => {
              index = i+1
              document.querySelector(`#cronjob_email_${index}`).value = el
              document.querySelector(`#cronjob_email_${index}`).classList.remove("d-none")
            });
            console.log({index});
            $this.emailsActived = index
          }, 200);
        })
      },
      changeInterval(evn){
        const selected = Number(evn.target.value);
        this.cronjob.intervalo = selected
      },
      addEmail(evn){
        if (this.emailsActived < 5) {
          this.emailsActived++
          document.querySelector(`#cronjob_email_${this.emailsActived}`).classList.remove("d-none")
        }
        console.log(this.emailsActived)
      }
    },
    data() {
      return {
        distribuidoras:[],
        emailsActived: 1,
        intervalos:[
          {
            id: 1,
            value: "30m"
          },
          {
            id: 2,
            value: "1h"
          },
          {
            id: 3,
            value: "1h 30m"
          },
          {
            id: 4,
            value: "2h"
          },
          {
            id: 5,
            value: "2h 30m"
          },
          {
            id: 6,
            value: "3h"
          },
          {
            id: 7,
            value: "3h 30m"
          },
          {
            id: 8,
            value: "4h"
          },
        ],
        emails:[],
        tope_efectivo: {
          idParametro: "",
          codigoParametro: "",
          valor: ""
        },
        porc_monto_efectivo_change: {
          idParametro: "",
          codigoParametro: "",
          valor: ""
        },
        cronjob:{
          email: "",
          horario: "",
          asunto: "",
          id: "",
          intervalo: "",
          siguienteEjecucion: "",
          ultimaEjecucion: "",
        }
      };
    },
  };
</script>
<style lang="scss">
  .form_laive{
    .box_addphone{
      font-size: 13px;
      margin-top: -4px;
      span{
        font-weight: 600;
        line-height: 16px;
        font-style: normal;
        position: relative;
        width: auto !important;
        color: #008A1E;
        cursor: pointer;
      }
    }
  }
</style>
