<template>
  <div class="col-9 add-px0-export col-11-export">
    <span class="text-muted font-11">{{ titulo }}</span>
    <div
      class="row mx-0 px-3 rounded add-px0-export"
      :class="{
        'laive-bg-clean-gray': cantidad_productos > 1,
      }"
    >
      <div
        class="col-12 px-0"
        v-for="(producto, index) in grupo?.productos_nombres"
        :key="producto"
      >
        <b
          class="w-100 d-flex py-2 add-py0-export"
          :class="{
            'font-10': cantidad_productos > 1,
            'font-10 border-bottom':
              cantidad_productos > 1 && index < cantidad_productos - 1,
            'font-11': cantidad_productos == 1,
          }"
        >
          {{ producto }}
        </b>
      </div>
    </div>
  </div>
  <div
    class="col-3 d-flex align-items-start flex-column add-px0-export col-1-export"
  >
    <div class="row mx-0 w-100 text-center d-flex justify-content-center">
      <span class="text-muted font-11 change-unit-export">Unidades</span>
    </div>
    <div
      class="row py-2 mx-auto w-100 text-center d-flex justify-content-center add-py0-export"
    >
      <b class="font-11">{{ grupo?.unidades }}</b>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";
import constantes from "../../../model/constantes";
const { grupo, titulo } = defineProps({
  grupo: {
    type: Object,
  },
  titulo: {
    type: String,
  },
});
const cantidad_productos = computed(() => {
  return grupo?.productos?.length ?? 0;
});
</script>
