<script setup>
import { ref, reactive, watch, computed, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useAppToast } from "@/composables";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb.vue";
import DashboardNavbar from "@/views/Layout/DashboardNavbar.vue";
import { CardTable } from "@/components/Cards";
import Pagination from "../../../components/Pagination.vue";
import { Distribuidoras, Solicitudes } from "../../../services";
import { debounce, formatDate } from "../../../util";
import RequestModal from "./RequestModal.vue";
import { REQUEST_TYPE, REQUEST } from "../../../model/equipos";

const toast = useAppToast();
const store = useStore();

const token = computed(() => store.state.auth.token);
const user = computed(() => store.state.auth.user);
const isProvider = computed(() => user.value.rol === 8);

const STATUS_COLORS = {
  0: { bg: "#FFEDDC", text: "#FE7B03" },
  1: { bg: "#CAFDE0", text: "#02A54F" },
  3: { bg: "#FEF0F1", text: "#FF3143" },
};
const OFICINAS = {
  ALL: -1,
  LIMA: 1,
  PROV: 2,
};

const OFICINAS_CODES = {
  LIMA: 1001,
  PROV: 1002,
};

const MODAL_TYPE = {
  RECOJO: "RECOJO",
  NUEVO_EQUIPO: "NUEVO_EQUIPO",
  CAMBIO_QR: "CAMBIO_QR",
  EQUIPO_PERDIDO: "EQUIPO_PERDIDO",
};

const loading = ref(false);
const currentPage = ref(1);
const totalPages = ref(0);
const file = ref(null);

const totalDexs = ref([]);

const filters = reactive({
  codeOrName: "",
  oficinaVentas: {
    selected: OFICINAS.ALL,
    options: [
      {
        text: "Todas (Lima y Provincias)",
        value: OFICINAS.ALL,
      },
      {
        text: "Lima",
        value: OFICINAS.LIMA,
      },
      {
        text: "Provincias",
        value: OFICINAS.PROV,
      },
    ],
  },
  dexs: {
    selected: -1,
    options: [{ text: "Todas", value: -1 }],
  },
});

const tabla = reactive({
  headers: [
    { key: "codigoCliente", label: "Cód Cliente" },
    { key: "cliente", label: "Cliente" },
    { key: "segmento", label: "Segmento" },
    { key: "fechaCrea", label: "Fecha / Hora" },
    { key: "tipoSolicitudDescripcion", label: "Solicitud" },
    { key: "tipoSolicitudReportarDescripcion", label: "Tipo de Solicitud" },
    { key: "direccion", label: "Dirección" },
    { key: "horario", label: "Horario" },
    { key: "distribuidora", label: "DEX" },
    { key: "estado", label: "Estado del pedido" },
    { key: "", label: "" },
  ],
  rows: [],
});

const modal = reactive({
  show: false,
  selectedIdSolicitud: 0,
  request: null,
  requestType: REQUEST_TYPE.DEFAULT,
});

onBeforeMount(async () => {
  try {
    if (!token.value) return;

    loading.value = true;

    const dexsData = await Distribuidoras.getAll(token.value);
    const solicitudesData = await Solicitudes.getByFilters({
      page: currentPage.value,
    });

    const dexs = dexsData
      .filter(({ estado }) => estado == "1")
      .map((dx) => ({
        text:
          dx.razonSocial.charAt(0).toUpperCase() +
          dx.razonSocial.slice(1).toLowerCase(),
        id: dx.idDistribuidora,
        value: dx.idDistribuidora,
      }));

    totalDexs.value = dexs.map((obj) => ({ ...obj }));

    filters.dexs = {
      selected: -1,
      options: [{ text: "Todas", value: -1 }, ...dexs],
    };

    const { data: solicitudes, page, totalPage } = solicitudesData;

    tabla.rows =
      solicitudes.map((soli) => {
        const fechaCrea = formatDate(soli.fechaCrea, "dd/MM/yyyy hh:mm a");

        const horario =
          !!soli.horaInicio && !!soli.horaFin
            ? `${soli.horaInicio} A ${soli.horaFin}`
            : "-";

        return {
          ...soli,
          fechaCrea,
          horario,
        };
      }) ?? [];

    currentPage.value = page;
    totalPages.value = totalPage;
  } catch (error) {
    toast.error("No fue posible obtener la informacion");
  } finally {
    loading.value = false;
  }
});

watch(
  () => filters.oficinaVentas.selected,
  (selectedOf) => {
    let dexs = [...totalDexs.value];
    if (selectedOf == OFICINAS.PROV) {
      dexs = dexs.filter(({ id }) => id > 6);
    } else if (selectedOf === OFICINAS.LIMA) {
      dexs = dexs.filter(({ id }) => id <= 6);
    }
    dexs.push({ text: "Todas", value: -1 });
    dexs.unshift(dexs.pop());

    filters.dexs.options = dexs;
    filters.dexs.selected = -1;

    resetPagination();

    fetchData(selectedOf, filters.dexs.selected, filters.codeOrName);
  }
);
watch(
  () => filters.dexs.selected,
  (selected) => {
    resetPagination();

    fetchData(filters.oficinaVentas.selected, selected, filters.codeOrName);
  }
);

watch(
  () => filters.codeOrName,
  (val) => {
    performSearch(filters.oficinaVentas.selected, filters.dexs.selected, val);
  }
);

const performSearch = debounce(function (oficina, dex, codeOrName) {
  resetPagination();

  fetchData(oficina, dex, codeOrName);
}, 450);

async function fetchData(selectedOficina, seletedDex, codeOrName) {
  try {
    loading.value = true;

    const isCode = !isNaN(codeOrName);

    const codigoOficinaVenta =
      selectedOficina === OFICINAS.LIMA
        ? OFICINAS_CODES.LIMA
        : OFICINAS_CODES.PROV;
    const idDistribuidora = seletedDex === -1 ? 0 : seletedDex;

    const oficina =
      selectedOficina === OFICINAS.ALL ? null : codigoOficinaVenta.toString();

    const filters = {
      page: currentPage.value,
      oficina,
      idDistribuidora,
      codigoCliente: isCode ? codeOrName : null,
      nombreCliente: !isCode ? codeOrName : null,
    };

    const solicitudesData = await Solicitudes.getByFilters(filters);
    const { data: solicitudes, page, totalPage } = solicitudesData;

    tabla.rows = solicitudes.map((soli) => {
      const fechaCrea = formatDate(soli.fechaCrea, "dd/MM/yyyy hh:mm a");

      const horario =
        !!soli.horaInicio && !!soli.horaFin
          ? `${soli.horaInicio} A ${soli.horaFin}`
          : "-";

      return {
        ...soli,
        fechaCrea,
        horario,
      };
    });

    currentPage.value = page;
    totalPages.value = totalPage;
  } catch (error) {
    toast.error("No fue posible obtener la informacion");
  } finally {
    loading.value = false;
  }
}

const handleUpload = async (event) => {
  try {
    if (loading.value) return;

    loading.value = true;

    const files = event.target.files;
    if (files.length === 0) {
      toast.error("Seleccione un archivo");
      return;
    }

    file.value = files[0];

    const typeFile = file.value.name.split(".").pop();

    if (!["xlsx", "xls"].includes(typeFile)) {
      toast.error("Solo se admite archivos de tipo: xlx o xlsx");
      return;
    }

    const formData = new FormData();
    formData.append("file", file.value);

    await Solicitudes.uploadBulk(formData);
    toast.success("Plantilla cargada");

    await fetchData(
      filters.oficinaVentas.selected,
      filters.dexs.selected,
      filters.codeOrName
    );
  } catch (error) {
    const message = error.response.data.message;
    if (!!message) {
      toast.error(message[0]);
    } else {
      toast.error("Hubo un problema al subir el archivo excel");
    }
  } finally {
    loading.value = false;
    event.target.value = null;
    file.value = null;
  }
};

function getStatus(st) {
  if (st == "0") return "Pendiente";
  if (st == "1") return "Atendido";
  return "Rechazado";
}
function getStatusColor(st) {
  return STATUS_COLORS[st];
}

function closeModal() {
  modal.show = false;
  modal.selectedIdSolicitud = 0;
  modal.request = null;
  modal.requestType = REQUEST_TYPE.DEFAULT;
}

const onUpdated = () => {
  fetchData(
    filters.oficinaVentas.selected,
    filters.dexs.selected,
    filters.codeOrName
  );
};

function showModal(i) {
  const data = tabla.rows.at(i);
  const { idSolicitud, tipoSolicitud, tipoSolicitudReportar } = data;

  let request = null;
  let requestType = null;

  switch (tipoSolicitud) {
    case REQUEST.NUEVO_EQUIPO:
      request = REQUEST.NUEVO_EQUIPO;
      break;
    case REQUEST.RECOJO:
      request = REQUEST.RECOJO;
      break;
    case REQUEST.REPORTAR: {
      request = REQUEST.REPORTAR;

      if (tipoSolicitudReportar === REQUEST_TYPE.CAMBIO_QR) {
        requestType = REQUEST_TYPE.CAMBIO_QR;
      } else if (tipoSolicitudReportar === REQUEST_TYPE.EQUIPO_PERDIDO) {
        requestType = REQUEST_TYPE.EQUIPO_PERDIDO;
      } else if (tipoSolicitudReportar === REQUEST_TYPE.EQUIPO_ENCONTRADO) {
        requestType = REQUEST_TYPE.EQUIPO_ENCONTRADO;
      }

      break;
    }
    default:
      break;
  }

  if (request === null) {
    toast.error("Hubo un problema para mostrar el detalle");
    return;
  }

  modal.show = true;
  modal.request = request;
  modal.requestType = requestType;
  modal.selectedIdSolicitud = idSolicitud;
}

async function downloadList() {
  try {
    if (!token.value) return;
    loading.value = true;
    await Solicitudes.downloadList();

    toast.success("Se descargó la lista");
  } catch (error) {
    toast.error("Error en la descarga de la lista");
  } finally {
    loading.value = false;
  }
}

function changePage(page) {
  currentPage.value = page;

  fetchData(
    filters.oficinaVentas.selected,
    filters.dexs.selected,
    filters.codeOrName
  );
}

function resetPagination() {
  currentPage.value = 1;
  totalPages.value = 0;
}

const download = () => {
  setTimeout(() => {
    toast.success("Plantilla descargada");
  }, 500);
};
</script>

<template>
  <header
    aria-label="breadcrumb"
    class="justify-content-between align-items-center d-none d-md-flex"
  >
    <route-bread-crumb></route-bread-crumb>
    <dashboard-navbar></dashboard-navbar>
  </header>
  <div class="card-table-container th-bg-slate py-1 px-3">
    <CardTable
      headerTitle="Solicitudes de activos"
      :showButton="false"
      :loading="loading"
    >
      <template v-if="isProvider" #buttons>
        <div
          class="d-flex justify-content-center align-items-center"
          style="gap: 0.5rem"
        >
          <a
            class="th-btn th-btn-border-pry text-sm align-items-center d-flex flex-row"
            href="/plantillas/carga-masiva-solicitudes-plantilla.xlsx"
            download
            @click="download"
          >
            Descargar plantilla
            <i class="laive-i icon-download ml-2" />
          </a>
          <label
            for="upload-input"
            class="th-btn-pry th-btn-float d-flex align-items-center mb-0"
            style="gap: 0.5rem; cursor: pointer"
          >
            <input
              id="upload-input"
              hidden
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              @change="handleUpload"
            />
            <span>Carga masiva</span>
            <i class="laive-i icon-excel-download" />
          </label>
        </div>
      </template>
      <template #filters>
        <ui-flex dir="column" gap="0">
          <span class="title_filter">OFICINA DE VENTAS</span>
          <ui-select
            type="dropdown"
            :options="filters.oficinaVentas.options"
            v-model:selected="filters.oficinaVentas.selected"
            class="fw-light fs-xs"
            px="-xs"
            py="-xs"
            bg="white"
            text-color="black"
            hv-bg="-gray-hover"
          />
        </ui-flex>
        <ui-flex dir="column" gap="0">
          <span class="title_filter">DEX</span>
          <ui-select
            type="dropdown"
            :options="filters.dexs.options"
            v-model:selected="filters.dexs.selected"
            class="fw-light fs-xs"
            px="-xs"
            py="-xs"
            bg="white"
            text-color="black"
            hv-bg="-gray-hover"
          />
        </ui-flex>
        <ui-flex dir="column" gap="0">
          <span class="title_filter">Buscar</span>
          <ui-input
            v-model="filters.codeOrName"
            class="fs-xs fw-light"
            px="-xs"
            py="-xxs"
            bg="white"
            text-color="black"
            placeholder="Buscar por código o nombre"
          />
        </ui-flex>
      </template>
      <div class="b-table align-start sticky-header scroller">
        <div class="t-header">
          <div class="t-row">
            <div
              class="t-cell justify-content-start"
              v-for="header in tabla.headers"
              :key="header.label"
              :style="{ minWidth: header.minWidth }"
            >
              <span>{{ header.label }}</span>
            </div>
          </div>
        </div>
        <div v-if="tabla.rows.length > 0" class="t-body text-uppercase">
          <div class="t-row" v-for="(row, i) in tabla.rows">
            <div
              class="t-cell justify-content-start"
              v-for="{ key } in tabla.headers.slice(0, -2)"
            >
              {{ row[key] }}
            </div>
            <div class="t-cell justify-content-start">
              <ui-badge
                type="chip"
                :text="getStatus(row['codigoEstado'])"
                :bg="getStatusColor(row['codigoEstado']).bg"
                :text-color="getStatusColor(row['codigoEstado']).text"
                class="text-uppercase text-center"
                fw="600"
                px="-base"
                py="0.4em"
                fs="-xxs"
                w="98%"
              />
            </div>
            <div class="t-cell justify-content-start">
              <button
                class="th-link-pry see-details-btn text-center"
                role="button"
                @click="showModal(i)"
                style="--style: none"
              >
                Ver detalle
              </button>
            </div>
          </div>
        </div>
        <div v-if="tabla.rows.length == 0" class="t-nt-found">
          <img src="/img/shared/search.svg" alt="No encontrado" />
          <p class="text">No hay datos que mostrar</p>
        </div>

        <Pagination
          :currentPage="currentPage"
          :totalPages="totalPages"
          @page-changed="changePage"
        />
      </div>
    </CardTable>
    <ui-flex class="mt-3 d-flex" justify="end">
      <button
        v-if="
          user.rol === 1 ||
          user.rol === 2 ||
          user.rol === 3 ||
          user.rol === 4 ||
          user.rol === 8
        "
        class="app-btn-pry-icon"
        :disabled="loading"
        @click="downloadList"
      >
        Descargar lista
        <i class="laive-i icon-download-white" />
      </button>
    </ui-flex>
  </div>

  <RequestModal
    :show="modal.show"
    :idSolicitud="modal.selectedIdSolicitud"
    :request="modal.request"
    :requestType="modal.requestType"
    @onClose="closeModal"
    @onUpdated="onUpdated"
  />
</template>

<style scoped>
header {
  padding-inline: 1em;
  padding-block: 1.2em;
  padding-bottom: 4.5em;
}

header :deep(nav:last-child) {
  padding: 0 !important;
}

.card-table-container :deep(.card-table) {
  margin-top: -3.5em;
}

@media screen and (max-width: 760px) {
  .card-table-container {
    margin-top: 4.5em;
  }
}

:deep(.card-table-filters .ui-input) {
  flex: 1;
}

.detail > *:nth-last-child(2) {
  justify-content: space-between;
}

.info-detail :is(.ui-select-btn, .ui-input) {
  background-color: white;
  padding-block: 0.3em !important;
  padding-inline: 0.4em !important;
  font-size: 0.75rem;
  color: black !important;
}

.card-table-container :deep(.card-table) {
  margin-top: -3.5em;
}

.card-table-container :deep(.t-body) {
  font-weight: 400;
}

:deep(.card-table-filters .ui-select) {
  flex: 1;
}
.card-table-container :deep(.b-table) {
  --columns: 1.4fr 1.4fr 1fr 1fr 1.4fr 1.4fr 2fr 1.4fr 1fr 1.3fr 1.2fr;
  --columns-sm: 1.2fr 1.4fr 1.2fr 1.2fr 1.2fr 1.2fr 2fr 1fr 1fr 1.3fr 1.2fr;
}
</style>
