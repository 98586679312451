<template>
  <ui-button v-bind="btnProps" :type="type">
    <slot></slot>
  </ui-button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "solid",
      validator(v) {
        return ["solid", "outline"].includes(v);
      }
    },
  },
  computed: {
    btnProps() {
      return {
        class: this.type == "solid" ? "th-btn-float" : "",
        color: "-brand",
        borderW: this.type == "solid" ? "0" : "2px",
        hvTextColor: this.type == "solid" ? "white" : null,
        hvBg: this.type == "solid" ? null : "transparent",
        px: "-lg",
        py: "-xs",
        fw: "-medium",
        radius: "8px"
      }
    }
  }
}
</script>


<style></style>
