<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block only_hidden_after">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive" :class="{ 'validateClient' : isLoading}">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template v-slot:header>
            <div class="row">
              <div class="col-12 col-md-6">
                <h3 class="mb-0">Pedidos no procesados</h3>
              </div>
            </div>
          </template>
          <div>
            <div class="row px-3">
              <div class="col-12 mb-3">
                <div class="row">
                  <div class="col">
                    <template v-if="distribuidoraIdCurrent">
                      <div class="title_filter mr-2"><div>Distribuidora:</div>  <strong>{{distribuidoraNameCurrent}}</strong></div>
                    </template>
                    <template v-else>
                      <span class="title_filter mr-2">Distribuidoras:  </span>
                      <select class="form-control" v-model="dataSend.idDistribuidora" style="display: inline-block;height: 43px;" name="distributor_select" @change="changeListbyDistribuidora">
                        <option value="0">Todos</option>
                        <option
                          v-for="item in distribuidoras"
                          :value="item.idDistribuidora"
                          :key="item.idDistribuidora"
                          >
                          {{ item.razonSocial }}
                        </option>
                      </select>
                    </template>
                  </div>
                  <div class="col">
                    <template v-if="mesaCurrent">
                      <div class="title_filter mr-2"><div>Mesa:</div>  <strong>{{mesaCurrent}}</strong></div>
                    </template>
                    <template v-else>
                      <span class="title_filter mr-2">Mesa:  </span>
                      <select class="form-control" v-model="dataSend.codigomesa" style="display: inline-block;height: 43px;" name="mesa_select" @change="changeListbyMesa">
                        <option value="">Todos</option>
                        <option
                            v-for="item in mesas"
                            :value="item.codigoDato"
                            :key="item.codigoDato"
                            >
                            {{ item.nombre }}
                          </option>
                      </select>
                    </template>
                  </div>
                  <div class="col">
                    <span class="title_filter mr-2">Vendedor:  </span>
                    <select class="form-control" v-model="dataSend.codigoVendedor" id="vendedor_select" style="display: inline-block;height: 43px;" name="vendedor_select" @change="changeListbyVendedor">
                      <option value="">Todos</option>
                      <option
                        v-for="item in vendedores"
                        :value="item.codigoVendedor"
                        :key="item.codigoVendedor+item.distribuidora"
                        >
                        {{ item.nombre }} {{ item.apellidos }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <!-- Real Table -->
            <el-table
              :data="queriedData"
              row-key="idPedidosNoProcesados"
              :empty-text="emptyText"
              header-row-class-name="thead-light"
              style="width: 100%"
            >
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                v-bind="column"
              >
              </el-table-column>
              <el-table-column label="Total" prop="montoTotal" width="140">
                <template v-slot="{ row }">
                  S/{{ formatNumber(row.montoTotal) }}
                </template>
              </el-table-column>
              <el-table-column width="270" align="left">
                <template v-slot:default="props">
                  <div>
                    <base-button type="info" class="detail" size="sm" role="button" @click="openDetail(props.row.idPedido)">Detalle</base-button>
                    <base-button type="info" class="finalizar" size="sm" role="button" @click="initFinishOrder(props.row)">Finalizar</base-button>
                    <base-button type="info" class="delete" size="sm" @click="deleteOrder(props.row.idPedido)">Eliminar</base-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <template v-slot:footer>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category d-none">
                  Mostrando {{ from + 1 }} a {{ to }} de {{ total }} Clientes
                </p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="dataSend.page"
                :per-page="pagination.perPage"
                :total="total"
                @click="changePaginate"
              >
              </base-pagination>
            </div>
          </template>
        </card>
      </div>
    </div>
  </div>
  <CustomModal
    :show="modal.showModal"
    :isHeader="true"
    :isFooter="false"
    :title="modal.titleModal"
    :width="940"
    v-on:modal:close="modal.showModal = $event" >
    <template v-slot:body>
      <template v-if="modal.data.length">
        <table class="table_laive">
          <thead>
            <th>Cod. Producto</th>
            <th>Descripción</th>
            <th>Cantidad</th>
            <th>Precio</th>
            <th>Total</th>
            <th>Bonificación</th>
            <th>Tipo</th>
            <th>Sección</th>
          </thead>
          <tbody>
            <template v-for="detail in modal.data" :key="detail.codigoProducto">
              <tr>
                <td>{{detail.codigoProducto}}</td>
                <td>{{detail.descripcion}}</td>
                <td>{{detail.cantidad}}</td>
                <td>S/{{this.formatNumber(detail.precio)}}</td>
                <td>S/{{this.formatNumber(detail.total)}}</td>
                <td>{{detail.bonificacion}}</td>
                <td>{{detail.tipo}}</td>
                <td>{{detail.seccion}}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </template>
      <template v-else>
        <p class="text-center">No se encontraron resultados</p>
      </template>
    </template>
  </CustomModal>
  <CustomModal
    :show="modalFinish.showModal"
    :isHeader="true"
    :isFooter="false"
    :title="modalFinish.titleModal"
    :width="400"
    v-on:modal:close="modalFinish.showModal = $event" >
    <template v-slot:body>
      <form @submit.prevent="formFinishOrder" class="form_laive" :class="{ 'validateClient' : modalFinish.isLoading}">
        <div class="box_loader">
          <div class="loader" style="margin-top:-40px;"></div>
        </div>
        <div class="row mb-0">
          <div class="col">
            <div class="form-group">
              <label class="form-control-label">Condición de venta:</label>
              <select required v-model="dataSend.codigoCondicionVenta" class="form-control" name="condicion_venta">
                <option selected disabled value="">Todos</option>
                <option
                  v-for="item in condicionventa"
                  :value="item.codigo"
                  :key="item.codigo"
                  >
                  {{ item.nombre }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <base-button type="primary" native-type="submit" class="btn-default my-0">Finalizar pedido</base-button>
      </form>
    </template>
  </CustomModal>
  <CustomModal
    :show="modalSuccess.showModal"
    :isHeader="true"
    :isFooter="false"
    :width="500"
    v-on:modal:close="modalSuccess.showModal = $event" >
    <template v-slot:body>
      <div class="box_icon_pedidos text-center">
        <i :class="`ni text-laive ${modalSuccess.class}`"></i>
      </div>
      <p class="text-center">{{modalSuccess.message}}</p>
      <template v-if="modalSuccess.data.length">
        <table class="table_laive">
          <thead>
            <th>Cod. Producto</th>
            <th>Nombre</th>
          </thead>
          <tbody>
            <template v-for="detail in modalSuccess.data" :key="detail.codigoProducto">
              <tr>
                <td>{{detail.codigoProducto}}</td>
                <td>{{detail.nombre}}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </template>
    </template>
  </CustomModal>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import axios from "axios";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BasePagination from "@/components/BasePagination";
import {mapGetters, mapActions} from 'vuex';
import DashboardNavbar from "../Layout/DashboardNavbar";
import CustomModal from "../Components/CustomModal.vue";
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
export default {
  components: {
    DashboardNavbar,
    BasePagination,
    RouteBreadCrumb,
    CustomModal,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  computed: {
    pagedData() {
      this.getDistributor
      this.getMesas
      this.getVendedor
      this.getCondicionVenta
      this.getDataNoProcesados
      return this.tableData
    },
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString().toLowerCase();
          if (rowValue.includes && rowValue.includes(this.searchQuery.toLowerCase())) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      let firstPagination = document.querySelector("ul.pagination .page-item.number")
      if(this.pagination.total == 1 && firstPagination){
        firstPagination.classList.add("active")
      }
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.pagination.total;
    },
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    }),
    async getDistributor() {
      try{
        let result = await this.getDistributorList();
        this.distribuidoras = result.data ? result.data.filter((item) => { return item.estado == "1" }) : [];
        if(this.distribuidoraIdCurrent){
          let dataDistibuitorCurrent =  this.distribuidoras.filter((item)=>{return item.idDistribuidora == this.distribuidoraIdCurrent})
          this.distribuidoraNameCurrent = dataDistibuitorCurrent[0].razonSocial
        }
      }
      catch(ex){ex}
    },
    async getMesas() {
      if (this.dataSend.idDistribuidora >= 1 && !this.initLoad) {
        console.log("entro mesass")
        let result =  await this.getMesaByDistribuidora(this.dataSend);
        this.mesas = result.data ? result.data.filter((item) => { return item.estado == "Activo" }) : [];
      }else{
        console.info("no hay id de distribuidora para mesas")
      }
    },
    async getVendedor(){
      if(this.supervisorIdCurrent){
        this.dataSend.codigosupervisor = this.supervisorIdCurrent
        this.dataSend.codigomesa = this.mesaCurrent
        let result = await this.getVendedorByMesa(this.dataSend);
        this.vendedores = result.data ? result.data : [];
      }else{
        console.info("no hay id de supervisor")
      }
    },
    async getCondicionVenta() {
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const urlCondicionVenta = process.env.VUE_APP_API_URL+"/pedido/condicionventa";
      await axios.get(urlCondicionVenta,config)
      .then(function (response){
        return $this.condicionventa = response.data
      }).catch(errors => {
        console.error(errors, " error condicion venta");
      })
    },
    async getDataNoProcesados() {
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const urlApiListado = process.env.VUE_APP_API_URL + `/pedido/noprocesado/lista`;
      if(!this.initLoad) return
      await axios.post(urlApiListado,this.dataSend,config)
      .then(function (response){
        console.log(response.data, " ====lista de no procesados");
        $this.emptyText = "No se encontraron resultados"
        $this.pagination.perPage = response.data.data.length
        $this.pagination.total = response.data.totalPage
        $this.isLoading = false
        $this.initLoad = false
        return $this.tableData = response.data.data
      }).catch(errors => {
        $this.initLoad = false
      })
    },
  },
  methods: {
    ...mapActions({
      getDistributorList: 'distribuidora/getList',
      getMesaByDistribuidora: 'mesa/getListByDistribuidora',
      getVendedorByMesa:'vendedor/getListByMesa',
    }),
    changeListbyDistribuidora(evn){
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      this.isLoading = true
      this.dataSend.page = 1
      this.getMesaByDistribuidora(this.dataSend).then((result) => {
        $this.mesas = result.data ? result.data : [];
      });
      this.dataSend.codigomesa = ""
      if (this.dataSend.idDistribuidora == 0) {
        $this.vendedores = []
      } else {
        this.getVendedorByMesa(this.dataSend).then((result) => {
          $this.vendedores = result.data ? result.data : [];
        });
      }
      this.dataSend.codigoVendedor = ""
      this.resetPagination(this.dataSend.page);
      axios.post(process.env.VUE_APP_API_URL+"/pedido/noprocesado/lista",this.dataSend,config)
      .then(function (response){
        console.log(response.data, " ====lista de pedidos no procesados de distribuidora ====");
        $this.pagination.perPage = response.data.data.length
        $this.pagination.total = response.data.totalPage
        $this.initload = false
        $this.isLoading = false
        return $this.tableData = response.data.data
      }).catch(errors => {
        $this.isLoading = false
      })
    },
    changeListbyMesa(env){
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      this.isLoading = true
      this.dataSend.page = 1
      this.getVendedorByMesa(this.dataSend).then((result) => {
        $this.vendedores = result.data ? result.data : [];
      });
      this.resetPagination(this.dataSend.page);
      axios.post(process.env.VUE_APP_API_URL+"/pedido/noprocesado/lista",this.dataSend,config)
      .then(function (response){
        console.log(response.data, " ====lista de no procesados de mesa ===");
        $this.pagination.perPage = response.data.data.length
        $this.pagination.total = response.data.totalPage
        $this.isLoading = false
        return $this.tableData = response.data.data
      }).catch(errors => {
        $this.isLoading = false
      })
    },
    changeListbyVendedor(evn){
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      this.isLoading = true
      this.dataSend.page = 1
      this.resetPagination(this.dataSend.page);
      axios.post(process.env.VUE_APP_API_URL+"/pedido/noprocesado/lista",this.dataSend,config)
        .then(function (response) {
        console.log(response.data, " ====lista de no procesados de vendedor ===");
        $this.pagination.perPage = response.data.data.length
        $this.pagination.total = response.data.totalPage
        $this.isLoading = false
        return $this.tableData = response.data.data
      }).catch(errors => {
        $this.isLoading = false
      })
    },
    changePaginate(evn){
      const $this = this
      const listPaginator = document.querySelectorAll("ul.pagination .page-item")
      let elm = evn.target;
      let sendAjax = false;
      let parentEl = elm.parentNode
      if(elm.classList.contains("prev-page")){
        if(!elm.classList.contains("disabled")){
          let linkActivePrev = document.querySelectorAll("ul.pagination .page-item.active")
          linkActivePrev[0].classList.remove("active")
          linkActivePrev[0].previousSibling.classList.add("active")
          sendAjax = true
        }
      }else if(elm.classList.contains("next-page")){
      }else{
        if(elm.getAttribute("aria-label") == "Previous"){
          let linkActivePrev = document.querySelectorAll("ul.pagination .page-item.active")
          linkActivePrev[0].classList.remove("active")
          linkActivePrev[0].previousSibling.classList.add("active")
          sendAjax = true
        }else if(elm.getAttribute("aria-label") == "Next"){
          let linkActiveNext = document.querySelectorAll("ul.pagination .page-item.active")
            linkActiveNext[0].classList.remove("active")
            linkActiveNext[0].nextSibling.classList.add("active")
            sendAjax = true
        }else{
          if(!parentEl.classList.contains("active")){
            listPaginator.forEach(item => {
              item.classList.remove("active")
            });
            parentEl.classList.add("active")
            sendAjax = true
          }
        }
      }
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      if(sendAjax){
        this.isLoading = true
        axios.post(process.env.VUE_APP_API_URL+"/pedido/noprocesado/lista",this.dataSend,config)
        .then(function (response){
          console.log(response.data.data)
          sendAjax = false
          $this.dataSend.page = response.data.page
          $this.pagination.perPage = response.data.data.length
          $this.pagination.total = response.data.totalPage
          $this.isLoading = false
          return $this.tableData = response.data.data
        })
      }
    },
    openDetail(id) {
      const $this = this
      this.modal.titleModal = `Detalle del pedido: ${id}`
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      this.isLoading = true
      const urlDetail = process.env.VUE_APP_API_URL+"/pedido/noprocesado/detalle/"+id;
      axios.get(urlDetail,config)
      .then(function (response){
        $this.isLoading = false
        $this.modal.showModal = true
        return $this.modal.data = response.data.detalle
      }).catch(errors => {
        $this.isLoading = false
      })
    },
    deleteOrder(id) {
      const $this = this
      const dataID = id
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      this.isLoading = true
      var indexArr = $this.tableData.map(function (el) { return el.idPedido; }).indexOf(dataID);
      const dataDelete = {
        idDistribuidora: $this.tableData[indexArr].idDistribuidora,
        idPedido: dataID
      }
      axios.post(process.env.VUE_APP_API_URL+"/pedido/noprocesado/eliminar/",dataDelete,config)
      .then(function (response){
        $this.isLoading = false
        const data = response.data
        console.log({data})
        $this.tableData.splice(indexArr, 1)
        const text = "Se eliminó el pedido"
        const icon = "ni ni-bell-55";
        const content = {
          component: Notification,
          props: {
            icon: icon,
            text: text,
            type:"success",
          },
        };
        const toast = useToast();
        toast(content, {
          hideProgressBar: true,
          icon: false,
          closeButton: false,
          position: "top-right",
        });
        $this.isLoading = false
      }).catch(errors => {
        $this.isLoading = false
      })
    },
    initFinishOrder(data) {
      const idOrder = data.idPedido
      this.modalFinish.titleModal = "Finalizar pedido " + idOrder
      this.modalFinish.showModal = true
      this.dataSend.idPedido = Number(idOrder);
      this.dataSend.idDistribuidora = Number(data.idDistribuidora);
    },
    formFinishOrder() {
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      $this.modalFinish.isLoading = true
      const urlApiFinishOrder = process.env.VUE_APP_API_URL + `/pedido/noprocesado/finalizar`;
      axios.post(urlApiFinishOrder,this.dataSend,config)
      .then(function (response){
        console.log(response.data, " ==== de pedido finalizado");
        $this.modalFinish.isLoading = false
        const isSuccess = response.data.message;
        const message = response.data.respuesta
        const dataOrder = response.data.productosSinStock
        if (isSuccess == "Error") {
          $this.modalSuccess.class = "ni-danger-exclamation"
          $this.modalSuccess.data = dataOrder
        } else {
          $this.modalSuccess.class = "ni-check-bold"
        }
        $this.modalSuccess.message = "Se finalizó el pedido correctamente"
        $this.modalSuccess.showModal = true
        $this.modalFinish.showModal = false
        $this.dataSend.idDistribuidora = 0
      }).catch(errors => {
        console.error({errors})
        $this.modalFinish.showModal = false
        $this.dataSend.idDistribuidora = 0
      })
    },
    formatNumber(num) {
      var nf = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      return nf.format(num)
	  },
    resetPagination(value){
      const paginate = document.querySelectorAll(".page-item.number")
      let index = value - 1
      paginate.forEach(item => {
        if(item.classList.contains("active")){
          item.classList.remove("active")
        }
      });
      paginate[index].classList.add("active");
    }
  },
  mounted() {
    if(this.user.rol == 3){
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
    if(this.user.rol == 4){
      this.supervisorIdCurrent = this.user.codigo
      this.supervisorNameCurrent = this.user.nombre
      this.distribuidoraIdCurrent = this.user.idDistribuidora
      this.mesaCurrent = this.user.mesa
      this.dataSend.iddistribuidora = this.distribuidoraIdCurrent
    }
  },
  data() {
    return {
      emptyText: "No se encontraron resultados",
      distribuidoraIdCurrent: null,
      distribuidoraNameCurrent: null,
      supervisorIdCurrent: null,
      supervisorNameCurrent: null,
      isLoading: false,
      initLoad: true,
      pagination: {
        perPage: 2,
        currentPage: 1,
        newcurrentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 1,
      },
      condicionventa: [],
      modalSuccess: {
        showModal: false,
        message: "",
        data: []
      },
      modalFinish: {
        titleModal: "",
        isLoading: false,
        showModal: false,
      },
      modal: {
        titleModal: "",
        showModal: false,
        data: []
      },
      dataSend: {
        idDistribuidora: 0,
        codigomesa: "",
        codigoVendedor: "",
        codigosupervisor: "",
        page: 1,
        tipo: "mesas",
        idPedido: "",
        codigoCondicionVenta: ""
      },
      searchQuery: "",
      distribuidoras: [],
      vendedores: [],
      fechas: [],
      mesas: [],
      propsToSearch: ["nombreCliente", "idPedido"],
      tableColumns: [
        {
          prop: "idPedido",
          label: "Código",
          minWidth: 130,
        },
        {
          prop: "distribuidora",
          label: "Distribuidora",
          minWidth: 165,
        },
        {
          prop: "nombreVendedor",
          label: "Vendedor",
          minWidth: 280,
        },
        {
          prop: "nombreCliente",
          label: "Cliente",
          minWidth: 300,
        },
        {
          prop: "numeroProducto",
          label: "Nº Productos",
          minWidth: 170,
        },
        {
          prop: "horaCrea",
          label: "Hora",
          minWidth: 120,
        },
      ],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      expands: [],
    };
  },
};
</script>
