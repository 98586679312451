import { mapActions, mapGetters } from 'vuex';
import ParameterService from '../services/ParameterService';
import axios from 'axios';
export default { 
    namespaced: true,
    state:{},
    getters:{},
    mutations:{},
    methods:{ },
    actions:{
        async getList({state,commit},data) {
            return await ParameterService.getList(axios,data);
        },
        async setNotificacionParameter({state,commit},data){
            return await ParameterService.setNotificacionParameter(axios,data);
        }
    }
}