<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1 pt-0 mt-0"
          footer-classes="pb-2"
        >
          <template v-slot:header>
            <h3 class="mb-0">Creación de maestro</h3>
          </template>
          <div class="card-body">
            <form @submit.prevent="submitEdit" >
              <div class="row">
                <div class="col-md-4">
                  <label class="col-md-12 col-form-label form-control-label">Nombre:</label>
                  <base-input
                    name="name_category"
                    placeholder="Nombre"
                    required="required"
                    :value="dataSend.nombre"
                    v-model="dataSend.nombre"
                  ></base-input>
                </div>
                <div class="col-md-4">
                  <label class="col-md-12 col-form-label form-control-label">Código dato:</label>
                  <base-input
                    name="name_codigoDato"
                    placeholder="Código dato"
                    required="required"
                    :value="dataSend.codigoDato"
                    v-model="dataSend.codigoDato"
                  ></base-input>
                </div>
                <div class="col-md-4">
                  <label class="col-md-12 col-form-label form-control-label">Código Tipo:</label>
                  <base-input
                    name="name_codigoTipo"
                    placeholder="Código tipo"
                    required="required"
                    :value="dataSend.codigoTipo"
                    v-model="dataSend.codigoTipo"
                  ></base-input>
                </div>
              </div>
              <base-button type="primary" native-type="submit" class="btn-default my-0">Crear Maestro</base-button>
            </form>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
export default {
  components: {
    RouteBreadCrumb,
  },
  methods: {
    submitEdit(submitEvent) {
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let updatedMaestro = process.env.VUE_APP_API_URL+"/maestro/crear"
      document.querySelector(".form_laive").classList.add("validateClient")
      this.title = this.dataSend.nombre
      axios.post(updatedMaestro, this.dataSend,config)
      .then(function (response){
        document.querySelector(".form_laive").classList.remove("validateClient")
        const text = (response.status == 200) ? "Se creo el maestro" : "Hubo un error";
        const icon = "ni ni-bell-55";
        const content = {
          component: Notification,
          props: {
            icon: icon,
            text: text,
            type: (response.status == 200) ? "success" : "danger",
          },
        };
        const toast = useToast();
        toast(content, {
          hideProgressBar: true,
          icon: false,
          closeButton: false,
          position: "top-right",
        });
      })
    }
  },
  data() {
    return {
      title : "",
      dataSend: {
        codigoDato: "",
        codigoTipo: "",
        idRegistro: "",
        nombre: "",
        valorAsociado: ""
      }
    };
  },
}
</script>
