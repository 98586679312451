<template>
  <header aria-label="breadcrumb" class="justify-content-between align-items-center d-none d-md-flex" :class="{
    'expanded': currentTab ? currentTab ==
      'pedido-minimo' : false
  }">
    <route-bread-crumb></route-bread-crumb>
    <dashboard-navbar></dashboard-navbar>
  </header>
  <CargaMasiva v-if="currentTab == 'carga'" />
  <PedidoMinimo v-else />
</template>
<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import DashboardNavbar from "../../Layout/DashboardNavbar";
import PedidoMinimo from "./PedidoMinimo.vue";
import CargaMasiva from "./CargaMasiva.vue";
export default {
  components: {
    DashboardNavbar,
    RouteBreadCrumb,
    PedidoMinimo,
    CargaMasiva
  },
  computed: {
    currentTab() {
      return this.$route.query["tab"] ?? "pedido-minimo"
    }
  }
}
</script>
<style scoped>
.expanded {
  padding-bottom: 4.50em;
}

header {
  padding-inline: 1em;
  padding-block: 1.2em;
}

header :deep(nav:last-child) {
  padding: 0 !important;
}
</style>
