<template>
  <div v-if='show' class='custom-modal' ref="modal-container" @mousedown="hideClick" :style="{ zIndex: overLevel }">
    <div class='container' :class="contentClass" :style="{ ...contentStyle, maxWidth: `${width}px` }" v-focus-esc="hide">
      <button class="modal-close-btn" @click="hide" v-if="showCloseIcon">
        <i class="laive-i icon-x-close" />
      </button>
      <div v-if='isHeader == true' class='header'>
        <slot name="header">
          <h3>{{ title }}</h3>
          <i class='fa fa-times' @click='hide()'></i>
        </slot>
      </div>
      <div class='body'>
        <slot name='body'>
        </slot>
      </div>
      <div v-if='isFooter == true' class='footer'>
        <slot name='footer'>
          <a class='btn-cancel'>Cerrar</a>
          <a class='btn-ok'>Aceptar</a>
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    show: false,
    showCloseIcon: {
      type: Boolean,
      default: false
    },
    title: '',
    isHeader: false,
    isFooter: false,
    contentClass: [Object, String],
    contentStyle: {
      type: Object,
      default: {}
    },
    overLevel: {
      type: Number,
      default: 9999,
    },
    width: {
      type: [String, Number],
      default: "auto",
    },
  },
  methods: {
    hideClick(event) {
      // Click en el contenedor para que se cierre el modal
      if (event.target == this.$refs["modal-container"]) {
        this.hide();
      }
    },
    hide() {
      this.$emit('modal:close', false);
    }
  },
  watch: {
    show: function (val) {
      if (val) {
        document.querySelector("body").classList.add("overflow-hidden");
      } else {
        document.querySelector("body").classList.remove("overflow-hidden");
      }
    }
  },
};
</script>
<style lang="scss">
.modal-close-btn {
  z-index: 10;
  position: absolute;
  top: 0.3em;
  right: 0.3em;
  border: 0;
  padding: 0;
  background-color: unset;
  cursor: pointer;
  padding: 0.3em;
  border-radius: 3px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.06)
  }
}

.custom-modal {
  display: flex;
  width: 100%;
  height: 100%;
  background: #00000099;
  position: fixed;
  top: 0px;
  left: 0px;
  overflow-y: auto;

  .container {
    position: relative;
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding: 16px;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px 4px rgba(0, 0, 0, 0.12);
    border-radius: 7px;
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;

    .header {
      display: flex;
      align-items: center;
      padding-bottom: 10px;

      h5 {
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 23px;
        display: flex;
        align-items: center;
        color: #1A1818;
        margin-bottom: 0px;
      }

      i {
        margin-left: auto;
        cursor: pointer;
      }

      i:hover {
        // background: #dadada;
        // padding: 2px 4px;
        border-radius: 20px;
      }
    }

    .body {
      table {
        font-size: 14px;
        color: #545454;
      }
    }

    .footer {
      padding: 10px 0 5px 0px;
      display: flex;

      .btn-cancel {
        background: #f5365c;
        box-shadow: 0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #FFFFFF;
        padding: 12px 18px;
      }

      .btn-ok {
        background: #02A54F;
        box-shadow: 0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #FFFFFF;
        margin-left: auto;
        padding: 12px 18px;
      }
    }
  }
}
</style>
