<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive" :class="{ 'validateClient' : isLoading}">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card"
          body-classes="pb-1"
          footer-classes="pb-2"
        >
          <template v-slot:header>
            <div class="row">
              <div class="col-12 col-md-6">
                <h3 class="mb-0">Crear Método de Pago</h3>
              </div>
            </div>
          </template>
          <div>
            <form @submit.prevent="creteMethod">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label form-control-label">Método de Pago:</label>
                    <input v-model="createMethod.nombre" name="name_method" class="form-control" type="text" placeholder="Método de Pago" required>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label form-control-label">Estado:</label>
                    <select v-model.number="createMethod.estado" class="form-control" name="state_method" id="state_method" required>
                      <option value="" selected disabled style="display:none">Seleccionar</option>
                      <option value="1">Activo</option>
                      <option value="0">Inactivo</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label form-control-label">Número Teléfono:</label>
                    <input v-model="createMethod.numeroTelefono" name="number_method" class="form-control" type="text" placeholder="Método de Pago" required @keypress="isNumber($event)">
                  </div>
                </div>
                <div class="col-md-6">
                  <label class="col-form-label form-control-label">Distribuidoras:</label>
                  <select v-model="createMethod.iddistribuidora" class="form-control" name="distributor_select" required>
                    <option value="" disabled selected>Todos</option>
                    <option
                      v-for="item in distribuidoras"
                      :value="item.idDistribuidora"
                      :key="item.idDistribuidora"
                      >
                      {{ item.razonSocial }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="dropzone_laive">
                    <div class="dropzone-previews image_logo">
                      <label class="col-form-label form-control-label">Logo:</label>
                      <div class="dz-preview-cover dz-image-preview">
                        <img class="dz-preview-img" alt="image" :src="createMethod.previewLogo">
                      </div>
                    </div>
                    <dropzone-file-upload
                      v-model="fileLogo"
                      :options="dropzoneOptions"
                      @addedfile="addFileLogo"
                    ></dropzone-file-upload>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="dropzone_laive">
                    <div class="dropzone-previews image_qr">
                      <label class="col-form-label form-control-label">QR:</label>
                      <div class="dz-preview-cover dz-image-preview">
                        <img class="dz-preview-img" alt="image" :src="createMethod.previewImage">
                      </div>
                    </div>
                    <dropzone-file-upload
                      v-model="fileImage"
                      :options="dropzoneOptions"
                      @addedfile="addFileImage"
                    ></dropzone-file-upload>
                  </div>
                </div>
                <div class="col-md-12 pt-1" style="font-size:12px">*Solo se permiten formatos PNG. El código QR deberá tener una propoción de 1080x1080 (recomendado) y el Logo una propoción de 400x400 (recomendado).</div>
              </div>
              <base-button type="primary" native-type="submit" class="btn-default my-0 mt-3">Actualizar método de pago</base-button>
            </form>

          </div>
          <template v-slot:footer>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {mapGetters, mapActions} from 'vuex';
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import DropzoneFileUpload from "@/components/Inputs/DropzoneFileUpload";
import DashboardNavbar from "./../Layout/DashboardNavbar";
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
export default {
  components: {
    DashboardNavbar,
    RouteBreadCrumb,
    DropzoneFileUpload
  },
  created() {
    this.getDistributor()
  },
  computed:{
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    }),
  },
  methods:{
    ...mapActions({
        getDistributorList: 'distribuidora/getList',
    }),
    async addFileLogo(file) {
      const customContainer = document.querySelector(".dropzone_laive .image_logo");
      const template = (data) => {return `<label class="col-form-label form-control-label">Logo:</label><div class="dz-preview-cover dz-image-preview"><img class="dz-preview-img" data-dz-thumbnail="" alt="vue.jpg" src="${data}"></div>`};
      const reader = new FileReader();
      reader.addEventListener("load", function () {
        customContainer.innerHTML = ""
        customContainer.innerHTML = template(reader.result);
      }, false);
      if (file) {
        const fileSize = file.size / 1024 / 1024;
        if (fileSize <= 1.1) {
          reader.readAsDataURL(file);
          const logoUpload = await this.uploadImage(file)
          console.log({logoUpload})
          if (logoUpload.data.status == 200) {
            this.createMethod.imagenLogo = logoUpload.data.message
            this.isLoading = false
          } else {
            this.isLoading = false
          }
        } else {
          const text = "La imagen no debe ser mayor a 1MB"
          const icon = "ni ni-bell-55";
          const content = {
            component: Notification,
            props: {
              icon: icon,
              text: text,
              type:"danger",
            },
          };
          const toast = useToast();
          toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
          this.isLoading = false
        }
      }
    },
    async addFileImage(file) {
      const customContainer = document.querySelector(".dropzone_laive .image_qr");
      const template = (data) => {return `<label class="col-form-label form-control-label">QR:</label><div class="dz-preview-cover dz-image-preview"><img class="dz-preview-img" data-dz-thumbnail="" alt="imagen.jpg" src="${data}"></div>`};
      const reader = new FileReader();
      if (file) {
        const fileSize = file.size / 1024 / 1024;
        if (fileSize <= 1.1) {
          reader.addEventListener("load", function () {
            customContainer.innerHTML = ""
            customContainer.innerHTML = template(reader.result);
          }, false);
          reader.readAsDataURL(file);
          const imageUpload = await this.uploadImage(file)
          console.log({imageUpload})
          if (imageUpload.data.status == 200) {
            this.createMethod.imagen = imageUpload.data.message
            this.isLoading = false
          } else {
            this.isLoading = false
          }
        } else {
          const text = "La imagen no debe ser mayor a 1MB"
          const icon = "ni ni-bell-55";
          const content = {
            component: Notification,
            props: {
              icon: icon,
              text: text,
              type:"danger",
            },
          };
          const toast = useToast();
          toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
          this.isLoading = false
        }
      }
    },
    async getDistributor(){
      try{
        let result = await this.getDistributorList();
        this.distribuidoras = result.data ? result.data.filter((item) => { return item.estado == "1" }) : [];
        this.isLoading = false
      }
      catch(ex){ex}
    },
    creteMethod() {
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      this.isLoading = true
      console.log(this.createMethod)
      axios.post(process.env.VUE_APP_API_URL+"/distribuidoras/codigoqr/crear", this.createMethod,config)
      .then(function (response){
        $this.isLoading = false
        const dataCreated = response.data
        setTimeout(() => {
          $this.$router.replace({name: "Metodo de pago QR"})
        }, 350);
        console.log({dataCreated})
      }).catch(errors => {
        console.log(errors.response.data)
        const data = errors.response.data
        const text = data.mensaje
        const icon = "ni ni-bell-55";
        const content = {
          component: Notification,
          props: {
            icon: icon,
            text: text,
            type:"danger",
          },
        };
        const toast = useToast();
        toast(content, {
          hideProgressBar: true,
          icon: false,
          closeButton: false,
          position: "top-right",
        });
        $this.isLoading = false
      })
    },
    uploadImage(file) {
      const token = localStorage.getItem("token")
      const config = {
        headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
        }
      };
      this.isLoading = true
      let uploadImage = process.env.VUE_APP_API_URL+"/distribuidoras/codigoqr/cargar-imagen"
      let formData = new FormData();
      formData.append("file", file);
      return axios.post(uploadImage, formData, config)
    },
    formatNumber(num) {
      var nf = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      return nf.format(num)
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
  },
  mounted() {
    if(this.user.rol === 3){
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
    if(this.user.rol === 4){
      this.supervisorIdCurrent = this.user.codigo
      this.supervisorNameCurrent = this.user.nombre
      this.distribuidoraIdCurrent = this.user.idDistribuidora
      this.mesaCurrent = this.user.mesa
    }
  },
  data() {
    return {
      initload: true,
      isLoading: true,
      distribuidoraIdCurrent: null,
      distribuidoraNameCurrent: null,
      supervisorIdCurrent: null,
      supervisorNameCurrent: null,
      mesaCurrent : null,
      createMethod:{
        iddistribuidora: "",
        nombre: "",
        estado: 1,
        numeroTelefono: "",
        imagenLogo: "",
        imagen: "",
        previewLogo: "https://via.placeholder.com/400",
        previewImage: "https://via.placeholder.com/1080",
      },
      distribuidoras:[],
      fileLogo: [],
      fileImage: [],
      dropzoneOptions: {
        paramName: "image_product",
        maxFilesize: .1, // MB
        autoQueue: false,
        previewsContainer: ".dropzone-previews",
        dictDefaultMessage: "Actualizar imagen",
        acceptedFiles: "image/png",
      },
    };
  },
};
</script>
