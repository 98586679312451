<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive validateClient">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
          >
          <template v-slot:header>
            <div class="row">
              <div class="col-12 col-md-6">
                <h3 class="mb-0">Listado de encuestas</h3>
              </div>
            </div>
          </template>
          <div>
            <div class="row px-3">
              <div class="col-12 mb-3">
                <div class="row">
                  <div class="col-12 col-md-3">
                    <span class="title_filter mr-2" style="display:block;">Buscador:  </span>
                    <el-input
                      type="search"
                      clearable
                      prefix-icon="el-icon-search"
                      placeholder="Buscar estudio"
                      v-model="searchQuery"
                      aria-controls="datatables"
                    >
                    </el-input>
                  </div>
                  <div class="col-12 col-md-3">
                    <template v-if="distribuidoraIdCurrent">
                      <span class="title_filter mr-2">Distribuidora:  <strong>{{distribuidoraNameCurrent}}</strong></span>
                    </template>
                    <template v-else>
                      <span class="title_filter mr-2">Distribuidoras:  </span>
                      <select class="form-control" style="display: inline-block;height: 43px;" name="distributor_select" @change="changeDistributor">
                        <option value="0">Todos</option>
                        <option
                          v-for="item in distribuidoras"
                          :value="item.idDistribuidora"
                          :key="item.idDistribuidora"
                          >
                          {{ item.razonSocial }}
                        </option>
                      </select>
                    </template>
                  </div>

                  <div class="col-12 col-md-3">
                    <span class="title_filter mr-2">Estado:  </span>
                    <select class="form-control states_select" style="display: inline-block;height: 43px;" name="states_select" @change="changeStates">
                      <option value="0">Todos</option>
                      <option
                        v-for="item in states"
                        :value="item.codigoDato"
                        :key="item.codigoTipo"
                        >
                        {{ item.nombre }}
                      </option>
                    </select>
                  </div>

                  <div class="col-12 col-md-3">
                    <span class="title_filter mr-2">Tipo de estudio:  </span>
                    <select class="form-control type_study_select" style="display: inline-block;height: 43px;" name="type_study_select" @change="changeStudies">
                      <option value="0">Todos</option>
                      <option
                        v-for="item in studies"
                        :value="item.codigoDato"
                        :key="item.codigoTipo"
                        >
                        {{ item.nombre }}
                      </option>
                    </select>
                  </div>

                </div>
              </div>
              <div class="col-12 col-md-2 d-none">
                <div class="mr-2">
                  <!-- <span class="title_filter">Paginación:  </span> -->
                  <el-select
                    class="select-primary pagination-select"
                    v-model="pagination.perPage"
                    style="width:80px"
                    placeholder="Per page"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <!-- Real Table -->
            <!-- cell-style="padding-left:1rem;padding-right:1rem;" -->
            <el-table
              :data="queriedData"
              row-key="idProducto"
              empty-text="No se encontraron resultados"
              class=""
              header-row-class-name="thead-light"
            >
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                v-bind="column"
              >
              </el-table-column>

              <el-table-column label="Duración" min-width="140" align="left">
                <template v-slot:default="props">
                  <template v-if="props.row.editable == true && user.rol < 3">
                    <base-button type="" class="edit mr-3 text-left" size="sm" role="button" @click="openModalUpdate(props.row.idEstudio)">{{props.row.fechaInicio}} al<br>{{props.row.fechaFin}}</base-button>
                  </template>
                  <template v-else>
                    {{props.row.fechaInicio}} al<br>{{props.row.fechaFin}}
                  </template>
                </template>
              </el-table-column>

              <el-table-column label="Estado" min-width="106px" align="left">
                <template v-slot:default="props">
                  <template v-if="props.row.estado == 'Borrador'">
                    <span class="label-status draft">{{props.row.estado}}</span>
                  </template>
                  <template v-else-if="props.row.estado == 'En curso'">
                    <span class="label-status current">{{props.row.estado}}</span>
                  </template>
                  <template v-else-if="props.row.estado == 'Finalizado'">
                    <span class="label-status finish">{{props.row.estado}}</span>
                  </template>
                  <template v-else-if="props.row.estado == 'Publicado'">
                    <span class="label-status finish">{{props.row.estado}}</span>
                  </template>
                  <template v-else>
                    <span class="label-status finish">{{props.row.estado}}</span>
                  </template>
                </template>
              </el-table-column>

              <el-table-column min-width="190" align="left" class-name="pl-0">
                  <template v-slot:default="props">
                    <base-button v-if="user.rol < 3" type="" class="duplicate mr-3" size="sm" role="button" @click="duplicateEstudio(props.row.idEstudio)">Duplicar</base-button>
                      <template v-if="props.row.estado == 'Borrador' && user.rol < 3">
                        <template v-if="props.row.tipo == 'Encuesta'">
                          <router-link class="btn base-button edit_poll" :to="`reporte-encuestas/editar-encuesta/`+`${props.row.idEstudio}?name=${props.row.nombreEstudio}`">Editar encuesta</router-link>
                        </template>
                        <template v-else>
                          <router-link class="btn base-button edit_poll" :to="`reporte-encuestas/editar-formulario/`+`${props.row.idEstudio}?name=${props.row.nombreEstudio}`">Editar formulario</router-link>
                        </template>
                      </template>
                      <template v-else>
                        <router-link class="btn base-button statistics" :to="`reporte-encuestas/detalle/`+`${props.row.idEstudio}?name=${props.row.nombreEstudio}`">Ver Estadisticas</router-link>
                      </template>
                      <base-button type="info" class="delete" size="sm" role="button" @click="deleteEstudio(props.row.idEstudio)" aria-pressed="true">Eliminar</base-button>
                  </template>
              </el-table-column>
            </el-table>
          </div>
          <template v-slot:footer>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div class="">
                <p class="card-category d-none">
                  Mostrando {{ from + 1 }} a {{ to }} de {{ total }} productos
                </p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.newcurrentPage"
                :per-page="pagination.perPage"
                :total="total"
                @click="changePaginate"
              >
              </base-pagination>
            </div>
          </template>
        </card>
      </div>
    </div>
  </div>
  <CustomModal
      :show="showModal"
      :isHeader="true"
      :isFooter="false"
      title="Actualizar Fecha"
      v-on:modal:close="showModal = $event" >
      <template v-slot:body>
        <form @submit.prevent="submitUpdateDate" class="form_laive formDate">
          <div class="box_loader">
            <div class="loader"></div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label class="col-form-label form-control-label">Fecha de Inicio:</label>
              <base-input addon-left-icon="ni ni-calendar-grid-58" name="fechaInicio">
                <flat-picker :config="configDate" disabled class="form-control datepicker" required v-model="updateDate.fechaInicio"></flat-picker>
              </base-input>
            </div>
            <div class="col-md-12">
              <label class="col-form-label form-control-label">Fecha de Fin:</label>
              <base-input addon-left-icon="ni ni-calendar-grid-58" name="fechaFin">
                <flat-picker :config="configDate" class="form-control datepicker" required v-model="updateDate.fechaFin"></flat-picker>
              </base-input>
            </div>
            <div class="col-md-12">
              <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar</base-button>
            </div>
          </div>
        </form>
      </template>
  </CustomModal>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import axios from "axios";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BasePagination from "@/components/BasePagination";
import {mapGetters} from 'vuex';
import DashboardNavbar from "../Layout/DashboardNavbar";
import CustomModal from "../Components/CustomModal.vue";
import flatPicker from "vue-flatpickr-component";
import {Spanish} from 'flatpickr/dist/l10n/es.js';
import "flatpickr/dist/flatpickr.css";
export default {
  name: "formularioEncuestas",
  components: {
    DashboardNavbar,
    BasePagination,
    RouteBreadCrumb,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    CustomModal,
    flatPicker
  },
  computed: {
    pagedData() {
      this.getDistributor
      this.getStates
      this.getTipoStudies
      this.getEstudios
      if(this.tableData.length){
        this.searchedData = this.tableData
        return this.tableData
        // return this.tableData.slice(this.from, this.to);
      }
    },
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString().toLowerCase();
          if (rowValue.includes && rowValue.includes(this.searchQuery.toLowerCase())) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });
      console.log(result.length);
      this.searchedData = result
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    }),
    getEstudios(){
      const $this = this;
      if(this.distribuidoras.length){
        const selectDistribuodra = ($this.distribuidoraIdCurrent) ? $this.distribuidoraIdCurrent : Number(document.querySelector("[name='distributor_select']").value)
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        const parameters = {
          "iddistribuidora": selectDistribuodra,
          "nombreEstudio": "",
          "tipo": 0,
          "estado": 0,
          "page": "1",
        }
        axios.post(process.env.VUE_APP_API_URL+"/estudio/listar", parameters,config)
        .then(function (response){
          document.querySelector(".form_laive").classList.remove("validateClient")
          $this.pagination.perPage = response.data.data.length
          $this.pagination.total = response.data.totalPage
          return $this.tableData = response.data.data
        }).catch(errors => {
          document.querySelector(".form_laive").classList.remove("validateClient")
        })
      }
    },
    getDistributor(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let urlApiDistribuidoras = process.env.VUE_APP_API_URL+"/distribuidoras/lista"
      const requestDistribuidoras = axios.get(urlApiDistribuidoras, config);
      axios.all([requestDistribuidoras]).then(axios.spread((...responses) => {
        const responseDistribuidoras = responses[0].data
        if($this.distribuidoraIdCurrent){
          let dataDistibuitorCurrent =  responseDistribuidoras.filter((item)=>{return item.idDistribuidora == $this.distribuidoraIdCurrent})
          $this.distribuidoraNameCurrent = dataDistibuitorCurrent[0].razonSocial
        }
        $this.distribuidoras = responseDistribuidoras.filter((item)=>{return item.estado == "1"})
        return $this.distribuidoras
      })).catch(errors => {
        // react on errors.
      })
    },
    getStates(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      axios.get(process.env.VUE_APP_API_URL+"/maestro/lista/estadoestudio",config)
      .then(function (response){
        return $this.states = response.data
      })
    },
    getTipoStudies(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      axios.get(process.env.VUE_APP_API_URL+"/maestro/lista/tipoestudio",config)
      .then(function (response){
        return $this.studies = response.data
      })
    },
  },
  methods: {
    changeDistributor(event){
      const $this = this;
      const select = event.target
      const value = Number(select.value);
      const token = localStorage.getItem("token")
      const selectStates = Number(document.querySelector(".states_select").value)
      const selectStudies = Number(document.querySelector(".type_study_select").value)
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const parameters = {
        "iddistribuidora": value,
        "nombreEstudio": "",
        "tipo": selectStudies,
        "estado": selectStates,
        "page": this.pagination.newcurrentPage,
      }
      document.querySelector(".form_laive").classList.add("validateClient")
      axios.post(process.env.VUE_APP_API_URL+"/estudio/listar", parameters,config)
      .then(function (response){
        document.querySelector(".form_laive").classList.remove("validateClient")
        $this.pagination.perPage = response.data.data.length
        $this.pagination.total = response.data.totalPage
        return $this.tableData = response.data.data
      })
    },
    changeStates(event){
      const $this = this;
      const select = event.target
      const value = Number(select.value);
      const token = localStorage.getItem("token")
      const selectDistribuodra = ($this.distribuidoraIdCurrent) ? $this.distribuidoraIdCurrent : Number(document.querySelector("[name='distributor_select']").value)
      const selectStudies = Number(document.querySelector(".type_study_select").value)
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const parameters = {
        "iddistribuidora": selectDistribuodra,
        "nombreEstudio": "",
        "tipo": selectStudies,
        "estado": value,
        "page": this.pagination.newcurrentPage,
      }
      document.querySelector(".form_laive").classList.add("validateClient")
      axios.post(process.env.VUE_APP_API_URL+"/estudio/listar", parameters,config)
      .then(function (response){
        document.querySelector(".form_laive").classList.remove("validateClient")
        $this.pagination.perPage = response.data.data.length
        $this.pagination.total = response.data.totalPage
        return $this.tableData = response.data.data
      })
    },
    changeStudies(event){
      const $this = this;
      const select = event.target
      const value = Number(select.value);
      const token = localStorage.getItem("token")
      const selectDistribuodra = ($this.distribuidoraIdCurrent) ? $this.distribuidoraIdCurrent : Number(document.querySelector("[name='distributor_select']").value)
      const selectStates = Number(document.querySelector(".states_select").value)
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const parameters = {
        "iddistribuidora": selectDistribuodra,
        "nombreEstudio": "",
        "tipo": value,
        "estado": selectStates,
        "page": this.pagination.newcurrentPage,
      }
      document.querySelector(".form_laive").classList.add("validateClient")
      axios.post(process.env.VUE_APP_API_URL+"/estudio/listar", parameters,config)
      .then(function (response){
        document.querySelector(".form_laive").classList.remove("validateClient")
        $this.pagination.perPage = response.data.data.length
        $this.pagination.total = response.data.totalPage
        return $this.tableData = response.data.data
      }).catch(errors => {
        document.querySelector(".form_laive").classList.remove("validateClient")
        return $this.tableData = []
      })
    },
    changePaginate(evn){
      const $this = this
      const listPaginator = document.querySelectorAll("ul.pagination .page-item")
      const idDistribuidora = (this.distribuidoraIdCurrent) ? this.distribuidoraIdCurrent : Number(document.querySelector("select[name='distributor_select']").value)
      let elm = evn.target;
      let sendAjax = false;
      let parentEl = elm.parentNode
      if(elm.classList.contains("prev-page")){
        if(!elm.classList.contains("disabled")){
          let linkActivePrev = document.querySelectorAll("ul.pagination .page-item.active")
          linkActivePrev[0].classList.remove("active")
          linkActivePrev[0].previousSibling.classList.add("active")
          sendAjax = true
        }
      }else if(elm.classList.contains("next-page")){
      }else{
        if(elm.getAttribute("aria-label") == "Previous"){
          let linkActivePrev = document.querySelectorAll("ul.pagination .page-item.active")
          linkActivePrev[0].classList.remove("active")
          linkActivePrev[0].previousSibling.classList.add("active")
          sendAjax = true
        }else if(elm.getAttribute("aria-label") == "Next"){
          let linkActiveNext = document.querySelectorAll("ul.pagination .page-item.active")
            linkActiveNext[0].classList.remove("active")
            linkActiveNext[0].nextSibling.classList.add("active")
            sendAjax = true
        }else{
          if(!parentEl.classList.contains("active")){
            listPaginator.forEach(item => {
              item.classList.remove("active")
            });
            parentEl.classList.add("active")
            sendAjax = true
          }
        }
      }
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const parameters = {
          "page": this.pagination.newcurrentPage,
          "idDistribuidora": idDistribuidora,
      }
      if(sendAjax){
        document.querySelector(".form_laive").classList.add("validateClient")
        axios.post(process.env.VUE_APP_API_URL+"/producto/especial/lista",parameters,config)
        .then(function (response){
          document.querySelector(".form_laive").classList.remove("validateClient")
          console.log(response.data.data)
          sendAjax = false
          $this.pagination.currentPage = 1
          return $this.tableData = response.data.data
        })
      }
    },
    deleteEstudio(id){
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      document.querySelector(".form_laive").classList.add("validateClient")
      let urlApiDelete =  process.env.VUE_APP_API_URL+`/estudio/eliminar/${id}`
      axios.get(urlApiDelete,config)
      .then(function (response){
        const data = response.data
        console.log({data})
        setTimeout(() => {
          location.reload();
        },200)
      }).catch(errors => {
        document.querySelector(".form_laive").classList.remove("validateClient")
      })
    },
    duplicateEstudio(id){
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      document.querySelector(".form_laive").classList.add("validateClient")
      let urlDuplicate =  process.env.VUE_APP_API_URL+`/estudio/duplicar/${id}`
      axios.get(urlDuplicate,config)
      .then(function (response){
        const data = response.data
        console.log({data})
        setTimeout(() => {
          location.reload();
        },200)
      }).catch(errors => {
        document.querySelector(".form_laive").classList.remove("validateClient")
      })
    },
    openModalUpdate(id){
      document.querySelector(".form_laive").classList.add("validateClient")
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      document.querySelector(".form_laive").classList.add("validateClient")
      let getDetail =  process.env.VUE_APP_API_URL+`/estudio/detalle/${id}`
      axios.get(getDetail,config)
      .then(function (response){
        const data = response.data
        $this.updateDate = data
        document.querySelector(".form_laive").classList.remove("validateClient")
        $this.showModal =true;
      }).catch(errors => {
        document.querySelector(".form_laive").classList.remove("validateClient")
      })
    },
    submitUpdateDate(){
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let updateEstudio = process.env.VUE_APP_API_URL+"/estudio/actualizar/fecha"
      document.querySelector(".formDate").classList.add("validateClient")
      axios.post(updateEstudio, this.updateDate, config)
      .then(function(response){
        document.querySelector(".formDate").classList.remove("validateClient")
        const data = response.data
        setTimeout(() => {
          location.reload();
        },200)
        console.log({data})
      })
    }
  },
  mounted() {
    if(this.user.rol == 3){
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
    if(this.user.rol == 4){
      this.supervisorIdCurrent = this.user.codigo
      this.supervisorNameCurrent = this.user.nombre
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
  },
  data() {
    return {
      showModal: false,
      distribuidoraIdCurrent: null,
      distribuidoraNameCurrent: null,
      supervisorIdCurrent: null,
      supervisorNameCurrent: null,
      pagination: {
        perPage: 25,
        currentPage: 1,
        newcurrentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      distribuidoras: [],
      states: [],
      studies: [],
      searchQuery: "",
      propsToSearch: ["nombreEstudio"],
      tableColumns: [
        {
          prop: "nombreEstudio",
          label: "Nombre de estudio",
          minWidth: 160,
        },
        {
          prop: "tipo",
          label: "Tipo",
          width: 140,
        },
        {
          prop: "distribuidora",
          label: "Distribuidora",
          minWidth: 135,
        },
      ],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      expands: [],
      updateDate:{
        idEstudio: "",
        fechaInicio: "",
        fechaFin: ""
      },
      configDate: {
        altInput: true,
        allowInput: true,
        locale: Spanish, // locale for this instance only
      },
    };
  },
};
</script>
