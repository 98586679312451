import { mapActions, mapGetters } from 'vuex';
import BonificacionUtilService from '../services/BonificacionUtilService';
import axios from 'axios';


export default {
    namespaced: true,
    state: {
        distribuidoras: [],
        productos: [],
        vendedores: [],
        tiposCliente: [],
        segmentos: [],
        girosNegocio: [],
        tiposVendedor: [],
        categorias: [],
        familias: [],
        presentaciones: [],
        mesas: [],
        distribuidoras_clientes: [],
    },
    getters: {},
    mutations: {
        actualizarLista(state, payload) {
            state[payload.lista] = payload.datos;
        },
        actualizarContadorCliente(state, data) {
          const llave = data[0].indice;
          if(state.distribuidoras_clientes.length === 0){
            state.distribuidoras_clientes = data;
          }else{
            state.distribuidoras_clientes = state.distribuidoras_clientes.filter(c => c.indice != llave);
            state.distribuidoras_clientes = [...state.distribuidoras_clientes, ...data];
          }
        },
    },
    methods: {},
    actions: {
        async cargarLista({ state, commit }, lista) {
            let data = await BonificacionUtilService.cargarLista(axios, lista);
            if(lista =='mesas'){
                data = data.result;
            }
            commit("actualizarLista", { datos: data, lista });
        },
        async contarCientes({ state, commit }, payload) {
            const data = await BonificacionUtilService.contarCientes(axios, payload);
            data.forEach(element => {
              element.indice = payload.indice;
            });
            commit("actualizarContadorCliente", data);
        },
        async obtenerCostos({ state, commit }, productoId) {
            return await BonificacionUtilService.obtenerCostos(axios, productoId);
        },

    }
}
