import { mapActions, mapGetters } from 'vuex';
import UserService from '../services/UserService';
import axios from 'axios';
export default { 
    namespaced: true,
    state:{},
    getters:{},
    mutations:{},
    methods:{ },
    actions:{
        async getList({state,commit},data) {
            return await UserService.getList(axios,data);
        },

        async getToken({state,commit},data) {
            return await UserService.getToken(axios,data);
        }
    }
}