<template>
  <div></div>
</template>
<script>
import QrCode from "@keeex/qrcodejs-kx";

export default {
  props: {
    code: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: 200
    }
  },
  data() {
    return {
      qrEl: {}
    }
  },
  mounted() {
    this.qrEl = new QrCode(this.$el, {
      text: this.code,
      width: this.size,
      height: this.size,
    });
  },
  watch: {
    code(code) {
      this.qrEl.clear();
      this.qrEl.qrEl.makeCode(code);
    }
  }
}
</script>
