<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive validateClient">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template v-slot:header>
            <div class="row">
              <div class="col-12 col-md-6">
                <h3 class="mb-0">Reporte Ticket esperado</h3>
              </div>
              <div class="col-12 col-md-6 text-right">
                <button type="button" class="btn base-button btn-primary btn-sm" role="button" @click="exportReport"><i class="fas fa-file-export"></i>Exportar</button>
              </div>
            </div>
          </template>
          <div>
            <div class="row px-4">
              <div class="col-12 col-md-10 mb-3">
                <div class="row">
                  <div class="col-12 col-md-3 ">
                    <template v-if="distribuidoraIdCurrent">
                      <div class="title_filter mr-2"><div>Distribuidora:</div>  <strong>{{distribuidoraNameCurrent}}</strong></div>
                    </template>
                    <template v-else>
                      <span class="title_filter mr-2">Distribuidoras:  </span>
                      <select v-model="dataReport.iddistribuidora" class="form-control" style="display: inline-block;height: 43px;" name="distributor_select" @change="changeListbyDistribuidora">
                        <option value="0">Todos</option>
                        <option
                          v-for="item in distribuidoras"
                          :value="item.idDistribuidora"
                          :key="item.idDistribuidora"
                          >
                          {{ item.razonSocial }}
                        </option>
                      </select>
                    </template>
                  </div>
                  <div class="col-12 col-md-3">
                    <template v-if="supervisorIdCurrent">
                      <span class="title_filter mr-2">Supervisor:<br><strong>{{supervisorNameCurrent}}</strong></span>
                    </template>
                    <template v-else>
                      <span class="title_filter mr-2">Supervisor:  </span>
                      <select v-model="dataReport.codigosupervisor" class="form-control" id="supervisor_select" style="display: inline-block;height: 43px;" name="supervisor_select" @change="changeListbySupverisor">
                        <option value="">Todos</option>
                        <option
                          v-for="item in supervisores"
                          :value="item.codigoSupervisor"
                          :key="item.codigoSupervisor+item.distribuidora"
                          >
                          {{ item.nombre }}
                        </option>
                      </select>
                    </template>
                  </div>
                  <div class="col-12 col-md-3">
                    <span class="title_filter mr-2">Vendedor:  </span>
                    <select v-model="dataReport.codigovendedor" class="form-control" id="vendedor_select" style="display: inline-block;height: 43px;" name="vendedor_select" @change="changeListbyVendedor">
                      <option value="">Todos</option>
                      <option
                        v-for="item in vendedores"
                        :value="item.codigoVendedor"
                        :key="item.codigoVendedor+item.distribuidora"
                        >
                        {{ item.nombre }} {{ item.apellidos }}
                      </option>
                    </select>
                  </div>
                  <div class="col-12 col-md-3">
                    <span class="title_filter mr-2">Mes:  </span>
                    <select v-model="dataReport.month" class="form-control" style="display: inline-block;height: 43px;" name="order_select" @change="changeMonth">
                      <option value="" disabled style="display:none" selected>Seleccionar</option>
                      <option
                          v-for="(item, index) in meses"
                          :value="index+1"
                          :key="item"
                          :selected="index+1 == dataReport.month"
                          >
                          {{ item }}
                        </option>
                    </select>
                  </div>
                </div>
              </div>

            </div>
            <!-- Real Table -->
            <div class="el-table table_report pesos notoverflow">
              <table width="100%" border="0">
                <thead class="el-table__header-wrapper">
                  <th v-for="(item) in tableColumns" :key="item.prop">
                    {{ item.label }}
                  </th>
                  <th></th>
                </thead>
                <tbody>
                  <template v-if="queriedData.length">
                    <tr v-for="value in queriedData" :key="value.ticketEsperado">
                      <td style="width:180px" v-if="value.distribuidora">{{value.distribuidora}}</td><td v-else>---</td>
                      <td style="width:180px" v-if="value.codigoVendedor">{{value.codigoVendedor}}</td><td v-else>---</td>
                      <td style="width:390px" v-if="value.vendedor">{{value.vendedor}}</td><td v-else>---</td>
                      <td v-if="value.ticketEsperado">S/{{formatNumber(value.ticketEsperado)}}</td><td v-else>---</td>
                    </tr>
                  </template>
                </tbody>
              </table>
              <span class="no-result"  v-if="queriedData.length == 0">No se encontraron Resultados</span>
            </div>
          </div>
          <template v-slot:footer>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category d-none">
                  Mostrando {{ from + 1 }} a {{ to }} de {{ total }} Depositos
                </p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="dataReport.page"
                :per-page="pagination.perPage"
                :total="total"
                @click="changePaginate"
              >
              </base-pagination>
            </div>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import axios from "axios";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import TabPane from "@/components/Tabs/TabPane";
import BasePagination from "@/components/BasePagination";
import {mapGetters, mapActions} from 'vuex';
import swal from "sweetalert2";
import DashboardNavbar from "../../Layout/DashboardNavbar";
export default {
  components: {
    DashboardNavbar,
    BasePagination,
    RouteBreadCrumb,
    TabPane,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  computed: {
    pagedData() {
      this.getDistributor
      this.getSupervisor
      this.getVendedor
      this.getDataTicket
      return this.tableData
    },
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString().toLowerCase();
          if (rowValue.includes && rowValue.includes(this.searchQuery.toLowerCase())) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.pagination.total;
    },
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    }),
    async getDistributor(){
      try{
        let result = await this.getDistributorList();
        this.distribuidoras = result.data ? result.data.filter((item) => { return item.estado == "1" }) : [];
        if(this.distribuidoraIdCurrent){
          let dataDistibuitorCurrent =  this.distribuidoras.filter((item)=>{return item.idDistribuidora == this.distribuidoraIdCurrent})
          this.distribuidoraNameCurrent = dataDistibuitorCurrent[0].razonSocial
        }
      }
      catch(ex){ex}
    },
    async getSupervisor(){
      if(this.distribuidoraIdCurrent){
        this.dataReport.iddistribuidora = this.distribuidoraIdCurrent
        let result = await this.getSupervisorList(this.distribuidoraIdCurrent);
        this.supervisores = result.data ? result.data : [];
      }else{
        console.info("no hay id de distribuidora")
      }
    },
    async getVendedor(){
      if(this.supervisorIdCurrent){
        this.dataReport.codigosupervisor = this.supervisorIdCurrent
        let result = await this.getVendedorBySupervisor(this.dataReport);
        this.vendedores = result.data ? result.data : [];
      }else{
        console.info("no hay id de supervisor")
      }
    },
    getDataTicket() {
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      if(this.dataReport.year && this.dataReport.month && this.initload){
        if(this.distribuidoraIdCurrent){
          this.dataReport.iddistribuidora = Number(this.distribuidoraIdCurrent)
        }
        axios.post(process.env.VUE_APP_API_URL+"/reporte/ticketesperado/mes",this.dataReport,config)
        .then(function (response){
          console.log(response.data, " ====lista de reporte");
          document.querySelector(".form_laive").classList.remove("validateClient")
          $this.emptyText = "No se encontraron resultados"
          $this.pagination.perPage = response.data.data.length
          $this.pagination.total = response.data.totalPage
          $this.initload = false
          return $this.tableData = response.data.data
        }).catch(errors => {
          document.querySelector(".form_laive").classList.remove("validateClient")
        })
      }
    },
  },
  methods:{
    ...mapActions({
        getDistributorList: 'distribuidora/getList',
        getSupervisorList: 'supervisor/getListById',
        getVendedorBySupervisor:'vendedor/getListByDistribuidoraSupervisor',
    }),
    changeListbyDistribuidora(evn){
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      document.querySelector(".form_laive").classList.add("validateClient")
      this.dataReport.page = 1
      this.getSupervisorList(this.dataReport.iddistribuidora).then((result) => {
        $this.supervisores = result.data ? result.data : [];
      });
      this.getVendedorBySupervisor(this.dataReport).then((result) => {
        console.log({result})
        $this.vendedores = result.data ? result.data : [];
      });
      this.resetPagination(this.dataReport.page);
      axios.post(process.env.VUE_APP_API_URL+"/reporte/ticketesperado/mes",this.dataReport,config)
      .then(function (response){
        console.log(response.data, " ====lista de reporte");
        document.querySelector(".form_laive").classList.remove("validateClient")
        $this.emptyText = "No se encontraron resultados"
        $this.pagination.perPage = response.data.data.length
        $this.pagination.total = response.data.totalPage
        $this.initload = false
        return $this.tableData = response.data.data
      }).catch(errors => {
        document.querySelector(".form_laive").classList.remove("validateClient")
      })
    },
    changeListbySupverisor(env){
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      document.querySelector(".form_laive").classList.add("validateClient")
      this.dataReport.page = 1
      this.getVendedorBySupervisor(this.dataReport).then((result) => {
        $this.vendedores = result.data ? result.data : [];
      });
      this.resetPagination(this.dataReport.page);
      axios.post(process.env.VUE_APP_API_URL+"/reporte/ticketesperado/mes",this.dataReport,config)
      .then(function (response){
        console.log(response.data, " ====lista de reporte");
        document.querySelector(".form_laive").classList.remove("validateClient")
        $this.emptyText = "No se encontraron resultados"
        $this.pagination.perPage = response.data.data.length
        $this.pagination.total = response.data.totalPage
        $this.initload = false
        return $this.tableData = response.data.data
      }).catch(errors => {
        document.querySelector(".form_laive").classList.remove("validateClient")
      })
    },
    changeListbyVendedor(){
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      document.querySelector(".form_laive").classList.add("validateClient")
      this.dataReport.page = 1
      this.resetPagination(this.dataReport.page);
      axios.post(process.env.VUE_APP_API_URL+"/reporte/ticketesperado/mes",this.dataReport,config)
      .then(function (response){
        console.log(response.data, " ====lista de reporte");
        document.querySelector(".form_laive").classList.remove("validateClient")
        $this.emptyText = "No se encontraron resultados"
        $this.pagination.perPage = response.data.data.length
        $this.pagination.total = response.data.totalPage
        $this.initload = false
        return $this.tableData = response.data.data
      }).catch(errors => {
        document.querySelector(".form_laive").classList.remove("validateClient")
      })
    },
    changeMonth(evn){
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      document.querySelector(".form_laive").classList.add("validateClient")
      this.dataReport.page = 1
      this.resetPagination(this.dataReport.page);
      axios.post(process.env.VUE_APP_API_URL+"/reporte/ticketesperado/mes",this.dataReport,config)
      .then(function (response){
        console.log(response.data, " ====lista de reporte");
        document.querySelector(".form_laive").classList.remove("validateClient")
        $this.emptyText = "No se encontraron resultados"
        $this.pagination.perPage = response.data.data.length
        $this.pagination.total = response.data.totalPage
        $this.initload = false
        return $this.tableData = response.data.data
      }).catch(errors => {
        document.querySelector(".form_laive").classList.remove("validateClient")
      })
    },
    changePaginate(evn){
      const $this = this
      const listPaginator = document.querySelectorAll("ul.pagination .page-item")
      let elm = evn.target;
      let sendAjax = false;
      let parentEl = elm.parentNode
      if(elm.classList.contains("prev-page")){
        if(!elm.classList.contains("disabled")){
          let linkActivePrev = document.querySelectorAll("ul.pagination .page-item.active")
          linkActivePrev[0].classList.remove("active")
          linkActivePrev[0].previousSibling.classList.add("active")
          sendAjax = true
        }
      }else if(elm.classList.contains("next-page")){
      }else{
        if(elm.getAttribute("aria-label") == "Previous"){
          let linkActivePrev = document.querySelectorAll("ul.pagination .page-item.active")
          linkActivePrev[0].classList.remove("active")
          linkActivePrev[0].previousSibling.classList.add("active")
          sendAjax = true
        }else if(elm.getAttribute("aria-label") == "Next"){
          let linkActiveNext = document.querySelectorAll("ul.pagination .page-item.active")
            linkActiveNext[0].classList.remove("active")
            linkActiveNext[0].nextSibling.classList.add("active")
            sendAjax = true
        }else{
          if(!parentEl.classList.contains("active")){
            listPaginator.forEach(item => {
              item.classList.remove("active")
            });
            parentEl.classList.add("active")
            sendAjax = true
          }
        }
      }
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      if(sendAjax){
        document.querySelector(".form_laive").classList.add("validateClient")
        axios.post(process.env.VUE_APP_API_URL+"/reporte/ticketesperado/mes",this.dataReport,config)
        .then(function (response){
          document.querySelector(".form_laive").classList.remove("validateClient")
          console.log(response.data.data)
          sendAjax = false
          $this.dataReport.page = response.data.page
          $this.pagination.perPage = response.data.data.length
          $this.pagination.total = response.data.totalPage
          return $this.tableData = response.data.data
        })
      }
    },
    exportReport(){
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
        }
      };
      const urlDomain = process.env.VUE_APP_API_URL.replace("api", "")
      document.querySelector(".form_laive").classList.add("validateClient")
      axios.post(process.env.VUE_APP_API_URL+"/reporte/ticketesperado/mes/export",this.dataReport,config)
      .then(function (response){
        document.querySelector(".form_laive").classList.remove("validateClient")
        const urlFile = urlDomain + response.data.fileName
        const link = document.createElement('a');
        link.href = urlFile;
        document.body.appendChild(link);
        link.click();
      })
    },
    formatNumber(num) {
      var nf = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      return nf.format(num)
		  // return new Intl.NumberFormat("en-IN").format(num)
	  },
    resetPagination(value){
      const paginate = document.querySelectorAll(".page-item.number")
      let index = value - 1
      paginate.forEach(item => {
        if(item.classList.contains("active")){
          item.classList.remove("active")
        }
      });
      paginate[index].classList.add("active");
    }
  },
  mounted() {
    if(this.user.rol == 3){
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
    if(this.user.rol == 4){
      this.supervisorIdCurrent = this.user.codigo
      this.supervisorNameCurrent = this.user.nombre
      this.distribuidoraIdCurrent = this.user.idDistribuidora
      this.mesaCurrent = this.user.mesa
    }
    let currentDate = new Date();
    let month = currentDate.getMonth() + 1
    this.dataReport.year = currentDate.getFullYear()
    this.dataReport.month = month
  },
  data() {
    return {
      initload: true,
      distribuidoraIdCurrent: null,
      distribuidoraNameCurrent: null,
      supervisorIdCurrent: null,
      supervisorNameCurrent: null,
      mesaCurrent : null,
      emptyText: "Debe Selecionar una distribuidora",
      pagination: {
        perPage: 2,
        currentPage: 1,
        newcurrentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 1,
      },
      dataReport:{
        iddistribuidora: 0,       // Obligatorio
        codigosupervisor: "",     // Opcional
        codigovendedor: "", // Obligatorio
        year: null,               // Obligatorio
        month: null,                 // Obligatorio
        page : 1,
      },
      distribuidoras:[],
      supervisores:[],
      vendedores:[],
      mesas:[],
      searchQuery: "",
      propsToSearch: ["cliente", "codigo"],
      tableColumns: [
        {
          prop: "distribuidora",
          label: "Distribuidora",
        },
        {
          prop: "codigo",
          label: "Cod. Vendedor",
        },
        {
          prop: "vendedor",
          label: "Vendedor",
        },
        {
          prop: "ticketEsperado",
          label: "Ticket esperado",
        },
      ],
      meses:["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio",
            "Agosto","Setiembre","Octubre","Noviembre","Diciembre"],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      expands: [],
    };
  },
};
</script>
<style lang="scss">
  .swal-wide{
    &.cobromes{
     .swal2-popup{
        width: 70em;
      }
    }
  }
  .page-item .page-link .fa{
    z-index: 0;
  }
</style>
