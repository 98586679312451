<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive validateClient card p-4 mb-0">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <h2 class="mb-4">Creación de Producto:</h2>
        <form @submit.prevent="submitCreate">
          <h3 class="d-flex align-items-center">
            Jerarquía del producto
          </h3>
          <div class="form-group row mb-0">
            <div class="col-md-3">
              <label class="col-form-label form-control-label">Categoría:</label>
              <select class="form-control" name="category_select" required @change="changeSelect"
                v-model="product.categoria">
                <option value="" disabled>Seleccionar</option>
                <option v-for="category in categorias" :value="category.nombre" :dataID="category.codigo"
                  :key="category.codigo">
                  {{ category.nombre }}
                </option>
              </select>
            </div>
            <div class="col-md-3">
              <label class="col-form-label form-control-label">Familia:</label>
              <select class="form-control" name="family_select" required @change="changeSelect" v-model="product.familia">
                <option value="" disabled>Seleccionar</option>
                <option v-for="family in familias" :value="family.nombre" :dataID="family.codigo" :key="family.codigo">
                  {{ family.nombre }}
                </option>
              </select>
            </div>
            <div class="col-md-3">
              <label class="col-form-label form-control-label">Marca:</label>
              <select class="form-control" name="brand_select" required @change="changeSelect" v-model="product.marca">
                <option value="" disabled style="display:none">Seleccionar</option>
                <option v-for="marca in marcas" :value="marca.nombre" :dataID="marca.codigo" :key="marca.codigo">
                  {{ marca.nombre }}
                </option>
              </select>
            </div>
            <div class="col-md-3">
              <label class="col-form-label form-control-label">Presentacion:</label>
              <select class="form-control" name="present_select" required @change="changeSelect"
                v-model="product.presentacion">
                <option value="" disabled style="display:none">Seleccionar</option>
                <option v-for="present in presentaciones" :value="present.nombre" :dataID="present.codigo"
                  :key="present.codigo">
                  {{ present.nombre }}
                </option>
              </select>
            </div>
          </div>
          <hr>
          <h3 class="d-flex align-items-center">
            Descripción del producto
          </h3>
          <div class="form-group row mb-0">
            <div class="col-md-3">
              <label class="col-form-label form-control-label">Codigo SAP</label>
              <base-input required="required" name="code_fabric" placeholder="Codigo de Fábrica"
                :value="product.codigoFabrica" v-model="product.codigoFabrica"></base-input>
            </div>
            <div class="col-md-3">
              <label class="col-form-label form-control-label">Codigo SUNAT</label>
              <base-input required="required" name="code_sunat" placeholder="Codigo de Fábrica"
                :value="product.codigoSunat" v-model="product.codigoSunat"></base-input>
            </div>
            <div class="col-md-3">
              <label class="col-form-label form-control-label">Codigo de UNIFLEX</label>
              <base-input required="required" name="code_product" placeholder="Codigo de Producto"
                :value="product.codigoProducto" v-model="product.codigoProducto"></base-input>
            </div>
            <div class="col-md-3">
              <label class="col-form-label form-control-label">Descripción del Producto</label>
              <base-input name="description" required="required" placeholder="Descripción" :value="product.descripcion"
                v-model="product.descripcion"></base-input>
            </div>
            <div class="col-md-3">
              <label class="col-form-label form-control-label">Descripción Comercial:</label>
              <base-input name="description_comercial" required="required" placeholder="Descripción Comercial"
                :value="product.descripcionComercial" v-model="product.descripcionComercial"></base-input>
            </div>
            <div class="col-md-3">
              <label class="col-form-label form-control-label">Tipo:</label>
              <select class="form-control" name="type_select" required v-model="product.codigoTipoProducto">
                <option value="" disabled style="display:none">Seleccionar</option>
                <option v-for="tipo in tipos" :value="tipo.codigo" :dataID="tipo.codigo" :key="tipo.codigo">
                  {{ tipo.nombre }}
                </option>
              </select>
            </div>
            <div class="col-md-3">
              <label class="col-form-label form-control-label mb-4"> </label>
              <base-checkbox v-model="product.peso"><strong>Precio por peso</strong></base-checkbox>
            </div>
            <div class="col-md-3">
              <label class="col-form-label form-control-label">Unidad Base + Peso:</label>
              <div class="row">
                <div class="col-md-6">
                  <select class="form-control" name="unidadbase_select" required v-model="product.codigoUnidadBase">
                    <option value="" disabled style="display:none">Seleccionar</option>
                    <option v-for="unidad in unidades" :value="unidad.codigo" :dataID="unidad.codigo"
                      :key="unidad.codigo">
                      {{ unidad.nombre }}
                    </option>
                  </select>
                </div>
                <div class="col-md-6">
                  <base-input name="unit_base_peso" required="required" placeholder="Peso" :value="product.unidadBasePeso"
                    v-model.number="product.unidadBasePeso" @keypress="isNumber($event)"></base-input>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <label class="col-form-label form-control-label">Unidad Referencia + Peso:</label>
              <div class="row">
                <div class="col-md-6">
                  <select class="form-control" name="unidadreferencia_select" required
                    v-model="product.codigoUnidadReferencia">
                    <option value="" disabled style="display:none">Seleccionar</option>
                    <option v-for="unidad in unidades" :value="unidad.codigo" :dataID="unidad.codigo"
                      :key="unidad.codigo">
                      {{ unidad.nombre }}
                    </option>
                  </select>
                </div>
                <div class="col-md-6">
                  <base-input name="unit_reference_peso" required="required" placeholder="Peso"
                    :value="product.unidadReferenciaPeso" v-model.number="product.unidadReferenciaPeso"
                    @keypress="isNumber($event)"></base-input>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <label class="col-form-label form-control-label">Unidad Compra + Peso:</label>
              <div class="row">
                <div class="col-md-6">
                  <select class="form-control" name="unidadcompra_select" required v-model="product.codigoUnidadCompra">
                    <option value="" disabled style="display:none">Seleccionar</option>
                    <option v-for="unidad in unidades" :value="unidad.codigo" :dataID="unidad.codigo"
                      :key="unidad.codigo">
                      {{ unidad.nombre }}
                    </option>
                  </select>
                </div>
                <div class="col-md-6">
                  <base-input name="unit_buy_peso" required="required" placeholder="Peso"
                    :value="product.unidadCompraPeso" v-model.number="product.unidadCompraPeso"
                    @keypress="isNumber($event)"></base-input>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <label class="col-form-label form-control-label mb-4"> </label>
              <base-checkbox v-model="product.sujetopercepcion"><strong>Sujeto a percepción</strong></base-checkbox>
            </div>
            <div :class="product.sujetopercepcion ? 'col-md-3' : 'col-md-3 d-none'">
              <label class="col-form-label form-control-label">Porcentaje sujeto a percepción</label>
              <base-input name="sujato_percepcion" :required="product.sujetopercepcion" placeholder="Sujeto a percepción"
                :value="product.porcentajePercepcion" v-model.number="product.porcentajePercepcion"
                @keypress="isNumber($event)"></base-input>
            </div>
          </div>
          <div class="form-group row mb-0">
            <div class="dropzone-previews col-md-12" id="product_prewview">
              <label class="col-form-label form-control-label">Imagen:</label>
              <div class="dz-preview-cover dz-image-preview">
                <template v-if="product.imagen.length > 0">
                  <img class="dz-preview-img" alt="image" :src="product.imagen">
                </template>
                <template v-else>
                  <img class="dz-preview-img" alt="image" src="https://via.placeholder.com/200x200">
                </template>
              </div>
            </div>
            <div class="col-md-3">
              <dropzone-file-upload v-model="fileSingle" :options="dropzoneOptions"
                @thumbnail="thumbComplete"></dropzone-file-upload>
            </div>
          </div>
          <base-button type="primary" native-type="submit" class="btn-default my-0">Crear producto</base-button>
        </form>
        <div class="col-md-4" style="display:none">
          <label class="col-form-label form-control-label">Distribuidoras:</label>
          <el-select multiple v-model="product.distribuidora" name="distribuidoras" placeholder="Elige una opción">
            <el-option v-for="option in distribuidoras" :value="option.idDistribuidora" :label="option.razonSocial"
              :key="option.idDistribuidora">
            </el-option>
          </el-select>
        </div>
        <div class="col-md-4" style="display:none">
          <label class="col-form-label form-control-label">Grupos de Venta:</label>
          <el-select multiple v-model="product.grupoventa" name="distribuidoras" placeholder="Elige una opción">
            <el-option v-for="option in gruposventas" :value="option.idGrupoVenta" :label="option.descripcion"
              :key="option.idGrupoVenta">
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import DropzoneFileUpload from "@/components/Inputs/DropzoneFileUpload";
import { ElSelect, ElOption } from "element-plus";
import { Form } from "vee-validate";
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
import TabsLayout from "@/components/Tabs/TabsLayout";
import Tabs from "@/components/Tabs/Tabs";
import Tab from "@/components/Tabs/Tab";
import TabPane from "@/components/Tabs/TabPane";
export default {
  components: {
    RouteBreadCrumb,
    DropzoneFileUpload,
    Form,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    TabsLayout,
    Tabs,
    Tab,
    TabPane,
  },
  created() {
    const zona = Number(this.$route.query.zona);
    if (isNaN(zona) || zona < 0 || zona > 2) return this.$router.replace({ name: "Accesos Directos" })
    this.product.zonaProducto = zona;
    const $this = this;
    const token = localStorage.getItem("token")
    const config = {
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    };
    let urlApiMaestros = process.env.VUE_APP_API_URL + "/producto/maestros"
    let urlApiDistribuidoras = process.env.VUE_APP_API_URL + "/distribuidoras/lista"
    const requestMaestros = axios.get(urlApiMaestros, config);
    axios.all([requestMaestros]).then(axios.spread((...responses) => {
      const responseMaoestros = responses[0].data
      // Set data Maestros
      $this.marcas = responseMaoestros.marcas
      $this.presentaciones = responseMaoestros.presentaciones
      $this.categorias = responseMaoestros.categorias
      $this.marcas = responseMaoestros.marcas
      $this.presentaciones = responseMaoestros.presentaciones
      $this.categorias = responseMaoestros.categorias
      $this.tipos = responseMaoestros.tipoProductos
      $this.unidades = responseMaoestros.unidades
      document.querySelector(".form_laive").classList.remove("validateClient")
    })).catch(errors => {
      // react on errors.
    })
  },
  methods: {
    isNumber: function (evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    changeSelect(evn) {
      const select = evn.target
      const value = select.value;
      const id = select.options[select.selectedIndex].getAttribute("dataID")
      if (select.name == "category_select") {
        this.product.codigocategoria = id
        this.product.codigoFamilia = ""
        this.product.familia = ""
        this.getCategory(id)
      } else if (select.name == "family_select") {
        this.product.codigoFamilia = id
      } else if (select.name == "brand_select") {
        this.product.codigoMarca = id
      } else if (select.name == "present_select") {
        this.product.codigopresentacion = id
      }
    },
    getCategory(id) {
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let getFamilias = process.env.VUE_APP_API_URL + "/producto/familia/" + id
      document.querySelector(".form_laive").classList.add("validateClient")
      axios.get(getFamilias, config)
        .then(function (response) {
          document.querySelector(".form_laive").classList.remove("validateClient")
          $this.familias = response.data
        })
    },
    async submitCreate() {
      document.querySelector(".form_laive").classList.add("validateClient")
      if (this.uploadFile) {
        const finishUpload = await this.uploadImage();
        if (finishUpload.data.status == 200) {
          this.product.imagen = finishUpload.data.message
          this.createProduct();
        } else {
          document.querySelector(".form_laive").classList.remove("validateClient")
        }
      } else {
        this.createProduct();
      }
    },
    createProduct() {
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let createProduct = process.env.VUE_APP_API_URL + "/producto/crear"
      const icon = "ni ni-bell-55";
      axios.post(createProduct, this.product, config)
        .then(function (response) {
          document.querySelector(".form_laive").classList.remove("validateClient")
          const text = (response.status == 200) ? "Se ha creado el producto" : "Hubo un error";
          const content = {
            component: Notification,
            props: {
              icon: icon,
              text: text,
              type: (response.status == 200) ? "success" : "danger",
            },
          };
          const toast = useToast();
          toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
          setTimeout(() => {
            $this.$router.replace({ name: 'Maestro Productos' })
          }, 3000);
        }).catch(errors => {
          let message = errors.response.data.message
          const contentError = {
            component: Notification,
            props: {
              icon: icon,
              text: message,
              type: "danger",
            },
          };
          const toast = useToast();
          toast(contentError, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
          document.querySelector(".form_laive").classList.remove("validateClient")
        })
    },
    uploadImage() {
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let uploadImage = process.env.VUE_APP_API_URL + "/producto/cargar-imagen"
      let formData = new FormData();
      formData.append("file", this.fileSingle);
      formData.append("codigofabrica", this.product.codigoFabrica)
      return axios.post(uploadImage, formData, config)
    },
    thumbComplete(file) {
      var idxDot = file.name.lastIndexOf(".") + 1;
      var extFile = file.name.substr(idxDot, file.name.length).toLowerCase();
      if (extFile !== 'png') {
        const content = {
          component: Notification,
          props: {
            icon: 'ni ni-bell-55',
            text: 'El formato válido es PNG',
            type: "danger",
          },
        };
        const toast = useToast();
        toast(content, {
          hideProgressBar: true,
          icon: false,
          closeButton: false,
          position: "top-right",
        });
      } else {
        const customContainer = document.querySelector("#product_prewview");
        const template = (data) => { return `<div class="dz-preview-cover dz-image-preview"><img class="dz-preview-img" data-dz-thumbnail="" alt="vue.jpg" src="${data}"></div>` };
        customContainer.innerHTML = template(file.dataURL);
        this.product.imagen = file.name;
        this.fileSingle = file
        this.uploadFile = true
      }
    }
  },
  data() {
    return {
      dropzoneOptions: {
        paramName: "image_product",
        maxFilesize: .1, // MB
        autoQueue: false,
        previewsContainer: ".dropzone-previews",
        dictDefaultMessage: "Cargar imagen de producto",
      },
      product: {
        idProducto: null,
        codigoProducto: "",
        codigoFabrica: "",
        codigoSunat: "",
        descripcion: "",
        descripcionComercial: "",
        unidadBasePeso: "",
        unidadReferenciaPeso: "",
        codigoUnidadReferencia: "",
        unidadCompraPeso: "",
        codigoUnidadCompra: "",
        categoria: "",
        familia: "",
        presentacion: "",
        marca: "",
        codigoTipoProducto: "",
        codigoUnidadBase: "",
        zonaProducto: "",
        imagen: "", // https://via.placeholder.com/200x200
        peso: false,
        sujetopercepcion: false,
        porcentajePercepcion: 0,
      },
      gruposventas: [
        {
          "codigoGrupoVenta": "01",
          "descripcion": "VERDE",
          "idGrupoVenta": 1
        },
        {
          "codigoGrupoVenta": "02",
          "descripcion": "ROJO",
          "idGrupoVenta": 2
        },
        {
          "codigoGrupoVenta": "03",
          "descripcion": "AZUL",
          "idGrupoVenta": 3
        }
      ],
      distribuidoras: [],
      fileSingle: [],
      uploadFile: false,
      familias: [],
      presentaciones: [],
      marcas: [],
      categorias: [],
      tipos: [],
      unidades: [],
    };
  },
}
</script>
<style lang="scss">
.dropzone {
  width: 200px;
}

.dz-message {
  padding: 1rem 1rem !important;
}

.dropzone-single.dz-max-files-reached .dz-message {
  background-color: rgba(219, 219, 219, 0.349) !important;
  border: 1px dashed #dee2e6 !important;
  opacity: 1 !important;
}

#product_prewview {
  .dz-preview-cover {
    position: relative;
    width: 200px;
    padding-bottom: 0.5rem;

    &.dz-image-preview {
      &.dz-error {
        display: none;
      }

      &:nth-child(3) {
        display: none;
      }
    }
  }
}

.form_laive {
  form {
    .ni {
      top: -1px;
      position: relative;
    }
  }
}
</style>
