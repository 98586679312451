
import setting from './setting';

const API_URL = setting.http.base_url;

export default {
    cargarLista: async ($axios) => {
        try {
            let data = [];
            data = (await $axios.get(`${API_URL}/Usuario/listaTrade`, {headers:setting.http.headers()})).data;
           
            return data;
        } catch (err) {
            console.log(err);
        }
    },
    asociarDistribuidoras: async ($axios, payload) => {
        try {
            let data = null;
            data = (await $axios.post(`${API_URL}/Usuario/asociarDistribuidoras`, payload, {headers:setting.http.headers()})).data;                    
            return data;
        } catch (err) {
            console.log(err);
        }
    },
}
