<template>
  <div :class="['ui-split']" role="separator" :aria-orientation="direction" :style="[cssProps]" />
</template>

<script>
import { PropsHandler } from "../utils";

const DIRECTION = {
  x: "horizontal",
  y: "vertical"
}

export default {
  props: {
    dir: {
      type: String,
      default: "x",
      validator(direction) {
        return Object.keys(DIRECTION).includes(direction);
      }
    },
    width: {
      type: String,
      default: "1px",
    },
    color: {
      type: String,
      default: "gray",
    }
  },
  computed: {
    direction() {
      return DIRECTION[this.dir];
    },
    cssProps() {
      return {
        width: this.dir == "x" ? 'inherit' : this.width,
        height: this.dir == "y" ? 'inherit' : this.width,
        "--split-color": PropsHandler.color(this.color),
      }
    }
  }
}
</script>


<style scoped>
.ui-split {
  background-color: var(--split-color);
}
</style>
