<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive validateClient">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
          >
          <template v-slot:header>
            <div class="row">
              <div class="col-12 col-md-8">
                <h2 class="mb-1">Reporte de encuestas</h2>
                <h3 class="mb-1">Nombre de estudio: <span class="text-laive">{{nameEstudio}}</span></h3>
                <ol class="breadcrumb breadcrumb-links">
                  <li class="breadcrumb-item"><strong>Distribuidora:</strong> {{detail.distribuidoras}}</li>
                  <li class="breadcrumb-item"><strong>Duración:</strong> {{detail.duracion}}</li>
                  <li class="breadcrumb-item"><strong>Días transcurridos:</strong> {{detail.diasTranscurridos}} <span v-if="detail.diasTranscurridosPorcentaje">({{detail.diasTranscurridosPorcentaje}}%)</span></li>
                </ol>
              </div>
              <div class="col-12 col-md-4 text-right">
                <button type="button" class="btn base-button btn-primary btn-sm" role="button" @click="exportData"><i class="fas fa-file-export"></i>Exportar Resultados</button>
              </div>
            </div>
          </template>
          <div>
            <el-table
              :data="tableData"
              row-key="detalleStudio"
              empty-text="No se encontraron resultados"
              header-row-class-name="thead-light"
            >
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                v-bind="column"
              >
              </el-table-column>
              <el-table-column min-width="120px" label="% Avance">
                <template v-slot:default="props">
                    {{props.row.avancePorcentaje}}%
                </template>
              </el-table-column>
              <el-table-column min-width="160px" align="right" class-name="pl-0">
                <template v-slot:default="props">
                  <div class="d-flex">
                    <router-link class="btn base-button detail" :to="`${$route.params.id}/`+`vendedor/`+`${props.row.id}?name=${nameEstudio}`">Detalle por vendedor</router-link>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import swal from "sweetalert2";
import axios from "axios";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BasePagination from "@/components/BasePagination";
import {mapGetters} from 'vuex';
import DashboardNavbar from "../Layout/DashboardNavbar";
let fixClickOpenModal = 1
export default {
  name: "detalleEncuesta",
  components: {
    DashboardNavbar,
    BasePagination,
    RouteBreadCrumb,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  created(){
    const $this = this
    const idStudio = this.$route.params.id;
    const token = localStorage.getItem("token")
    const config = {
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    };
    const parameters = {
      "idEstudio": idStudio,
    }
    this.nameEstudio = this.$route.query.name
    let urlDetail = process.env.VUE_APP_API_URL+"/estudio/estadistica"
    axios.post(urlDetail, parameters,config)
    .then(function (response){
      document.querySelector(".form_laive").classList.remove("validateClient")
      const data = response.data
      $this.detail.diasTranscurridos = data.diasTranscurridos
      $this.detail.diasTranscurridosPorcentaje = data.diasTranscurridosPorcentaje
      $this.detail.distribuidoras = data.distribuidoras
      $this.detail.duracion = data.duracion
      $this.tableData = data.detalle
      console.log({data})
    }).catch(errors => {
      document.querySelector(".form_laive").classList.remove("validateClient")
      console.error("error")
    })
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    }),
  },
  mounted() {
    if(this.user.rol == 3){
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
    if(this.user.rol == 4){
      this.supervisorIdCurrent = this.user.codigo
      this.supervisorNameCurrent = this.user.nombre
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
  },
  methods: {
    exportData(){
      const $this = this
      const idStudio = this.$route.params.id;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const parameters = {
        "idEstudio": Number(idStudio),
        "iddistribuidora": 0,
        "mesa": ""
      }
      const urlDomain = process.env.VUE_APP_API_URL.replace("api", "")
      let urlExport = process.env.VUE_APP_API_URL+"/estudio/exportar"
      axios.post(urlExport, parameters,config)
      .then(function (response){
        document.querySelector(".form_laive").classList.remove("validateClient")
        const urlFile = urlDomain + response.data.fileName
        const link = document.createElement('a');
        link.href = urlFile;
        document.body.appendChild(link);
        link.click();
      }).catch(errors => {
        document.querySelector(".form_laive").classList.remove("validateClient")
        console.error("error")
      })
    }
  },
  data() {
    return {
      distribuidoraIdCurrent: null,
      distribuidoraNameCurrent: null,
      supervisorIdCurrent: null,
      supervisorNameCurrent: null,
      tableColumns: [
        {
          prop: "razonSocial",
          label: "Distribuidora",
          minWidth: 120,
        },
        {
          prop: "asignada",
          label: "Asignadas",
          minWidth: 120,
        },
        {
          prop: "completada",
          label: "Completadas",
          minWidth: 120,
        },
      ],
      tableData: [
        {
            id: "1-1",
            razonSocial: "3a",
            asignada: 714,
            completada: 5,
            avancePorcentaje: 1
        },
        {
            id: "1-5",
            razonSocial: "Jmj",
            asignada: 764,
            completada: 0,
            avancePorcentaje: 0
        }
      ],
      nameEstudio: "",
      detail: {
        diasTranscurridosPorcentaje: "",
        distribuidoras: "",
        duracion: "",
        diasTranscurridos: ""
      }
    };
  },
};
</script>
