<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive validateClient">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template v-slot:header>
            <div class="row">
              <div class="col-12 col-md-6">
                <h3 class="mb-0">Solcitudes de Incidencias</h3>
              </div>
              <div class="col-12 col-md-6 text-right">
                <button type="button" @click="exportPedidos" class="btn base-button btn-primary btn-sm" role="button"><i class="fas fa-file-export"></i>Exportar </button>
              </div>
            </div>
          </template>
          <div>
            <div class="row px-3">
              <div class="col-12 mb-3">
                <div class="row">
                  <div class="col">
                    <template v-if="distribuidoraIdCurrent">
                      <div class="title_filter mr-2"><div>Distribuidora:</div>  <strong>{{distribuidoraNameCurrent}}</strong></div>
                    </template>
                    <template v-else>
                      <span class="title_filter mr-2">Distribuidoras:  </span>
                      <select class="form-control" style="display: inline-block;height: 43px;" name="distributor_select" @change="changeListbyDistribuidora">
                        <option value="0">Todos</option>
                        <option
                          v-for="item in distribuidoras"
                          :value="item.idDistribuidora"
                          :key="item.idDistribuidora"
                          :selected="item.idDistribuidora == parameters.distribuidora"
                          >
                          {{ item.razonSocial }}
                        </option>
                      </select>
                    </template>
                  </div>
                  <div class="col">
                    <template v-if="supervisorIdCurrent">
                      <span class="title_filter mr-2">Supervisor:<br><strong>{{supervisorNameCurrent}}</strong></span>
                    </template>
                    <template v-else>
                      <span class="title_filter mr-2">Supervisor:  </span>
                      <select class="form-control" id="supervisor_select" style="display: inline-block;height: 43px;" name="supervisor_select" @change="changeListbySupverisor">
                        <option value="">Todos</option>
                        <option
                          v-for="item in supervisores"
                          :value="item.codigoSupervisor"
                          :key="item.codigoSupervisor+item.distribuidora"
                          :selected="item.codigoSupervisor == parameters.supervisor"
                          >
                          {{ item.nombre }}
                        </option>
                      </select>
                    </template>
                  </div>
                  <div class="col">
                    <span class="title_filter mr-2">Vendedor:  </span>
                    <select class="form-control" id="vendedor_select" style="display: inline-block;height: 43px;" name="vendedor_select" @change="changeListbyVendedor">
                      <option value="">Todos</option>
                      <option
                        v-for="item in vendedores"
                        :value="item.codigoVendedor"
                        :key="item.codigoVendedor+item.distribuidora"
                        :selected="item.codigoVendedor == parameters.vendedor"
                        >
                        {{ item.nombre }} {{ item.apellidos }}
                      </option>
                    </select>
                  </div>
                  <div class="col">
                    <span class="title_filter mr-2">Fechas:</span>
                    <select class="form-control" id="fecha_select" style="display: inline-block;height: 43px;" name="fecha_select" @change="changeListbyFechas">
                      <option value="">Todas</option>
                      <option
                        v-for="item in fechas"
                        :value="item.codigoDato"
                        :key="item.codigoDato"
                        :selected="item.codigoDato == fechaSelected"
                        >
                        {{ item.nombre }}
                      </option>
                    </select>
                  </div>
                  <div class="col">
                    <span class="title_filter mr-2">Estado:</span>
                    <select class="form-control" id="estado_select" style="display: inline-block;height: 43px;" name="estado_select" @change="changeListbyEstados">
                      <option value="">Todos</option>
                      <option
                        v-for="item in estados"
                        :value="item.codigoDato"
                        :key="item.codigoDato"
                        >
                        {{ item.nombre }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-2 mb-3 d-none">
                <div class="mr-2">
                  <span class="title_filter">Paginación:  </span>
                  <el-select
                    class="select-primary pagination-select"
                    v-model="pagination.perPage"
                    style="width:80px"
                    placeholder="Per page"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <!-- Real Table -->
            <div class="el-table table_laive">
              <table width="100%" border="0">
                <thead class="el-table__header-wrapper">
                  <th v-for="(item, i) in tableColumns" :key="item.prop" :style="(i == 1) ? `width:250px;` : ``">
                    {{ item.label }}
                  </th>
                  <th></th>
                </thead>
                <tbody>
                  <template v-if="queriedData">
                    <tr v-for="(value) in queriedData" :key="value.nombreCliente">
                      <td>{{value.codigoCliente ? value.codigoCliente : '---'}}</td>
                      <td>{{value.nombreCliente ? value.nombreCliente : '---'}}</td>
                      <td>{{value.distrito ? value.distrito : '---'}}</td>
                      <td>{{value.tipoIncidencia ? value.tipoIncidencia : '---'}}</td>
                      <td style="width: 130px;">
                        <template v-if="value.codigoEstadoIncidencia == 1">
                          <span class="text-warning">{{ value.estadoIncidencia }}</span>
                        </template>
                        <template v-else-if="value.codigoEstadoIncidencia == 2">
                          <span class="text-success">{{ value.estadoIncidencia }}</span>
                        </template>
                        <template v-else-if="value.codigoEstadoIncidencia == 3">
                          <span class="text-info">{{ value.estadoIncidencia }}</span>
                        </template>
                        <template v-else-if="value.codigoEstadoIncidencia == 4">
                          <span class="text-primary">{{ value.estadoIncidencia }}</span>
                        </template>
                        <template v-else-if="value.codigoEstadoIncidencia == 5">
                          <span class="text-dark">{{ value.estadoIncidencia }}</span>
                        </template>
                        <template v-else>
                          <span class="text-dark">{{ value.estadoIncidencia }}</span>
                        </template>
                      </td>
                      <td>{{value.fecha ? value.fecha : '---'}}</td>
                      <td>
                        <base-button type="info" tag="a" class="detail" size="sm" role="button" @click="openDetail(1, value)" aria-pressed="true">Detalle</base-button>
                        <base-button v-if="user.rol < 3" type="info" tag="a" class="edit" size="sm" role="button" @click="updatedState(value)" aria-pressed="true">Actualizar</base-button>
                      </td>
                    </tr>
                  </template>
                  <tr v-else><td>No se encontraron Pedidos</td></tr>
                </tbody>
              </table>
            </div>
          </div>
          <template v-slot:footer>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category d-none">
                  Mostrando {{ from + 1 }} a {{ to }} de {{ total }} Clientes
                </p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.newcurrentPage"
                :per-page="pagination.perPage"
                :total="total"
                @click="changePaginate"
              >
              </base-pagination>
            </div>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
let fixClickOpenModal = 1
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import axios from "axios";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import TabPane from "@/components/Tabs/TabPane";
import BasePagination from "@/components/BasePagination";
import {mapGetters} from 'vuex';
import swal from "sweetalert2";
import DashboardNavbar from "../Layout/DashboardNavbar";
export default {
  components: {
    DashboardNavbar,
    BasePagination,
    RouteBreadCrumb,
    TabPane,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  computed: {
    pagedData() {
      this.getDistributor
      this.getSupervisor
      this.getVendedor
      this.getFechas
      this.getEstados
      this.getDataIncidencias
      return this.tableData
    },
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString().toLowerCase();
          if (rowValue.includes && rowValue.includes(this.searchQuery.toLowerCase())) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      let firstPagination = document.querySelector("ul.pagination .page-item.number")
      if(this.pagination.total == 1 && firstPagination){
        firstPagination.classList.add("active")
      }
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.pagination.total;
    },
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    }),
    getDistributor(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let urlApiDistribuidoras = process.env.VUE_APP_API_URL+"/distribuidoras/lista"
      const requestDistribuidoras = axios.get(urlApiDistribuidoras, config);
      if($this.distribuidoras.length == 0){
        axios.all([requestDistribuidoras]).then(axios.spread((...responses) => {
          const responseDistribuidoras = responses[0].data
          if($this.distribuidoraIdCurrent){
            let dataDistibuitorCurrent =  responseDistribuidoras.filter((item)=>{return item.idDistribuidora == $this.distribuidoraIdCurrent})
            $this.distribuidoraNameCurrent = dataDistibuitorCurrent[0].razonSocial
          }
          $this.distribuidoras = responseDistribuidoras.filter((item)=>{return item.estado == "1"})
          return $this.distribuidoras;
        })).catch(errors => {
          // react on errors.
        })
      }
    },
    getSupervisor(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      if($this.distribuidoras.length && $this.initload){
        const selectDistribuidora = ($this.distribuidoraIdCurrent) ? $this.distribuidoraIdCurrent : 0
        const urlApiSupervisores = process.env.VUE_APP_API_URL+`/supervisor/lista/${selectDistribuidora}`
        axios.get(urlApiSupervisores,config)
        .then(function (response){
          let dataSupervisores= response.data
          return $this.supervisores = dataSupervisores
        })
      }
    },
    getVendedor(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      if($this.supervisores.length && $this.initload){
        const selectDistribuidora = ($this.distribuidoraIdCurrent) ? $this.distribuidoraIdCurrent : 0
        const selectSupervisor = ($this.supervisorIdCurrent) ? $this.supervisorIdCurrent : document.querySelector("select[name='supervisor_select']").value
        const parameters = {
          "iddistribuidora": selectDistribuidora,
          "codigosupervisor": selectSupervisor
        }
        const urlApiSupervisores = process.env.VUE_APP_API_URL+`/vendedor/lista`
        axios.post(urlApiSupervisores,parameters,config)
        .then(function (response){
          return $this.vendedores = response.data
        })
      }
    },
    async getFechas(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const urlApiFechas = process.env.VUE_APP_API_URL+"/maestro/lista/listafecha"
      if($this.fechas.length == 0){
        await axios.get(urlApiFechas,config)
        .then(function (response){
          return $this.fechas = response.data
        })
      }
    },
    async getEstados(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const urlApiPedidos = process.env.VUE_APP_API_URL+"/maestro/lista/estadoincidencia"
      if($this.estados.length == 0){
        await axios.get(urlApiPedidos,config)
        .then(function (response){
          return $this.estados = response.data
        })
      }
    },
    getDataIncidencias() {
      const $this = this
      let getParams = this.$route.query
      if($this.distribuidoras.length && $this.initload){
        let idDistribuidora = ""
        let idSupervisor = ""
        let idVendedor = ""
        if(Object.keys(getParams).length){
          idDistribuidora = Number(getParams.distribuidora)
          idSupervisor = getParams.supervisor
          idVendedor = getParams.vendedor
          this.parameters.distribuidora = idDistribuidora
          this.parameters.supervisor = idSupervisor
          this.parameters.vendedor = idVendedor
        }else{
          idDistribuidora = ($this.distribuidoraIdCurrent) ? $this.distribuidoraIdCurrent : 0
          idSupervisor = ($this.supervisorIdCurrent) ? $this.supervisorIdCurrent : document.querySelector("select[name='supervisor_select']").value
        }
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        const parameters = {
          "idDistribuidora": idDistribuidora,
          "codigoSupervisor": idSupervisor,
          "codigoVendedor": idVendedor,
          "periodo": "1",
          "estado": "",
          "page": 1
        }
        const urlApiIncidencia = process.env.VUE_APP_API_URL+`/incidencia/lista`
        axios.post(urlApiIncidencia,parameters,config)
        .then(function (response){
          $this.initload = false
          document.querySelector(".form_laive").classList.remove("validateClient")
          $this.pagination.currentPage = 1
          $this.pagination.perPage = response.data.data.length
          $this.pagination.total = response.data.totalPage
          return $this.tableData = response.data.data
        })
      }
    },
  },
  methods:{
    changeListbyDistribuidora(evn){
      const $this = this
      let selected = Number(evn.target.value)
      if (selected == 0) document.querySelector("select[name='supervisor_select']").value = ""
      let idSupervisor = document.querySelector("select[name='supervisor_select']").value
      let idVendedor = document.querySelector("select[name='vendedor_select']").value
      let idPeriodo = document.querySelector("select[name='fecha_select']").value
      let idEstado = document.querySelector("select[name='estado_select']").value
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const parameters = {
        "idDistribuidora": selected,
        "codigoSupervisor": idSupervisor,
        "codigoVendedor": idVendedor,
        "periodo": idPeriodo,
        "estado": idEstado,
        "page": 1
      }
      document.querySelector(".form_laive").classList.add("validateClient")
      const urlApiSupervisores = process.env.VUE_APP_API_URL+`/supervisor/lista/${selected}`
      const urlApiPedidos = process.env.VUE_APP_API_URL+`/incidencia/lista`
      const dataPedidos = axios.post(urlApiPedidos,parameters,config)
      const getSupervisores = axios.get(urlApiSupervisores,config)
      const urlApiVendedores = process.env.VUE_APP_API_URL+`/vendedor/lista/`
      const getVendedores = axios.post(urlApiVendedores,parameters,config)
      axios.all([dataPedidos, getSupervisores, getVendedores]).then(axios.spread((...responses) => {
        document.querySelector(".form_laive").classList.remove("validateClient")
        const resDataByDistribuidora = responses[0].data
        const resListSupervisores = responses[1].data
        const resListVendedores = responses[2].data
        $this.pagination.currentPage = 1
        $this.pagination.perPage = resDataByDistribuidora.data.length
        $this.pagination.total = resDataByDistribuidora.totalPage
        $this.tableData = resDataByDistribuidora.data
        $this.supervisores = resListSupervisores
        $this.vendedores = resListVendedores
      })).catch(errors => {
        // react on errors.
      })
    },
    changeListbySupverisor(evn){
      const $this = this
      let selected = evn.target.value
      if (selected == 0) document.querySelector("select[name='vendedor_select']").value = ""
      let idDistribuidora = ($this.distribuidoraIdCurrent) ? $this.distribuidoraIdCurrent : Number(document.querySelector("select[name='distributor_select']").value)
      let idVendedor = document.querySelector("select[name='vendedor_select']").value
      let idPeriodo = document.querySelector("select[name='fecha_select']").value
      let idEstado = document.querySelector("select[name='estado_select']").value
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      if(idDistribuidora != 0){
        const parametersPedidos = {
          "idDistribuidora": idDistribuidora,
          "codigoSupervisor": selected,
          "codigoVendedor": idVendedor,
          "periodo": idPeriodo,
          "estado": idEstado,
          "page": 1
        }
        const parameters = {
          "idDistribuidora": idDistribuidora,
          "codigosupervisor": selected
        }
        document.querySelector(".form_laive").classList.add("validateClient")
        console.log({parameters});
        const urlApiVendedores = process.env.VUE_APP_API_URL+`/vendedor/lista/`
        const urlApiPedidos = process.env.VUE_APP_API_URL+`/incidencia/lista`
        const dataPedidos = axios.post(urlApiPedidos,parametersPedidos,config)
        const getVendedores = axios.post(urlApiVendedores,parameters,config)
        axios.all([dataPedidos, getVendedores]).then(axios.spread((...responses) => {
          const resDataBySupervisor = responses[0].data
          const resListVendedores = responses[1].data
          $this.pagination.currentPage = 1
          $this.pagination.perPage = resDataBySupervisor.data.length
          $this.pagination.total = resDataBySupervisor.totalPage
          $this.tableData = resDataBySupervisor.data
          $this.vendedores = resListVendedores
          document.querySelector(".form_laive").classList.remove("validateClient")
          document.querySelector("select[name='vendedor_select']").value = ""
        })).catch(errors => {
          // react on errors.
        })
      }else{
        document.querySelector("select[name='supervisor_select']").value = ""
        const swalWithBootstrapButtons1 = swal.mixin({
          customClass: {
            confirmButton: "btn btn-success btn-fill",
          },
          buttonsStyling: false,
        });
        swalWithBootstrapButtons1.fire({
          title: `Seleccionar una distribuidora`,
          confirmButtonText: "Ok",
        });
        document.querySelector("select[name='supervisor_select']").value = ""
      }
    },
    changeListbyVendedor(evn){
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let selected = evn.target.value
      let idDistribuidora = ($this.distribuidoraIdCurrent) ? $this.distribuidoraIdCurrent : Number(document.querySelector("select[name='distributor_select']").value)
      let idSupervisor = ($this.supervisorIdCurrent) ? $this.supervisorIdCurrent : document.querySelector("select[name='supervisor_select']").value
      let idPeriodo = document.querySelector("select[name='fecha_select']").value
      let idEstado = document.querySelector("select[name='estado_select']").value
      if(idDistribuidora != 0){
        const parameters = {
          "idDistribuidora": idDistribuidora,
          "codigoSupervisor": idSupervisor,
          "codigoVendedor": selected,
          "periodo": idPeriodo,
          "estado": idEstado,
          "page": 1
        }
        document.querySelector(".form_laive").classList.add("validateClient")
        console.log({parameters});

        axios.post(process.env.VUE_APP_API_URL+"/incidencia/lista",parameters,config)
        .then(function (response){
          document.querySelector(".form_laive").classList.remove("validateClient")
          console.log(response.data, " ====lista by vendedores");
          $this.pagination.currentPage = 1
          $this.pagination.perPage = response.data.data.length
          $this.pagination.total = response.data.totalPage
          return $this.tableData = response.data.data
        })
      }else{
        document.querySelector("select[name='vendedor_select']").value = ""
        const swalWithBootstrapButtons1 = swal.mixin({
          customClass: {
            confirmButton: "btn btn-success btn-fill",
          },
          buttonsStyling: false,
        });
        swalWithBootstrapButtons1.fire({
          title: `Seleccionar una distribuidora`,
          confirmButtonText: "Ok",
        });
      }
    },
    changeListbyFechas(evn){
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let selected = evn.target.value
      let idDistribuidora = ($this.distribuidoraIdCurrent) ? $this.distribuidoraIdCurrent : Number(document.querySelector("select[name='distributor_select']").value)
      let idSupervisor = ($this.supervisorIdCurrent) ? $this.supervisorIdCurrent : document.querySelector("select[name='supervisor_select']").value
      let idVendedor = document.querySelector("select[name='vendedor_select']").value
      let idEstado = document.querySelector("select[name='estado_select']").value
      const parameters = {
        "idDistribuidora": idDistribuidora,
        "codigoSupervisor": idSupervisor,
        "codigoVendedor": idVendedor,
        "periodo": selected,
        "estado": idEstado,
        "page": 1
      }
      document.querySelector(".form_laive").classList.add("validateClient")
      axios.post(process.env.VUE_APP_API_URL+"/incidencia/lista",parameters,config)
      .then(function (response){
        document.querySelector(".form_laive").classList.remove("validateClient")
        console.log(response.data, " ====lista by fechas");
        $this.pagination.currentPage = 1
        $this.pagination.perPage = response.data.data.length
        $this.pagination.total = response.data.totalPage
        return $this.tableData = response.data.data
      })
    },
    changeListbyEstados(evn){
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let selected = evn.target.value
      let idDistribuidora = ($this.distribuidoraIdCurrent) ? $this.distribuidoraIdCurrent : Number(document.querySelector("select[name='distributor_select']").value)
      let idSupervisor = ($this.supervisorIdCurrent) ? $this.supervisorIdCurrent : document.querySelector("select[name='supervisor_select']").value
      let idVendedor = document.querySelector("select[name='vendedor_select']").value
      let idPeriodo = document.querySelector("select[name='fecha_select']").value
      const parameters = {
        "idDistribuidora": idDistribuidora,
        "codigoSupervisor": idSupervisor,
        "codigoVendedor": idVendedor,
        "periodo": idPeriodo,
        "estado": selected,
        "page": 1
      }
      document.querySelector(".form_laive").classList.add("validateClient")
      axios.post(process.env.VUE_APP_API_URL+"/incidencia/lista",parameters,config)
      .then(function (response){
        document.querySelector(".form_laive").classList.remove("validateClient")
        console.log(response.data, " ====lista by fechas");
        $this.pagination.currentPage = 1
        $this.pagination.perPage = response.data.data.length
        $this.pagination.total = response.data.totalPage
        return $this.tableData = response.data.data
      })
    },
    changePaginate(evn){
      const $this = this
      const listPaginator = document.querySelectorAll("ul.pagination .page-item")
      let idDistribuidora = (this.distribuidoraIdCurrent) ? this.distribuidoraIdCurrent : Number(document.querySelector("select[name='distributor_select']").value)
      let idSupervisor = ($this.supervisorIdCurrent) ? $this.supervisorIdCurrent : document.querySelector("select[name='supervisor_select']").value
      let idVendedor = document.querySelector("select[name='vendedor_select']").value
      let idPeriodo = document.querySelector("select[name='fecha_select']").value
      let idEstado = document.querySelector("select[name='estado_select']").value
      let elm = evn.target;
      let sendAjax = false;
      let parentEl = elm.parentNode
      if(elm.classList.contains("prev-page")){
        if(!elm.classList.contains("disabled")){
          let linkActivePrev = document.querySelectorAll("ul.pagination .page-item.active")
          linkActivePrev[0].classList.remove("active")
          linkActivePrev[0].previousSibling.classList.add("active")
          sendAjax = true
        }
      }else if(elm.classList.contains("next-page")){
      }else{
        if(elm.getAttribute("aria-label") == "Previous"){
          let linkActivePrev = document.querySelectorAll("ul.pagination .page-item.active")
          linkActivePrev[0].classList.remove("active")
          linkActivePrev[0].previousSibling.classList.add("active")
          sendAjax = true
        }else if(elm.getAttribute("aria-label") == "Next"){
          let linkActiveNext = document.querySelectorAll("ul.pagination .page-item.active")
            linkActiveNext[0].classList.remove("active")
            linkActiveNext[0].nextSibling.classList.add("active")
            sendAjax = true
        }else{
          if(!parentEl.classList.contains("active")){
            listPaginator.forEach(item => {
              item.classList.remove("active")
            });
            parentEl.classList.add("active")
            sendAjax = true
          }
        }
      }
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const parameters = {
          "page": this.pagination.newcurrentPage,
          "iddistribuidora": idDistribuidora,
          "codigosupervisor": idSupervisor,
          "codigovendedor": idVendedor,
          "periodo": idPeriodo,
          "estado": idEstado
      }
      if(sendAjax){
        document.querySelector(".form_laive").classList.add("validateClient")
        axios.post(process.env.VUE_APP_API_URL+"/incidencia/lista",parameters,config)
        .then(function (response){
          document.querySelector(".form_laive").classList.remove("validateClient")
          sendAjax = false
          $this.pagination.currentPage = 1
          $this.pagination.perPage = response.data.data.length
          $this.pagination.total = response.data.totalPage
          $this.tableData = [];
          return $this.tableData = response.data.data
        })
      }
    },
    updatedState(elm){
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      var inputOptionsPromise = new Promise(function (resolve) {
        setTimeout(function () {
          let stateObject = {};
          const data = $this.estados
          data.forEach(item => {
            stateObject[item.codigoDato] = item.nombre
          });
          resolve(stateObject)
        }, 500)
      })
      let swalCustom = swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          cancelButton: "btn btn-danger btn-fill",
          container: "swal-wide",
          content: 'custom_select'
        },
        buttonsStyling: false,
      });
      swalCustom.fire({
        title: `Actualizar Incidencia`,
        input: 'select',
        inputOptions: inputOptionsPromise,
        inputValue: elm.codigoEstadoIncidencia,
        showCancelButton: true,
        confirmButtonText: 'Actualizar estado',
        inputValidator: (value) => {
          return new Promise((resolve) => {
            let valueCurrent = Number(value)
            if (valueCurrent != elm.codigoEstadoIncidencia) {
              const urlApiEstadosUpdate = process.env.VUE_APP_API_URL+"/incidencia/editar"
              const parameters = {
                "idIncidencia": elm.idIncidencia,
                "estado": value,
              }
              axios.post(urlApiEstadosUpdate,parameters,config)
              .then(function (response){
                resolve()
                setTimeout(() => {
                  location.reload();
                }, 500);
                //swalCustom.fire(`Se actualizo el estado de la incidencia`)
              })
            } else {
              resolve('Cambie de estado para actualizar')
            }
          })
        }
      })
    },
    openDetail(index, row){
      const swalWithBootstrapButtons1 = swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          container: "swal-wide",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons1.fire({
        title: `Detalle de la Incidencia`,
        html: `
            <table class="table_laive">
              <tr>
                <th>Código Vendedor:</th>
                <td>${row.codigoVendedor}</td>
              </tr>
              <tr>
                <th>Nombre Vendedor:</th>
                <td>${row.nombreVendedor}</td>
              </tr>
              <tr>
                <th>Código Supervisor:</th>
                <td>${row.codigoSupervisor}</td>
              </tr>
              <tr>
                <th>Nombre Supervisor:</th>
                <td>${row.nombreSupervisor}</td>
              </tr>
              <tr>
                <th>Dirección del cliente:</th>
                <td>${row.direccion}</td>
              </tr>
              <tr>
                <th>Referencia de la dirección:</th>
                <td>${row.referenciaDireccion}</td>
              </tr>
              <tr>
                <th>Coordenadas del negocio:</th>
                <td>Latitud: ${row.latitud}, Longitud: ${row.longitud}</td>
              </tr>
              <tr>
                <th>Modelo:</th>
                <td>${row.modelo}</td>
              </tr>
              <tr>
                <th>Placa:</th>
                <td>${row.placa}</td>
              </tr>
              <tr>
                <th>Lote:</th>
                <td>${row.lote}</td>
              </tr>
              <tr>
                <th>Persona de contacto:</th>
                <td>${row.personaContacto}</td>
              </tr>
              <tr>
                <th>Numero de contacto:</th>
                <td>${row.numeroContacto}</td>
              </tr>
              <tr>
                <th>Horario de atención:</th>
                <td>Inicio: ${row.horaInicioDisponibilidad}, Cierre: ${row.horaFinDisponibilidad}</td>
              </tr>
            </table>`,
        confirmButtonText: "Cerrar",
        showConfirmButton: true,
      });
    },
    exportPedidos(){
      let idDistribuidora = (this.distribuidoraIdCurrent) ? this.distribuidoraIdCurrent : Number(document.querySelector("select[name='distributor_select']").value)
      let idSupervisor = (this.supervisorIdCurrent) ? this.supervisorIdCurrent : document.querySelector("select[name='supervisor_select']").value
      let idVendedor = document.querySelector("select[name='vendedor_select']").value
      let idPeriodo = document.querySelector("select[name='fecha_select']").value
      let idEstado = document.querySelector("select[name='estado_select']").value
      const token = localStorage.getItem("token")
      const urlDomain = process.env.VUE_APP_API_URL.replace("api", "")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
        }
      };
      const parameters = {
        "iddistribuidora": idDistribuidora,
        "codigosupervisor": idSupervisor,
        "codigovendedor": idVendedor,
        "periodo": idPeriodo,
        "estado": idEstado
      }
      console.log(parameters, " selectt export");
      document.querySelector(".form_laive").classList.add("validateClient")
      axios.post(process.env.VUE_APP_API_URL+"/incidencia/exportar",parameters,config)
      .then(function (response){
        document.querySelector(".form_laive").classList.remove("validateClient")
        const urlFile = urlDomain + response.data.fileName
        const link = document.createElement('a');
        link.href = urlFile;
        document.body.appendChild(link);
        link.click();
      })
    }
  },
  mounted() {
    if(this.user.rol == 3){
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
    if(this.user.rol == 4){
      this.supervisorIdCurrent = this.user.codigo
      this.supervisorNameCurrent = this.user.nombre
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
  },
  data() {
    return {
      initload: true,
      distribuidoraIdCurrent: null,
      distribuidoraNameCurrent: null,
      supervisorIdCurrent: null,
      supervisorNameCurrent: null,
      pagination: {
        perPage: 2,
        currentPage: 1,
        newcurrentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 1,
      },
      parameters:{
        distribuidora: "",
        supervisor: "",
        vendedor: ""
      },
      searchQuery: "",
      distribuidoras:[],
      supervisores:[],
      vendedores:[],
      fechas:[],
      fechaSelected: "1",
      estados:[],
      propsToSearch: ["nombreCliente", "idPedido"],
      tableColumns: [
        {
          prop: "codCliente",
          label: "Cod. Cliente",
          minWidth: 120,
        },
        {
          prop: "nombreCliente",
          label: "Cliente",
          minWidth: 280,
        },
        {
          prop: "distrito",
          label: "Distrito",
          minWidth: 265,
        },
        {
          prop: "tipo",
          label: "Tipo",
          minWidth: 265,
        },
        {
          prop: "estado",
          label: "Estado",
          minWidth: 265,
        },
        {
          prop: "fecha",
          label: "Fecha",
          minWidth: 265,
        },
      ],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      expands: [],
    };
  },
};
</script>
