<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
          >
          <template v-slot:header>
            <div class="row">
              <div class="col-12 col-md-8">
                <h2 class="mb-1">Reporte de encuestas</h2>
                <h3 class="mb-1">Nombre de estudio: <span class="text-laive">{{nameEstudio}}</span></h3>
                <ol class="breadcrumb breadcrumb-links">
                  <li class="breadcrumb-item"><strong>Distribuidora:</strong> {{detail.distribuidoras}}</li>
                  <li class="breadcrumb-item"><strong>Duración:</strong> {{detail.duracion}}</li>
                  <li class="breadcrumb-item"><strong>Días transcurridos:</strong> {{detail.diasTranscurridos}} <span v-if="detail.diasTranscurridosPorcentaje">({{detail.diasTranscurridosPorcentaje}}%)</span></li>
                </ol>
              </div>
              <div class="col-12 col-md-4 text-right">
                <button type="button" class="btn base-button btn-primary btn-sm" role="button" @click="exportData"><i class="fas fa-file-export"></i>Exportar Resultados</button>
              </div>
            </div>
          </template>
          <div>
            <div class="row px-3">
              <div class="col-12 mb-3">
                <div class="row">
                  <div class="col-12 col-md-3">
                    <span class="title_filter mr-2">Mesa:  </span>
                    <select class="form-control" style="display: inline-block;height: 43px;" id="mesa_select" name="mesa_select" @change="changeListMesa">
                      <option value="">Todas</option>
                      <option
                          v-for="item in mesas"
                          :value="item.codigoDato"
                          :key="item.codigoDato"
                          >
                          {{ item.nombre }}
                        </option>
                    </select>
                  </div>
                  <div class="col-12 col-md-3 ">
                    <span class="title_filter mr-2">Ordenar por:  </span>
                    <select class="form-control" style="display: inline-block;height: 43px;" name="order_select" @change="changeOrder">
                      <option value="" disabled style="display:none" selected>Seleccionar</option>
                      <option value="1">Ordenar de mayor a menor</option>
                      <option value="2">Ordenar de menor a mayor</option>
                    </select>
                  </div>
                </div>
              </div>
              <el-table
                :data="queriedData"
                row-key="detalleStudio"
                empty-text="No se encontraron resultados"
                header-row-class-name="thead-light"
              >
                <el-table-column
                  v-for="column in tableColumns"
                  :key="column.label"
                  v-bind="column"
                >
                </el-table-column>
                <el-table-column min-width="120px" label="% Avance">
                  <template v-slot:default="props">
                      {{props.row.avancePorcentaje}}%
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <template v-slot:footer>
            <div class="col-12 d-flex justify-content-end flex-wrap">
              <default-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              >
              </default-pagination>
            </div>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import axios from "axios";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BasePagination from "@/components/BasePagination";
import {mapGetters} from 'vuex';
import DashboardNavbar from "../Layout/DashboardNavbar";
function mayorToMenor( a, b ) {
  if (a.asignada < b.avancePorcentaje) {
    return 1;
  }
  if (a.avancePorcentaje > b.avancePorcentaje) {
    return -1;
  }
  return 0;
}
function menorToMayor( a, b ) {
  if (a.avancePorcentaje > b.avancePorcentaje) {
    return 1;
  }
  if (a.avancePorcentaje < b.avancePorcentaje) {
    return -1;
  }
  return 0;
}
export default {
  name: "detalleEncuesta",
  components: {
    DashboardNavbar,
    BasePagination,
    RouteBreadCrumb,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    }),
    pagedData() {
      this.getMesas
      this.getDataDetailVendedor
      return this.tableData.slice(this.from, this.to);
      // return this.tableData
    },
    queriedData() {
      return this.pagedData;
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },
    getMesas(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const distribuidora = this.$route.params.idVendedor.split("-")
      this.idDistribuidora = distribuidora[1]
      const paramsMesa = {
        "iddistribuidora": Number(distribuidora[1]),
        "tipo": "mesas",
      }
      const urlApiMesa = process.env.VUE_APP_API_URL+`/maestro/lista/pordistribuidora`
      if($this.mesas.length == 0){
        axios.post(urlApiMesa,paramsMesa,config)
        .then(function (response){
          return $this.mesas = response.data
        })
      }
    },
    getDataDetailVendedor(){
      const $this = this
      const idStudio = this.$route.params.id;
      const idVendedor = this.$route.params.idVendedor;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const parameters = {
        "id": idVendedor,
        "codigoMesa": "",
        "avanceOrden": ""
      }
      this.nameEstudio = this.$route.query.name
      let urlDetailVendedor = process.env.VUE_APP_API_URL+"/estudio/estadistica/vendedor"
      axios.post(urlDetailVendedor, parameters,config)
      .then(function (response){
        document.querySelector(".form_laive").classList.remove("validateClient")
        const data = response.data
        // $this.detail.diasTranscurridosPorcentaje = data.diasTranscurridosPorcentaje
        $this.detail.diasTranscurridos = data.diasTranscurridos
        $this.detail.distribuidoras = data.distribuidoras
        $this.detail.duracion = data.duracion
        $this.tableData = data.detalle
        $this.pagination.total = data.detalle.length
        console.log({data})
      }).catch(errors => {
        document.querySelector(".form_laive").classList.remove("validateClient")
        console.error("error")
      })
    },
  },
  mounted() {
    if(this.user.rol == 3){
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
    if(this.user.rol == 4){
      this.supervisorIdCurrent = this.user.codigo
      this.supervisorNameCurrent = this.user.nombre
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
  },
  methods: {
    changeListMesa(evn){
      const selected = evn.target.value
      const $this = this
      const idVendedor = this.$route.params.idVendedor;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const parameters = {
        "id": idVendedor,
        "codigoMesa": selected,
        "avanceOrden": ""
      }
      document.querySelector(".form_laive").classList.add("validateClient")
      let urlDetailVendedor = process.env.VUE_APP_API_URL+"/estudio/estadistica/vendedor"
      axios.post(urlDetailVendedor, parameters,config)
      .then(function (response){
        document.querySelector(".form_laive").classList.remove("validateClient")
        const data = response.data
        $this.tableData = data.detalle
        $this.pagination.total = data.detalle.length
        console.log({data})
      }).catch(errors => {
        document.querySelector(".form_laive").classList.remove("validateClient")
        console.error("error")
      })
    },
    changeOrder(evn){
      let getDataTable = this.tableData;
      if(evn.target.value == 1){
        getDataTable.sort(mayorToMenor)
      }else if(evn.target.value == 2){
        getDataTable.sort(menorToMayor)
      }
      this.tableData = getDataTable
    },
    exportData(){
      const $this = this
      const idStudio = this.$route.params.id;
      const token = localStorage.getItem("token")
      const mesa = document.querySelector("#mesa_select").value
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const parameters = {
        "idEstudio": Number(idStudio),
        "iddistribuidora": Number(this.idDistribuidora),
        "mesa": mesa
      }
      const urlDomain = process.env.VUE_APP_API_URL.replace("api", "")
      let urlExport = process.env.VUE_APP_API_URL+"/estudio/exportar"
      axios.post(urlExport, parameters,config)
      .then(function (response){
        document.querySelector(".form_laive").classList.remove("validateClient")
        const urlFile = urlDomain + response.data.fileName
        const link = document.createElement('a');
        link.href = urlFile;
        document.body.appendChild(link);
        link.click();
      }).catch(errors => {
        document.querySelector(".form_laive").classList.remove("validateClient")
        console.error("error")
      })
    }
  },
  data() {
    return {
      distribuidoraIdCurrent: null,
      distribuidoraNameCurrent: null,
      supervisorIdCurrent: null,
      supervisorNameCurrent: null,
      mesas: [],
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      tableColumns: [
        {
          prop: "vendedor",
          label: "Vendedor",
          minWidth: 180,
        },
        {
          prop: "codigoVendedor",
          label: "Código",
          minWidth: 120,
        },
        {
          prop: "codigoMesa",
          label: "Mesa",
          minWidth: 120,
        },
        {
          prop: "asignada",
          label: "Asignadas",
          minWidth: 120,
        },
        {
          prop: "realizada",
          label: "Realizadas",
          minWidth: 120,
        },
      ],
      tableData: [],
      nameEstudio: "",
      idDistribuidora: "",
      detail: {
        diasTranscurridosPorcentaje: "",
        distribuidoras: "",
        duracion: "",
        diasTranscurridos: ""
      }
    };
  },
};
</script>
