<template>
  <div class="content bonificacion">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
      <div class="row mx-0 my-2">
        <div
          v-for="mensaje in mensajes"
          :key="mensaje"
          class="col-12 border chip chip-message py-1"
          :class="{
            [mensaje.clase]: mensaje.clase,
          }"
          :style="`opacity: ${mensaje.opacidad};`"
        >
          <i class="far fa-bell"></i>
          <span class="pl-2"> {{ mensaje.texto }} </span>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div :class="{ validateClient: !all_loaded }" class="form_laive">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card bg-white"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
          headerClasses="border-0 px-0"
        >
          <template v-slot:header>
            <div class="row p-0 mx-0">
              <div class="col-12 col-md-3 d-flex align-items-center px-3">
                <span class="mb-0 title"><b>Liquidaciones</b></span>
              </div>
              <div class="col-12 col-md-9 text-right">
                <div class="row mx-0 px-0" v-if="mes_actual">
                  <base-button
                    type="success"
                    :outline="true"
                    class="ml-auto px-2 py-1 submit-btns d-flex w-auto"
                    @click.prevent="descargarPlantilla"
                  >
                    <i class="fas fa-download font-11"></i>
                    <span class="font-11">Descargar plantilla</span>
                  </base-button>
                  <a
                    class="btn base-button btn-outline-success px-2 py-1 submit-btns d-flex w-auto"
                    href="#nueva-actividad"
                    @click="nueva_liquidacion.esta_creando = true"
                  >
                    <i class="fas fa-plus font-11"></i>
                    <span class="font-11">Crear Actv. de Venta</span>
                  </a>

                  <router-link :to="`/bonificaciones/crear/`">
                    <base-button
                      size="sm"
                      type="info"
                      class="submit-btns w-auto px-2 py-1"
                      @click.prevent="cargarArchivo"
                    >
                      <i class="fas fa-plus-circle font-11"></i>
                      <span class="font-11">Cargar Actv. de Venta</span>
                    </base-button>
                  </router-link>
                  <input
                    ref="inputArchivo"
                    type="file"
                    accept=".xlsx"
                    style="display: none"
                    @change="handleFileChange"
                  />
                </div>
              </div>
            </div>
          </template>
          <div class="row mx-0 px-3 mb-3">
            <div class="col-5 px-0">
              <div class="row mx-0">
                <div class="col-3 px-1">
                  <b class="font-11">Dex</b>
                  <div class="row mx-0">
                    <div class="col-12 p-0">
                      <GeneralDropdown
                        v-if="listas_cargadas"
                        v-model="filtro.dex"
                        aditional_class="bg-white mh-35px"
                        :options="distribuidoras_options"
                        :is_multiple="false"
                        :collapse_tags="true"
                        :puede_seleccionar_todos="true"
                        :resumir_tags="true"
                        :fit_content="true"
                      ></GeneralDropdown>
                    </div>
                  </div>
                </div>
                <div class="col-3 px-1">
                  <b class="font-11">Estado</b>
                  <div class="row mx-0">
                    <div class="col-12 p-0">
                      <GeneralDropdown
                        v-if="listas_cargadas"
                        v-model="filtro.estado"
                        aditional_class="bg-white mh-35px"
                        :options="estados_options"
                        :is_multiple="true"
                        :collapse_tags="true"
                        :puede_seleccionar_todos="true"
                        :resumir_tags="true"
                        :fit_content="true"
                      ></GeneralDropdown>
                    </div>
                  </div>
                </div>
                <div class="col-3 px-1">
                  <b class="font-11">Mes a visualizar</b>
                  <div class="row mx-0">
                    <div class="col-12 p-0">
                      <GeneralDropdown
                        v-if="listas_cargadas"
                        v-model="filtro.mes"
                        aditional_class="bg-white mh-35px"
                        :options="meses_options"
                        :is_multiple="false"
                        :collapse_tags="true"
                        :puede_seleccionar_todos="true"
                        :resumir_tags="true"
                        :fit_content="true"
                      ></GeneralDropdown>
                    </div>
                  </div>
                </div>
                <div class="col-3 px-1">
                  <b class="font-11">Año a visualizar</b>
                  <div class="row mx-0">
                    <div class="col-12 p-0">
                      <GeneralDropdown
                        v-if="listas_cargadas"
                        v-model="filtro.year"
                        aditional_class="bg-white mh-35px"
                        :options="anios_options"
                        :is_multiple="false"
                        :collapse_tags="true"
                        :puede_seleccionar_todos="true"
                        :resumir_tags="true"
                        :fit_content="true"
                      ></GeneralDropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-7 px-0">
              <div class="row mx-0 h-100">
                <div class="col-2 px-1 text-center">
                  <div class="row mx-0 h-100">
                    <div class="col-12 px-0">
                      <b class="font-11">Total del mes</b>
                    </div>
                    <div class="col-12 px-0 bonificacion-primary-text">
                      <b> S/{{ costo_total }} </b>
                    </div>
                  </div>
                </div>
                <div class="col-2 px-1 text-center">
                  <div class="row mx-0 h-100">
                    <div class="col-12 px-0">
                      <b class="font-11">Ratio venta</b>
                    </div>
                    <div class="col-12 px-0 bonificacion-primary-text">
                      <b> {{ ratio_total }}% </b>
                    </div>
                  </div>
                </div>

                <div class="col-2 px-1 border-right text-center">
                  <div class="row mx-0 h-100">
                    <div class="col-12 px-0">
                      <b class="font-11">Liquidados</b>
                    </div>
                    <div
                      class="col-12 px-0"
                      :class="{
                        'text-danger':
                          liquidaciones_completas < liquidaciones_raw.length,
                        'bonificacion-primary-text':
                          liquidaciones_completas == liquidaciones_raw.length,
                      }"
                    >
                      <b>
                        {{ liquidaciones_completas }}/{{
                          liquidaciones_raw.length
                        }}
                      </b>
                    </div>
                  </div>
                </div>
                <div v-for="mes in mesesHistoricos"
                  class="col-2 px-1 text-center">
                  <div class="row mx-0 h-100">
                    <div class="col-12 px-0">
                      <b class="font-11">{{ mes.nombre }}</b>
                    </div>
                    <div class="col-12 px-0">
                      <span> {{ mes.monto }} </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <JOPTable
            :liquidaciones="liquidaciones"
            v-if="data_cargada"
          ></JOPTable>

          <NewRow
            :liquidacion="nueva_liquidacion"
            v-if="nueva_liquidacion.esta_creando==true"
          ></NewRow>

          <NewRow
            v-for="liquidacion in liquidaciones_extraidas.filter(item => item.esta_creando==true)"
            v-if="cargando_liquidaciones_excel"
            :liquidacion="liquidacion"
          ></NewRow>

          <div class="row mx-2" v-if="mes_actual">
            <base-button
              type="success"
              :outline="true"
              class="px-2 py-1 submit-btns d-flex w-auto"
              @click.prevent="verProximos"
            >
              <span class="font-11">Ver proximos</span>
            </base-button>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script setup>
// import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import DashboardNavbar from "../../Layout/DashboardNavbar";
import "@/assets/sass/laive/bonificaciones.scss";
import { ref, computed, watch, onMounted } from "vue";
import GeneralDropdown from "../../Bonificaciones/components/GeneralDropdown.vue";
import NewRow from "./components/NewRow.vue";
import { useStore } from "vuex";
import constantes from "@/model/constantes.js";
import JOPTable from "./components/Table.vue";
import * as XLSX from "xlsx";
import { formatearCantidades, calcularCostoTotal } from "../../../util";

const store = useStore();

store.dispatch("bonificacionUtil/cargarLista", "distribuidoras");

const liquidaciones_raw = computed(
  () => store.state.liquidacion.liquidaciones_jop
);
const liquidaciones_historico_raw = computed(
  () => store.state.liquidacion.liquidaciones_jop_historico
);
const liquidaciones = computed(() => {
  const result = liquidaciones_raw.value;
  const filtered = result.filter((liquidacion) =>
    filtro.value.estado?.includes(liquidacion.estado)
  );
  return filtered;
});

const liquidaciones_completas = computed(() => {
  return liquidaciones_raw.value.filter((item) =>
    [constantes.FINALIZADO, constantes.APROBADO, constantes.ENVIADO].includes(
      item.estado
    )
  ).length;
});

const all_loaded = computed(
  () => store.state.bonificacionUtil.distribuidoras.length > 0
);
const costo_total = computed(() => {
  const costoTotal = calcularCostoTotal(liquidaciones_raw.value);
  return formatearCantidades(costoTotal);
});

const ratio_total = computed(() => {
  const meta_total = liquidaciones_raw.value.reduce(
    (acumulador, objeto) =>
      acumulador + parseFloat(objeto.cant_bonificable ?? 0),
    0
  );
  const real_total = liquidaciones_raw.value.reduce(
    (acumulador, objeto) =>
      acumulador +
      parseFloat(
        objeto.cant_bonificada_ajustada ?? objeto.cant_bonificada ?? 0
      ),
    0
  );
  let resultado = 0;
  if (meta_total > 0) {
    if (real_total > 0) {
      if (real_total >= meta_total) {
        resultado = 100;
      } else {
        resultado = ((real_total * 100) / meta_total).toFixed(2);
      }
    }
  }
  return resultado;
});

const fecha_actual = ref(new Date());
const mesesHistoricos = computed(() => {
  let mesesAnteriores = [];
  let anioActual = filtro.value.year;
  let mesActual = filtro.value.mes;
  if (mesActual) {
    mesActual = mesActual - 1;
    for (let i = 1; i <= 3; i++) {
      let mesAnterior = ((mesActual - i + 12) % 12) + 1;
      let anioAnterior = mesAnterior === 12 ? anioActual - 1 : anioActual;
      let nombreMesAnterior = meses_options.value.find(
        (m) => m.value === mesAnterior
      ).label;
      let monto = calcularCostoTotal(liquidaciones_historico_raw.value.filter(l => l.year === anioAnterior && l.month === mesAnterior));
      mesesAnteriores.push({
        numero: mesAnterior,
        nombre: nombreMesAnterior,
        monto: `S/ ${formatearCantidades(monto)}`,
      });
    }
  }
  return mesesAnteriores;
});

const nueva_liquidacion = ref({ esta_creando: false });

const definirActividadVenta = () => {
  nueva_liquidacion.value = {
    tipo_bonificacion: constantes.TIPO_ACTV_VENTA,
    nombre_bonificacion: "",
    codigo_bonificacion: "nuevo",
    estado: constantes.POR_REVISAR,
    costo_total_ajustado: "",
    cantidadbhgr: "1",
    codigoproductopr: "",
    esta_creando: false,
    iddistribuidora: filtro.value.dex,
  };
};

const mes_actual = computed(() => {
  const fechaActual = new Date();
  const mes_actual = fechaActual.getMonth() + 1;
  return mes_actual == filtro.value.mes;
});

const verProximos = () => {
  const fechaActual = new Date();
  const mes_proximo = fechaActual.getMonth() + 2;
  filtro.value.mes = mes_proximo;
};

const filtro = ref({});
const listas_cargadas = ref(false);
const data_cargada = ref(false);
onMounted(() => {
  store
    .dispatch("bonificacionUtil/cargarLista", "distribuidoras")
    .then((result) => {
      setTimeout(() => {
        filtro.value.dex = distribuidoras_options.value[0].value;
        const fechaActual = new Date();
        filtro.value.mes = fechaActual.getMonth() + 1;
        filtro.value.year = fechaActual.getFullYear();
        filtro.value.estado = [
          constantes.POR_REVISAR,
          constantes.ENVIADO,
          constantes.APROBADO,
          constantes.OBSERVADO,
          constantes.FINALIZADO,
        ];
        const request = {
          year: filtro.value.year,
          month: filtro.value.mes,
          distribuidoras: filtro.value.dex,
        };
        listas_cargadas.value = true;
        definirActividadVenta();
        store
          .dispatch("liquidacion/obtenerLiquidacionesJOP", request)
          .then(() => {
            data_cargada.value = true;
          });
      }, 500);
    });
});

const distribuidoras_options = computed(() =>
  store.state.bonificacionUtil.distribuidoras.map((item) => {
    return {
      value: item.iddistribuidora,
      label: item.codigodistribuidora,
    };
  })
);
const anios_options = computed(() => {
  let currencyYear = new Date().getFullYear()
  let quantityYears = 0
  const list = []
  while (quantityYears < 4) {
    list.push({
      value: `${currencyYear - quantityYears}`,
      label: `${currencyYear - quantityYears}`,
    })
    quantityYears++;
  }

  return list
});
const meses_options = computed(() => [
  {
    value: 1,
    label: "Enero",
  },
  {
    value: 2,
    label: "Febrero",
  },
  {
    value: 3,
    label: "Marzo",
  },
  {
    value: 4,
    label: "Abril",
  },
  {
    value: 5,
    label: "Mayo",
  },
  {
    value: 6,
    label: "Junio",
  },
  {
    value: 7,
    label: "Julio",
  },
  {
    value: 8,
    label: "Agosto",
  },
  {
    value: 9,
    label: "Setiembre",
  },
  {
    value: 10,
    label: "Octubre",
  },
  {
    value: 11,
    label: "Noviembre",
  },
  {
    value: 12,
    label: "Diciembre",
  },
]);
const estados_options = computed(() => [
  {
    value: constantes.POR_REVISAR,
    label: "Por revisar",
  },
  {
    value: constantes.ENVIADO,
    label: "Enviado",
  },
  {
    value: constantes.APROBADO,
    label: "Aprobado",
  },
  {
    value: constantes.OBSERVADO,
    label: "observado",
  },
  {
    value: constantes.FINALIZADO,
    label: "Finalizado",
  },
]);

const mensajes = computed(() => store.state.liquidacion.mensajes);

const aplicarFiltros = () => {
  if (data_cargada.value == true) {
    data_cargada.value = false;
    const request = {
      year: filtro.value.year,
      month: filtro.value.mes,
      distribuidoras: filtro.value.dex,
    };
    store.dispatch("liquidacion/obtenerLiquidacionesJOP", request).then(() => {
      data_cargada.value = true;
    });
  }
};

const obtenerEncabezados = () => {
  const headers = [
    "Nombre",
    "Código Producto",
    "Producto bonificado",
    "Cantidad",
    "Costo Total S/",
  ];
  return headers;
};

const descargarPlantilla = () => {
  const headers = obtenerEncabezados();
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet([headers]);
  XLSX.utils.book_append_sheet(workbook, worksheet, "Plantilla");
  XLSX.writeFile(workbook, "plantilla_act_venta.xlsx");
};

const inputArchivo = ref("inputArchivo");

const cargarArchivo = () => {
  inputArchivo.value.click();
};

const limpiarExcelData = () => {
  datosExtraidos.value = null;
  liquidaciones_extraidas.value = [];
};

const handleFileChange = (event) => {
  limpiarExcelData();
  cargando_liquidaciones_excel.value = false;
  const archivo = event.target.files[0];
  const lector = new FileReader();

  lector.onload = async (e) => {
    const contenido = e.target.result;
    extraerColumnas(contenido);
    cargando_liquidaciones_excel.value = true;
  };
  lector.readAsBinaryString(archivo);
};

const datosExtraidos = ref(null);
const liquidaciones_extraidas = ref([]);
const cargando_liquidaciones_excel = ref(false);

const extraerColumnas = (contenido) => {
  const workbook = XLSX.read(contenido, { type: "binary" });
  const primeraHoja = workbook.Sheets[workbook.SheetNames[0]];
  const datos = XLSX.utils.sheet_to_json(primeraHoja, { header: 1 });
  const columnasDeseadas = obtenerEncabezados();
  const indicesColumnasDeseadas = columnasDeseadas.map((columna) =>
    datos[0].indexOf(columna)
  );

  datosExtraidos.value = datos.map((fila) =>
    indicesColumnasDeseadas.map((indice) => fila[indice])
  );
  datosExtraidos.value.shift();//eliminar headers
  liquidaciones_extraidas.value = datosExtraidos.value.map((item) => {
    return {
      codigo_bonificacion: "nuevo",
      nombre_bonificacion: item[0],
      codigoproductopr: `${item[1]}`,
      cantidadbhgr: item[3],
      costo_total_ajustado: item[4],
      tipo_bonificacion: constantes.TIPO_ACTV_VENTA,
      esta_creando: true,
      iddistribuidora: filtro.value.dex,
    };
  });
};

watch(
  () => filtro.value.mes,
  (nuevoValor, valorAnterior) => {
    aplicarFiltros();
  }
);

watch(
  () => filtro.value.year,
  (nuevoValor, valorAnterior) => {
    aplicarFiltros();
  }
);

watch(
  () => filtro.value.dex,
  (nuevoValor, valorAnterior) => {
    nueva_liquidacion.value.iddistribuidora = nuevoValor;
    aplicarFiltros();
  }
);

watch(
  () => nueva_liquidacion.value.esta_creando,
  (nuevoValor, valorAnterior) => {
    if (nuevoValor == false && valorAnterior == true) {
      aplicarFiltros();
    }
  }
);

watch(() => liquidaciones_extraidas.value.map(liquidacion => liquidacion.esta_creando), (nuevoEstado, estadoAnterior) => {
  aplicarFiltros();
});

watch(
  () => liquidaciones.value,
  (nuevoValor, valorAnterior) => {
    data_cargada.value = false;
    setTimeout(() => {
      data_cargada.value = true;
    }, 100);
  }
);
</script>

<style scoped>
.submit-btns {
  height: fit-content !important;
}
</style>
