<template>
  <div class="time-picker">
    <DatePicker
      v-model="modelValue"
      :config="parsedConfig"
      @input="handleSelectDate"
      @on-open="calendarHandler(true)"
      @on-close="calendarHandler(false)"
      ref="datePicker"
    />
  </div>
</template>
<script>
import { Spanish } from "flatpickr/dist/l10n/es.js";
import DatePicker from "vue-flatpickr-component";
export default {
  components: {
    DatePicker,
  },
  emits: ["update:modelValue"],
  props: {
    modelValue: String,
    config: {
      type: Object,
      default: {},
    },
    calendarClass: String,
    defaultDate: String,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  mounted() {
    if (this.calendarClass) {
      this.$refs["datePicker"].fp.calendarContainer.classList.add(
        this.calendarClass
      );
    }
  },
  computed: {
    parsedConfig() {
      return {
        altInput: true,
        locale: Spanish,
        enableTime: true,
        noCalendar: true,
        dateFormat: "h:i K",
        time_24hr: false,
        ...this.config,
      };
    },
  },
  methods: {
    handleSelectDate(ev) {
      this.$emit("update:modelValue", ev.target.value);
    },
    calendarHandler(open) {
      if (open) this.isOpen = open;
      else {
        setTimeout(() => {
          this.isOpen = open;
        }, 100);
      }
    },
    openCalendar() {
      this.$refs["datePicker"].fp.open();
    },
    clearDate() {
      const fp = this.$refs["datePicker"].fp;
      fp.clear();
    },
  },
};
</script>

<style scoped lang="scss">
.time-picker {
  width: 4rem;
  height: 1.25rem;
  border-bottom: 2px solid #b8b8b8;
}

.time-picker :deep(input::placeholder) {
  color: black;
  font-weight: inherit;
}

.time-picker :deep(input) {
  outline: none;
  height: 100%;
  border: 0;
  border: 0;
  font-weight: inherit;
  background: transparent;
  padding: 0;

  color: #1a1818;
  font-size: 0.75rem;
  text-align: center;
  font-weight: 300;
  line-height: 1.5rem;
}

i {
  cursor: pointer;
}
</style>
