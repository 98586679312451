<template>
  <div class="main-content bg-login">
    <!-- Header -->
    <div class="header py-7 py-lg-8">
      <div class="container">
        <div class="header-body text-center mb-6">
          <div class="row justify-content-center">
            <div class="col-12">
              <router-link class="navbar-brand mr-0" to="/">
                <img style="max-width:200px" :src="logo" class="navbar-brand-img" alt="..." />
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <router-view></router-view>
    </div>
    <footer class="py-5 d-none">
      <div class="container">
        <div class="row align-items-center justify-content-xl-between">
          <div class="col-xl-6">
            <div class="copyright text-center text-xl-left text-litgh-laive">
              &copy; {{ year }} Todos los derechos reservados <a href="/" class="ml-1·text-litgh-laive" target="_blank">Laive</a>
            </div>
          </div>
          <div class="col-xl-6">
            <div
              class="nav nav-footer justify-content-center justify-content-xl-end text-litgh-laive"
            >
              Desarrollador por ...
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  name: "auth-layout",
  data() {
    return {
      year: new Date().getFullYear(),
      showMenu: false,
    };
  },
  props: {
    logo: {
      type: String,
      default: "/img/brand/logo_laive.svg",
      description: "Sidebar app logo",
    },
  },
};
</script>
<style>
.header .header-body{
  padding-bottom: 0;;
}
</style>
