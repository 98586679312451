<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template v-slot:header>
            <div class="row">
              <div class="col-12 col-md-6">
                <h3 class="mb-0">Carga de Geocercas</h3>
              </div>
            </div>
          </template>
          <div>
            <div class="row px-3">
              <div class="col-12 col-md-10 mb-3">
                <form @submit.prevent="submitExcel">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <select class="form-control" style="height: 43px;" name="jerarquias_select" required @change="changeDistribuidora">
                          <option value="" disabled selected style="display:none">Seleccionar una Distribuidora</option>
                          <option value="0">Todas las distribuidoras</option>
                          <option
                          v-for="item in distribuidoras"
                          :value="item.idDistribuidora"
                          :key="item.idDistribuidora"
                          >
                          {{ item.razonSocial }}
                        </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <file-input required></file-input>
                      </div>
                    </div>
                  </div>
                  <div class="notes_editor">
                    <p><strong>Consideraciones del Archivo</strong></p>
                    <ul>
                      <li>El Excel debe contener los siguientes datos: <strong>id, Longitude, Latitude y Elevation</strong></li>
                    </ul>
                  </div>
                  <base-button type="primary" native-type="submit" class="btn-default my-0">Subir archivo</base-button>
                </form>
              </div>

            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BasePagination from "@/components/BasePagination";
import {mapGetters} from 'vuex';
import FileInput from "@/components/Inputs/FileInput";
import swal from "sweetalert2";
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
import DashboardNavbar from "../Layout/DashboardNavbar";
export default {
  components: {
    DashboardNavbar,
    BasePagination,
    RouteBreadCrumb,
    FileInput,
  },
  created(){
    const $this = this
    const token = localStorage.getItem("token")
    const config = {
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    };
    let urlApiDistribuidoras = process.env.VUE_APP_API_URL+"/distribuidoras/lista"
    axios.get(urlApiDistribuidoras,config)
    .then(function (response){
      $this.distribuidoras = response.data
      console.log(response.data, " ====lista de distribuidoras");
    })
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    }),
  },
  methods:{
    changeDistribuidora(evn){
      this.distribuidoraSelected = Number(evn.target.value)
    },
    submitExcel() {
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        }
      };
      var formData = new FormData();
      var excelfile = document.querySelector('#customFileLang');
      formData.append("file", excelfile.files[0]);
      formData.append("iddistribuidora", this.distribuidoraSelected)
      const urlUploadExcel = process.env.VUE_APP_API_URL+`/geocerca/carga-coordenadas`
      document.querySelector(".form_laive").classList.add("validateClient")
      axios.post(urlUploadExcel, formData, config)
      .then(function (response){
        console.log(response.status)
        const text = (response.status == 200) ? "Se cargaron las geocercas" : "Hubo en error en el archivo";
        const icon = "ni ni-bell-55";
        const content = {
          component: Notification,
          props: {
            icon: icon,
            text: text,
            type: (response.status == 200) ? "success" : "danger",
          },
        };
        const toast = useToast();
        toast(content, {
          hideProgressBar: true,
          icon: false,
          closeButton: false,
          position: "top-right",
        });
        excelfile.value = ""
        document.querySelector(".custom-file-label").innerText = "Seleccionar Archivo"
        document.querySelector(".form_laive").classList.remove("validateClient")
      })
    }
  },
  mounted() {
    if(this.user.rol == 3){
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
    if(this.user.rol == 4){
      this.supervisorIdCurrent = this.user.codigo
      this.supervisorNameCurrent = this.user.nombre
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
  },
  data() {
    return {
      distribuidoraSelected: "",
      files: [],
      month:[],
      distribuidoras: []
    };
  },
};
</script>
<style lang="scss">
</style>
