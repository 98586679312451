<template>
  <div>
    <div class="row mx-0">
      <Chip etiqueta="Dex:" :texto="dex"></Chip>
      <Chip etiqueta="Tipos de Vendedor:" :texto="tipo_vendedor"></Chip>
      <Chip etiqueta="Vendedor:" :texto="vendedor"></Chip>
      <Chip etiqueta="Giro:" :texto="giro"></Chip>
      <Chip etiqueta="Segmento:" :texto="segmento"></Chip>
      <Chip etiqueta="Tipo de Cliente:" :texto="tipo_cliente"></Chip>
    </div>
  </div>
</template>
<script setup>
import Chip from "./Chip.vue";

import { ref, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
const store = useStore();

const { bonificacion } = defineProps({
  bonificacion: {
    type: Object,
  },
});

onMounted(() => {});

const vendedores = computed(() => {
  let fuente = store.state.bonificacionUtil.vendedores;
  const distribuidoras =
    distribuidoras_como_objeto.value
      ? bonificacion.distribuidoras?.map((item) => `${item.id}`)
      : bonificacion.distribuidoras?.map((item) => `${item}`);

  const resultado = fuente.filter((item) => {
    const pertence_a_tipo = bonificacion.tipos_vendedor?.includes(
      item.tipovendedor.toUpperCase()
    );
    const incluido_en_dex = distribuidoras.includes(
      item.iddistribuidora.toString()
    );
    return pertence_a_tipo && incluido_en_dex;
  });
  return resultado;
});

const distribuidoras_options = computed(() =>
  store.state.bonificacionUtil.distribuidoras.map((item) => {
    return {
      value: item.iddistribuidora,
      label: item.codigodistribuidora,
    };
  })
);
const tipos_vendedor_options = computed(() =>
  store.state.bonificacionUtil.tiposVendedor.map((item) => {
    return {
      value: item.codigodato,
      label: item.nombre,
    };
  })
);
const vendedor_options = computed(() =>
  vendedores.value.map((item) => {
    return {
      value: `${item.iddistribuidora}-${item.codigovendedor}`,
      label: `${item.primernombre} ${item.apellidopaterno} ${item.apellidomaterno}`,
    };
  })
);
const giros_negocio_options = computed(() =>
  store.state.bonificacionUtil.girosNegocio.map((item) => {
    return {
      value: item.codigodato,
      label: item.nombre,
    };
  })
);
const segmentos_options = computed(() =>
  store.state.bonificacionUtil.segmentos.map((item) => {
    return {
      value: item.codigodato,
      label: item.nombre,
    };
  })
);
const tipos_cliente_options = computed(() =>
  store.state.bonificacionUtil.tiposCliente.map((item) => {
    return {
      value: item.codigodato,
      label: item.nombre,
    };
  })
);
const distribuidoras_como_objeto = computed(
  () => typeof bonificacion.distribuidoras[0] == "object"
);
const dex = computed(() => {
  return distribuidoras_como_objeto.value
    ? obtenerLabels(
        bonificacion.distribuidoras.map((item) => item.id),
        distribuidoras_options.value,
        'dex'
      )
    : obtenerLabels(bonificacion.distribuidoras, distribuidoras_options.value, 'dex');
});
const tipo_vendedor = computed(() => {
  return obtenerLabels(
    bonificacion.tipos_vendedor,
    tipos_vendedor_options.value
  );
});
const vendedor = computed(() => {
  return obtenerLabels(bonificacion.vendedores, vendedor_options.value);
});
const giro = computed(() => {
  return obtenerLabels(bonificacion.giros_negocio, giros_negocio_options.value);
});
const segmento = computed(() => {
  return obtenerLabels(bonificacion.segmentos, segmentos_options.value);
});
const tipo_cliente = computed(() => {
  return obtenerLabels(bonificacion.tipos_cliente, tipos_cliente_options.value);
});

const obtenerLabels = (seleccionados, fuente, tipo = '') => {
  seleccionados = seleccionados?.map((item) => item.toString());
  if (seleccionados?.length > 0) {
    if (seleccionados?.length >= fuente.length) {
      if(!tipo) return "Todos";
      else {
        if(fuente.length > 1) return "Todos";
      }
    }
    let result = [];
      fuente.forEach((element) => {
        if (seleccionados?.includes(element.value.toString())) {
          result.push(element.label);
        }
      });
      return result.join(", ");
  }
};
</script>
<style lang="scss" scoped></style>
