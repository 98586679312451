<template>
<div class='select-button'>
    <div>
      <a v-for="item in collection" class="mr-1" :select='item.select' @click='buttonSelectChange(item)'>{{item.text}}</a>
    </div>
</div>
</template>

<script>
export default {
    props: {
        collection: []
    },
    components: {},
    methods:{
      buttonSelectChange(i) {
        let group = this.collection.filter(x=>x != i);
        if(!group) return;
        for(let item of group){
          item.select = false;
        }
        i.select = true;
        this.$emit("change",'click',i);
      }
    }
};
</script>

<style lang="scss">
.select-button {
    label {
        display: block;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #6E6E6E;
    }

    div {
        display: flex;

        a {
            color: #FFFFFF;
            background: #007841;
            border-radius: 4px;
            align-items: center;
            padding: 5px 10px;
            text-align: center;
            font-style: normal;
            font-weight: 300;
            font-size: 11px;
            line-height: 16px;
            letter-spacing: 0.2px;
            cursor: pointer;
        }

        a:hover{
          border-color: #5e72e4!important;
        }

        [select="false"] {
            background: #FFFFFF;
            color: #6E6E6E !important;
            border: 0.5px solid #B8B8B8;
            box-sizing: border-box;
            border-radius: 4px;
        }
    }
}
</style>
