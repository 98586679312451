import { mapActions, mapGetters } from 'vuex';
import MesaService from '../services/MesaService';
import axios from 'axios';
export default { 
    namespaced: true,
    state:{},
    getters:{},
    mutations:{},
    methods:{ },
    actions:{
        async getList({state,commit},data) {
            return await MesaService.getList(axios,data);
        },
        async getListByDistribuidora({state,commit},data) {
            return await MesaService.getListByDistribuidora(axios,data);
        },
        
    }
}