/* eslint-disable prettier/prettier */ /* eslint-disable prettier/prettier */ /*
eslint-disable prettier/prettier */
<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <div class="card form_laive" :class="{ validateClient: loading }">
            <div class="box_loader">
              <div class="loader"></div>
            </div>
            <div class="card-header">
              <h3 class="mb-0">Parametros Stock Reservado</h3>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <form
                    @submit.prevent="
                      updateProdReco($event, 'minutos_antes_retornar_stock')
                    "
                    class="row"
                  >
                    <label class="col-md-12 col-form-label form-control-label">
                      Stock Reservado
                    </label>
                    <p class="col-md-12" style="font-size: 14px">
                      Número de minutos antes de considerar que un pedido no
                      está en uso
                    </p>
                    <div class="col-md-6">
                      <base-input
                        required
                        name="minutos_antes_retornar_stock"
                        v-model="minutos_antes_retornar_stock.valor"
                        placeholder="Minutos antes retomar stock"
                        @keypress="isNumber($event)"
                      >
                        <template v-slot:addonLeft>Min.</template>
                      </base-input>
                    </div>
                    <div class="col-md 6">
                      <base-button
                        type="primary"
                        native-type="submit"
                        class="btn-default my-0"
                        >Actualizar</base-button
                      >
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import DashboardNavbar from "../Layout/DashboardNavbar";
export default {
  components: {
    DashboardNavbar,
    RouteBreadCrumb,
  },
  created() {
    const $this = this;
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    this.loading = true;
    let urlFrecuenciaPR =
      process.env.VUE_APP_API_URL +
      "/parametro/lista/minutos_antes_retornar_stock";
    const getFrecuenciaPR = axios.get(urlFrecuenciaPR, config);

    axios
      .all([getFrecuenciaPR])
      .then(
        axios.spread((...responses) => {
          const resFrecuenciaPR = responses[0].data[0];
          $this.minutos_antes_retornar_stock = resFrecuenciaPR;
          $this.loading = false;
        })
      )
      .catch(() => {
        $this.loading = false;
      });
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
  mounted() {
    if (this.user.rol == 3) {
      this.distribuidoraIdCurrent = this.user.idDistribuidora;
    }
  },
  methods: {
    ...mapActions({
      getDistributorList: "distribuidora/getList",
    }),
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    updateProdReco(evn, name) {
      const $this = this;
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      let dataSend = "";
      if (name === "minutos_antes_retornar_stock") {
        dataSend = this.minutos_antes_retornar_stock;
      }
      this.loading = true;
      const urlApiUpdated =
        process.env.VUE_APP_API_URL + "/parametro/actualizar";
      axios
        .put(urlApiUpdated, dataSend, config)
        .then(function (response) {
          const text =
            response.status == 200
              ? "Se actulizaron los Minutos"
              : "Hubo un error";
          const icon = "ni ni-bell-55";
          const content = {
            component: Notification,
            props: {
              icon: icon,
              text: text,
              type: response.status == 200 ? "success" : "danger",
            },
          };
          const toast = useToast();
          toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
          $this.loading = false;
        })
        .catch(function (error) {
          console.log({ error });
          $this.loading = false;
        });
    },
  },
  data() {
    return {
      distribuidoraIdCurrent: null,
      loading: false,
      minutos_antes_retornar_stock: {},
    };
  },
};
</script>
