<template>
  <div class="row mx-0 mt-0 border-top">
    <div class="col-6 px-0">
      <div class="row mx-0 h-100">
        <div class="col-4 px-0">
          <div class="row mx-0 h-100">
            <div
              class="col-4 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              {{ distribuidora.iddistribuidora }}
            </div>
            <div
              class="col-8 px-1 font-10 py-2 text-center d-flex align-items-center"
            >
              {{ distribuidora.nombre_distribuidora }}
            </div>
          </div>
        </div>
        <div class="col-8 px-0">
          <div class="row mx-0 h-100">
            <div
              class="col-3 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              {{ formatearCantidades(cant_bonificable) }}
            </div>
            <div
              class="col-3 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              {{ formatearCantidades(cant_bonificada) }}
            </div>
            <div
              class="col-2 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              {{ eficacia }} %
            </div>
            <div
              class="col-2 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              {{ por_revisar }}
            </div>
            <div
              class="col-2 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              S/{{ formatearCantidades(costo_total) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 px-0">
      <div class="row mx-0 h-100">
        <div class="col-2 px-0">
          <div class="row mx-0 h-100">
            <div class="col-12 px-1 font-1 py-1 text-center d-flex align-items-center justify-content-center font-weight-600"
            :class=" aprobadas== distribuidora.liquidaciones.length ? 'bonificacion-primary-text': 'text-danger'">
              {{ aprobadas }}/{{ distribuidora.liquidaciones.length }}
              
            </div>
          </div>
        </div>
        <div class="col-2 px-0">
          <div class="row mx-0 h-100">
            <div
              class="col-12 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
            <ul class="my-1">
              <li v-for="memo in memos" :key="memo" class="bonificacion-primary-text">
                {{ memo }}
              </li>
            </ul>
            </div>
          </div>
        </div>
        <div class="col-6 px-0">
          <div class="row mx-0 h-100">
            <div
              class="col-12 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            ></div>
          </div>
        </div>
        <div class="col-2 px-0">
          <div class="row mx-0 h-100">
            <div
              class="col-6 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              <button
                type="button"
                @click.prevent="confirmarAprobacion"
                class="btn circle-btn-sm bonificacion-primary-text mx-auto p-0 d-flex align-items-center justify-content-center"
              >
                <i class="fas fa-check font-18"></i>
              </button>
            </div>

            <div
              class="col-6 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              <button
                type="button"
                class="btn circle-btn-sm bonificacion-primary-text mx-auto p-0 d-flex align-items-center justify-content-center"
                data-toggle="collapse"
                :data-target="`#dist-${distribuidora.iddistribuidora}`"
              >
                <i class="fas fa-chevron-circle-down"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="collapse row mx-0 px-1 mb-2"
    :id="`dist-${distribuidora.iddistribuidora}`"
  >
    <div class="col-12 px-0 rounded border border-gray overflow-hidden">
      <TableJOP
        :liquidaciones="distribuidora.liquidaciones"
        :subgerente="true"
      ></TableJOP>
    </div>
  </div>
</template>

<script setup>
import Chip from "../../../Bonificaciones/components/Chip.vue";
import TableJOP from "../../JefeOperaciones/components/Table.vue";
import { ref, computed, watch, onMounted } from "vue";
import constantes from "@/model/constantes.js";
import Swal from "sweetalert2";

import { useStore } from "vuex";
const store = useStore();
const { distribuidora } = defineProps({
  distribuidora: {
    type: Object,
  },
});

const cant_bonificable = computed(() => {
  const resultado = distribuidora.liquidaciones.reduce(
    (acumulador, objeto) => acumulador + parseFloat(objeto.cant_bonificable),
    0
  );
  return resultado;
});
const cant_bonificada = computed(() => {
  const resultado = distribuidora.liquidaciones.reduce(
    (acumulador, objeto) =>
      acumulador +
      parseFloat(
        objeto.cant_bonificada_ajustada ?? objeto.cant_bonificada ?? 0
      ),
    0
  );
  return resultado;
});
const eficacia = computed(() => {
  let resultado = 0;
  const cantbonificable = parseFloat(cant_bonificable.value);
  const cantbonificada = parseFloat(cant_bonificada.value);
  if (cantbonificable > 0) {
    if (cantbonificada > 0) {
      if (cantbonificada >= cantbonificable) {
        resultado = 100;
      } else {
        resultado = ((cantbonificada * 100) / cantbonificable).toFixed(2);
      }
    }
  }
  return resultado;
});
const por_revisar = computed(() => {
  const resultado = cant_bonificada.value - cant_bonificable.value;
  return resultado < 0 ? 0 : resultado;
});
const costo_total = computed(() => {
  const resultado = distribuidora.liquidaciones.reduce(
    (acumulador, objeto) =>
      acumulador +
      parseFloat(objeto.costo_total_ajustado ?? objeto.costo_total ?? 0),
    0
  );
  return resultado;
});
const aprobadas = computed(() => {
  var liquidaciones_aprobadas = distribuidora.liquidaciones.filter(
    (item) => item.estado == constantes.APROBADO
  );
  return liquidaciones_aprobadas.length;
});
const memos = computed(() => {
return distribuidora.liquidaciones
    .filter(objeto => objeto.codigo_memo !== null) // Filtra los objetos con código no nulo
    .map(objeto => objeto.codigo_memo) // Extrae los códigos
    .filter((codigo_memo, index, array) => array.indexOf(codigo_memo) === index); // Filtra códigos no repetidos

});

const formatearCantidades = (param) => {
  const cantidad = parseFloat(param);
  return cantidad.toLocaleString("en-US");
};

const esta_actualizando = ref(false);

const inicializarMensaje = () => {
  return {
    id: `msj_${new Date().getTime()}`,
    opacidad: 1,
  };
};

const mostrarMensaje = (mensaje) => {
  store.commit("liquidacion/agregarMensaje", mensaje);
  setTimeout(() => {
    store.commit("liquidacion/cambiarOpacity", mensaje.id);
    store.commit("liquidacion/eliminarMensaje", mensaje.id);
  }, 2000);
};


const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-success",
    cancelButton: "btn btn-danger",
  },
  buttonsStyling: false,
});

const confirmarAprobacion = ()=>{
swalWithBootstrapButtons
    .fire({
      title: "Aprobar",
      text: `Aprobará los items que se encuentren en estado "Pendiente" ¿Está seguro de esta acción?`,
      showCancelButton: true,
      confirmButtonText: "Aprobar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    })
    .then((result) => {
      if (result.isConfirmed) {
        aprobarEnviados();
      }
    });
};

const aprobarEnviados=()=>{
    const liquidaciones = distribuidora.liquidaciones.filter(item => item.estado == constantes.ENVIADO);
    liquidaciones.forEach(liquidacion => {
      enviarLiquidacion(liquidacion);
    });
}

const enviarLiquidacion = (liquidacion) => {
  esta_actualizando.value = true;
  const mensaje = inicializarMensaje();

  const payload = {
    idbonificacionbonhistoricodistribuidora:
      liquidacion.idbonificacionbonhistoricodistribuidora,
    rango_promo_inicio: liquidacion.rango_promo_inicio,
    rango_promo_fin: liquidacion.rango_promo_fin,
    estado: constantes.APROBADO,
  };

  store.dispatch("liquidacion/actualizarEstado", payload).then((result) => {
    if (result.status == 200) {
      mensaje.clase = "chip-message-success";
      mensaje.texto = "Cambios guardados exitosamente.";
      liquidacion.estado = constantes.APROBADO;
    } else {
      mensaje.clase = "chip-message-error";
      mensaje.texto = "Ocurrió un error.";
    }
    esta_actualizando.value = false;
    mostrarMensaje(mensaje);
  });
};
</script>

<style scoped>
.btn:disabled {
  color: #b8b8b8 !important;
  background: transparent !important;
}
</style>
