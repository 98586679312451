<template>
  <div
    class="card-stack-container d-flex d-flex justify-content-center"
    :id="componenteId"
  >
    <div class="card-stack">
      <ul class="card-list">
        <li
          class="stacked-card font-9"
          :class="{
            'no-aplica': !show_activas,
            [fecha.clases]: fecha.clases,
          }"
          :style="`filter: brightness(${fecha.brillo}); width: ${fecha.ancho}%`"
          @click="mostrarSiguiente"
          v-for="(fecha, index) in fechas_unicas"
          :key="fecha"
        >
          <div class="row mx-0">
            <div class="col-12 px-0" v-if="fecha.aun_no_inicia">
              Fecha inicio:
            </div>
            <div class="col-12 px-0">
              {{ fecha.text }}
            </div>
            <div class="col-12 px-0" v-if="fecha.dias_restantes!=null">
              {{ fecha.dias_restantes }} días
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, watch, onMounted } from "vue";

const { fechas, show_activas } = defineProps({
  fechas: {
    type: Array,
  },
  show_activas: {
    type: Boolean,
    default: true,
  },
});
const componenteId = ref(`componente_stackedcards_${new Date().getTime()}`);

const mostrarSiguiente = () => {
  const firstElement = fechas_filtradas.value.shift();
  fechas_filtradas.value.push(firstElement);
};

const fechas_filtradas = ref([]);

onMounted(() => {
  const cantidad_maxima = 5;
  const fecha_actual = new Date();

  let filtradas = [];

  fechas.forEach((item) => {
    const existe = filtradas.find(
      (filtrada) => filtrada.inicio == item.inicio && filtrada.fin == item.fin
    );
    if (!existe) {
      filtradas.push(item);
    }
  });

  if (show_activas) {
    fechas_filtradas.value = filtradas
      .map((item, index) => {
        const inicio = new Date(item.inicio);
        const fin = new Date(item.fin);
        const aun_no_inicia = fecha_actual < inicio;
        const texto = aun_no_inicia
          ? `${formatearFecha(inicio)}`
          : formatearFecha(fin);
        const dias_restantes = aun_no_inicia
          ? null
          : obtenerDiasRestantes(fecha_actual, fin);

        const clases = aun_no_inicia
          ? "futura"
          : obtenerClaseSegunRestante(inicio, fin, fecha_actual);

        if (index + 1 <= cantidad_maxima) {
          return {
            text: texto,
            aun_no_inicia,
            dias_restantes: dias_restantes,
            clases,
          };
        }
      })
      .filter((item) => !!item)
      .reverse();
  } else {
    fechas_filtradas.value = filtradas
      .map((item, index) => {
        const incio = new Date(item.inicio);
        const fin = new Date(item.fin);
        if (index + 1 <= cantidad_maxima) {
          return {
            text: `${formatearFecha(incio)} al ${formatearFecha(fin)}`,
            dias_restantes: null,
          };
        }
      })
      .filter((item) => !!item)
      .reverse();
  }
});

const obtenerClaseSegunRestante = (inicio, fin, fecha_actual) => {
  const total = fin - inicio;
  const actual = fecha_actual - inicio;
  const progreso = (actual * 100) / total;
  if (progreso < 50) {
    return "a-tiempo";
  }
  if (progreso < 75) {
    return "medio";
  }
  return "por-culminar";
};

const obtenerDiasRestantes = (fecha_actual, fecha_objetivo) => {
  const milisegundos = fecha_objetivo - fecha_actual;
  return Math.ceil(milisegundos / (1000 * 60 * 60 * 24));
};

const fechas_unicas = computed(() => {
  return fechas_filtradas.value.map((item, index) => {
    const total_fechas = fechas_filtradas.value.length;
    const brillo = 1 - 0.1 * (total_fechas - (index + 1));
    const ancho = 100 - 5 * (total_fechas - index + 1);
    return {
      text: item.text,
      dias_restantes: item.dias_restantes,
      brillo,
      ancho,
      aun_no_inicia: item.aun_no_inicia,
      clases: item.clases,
    };
  });
});

const formatearFecha = (fecha) => {
  if (!fecha) {
    return "-";
  }
  const meses = [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ];

  const dia = fecha.getDate();
  const mes = meses[fecha.getMonth()];
  const anio = fecha.getFullYear();
  const dia_st = dia < 10 ? `0${dia}` : dia;
  return `${dia_st} ${mes} ${anio}`;
};
</script>
<style lang="scss" scoped>
$default-diff: 3px;
$default-width: 100%;
$default-min: 5%;
$total-elements: 5;

.card-stack-container {
  width: 100%;
  background-color: #fff;

  .card-stack {
    width: 80px;
    height: 50px;
    position: relative;
    .card-list {
      width: 90px;

      li {
        cursor: pointer;
        width: 80px;
        height: 32px;
        transition: all 100ms ease-in-out;
        border-radius: 8px;
        position: absolute;
        list-style: none;
        // -webkit-box-shadow: 0 2px 15px 1px rgba(225, 225, 225, 0.208);
        // box-shadow: 0 1px 4px 1px rgba(207, 207, 207, 0.5);
        left: 0;
        right: 0;
        margin: 0 auto;
        padding-top: 5px;
        text-align: center;
        background-size: cover;
      }
      .a-tiempo {
        background-color: #e6f0ff;
      }
      .medio {
        color: #f78d2c;
        background-color: #fff3e1;
      }
      .por-culminar {
        color: #cb2937;
        background-color: #ffeaec;
      }
      .futura {
        background-color: white;
        padding-top: 3px;
        border: 1px solid #038540;
        color: #038540;
      }
      .no-aplica {
        background-color: transparent;
      }

      @for $i from 1 through $total-elements {
        li:nth-child(#{$i}) {
          bottom: $default-diff * ($i + 1);
        }
      }
    }
  }
}

.transformThis {
  animation: scaleDown 500ms;
}

@keyframes scaleUp {
  0% {
    transform: scale(1.2) translateY(50px);
    opacity: 0;
  }
  20% {
    transform: scale(1.15) translateY(40px);
    opacity: 0.1;
  }
  40% {
    transform: scale(1.1) translateY(30px);
    opacity: 0.2;
  }
  60% {
    transform: scale(1.05) translateY(20px);
    opacity: 0.4;
  }
  80% {
    transform: scale(1.01) translateY(10px);
    opacity: 0.8;
  }
  100% {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
}

@keyframes scaleDown {
  0% {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
  20% {
    transform: scale(1.01) translateY(20px);
    opacity: 0.8;
  }
  40% {
    transform: scale(1.05) translateY(40px);
    opacity: 0.4;
  }
  60% {
    transform: scale(1.1) translateY(60px);
    opacity: 0.2;
  }
  80% {
    transform: scale(1.15) translateY(80px);
    opacity: 0.1;
  }
  100% {
    transform: scale(1.2) translateY(100px);
    opacity: 0;
  }
}

@keyframes scaleCard {
  0% {
    top: 5px;
  }
  100% {
    top: 24px;
  }
}

@keyframes bounceInLeft {
  0% {
    opacity: 0;
    transform: translateX(40px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(-40px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
