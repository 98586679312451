<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <!-- HTML5 inputs -->
            <div class="card mb-0">
              <!-- Card header -->
              <div class="card-header">
                <h3 class="mb-0">Estos campos se reflejarán en la aplicación</h3>
              </div>
              <!-- Card body -->
              <div class="card-body" style="overflow: hidden;">
                <div class="row">
                  <div class="col-md-6">
                    <form @submit.prevent="submitSemaforo" class="form_laive validateClient semaforo">
                      <div class="box_loader">
                        <div class="loader"></div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <label class="col-form-label form-control-label">Rango de Devolución - Verde</label>
                          <div class="form-group">
                            <div class="row">
                              <div class="col-md-6">
                                <base-input
                                  required
                                  name="rango_devol_green_min"
                                  placeholder="Mínmo"
                                  v-model="rango_devol_green.min"
                                  inputClasses="green"
                                  @keypress="isNumber($event)"
                                ></base-input>
                              </div>
                              <div class="col-md-6">
                                <base-input
                                  required
                                  name="rango_devol_green_max"
                                  placeholder="Máximo"
                                  v-model="rango_devol_green.max"
                                  inputClasses="green"
                                  @keypress="isNumber($event)"
                                ></base-input>
                              </div>
                            </div>
                            <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar Devolución - Verde</base-button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="col-md-6">
                    <form @submit.prevent="submitSemaforo" class="form_laive validateClient semaforo">
                      <div class="box_loader">
                        <div class="loader"></div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <label class="col-form-label form-control-label">Rango de Devolución - Amarillo</label>
                          <div class="form-group">
                            <div class="row">
                              <div class="col-md-6">
                                <base-input
                                  required
                                  name="rango_devol_yellow_min"
                                  placeholder="Mínmo"
                                  v-model="rango_devol_yellow.min"
                                  inputClasses="yellow"
                                  @keypress="isNumber($event)"
                                ></base-input>
                              </div>
                              <div class="col-md-6">
                                <base-input
                                  required
                                  name="rango_devol_yellow_max"
                                  placeholder="Máximo"
                                  v-model="rango_devol_yellow.max"
                                  inputClasses="yellow"
                                  @keypress="isNumber($event)"
                                ></base-input>
                              </div>
                            </div>
                            <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar Devolución - Amarillo</base-button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="col-md-6">
                    <form @submit.prevent="submitSemaforo" class="form_laive validateClient semaforo">
                      <div class="box_loader">
                        <div class="loader"></div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <label class="col-form-label form-control-label">Rango de Devolución - Rojo</label>
                          <div class="form-group">
                            <div class="row">
                              <div class="col-md-12">
                                <base-input
                                  required
                                  name="rango_devol_red"
                                  placeholder="Valor"
                                  v-model="rango_devol_red.valor"
                                  inputClasses="red"
                                  @keypress="isNumber($event)"
                                ></base-input>
                              </div>
                            </div>
                            <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar Devolución - Rojo</base-button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <form @submit.prevent="submitDevolucion" class="form_laive validateClient semaforo">
                      <div class="box_loader">
                        <div class="loader"></div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <label class="col-form-label form-control-label">Periodo para Calcular Devoluciones - en Semanas</label>
                          <div class="form-group">
                            <base-input
                              required
                              name="periodo_devoluciones"
                              placeholder="Cantidad de devoluciones"
                              v-model="periodo_devoluciones.valor"
                              @keypress="isNumber($event)"
                            ></base-input>
                            <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar cantidad de Devoluciones</base-button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="col-md-6">
                    <form @submit.prevent="submitDevolucionHorario" class="form_laive validateClient">
                      <div class="box_loader">
                        <div class="loader"></div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <label class="col-form-label form-control-label">Configuración del horario de la Devolución</label>
                          <div class="form-group">
                            <div class="row">
                              <div class="col-md-6">
                                <base-input name="devolucion_horario_inicio">
                                  <template v-slot:addonLeft>Inicio</template>
                                  <flat-picker :config="configDate" placeholder="Horario de Inicio" class="form-control datepicker" v-model="devolucionhorario.inicio"></flat-picker>
                                </base-input>
                              </div>
                              <div class="col-md-6">
                                <base-input name="devolucion_horario_cierre">
                                  <template v-slot:addonLeft>Cierre</template>
                                  <flat-picker :config="configDate" placeholder="Horario de Cierre" class="form-control datepicker" v-model="devolucionhorario.cierre"></flat-picker>
                                </base-input>
                              </div>
                            </div>
                            <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar Horario de Devoluciones</base-button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from "axios";
  import { useToast } from "vue-toastification";
  import Notification from "@/components/Notification";
  import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
  import DashboardNavbar from "../Layout/DashboardNavbar";
  import flatPicker from "vue-flatpickr-component";
  import {Spanish} from 'flatpickr/dist/l10n/es.js';
  import "flatpickr/dist/flatpickr.css";
  export default {
    components: {
      DashboardNavbar,
      RouteBreadCrumb,
      flatPicker,
    },
    created(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const urlApiParameters = process.env.VUE_APP_API_URL+"/parametro/lista"
      const urlApiDevolucionHorario = process.env.VUE_APP_API_URL+"/parametro/devolucion/horario"
      const requestParameters = axios.post(urlApiParameters,"",config);
      const requestDevolucionHorario = axios.get(urlApiDevolucionHorario,config);
      axios.all([requestParameters, requestDevolucionHorario]).then(axios.spread((...responses) => {
          const data = responses[0].data
          const dataDevolucionHorario = responses[1].data
          // Devolucion Verde
          let rango_devol_green = data.filter((item)=>{return item.codigoParametro == "rango_devol_green"})
          $this.rango_devol_green.idParametro = rango_devol_green[0].idParametro
          $this.rango_devol_green.codigoParametro = rango_devol_green[0].codigoParametro
          $this.rango_devol_green.valor = rango_devol_green[0].valor
          let valorMinMaxGreen = rango_devol_green[0].valor.split("-");
          if( valorMinMaxGreen.length == 2){
            $this.rango_devol_green.min = valorMinMaxGreen[0]
            $this.rango_devol_green.max = valorMinMaxGreen[1]
          }
          // Devolucion Rojo
          let rango_devol_red = data.filter((item)=>{return item.codigoParametro == "rango_devol_red"})
          $this.rango_devol_red.idParametro = rango_devol_red[0].idParametro
          $this.rango_devol_red.codigoParametro = rango_devol_red[0].codigoParametro
          $this.rango_devol_red.valor = rango_devol_red[0].valor
          let valorMinMaxRed = rango_devol_red[0].valor.split("-");
          if( valorMinMaxRed.length == 2){
            $this.rango_devol_red.min = valorMinMaxRed[0]
            $this.rango_devol_red.max = valorMinMaxRed[1]
          }
          // Devolucion Amarillo
          let rango_devol_yellow = data.filter((item)=>{return item.codigoParametro == "rango_devol_yellow"})
          $this.rango_devol_yellow.idParametro = rango_devol_yellow[0].idParametro
          $this.rango_devol_yellow.codigoParametro = rango_devol_yellow[0].codigoParametro
          $this.rango_devol_yellow.valor = rango_devol_yellow[0].valor
          let valorMinMaxYellow = rango_devol_yellow[0].valor.split("-");
          if( valorMinMaxYellow.length == 2){
            $this.rango_devol_yellow.min = valorMinMaxYellow[0]
            $this.rango_devol_yellow.max = valorMinMaxYellow[1]
          }
          // Periodo Devoluciones
          let periodo_devoluciones = data.filter((item)=>{return item.codigoParametro == "periodo_devoluciones"})
          $this.periodo_devoluciones.idParametro = periodo_devoluciones[0].idParametro
          $this.periodo_devoluciones.codigoParametro = periodo_devoluciones[0].codigoParametro
          $this.periodo_devoluciones.valor = periodo_devoluciones[0].valor

          // Horario Devolución Devoluciones
          $this.devolucionhorario.inicio = dataDevolucionHorario.inicio
          $this.devolucionhorario.cierre = dataDevolucionHorario.cierre

          const getFormsLaive = document.querySelectorAll(".form_laive")
          getFormsLaive.forEach(element => {
            element.classList.remove("validateClient")
          });
        })).catch(errors => {
          // react on errors.
        })
    },
    methods: {
      isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault();;
        } else {
          return true;
        }
      },
      submitSemaforo(evn) {
        const $parent = evn.target;
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        const getGreen = evn.target.querySelector(".green")
        const getYellow = evn.target.querySelector(".yellow")
        const getRed = evn.target.querySelector(".red")
        $parent.classList.add("validateClient")
        const urlApiUpdated=process.env.VUE_APP_API_URL+"/parametro/actualizar"
        let dataSend = null;
        if(getGreen){
          dataSend = {
            "idParametro": this.rango_devol_green.idParametro,
            "codigoParametro": this.rango_devol_green.codigoParametro,
            "valor": this.rango_devol_green.min + "-" + this.rango_devol_green.max
          }
          console.log({dataSend})
        }
        if(getYellow){
          dataSend = {
            "idParametro": this.rango_devol_yellow.idParametro,
            "codigoParametro": this.rango_devol_yellow.codigoParametro,
            "valor": this.rango_devol_yellow.min + "-" + this.rango_devol_yellow.max
          }

        }
        if(getRed){
          dataSend = this.rango_devol_red
        }
        axios.put(urlApiUpdated,dataSend,config)
        .then(function (response){
          $parent.classList.remove("validateClient")
          const text =(response.status == 200) ? "Se actualizó el porcentaje de Devolución " : "Hubo un error";
          const icon ="ni ni-bell-55";
          const content={
            component: Notification,
            props: {
              icon: icon,
              text: text,
              type: (response.status == 200) ? "success" : "danger",
            },
          };
          const toast = useToast();
          toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
        }).catch(function(error){
          console.log({error});
        })
      },
      submitDevolucion(evn) {
        const $parent = evn.target;
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        $parent.classList.add("validateClient")
        let dataSend = this.periodo_devoluciones
        const urlApiUpdated=process.env.VUE_APP_API_URL+"/parametro/actualizar"
        axios.put(urlApiUpdated,dataSend,config)
        .then(function (response){
          $parent.classList.remove("validateClient")
          const text =(response.status == 200) ? "Se actualizó el periodo de Devolución " : "Hubo un error";
          const icon ="ni ni-bell-55";
          const content={
            component: Notification,
            props: {
              icon: icon,
              text: text,
              type: (response.status == 200) ? "success" : "danger",
            },
          };
          const toast = useToast();
          toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
        }).catch(function(error){
          console.log({error});
        })
      },
      submitDevolucionHorario(evn) {
        const $parent = evn.target;
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        $parent.classList.add("validateClient")
        let dataSend = this.devolucionhorario
        const urlApiUpdated=process.env.VUE_APP_API_URL+"/parametro/devolucion/horario/actualizar"
        axios.post(urlApiUpdated,dataSend,config)
        .then(function (response){
          $parent.classList.remove("validateClient")
          const text =(response.status == 200) ? "Se actualizó el Horario de Devolución " : "Hubo un error";
          const icon ="ni ni-bell-55";
          const content={
            component: Notification,
            props: {
              icon: icon,
              text: text,
              type: (response.status == 200) ? "success" : "danger",
            },
          };
          const toast = useToast();
          toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
        }).catch(function(error){
          console.log({error});
        })
      },

    },
    data() {
      return {
        configDate: {
          altInput: false,
          allowInput: true,
          noCalendar: true,
          enableTime: true,
          locale: Spanish, // locale for this instance only
        },
        rango_devol_green: {
          idParametro: 11,
          codigoParametro: "rango_devol_green",
          valor: "0-0.5",
          min:"0",
          max:"0.5",
        },
        rango_devol_yellow: {
          idParametro: "",
          codigoParametro: "",
          valor: "",
          min:"",
          max:"",
        },
        rango_devol_red: {
          idParametro: "",
          codigoParametro: "",
          valor: "",
          min:"",
          max:"",
        },
        periodo_devoluciones: {
          idParametro: "",
          codigoParametro: "",
          valor: ""
        },
        devolucionhorario: {
          inicio: "",
          cierre: "",
        },
      };
    },
  };
</script>
<style lang="scss">
  .semaforo{
    .red{
      background-color: rgb(255, 245, 245);
    }
    .yellow{
      background-color: rgb(254, 255, 245);
    }
    .green{
      background-color: rgb(245, 255, 248);
    }
  }
</style>
