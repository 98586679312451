<template>
  <div class="wrapper">
    <!-- <notifications></notifications> -->
    <side-bar class="icons_laive">
      <template v-slot:links>
        <sidebar-item
          :link="{
            name: 'Home',
            path: '/dashboard',
            icon: 'fas fa-home text-laive',
          }"
        ></sidebar-item>
        <sidebar-item
          v-if="user.rol !== 8"
          :link="{
            name: 'Visualizacion en tiempo real',
            icon: 'fas fa-map-marked-alt text-laive',
            path: '/tiemporeal',
          }"
        ></sidebar-item>

        <sidebar-item
          v-if="user.rol !== 8"
          :link="{
            name: 'Notificaciones',
            icon: 'ni ni-bell-55 text-laive',
            path: '/notificaciones/v2',
          }"
        ></sidebar-item>

        <sidebar-item
          v-if="user.rol !== 8"
          :link="{ name: 'Lista de Pedidos', icon: 'ni ni-pedidos text-laive' }"
        >
          <sidebar-item
            :link="{ name: 'Por Pedido', path: '/pedidos/listado' }"
          />
          <sidebar-item
            :link="{ name: 'Por Recomendacion', path: '/pedidos/estadisticas' }"
          />
          <sidebar-item
            :link="{ name: 'Por Categoria', path: '/pedidos/reporte' }"
          />
          <sidebar-item
            :link="{
              name: 'Por pedidos truncos',
              path: '/pedidos/promociones-truncas',
            }"
          />
          <sidebar-item
            :link="{
              name: 'No Pedidos',
              path: '/nopedidos',
              icon: 'ni ni-pedidos text-laive',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Pedidos no procesados',
              path: '/pedidos-no-procesados',
              icon: 'ni ni-pedidos text-laive',
            }"
          />
          <sidebar-item
            :link="{ name: 'Devoluciones', path: '/devoluciones/listado' }"
          />
        </sidebar-item>

        <sidebar-item
          v-if="user.rol !== 8"
          :link="{
            name: 'Lista de Cobranzas',
            icon: 'ni ni-cobranzas text-laive',
          }"
        >
          <sidebar-item
            :link="{ name: 'Por Documentos', path: '/cobranzas/listado' }"
          />
          <sidebar-item
            :link="{ name: 'Por Vendedor', path: '/cobranzas/reporte' }"
          />
          <sidebar-item
            :link="{ name: 'Por Depositos', path: '/depositos/listado' }"
          />
        </sidebar-item>

        <sidebar-item
          v-if="user.rol !== 8"
          :link="{
            name: 'Lista de Hoja de Ruta',
            icon: 'fas fa-map-marked-alt text-laive',
          }"
        >
          <sidebar-item
            :link="{
              name: 'Recorrido Sugerido',
              path: '/hoja-de-ruta/ruta-recorrida',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Recorrido Real',
              path: '/hoja-de-ruta/ubicacion-recorrida',
            }"
          />
        </sidebar-item>

        <sidebar-item
          v-if="user.rol !== 8"
          :link="{
            name: 'Listado de archivos cargados',
            icon: 'ni ni-reporte text-laive',
          }"
        >
          <sidebar-item
            :link="{
              name: 'Productos foco',
              path: '/maestro-productos/listado-productos-foco',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Productos especiales',
              path: '/maestro-productos/listado-productos-especiales',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Productos refrigerados',
              path: '/maestro-productos/listado-productos-refrigerados',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Productos recomendados',
              path: '/maestro-productos/listado-productos-recomendados',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Productos en prioridad',
              path: '/maestro-productos/productos-prioridad',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Efectividad y Surtidos',
              path: '/reportes/surtidos-vendedor',
            }"
          />
          <!-- <sidebar-item :link="{ name: 'Productos cluster', path: '/maestro-productos/listado-productos-cluster' }"/> -->
        </sidebar-item>

        <sidebar-item
          v-if="user.rol !== 8"
          :link="{ name: 'Reportes', icon: 'ni ni-reporte text-laive' }"
        >
          <sidebar-item
            :link="{
              name: 'Ventas por Vendedor',
              path: '/reportes/ventas-vendedor',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Ventas por Categoria',
              path: '/reportes/ventas-categoria',
            }"
          />
          <sidebar-item
            :link="{ name: 'Efectividad', path: '/reportes/visitas-vendedor' }"
          />
          <sidebar-item
            :link="{
              name: 'Crecimiento',
              path: '/reportes/cobertura-crecimiento-vendedor',
            }"
          />
          <sidebar-item
            :link="{ name: 'Cobranza', path: '/reportes/cobros-mes' }"
          />
          <sidebar-item
            :link="{
              name: 'Cobertura Simple',
              path: '/reportes/cobertura-vendedor',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Devoluciones por Vendedor',
              path: '/reportes/maximo-devoluciones-vendedor',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Devoluciones por Categoria',
              path: '/reportes/maximo-devoluciones-categoria',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Ticket Esperado',
              path: '/reportes/ticket-esperado',
            }"
          />
          <sidebar-item
            :link="{ name: 'Promociones', path: '/reportes/promociones' }"
          />
          <sidebar-item
            :link="{
              name: 'Recomendaciones por vendedor',
              path: '/maestro-productos/recomendaciones-vendedor',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Recomendaciones por categoria',
              path: '/maestro-productos/recomendaciones-categoria',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Exportar reporte de productos recomendados',
              path: '/maestro-productos/exportar-productos-recomendados',
            }"
          />
        </sidebar-item>
        <sidebar-item
          v-if="
            user.rol === 1 ||
            user.rol === 2 ||
            user.rol === 3 ||
            user.rol === 4 ||
            user.rol === 8
          "
          :link="{ name: 'Equipos de Frio', icon: 'ni ni-reporte text-laive' }"
        >
          <sidebar-item
            v-if="
              user.rol === 1 ||
              user.rol === 2 ||
              user.rol === 3 ||
              user.rol === 4 ||
              user.rol === 8
            "
            :link="{
              name: 'Maestro de activos',
              path: '/equipos-frio/maestro-activos',
            }"
          />
          <sidebar-item
            v-if="
              user.rol === 1 ||
              user.rol === 2 ||
              user.rol === 3 ||
              user.rol === 4 ||
              user.rol === 8
            "
            :link="{
              name: 'Incidencias',
              path: '/equipos-frio/incidencias',
            }"
          />
          <sidebar-item
            v-if="
              user.rol === 1 ||
              user.rol === 2 ||
              user.rol === 3 ||
              user.rol === 4 ||
              user.rol === 8
            "
            :link="{
              name: 'Solicitudes',
              path: '/equipos-frio/solicitudes',
            }"
          />
          <sidebar-item
            v-if="user.rol === 1 || user.rol === 2"
            :link="{
              name: 'Pedido mínimo',
              path: '/equipos-frio/pedido-minimo',
            }"
          />
          <!--<sidebar-item :link="{ name: 'Listado de Incidencias', path: '/incidencias/lista'}"/>
          <template v-if="user.rol < 3">
            <sidebar-item :link="{ name: 'Actualizar estados Incidencias', path: '/incidencias/actualizar-estados' }" />
          </template>
          <sidebar-item :link="{ name: 'Listado de Clientes con Equipos', path: '/equipos-frio' }" />
          <template v-if="user.rol < 3">
            <sidebar-item :link="{ name: 'Carga de Equipo de Frio', path: '/clientes/cargar-equipos-frio' }"/>
          </template>-->
        </sidebar-item>

        <sidebar-item
          v-if="user.rol !== 8"
          :link="{
            name: 'Encuestas y Formularios',
            icon: 'ni ni-users text-laive',
          }"
        >
          <template v-if="user.rol < 3">
            <sidebar-item
              :link="{
                name: 'Crear Encuestas',
                path: '/formularios-encuestas/reporte-encuestas/crear',
              }"
            />
          </template>
          <sidebar-item
            :link="{
              name: 'Listado de Encuestas',
              path: '/formularios-encuestas/reporte-encuestas',
            }"
          />
        </sidebar-item>

        <template v-if="user.rol < 3">
          <sidebar-item
            :link="{
              name: 'Carga de archivos',
              icon: 'ni ni-reporte text-laive',
            }"
          >
            <sidebar-item
              :link="{
                name: 'Carga de ventas',
                path: '/reportes/ventas-objetivos-vendedor',
              }"
            />
            <sidebar-item
              :link="{
                name: 'Carga de cobertura simple',
                path: '/reportes/cobertura-import-pesos',
              }"
            />
            <sidebar-item
              :link="{
                name: 'Carga de maximo de devoluciones',
                path: '/reportes/maximo-devoluciones-objetivos',
              }"
            />
            <sidebar-item
              :link="{
                name: 'Carga de efectividad y surtidos',
                path: '/reportes/surtidos-porcentajes',
              }"
            />
            <sidebar-item
              :link="{
                name: 'Carga de promociones',
                path: '/maestro-productos/promociones',
              }"
            />
            <sidebar-item
              :link="{
                name: 'Carga de productos imperdonables',
                path: '/maestro-productos/carga-productos-imperdonables',
              }"
            />
            <sidebar-item
              :link="{
                name: 'Carga de prod especiales',
                path: '/maestro-productos/carga-productos-especiales',
              }"
            />
            <sidebar-item
              :link="{
                name: 'Carga de Productos Refrigerados',
                path: '/maestro-productos/carga-productos-refrigerados',
              }"
            />
            <sidebar-item
              :link="{
                name: 'Carga de Productos en Prioridad',
                path: '/maestro-productos/carga-productos-prioridad',
              }"
            />
            <sidebar-item
              :link="{
                name: 'Creacion pedido minimo',
                path: '/pedidos/pedido-minimo',
              }"
            />
          </sidebar-item>
        </template>

        <template v-if="user.rol < 4">
          <sidebar-item
            :link="{ name: 'Parámetros', icon: 'fas fa-cogs text-laive' }"
          >
            <template v-if="user.rol < 3">
              <sidebar-item
                :link="{ name: 'Login', path: '/parametros/login' }"
              />
              <sidebar-item
                :link="{ name: 'Pedidos', path: '/parametros/tomapedidos' }"
              />
              <sidebar-item
                :link="{ name: 'Devolucion', path: '/parametros/devolucion' }"
              />
              <sidebar-item
                :link="{ name: 'Depositos', path: '/parametros/depositos' }"
              />
              <sidebar-item
                :link="{ name: 'Hoja de ruta', path: '/parametros/hojaderuta' }"
              />
              <sidebar-item
                :link="{ name: 'Semaforos', path: '/parametros/reportes' }"
              />
              <sidebar-item
                :link="{
                  name: 'Notificaciones de Control',
                  path: '/parametros/alertas',
                }"
              />
              <sidebar-item
                :link="{
                  name: 'Notificaciones Operativas',
                  path: '/parametros/notificaciones',
                }"
              />
              <sidebar-item
                :link="{
                  name: 'Envio de Errores',
                  path: '/parametros/envio-erorres',
                }"
              />
              <sidebar-item
                :link="{ name: 'Productos', path: '/parametros/productos' }"
              />
              <sidebar-item
                :link="{
                  name: 'Operaciones de activos',
                  path: '/parametros/operaciones-activos',
                }"
              />
              <sidebar-item
                :link="{
                  name: 'Pesos Cobertura Simple',
                  path: '/reportes/cobertura-pesos',
                }"
              />
              <sidebar-item
                :link="{
                  name: 'Listado de Geocercas',
                  path: '/vendedores/listado-geocercas',
                }"
              />
              <sidebar-item
                :link="{
                  name: 'Cargar de Geocerca',
                  path: '/vendedores/geocerca-importar',
                }"
              />
              <sidebar-item
                :link="{
                  name: 'Ticket Esperado',
                  path: '/parametros/ticket-esperado',
                }"
              />
            </template>
            <sidebar-item
              :link="{
                name: 'Registros de Pedidos',
                path: '/parametros/registro-pedidos',
              }"
            />
            <template v-if="user.rol < 3">
              <sidebar-item
                :link="{
                  name: 'Cobranza fuera de ruta',
                  path: '/parametros/cobranza-fuera-ruta',
                }"
              />
              <sidebar-item
                :link="{
                  name: 'Nuevos Clientes',
                  path: '/parametros/notificacion-nuevo-cliente',
                }"
              />
              <sidebar-item
                :link="{
                  name: 'Recencia de compra',
                  path: '/parametros/recencia-compra',
                }"
              />
              <sidebar-item
                :link="{
                  name: 'Productos Recomendados',
                  path: '/parametros/productos-recomendados',
                }"
              />
              <sidebar-item
                :link="{
                  name: 'Metodo de pago QR',
                  path: '/parametros/metodos-pago',
                }"
              />
              <sidebar-item
                :link="{
                  name: 'Stock reservado',
                  path: '/parametros/stock-reservado',
                }"
              />
              <sidebar-item
                :link="{
                  name: 'Productos refrigerados',
                  path: '/parametros/productos-refrigerados',
                }"
              />
            </template>
            <sidebar-item
              :link="{
                name: 'Parámetros de equipos de frío',
                path: '/parametros/equipos-frio',
              }"
            />
            <sidebar-item
              :link="{
                name: 'Notas de credito',
                path: '/parametros/notas-credito',
              }"
            />
          </sidebar-item>
        </template>

        <sidebar-item
          :link="{
            name: 'Maestro de Productos',
            icon: 'fab fa-product-hunt text-laive',
          }"
        >
          <template v-if="user.rol < 3">
            <sidebar-item
              :link="{
                name: 'Carga de Productos',
                path: '/maestro-productos/listado',
              }"
            />
            <sidebar-item
              :link="{
                name: 'Carga de Jerarquias',
                path: '/maestro-productos/jerarquias',
              }"
            />
          </template>
        </sidebar-item>

        <sidebar-item
          v-if="user.rol !== 8"
          :link="{
            name: 'Maestro de Clientes ',
            icon: 'fas fa-user-check text-laive',
          }"
        >
          <sidebar-item
            :link="{ name: 'Listado de Clientes', path: '/clientes/listado' }"
          />
          <sidebar-item
            :link="{
              name: 'Listado de Clientes Nuevos ',
              path: '/clientes/solicitudes',
            }"
          />
        </sidebar-item>

        <sidebar-item
          v-if="showBonification && accessTrade"
          :link="{ name: 'Bonificaciones', icon: 'fas fa-gifts text-laive' }"
        >
          <sidebar-item
            :link="{
              name: 'Listado de Bonificaciones',
              path: '/bonificaciones/listado',
            }"
          />
          <sidebar-item
            :link="{ name: 'Liquidaciones', path: '/liquidaciones/listado' }"
          />
          <sidebar-item
            :link="{
              name: 'Administrar usuarios Trade',
              path: '/usuarios/administracion-trade',
            }"
          />
        </sidebar-item>

        <sidebar-item
          v-if="user.rol !== 8"
          :link="{
            name: 'Maestro de Vendedores',
            icon: 'fas fa-user-tag text-laive',
          }"
        >
          <template v-if="user.rol <= 3">
            <sidebar-item
              :link="{ name: 'Supervisores', path: '/supervisores/listado' }"
            />
          </template>
          <sidebar-item
            :link="{ name: 'Vendedores', path: '/vendedores/listado' }"
          />
        </sidebar-item>

        <template v-if="user.rol < 3">
          <sidebar-item
            :link="{
              name: 'Maestro de Codigos de Verificacion',
              path: '/codigos-verificacion/listado',
              icon: 'fas fa-laptop-code text-laive',
            }"
          ></sidebar-item>
        </template>

        <template v-if="user.rol == 1">
          <sidebar-item
            :link="{ name: 'Usuarios', icon: 'ni ni-users text-laive' }"
          >
            <sidebar-item
              :link="{ name: 'Reporte de usuarios', path: '/usuarios/listado' }"
            />
            <sidebar-item
              :link="{ name: 'Crear Usuario', path: '/usuarios/crear' }"
            />
          </sidebar-item>

          <sidebar-item
            :link="{
              path: '/versiones/lista',
              name: 'Versiones del APP',
              icon: 'ni ni-users text-laive',
            }"
          ></sidebar-item>

          <sidebar-item
            :link="{
              path: '/maestros/lista',
              name: 'Actualizacion Maestros',
              icon: 'ni ni-reporte text-laive',
            }"
          ></sidebar-item>

          <template v-if="user.rol < 3">
            <sidebar-item
              :link="{
                name: 'Logs',
                path: '/logs/lista',
                icon: 'ni ni-sound-wave text-laive',
              }"
            ></sidebar-item>
          </template>

          <sidebar-item
            :link="{
              name: 'Catalogo de errores',
              path: '/catalogo/lista',
              icon: 'ni ni-app text-laive',
            }"
          ></sidebar-item>
        </template>

        <!-- <sidebar-item
          :link="{
            name: 'Reportes de Cobertura Agregada', icon: 'ni ni-reporte text-laive',
          }"
        >
          <sidebar-item
            :link="{ name: 'Por Categoria', path: '/reportes/cobertura-agregada-categoria' }"
          />
          <sidebar-item
            :link="{ name: 'Pesos por Categoria', path: '/reportes/cobertura-agregada-pesos' }"
          />
          <template v-if="user.rol <= 3">
            <sidebar-item
              :link="{ name: 'Carga de objetivos por Categoria', path: '/reportes/cobertura-agregada-objetivo' }"
            />
          </template>
          <sidebar-item
            :link="{ name: 'Por SKU', path: '/reportes/cobertura-agregada-sku' }"
          />
          <sidebar-item
            :link="{ name: 'Pesos por Sku', path: '/reportes/cobertura-agregada-pesos-sku' }"
          />
          <template v-if="user.rol <= 3">
            <sidebar-item
              :link="{ name: 'Carga de objetivos por Sku', path: '/reportes/cobertura-agregada-objetivo-sku' }"
            />
          </template>
        </sidebar-item> -->
      </template>

      <template v-slot:links-after> </template>
    </side-bar>
    <div class="main-content dashboard">
      <dashboard-navbar
        class="d-md-none"
        :type="$route.meta.navbarType"
      ></dashboard-navbar>
      <div @click="$sidebar.displaySidebar(false)">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import DashboardNavbar from "./DashboardNavbar.vue";
import { mapGetters } from "vuex";

const showBonification = process.env.VUE_APP_ENABLE_BONIFICATION === "1";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}
function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    DashboardNavbar,
  },
  data() {
    return {
      showBonification,
    };
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
      accessTrade: "auth/accessTrade",
    }),
  },
  methods: {
    initScrollbar() {
      initScrollbar("sidenav");
    },
  },
  mounted() {
    this.initScrollbar();
  },
};
</script>
<style lang="scss"></style>
