<template>
<div class="content">
    <base-header class="">
        <div class="row align-items-center pb-4">
            <div class="col-lg-6 col-7">
                <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                    <route-bread-crumb></route-bread-crumb>
                </nav>
            </div>
            <div class="col-lg-6 col-5 d-none d-md-inline-block">
                <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
            </div>
        </div>
    </base-header>
    <div :client-clicked='is_client_clicked' class="container-fluid">
        <div class="form_laive " :class="{validateClient:isLoading}">
            <!--validateClient-->
            <div class="box_loader">
                <div class="loader"></div>
            </div>
            <card v-if='!is_client_clicked' class="no-border-card " body-classes="px-0 py-1" footer-classes="pb-2" style='z-index: 1;'>
                <template v-slot:header class='pt-2 p-b2'>
                    <div class="row">
                        <div class="col-6">
                            <template v-if="this.user.rol == 3 || this.user.rol == 4">
                                <div class="title_filter mr-2">
                                    <div>Distribuidora: <strong>{{distribuidoraName}}</strong></div>
                                </div>
                            </template>
                            <template v-else>

                                <span class="title_filter mr-2">Distribuidoras: </span>
                                <select v-model='filter.idDistribuidora' class="form-control" style="display: inline-block;height: 43px;" name="distributor_select" @change="selectChangeDistribuidora">
                                    <option v-for="item in distribuidoras" :value="item.idDistribuidora" :key="item.idDistribuidora">
                                        {{ item.razonSocial }}
                                    </option>
                                </select>
                            </template>
                        </div>
                        <div class="col-12 col-md-6 text-right">

                        </div>
                    </div>
                </template>
                <div>
                    <div class="row px-3">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-3 d-flex align-items-center">
                                    <template v-if="this.user.rol == 4">
                                        <div class="title_filter mr-2">
                                            <div>Mesa: <strong>{{this.user.mesa}}</strong></div>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class='w-100'>
                                            <span class="title_filter mr-2">Mesa: </span>
                                            <select v-model='filter.codigoMesa' class="form-control" style="display: inline-block;height: 43px;" name="supervisor_select" @change="selectChangeMesa">
                                                <option v-for="item in mesas" :key="item.idRegistro" :value="item.codigoDato" >
                                                    {{ item.nombre }}
                                                </option>
                                            </select>
                                        </div>

                                    </template>

                                </div>
                                <div class="col-3">
                                    <span class="title_filter mr-2">Vendedor: </span>
                                    <select v-model='filter.idVendedor' class="form-control" id="vendedor_select" style="display: inline-block;height: 43px;" name="vendedor_select" @change='selectChangeVendedor'>
                                        <option value="">Todos los vendedores</option>
                                        <option v-for="item in vendedores" :value="item.codigoVendedor" :key="item.codigoVendedor">
                                            {{ item.nombre }} {{ item.apellidos }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-6">
                                    <div class='row'>
                                        <div class="col-4 pl-1 pr-1">
                                            <label class="title_filter w-100">Vend. con alertas</label>
                                            <label class="custom-toggle">
                                                <input type="checkbox" v-model='filter.isAlert' @change='this.alertChange()'>
                                                <span class="custom-toggle-slider rounded-circle"></span>
                                            </label>
                                        </div>
                                        <div class="col-4 pl-1 pr-1">
                                            <label class="title_filter w-100">Rutas sugeridas </label>
                                            <label class="custom-toggle">
                                                <SelectOnlyButton :collection='buttonSuggetion' @change='onlyButtonChange'></SelectOnlyButton>
                                            </label>
                                        </div>
                                        <div class="col-4 pl-1">
                                            <label class="title_filter w-100 text-white mb-0">. </label>
                                            <a class='btn btn-secondary btn-filter' :is-filtered='filtered_length > 0' @click='showClientFilterClick'>
                                                <span>Filtrar por clientes</span>
                                                <i class='fa icon-filter'></i>
                                                <i class='fa icon-indicator'>{{filtered_length}}</i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class ='col-12'>
                                    <RightSidebarClienteFilter :reset="clearFilter" :suggest="this.filter.isRoutes" :show='showClientFilter' @filter='filterChange' :efectividad='filtroParameters?.efectividad' :pedido='filtroParameters?.pedido' />
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-2 mb-3 d-none">

                        </div>
                    </div>
                    <!-- googler maps -->
                    <div class="el-table table_report pesos notoverflow">
                    </div>
                </div>
            </card>
            <card v-else class='no-border-card' body-classes='px-0 py-0' style='z-index:1;'>
                <template v-slot:header>
                    <div class='row'>
                        <div class='col-12'>
                            <div class='client-bar'>
                                <i class="fas fa-angle-left mr-3" @click='hideClientBar'></i>
                                <span class='client-name'>{{currentInfoVendedor?.info?.nombreCompleto}}</span>
                                <span class='client-code'>Código<strong>{{currentInfoVendedor?.info?.codigoVendedor}}</strong></span>
                            </div>
                        </div>
                    </div>
                </template>
            </card>
            <div class='maps-container' id='map'>
                <div class="google-map" ref="googleMap"></div>
            </div>
        </div>
    </div>
</div>

<InfoVendedor  :showSugget="this.filter.isRoutes" :show='showInfoVendedor' @change='infoChange' :current='currentInfoVendedor'></InfoVendedor>
<InfoCliente :show='showInfoCliente' :suggest="this.filter.isRoutes" @change='clientChange' :current='currentInfoClient'></InfoCliente>
</template>

<script>
let fixClickOpenModal = 1
import {
    ElTable,
    ElTableColumn,
    ElSelect,
    ElOption,
    ElInput
} from "element-plus";
import axios from "axios";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import TabPane from "@/components/Tabs/TabPane";
import BasePagination from "@/components/BasePagination";
import swal from "sweetalert2";
import DashboardNavbar from "../Layout/DashboardNavbar";
import GoogleMapsApiLoader from 'google-maps-api-loader';
import RightSidebarClienteFilter from '../Components/RightSidebarClienteFilter';
import InfoVendedor from '../Components/InfoVendedor';
import InfoCliente from '../Components/InfoCliente';
import SelectOnlyButton from '../Components/SelectOnlyButton';
import * as moment from 'moment';
//import * as signalr from "../../util/signalr";
import {
    LiquidTable,
    LiquidAlert
} from "../../util/liquid";
import {
    mapActions,
    mapGetters
} from 'vuex';
import * as signalR from "@microsoft/signalr";
export default {
    components: {
        RightSidebarClienteFilter,
        InfoVendedor,
        InfoCliente,
        DashboardNavbar,
        BasePagination,
        RouteBreadCrumb,
        SelectOnlyButton
    },
    async created() {

    },
    computed: {
        ...mapGetters({
            authenticated: 'auth/authenticated',
            user: 'auth/user',
        }),
        async getDistribuidora() {
            try {
              console.log(this.filter.idDistribuidora)
              let result = await this.getDistributorList();
              this.distribuidoras = result.data ? result.data : [];
              let m = this.distribuidoras.reduce(x => x);
              // console.log(this.filter.idDistribuidora)
              // console.log({m})
              // this.filter.idDistribuidora = m ? m.idDistribuidora : 0;
            } catch (ex) {
              ex
            }
        },

        async getVendedorListaByDistribuidora(){
            this.vendedores = [];
            let result = await this.getListByMesa(this.filter)
            this.vendedores = result.data ? result.data : [];
            this.vendedores.map(x => {
                x.completeName = this.capitalize(x.nombre + ' ' + x.apellidos);
                x.route = false;
                return x;
            })
            console.log('se cargaron los vendedores')
        },

        /* get list supervisor data*/
        async getMesaByDistribuidora(){
            try{
              this.mesas = [];
              let listMesas = await this.getMesaListByDistribuidora(this.filter)
              console.log({listMesas})
              this.mesas = listMesas.data
              this.filter.codigoMesa = (this.user.mesa) ? this.user.mesa : this.mesas[0]["codigoDato"];
            }
            catch(ex){
              this.isLoading = false;
            }
        },

        async getFiltroAvanzadoParameterList() {

            try {
                let result = await this.getFiltroAvanzadoParameter({});
                this.filtroParameters = result.data;
            } catch (ex) {
                console.log(ex)
            }
        },

        async getVendedor() {
            try {
                this.vendedores = [];
                let result = await this.getListByMesa({
                    iddistribuidora: this.filter.idDistribuidora,
                    codigomesa: this.filter.codigoMesa
                });
                this.vendedores = result.data ? result.data : [];
                this.vendedores.map(x => {
                    x.completeName = this.capitalize(x.nombre + ' ' + x.apellidos);
                    x.route = false;
                    return x;
                })

            } catch (ex) {
                console.log(ex)
            }
        },
        async getVendedorLocationTimeRouteData() {
            //this.locationVendedores = [];
            try{
                let locations =[];
                let mesa = this.filter.codigoMesa;
                if(!mesa) return;
                let result = await this.getVendedorLocationTimeLocation({
                    idDistribuidora: this.filter.idDistribuidora,
                    codigoMesa: mesa,
                    codigoVendedor: this.filter.idVendedor,
                    venta: this.filterAdvance.venta,
                    cobranza: this.filterAdvance.cobranza,
                    efectividad: this.filterAdvance.efectividad,
                    pedido: this.filterAdvance.pedido,
                    devolucion: this.filterAdvance.devolucion,
                    surtido:this.filterAdvance.surtido
                });
                if (this.filter.isAlert == true) {
                    let data = result.data.map(x => {
                        if (x.alertas == undefined) x.alertas = 0;
                        return x;
                    });
                    locations = data.filter(x => +x.alertas == 1);
                } else {
                    locations = result.data;
                }
                return locations;
            }
            catch(ex){console.log(ex);return [];}
        },
        async getVendedorLocationTimeRoute() {
            //this.locationVendedores = [];
            console.log(this.filter.codigoMesa, "seleccionado filtro mesa")
            let mesa = this.filter.codigoMesa;
            console.log(mesa, "seleccionado filtro mesa")
            if (!mesa) {
              this.isLoading = false;
              this.locationVendedores = [];
              return
            }
            let result = await this.getVendedorLocationTimeLocation({
                idDistribuidora: this.filter.idDistribuidora,
                codigoMesa: mesa,
                codigoVendedor: this.filter.idVendedor,
                venta: this.filterAdvance.venta,
                cobranza: this.filterAdvance.cobranza,
                efectividad: this.filterAdvance.efectividad,
                pedido: this.filterAdvance.pedido,
                devolucion: this.filterAdvance.devolucion,
                surtido:this.filterAdvance.surtido
            });
            if (this.filter.isAlert == true) {
                let data = result.data.map(x => {
                    if (x.alertas == undefined) x.alertas = 0;
                    return x;
                });
                this.locationVendedores = data.filter(x => +x.alertas == 1);
            } else {
                this.locationVendedores = result.data;
            }
        },

        async getGeocercaList(){
            try{
              let result = await this.getGeocerca({ idDistribuidora: this.filter.idDistribuidora });
              console.log("result getGeocercaList ", result.data)
              if(result.data){
                  this.geocercas = result.data;
              } else {
                this.geocercas = [];
              }
            }
            catch(ex){console.log(ex)}
        },

        distribuidoraName() {
          let x = this.distribuidoras.find(x => x.idDistribuidora == this.filter.idDistribuidora);
          console.log(this.distribuidoras, this.filter.idDistribuidora)
          // console.log(this.distribuidoras.filter((item)=>{return item.idDistribuidora == this.filter.idDistribuidora}))
          return x ? x.razonSocial : '---';
        },
        supervisorName() {
            let x = this.supervisores.find(x => x.codigoSupervisor == this.filter.codigoMesa);
            /*return x ? x.nombre: 'loading...';*/
            return this.user.nombre;
        },

        async hideClientBar() {
            this.is_client_clicked = false;
            this.showInfoVendedor = false;
            await this.getLocationTimeRoute;
            await this.LoadMap();
        },

        async Io() {
            let token = localStorage.getItem("token");
            let server = 'https://laive-adm-p45wb2lx3a-ue.a.run.app' ///api/usuarios/login
            let connection = new signalR.HubConnectionBuilder()
                .withUrl(server + '/hub/vendedor', {
                    accessTokenFactory: () => token,
                    skipNegotiation: true,
                    transport: signalR.HttpTransportType.WebSockets
                })
                .withAutomaticReconnect()
                .configureLogging(signalR.LogLevel.Information)
                .build();
            connection.on('OnVendedorUbicacion', (data) => {
                try {
                    let newV = JSON.parse(data);
                    if (!newV) return; // not found data;
                    console.log('OnVendedorUbicacion', newV)
                    let vMarker = this.vendedorMarkers.find(x => x.key == newV.id);
                    if (!vMarker) return; //not found vendedor marker
                    if (vMarker.marker) {
                        vMarker.marker.setPosition(new google.maps.LatLng(newV.latitud, newV.longitud));
                        vMarker.marker.setVisible((+newV.latitud) != 0 && (newV.longitud != 0));
                        let line = this.lines.find(x => x.key == newV.codigoVendedor);
                        if (line) {
                            line.coordenada = {
                                lat: newV.latitud,
                                lng: newV.longitud
                            };
                            line.show(line.key);
                        }
                    }

                } catch (ex) {
                    console.log(ex)
                }
            });
            connection.on('OnClienteVisita', async(data) => {
              try {
                let ruta = JSON.parse(data);
                if (!ruta) return; // not found data;
                console.log('OnClienteVisita', ruta)
                let cMarker = this.clienteMarkers.find(x => x.key == ruta.id);
                if (!cMarker) return;
                cMarker.ruta.visitado = ruta.visitado;
                cMarker.ruta.pedido = ruta.pedido;
                cMarker.ruta.tipoPedido = ruta.tipoPedido;
                cMarker.marker.setPosition(new google.maps.LatLng(ruta.latitud, ruta.longitud));
                console.log(cMarker.ruta, " new rutaaaaa")
                let iconoState = this.clientIconState(cMarker.ruta);
                if (cMarker.marker) cMarker.marker.setIcon(iconoState.icono);
                if(this.filter.isRoutes == false){
                  if (cMarker.marker) {
                    cMarker.marker.setVisible(iconoState.type !="red");
                    let label = cMarker.marker.getLabel();
                    let locations = await this.getVendedorLocationTimeRouteData;
                    if(locations){
                        let loc = locations.find(x=>x.id == cMarker.idVendedor);
                        if(loc) {
                            let r = loc.ruta.find(x=>x.id == ruta.id);
                            r.longitud = ruta.longitud;
                            r.latitud = ruta.latitud;
                            if(r){
                                let a = loc.ruta.filter(x=>x.cvOrden != 0);
                                r.cvOrden = r.cvOrden != 0 ? r.cvOrden : (a.length + 1);
                                r.visitado = ruta.visitado;
                                r.pedido = ruta.pedido;
                                label.text = '' + r.cvOrden;
                                label.color = (cMarker.ruta.tipoPedido) ? this.marketSet.color = "black" : this.marketSet.color = "white"
                                cMarker.marker.setLabel(label);
                                google.maps.event.clearListeners(cMarker.marker,'click');
                                google.maps.event.addListener(cMarker.marker,'click',()=>{cMarker.event(r)});
                            }
                            let line = this.lines.find(x=>x.idVendedor == loc.id);
                            if(line) {
                                line.coordenadas = loc.ruta.map(x=>{
                                  return { lat:x.latitud, lng:x.longitud, ruta:x};
                                });
                            }
                        }

                    }
                  }
                }
              } catch (ex) {console.log(ex);}
            });
            await connection.start()
                .then(() => {
                    console.log('success', 'connection started');
                })
                .catch(error => {
                    console.log('error', error);
                });

        }

    },
    methods: {
        ...mapActions({
            getMesaList: 'mesa/getList',
            getMesaListByDistribuidora:'mesa/getListByDistribuidora',
            getVendedorListByDistribuidora:'vendedor/getListByDistribuidora',
            getNotificacionList: 'notification/getList',
            getNotificacionDetalleList: 'notification/getDetalle',
            getDistributorList: 'distribuidora/getList',
            getSupervisorList: 'supervisor/getListById',
            getListByMesa: 'vendedor/getListByMesa',
            getVendedorLocationTimeLocation: 'vendedor/getLocationTimeRoute',
            getSupervisionVendedorDetalle: 'vendedor/getSupervisionVendedorDetalle',
            getSupervisionClientDetalle: 'vendedor/getSupervisionClientDetalle',
            getFiltroAvanzadoParameter: 'vendedor/getFiltroAvanzadoParameter',
            getToken: 'user/getToken',
            getGeocerca:'vendedor/getGeocerca'
        }),
        async getSupervisionVendedorDetalleInfo(codigo) {
            let result = await this.getSupervisionVendedorDetalle({
                id: codigo
            });
            return result.data;
        },
        async getSupervisionClientDetalleInfo(codigo) {
            let result = await this.getSupervisionClientDetalle({
                id: codigo
            });
            return result.data;
        },

        async selectChangeDistribuidora() {
            const $this = this
            this.isLoading = true;
            this.clearFilter = false;
            this.filtered_length = 0;
            this.filter.codigoMesa = '';
            this.filter.idVendedor = '';
            await this.getMesaByDistribuidora.then(() => {
              $this.getVendedorListaByDistribuidora.then(() => {
                $this.selectChangeVendedor()
              });
            });
        },

        async selectChangeMesa() {
          const $this = this
          this.filtered_length = 0;
          this.filter.idVendedor = '';
          this.clearFilter = false;
          this.clearFilter = true;
          this.isLoading = true;
          await this.getVendedorListaByDistribuidora.then(() => {
            $this.selectChangeVendedor()
          });
        },

        async selectChangeVendedor() {
            const $this = this
            this.isLoading = true;
            this.filtered_length = 0;
            this.clearFilter = false;
            this.clearFilter = true;
            localStorage.removeItem('map_center');
          this.getVendedorLocationTimeRoute.then(() => {
            $this.getGeocercaList.then(() => {
                $this.LoadMap();
              })
            }).catch(error => {
              console.log('error', error);
            });
        },
        async alertChange() {
            console.log('alert cahnge')
            await this.getVendedorLocationTimeRoute;
            await this.LoadMap();
        },

        async vendedorFill(map, locationV) {
            /*observaciones, revisar bien el caso de cuando el location sea 0*/
            try{
                var info = new google.maps.InfoWindow({ content: locationV.codigoVendedor,  disableAutoPan: true });
                let marker = new google.maps.Marker({
                    position: { lat: locationV.latitud, lng: locationV.longitud },
                    icon: this.iconsMaps.circlePulse,
                    map: map,
                });
                google.maps.event.addListener(marker, 'click', async () => {
                    console.log('click en vendedor',locationV);
                    this.isLoading = true;
                    info.open(map, marker);
                    let vendedor = this.vendedores.find(x => x.idVendedor == locationV.id);
                    if (vendedor) {
                        let result = await this.getSupervisionVendedorDetalleInfo(locationV.id);
                        if (result) {
                            this.showInfoVendedor = true;
                            result.info = vendedor;
                            result.notificaciones.map(x=>{
                                x.hora = moment(x.hora,'HH:mm:ss').format('hh:mm a');
                                return x;
                            })
                            this.currentInfoVendedor = result;

                            console.log('vendedor', this.currentInfoVendedor);
                        }
                    }
                    this.isLoading = false;
                });
                //console.log(locationV.id,+locationV.latitud,+locationV.longitud,(+locationV.latitud) != 0 && (+locationV.longitud != 0));
                console.log("set visible ", (+locationV.latitud) != 0 && (+locationV.longitud != 0))
                marker.setVisible(true)
                this.vendedorMarkers.push({  marker: marker, key: locationV.id});
            }
            catch(ex){console.log(ex);}
        },
        ramdomNumber(min, max) {
            return Math.floor(Math.random() * (max - min)) + min;
        },
        capitalize: function (s) {
            if (s.length > 1) {
                s = s.toLowerCase();
                return s.charAt(0).toUpperCase() + s.slice(1)
            } else {
                return s;
            }
        },
        /*mostrar el tipo de icono de clientes de acuerdo a su estado*/
        clientIconState(ruta) {
          let icono = '';
          let type = null;
          this.marketSet.color = "white";
          // Clientes Gestionados
          if (ruta.visitado == '1') {
            // Si son pedidos deben ser verde
            if (ruta.pedido == 'SIPEDIDO') {
              // Y si son top y oro
              if (ruta.codigoSegmento == "03" || ruta.codigoSegmento == "04") {
                // y si no coincide con el filtro
                if (ruta.filtro == 0) {
                  if (ruta.tipoPedido == 1) {
                    this.marketSet.color = "Black";
                    icono = this.iconsMaps.phoneCoronaGray;
                    type = 'gray';
                  } else {
                    icono = this.iconsMaps.circleCoronaGray;
                    type = 'gray';
                  }
                } else {
                  if (ruta.tipoPedido == 1) {
                    this.marketSet.color = "Black";
                    icono = this.iconsMaps.phoneCoronaGreen;
                    type = 'green';
                  } else {
                    icono = this.iconsMaps.circleCoronaGreen;
                    type = 'green';
                  }
                }
              } else {
                // y si no coincide con el filtro
                if (ruta.filtro == 0) {
                  if (ruta.tipoPedido == 1) {
                    this.marketSet.color = "black";
                    icono = this.iconsMaps.phoneGray;
                    type = 'gray';
                  } else {
                    icono = this.iconsMaps.circleGray;
                    type = 'gray';
                  }
                } else {
                  if (ruta.tipoPedido == 1) {
                    this.marketSet.color = "Black";
                    icono = this.iconsMaps.phoneGreen;
                    type = 'green';
                  } else {
                    icono = this.iconsMaps.circleGreen;
                    type = 'green';
                  }
                }
              }
            } else {
              // Si no son pedidos debe ser naranja
              // Y si son top y oro
              if (ruta.codigoSegmento == "03" || ruta.codigoSegmento == "04") {
                // y si no coincide con el filtro
                if (ruta.filtro == 0) {
                  icono = this.iconsMaps.circleCoronaGray;
                  type = 'gray';
                } else {
                  // En caso coincida
                  icono = this.iconsMaps.circleCoronaOrange;
                  type = 'orange';
                }
              } else {
                // si no son top y oro
                // y si no coincide con el filtro
                if (ruta.filtro == 0) {
                  icono = this.iconsMaps.circleGray;
                  type = 'gray';
                } else {
                  // En caso coincida
                  icono = this.iconsMaps.circleOrange;
                  type = 'orange';
                }
              }
            }
          } else {
            // En caso no sean gestionados deben ser rojo
            // Y si son top y oro
            if (ruta.codigoSegmento == "03" || ruta.codigoSegmento == "04") {
              // y si no coincide con el filtro
              if (ruta.filtro == 0) {
                if (ruta.tipoPedido == 1) {
                  this.marketSet.color = "Black";
                  icono = this.iconsMaps.phoneCoronaGray;
                  type = 'gray';
                } else {
                  icono = this.iconsMaps.circleCoronaGray;
                  type = 'gray';
                }
              } else {
                // En caso coincida
                if (ruta.tipoPedido == 1) {
                  this.marketSet.color = "white";
                  icono = this.iconsMaps.phoneCoronaRed;
                  type = 'red';
                } else {
                  icono = this.iconsMaps.circleCoronaRed;
                  type = 'red';
                }
              }
            } else {
              // si no son top y oro
              // y si no coincide con el filtro
              if (ruta.filtro == 0) {
                if (ruta.tipoPedido == 1) {
                  this.marketSet.color = "Black";
                  icono = this.iconsMaps.phoneCoronaGray;
                  type = 'gray';
                } else {
                  icono = this.iconsMaps.circleGray;
                  type = 'gray';
                }
              } else {
                // En caso coincida
                if (ruta.tipoPedido == 1) {
                  this.marketSet.color = "white";
                  icono = this.iconsMaps.phoneRed;
                  type = 'red';
                } else {
                  icono = this.iconsMaps.circleRed;
                  type = 'red';
                }
              }
            }
          }
          return {icono:icono,type:type};
        },

        /* dibuja los marcadores de los clientes por vendedor*/
        markerClient(vendedor, map){
            try{
                for (let ruta of vendedor.ruta) {
                    let iconState = this.clientIconState(ruta);
                    let circle_marker = this.clienteMarkers.find(x=>x.key == ruta.id);
                    if(!circle_marker) {
                        let circ = new google.maps.Marker({
                            position: { lat: (ruta.latitud), lng: ruta.longitud},
                            label: { text: '' + (this.filter.isRoutes == true ? ruta.orden : ruta.cvOrden), color: this.marketSet.color },
                            map:map,
                            icon: iconState.icono,
                            zIndex: 1000 + (ruta.orden)
                        });
                        if(this.filter.isRoutes == false){
                            if(ruta.cvOrden != 0){
                                circ.setVisible(iconState.type !="red");
                            } else{
                                circ.setVisible(false);
                            }
                        }
                        let click_event = async (_ruta) => {
                            console.log(_ruta);
                            let _iconState = this.clientIconState(_ruta);
                            this.currentInfoClient = await this.getSupervisionClientDetalleInfo(_ruta.id);
                            if (this.currentInfoClient) {
                                this.currentInfoClient.orden = _ruta.orden;
                                this.currentInfoClient.cvOrden = _ruta.cvOrden;
                                this.currentInfoClient.nombre = this.capitalize(this.currentInfoClient.nombre)
                                this.currentInfoClient.giroImagen = process.env.VUE_APP_STORAGE_URL + '/' + this.currentInfoClient.giroImagen;
                                this.currentInfoClient.iconType = _iconState.type;
                                this.showInfoCliente = true;
                            }
                        }
                        let event = google.maps.event.addListener(circ, 'click',()=>{click_event(ruta);} );
                        this.clienteMarkers.push({marker: circ, key: ruta.id, idVendedor: vendedor.id, icon:iconState.icono, type:iconState.type,ruta:ruta,event:click_event });
                    }
                }
            }
            catch(ex){console.log(ex)}
        },

        /*mostrar u ocultar lineas y puntos de cliente*/
        showLines(key) {
          let l = this.lines.find(x => x.key == key);
            // no show lines
            return
            if (l) {
                /*create lines*/
                if (l.hide) {l.hide();}
                let coordenadas = [];
                if(!this.filter.isRoutes){
                    coordenadas = l.coordenadas.filter(x=>x.ruta.cvOrden > 0);
                    coordenadas = coordenadas.sort((a,b)=>{
                        if(a.ruta.cvOrden > b.ruta.cvOrden ) return -1;
                        if(a.ruta.cvOrden > b.ruta.cvOrden ) return 1;
                        return 0;
                    })
                } else  {
                    coordenadas = l.coordenadas.filter(x=>x.ruta.enRuta == 1);
                }
                let nline = new google.maps.Polyline({ path: coordenadas, strokeColor: '#000000', strokeOpacity: 1, strokeWeight: 2, map: l.map });

                /*mostrar lineas solo cuando el cliente este en ruta y su ubicación sea diferente de cero*/
                if(this.showInfoVendedor == true && this.currentInfoVendedor.info.route == true){
                    nline.setVisible( (+l.coordenada.lat) != 0 && (+l.coordenada.lng != 0));
                } else {
                    nline.setVisible((+l.coordenada.lat) != 0 && (+l.coordenada.lng != 0));
                }
                /*hide*/
                l.hide = () => {
                    nline.setMap(null);
                };
            }
        },

        /*muestra las lineas de la geo cerca */
        linesGeocerca(vendedor,map) {
            try {
                let l = this.lines_geocerca.find(x => x.key == vendedor.codigoVendedor);
                if (!l) {


                    let geo = this.geocercas.find(x=> x.codigoVendedor == vendedor.codigoVendedor);
                    if(!geo) return;
                    for(let zona of geo.zonas){
                        if(zona.coordenadas){
                            let coordenadas=[];
                            for(let item of zona.coordenadas){
                                coordenadas.push({lat:item.latitud, lng:item.longitud});
                            }
                            let poligon = new google.maps.Polygon({ paths: coordenadas, strokeColor: "#FF0000", strokeOpacity: 0.8, strokeWeight: 2, fillColor: "#FF0000", fillOpacity: 0.35, map:map });
                            this.lines_geocerca.push({ line:poligon,map: map, key: vendedor.codigoVendedor });
                        }
                    }
                }
            }
            catch(ex){console.log(ex)}
        },

        /*devuelve el zoom actual guardado en memoria*/
        async getZoom(){
            let _zoom_level = localStorage.getItem("zoom_level");
            if(!_zoom_level) return 14;
            return (+_zoom_level);
        },
        getCenter(){
            try{
                let x = localStorage.getItem("map_center");
                if(!x) return null;
                return JSON.parse(x);
            }
            catch(ex){ return null;}
        },
        /*centrar el mapa de acuerdo a las ubicaciones del vendedor*/
        async getCalculeMapCenter(){
            let a = 0,b = 0;
            for(let item of this.locationVendedores){
                if(item.latitud != 0){
                    a += item.latitud;
                    b += item.longitud;
                }
            }
            if(a == 0 && b == 0) return undefined;
            let r = {
                lat: (a / this.locationVendedores.filter(x=>x.latitud != 0).length),
                lng: (b / this.locationVendedores.filter(x=>x.latitud != 0).length)
            }
            return r;

        },
        async LoadMap() {
            try {
                this.lines = [];
                this.lines_suggestion =[];
                this.vendedorMarkers = [];
                this.clienteMarkers = [];
                this.lines_geocerca =[];
                this.isLoading = true;
                /*inicialice map*/
                let map = undefined;
                let mapLayer = this.$refs.googleMap
                let mapProp = {
                    zoom: await this.getZoom(),
                    center: {
                        lat: -12.0407277,
                        lng: -77.0584408
                    },
                    mapTypeId: google.maps.MapTypeId.roadmap,
                    fullscreenControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    mapTypeControl: false,
                    zoomControl: false
                };
                map = new google.maps.Map(mapLayer, mapProp);

                console.log("load maps services ", this.locationVendedores)

                if (this.locationVendedores.length == 1) {
                    let locationV = this.locationVendedores.length > 0 ? this.locationVendedores[0] : undefined;
                    if (locationV) {
                        let map_center = this.getCenter();
                        if(!map_center) {
                            if(locationV.latitud != 0){
                                map.setCenter({ lat: locationV.latitud, lng: locationV.longitud });
                            }
                        } else{
                            map.setCenter(map_center);
                        }

                        map.setZoom(await this.getZoom());
                        this.markerClient(locationV,map);
                        this.linesGeocerca(locationV,map);
                        await this.vendedorFill(map, locationV);
                        let coordenadas = [];
                        for(let c of locationV.ruta){
                            coordenadas.push({lat:c.latitud,lng:c.longitud, ruta:c})
                        }

                        this.lines.push({ coordenada: { lat: locationV.latitud, lng: locationV.longitud }, coordenadas: coordenadas, map: map, idVendedor:locationV.id, key: locationV.codigoVendedor, show: this.showLines, hide: undefined })
                        ///this.lines_geocerca.push({ line:poligon,map: map, key: vendedor.codigoVendedor, show: this.showLines, hide: undefined });
                        this.show_lines = { key: locationV.codigoVendedor, route: true }

                    }
                } else {
                    let l = this.locationVendedores.length > 0 ? this.locationVendedores[0] : undefined;
                    // console.log("que es esto L? ", l)
                    if (!l) return this.isLoading = false;
                    for (let locationV of this.locationVendedores) {
                        this.linesGeocerca(locationV,map);
                        this.markerClient(locationV,map);
                        await this.vendedorFill(map, locationV);
                        let coordenadas = [];
                        for(let c of locationV.ruta){
                            coordenadas.push({lat:c.latitud,lng:c.longitud, ruta:c})
                        }
                        this.lines.push({ coordenada: { lat: locationV.latitud, lng: locationV.longitud }, coordenadas: coordenadas, map: map, idVendedor:locationV.id, key: locationV.codigoVendedor, show: this.showLines, hide: undefined })
                        this.show_lines = { key: locationV.codigoVendedor, route: true }

                    }
                    let map_center = this.getCenter();
                    if(!map_center) {
                        let x = await this.getCalculeMapCenter();
                        if(x){
                            map.setCenter(x);
                        }
                    } else {
                        map.setCenter(map_center);
                    }
                }
                /*zoom change*/
                google.maps.event.addListener(map, 'zoom_changed', function() {
                    let zoomLevel = map.getZoom();
                    localStorage.setItem("zoom_level",zoomLevel);
                });
                google.maps.event.addListener(map, 'center_changed', function() {
                    localStorage.setItem("map_center",JSON.stringify({ lat:map.getCenter().lat(),lng:map.getCenter().lng()}));
                });
                this.isLoading = false;
            } catch (ex) {this.isLoading = false;}
        },
        showClientFilterClick() {
            this.showClientFilter = true;
        },
        showClientInfoClick() {

        },
        async filterChange(key, value) {
            if (key == 'close') {
                this.showClientFilter = false;
                this.filtered_length = (+value);
            } else if (key == 'filtered') {
                this.filtered_length = (+value.length);
                this.filterAdvance = value.filtro;
                await this.getVendedorLocationTimeRoute;
                await this.LoadMap();
            } else {}
        },
        async onlyButtonChange(key, value){
            if(value.key == 1){
                this.filter.isRoutes = true;
            } else {
                this.filter.isRoutes = false;
            }
            await this.getVendedorLocationTimeRoute;
            await this.LoadMap();

        },
        infoChange(key, value) {
            if (key == 'close') {
                this.showInfoVendedor = false;
            }
        },
        clientChange(key, value) {
            if (key == 'close') {
                this.showInfoCliente = false;
            }
        }
    },
    watch: {
        filter: {
            handler(a, b) {
                try {
                    if (a.isRoutes == false) {
                    } else {
                    }
                } catch (ex) {}
            },
            deep: true
        },
        show_lines: {
            handler(a, b) {
                try {
                    if (a.route == true) {
                        const line = this.lines.find(x => x.key == a.key)
                        if (line) line.show(line.key);
                    }
                } catch (ex) {}
            }
        },
        currentInfoVendedor: {
            async handler(a, b) {
                try {
                    if (a) {
                        if (a.info) {
                            this.is_client_clicked = false
                            if (a.info.route === false) {
                                this.buttonSuggetion = this.buttonSuggetion.map(x=>{
                                    if(x.key == 1)x.select = false;
                                    if(x.key == 2)x.select = true;
                                    return x;
                                })
                                this.filter.isRoutes = false;
                                await this.LoadMap();
                                const line = this.lines.find(x => x.key == a.info.codigoVendedor)
                                if (this.showInfoVendedor == true) {
                                    for (let item of this.lines) { item.show(item.key); }
                                    for (let item of this.clienteMarkers) {
                                        if(this.filter.isRoutes == false){
                                            item.marker.setVisible(item.type != "red");
                                        } else {
                                            item.marker.setVisible(true);
                                        }
                                    }
                                } else {
                                    if (line) line.show();
                                }
                            } else {
                                this.buttonSuggetion = this.buttonSuggetion.map(x=>{
                                    if(x.key == 1)x.select = true;
                                    if(x.key == 2)x.select = false;
                                    return x;
                                })
                                this.filter.isRoutes = true;
                                await this.LoadMap();
                                a.info.nombreCompleto = this.capitalize(a.info.nombre + ' ' + a.info.apellidos);
                                this.is_client_clicked = true
                                const line = this.lines.find(x => x.key == a.info.codigoVendedor);
                                if (this.showInfoVendedor == true) {
                                    for (let item of this.lines.filter(x => x.key != line.key)) { if (item) item.hide(); }

                                    for (let item of this.clienteMarkers.filter(x=>x.idVendedor == a.info.idVendedor)) {
                                        item.marker.setVisible(true);
                                    }
                                    for (let item of this.clienteMarkers.filter(x=>x.idVendedor != a.info.idVendedor)) {
                                        item.marker.setVisible(false);
                                    }

                                    for (let item of this.vendedorMarkers.filter(x=>x.key != a.info.idVendedor)) {
                                        item.marker.setVisible(false);
                                    }
                                    line.show(line.key);

                                } else {
                                    if (line) {
                                        line.show(line.key);
                                    }
                                }
                            }
                        }
                    } {
                        if (this.showInfoVendedor == false) {
                            for (let item of this.lines) {
                                item.show(item.key);
                            }
                        }

                    }
                } catch (ex) {
                    console.log(ex)
                }
            },
            deep: true
        }

    },
    async mounted() {
        localStorage.removeItem('map_center');
        try {
            this.isLoading = true;

            if (this.user.rol == 3) {
              this.filter.idDistribuidora = this.user.idDistribuidora;
              await this.getDistribuidora;
              await this.getMesaByDistribuidora;
              await this.getMesaByDistribuidora;
              await this.getVendedorListaByDistribuidora;
            }
            /*supervisor*/
            else if (this.user.rol == 4) {
                this.filter.idDistribuidora = this.user.idDistribuidora
                this.filter.codigoMesa = this.user.mesa
                await this.getDistribuidora;
                await this.getMesaByDistribuidora;
                await this.getMesaByDistribuidora;
                await this.getVendedorListaByDistribuidora;
            } else {
                await this.getDistribuidora;
                await this.getMesaByDistribuidora;
                await this.getMesaByDistribuidora;
                await this.getVendedorListaByDistribuidora;
            }
            await this.getFiltroAvanzadoParameterList;
            await this.getVendedorLocationTimeRoute;
            await this.getGeocercaList;
            //google maps
            const googleMapApi = await GoogleMapsApiLoader({
                apiKey: 'AIzaSyCx5F8PVblTCbM4um3f_4gTE3AUZLCr4QA'
            });
            this.google = googleMapApi;
            await this.LoadMap();
            this.isLoading = false;
        } catch (ex) {}
        this.Io;
    },
    data() {
        return {
            filtroParameters: [],
            isLoading: false,
            lTable: LiquidTable,
            distribuidoras: [],
            supervisores: [],
            vendedores: [],
            mesas: [],
            userInfo:{},
            buttonSuggetion:[
                {key:1,select:false,text:'Sugerida'},
                {key:2,select:true,text:'Real'}
            ],
            marketSet: {
              color: "white"
            },
            geocercas:[],
            filter: {
                idDistribuidora: 1,
                codigoMesa: '',
                idVendedor: '',
                isRoutes: false,
                isAlert: false,
                tipo: "mesas"
            },
            filterAdvance:{},
            clearFilter:false,
            google: null,
            map: null,
            showClientFilter: false,
            showInfoVendedor: false,
            currentInfoVendedor: {},
            currentInfoClient: {},
            locationVendedores: [],
            iconsMaps: {
              circleRed: '/img/maps/icon/circle-red.svg',
              circleOrange: '/img/maps/icon/circle-orange.svg',
              circleGreen: '/img/maps/icon/circle-green.svg',
              circleGray: '/img/maps/icon/circle-gray.svg',
              circlePulse: '/img/maps/icon/vendedor.png',
              circleCoronaGreen: '/img/maps/icon/circle-corona-green.svg',
              circleCoronaRed: '/img/maps/icon/circle-corona-red.svg',
              circleCoronaOrange: '/img/maps/icon/circle-corona-orange.svg',
              circleCoronaGray: '/img/maps/icon/circle-corona-gray.svg',
              romboCoronaFueraCordenada: '/img/maps/icon/rombo_fueracordenada_corona_green.svg',
              romboCoronaFueraCordenadaGray: '/img/maps/icon/rombo_fueracordenada_corona_gray.svg',
              romboFueraCordenada: '/img/maps/icon/rombo_fueracordenada_green.svg',
              romboFueraCordenadaGray: '/img/maps/icon/rombo_fueracordenada_gray.svg',
              phoneCoronaGreen: '/img/maps/icon/phone_corona_green.svg',
              phoneCoronaGray: '/img/maps/icon/phone_corona_gray.svg',
              phoneCoronaOrange: '/img/maps/icon/phone_corona_orange.svg',
              phoneCoronaRed: '/img/maps/icon/phone_corona_red.svg',
              phoneGreen: '/img/maps/icon/phone_green.svg',
              phoneGray: '/img/maps/icon/phone_gray.svg',
              phoneOrange: '/img/maps/icon/phone_orange.svg',
              phoneRed: '/img/maps/icon/phone_red.svg',
            },
            showInfoCliente: false,
            filtered_length: 0,
            is_client_clicked: false,
            lines: [],
            lines_geocerca: [],
            lines_suggestion:[],
            vendedorMarkers: [],
            clienteMarkers: [],
            show_lines: {},
            zoom_level:14
        };
    },
    async created() {

    },
};
</script>

<style lang="scss">
.table_laive {
    border-collapse: collapse;
    width: 100%;
    margin: 0;

    td,
    th {
        border: 1px solid #f3f3f3;
        text-align: left;
        padding: 8px;
    }

    tr:nth-child(even) {
        background-color: #f3f3f3;
    }

    td {
        a : {
            cursor: pointer !important
        }
    }
}

.swal-wide {
    .swal2-popup {
        width: 56em;
    }
}

.el-table .text-warning {
    color: #fb7f00 !important;
}

//togle
.custom-toggle {
    margin-top: 3px !important;

    input:checked+.custom-toggle-slider {
        border: 1px solid #07C25E !important;
        background: #07C25E !important;
    }

    input:checked+.custom-toggle-slider:before {
        background: #f7fafc !important;
    }

    input:checked+.custom-toggle-slider:after {
        color: #f7fafc !important;
    }
}

.btn-filter {

    border: 1px solid #E0E0E0 !important;
    transition: all 0.15s ease-in-out !important;
    height: calc(1.5em + 1.25rem + 5px) !important;
    padding-left: 6px !important;
    padding-right: 6px !important;
    padding-bottom: auto !important;

    display: flex !important;
    align-content: center;
    align-items: center;

    span {
        margin-left: auto;
        margin-right: auto;
        letter-spacing: 0.001em !important;
        line-height: 14px !important;
        font-weight: 500 !important;
        font-style: normal !important;
        font-size: 0.875rem !important;
        color: #636363 !important;
    }

    &[is-filtered="true"] {
        span {
            color: #337AD0 !important;
        }

        border: 1px solid #337AD0 !important;

        .icon-filter {
            display: none;
        }
    }

    &[is-filtered="false"] {
        .icon-indicator {
            display: none;
        }
    }

    .icon-indicator {
        border-radius: 100px;
        background: #337AD0 !important;
        width: 24px;
        min-width: 24px;
        height: 24px;
        color: white !important;
        margin-left: 3px !important;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
    }

    .icon-filter {
        background: url("~img/icons/filter.svg") no-repeat;
        width: 14px;
        height: 12px;
        /* background: red; */
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
        margin-left: 3px;

    }
}

.container-fluid {
    &[client-clicked="false"] {
        .maps-container {
            margin-left: -30px !important;
            margin-right: -30px !important;
            margin-top: -122px !important;
        }
    }

    &[client-clicked="true"] {
        .maps-container {
            margin-left: -30px !important;
            margin-right: -30px !important;
            margin-top: -69px !important;
        }
    }
}

.client-bar {
    display: flex;
    align-items: center;

    i {
        margin-right: 16px;
        cursor: pointer;
        color: #1A1818;
    }

    .client-name {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
        display: flex;
        align-items: center;
        letter-spacing: 0.2px;
        color: #1A1818;
    }

    .client-code {
        margin-left: auto;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        text-align: right;
        color: #6E6E6E;

        strong {
            margin-left: 5px;
        }
    }
}

.maps-container {
    height: 800px !important;
    position: relative !important;

    .google-map {
        width: 100%;
        height: 100%;
    }
}

.gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-t::after {
    background: #1A1818;
    color: white;
}
</style>
