<template>
  <div class="content">
    <base-header class="">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>

    <box-loader :loading="loading">
      <div class="container-fluid">
        <div class="form_laive">
          <div class="box_loader">
            <div class="loader"></div>
          </div>
          <card
            class="no-border-card"
            body-classes="px-0 pb-1"
            footer-classes="pb-2"
          >
            <template v-slot:header>
              <div class="row">
                <div class="col-12 col-md-6">
                  <h3 class="mb-0">Carga de Productos Imperdonables</h3>
                </div>
              </div>
            </template>
            <div>
              <div class="px-3 pb-3">
                <form @submit.prevent="submitExcel" class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="col-form-label form-control-label"
                        >Archivo:</label
                      >
                      <file-input
                        @change="onChangeFile"
                        required
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      ></file-input>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group date-select">
                      <label class="col-form-label form-control-label"
                        >Fecha de Inicio:
                      </label>
                      <DateSelect
                        v-model="inicio"
                        showIcon
                        calendarClass="th-calendar"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group date-select">
                      <label class="col-form-label form-control-label"
                        >Fecha Fin:
                      </label>
                      <DateSelect
                        v-model="fin"
                        showIcon
                        calendarClass="th-calendar"
                        :config="{
                          minDate: inicio,
                        }"
                      />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="notes_editor">
                      <p><strong>Consideraciones del Archivo</strong></p>
                      <ul>
                        <li>El archivo debe ser un Excel</li>
                      </ul>
                    </div>
                    <div class="d-flex align-item-center">
                      <base-button
                        type="primary"
                        native-type="submit"
                        class="btn-default my-0"
                      >
                        Subir Productos Imperdonables
                      </base-button>
                      <a
                        class="th-btn th-btn-border-pry text-sm align-items-center d-flex flex-row"
                        href="/plantillas/productos-imperdonable-plantilla.xlsx"
                        download
                        @click="download"
                      >
                        Descargar plantilla
                        <i class="laive-i icon-download ml-2" />
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </card>
        </div>
      </div>
    </box-loader>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import flatPicker from "vue-flatpickr-component";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import { useAppToast } from "../../composables";

import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BasePagination from "@/components/BasePagination";
import FileInput from "@/components/Inputs/FileInput";
import { DateSelect } from "@/components/selects";
import DashboardNavbar from "../Layout/DashboardNavbar";

import { serviceMaestroCarga } from "../../services/maestroCarga";

import "flatpickr/dist/flatpickr.css";

const toast = useAppToast();

export default {
  components: {
    DashboardNavbar,
    BasePagination,
    RouteBreadCrumb,
    FileInput,
    flatPicker,
    DateSelect,
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
  methods: {
    async submitExcel() {
      try {
        this.loading = true;

        if (this.file === null) {
          toast.error("Seleccione el archivo excel");
          return;
        }

        if (this.inicio === "" || this.inicio.length === 0) {
          toast.error("Seleccione la fecha de inicio");
          return;
        }

        if (this.fin === "" || this.fin.length === 0) {
          toast.error("Seleccione la fecha de fin");
          return;
        }

        const file = this.file;

        var formData = new FormData();

        formData.append("file", file);
        formData.append("inicio", this.inicio);
        formData.append("fin", this.fin);

        await serviceMaestroCarga.uploadProductosImperdonables(formData);

        /** @type {HTMLInputElement} */
        const fileInput = document.getElementById("customFileLang");
        fileInput.value = null;

        this.file = null;
        this.inicio = "";
        this.fin = "";
        document.querySelector(".custom-file-label").innerText =
          "Seleccionar Archivo";

        toast.success("Se subío correctamente la plantilla");
      } catch (error) {
        toast.error("Hubo un problema con la carga del archivo");
      } finally {
        this.loading = false;
      }
    },
    onChangeFile() {
      const excelfile = document.querySelector("#customFileLang");
      this.file = excelfile.files[0];
    },
  },
  mounted() {
    if (this.user.rol == 3) {
      this.distribuidoraIdCurrent = this.user.idDistribuidora;
    }
    if (this.user.rol == 4) {
      this.supervisorIdCurrent = this.user.codigo;
      this.supervisorNameCurrent = this.user.nombre;
      this.distribuidoraIdCurrent = this.user.idDistribuidora;
    }
  },
  data() {
    return {
      loading: false,
      files: [],
      configDate: {
        altInput: true,
        allowInput: true,
        locale: Spanish, // locale for this instance only
      },
      file: null,
      inicio: "",
      fin: "",
    };
  },
};
</script>
<style lang="scss">
.page-item .page-link .fa {
  z-index: 0;
}
.notes_editor {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  font-size: 0.875rem;

  p {
    margin: 0;
  }

  ul {
    padding-left: 1.5rem;
  }
}

.date-select {
  height: 2.875rem;

  .date-picker {
    flex: 1;
    height: 100%;
  }

  input {
    font-size: 0.75rem;
    font-weight: 300;
    height: 100%;
  }
}
</style>
