<template>
    <div class='question-card'>
        <div class='body'>
            <div class='question-bar'>
                <div class='col-8'>
                    <div class ='form-group mb-0'>
                        <label>Pregunta {{(this.getQuestionIndex() + 1)}}</label>
                        <input class='form-control' v-model="this.question.title" type='text' placeholder='Escriba una pregunta' @change="changeControl()" />
                    </div>
                </div>
                <div class='col-4'>
                    <div class ='form-group mb-0'>
                        <label>Tipo de respuesta</label>
                        <select class='form-control' v-model="this.question.type" @change="changeControl()">
                            <option v-for="item of questionTypes" :value="item.codigoDato">{{item.nombre}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div v-if="this.question.type == 'multiple'" class='type-option'>
                <div v-for="(res,index) in this.question.options" class='option col-6'>
                    <div class='index-number'>{{(index + 1)}}.</div>
                    <input class='form-control ml-2' v-model="res.option" type='text' placeholder='Escriba una opción' @change="changeControl()" />
                    <i class='icon-quit ml-2' @click="quitOption(index)"></i>
                </div>
                <div class='option col-6'>
                    <a class ='btn-add' @click="addOption()">Agregar otra opción</a>
                </div>
            </div>

            <div v-if="this.question.type == 'unique'" class='type-option'>
                <div class='col-12'>
                    <div v-for="(res,index) in this.question.options" class='row '>
                        <div  class='option col-6'>
                            <div class='index-number'>{{(index + 1)}}.</div>
                            <input class='form-control ml-2' v-model="res.option" type='text' placeholder='Escriba una opción' @change="changeControl()" />
                            <i class='icon-quit ml-2' @click="quitOption(index)"></i>
                        </div>
                        <div v-if="this.isCondicional" class='option col-4 pl-0'>
                            <select class='form-control' v-model="res.goto">
                                <option v-for="op of this.getQuestions()" :value="op.id" >{{op.name}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class='option col-6'>
                    <a class ='btn-add' @click="addOption()">Agregar otra opción</a>
                </div>
            </div>
            <div v-if="this.question.type == 'multitext'" class='type-option'>
                <div class='col-12'>
                    <div v-for="(res,index) in this.question.options" class='row '>
                        <div  class='option col-6'>
                            <div class='index-number'>{{(index + 1)}}.</div>
                            <input class='form-control ml-2' v-model="res.option" type='text' placeholder='Escriba una opción' @change="changeControl()" />
                            <i class='icon-quit ml-2' @click="quitOption(index)"></i>
                        </div>
                        <div class='option col-4 pl-0'>
                            <label class='label-text'>Respuesta del encuestado</label>
                        </div>
                    </div>
                </div>

                <div class='option col-6'>
                    <a class ='btn-add' @click="addOption()">Agregar otra opción</a>
                </div>
            </div>
        </div>
        <div class='footer'>
            <a class='btn-add' @click="addQuestion()"> <i class ='fa fa-plus-circle'></i> Añadir pregunta</a>
            <a v-if="this.question.type == 'unique'" class='btn-condicion' :class="{active:this.isCondicional}" @click="condicionarRespuesta()"><i></i> Condicionar respuestas ({{this.countCondition}})</a>
            <a class='btn-delete' @click="quitQuestion()"><i class='fa fa-trash'></i> Eliminar</a>
            <span class='btn-option d-flex align-items-center'>
                <label class="custom-toggle ">
                    <input type="checkbox" v-model="this.question.required" @change="changeControl()">
                    <span class="custom-toggle-slider rounded-circle"></span>
                </label>
                <span class='ml-2'>Obligatoria</span>
            </span>
        </div>

        <!--condition card-->
        <div v-if="this.isCondicional" v-for="(condicion,index) in this.question.condition" class='condition-card'>
            <div class='body'>
                <div class='question-bar'>
                    <div class='col-8'>
                        <div class ='form-group mb-0'>
                            <label>Respuesta condicional: “”</label>
                            <input class='form-control' v-model="condicion.title" type='text' placeholder='Escriba una pregunta' @change="changeControl()" />
                        </div>
                    </div>
                    <div class='col-4'>
                        <div class ='form-group mb-0'>
                            <label>Tipo de respuesta {{condicion.type}}</label>
                            <select class='form-control' v-model="condicion.type" @change="changeControl()">
                                <option v-for="item of this.getTypesForCondition()" :value="item.codigoDato">{{item.nombre}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div v-if="condicion.type == 'multiple' || condicion.type == 'unique'" class='type-option'>
                    <div v-for="(res,index) in condicion.options" class='option col-6'>
                        <div class='index-number'>{{(index + 1)}}.</div>
                        <input class='form-control ml-2' v-model="res.option" type='text' placeholder='Escriba una opción' @change="changeControl()" />
                        <i class='icon-quit ml-2' @click="quitOptionCondition(condicion,index)"></i>
                    </div>
                    <div class='option col-6'>
                        <a class ='btn-add' @click="addOptionCondition(condicion)">Agregar otra opción</a>
                    </div>
                </div>

                <div v-if="condicion.type == 'multitext'" class='type-option'>
                    <div class='col-12'>
                        <div v-for="(res,index) in condicion.options" class='row '>
                            <div  class='option col-6'>
                                <div class='index-number'>{{(index + 1)}}.</div>
                                <input class='form-control ml-2' v-model="res.option" type='text' placeholder='Escriba una opción' @change="changeControl()" />
                                <i class='icon-quit ml-2' @click="quitOptionCondition(condicion,index)"></i>
                            </div>
                            <div class='option col-4 pl-0'>
                                <label class='label-text'>Respuesta del encuestado</label>
                            </div>
                        </div>
                    </div>

                    <div class='option col-6'>
                        <a class ='btn-add' @click="addOptionCondition(condicion)">Agregar otra opción</a>
                    </div>
                </div>
            </div>
            <div class='footer'>
                <a class='btn-add' @click="addCondition(condicion,index)"> <i class ='fa fa-plus-circle'></i> Añadir pregunta</a>
                <a class='btn-delete' @click="quitCondition(condicion)"><i class='fa fa-trash'></i> Eliminar</a>
                <span class='btn-option d-flex align-items-center'>
                    <label class="custom-toggle ">
                        <input type="checkbox" v-model="condicion.required" @change="changeControl()">
                        <span class="custom-toggle-slider rounded-circle"></span>
                    </label>
                    <span class='ml-2'>Obligatoria</span>
                </span>
            </div>
        </div>
    </div>


</template>
<script>
export default {
    props:{
        questionTypes:[],
        question:{},
        questions:[],
        indexCard:0
    },
    created(){

    },
    methods:{
        changeControl(){
            this.$emit('changed','input',this.question);
        },
        addOption(){
            try{
                if(!this.question.options) this.question.options = [];
                let idOpt = `opt_${this.question.options.length + 1}`
                console.log(idOpt)
                this.question.options.push({id:idOpt, option:'',goto:null});
                this.changeControl();
            }
            catch(ex){console.log(ex)}
        },
        quitOption(_index){
            try{
                this.question.options.splice(_index,1)
                this.changeControl();
            }
            catch(ex){console.log(ex)}
        },
        addOptionCondition(row){
            try{
                if(!row.options) row.options = [];
                row.options.push({id:``,option:'',goto:null});
                this.changeControl();
            }
            catch(ex){console.log(ex)}
        },
        quitOptionCondition(row,_index){
            try{
                row.options.splice(_index,1)
                this.changeControl();
            }
            catch(ex){console.log(ex)}
        },
        getQuestionIndex(){
            let position = this.questions.findIndex(x=>x.id== this.question.id);
            return position;
        },
        addQuestion(){
            try{
                let nOpcion = {
                    id:`preg_${(this.questions.length + 1) }`,
                    title:'',
                    type:'multiple',
                    required:false
                }
                this.questions.splice(this.indexCard + 1,0,nOpcion)
                this.changeControl();
            }
            catch(ex){console.log(ex)}
        },
        quitQuestion(){
            try{
                let _index = this.getQuestionIndex();
                this.questions.splice(_index,1)
                this.changeControl();
            }
            catch(ex){console.log(ex)}
        },

        addCondition(condition,_index){
            try{
                if(this.question.condition){
                    let nOpcion = {
                        id:`cond_${this.question.condition.length + 1 }`,
                        title:'',
                        type:'multiple',
                        required:false,
                        options:[],
                        condition:null
                    }
                    this.question.condition.splice((_index + 1),0,nOpcion)
                    this.changeControl();
                }
            }
            catch(ex){console.log(ex)}
        },
        quitCondition(condition){
            try{
                if(this.question.condition){
                    let position = this.question.condition.findIndex(x=>x.id == condition.id);
                    if(position == -1) return;
                    this.question.condition.splice(position,1);
                    if(this.question.condition.length == 0){
                        this.question.condition = null;
                        this.isCondicional = false;
                    }
                    this.changeControl();
                }
            }
            catch(ex){console.log(ex)}
        },

        condicionarRespuesta(){
            if(this.isCondicional){
                this.isCondicional = false;
            } else {
                this.isCondicional = true;

                this.question.condition = (this.question.condition ? this.question.condition.length > 0 ? true : false : false ) ? this.question.condition : [
                    {
                        id:"cond_1",
                        title:"",
                        type:"multiple",
                        required:false,
                        options:[],
                        condition:null
                    }
                ];
            }

        },
        getTypesForCondition(){
            let result =[];
            for(let item of this.questionTypes){
                if(item.type !='unique'){
                    result.push(item);
                }
            }
            return result;
        },
        /* condiciones */
        getConditions(){
            let result =[];
            for(let item of this.questions){
                if(item.condition) {
                    for(let con of item.condition){
                        result.push(con);
                    }
                }
            }
            return result;
        },

        getQuestions(){
            try{
                //console.log('preguntas ssss',this.questions)
                if(!this.questions) return [];
                let result = [];
                for(let item of this.questions){
                    result.push({id:item.id, name: this.continuarPreguntaText(item.id),type:1});
                }
                for(let item of this.questions){
                    if(item.condition != null){
                        for(let c of item.condition){
                            result.push({id:c.id, name: this.continuarPreguntaConText(c.id),type:2});
                        }
                    }
                }
                return result.sort();
            }
            catch(ex){console.log(ex)}
        },
        continuarPreguntaText(text){
            return `Continuar con la Pregunta ${(text+'').replace('preg_','')}`;
        },
        continuarPreguntaConText(text){
            return `Ir a Pregunta condicionada ${(text+'').replace('preg_','').replace('cond_','')}`;
        },
        checkIsCondicionada(item){
          //  if(!item.condition ? item.condition == null) return false;


        }

    },
    computed:{
        countCondition(){
            return this.question.condition ? this.question.condition.length : 0;
        }
    },
    data(){
        return {
            isCondicional:false
        }
    },

};
</script>
<style lang="scss">
.question-card {
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background:white;

    .condition-card {
        background: #E6F0FF;
        margin: 5px 5px 10px 5px;
        border: 1px solid #e5e5e5;
    }
    .body {
        padding:10px;

        .question-bar {
            display:flex;
            align-items:center;

            .form-group {
                label {
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 20px;
                    letter-spacing: 0.2px;
                    color: #1A1818;
                    margin-bottom:4px!important;
                }
            }
        }
        .type-option {
            width:100%;
            .option {
                display:flex;
                align-items:center;
                margin-top:12px;
                .index-number {
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.2px;
                    color: #1A1818;
                }
                .btn-add {
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.2px;
                    color: #02A54F;
                    cursor:pointer;
                    margin-left:1rem;
                }
                i {
                    &.icon-quit {
                        background: url("~img/icons/close.svg") no-repeat;
                        width: 15px;
                        height: 15px;
                        cursor:pointer;
                    }
                }

                .label-text {
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 24px;
                    color: #6E6E6E;
                    border-bottom: 1px solid #B8B8B8;
                    margin-bottom: 0px;
                    padding: 5px 0px;
                    width: 100%;
                }
            }
        }
    }
    .footer {
        padding:10px;
        background: #F5F5F5;
        border-radius: 0px 0px 4px 4px;
        font-family: Poppins;

        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #1A1818;

        display:flex;
        align-items:center;
        .btn-add {
            color: #1A1818;
            cursor: pointer;
            i {

            }
        }
        .btn-condicion {
            margin-left:40px;
            color: #1A1818;
            cursor: pointer;
            display:flex;
            i {
                background: url("~img/icons/icon-condicion.svg") no-repeat;
                width: 21px!important;
                height: 19px!important;
                display: block;
            }
            &.active {
                color:#378be2;
            }
        }
        .btn-delete {
            margin-left:40px;
            color: #1A1818;
            cursor: pointer;
            i {

            }
        }

        .btn-option {
            margin-left:40px;
        }
    }

    .form-group {

    }
    .form-control {
        height:40px!important;
        color: #1A1818;
    }

    .custom-toggle input:checked + .custom-toggle-slider {
        border: 1px solid #07C25E !important;
        background: #07C25E !important;
    }

    .custom-toggle input:checked + .custom-toggle-slider:before {
        background: #ffffff;
        transform: translateX(28px);
    }
}

</style>
