import { mapActions, mapGetters } from 'vuex';
import EncuestaService from '../services/EncuestaService';
import axios from 'axios';
export default { 
    namespaced: true,
    state:{},
    getters:{},
    mutations:{},
    methods:{ },
    actions:{
        async getTipoPregunta({state,commit},data) {
            return await EncuestaService.getTipoPregunta(axios,data);
        },
        async getDataPreguntas({state,commit},data) {
            return await EncuestaService.getDataPreguntas(axios,data);
        },
        async setDataPreguntas({state,commit},data) {
            return await EncuestaService.setDataPreguntas(axios,data);
        },
        async insDataPreguntas({state,commit},data) {
            return await EncuestaService.insDataPreguntas(axios,data);
        },
        
        
    }
}