<template>
<div class='right-sidebar show' v-if='show'>
    <!-- filtros avanzados -->
    <div class='card no-border info-vendedor'>
        <div class='card-header d-flex position-relative'>
            <h4 class="card-title m-0">Información del día de hoy</h4>
            <a class="btn-close ml-auto" @click='close()'><i class='fa fa-times'></i></a>
        </div>
        <div class='card-body'>
            <div class='row'>
                <div class='col-12 information'>
                    <h5>{{current.info?.completeName}}</h5>
                    <p class='codigo'><strong>Cód:</strong> {{current.info?.codigoVendedor}}</p>
                    <div class='row-icon'>
                        <i  class='icon-chart-line'></i>
                        <span class='text-left'>Ventas</span>
                        <span class='text-right'><strong>S/{{current.ventas?.real}}</strong>de S/{{current.ventas?.objetivo | rounded}}</span>
                    </div>
                    <div class='row-icon'>
                        <i  class='icon-user-info'></i>
                        <span class='text-left'>Visitas efectivas</span>
                        <span class='text-right'><strong>{{current.visitas?.real}}</strong>de {{current.visitas?.objetivo }}</span>
                    </div>
                    <div class='row-icon'>
                        <i  class='icon-money'></i>
                        <span class='text-left'>Cobros</span>
                        <span class='text-right'><strong>S/{{current.cobros?.real}}</strong>de S/{{current.cobros?.objetivo | rounded}}</span>
                    </div>

                    <div class='row-icon'>
                        <i  class='icon-surtido fa fa-picture-o'></i>
                        <span class='text-left'>Surtidos</span>
                        <span class='text-right'><strong>{{current.surtidos?.real}}</strong>de {{current.surtidos?.objetivo | rounded}}</span>
                    </div>

                    <div class='row-icon'>
                        <i  class='icon-devolucion fa fa-picture-o'></i>
                        <span class='text-left'>Devoluciones</span>
                        <span class='text-right'><strong>S/{{current.devoluciones?.total}}</strong>de S/{{current.devoluciones?.maximo | rounded}}</span>
                    </div>

                    <h5 class='mt-2'>Alertas</h5>
                    
                    <a class="btn btn-notification" data-toggle="collapse" href="#xnotification" role="button" aria-expanded="false" aria-controls="xnotification">
                        <span class='notification-number' >{{current.notificaciones?.length}}</span>
                        <i class='fa fa-bell danger'></i>
                        <span class='text-right'>Ver notificaciones</span>
                        <i class="fas fa-angle-down"></i>
                    </a>
                    <div class="collapse" id="xnotification">
                        <div class="card card-body notification-detail">
                            <table v-if='current.notificaciones'>
                                <tbody>
                                    <tr v-for='item of current.notificaciones'>
                                        <td class='col-time'>{{(item.hora+'')}}</td>
                                        <td>{{item.mensaje}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <!--ruta sugerida-->
                    <h5  class='mt-3 d-flex align-items-center'>
                        <span>Mostrar ruta sugerida</span>
                        <label class="custom-toggle ml-2">
                            <input type="checkbox" v-model='current.info.route'>
                            <span class="custom-toggle-slider rounded-circle"></span>
                        </label>
                    </h5>
                    
                </div>
            </div>
        </div>
    </div>
</div>
<div class='overflow-right-sidebar d-none' @click='close()' v-if='show'></div>
</template>

<script>
import SelectButton from './SelectButton';
export default {
    props: {
        show: false,
        showSugget:false,
        current:{
            cobros:{objetivo:0,real:0},
            visitas:{objetivo:0,real:0},
            ventas:{objetivo:0,real:0},
            info: {
                route:false
            },
            notificaciones:[]
        }
    },
    created(){
        console.log(this.current);
    },
    components: {SelectButton},
    methods: {
        close() {
            this.$emit("change", 'close',false);
        },
        options(pos) {
            this.$emit("change", 'option', pos);
        },
        clear() {
            this.$emit("change", 'clear', true);
        },
    },
    watch: { 
      	show: function(a, b) {
           let body = document.getElementsByTagName('body')[0];
          if(a == true){
            if(body) body.classList.add("body-modal-overflow");
          } else {
              if(body) body.classList.remove("body-modal-overflow");;
          }
        }
    },
    filters:{
        datetime(s){
            console.log(s);
            return moment(s).format('HH:mm');
        },
        rounded(a){
            console.log(a);
        }
    },
    computed:{
        modalHeigth(){

        }
    },
    data: () => {
        return {
        }
    }
};
</script>

<style lang="scss">
.body-modal-overflow {
    overflow:hidden;
}
.info-vendedor {
    .information {
        h5 {
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #1A1818;
        }
        .codigo {
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.2px;
            color: #1A1818;
            margin-bottom: 4px;
            strong {

            }
        }
        .row-icon {
            display: flex;
            vertical-align: middle;
            align-items: center;
            padding-top: 14px;
            padding-bottom: 0px;

            i {
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                line-height: 1;
                margin-right: 16px;

                &.icon-chart-line {
                    background: url("~img/icons/chart-line.svg") no-repeat;
                    width: 16px;
                    height: 10px;
                }

                &.icon-user-info {
                    background: url("~img/icons/user-info.svg") no-repeat;
                    width: 16px;
                    height: 10px;
                }

                &.icon-money {
                    background: url("~img/icons/money.svg") no-repeat;
                    width: 16px;
                    height: 10px;
                }

                &.icon-surtido {
                    background: url("~img/icons/surtido_blue.svg") no-repeat;
                    width: 16px;
                    height: 10px;
                }

                &.icon-devolucion {
                    background: url("~img/icons/devolucion-lime.svg") no-repeat;
                    width: 16px;
                    height: 10px;
                }
            }
            .text-left {
                font-family: Poppins;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 20px;
                display: flex;
                align-items: center;
                letter-spacing: 0.2px;
                color: #6E6E6E;
            }
            .text-right {
                margin-left:auto;
                font-family: Poppins;
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: #6E6E6E;
                strong {
                    margin-right:5px;
                }
            }
        }

        .btn-notification {
            background: #FFFFFF;
            box-shadow: 0px 2px 9px rgba(166, 179, 194, 0.25);
            border-radius: 8px;
            display:flex;
            align-items: center;
            margin-right: 0px;
            .notification-number {
                font-family: Poppins;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 16px;
                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.2px;
                color:#ED1C24;
            }
            .fa-bell.danger {
                color:#ED1C24;
            }
            .text-right {
                margin-left:auto;
                font-family: Poppins;
                font-style: normal;
                font-weight: 500;
                font-size: 11px;
                line-height: 16px;
                display: flex;
                align-items: center;
                letter-spacing: 0.2px;
                color: #1A1818;
            }

            &[aria-expanded="true"]{
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
                .text-right {
                    color: #02A54F;
                }
                .fa-angle-down {
                    color: #02A54F;
                }
            }
        }

        .notification-detail {
            font-family: Poppins;
            font-style: normal;
            font-weight: 300;
            font-size: 11px;
            line-height: 16px;
            letter-spacing: 0.2px;
            padding-top: 0px;
            background: #FFFFFF;
            box-shadow: 0px 2px 9px rgba(166 ,179 ,194,0.25);
            border-radius: 8px;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            border-top: none;
            table {
                tbody {
                    tr {
                        td {
                            padding-top: 8px;
                            padding-bottom: 8px;
                            &.col-time {
                                padding-right: 7px;
                                min-width: 70px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.overflow-right-sidebar {
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: #00000054;
    position: fixed;
    left: 0px;
    top: 0px;
}
.right-sidebar {
    position: fixed;
    top: 0px;
    right: 0px;
    width: 390px;
    height: 100%;
    background: white;
    z-index: 10000;
    border-left: 1px solid #dee2e6;
    display: none;
    overflow-y:auto;
    .space {
        margin-top: 110px;
    }

    &.show {
        display: block !important;
    }

    .btn-close {
        i {
            background: #F3F3F3!important;
            padding: 7px 9px!important;
            border-radius: 100px!important;
            cursor: pointer!important;
            color: #1A1818!important;
        }
    }
}

[aria-expanded="true"] .fa-angle-down {
    transform: rotate(180deg);
}

.card-header {
    
    i.fa-angle-down {
        position: absolute !important;
        right: 25px !important;
        top: 40% !important;
    }
}

</style>
