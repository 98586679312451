<template>
  <div class="SelectButton">

    <span class="title_filter mr-2">{{ label }}</span>

    <div class="dropdown-container" @click="toggleDropdownList()">
      <input ref="inputFieldRef" class="field field-dropdown"
        :value="isLoading ? '' : ((hasId === true) ? selectedOption['texto'] : selectedOption['valor'])"
        :required="required" :disabled="isDisabled" :class="{ 'with-content': !!selectedOption }" readonly />

      <i class="laive-i icon-arrow-down"></i>
      <ul class="dropdown-list" aria-expanded="false" ref="dropdownListRef" :class="{ 'disabled-input': isDisabled }">
        <li v-if="isSearchable" class="list-item">
          <div class="list-container">
            <input v-model="inputSearch" type="text" placeholder="Buscar" />
            <i class="laive-i icon-search"></i>
          </div>
        </li>
        <div v-if="inputSearch && !filteredList().length">
          <p class="text-default">No se encontraron resultados!</p>
        </div>
        <div class="list-scroll">
          <li v-for="(option, index) in filteredList()" :key="index" class="list-item">
            <a class="list-link" @click.prevent="changeSelectedOption(option)">{{ (hasId == true) ? option['texto'] :
              option['valor'] }}</a>
          </li>
        </div>
      </ul>
    </div>
    <span class="message-error" v-if="errors[0]">{{ errors[0] }}</span>
  </div>
</template>

<script>
import { ref, onBeforeUnmount } from 'vue';

export default {
  props: {
    label: {
      type: String,
      default: "Tipo"
    },
    value: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Number],
      description: "Input value",
    },
    options: {
      type: Array,
      default: []
    },
    isSearchable: {
      type: [Boolean, String],
      default: false
    },
    required: {
      type: [Boolean, String],
      default: false
    },
    isDisabled: {
      type: [Boolean, String],
      default: false
    },
    hasId: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    selectedOption() {
      let opt = "";
      if (this.hasId == true && this.modelValue !== null && this.modelValue !== undefined) {
        this.isLoading = false;
        const option = this.options.find(({ valor }) => (this.modelValue == valor));
        if (option) {
          opt = option;
          setTimeout(() => {
            this.$refs["inputFieldRef"].value = option ? option.texto : '';
          }, 100);
        }
      } else {
        setTimeout(() => {
          this.$refs["inputFieldRef"].value = '';
        }, 100);
      }
      return opt;
    }
  },

  setup(props, { emit }) {
    const modelValue = ref(props.modelValue || props.value);
    const inputSearch = ref('');
    const isScroll = ref(false);
    const availableOption = ref('');
    const error = ref([]);
    const errors = ref([]);
    const dropdownListRef = ref(null);
    const inputFieldRef = ref(null);
    const isLoading = ref(true);
    // const selectedOption = ref('');
    const outsideClickHandler = ref(null);

    const toggleDropdownList = () => {

      if (props.isDisabled) {
        return;
      }

      const dropdownList = dropdownListRef.value;
      const isDropdownExpanded = dropdownList.getAttribute('aria-expanded') === 'true';

      if (isDropdownExpanded) {
        dropdownList.setAttribute('aria-expanded', 'false');
        dropdownList.style.height = '0';
        setTimeout(() => {
          dropdownList.parentNode.removeAttribute('style');
        }, 1);

        // Remove the outside click event listener when closing the dropdown
        document.removeEventListener('click', outsideClickHandler.value);
      } else {
        dropdownList.parentNode.style.zIndex = '4';
        dropdownList.setAttribute('aria-expanded', 'true');

        /***/
        const dropdownListScroll = dropdownList.querySelector('.list-scroll');
        /***/

        const dropdownListItems = dropdownList.querySelectorAll('.list-item');
        const dropdownListItemsHeight = dropdownListItems[0].offsetHeight;
        const dropdownListItemsLength = dropdownListItems.length + 1;
        const dropdownListHeight = dropdownListItemsHeight * dropdownListItemsLength + 12;

        /***/
        (dropdownListHeight > 250) ? isScroll.value = true : isScroll.value = false;
        const dropScrollHeight = dropdownListHeight > 250 ? 250 : dropdownListHeight;
        let scrollHeight = dropScrollHeight + 100

        if (isScroll.value) {
          scrollHeight = dropScrollHeight - 106
          dropdownListScroll.classList.add("active");
          dropdownListScroll.style.height = `${scrollHeight + 10}px`;
          dropdownList.style.height = `${scrollHeight + 60}px`;
        } else {

          dropdownListScroll.style.height = `${dropdownListHeight}px`;
          dropdownList.style.height = `${dropdownListHeight}px`;
        }

        /***/

        // Add the outside click event listener when opening the dropdown
        outsideClickHandler.value = (event) => {
          if (!dropdownList.contains(event.target)) {
            dropdownList.setAttribute('aria-expanded', 'false');
            dropdownList.style.height = '0';
            setTimeout(() => {
              dropdownList.parentNode.removeAttribute('style');
            }, 1);

            // Remove the outside click event listener after hiding the dropdown
            document.removeEventListener('click', outsideClickHandler.value);
          }
        };
        document.addEventListener('click', outsideClickHandler.value);
      }
    }


    onBeforeUnmount(() => {
      document.removeEventListener('click', outsideClickHandler.value);
    });


    const changeSelectedOption = (option) => {
      // selectedOption.value = option;
      handleInputChange(option);

    }

    const handleInputChange = (option) => {
      modelValue.value = option.valor;
      emit('update:modelValue', option.valor);
      inputFieldRef.value.dispatchEvent(new Event('change', { bubbles: true }));

      setTimeout(() => {
        dropdownListRef.value.parentNode.querySelector(".field-dropdown").value = (props.hasId === true) ? option.texto : option.valor;
      }, 100);
    }

    const filteredList = () => {
      let opts = [];

      if (Array.isArray(props.options)) {
        opts = props.options.filter((item) => {
          if (item.nameProduct) {
            const searchTerm = inputSearch.value.toLowerCase();
            const itemValue = (props.hasId === true) ? item.texto : item.valor;
            return itemValue.toLowerCase().includes(searchTerm);
          }
          return [];
        });
      }

      return opts;
    }

    return {
      inputValue: modelValue,
      dropdownListRef,
      inputFieldRef,
      toggleDropdownList,
      changeSelectedOption,
      availableOption,
      inputSearch,
      filteredList,
      error,
      errors,
      handleInputChange,
      isLoading,
      // selectedOption,

      onBeforeUnmount() {
        document.removeEventListener('click', outsideClickHandler.value);
      }
    }

  }
}
</script>


<style lang="scss" scoped>
@import "../../assets/sass/laive-components/input-field.scss";
@import "../../assets/sass/laive-components/dropdown.scss";
@import "../../assets/sass/laive-components/icons.scss";

.SelectButton {
  width: 100%;
}
</style>
