<template>
  <div id="pdf-to-export" class="content bonificacion" >
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div :class="{ validateClient: !all_loaded }" class="form_laive" >
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card bg-white"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
          headerClasses="border-0 px-0 hide-export"
        >
          <template v-slot:header>
            <div class="row p-0 mx-0">
              <div class="col-12 col-md-6 d-flex align-items-center px-3">
                <span class="mb-0 title"><b>Bonificaciones</b></span>
              </div>
              <div class="col-12 col-md-6 text-right">
                <div class="row mx-0 px-0">
                  <base-button
                    type="success"
                    :outline="true"
                    class="ml-auto px-4 py-3 submit-btns d-flex w-auto"
                    @click.prevent="descargar"
                  >
                    <i class="fas fa-download"></i>
                    <span class="">Descargar</span>
                  </base-button>

                  <router-link :to="`/bonificaciones/crear/`">
                    <base-button
                      size="sm"
                      type="info"
                      class="submit-btns w-auto"
                    >
                      <i class="fas fa-plus-circle"></i>
                      Crear bonificación
                    </base-button>
                  </router-link>
                </div>
              </div>
            </div>
          </template>
          <div class="row py-0 mx-0 pl-3">
            <div class="col-12 col-md-12 border-bottom px-0">
              <ul class="nav nav-tabs state-navs" id="myTab" role="tablist">
                <li class="nav-item state-nav">
                  <a
                    class="nav-link active py-2 px-4"
                    id="nav-activas-tab"
                    href="#activas-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-selected="true"
                    @click="cargarActivas"
                    >Activas</a
                  >
                </li>
                <li class="nav-item state-nav">
                  <a
                    class="nav-link py-2 px-4"
                    id="nav-pending-tab"
                    href="#pending-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-selected="false"
                    >Por Aprobar ({{ bonificaciones_por_aprobar.length }})</a
                  >
                </li>
                <li class="nav-item state-nav">
                  <a
                    class="nav-link py-2 px-4"
                    id="nav-inactivas-tab"
                    href="#inactivas-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-selected="false"
                    @click="cargarInactivas"
                    >Inactivas</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div
            class="row py-4 mx-0 px-0 remove-py-export"
            v-if="all_loaded"
          >
            <div
              class="tab-content w-100 remove-margin-export"
              id="nav-tabContent"

            >
              <div
                class="tab-pane fade show active"
                id="activas-tab"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
              >
                <Lista></Lista>
              </div>
              <div
                class="tab-pane fade px-3"
                id="pending-tab"
                role="tabpanel"
                aria-labelledby="nav-profile-tab"
              >
                <div
                  v-for="(bonificacion, index) in bonificaciones_por_aprobar"
                  :key="bonificacion"
                >
                  <AprobationCard :bonificacion="bonificacion" :index="index"></AprobationCard>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="inactivas-tab"
                role="tabpanel"
                aria-labelledby="nav-contact-tab"
              >
              <Lista :activas="false"></Lista>

              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script setup>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import DashboardNavbar from "../Layout/DashboardNavbar";
import "@/assets/sass/laive/bonificaciones.scss";
import AprobationCard from "./components/AprobationCard.vue";
import Lista from "./components/Lista.vue";
import { ref, computed, watch, onMounted } from "vue";

import { descargarBonificacionesPdf, agregarSaltosPdf, formatDateToYYYYMMDD } from '../../util';
import { useStore } from "vuex";
import constantes from "@/model/constantes.js";
const store = useStore();

store.dispatch("bonificacionUtil/cargarLista", "distribuidoras");
store.dispatch("bonificacionUtil/cargarLista", "productos");

store.dispatch("bonificacionUtil/cargarLista", "tiposCliente");
store.dispatch("bonificacionUtil/cargarLista", "segmentos");
store.dispatch("bonificacionUtil/cargarLista", "girosNegocio");
store.dispatch("bonificacionUtil/cargarLista", "tiposVendedor");
store.dispatch("bonificacionUtil/cargarLista", "vendedores");

store.dispatch("bonificacionUtil/cargarLista", "categorias");
store.dispatch("bonificacionUtil/cargarLista", "familias");
store.dispatch("bonificacionUtil/cargarLista", "presentaciones");

store.dispatch("bonificacionUtil/cargarLista", "mesas");

store.dispatch("bonificacion/obtenerBonificacionesPorAprobar", {conclientes: false});

const request = {
  fechainicio: formatDateToYYYYMMDD(constantes.FECHA_INICIO),
  fechafin: formatDateToYYYYMMDD(constantes.FECHA_FIN),
  conclientes: false
};
const cargarActivas = () => {
  store.dispatch("bonificacion/obtenerBonificacionesActivas", request);
}
const cargarInactivas = () => {
  store.dispatch("bonificacion/obtenerBonificacionesInactivas", request);
}

cargarActivas();
cargarInactivas();

const bonificaciones_por_aprobar = computed(() => {
  const bonificaciones = store.state.bonificacion.bonificaciones_por_aprobar;
  agregarSaltosPdf(bonificaciones, 25, 28);
  return bonificaciones;
});


const all_loaded = computed(
  () =>
    store.state.bonificacionUtil.distribuidoras.length &&
    store.state.bonificacionUtil.productos.length &&
    store.state.bonificacionUtil.tiposCliente.length &&
    store.state.bonificacionUtil.segmentos.length &&
    store.state.bonificacionUtil.girosNegocio.length &&
    store.state.bonificacionUtil.tiposVendedor.length &&
    store.state.bonificacionUtil.vendedores.length &&
    store.state.bonificacionUtil.mesas.length
);


onMounted(() => {

});

const descargar = () => {
  document.body.classList.add('cursor-wait');
  (new Promise(resolve => setTimeout(resolve, 500))).finally(() => {
    descargarBonificacionesPdf(document.getElementById('pdf-to-export')).finally(() => {
      document.body.classList.remove('cursor-wait');
    });
  });
}

</script>
<style lang="scss" scoped>
.el-table .cell {
  word-break: unset !important;
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
.no-border-card .card-footer {
  border-top: 0;
}

.view-btn {
  padding: 1px 0 0 15px !important;
  color: #02a54f !important;
  background: transparent !important;
  border: 0 !important;
  box-shadow: inherit !important;
  box-shadow: none !important;
}

.nav-link {
  font-weight: 600 !important;
  color: #b8b8b8;

  &.active {
    background: none !important;
    border: none;
    color: #02a54f !important;
    border-bottom: #02a54f 3px solid !important;
  }
}

.state-nav .nav-link:not(.active):hover {
  background: none !important;
  border: none !important;
  color: inherit !important;
}
.state-navs {
  border: none !important;
}
.state-nav a {
  border: none !important;
}
</style>
