<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive validateClient">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template v-slot:header>
            <div class="row">
              <div class="col-12 col-md-6">
                <h3 class="mb-0">Por Categoría</h3>
              </div>
              <!-- <div class="col-12 col-md-6 text-right">
                <button type="button" class="btn base-button btn-primary btn-sm" role="button"><i class="fas fa-file-export"></i>Exportar Reportes </button>
              </div> -->
            </div>
          </template>
          <div>
            <div class="row px-4">
              <div class="col-12 mb-3">
                <div class="row">
                  <div class="col-12 col-md-3">
                    <template v-if="distribuidoraIdCurrent">
                      <div class="title_filter mr-2"><div>Distribuidora:</div>  <strong>{{distribuidoraNameCurrent}}</strong></div>
                    </template>
                    <template v-else>
                      <span class="title_filter mr-2">Distribuidoras:  </span>
                      <select class="form-control" style="display: inline-block;height: 43px;" name="distributor_select" @change="changeListbyDistribuidora">
                        <option value="0">Todos</option>
                        <option
                          v-for="item in distribuidoras"
                          :value="item.idDistribuidora"
                          :key="item.idDistribuidora"
                          >
                          {{ item.razonSocial }}
                        </option>
                      </select>
                    </template>
                  </div>
                  <div class="col-12 col-md-3">
                    <template v-if="supervisorIdCurrent">
                      <span class="title_filter mr-2">Supervisor:<br><strong>{{supervisorNameCurrent}}</strong></span>
                    </template>
                    <template v-else>
                      <span class="title_filter mr-2">Supervisor:  </span>
                      <select class="form-control" id="supervisor_select" style="display: inline-block;height: 43px;" name="supervisor_select" @change="changeListbySupverisor">
                        <option value="">Todos</option>
                        <option
                          v-for="item in supervisores"
                          :value="item.codigoSupervisor"
                          :key="item.codigoSupervisor+item.distribuidora"
                          :selected="item.codigoSupervisor == parameters.supervisor"
                          >
                          {{ item.nombre }}
                        </option>
                      </select>
                    </template>
                  </div>
                  <div class="col-12 col-md-3">
                    <span class="title_filter mr-2">Fechas:</span>
                    <select class="form-control" id="fecha_select" style="display: inline-block;height: 43px;" name="fecha_select" @change="changeListbyFechas">
                      <option value="">Seleccionar</option>
                      <option
                        v-for="item in fechas"
                        :value="item.codigoDato"
                        :key="item.codigoDato"
                        :selected="item.codigoDato == fechaSelected"
                        >
                        {{ item.nombre }}
                      </option>
                    </select>
                  </div>
                  <div class="col-12 col-md-3 d-none">
                    <span class="title_filter mr-2">Estado:</span>
                    <select class="form-control" id="estado_select" style="display: inline-block;height: 43px;" name="estado_select">
                      <option value="">Seleccionar</option>
                      <option
                        v-for="item in estados"
                        :value="item.codigoDato"
                        :key="item.codigoDato"
                        >
                        {{ item.nombre }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="row px-1">
              <div class="col-12">
                  <template v-if="queriedData.length">
                    <template v-for="table in queriedData" :key="table.codigoMesa">
                      <div class="border-0 card-header">
                        <h3 class="mb-0">{{table.mesa}}</h3>
                      </div>
                      <div class="el-table table_report">
                        <table>
                          <thead class="el-table__header-wrapper">
                            <th v-for="(item, i) in Object.keys(table.resumen[0])" :key="item.vendedor" :style="(i == 1) ? `min-width:350px;display:block;` : ``"  >{{item}}</th>
                          </thead>
                          <tbody>
                            <template v-for="(values, index) in table.resumen" :key="values.codigomesa">
                              <tr :class="(table.resumen.length - 1 == index) ? 'footer' : ''">
                                <template v-for="(row, k) in Object.values(values)" :key="row+k">
                                  <template v-if="k <= 1">
                                    <td>{{row}}</td>
                                  </template>
                                  <template v-else>
                                    <td v-if="row == 0">0</td>
                                    <td v-else>S/{{formatNumber(row)}}</td>
                                  </template>
                                </template>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                    </template>
                  </template>
                  <template v-else>
                    <div class="col-12 text-center">
                      <el-table
                        :data="tableReporte"
                        row-key="totalreporte"
                        header-row-class-name="thead-light"
                        empty-text="Debe Seleccionar una distribuidora"
                      >
                      </el-table>
                    </div>
                  </template>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import axios from "axios";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import TabPane from "@/components/Tabs/TabPane";
import BasePagination from "@/components/BasePagination";
import {mapGetters} from 'vuex';
import swal from "sweetalert2";
import DashboardNavbar from "../Layout/DashboardNavbar";
export default {
  components: {
    DashboardNavbar,
    BasePagination,
    RouteBreadCrumb,
    TabPane,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  computed: {
    pagedData() {
      this.getDistributor
      this.getSupervisor
      this.getFechas
      this.getEstados
      this.getDataReporte
      return this.tableReporte;
    },
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
    },
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    }),
    getDistributor(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let urlApiDistribuidoras = process.env.VUE_APP_API_URL+"/distribuidoras/lista"
      const requestDistribuidoras = axios.get(urlApiDistribuidoras, config);
      if($this.distribuidoras.length == 0){
        console.log("debo cargar distruiwiqeqw")
        axios.all([requestDistribuidoras]).then(axios.spread((...responses) => {
          const responseDistribuidoras = responses[0].data
          if($this.distribuidoraIdCurrent){
            let dataDistibuitorCurrent =  responseDistribuidoras.filter((item)=>{return item.idDistribuidora == $this.distribuidoraIdCurrent})
            $this.distribuidoraNameCurrent = dataDistibuitorCurrent[0].razonSocial
          }
          $this.distribuidoras = responseDistribuidoras.filter((item)=>{return item.estado == "1"})
          return $this.distribuidoras;
        })).catch(errors => {
          // react on errors.
        })
      }
    },
    getSupervisor(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const selectDistribuidora = ($this.distribuidoraIdCurrent) ? $this.distribuidoraIdCurrent : 0
      const urlApiSupervisores = process.env.VUE_APP_API_URL+`/supervisor/lista/${selectDistribuidora}`
      console.log({urlApiSupervisores})
      axios.get(urlApiSupervisores,config)
      .then(function (response){
        let dataSupervisores= response.data
        console.log(response.data, " ====lista de supervisores");
        if(Number($this.distribuidoraIdCurrent) == 0){
          document.querySelector(".form_laive").classList.remove("validateClient")
        }
        return $this.supervisores = dataSupervisores
      })
    },
    async getFechas(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const urlApiFechas = process.env.VUE_APP_API_URL+"/maestro/lista/listafecha"
      if($this.fechas.length == 0){
        await axios.get(urlApiFechas,config)
        .then(function (response){
          console.log(response.data, " ====lista de fechas");
          return $this.fechas = response.data
        })
      }
    },
    async getEstados(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const urlApiPedidos = process.env.VUE_APP_API_URL+"/maestro/lista/pedido_estados"
      if($this.estados.length == 0){
        await axios.get(urlApiPedidos,config)
        .then(function (response){
          console.log(response.data, " ====lista de estads");
          return $this.estados = response.data
        })
      }
    },
    getDataReporte(){
      const $this = this
      let idDistribuidora = Number(this.distribuidoraIdCurrent)
      if(idDistribuidora){
        let idPeriodo = document.querySelector("select[name='fecha_select']").value
        let idSupervisor = ($this.supervisorIdCurrent) ? $this.supervisorIdCurrent : document.querySelector("select[name='supervisor_select']").value
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        const parameters = {
          "iddistribuidora": idDistribuidora,
          "codigosupervisor": idSupervisor,
          "codigomesa": "",
          "codigovendedor": "",
          "periodo": idPeriodo,
        }
        const urlApiResumen = process.env.VUE_APP_API_URL+`/pedido/resumenpormesa`
        const dataResumen = axios.post(urlApiResumen,parameters,config)
        axios.all([dataResumen]).then(axios.spread((...responses) => {
          const resDataByDistribuidora = responses[0].data
          document.querySelector(".form_laive").classList.remove("validateClient")
          return $this.tableReporte = resDataByDistribuidora.filter((item)=>{return item.resumen.length > 0})
        })).catch(errors => {
          // react on errors.
        })
      }
    },
  },
  methods:{
    changeListbyDistribuidora(evn){
      const $this = this
      let selected = Number(evn.target.value)
      if(selected){
        let idSupervisor = document.querySelector("select[name='supervisor_select']").value
        let idPeriodo = document.querySelector("select[name='fecha_select']").value
        let idEstado = document.querySelector("select[name='estado_select']").value
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        const parameters = {
          "iddistribuidora": selected,
          "codigosupervisor": idSupervisor,
          "codigomesa": "",
          "codigovendedor": "",
          "periodo": idPeriodo,
          "estado": idEstado,
          "page": 1
        }
        document.querySelector(".form_laive").classList.add("validateClient")
        const urlApiSupervisores = process.env.VUE_APP_API_URL+`/supervisor/lista/${selected}`
        const urlApiResumen = process.env.VUE_APP_API_URL+`/pedido/resumenpormesa`
        const dataResumen = axios.post(urlApiResumen,parameters,config)
        const getSupervisores = axios.get(urlApiSupervisores,config)
        axios.all([dataResumen, getSupervisores]).then(axios.spread((...responses) => {
          const resDataByDistribuidora = responses[0].data
          const resListSupervisores = responses[1].data
          $this.tableReporte = resDataByDistribuidora.filter((item)=>{return item.resumen.length > 0})
          $this.supervisores = resListSupervisores
          document.querySelector(".form_laive").classList.remove("validateClient")
        })).catch(errors => {
          // react on errors.
        })
      }else{
        document.querySelector("select[name='supervisor_select']").value = ""
        this.tableReporte = []
        const swalWithBootstrapButtons1 = swal.mixin({
          customClass: {
            confirmButton: "btn btn-success btn-fill",
          },
          buttonsStyling: false,
        });
        swalWithBootstrapButtons1.fire({
          title: `Seleccionar una distribuidora`,
          confirmButtonText: "Ok",
        });
      }
    },
    changeListbySupverisor(evn){
      const $this = this
      let selected = evn.target.value
      let idDistribuidora = ($this.distribuidoraIdCurrent) ? $this.distribuidoraIdCurrent : Number(document.querySelector("select[name='distributor_select']").value)
      let idPeriodo = document.querySelector("select[name='fecha_select']").value
      let idEstado = document.querySelector("select[name='estado_select']").value
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      if(idDistribuidora != 0){
        const parameters = {
          "iddistribuidora": idDistribuidora,
          "codigosupervisor": selected,
          "codigomesa": "",
          "codigovendedor": "",
          "periodo": idPeriodo,
          "estado": idEstado,
          "page": 1
        }

        document.querySelector(".form_laive").classList.add("validateClient")
        console.log({parameters});
        const urlApiResumen = process.env.VUE_APP_API_URL+`/pedido/resumenpormesa`
        const dataResumen = axios.post(urlApiResumen,parameters,config)
        axios.all([dataResumen]).then(axios.spread((...responses) => {
          const resDataBySupervisor = responses[0].data
          $this.tableReporte = resDataBySupervisor.filter((item)=>{return item.resumen.length > 0})
          document.querySelector(".form_laive").classList.remove("validateClient")
        })).catch(errors => {
          // react on errors.
        })
      }else{
        const swalWithBootstrapButtons1 = swal.mixin({
          customClass: {
            confirmButton: "btn btn-success btn-fill",
          },
          buttonsStyling: false,
        });
        swalWithBootstrapButtons1.fire({
          title: `Seleccionar una distribuidora`,
          confirmButtonText: "Ok",
        });
      }
    },
    changeListbyFechas(evn){
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let selected = evn.target.value
      let idDistribuidora = ($this.distribuidoraIdCurrent) ? $this.distribuidoraIdCurrent : Number(document.querySelector("select[name='distributor_select']").value)
      let idSupervisor = document.querySelector("select[name='supervisor_select']").value
      let idEstado = document.querySelector("select[name='estado_select']").value
      if(idDistribuidora != 0){
        const parameters = {
          "iddistribuidora": idDistribuidora,
          "codigosupervisor": idSupervisor,
          "codigomesa": "",
          "codigovendedor": "",
          "periodo": selected,
          "estado": idEstado,
          "page": 1
        }
        document.querySelector(".form_laive").classList.add("validateClient")
        const urlApiResumen = process.env.VUE_APP_API_URL+`/pedido/resumenpormesa`
        axios.post(urlApiResumen,parameters,config)
        .then(function (response){
          const resDataByFechas = response.data
          $this.tableReporte = resDataByFechas.filter((item)=>{return item.resumen.length > 0})
          document.querySelector(".form_laive").classList.remove("validateClient")
        })
      }else{
        const swalWithBootstrapButtons1 = swal.mixin({
          customClass: {
            confirmButton: "btn btn-success btn-fill",
          },
          buttonsStyling: false,
        });
        swalWithBootstrapButtons1.fire({
          title: `Seleccionar una distribuidora`,
          confirmButtonText: "Ok",
        });
      }
    },
    changeListbyEstados(evn){
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let selected = evn.target.value
      let idDistribuidora = ($this.distribuidoraIdCurrent) ? $this.distribuidoraIdCurrent : Number(document.querySelector("select[name='distributor_select']").value)
      let idSupervisor = document.querySelector("select[name='supervisor_select']").value
      let idPeriodo = document.querySelector("select[name='fecha_select']").value
      if(idDistribuidora != 0){
        const parameters = {
          "iddistribuidora": idDistribuidora,
          "codigosupervisor": idSupervisor,
          "codigomesa": "",
          "codigovendedor": "",
          "periodo": idPeriodo,
          "estado": selected,
          "page": 1
        }
        document.querySelector(".form_laive").classList.add("validateClient")
        const urlApiResumen = process.env.VUE_APP_API_URL+`/pedido/resumenpormesa`
        axios.post(urlApiResumen,parameters,config)
        .then(function (response){
          const resDataByEstados = response[0].data
          $this.tableReporte = resDataByEstados.filter((item)=>{return item.resumen.length > 0})
          document.querySelector(".form_laive").classList.remove("validateClient")
        })
      }else{
        const swalWithBootstrapButtons1 = swal.mixin({
          customClass: {
            confirmButton: "btn btn-success btn-fill",
          },
          buttonsStyling: false,
        });
        swalWithBootstrapButtons1.fire({
          title: `Seleccionar una distribuidora`,
          confirmButtonText: "Ok",
        });
      }
    },
    formatNumber(num) {
      var nf = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      return nf.format(num)
		  // return new Intl.NumberFormat("en-IN").format(num)
	  }
  },
  mounted() {
    if(this.user.rol == 3){
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
    if(this.user.rol == 4){
      this.supervisorIdCurrent = this.user.codigo
      this.supervisorNameCurrent = this.user.nombre
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
  },
  data() {
    return {
      initload: true,
      distribuidoraIdCurrent: null,
      distribuidoraNameCurrent: null,
      supervisorIdCurrent: null,
      supervisorNameCurrent: null,
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      parameters:{
        distribuidora: "",
        supervisor: "",
        vendedor: ""
      },
      searchQuery: "",
      distribuidoras:[],
      supervisores:[],
      vendedores:[],
      fechaSelected: "1",
      fechas:[],
      estados:[],
      tableReporte:[],
      propsToSearch: ["montoTotal", "totalpedidos"],
      fuseSearch: null,
      searchedData: [],
      expands: [],
    };
  },
};
</script>
