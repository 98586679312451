<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-header">
              <h3 class="mb-0">Parametros registro de pedidos</h3>
            </div>
            <div class="card-body">
              <div class="row" v-if="user.rol < 3">
                <div class="col-md-6">
                  <form @submit.prevent="submitMetros" class="form_laive validateClient">
                    <div class="box_loader" style="top:-20px;">
                      <div class="loader"></div>
                    </div>
                    <div class="form-group row mb-0">
                      <label class="col-md-12 col-form-label form-control-label">Metros fuera de coordenadas</label>
                      <div class="col-md-6">
                        <base-input
                          required
                          name="mtr_coordenadas"
                          v-model="metros_antes_de_fuera_coordenadas.valor"
                          placeholder="metros fuera de coordenada"
                          @keypress="isNumber($event)"
                        >
                          <template v-slot:addonLeft>Mtrs.</template>
                        </base-input>
                      </div>
                      <div class="col-md 6">
                        <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar metros</base-button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="el-table table_report pesos notoverflow">
                <h4 class="mb-1 form-control-label">Horarios y correos por distribuidora</h4>
                <table width="100%" border="0">
                  <thead class="el-table__header-wrapper">
                    <th v-for="(item) in tableColumns" :key="item.prop">
                      {{ item.label }}
                    </th>
                    <th></th>
                  </thead>
                  <tbody>
                    <template v-if="registroPedidos.length">
                      <template v-for="(value, k) in registroPedidos" :key="value.idParametro">
                        <tr v-if="value.show">
                          <td style="width:180px" v-if="value.nombre">{{value.nombre}}</td><td v-else>---</td>
                          <td style="width:90px" v-if="value.inicio">{{value.inicio}}</td><td v-else>---</td>
                          <td style="width:90px" v-if="value.cierre">{{value.cierre}}</td><td v-else>---</td>
                          <td>
                            <base-button type="info" class="edit mr-3" size="sm" role="button" @click="openHorario(value, k)" aria-pressed="true">Cambiar horario</base-button>
                            <base-button type="info" class="edit" size="sm" role="button" @click="openEmail(value, k)" aria-pressed="true">Configurar correos</base-button>
                          </td>
                        </tr>
                      </template>
                    </template>
                  </tbody>
                </table>
                <span class="no-result" v-if="registroPedidos.length == 0">No se encontraron Resultados</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CustomModal
    :show="registroHorarios.showModal"
    :isHeader="true"
    :isFooter="false"
    :width="600"
    :title="'Configurar horario de '+registroHorarios.distribuidora"
    v-on:modal:close="registroHorarios.showModal = $event" >
    <template v-slot:body>
      <form @submit.prevent="submitUpdateHorario" class="form_laive formDate" :class="{ 'validateClient' : registroHorarios.loading}">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label class="col-form-label form-control-label">Hora de Inicio:</label>
            <base-input addon-left-icon="ni ni-calendar-grid-58" name="fechaInicio">
              <flat-picker :config="configDate" class="form-control datepicker" required v-model="registroHorarios.inicio"></flat-picker>
            </base-input>
          </div>
          <div class="col-md-6">
            <label class="col-form-label form-control-label">Hora de Fin:</label>
            <base-input addon-left-icon="ni ni-calendar-grid-58" name="fechaFin">
              <flat-picker :config="configDate" class="form-control datepicker" required v-model="registroHorarios.cierre"></flat-picker>
            </base-input>
          </div>
          <div class="col-md-12">
            <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar</base-button>
          </div>
        </div>
      </form>
    </template>
  </CustomModal>
  <CustomModal
    :show="horariosinfacturar.showModal"
    :isHeader="true"
    :isFooter="false"
    :width="600"
    :title="'Configurar correos de '+horariosinfacturar.distribuidora"
    v-on:modal:close="horariosinfacturar.showModal = $event" >
    <template v-slot:body>
      <form @submit.prevent="submitHorarioEmail" class="form_laive formDate" :class="{ 'validateClient' : horariosinfacturar.loading}">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <div class="form-group mb-0">
          <label class="col-form-label form-control-label">Agregar correos:</label>
          <div class="row">
            <template v-for="(elm, index) in horariosinfacturar.data.listemail" :key="index">
              <div class="col-md-6">
                <div class="box__input">
                  <input v-model="horariosinfacturar.data.listemail[index]" :name="'cronjob_'+index" class="form-control cronjob_phome mb-2" type="email" placeholder="Agregar correo Electronico" required>
                  <i class="fa fa-times" @click="removeEmail(horariosinfacturar.data.listemail[index])"></i>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="box_addphone pt-1" v-if="horariosinfacturar.data.listemail.length < 5"><span @click="addEmail">+ Agregar otro correo</span></div>
        <base-button type="primary" native-type="submit" class="btn-default my-0 mt-2">Actualizar envio de correos</base-button>
      </form>
    </template>
  </CustomModal>
</template>
<script>
  import axios from "axios";
  import {mapGetters, mapActions} from 'vuex';
  import { useToast } from "vue-toastification";
  import Notification from "@/components/Notification";
  import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
  import DashboardNavbar from "../Layout/DashboardNavbar";
  import flatPicker from "vue-flatpickr-component";
  import {Spanish} from 'flatpickr/dist/l10n/es.js';
  import "flatpickr/dist/flatpickr.css";
  import CustomModal from "../Components/CustomModal.vue";
  export default {
    components: {
      DashboardNavbar,
      RouteBreadCrumb,
      flatPicker,
      CustomModal
    },
    created(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const urlPorcentajes = process.env.VUE_APP_API_URL+"/parametro/lista/horario_registro_pedidos"
      const getPorcentajes = axios.get(urlPorcentajes,config)
      let urlDistribuidoras = process.env.VUE_APP_API_URL+"/distribuidoras/lista"
      const getDistribuidoras = axios.get(urlDistribuidoras, config);
      let urlCoordenadas = process.env.VUE_APP_API_URL+"/parametro/lista/metros_antes_de_fuera_coordenadas"
      const getCoordenadas = axios.get(urlCoordenadas,config)

      axios.all([getPorcentajes, getDistribuidoras, getCoordenadas]).then(axios.spread((...responses) => {
        const resPorcentajes = responses[0].data
        const resDistribuidoras = responses[1].data
        $this.distribuidoras = resDistribuidoras ? resDistribuidoras.filter((item)=>{return item.estado == "1"}) : []
        const newDataFormat = resPorcentajes.map((item, indice, arr) => {
          let distribuidora = resDistribuidoras.filter((dis)=>{return dis.idDistribuidora == item.idDistribuidora})
          let nameDist = distribuidora[0].razonSocial
          arr[indice]["nombre"] = nameDist
          let hourInit = item.valor.split('|')[0]
          let hourFinish = item.valor.split('|')[1]
          arr[indice]["inicio"] = hourInit
          arr[indice]["cierre"] = hourFinish
          arr[indice]["show"] = ($this.distribuidoraIdCurrent) ? ($this.distribuidoraIdCurrent == distribuidora[0].idDistribuidora) ? true : false : true
          return item
        });
        $this.registroPedidos = newDataFormat
        const resCoordenadas = responses[2].data[0]
        $this.metros_antes_de_fuera_coordenadas = resCoordenadas
        setTimeout(() => {
          const getFormsLaive = document.querySelectorAll(".form_laive")
          getFormsLaive.forEach(element => {
            element.classList.remove("validateClient")
          });
        }, 350);
      })).catch(errors => {
        const getFormsLaive = document.querySelectorAll(".form_laive")
        getFormsLaive.forEach(element => {
          element.classList.remove("validateClient")
        });
      })
    },
    computed:{
      ...mapGetters({
        authenticated: 'auth/authenticated',
        user: 'auth/user',
      }),
    },
    mounted() {
      if(this.user.rol == 3){
        this.distribuidoraIdCurrent = this.user.idDistribuidora
      }
    },
    methods: {
      ...mapActions({
        getDistributorList: 'distribuidora/getList',
      }),
      isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault();;
        } else {
          return true;
        }
      },
      submitHorario(evn){
        const keyArray = evn.target.getAttribute("data-key")
        const $parent = evn.target.parentNode
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        this.registroPedidos.map((item, indice, arr) => {
          let newValue = `${item.inicio}|${item.cierre}`
          item.valor = newValue
          return item
        });
        const dataSend = this.registroPedidos[keyArray]
        const urlApiUpdated = process.env.VUE_APP_API_URL+"/parametro/actualizar"
        $parent.classList.add("validateClient")
        axios.put(urlApiUpdated,dataSend,config)
        .then(function (response){
          $parent.classList.remove("validateClient")
          const text =(response.status == 200) ? "Se actualizó el horario de pedido" : "Hubo un error";
          const icon ="ni ni-bell-55";
          const content={
            component: Notification,
            props: {
              icon: icon,
              text: text,
              type: (response.status == 200) ? "success" : "danger",
            },
          };
          const toast = useToast();
          toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
        }).catch(function(error){
          console.log({error});
        })
      },
      submitMetros(evn){
        const $parent = evn.target
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        const dataSend = this.metros_antes_de_fuera_coordenadas
        const urlApiUpdated = process.env.VUE_APP_API_URL+"/parametro/actualizar"
        $parent.classList.add("validateClient")
        axios.put(urlApiUpdated,dataSend,config)
        .then(function (response){
          $parent.classList.remove("validateClient")
          const text =(response.status == 200) ? "Se actulizaron las metros" : "Hubo un error";
          const icon ="ni ni-bell-55";
          const content={
            component: Notification,
            props: {
              icon: icon,
              text: text,
              type: (response.status == 200) ? "success" : "danger",
            },
          };
          const toast = useToast();
          toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
        }).catch(function(error){
          console.log({error});
        })
      },
      changeDistribuidora(evn){
        const $this = this
        const $parent = evn.target.parentNode.parentNode.parentNode.parentNode
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        const dataSend = this.horariosinfacturar
        const urlApiCronjob = process.env.VUE_APP_API_URL+"/parametro/cronjob/pedidosinfacturar"
        $parent.classList.add("validateClient")
        axios.post(urlApiCronjob,dataSend,config)
        .then(function (response){
          $parent.classList.remove("validateClient")
          const resData = response.data[0]
          resData["listemail"] = resData.email.split(";")
          $this.horariosinfacturar.data = resData
        }).catch(function(error){
          console.log({error});
        })
      },
      addEmail(evn){
        const countEmails = this.horariosinfacturar.data.listemail.length
        if(countEmails < 5) this.horariosinfacturar.data.listemail.push("")
      },
      removeEmail(data){
        const index = this.horariosinfacturar.data.listemail.indexOf(data);
        if (index > -1) this.horariosinfacturar.data.listemail.splice(index, 1)
      },
      openHorario(data, key){
        this.registroHorarios.id = key
        this.registroHorarios.distribuidora = data.nombre,
        this.registroHorarios.inicio = data.inicio,
        this.registroHorarios.cierre = data.cierre
        this.registroHorarios.showModal = true
      },
      submitUpdateHorario(evn){
        const dataSend = this.registroPedidos[this.registroHorarios.id]
        let newValue = `${this.registroHorarios.inicio}|${this.registroHorarios.cierre}`
        dataSend.valor = newValue
        this.registroHorarios.loading = true
        const $this = this
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        const urlApiUpdated = process.env.VUE_APP_API_URL+"/parametro/actualizar"
        axios.put(urlApiUpdated,dataSend,config)
        .then(function (response){
          const text =(response.status == 200) ? "Se actualizó el horario de pedido" : "Hubo un error";
          const icon ="ni ni-bell-55";
          const content={
            component: Notification,
            props: {
              icon: icon,
              text: text,
              type: (response.status == 200) ? "success" : "danger",
            },
          };
          const toast = useToast();
          toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
          $this.registroHorarios.loading = false
          $this.registroHorarios.showModal = false
          $this.registroPedidos[$this.registroHorarios.id].valor = newValue
          $this.registroPedidos[$this.registroHorarios.id].inicio = $this.registroHorarios.inicio
          $this.registroPedidos[$this.registroHorarios.id].cierre = $this.registroHorarios.cierre
        }).catch(function(error){
          console.log({error});
        })
      },
      openEmail(data, key){
        const $this = this
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        this.horariosinfacturar.loading = true
        this.horariosinfacturar.showModal = true
        this.horariosinfacturar.idDistribuidora = Number(data.idDistribuidora)
        this.horariosinfacturar.distribuidora = data.nombre
        const dataSend = this.horariosinfacturar
        const urlApiCronjob = process.env.VUE_APP_API_URL+"/parametro/cronjob/pedidosinfacturar"
        axios.post(urlApiCronjob,dataSend,config)
        .then(function (response){
          const resData = response.data[0]
          resData["listemail"] = resData.email.split(";")
          $this.horariosinfacturar.data = resData
          $this.horariosinfacturar.loading = false
        }).catch(function(error){
          console.log({error});
          $this.horariosinfacturar.loading = false
          $this.horariosinfacturar.showModal = false
        })
      },
      submitHorarioEmail(evn){
        const $this = this
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        this.horariosinfacturar.loading = true
        const newEmails = this.horariosinfacturar.data.listemail.join(";")
        this.horariosinfacturar.data.email = newEmails
        const dataSend = this.horariosinfacturar.data
        const urlApiUpdatedHorario = process.env.VUE_APP_API_URL+"/parametro/cronjob/pedidosinfacturar/actualizar"
        axios.post(urlApiUpdatedHorario,dataSend,config)
        .then(function (response){
          const text =(response.status == 200) ? "Se actulizaron las correos" : "Hubo un error";
          const icon ="ni ni-bell-55";
          const content={
            component: Notification,
            props: {
              icon: icon,
              text: text,
              type: (response.status == 200) ? "success" : "danger",
            },
          };
          const toast = useToast();
          toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
          $this.horariosinfacturar.loading = false
          $this.horariosinfacturar.showModal = false
        }).catch(function(error){
          console.log({error});
        })
      },
    },
    data() {
      return {
        distribuidoraIdCurrent: null,
        distribuidoras: [],
        configDate: {
          altInput: false,
          allowInput: true,
          noCalendar: true,
          enableTime: true,
          locale: Spanish, // locale for this instance only
        },
        tableColumns: [
          {
            prop: "name",
            label: "Distribuidora",
          },
          {
            prop: "inicio",
            label: "Inicio",
          },
          {
            prop: "cierre",
            label: "Cierre",
          },
        ],
        registroPedidos: [],
        registroHorarios:{
          showModal: false,
          loading: false,
          id: "",
          distribuidora: "",
          inicio: "",
          cierre: ""
        },
        metros_antes_de_fuera_coordenadas:{},
        horariosinfacturar:{
          distribuidora: "",
          idDistribuidora: 0,
          showModal: false,
          loading: true,
          data: {
            id: "",
            asunto: "",
            email: "",
            horario: "",
            intervalo: 2,
            ultimaEjecucion: "",
            siguienteEjecucion: "",
            listemail: []
          }
        }
      };
    },
  };
</script>

