<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive validateClient">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1 pt-0 mt-0"
        >
          <template v-slot:header>
            <h3 class="mb-0">Creación de Productos Cluster</h3>
          </template>
          <div class="card-body">
            <form @submit.prevent="submiCreate">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label form-control-label">Distribuidoras:</label>
                    <select class="form-control" style="display: inline-block;height: 43px;" required name="distributor_select" @change="changeDistributor">
                      <option value="">Todos</option>
                      <option
                        v-for="item in distribuidoras"
                        :value="item.idDistribuidora"
                        :key="item.idDistribuidora"
                        >
                        {{ item.razonSocial }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <base-button type="primary" native-type="submit" class="btn-default my-0">Crear Producto Cluster</base-button>
            </form>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
import Select2 from 'vue3-select2-component';
export default {
  components: {
    RouteBreadCrumb,
    Select2,
  },
  created(){
    const $this = this
    const token = localStorage.getItem("token")
    const config = {
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    };
    axios.get(process.env.VUE_APP_API_URL+"/distribuidoras/lista",config)
    .then(function (response){
      document.querySelector(".form_laive").classList.remove("validateClient")
      const dataDistribuidoras = response.data
      $this.distribuidoras = dataDistribuidoras
    })
  },
  methods: {
    submiCreate(values) {
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let createPromotion  = process.env.VUE_APP_API_URL+"/producto/crear-producto-cluster"
      document.querySelector(".form_laive").classList.add("validateClient")
      axios.post(createPromotion, this.dataSend,config)
      .then(function (response){
        document.querySelector(".form_laive").classList.remove("validateClient")
        const text = (response.status == 200) ? "Se crearon los productos del cluster" : "Hubo un error";
        const icon = "ni ni-bell-55";
        const content = {
          component: Notification,
          props: {
            icon: icon,
            text: text,
            type: (response.status == 200) ? "success" : "danger",
          },
        };
        const toast = useToast();
        toast(content, {
          hideProgressBar: true,
          icon: false,
          closeButton: false,
          position: "top-right",
        });
        console.log(response);
        setTimeout(() => {
          $this.$router.replace({name: 'Productos Cluster'})
        }, 2000);
      })
      // alert(JSON.stringify(values, null, 2));
    },
    changeDistributor(event) {
      const $this = this;
      const select = event.target
      const value = Number(select.value);
      this.dataSend.idDistribuidora = value
    },
  },
  data() {
    return {
      distribuidoras:[],
      dataSend:{
        idDistribuidora: "",
      }
    };
  },
}
</script>
