<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive validateClient">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template v-slot:header>
            <div class="row">
              <div class="col-12 col-md-6">
                <h3 class="mb-0">Pesos por Distribuidora</h3>
              </div>
              <div class="col-12 col-md-6 text-right">
                <button type="button" class="btn base-button btn-primary btn-sm" role="button" @click="downloadFormat"><i class="fas fa-file-export"></i>Exportar Formato</button>
              </div>
            </div>
          </template>
          <div class="row px-3">
            <div class="col-12">
              <form @submit.prevent="submitPesos">
                <div class="el-table table_report pesos">
                  <table>
                    <thead class="el-table__header-wrapper">
                      <th></th>
                      <th v-for="(item, index) in days" :key="index">{{ index + 1 }}</th>
                    </thead>
                    <tbody>
                      <tr v-for="(row) in tableData" :key="row" :data-id="row.id" :data-name="row.distribuidora" :data-codigo="row.codigo">
                        <td><strong>{{row.distribuidora}}</strong></td>
                        <template v-for="(item, index) in row.data.split(',')" :key="item">
                          <td v-if="index < setAllDays" :data-value="item" class="pesovalue" style="padding-left: 0.2rem;padding-right: 0.2rem;">
                            <input class="form-control" :name="`peso_${index}`" type="text" :value="item">
                          </td>
                        </template>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar Pesos</base-button>
                <br>
                <br>
              </form>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BasePagination from "@/components/BasePagination";
import {mapGetters} from 'vuex';
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
import DashboardNavbar from "../../Layout/DashboardNavbar";
export default {
  components: {
    DashboardNavbar,
    BasePagination,
    RouteBreadCrumb,
  },
  created(){
    const $this = this
    const token = localStorage.getItem("token")
    const config = {
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    };
    let urlApiTablePesos = process.env.VUE_APP_API_URL+"/parametro/reporte/cobertura/simple"
    const requestTablePesos = axios.get(urlApiTablePesos, config);
    axios.all([requestTablePesos]).then(axios.spread((...responses) => {
      const responseDistribuidora = responses[0].data
      $this.tableData = responseDistribuidora
      // Sete Days
      $this.days = Array($this.setAllDays).fill(0)
      // Set List Headers Distribuidoras
      responseDistribuidora.forEach(item => {
        $this.distribuidoras.push(
          {
            "razonSocial": item.distribuidora,
            "idDistribuidora": item.distribuidora,
          }
        )
      });
      document.querySelector(".form_laive").classList.remove("validateClient")
    })).catch(errors => {
      // react on errors.
    })
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    }),
  },
  methods:{
    downloadFormat(){
      const token = localStorage.getItem("token")
      const config = {
        responseType: 'blob',
        headers: {
          "Authorization": `Bearer ${token}`,
        }
      };
      axios.get(process.env.VUE_APP_API_URL+"/parametro/reporte/cobertura/simple/exportar",config)
      .then(function (response){
        const url = window.URL.createObjectURL(new Blob([response.data], {type:'application/vnd.ms-excel'}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download',`pesos-format-${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
    },
    submitPesos(){
      let sendArr = []
      let allTr = document.querySelectorAll("tbody tr")
      allTr.forEach((element, index) => {
        let valueArr = []
        sendArr.push({
          "id": element.getAttribute("data-id"),
          "codigo": element.getAttribute("data-codigo"),
          "distribuidora": element.getAttribute("data-name"),
          "data": ""
        })
        element.querySelectorAll("td.pesovalue").forEach((item, i) => {
          if(item.hasAttribute("data-value")){
            // console.log(item.getAttribute("data-value"))
            valueArr[i] = item.querySelector("input").value
            // console.log(item.getAttribute("data-value"))
          }
        });
        sendArr[index].data = valueArr.join(",")
        let addValues = sendArr[index].data + ",0,0,0,0"
        sendArr[index].data = addValues
        // console.log(valueArr)
      });
      console.log(sendArr);
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      document.querySelector(".form_laive").classList.add("validateClient")
      axios.post(process.env.VUE_APP_API_URL+"/parametro/reporte/cobertura/simple/guardar",sendArr,config)
      .then(function (response){
        document.querySelector(".form_laive").classList.remove("validateClient")
        const text = (response.data.status == 200) ? "Se actualizó la tabla de Pesos" : "Hubo un error";
        const icon = "ni ni-bell-55";
        const content = {
          component: Notification,
          props: {
            icon: icon,
            text: text,
            type: (response.data.status == 200) ? "success" : "danger",
          },
        };
        const toast = useToast();
        toast(content, {
          hideProgressBar: true,
          icon: false,
          closeButton: false,
          position: "top-right",
        });
      })
    }
  },
  mounted() {
    if(this.user.rol == 3){
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
    if(this.user.rol == 4){
      this.supervisorIdCurrent = this.user.codigo
      this.supervisorNameCurrent = this.user.nombre
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
  },
  data() {
    return {
      initload: true,
      distribuidoraIdCurrent: null,
      distribuidoraNameCurrent: null,
      supervisorIdCurrent: null,
      supervisorNameCurrent: null,
      emptyText: "Debe Selecionar una distribuidora",
      tableData: [],
      setAllDays: 26,
      days:[],
      distribuidoras: [],
    };
  },
};
</script>
<style lang="scss">
  .page-item .page-link .fa{
    z-index: 0;
  }
</style>
