<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive validateClient">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template v-slot:header>
            <div class="row">
              <div class="col-12 col-md-6">
                <h3 class="mb-0">Productos</h3>
              </div>
              <div class="col-12 col-md-6 text-right">
                <button type="button" @click="exportProducto" class="btn base-button btn-primary btn-sm" role="button"><i
                    class="fas fa-file-export"></i>Exportar </button>
                <base-button size="sm" @click="createProduct()" type="info">Crear Producto</base-button>
              </div>
            </div>
          </template>
          <div>
            <div class="row px-4">
              <div class="col-12 mb-3">
                <div class="row">
                  <div class="col-12 col-md-3">
                    <span class="title_filter mr-2">Buscador:</span>
                    <el-input type="search" clearable prefix-icon="el-icon-search" placeholder="Buscar producto"
                      v-model="searchQuery" aria-controls="datatables">
                    </el-input>
                  </div>
                  <div class="col-12 col-md-3">
                    <template v-if="distribuidoraIdCurrent">
                      <span class="title_filter mr-2">Distribuidora: <strong>{{ distribuidoraNameCurrent
                      }}</strong></span>
                    </template>
                    <template v-else>
                      <span class="title_filter mr-2">Distribuidoras: </span>
                      <select class="form-control" style="display: inline-block;height: 43px;" name="distributor_select"
                        @change="changeDistributor">
                        <option value="0">Todos</option>
                        <option v-for="item in distribuidoras" :value="item.idDistribuidora" :key="item.idDistribuidora">
                          {{ item.razonSocial }}
                        </option>
                      </select>
                    </template>
                  </div>
                  <div class="col-12 col-md-3">
                    <span class="title_filter mr-2" style="width:200px;">Grupos de Venta: </span>
                    <select class="form-control" style="display: inline-block;height: 43px;" name="grupoventa_select"
                      @change="changeGrupoVenta">
                      <option value="">Todos</option>
                      <option v-for="item in gruposventa" :value="item.codigoDato" :key="item.idRegistro">
                        {{ item.nombre }}
                      </option>
                    </select>
                  </div>
                  <div class="col-12 col-md-3">
                    <span class="title_filter mr-2">Categorias: </span>
                    <select class="form-control" style="display: inline-block;height: 43px;" name="category_select"
                      @change="changeCategory">
                      <option value="" :selected="searchQuery == ''">Todos</option>
                      <option v-for="item in categories" :value="item.codigo" :key="item.codigo">
                        {{ item.nombre }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-2 d-none">
                <div class="mr-2">
                  <!-- <span class="title_filter">Paginación:  </span> -->
                  <el-select class="select-primary pagination-select" v-model="pagination.perPage" style="width:80px"
                    placeholder="Per page">
                    <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item"
                      :value="item">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <!-- Real Table -->
            <el-table :data="queriedData" row-key="idProducto" empty-text="No se encontraron resultados"
              header-row-class-name="thead-light">
              <el-table-column v-for="column in tableColumns" :key="column.label" v-bind="column">
              </el-table-column>
              <el-table-column min-width="120px" align="right">
                <template v-slot:default="props">
                  <div class="d-flex">
                    <base-button type="info" class="detail" size="sm" role="button"
                      @click="openDetail(props.$index, props.row)" aria-pressed="true">Detalle</base-button>
                    <base-button tag="a" type="success" class="edit" size="sm"
                      :href="`./editar/` + `${props.row.codigoProducto}`" role="button"
                      aria-pressed="true">Editar</base-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <template v-slot:footer>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div class="">
                <p class="card-category d-none">
                  Mostrando {{ from + 1 }} a {{ to }} de {{ total }} productos
                </p>
              </div>
              <default-pagination v-if="searchedData.length" class="pagination-no-border" v-model="pagination.currentPage"
                :per-page="pagination.perPage" :total="total">
              </default-pagination>
            </div>
          </template>
        </card>
      </div>
    </div>
  </div>
  <CustomModal :show="modal.showModal" :isHeader="true" :isFooter="false" :width="400" title="Seleccionar zona"
    v-on:modal:close="modal.showModal = $event">
    <template v-slot:body>
      <div class="row form_laive">
        <div class="col-md-12">
          <div class="form-group">
            <label class="col-form-label form-control-label">Escoja una zona:</label>
            <select v-model="modal.zona" class="form-control" name="zona_lonjeado">
              <option value="-1" selected disabled>Seleccione una zona</option>
              <option value="1">Lima</option>
              <option value="0">Provincia</option>
              <option value="2">Nacional</option>
            </select>
          </div>
        </div>
        <div class="col-md-12">
          <template v-if="modal.zona != '-1'">
            <a :href="`/maestro-productos/crear?zona=${modal.zona}`"
              class="btn base-button btn-primary btn-default">Continuar</a>
            <!-- <router-link to="/maestro-productos/crear/?lonjeado=true" class="btn base-button btn-primary btn-default">Continuar</router-link> -->
          </template>
        </div>
      </div>
    </template>
  </CustomModal>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import swal from "sweetalert2";
import { mapGetters } from 'vuex';
import axios from "axios";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BasePagination from "@/components/BasePagination";
import DashboardNavbar from "../Layout/DashboardNavbar";
import CustomModal from "../Components/CustomModal.vue";
export default {
  components: {
    DashboardNavbar,
    BasePagination,
    RouteBreadCrumb,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    CustomModal
  },
  computed: {
    pagedData() {
      this.getCategories
      this.getDistributor
      this.getGruposVenta
      this.getDataProductos;
      if (this.tableData.length) {
        this.searchedData = this.tableData
        return this.tableData.slice(this.from, this.to);
      }
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          if (row[key]) {
            let rowValue = row[key].toString().toLowerCase();
            if (rowValue.includes && rowValue.includes(this.searchQuery.toLowerCase())) {
              isIncluded = true;
            }
          }
        }
        return isIncluded;
      });
      // console.log(result.length);
      this.searchedData = result
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },

    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    }),
    async getDataProductos() {
      const $this = this;
      if (this.gruposventa.length && this.distribuidoras.length) {
        const selectDistribuodra = ($this.distribuidoraIdCurrent) ? $this.distribuidoraIdCurrent : Number(document.querySelector("[name='distributor_select']").value)
        const selectGruposVenta = document.querySelector("[name='grupoventa_select']").value
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        const parameters = {
          "iddistribuidora": selectDistribuodra,
          "codigocategoria": "",
          "grupoventa": selectGruposVenta
        }
        axios.post(process.env.VUE_APP_API_URL + "/producto/lista", parameters, config)
          .then(function (response) {
            document.querySelector(".form_laive").classList.remove("validateClient")
            return $this.tableData = response.data
          })
      }
    },
    async getCategories() {
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let urlApiMaestros = process.env.VUE_APP_API_URL + "/producto/maestros"
      const requestDistribuidoras = axios.get(urlApiMaestros, config);
      await axios.all([requestDistribuidoras]).then(axios.spread((...responses) => {
        const responseMaoestros = responses[0].data
        $this.categories = responseMaoestros.categorias
        return $this.categories;
      })).catch(errors => {
        // react on errors.
      })
    },
    async getDistributor() {
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let urlApiDistribuidoras = process.env.VUE_APP_API_URL + "/distribuidoras/lista"
      const requestDistribuidoras = axios.get(urlApiDistribuidoras, config);
      await axios.all([requestDistribuidoras]).then(axios.spread((...responses) => {
        const responseDistribuidoras = responses[0].data
        if ($this.distribuidoraIdCurrent) {
          let dataDistibuitorCurrent = responseDistribuidoras.filter((item) => { return item.idDistribuidora == $this.distribuidoraIdCurrent })
          $this.distribuidoraNameCurrent = dataDistibuitorCurrent[0].razonSocial
        }
        $this.distribuidoras = responseDistribuidoras.filter((item) => { return item.estado == "1" })
        return $this.distribuidoras
      })).catch(errors => {
        // react on errors.
      })
    },
    async getGruposVenta() {
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let urlApiGruposVentas = process.env.VUE_APP_API_URL + "/maestro/lista/grupoventa"
      const requestGruposVentas = axios.get(urlApiGruposVentas, config);
      await axios.all([requestGruposVentas]).then(axios.spread((...responses) => {
        const responseGruposVentas = responses[0].data
        $this.gruposventa = responseGruposVentas
        return $this.gruposventa
      })).catch(errors => {
        // react on errors.
      })
    }
  },
  methods: {
    createProduct() {
      this.modal.showModal = true
    },
    openDetail(index, row) {
      const swalWithBootstrapButtons1 = swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          container: "swal-wide",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons1.fire({
        title: `${row.descripcionComercial}`,
        html: `
              <table class="table_laive">
                <tr>
                  <th>Codigo SAP:</th>
                  <td>${row.codigoFabrica}</td>
                </tr>
                <tr>
                  <th>Codigo Uniflex:</th>
                  <td>${row.codigoProducto}</td>
                </tr>
                <tr>
                  <th>Marca:</th>
                  <td>${row.marca}</td>
                </tr>
                <tr>
                  <th>Categoría:</th>
                  <td>${row.categoria}</td>
                </tr>
                <tr>
                  <th>Codigo de Categoria:</th>
                  <td>${row.codigoCategoria}</td>
                </tr>
                <tr>
                  <th>Familia:</th>
                  <td>${row.familia}</td>
                </tr>
                <tr>
                  <th>Codigo de Familia:</th>
                  <td>${row.codigoFamilia}</td>
                </tr>
                <tr>
                  <th>Presentacion:</th>
                  <td>${row.presentacion}</td>
                </tr>
                <tr>
                  <th>Codigo de Presentación:</th>
                  <td>${row.codigoPresentacion}</td>
                </tr>
                <tr>
                  <th>Descripción:</th>
                  <td>${row.descripcion}</td>
                </tr>
                <tr>
                  <th>Descripción Comercial:</th>
                  <td>${row.descripcionComercial}</td>
                </tr>
                <tr>
                  <th>Unidad Base:</th>
                  <td>${row.unidadBase}</td>
                </tr>
                <tr>
                  <th>Unidad Referencia:</th>
                  <td>${row.unidadReferencia}</td>
                </tr>
                <tr>
                  <th>Unidad Compra:</th>
                  <td>${row.unidadCompra}</td>
                </tr>
              </table>`,
        confirmButtonText: "Cerrar"
      });
    },
    changeDistributor(event) {
      const $this = this;
      const select = event.target
      const value = Number(select.value);
      const selectGruposVenta = document.querySelector("[name='grupoventa_select']").value
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const parameters = {
        "iddistribuidora": value,
        "codigocategoria": "",
        "grupoventa": selectGruposVenta
      }
      document.querySelector(".form_laive").classList.add("validateClient")
      axios.post(process.env.VUE_APP_API_URL + "/producto/lista", parameters, config)
        .then(function (response) {
          document.querySelector(".form_laive").classList.remove("validateClient")
          return $this.tableData = response.data
        })
    },
    changeGrupoVenta(event) {
      const $this = this;
      const select = event.target
      const value = select.value
      const selectDistribuodra = Number(document.querySelector("[name='distributor_select']").value)
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const parameters = {
        "iddistribuidora": selectDistribuodra,
        "codigocategoria": "",
        "grupoventa": value
      }
      document.querySelector(".form_laive").classList.add("validateClient")
      axios.post(process.env.VUE_APP_API_URL + "/producto/lista", parameters, config)
        .then(function (response) {
          document.querySelector(".form_laive").classList.remove("validateClient")
          return $this.tableData = response.data
        })
    },
    changeCategory(event) {
      const select = event.target
      const getTextSelected = select.options[select.selectedIndex].text;
      this.searchQuery = getTextSelected
      if (getTextSelected.length == 0) {
        this.searchedData = []
      }
    },
    exportProducto() {
      const selectDistribuidra = (this.distribuidoraIdCurrent) ? this.distribuidoraIdCurrent : Number(document.querySelector("select[name='distributor_select']").value)
      const selectGrupoVenta = document.querySelector("select[name='grupoventa_select']").value
      const selectCategoria = document.querySelector("select[name='category_select']").value
      const token = localStorage.getItem("token")
      const urlDomain = process.env.VUE_APP_API_URL.replace("api", "")
      const configExport = {
        headers: {
          "Authorization": `Bearer ${token}`,
        }
      };
      const parameters = {
        "iddistribuidora": selectDistribuidra,
        "grupoventa": selectGrupoVenta,
        "codigocategoria": selectCategoria,
      }
      document.querySelector(".form_laive").classList.add("validateClient")
      axios.post(process.env.VUE_APP_API_URL + `/producto/exportar`, parameters, configExport)
        .then(function (response) {
          document.querySelector(".form_laive").classList.remove("validateClient")
          const urlFile = urlDomain + response.data.fileName
          const link = document.createElement('a');
          link.href = urlFile;
          document.body.appendChild(link);
          link.click();
        })
    }
  },
  mounted() {
    if (this.user.rol == 3) {
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
    if (this.user.rol == 4) {
      this.supervisorIdCurrent = this.user.codigo
      this.supervisorNameCurrent = this.user.nombre
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
  },
  data() {
    return {
      distribuidoraIdCurrent: null,
      distribuidoraNameCurrent: null,
      supervisorIdCurrent: null,
      supervisorNameCurrent: null,
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      distribuidoras: [],
      gruposventa: [],
      categories: [],
      searchQuery: "",
      propsToSearch: ["categoria", "codigoProducto", "descripcionComercial"],
      tableColumns: [
        {
          prop: "codigoProducto",
          label: "Código",
          minWidth: 120,
        },
        {
          prop: "descripcionComercial",
          label: "Producto",
          minWidth: 220,
        },
        {
          prop: "categoria",
          label: "Categoria",
          minWidth: 120,
        },
      ],
      modal: {
        showModal: false,
        zona: "-1"
      },
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      expands: [],
    };
  },
};
</script>
<style>
.demo-table-expand {
  font-size: 0;
}

.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}

.no-border-card .card-footer {
  border-top: 0;
}

/* .card-body{
  padding-top: 0 !important;
} */
.card-body .title_filter {
  font-size: 14px;
  font-weight: 500;
}

.swal2-container .swal2-header {
  padding: 0;
}

.swal2-container .swal2-content {
  padding: 0;
}

.swal2-container .swal2-popup #swal2-title {
  font-size: 1.2rem;
}

.base-button i {
  position: relative;
  top: 1px;
}
</style>
