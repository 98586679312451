<template>
  <div
    v-if="user.rol === 1 || user.rol === 2"
    class="card-table-container th-bg-slate py-1 px-3"
  >
    <CardTable
      headerTitle="Pedido mínimo"
      buttonText="Carga masiva"
      buttonType="link"
      buttonLink="/equipos-frio/pedido-minimo?tab=carga"
      :showFilters="false"
      :tableData="{
        headers: tabla?.headers ?? [],
        rows: tabla?.rows ?? [],
      }"
      :loading="loading"
      showEmptyMessage
    />
    <ui-flex class="mt-3" justify="end">
      <button
        class="app-btn-pry-icon"
        @click.stop="downloadList()"
        :disabled="loading"
      >
        Descargar lista
        <i class="laive-i icon-download-white" />
      </button>
    </ui-flex>
  </div>
  <div v-else class="p-4 th-bg-slate">
    <h2 class="fs-4 text-dark">No tienes permisos para ver esta sección</h2>
  </div>
</template>
<script>
import { useToast } from "vue-toastification";
import { PedidoMinimo } from "../../../services";
import { CardTable } from "../../../components/Cards";
import SelectButton from "@/components/Shared/SelectButton.vue";
import TextInput from "@/components/Shared/TextInput.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    SelectButton,
    TextInput,
    CardTable,
  },
  data: () => ({
    loading: false,
    tabla: {
      headers: [
        { key: "id-dex", label: "ID DEX" },
        { key: "modelo", label: "Modelo Equipo de frio" },
        { key: "top-may", label: "Top Mayorista" },
        { key: "oro-may", label: "Oro Mayorista" },
        { key: "esp-may", label: "Especial Mayorista" },
        { key: "clas-may", label: "Clásico Mayorista" },
        { key: "top-min", label: "Top minorista" },
        { key: "oro-min", label: "Oro minorista" },
        { key: "esp-min", label: "Especial minorista" },
        { key: "clas-min", label: "Clásico minorista" },
      ],
      rows: [],
    },
    modal: {
      show: false,
    },
  }),
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  mounted() {
    if (this.user.rol > 2) return;

    const token = this.$store.state.auth.token;
    this.loading = true;
    PedidoMinimo.listAll(token)
      .then((data) => {
        const groupDetails = (details) => {
          return details.reduce((acc, { giro, segmento, montoMinimo }) => {
            if (acc[giro]) {
              acc[giro][segmento] = montoMinimo;
            } else {
              acc[giro] = {
                [segmento]: montoMinimo,
              };
            }
            return acc;
          }, {});
        };
        const rows = data.map((pm) => {
          const { Mayorista, Minorista } = groupDetails(pm.detalle);
          return {
            "id-dex": String(pm.idDistribuidora).padStart(5, "0"),
            modelo: pm.modelo,
            "top-may": `S/ ${Mayorista["Top"]}.00`,
            "oro-may": `S/ ${Mayorista["Oro"]}.00`,
            "esp-may": `S/ ${Mayorista["Especial"]}.00`,
            "clas-may": `S/ ${Mayorista["Clasico"] ?? Mayorista["Clásico"]}.00`,
            "top-min": `S/ ${Minorista["Top"]}.00`,
            "oro-min": `S/ ${Minorista["Oro"]}.00`,
            "esp-min": `S/ ${Minorista["Especial"]}.00`,
            "clas-min": `S/ ${Minorista["Clasico"] ?? Minorista["Clásico"]}.00`,
          };
        });
        this.tabla.rows = rows;
      })
      .catch(() => {
        this.toast("No se pudo cargar la informacion", "error");
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    toast(title, type = "success") {
      const toast = useToast();
      if (type == "success") {
        toast.success(title, {
          position: "top-right",
          closeOnClick: true,
          hideProgressBar: true,
          timeout: 6000,
          toastClassName: "th-alert th-alert-pry",
          showCloseButtonOnHover: true,
        });
      } else if (type == "error") {
        toast.error(title, {
          position: "top-right",
          closeOnClick: true,
          hideProgressBar: true,
          timeout: 6000,
          toastClassName: "th-alert th-alert-error",
          showCloseButtonOnHover: true,
        });
      }
    },
    downloadList() {
      const token = this.$store.state.auth.token;
      PedidoMinimo.downloadList(token)
        .then(() => {
          this.toast("Se descargó la lista");
        })
        .catch(() => {
          this.toast("Error al descargar la información", "error");
        });
    },
  },
};
</script>

<style scoped>
.card-table-container :deep(.card-table) {
  margin-top: -3.5em;
}

h2 {
  font-size: 1.7rem;
  font-weight: bold;
  margin-bottom: 1.3em;
}

@media screen and (max-width: 760px) {
  .card-table-container {
    margin-top: 4.5em;
  }
}

.card-table-container:deep(.b-table) {
  min-width: 58.7em;
  --columns: 1fr 1fr 1.1fr 1.1fr 1.1fr 1.1fr 1fr 1fr 1fr 1fr;
  --columns-sm: 1fr 1.4fr 1.2fr 1.2fr 1.2fr 1.3fr 1.2fr 1.2fr 1.2fr 1.1fr;
}
</style>
