
import setting from './setting';
export default {
    getList: ($axios,data)=>{
        return $axios.get(`${setting.http.base_url}/parametro/notificacion/indicador/control`,{headers:setting.http.headers()});
    },

    setNotificacionParameter: ($axios,data)=>{
        return $axios.post(`${setting.http.base_url}/parametro/notificacion/indicador/control/actualizar`,data,{headers:setting.http.headers()});
    }
}