import Badge from "../components/Badge";
import BaseButton from "../components/BaseButton";
import BaseCheckbox from "../components/Inputs/BaseCheckbox";
import BaseDropdown from "../components/BaseDropdown";
import BaseHeader from "../components/BaseHeader";
import BaseInput from "../components/Inputs/BaseInput";
import BaseNav from "../components/Navbar/BaseNav";
import BasePagination from "../components/BasePagination";
import DefaultPagination from "../components/BasePaginationDefault";
import BaseProgress from "../components/BaseProgress";
import BaseRadio from "../components/Inputs/BaseRadio";
import BaseSwitch from "../components/BaseSwitch";
import Card from "../components/Cards/Card";
import StatsCard from "../components/Cards/StatsCard";
import { ElTooltip, ElPopover, ElLoading } from "element-plus";
import { BoxLoader, ThButton } from "../components";

// Import all components from ui folder:
import {
	UIFlex,
	UIGrid,
	UIGridItem,
	UISplit,
	UIButton,
	UIBadge,
	UITitle,
	UIText,
	UIAccordion,
	UIBoxInfo,
	UISelect,
	UIInput,
} from "../ui";

const GlobalComponents = {
	install(app) {
		app.component("badge", Badge);
		app.component("base-button", BaseButton);
		app.component("base-checkbox", BaseCheckbox);
		app.component("base-dropdown", BaseDropdown);
		app.component("base-input", BaseInput);
		app.component("base-nav", BaseNav);
		app.component("base-header", BaseHeader);
		app.component("base-pagination", BasePagination);
		app.component("default-pagination", DefaultPagination);
		app.component("base-progress", BaseProgress);
		app.component("base-radio", BaseRadio);
		app.component("base-switch", BaseSwitch);
		app.component("card", Card);
		app.component("stats-card", StatsCard);
		app.component("box-loader", BoxLoader);
		app.component("th-btn", ThButton);
		app.use(ElTooltip);
		app.use(ElPopover);
		app.use(ElLoading);
		// UI components:
		app.component("ui-flex", UIFlex);
		app.component("ui-grid", UIGrid);
		app.component("ui-grid-item", UIGridItem);

		app.component("ui-split", UISplit);
		app.component("ui-button", UIButton);
		app.component("ui-badge", UIBadge);
		app.component("ui-title", UITitle);
		app.component("ui-text", UIText);

		app.component("ui-accordion", UIAccordion);
		app.component("ui-box-info", UIBoxInfo);
		app.component("ui-select", UISelect);
		app.component("ui-input", UIInput);
	},
};

export default GlobalComponents;
