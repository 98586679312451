<template>
  <div
    class="border rounded px-1 d-flex"
    :class="{
      [wrapper_class]: wrapper_class,
      'bonificacion-form-wrapper': !fit_content,
    }"
  >
    <label class="col-form-label form-control-label pb-0 w-100">
      <span class="line-limit-1" v-if="label">
        {{ label }}
      </span>
      <input
        :type="tipo"
        :placeholder="placeholder"
        class="form-control border-0 pt-0 px-0 pb-0 w-100"
        :class="{
          'text-center': tipo == 'number',
          'h-auto': fit_content == true,
        }"
        :value="modelValue"
        @input="updateValue"
        :disabled="disabled"
      />
    </label>
    <span
      class="d-flex align-items-center"
      v-if="suffix_icon"
      :class="{
        [suffix_icon_class]: suffix_icon_class,
      }"
    >
      <i :class="suffix_icon"></i>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: String,
    label: String,
    tipo: { type: String, required: true },
    placeholder: String,
    wrapper_class: String,
    disabled: Boolean,
    fit_content: Boolean,
    suffix_icon: String,
    suffix_icon_class: String,
  },
  methods: {
    updateValue(event) {
      this.$emit("update:modelValue", event.target.value);
    },
  },
};
</script>

<style>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
