<template>
  <div class="content bonificacion">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>

      <div class="row mx-0 my-2">
        <div
          v-for="mensaje in mensajes"
          :key="mensaje"
          class="col-12 border chip chip-message py-1"
          :class="{
            [mensaje.clase]: mensaje.clase,
          }"
          :style="`opacity: ${mensaje.opacidad};`"
        >
          <i class="far fa-bell"></i>
          <span class="pl-2"> {{ mensaje.texto }} </span>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div :class="{ validateClient: !all_loaded }" class="form_laive">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card bg-white"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
          headerClasses="border-0 px-0"
        >
          <template v-slot:header>
            <div class="row p-0 mx-0">
              <div class="col-12 col-md-12 d-flex align-items-center px-3">
                <span class="mb-0 title"><b>Aprobación de Memos</b></span>
              </div>
            </div>
          </template>
          <div class="row mx-0 px-3">
            <div class="col-8 px-0">
              <div class="row mx-0">
                <div class="col-4 px-1">
                  <b class="font-11">Oficina de ventas</b>
                  <div class="row mx-0">
                    <div class="col-12 p-0">
                      <GeneralDropdown
                        v-if="listas_cargadas"
                        v-model="filtro.oficina"
                        aditional_class="bg-white mh-35px"
                        :options="oficinas_options"
                        :is_multiple="true"
                        :collapse_tags="true"
                        :puede_seleccionar_todos="true"
                        :resumir_tags="true"
                        :fit_content="true"
                      ></GeneralDropdown>
                    </div>
                  </div>
                </div>
                <div class="col-2 px-1">
                  <b class="font-11">Mes ejecucion del gasto</b>
                  <div class="row mx-0">
                    <div class="col-12 p-0">
                      <GeneralDropdown
                        v-if="listas_cargadas"
                        v-model="filtro.mes_ejecucion"
                        aditional_class="bg-white mh-35px"
                        :options="meses_options"
                        :is_multiple="false"
                        :collapse_tags="true"
                        :puede_seleccionar_todos="true"
                        :resumir_tags="true"
                        :fit_content="true"
                      ></GeneralDropdown>
                    </div>
                  </div>
                </div>
                <div class="col-2 px-1">
                  <b class="font-11">Año ejecucion del gasto</b>
                  <div class="row mx-0">
                    <div class="col-12 p-0">
                      <GeneralDropdown
                        v-if="listas_cargadas"
                        v-model="filtro.year_ejecucion"
                        aditional_class="bg-white mh-35px"
                        :options="anios_options"
                        :is_multiple="false"
                        :collapse_tags="true"
                        :puede_seleccionar_todos="true"
                        :resumir_tags="true"
                        :fit_content="true"
                      ></GeneralDropdown>
                    </div>
                  </div>
                </div>
                <div class="col-2 px-1">
                  <b class="font-11">Mes Liquidacion</b>
                  <div class="row mx-0">
                    <div class="col-12 p-0">
                      <GeneralDropdown
                        v-if="listas_cargadas"
                        v-model="filtro.mes_liquidacion"
                        aditional_class="bg-white mh-35px"
                        :options="meses_options"
                        :is_multiple="false"
                        :collapse_tags="true"
                        :puede_seleccionar_todos="true"
                        :resumir_tags="true"
                        :fit_content="true"
                      ></GeneralDropdown>
                    </div>
                  </div>
                </div>
                <div class="col-2 px-1">
                  <b class="font-11">Año Liquidacion</b>
                  <div class="row mx-0">
                    <div class="col-12 p-0">
                      <GeneralDropdown
                        v-if="listas_cargadas"
                        v-model="filtro.year_liquidacion"
                        aditional_class="bg-white mh-35px"
                        :options="anios_options"
                        :is_multiple="false"
                        :collapse_tags="true"
                        :puede_seleccionar_todos="true"
                        :resumir_tags="true"
                        :fit_content="true"
                      ></GeneralDropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4 px-0">
              <div class="row mx-0">
                <div class="col-6 px-1">
                  <b class="font-11">Estado</b>
                  <div class="row mx-0">
                    <div class="col-12 p-0">
                      <GeneralDropdown
                        v-if="listas_cargadas"
                        v-model="filtro.estado"
                        aditional_class="bg-white mh-35px"
                        :options="estados_options"
                        :is_multiple="true"
                        :collapse_tags="true"
                        :puede_seleccionar_todos="true"
                        :resumir_tags="true"
                        :fit_content="true"
                      ></GeneralDropdown>
                    </div>
                  </div>
                </div>
                <div class="col-6 px-1">
                  <b class="font-11">Buscar</b>
                  <div class="row mx-0">
                    <div class="col-12 p-0">
                      <GeneralInput
                        v-model="searchQuery"
                        tipo="text"
                        :placeholder="'Cód. o descripción'"
                        :fit_content="true"
                        wrapper_class="py-1 mh-35px"
                        suffix_icon="fas fa-search"
                        suffix_icon_class="bonificacion-light-gray-text"
                      ></GeneralInput>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mx-0 mt-2 header">
            <div class="col-9 px-0">
              <div class="row mx-0 h-100">
                <div
                  class="col-2 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
                >
                  <span class="px-1"> Cód. Memo </span>
                </div>

                <div
                  class="col-2 px-0 font-10 py-2 text-center d-flex align-items-center justify-content-center"
                >
                  <span class="px-1"> Descripción </span>
                </div>
                <div
                  class="col-1 px-0 font-10 py-2 text-center d-flex align-items-center justify-content-center"
                >
                  <span class="px-1"> Oficina de ventas </span>
                </div>
                <div
                  class="col-1 px-0 font-10 py-2 text-center d-flex align-items-center justify-content-center"
                >
                  <span class="px-1"> Mes de liquidación </span>
                </div>
                <div
                  class="col-1 px-0 font-10 py-2 text-center d-flex align-items-center justify-content-center"
                >
                  <span class="px-1"> Aprobación de memo </span>
                </div>
                <div
                  class="col-1 px-0 font-10 py-2 text-center d-flex align-items-center justify-content-center"
                >
                  <span class="px-1"> Total </span>
                </div>
                <div
                  class="col-2 px-0 font-10 py-2 text-center d-flex align-items-center justify-content-center"
                >
                  <span class="px-1"> Estado </span>
                </div>
              </div>
            </div>
            <div class="col-3 px-0"></div>
          </div>
          <Row
            v-if="data_cargada"
            v-for="memo in memos"
            :key="memo"
            :memo="memo"
          ></Row>
        </card>
      </div>
    </div>
  </div>
</template>

<script setup>
import "@/assets/sass/laive/bonificaciones.scss";
import DashboardNavbar from "../../Layout/DashboardNavbar";
import { ref, computed, watch, onMounted } from "vue";
import GeneralDropdown from "../../Bonificaciones/components/GeneralDropdown.vue";
import Row from "./components/Row.vue";
import { useStore } from "vuex";
import constantes from "@/model/constantes.js";
import GeneralInput from "../../Bonificaciones/components/GeneralInput.vue";
const store = useStore();
import Swal from "sweetalert2";

const memos_raw = computed(() => store.state.liquidacion.liquidaciones_gerente);

const memos = computed(() => {
  data_cargada.value = false;
  const result = memos_raw.value;
  let fuente = result.filter((memo) =>
    filtro.value.estado.includes(memo.estado)
  );
  fuente = fuente.filter((memo) =>
    filtro.value.oficina.includes(memo.oficinaventa)
  );
  const busqueda = searchQuery.value.trim();
  const filtered = busqueda
    ? fuente.filter(
        (memo) =>
          memo.descripcion.toLowerCase().includes(busqueda.toLowerCase()) ||
          memo.codigo.includes(busqueda.toLowerCase())
      )
    : fuente;
  data_cargada.value = true;
  return filtered;
});

const all_loaded = computed(
  () => store.state.bonificacionUtil.distribuidoras?.length > 0
);
const filtro = ref({});

const fechaActual = ref(new Date());
const fechaHaceUnMes = ref(new Date());
const listas_cargadas = ref(false);
const data_cargada = ref(false);
const searchQuery = ref("");
onMounted(() => {
  fechaHaceUnMes.value.setMonth(fechaHaceUnMes.value.getMonth() - 1);
  store
    .dispatch("bonificacionUtil/cargarLista", "distribuidoras")
    .then((result) => {
      setTimeout(() => {
        filtro.value.mes_ejecucion = fechaHaceUnMes.value.getMonth() + 1;
        filtro.value.year_ejecucion = fechaHaceUnMes.value.getFullYear();
        filtro.value.mes_liquidacion = fechaActual.value.getMonth() + 1;
        filtro.value.year_liquidacion = fechaActual.value.getFullYear();

        const request = {
          year_ejecucion: filtro.value.year_ejecucion,
          month_ejecucion: filtro.value.mes_ejecucion,
          year_liquidacion: filtro.value.year_liquidacion,
          month_liquidacion: filtro.value.mes_liquidacion,
        };

        filtro.value.estado = [
          constantes.PENDIENTE,
          constantes.OBSERVADO,
          constantes.APROBADO,
        ];

        filtro.value.oficina = oficinas_options.value.map((item) => item.value);

        store
          .dispatch("liquidacion/obtenerLiquidacionesGerente", request)
          .then(() => {
            data_cargada.value = true;
          });

        listas_cargadas.value = true;
      }, 500);
    });
});

const distribuidoras_options = computed(() =>
  store.state.bonificacionUtil.distribuidoras.map((item) => {
    return {
      value: item.iddistribuidora,
      label: item.codigodistribuidora,
    };
  })
);
const anios_options = computed(() => {
  let currencyYear = new Date().getFullYear()
  let quantityYears = 0
  const list = []
  while (quantityYears < 4) {
    list.push({
      value: `${currencyYear - quantityYears}`,
      label: `${currencyYear - quantityYears}`,
    })
    quantityYears++;
  }

  return list
});
const meses_options = computed(() => [
  {
    value: 1,
    label: "Enero",
  },
  {
    value: 2,
    label: "Febrero",
  },
  {
    value: 3,
    label: "Marzo",
  },
  {
    value: 4,
    label: "Abril",
  },
  {
    value: 5,
    label: "Mayo",
  },
  {
    value: 6,
    label: "Junio",
  },
  {
    value: 7,
    label: "Julio",
  },
  {
    value: 8,
    label: "Agosto",
  },
  {
    value: 9,
    label: "Setiembre",
  },
  {
    value: 10,
    label: "Octubre",
  },
  {
    value: 11,
    label: "Noviembre",
  },
  {
    value: 12,
    label: "Diciembre",
  },
]);
const estados_options = computed(() => [
  {
    value: constantes.PENDIENTE,
    label: "Pendiente",
  },
  {
    value: constantes.OBSERVADO,
    label: "Enviado",
  },
  {
    value: constantes.APROBADO,
    label: "Aprobado",
  },
]);
const oficinas_options = computed(() => [
  {
    value: "Lima",
    label: "Lima",
  },
  {
    value: "Provincia",
    label: "Provincia",
  },
]);

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-success",
    cancelButton: "btn btn-danger",
  },
  buttonsStyling: false,
});

const confirmarMemo = () => {
  swalWithBootstrapButtons
    .fire({
      title: "Enviar Memo de Aprobación",
      text:
        "Esta acción es irreversible, sólo se enviarán las liquidaciones aprobadas tanto por el Jefe de Operaciones como por el Subgerente",
      showCancelButton: true,
      confirmButtonText: "Enviar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    })
    .then((result) => {
      if (result.isConfirmed) {
        store
          .dispatch("liquidacion/CrearMemo", {
            year: filtro.value.year,
            mes: filtro.value.mes,
          })
          .then((respuesta) => {
            store.dispatch("liquidacion/obtenerLiquidacionesSubGerente", {
              year: filtro.value.year,
              month: filtro.value.mes,
              distribuidoras: filtro.value.dex.join(","),
            });
          });
      }
    });
};
const obtenerLiquidacionesAprobadas = () => {
  return liquidaciones_por_distribuidoras.value.reduce(
    (acumulador, distribuidora) => {
      return acumulador.concat(
        distribuidora.liquidaciones.filter(
          (item) => item.estado == constantes.APROBADO
        )
      );
    },
    []
  );
};

watch(
  () => filtro.value.year_liquidacion,
  (nuevoValor, valorAnterior) => {
    aplicarFiltros();
  }
);

watch(
  () => filtro.value.mes_liquidacion,
  (nuevoValor, valorAnterior) => {
    aplicarFiltros();
  }
);

watch(
  () => filtro.value.year_ejecucion,
  (nuevoValor, valorAnterior) => {
    aplicarFiltros();
  }
);

watch(
  () => filtro.value.mes_ejecucion,
  (nuevoValor, valorAnterior) => {
    aplicarFiltros();
  }
);

const aplicarFiltros = () => {
  if (data_cargada.value == true) {
    data_cargada.value = false;
    const request = {
      year_ejecucion: fechaHaceUnMes.value.getFullYear(),
      month_ejecucion: filtro.value.mes_ejecucion,
      year_liquidacion: fechaActual.value.getFullYear(),
      month_liquidacion: filtro.value.mes_liquidacion,
    };
    store
      .dispatch("liquidacion/obtenerLiquidacionesGerente", request)
      .then(() => {
        data_cargada.value = true;
      });
  }
};

const mensajes = computed(() => store.state.liquidacion.mensajes);
</script>

<style scoped>
.submit-btns {
  height: fit-content !important;
}

</style>
