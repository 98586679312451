<template>
  <div class="accordion confirmation-field" :class="{ 'is-active': isOpen }">
    <div class="accordion-item-title header">
      <button @click="toggleAccordion(dropdown)" :aria-expanded="isOpen" :aria-controls="`collapse${uid}`"
        class="accordion-item-trigger d-flex justify-content-between" :class="getClass(backgroundColor, dropdown)">
        <h4 class="title">
          <slot name="title" />
        </h4>

        <img v-if="isEdit" @click="performAction($event)" :class="getEditIconClass(backgroundColor)"
          :src="getEditIcon(backgroundColor)" alt="Editar Datos">

        <template v-if="dropdown">
          <div class="arrow-trigger">
            <span class="accordion-item-trigger-icon"></span>
          </div>
        </template>

      </button>
    </div>

    <transition name="accordion-item" @enter="startTransition" @before-enter="startTransition"
      @after-enter="endTransition" @before-leave="startTransition" @after-leave="endTransition">
      <div v-show="isOpen" :id="`collapse${uid}`" class="accordion-item-details">
        <div class="accordion-item-details-inner body">
          <slot name="content" />
        </div>
      </div>
    </transition>

  </div>
</template>

<script>
export default {
  emits: ["update:isOpen"],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    dropdown: {
      type: Boolean,
      default: true
    },
    showOnEdit: '',
  },
  created() {
    this.uid = Math.random().toString(16).slice(2);
  },
  methods: {
    toggleAccordion(dropdown) {
      if (!dropdown) {
        return;
      }
      this.$emit("update:isOpen", !this.isOpen);
    },
    performAction(event) {
      event.stopPropagation();
      this.$emit('performAction', this.showOnEdit);
    },
    startTransition(el) {
      el.style.height = el.scrollHeight + 'px'
    },
    endTransition(el) {
      el.style.height = ''
    },
    getClass: (color, dropdown) => {

      if (color !== undefined) {

        let backgroundColor;

        backgroundColor = `${color}`;

        var className = `background-${backgroundColor}`;

        if (!dropdown) {
          className += ` no-dropdown`;
        }

        return className;

      } else {

        if (!dropdown) {
          return `no-dropdown`;
        }

      }

    },
    getEditIcon(color) {

      return color == 'gray' ? '/assets/images/icons/edit.svg' : '/assets/images/icons/edit-white.svg';

    },
    getEditIconClass(color) {

      return color == 'gray' ? 'edit-btn color-white' : 'edit-btn';

    }
  },
};
</script>

<style lang="scss" scoped>
@use "sass:math";
@import "../../assets/sass/globals/_variables.scss";
@import "../../assets/sass/globals/_mixins.scss";

.accordion-item-trigger {
  width: 100%;
  text-align: left;

  border: none;
  border-radius: 8px;
  background: #EAEBE3;

  padding: 11.5px 20px;

  color: #1A1818;

  .title {
    color: #1A1818 !important;
  }

  .accordion-item-trigger-icon {
    border-right: 2px solid #1C1B1F;
    border-bottom: 2px solid #1C1B1F;
  }

  .edit-btn {
    //border-right: 2px solid white;

    margin-right: 71px;
    padding-left: 80px;
    padding-right: 31px;
    padding-top: 12px;
    position: absolute;
    top: 0;
    padding-bottom: 13px;
    right: 0;
    cursor: pointer;

    &.color-white {
      border-right: 2px solid #02A54F;
    }
  }

  .edit-btn+.arrow-trigger {
    border-left: 2px solid white;
    padding-left: 50px;
    width: 20px;
    height: 20px;
  }

  &.no-dropdown {

    cursor: initial;

    .edit-btn {
      margin-right: 0;
    }
  }

}

.accordion-item-title {
  position: relative;

  h4 {
    font-size: 1.25rem;
    margin-bottom: 0;
  }
}

.accordion-item-trigger-icon {
  $size: 10.67px;
  display: block;
  position: absolute;
  top: 3px;
  right: 1.25rem;
  bottom: 0;
  margin: auto;
  width: $size;
  height: $size;
  border-right: 2px solid #ffffff; //2px solid #02A54F;
  border-bottom: 2px solid #ffffff; //2px solid #02A54F;
  transform: translateY(math.div(-$size, 4)) rotate(45deg);
  transition: transform 0.2s ease;

  .is-active & {
    transform: translateY(math.div($size, 4)) rotate(225deg);
  }
}

.accordion-item-enter-active,
.accordion-item-leave-active {
  will-change: height, opacity;
  transition: height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
}

.accordion-item-enter,
.accordion-item-leave-to {
  height: 0 !important;
  opacity: 0;
}

.confirmation-field {
  margin-bottom: 15px;

  .header {
    background: rgba(247, 247, 247, 0.5);
    border: 1px solid #eaebe3;
    border-radius: 10px;

    .title {
      font-family: 'Poppins';
      font-weight: 700;
      font-size: 1rem;
      text-transform: uppercase;
      line-height: 22px;
      color: #02A54F;
      margin-bottom: 0;
      border: 0;

      @media (max-width: 1600px) {
        font-size: 13px;
      }

    }
  }

}
</style>
