<template>
  <div class="row">
    <div class="col-lg-12 px-0">
      <div class="row mx-0">
        <div class="col-md-5 px-1">
          <h3 for="">Datos generales</h3>
        </div>
        <div class="col-md-7 col-sm-12 col-12 col-lg-7 pr-0">
          <div class="row mx-0">
            <div class="col-md-4 px-1">
              <span class="section-title">Grupo de productos</span>
            </div>
            <div class="col-md-8 px-1">
              <Toggle
                v-model="tipo_regalo"
                :true_value="constantes.TIPO_REGALO_PORCENTAJE"
                :false_value="constantes.TIPO_REGALO_PRODUCTO"
              ></Toggle>
              <span class="section-title">Bonificar descuento</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" :class="bonificacion.tipo ? '' : 'laive-disabled'">
    <div class="col-lg-12 px-0">
      <div class="row mx-0">
        <div class="col-md-5 col-sm-12 col-12 col-lg-5 pr-2">
          <div class="row">
            <div class="col-md-7 my-1 px-1">
              <GeneralInput
                v-model="bonificacion.descripcion"
                tipo="text"
                label="Descripción"
              ></GeneralInput>
            </div>
            <div class="col-md-5">
              <div class="row h-100">
                <div class="col-md-6 my-1 px-1">
                  <DateInput
                    v-model="bonificacion.inicio"
                    tipo="date"
                    label="Inicio"
                    :disabled-date="disabledDate"
                    :disabled="es_edicion && !bonificacion.puede_modificar_inicio"
                  ></DateInput>
                </div>
                <div class="col-md-6 my-1 px-1">
                  <DateInput
                    v-model="bonificacion.fin"
                    tipo="date"
                    label="Fin"
                    :disabled-date="disabledDate"
                    :disabled="es_edicion && !bonificacion.puede_modificar_fin"
                  ></DateInput>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-7 col-sm-12 col-12 col-lg-7 pr-0">
          <div class="row mx-0 w--100">
            <div class="col-md-4 px-1 my-1">
              <GeneralDropdown
                v-model="bonificacion.prods_promo[0].categorias"
                label="Caterogías"
                aditional_class="bg-white"
                :options="categorias_options"
                :is_multiple="true"
                :collapse_tags="true"
                :puede_seleccionar_todos="true"
                :resumir_tags="true"
                :disabled="es_edicion"
              ></GeneralDropdown>
            </div>
            <div class="col-md-8 px-1">
              <div
                class="row mx-0"
                v-for="(item, index) in bonificacion.prods_regalo"
              >
                <div
                  class="col-md-8 my-1 px-1"
                  :class="{
                    'laive-disabled':
                      item.tipo != constantes.TIPO_REGALO_PRODUCTO,
                  }"
                >
                  <GeneralDropdown
                    v-model="item.productos"
                    label="Prod. Promocional"
                    :options="productos_options"
                    :is_multiple="true"
                    :collapse_tags="false"
                    :filterable="true"
                    placeholder="Selecciona productos"
                    :disabled="
                      item.tipo != constantes.TIPO_REGALO_PRODUCTO || es_edicion
                    "
                  ></GeneralDropdown>
                </div>
                <div
                  class="col-md-2 my-1 px-1"
                  :class="{
                    'laive-disabled':
                      item.tipo != constantes.TIPO_REGALO_PRODUCTO,
                  }"
                >
                  <GeneralInput
                    v-model="item.unidades"
                    tipo="number"
                    label="Undidades"
                    placeholder="00"
                    wrapper_class="text-center"
                    :disabled="
                      item.tipo != constantes.TIPO_REGALO_PRODUCTO || es_edicion
                    "
                  ></GeneralInput>
                </div>
                <div
                  class="col-md-2 my-1 px-1"
                  :class="{
                    'laive-disabled':
                      item.tipo != constantes.TIPO_REGALO_PORCENTAJE,
                  }"
                >
                  <GeneralInput
                    v-model="item.valor"
                    tipo="number"
                    label="Dscto %"
                    placeholder="0%"
                    wrapper_class="text-center"
                    :disabled="
                      item.tipo != constantes.TIPO_REGALO_PORCENTAJE ||
                      es_edicion
                    "
                  ></GeneralInput>
                </div>
              </div>
              <div
                class="row mx-0 d-flex justify-content-between px-1"
                v-if="tipo_regalo == constantes.TIPO_REGALO_PRODUCTO"
              >
                <button
                  type="button"
                  class="bonificacion-producto-btn px-0 bonificacion-primary-text"
                  @click.prevent="agregar"
                >
                  <i class="fas fa-plus-circle"></i>
                  <span class=""> Agregar Bonificacion</span>
                </button>
                <button
                  :disabled="bonificacion.prods_regalo.length == 1"
                  type="button"
                  :class="{
                    'text-danger': bonificacion.prods_regalo.length > 1,
                  }"
                  class="bonificacion-producto-btn px-1"
                  @click.prevent="eliminar"
                >
                  <i class="far fa-times-circle"></i>
                  <span class=""> Eliminar</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import GeneralInput from "./GeneralInput.vue";
import DateInput from "./DateInput.vue";
import GeneralDropdown from "./GeneralDropdown.vue";
import Toggle from "./Toggle.vue";
import constantes from "@/model/constantes.js";

const store = useStore();

const disabledDate = (time) => {
  let today = new Date().setHours(0, 0, 0, 0)
  return time.getTime() < today
}

const { bonificacion, es_edicion } = defineProps({
  bonificacion: {
    type: Object,
  },
  productos_options: Array,
  es_edicion: {
    type: Boolean,
    default: false,
  },
});

const agregar = () => {
  bonificacion.prods_regalo.push({
    producto: null,
    unidades: null,
    tipo: tipo_regalo.value,
    valor: null,
  });
};

const eliminar = () => {
  bonificacion.prods_regalo.pop();
};
const producto = ref(null);
const tipo_regalo = ref(constantes.TIPO_REGALO_PRODUCTO);
const categorias = ref([]);
onMounted(() => {
  if (!es_edicion) {
    bonificacion.prods_promo = [{ categorias: [] }];
    bonificacion.prods_regalo = [
      {
        productos: [],
        unidades: null,
        tipo: constantes.TIPO_REGALO_PRODUCTO,
        valor: null,
      },
    ];
  }
});

const categorias_raw = computed(() => store.state.bonificacionUtil.categorias);

const categorias_options = computed(() => {
  return categorias_raw.value.map((item) => {
    return {
      value: item.codigodato,
      label: item.nombre,
    };
  });
});

watch(tipo_regalo, (nuevo) => {
  const item = bonificacion.prods_regalo[0];
  item.tipo = nuevo;
  bonificacion.prods_regalo = [item];
});
</script>
<style lang="scss" scoped></style>
