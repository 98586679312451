<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block only_hidden_after">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive validateClient">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template v-slot:header>
            <div class="row">
              <div class="col-12 col-md-6">
                <h3 class="mb-0">Catálogo de errores</h3>
              </div>
            </div>
          </template>
          <div>
            <div class="row px-3 mb-3">
              <div class="col-12 col-md-3">
                  <span class="title_filter mr-2">Modulos:  </span>
                  <select v-model="searchQuery" class="form-control" id="modulos_select" style="display: inline-block;height: 43px;" name="modulos_select">
                    <option value="">Todos</option>
                    <option
                      v-for="item in modules"
                      :value="item.nombre"
                      :key="item.nombre"
                      >
                      {{ item.nombre }}
                    </option>
                  </select>
                </div>
            </div>
            <!-- Real Table -->
            <el-table
              :data="queriedData"
              row-key="erroresapp"
              :empty-text="emptyText"
              class=""
              header-row-class-name="thead-light"
            >
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                v-bind="column"
              >
              </el-table-column>
            </el-table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import axios from "axios";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BasePagination from "@/components/BasePagination";
import {mapGetters, mapActions} from 'vuex';
import DashboardNavbar from "../Layout/DashboardNavbar";
export default {
  name: "ListErrors",
  components: {
    DashboardNavbar,
    BasePagination,
    RouteBreadCrumb,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  computed: {
    pagedData() {
      this.getDataErros
      this.getListModules
      if(this.tableData.length){
        return this.tableData;
      }
    },
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString().toLowerCase();
          if (rowValue.includes && rowValue.includes(this.searchQuery.toLowerCase())) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });
      return result
    },
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    }),
    getListModules(){
      const data = this.tableData
      let arrTmp = []
      data.forEach(item => {
        arrTmp.push({
          "nombre" :  item.modulo
        })
      });
      const arrUnique =  [...new Map(arrTmp.map(item => [item["nombre"], item])).values()]
      this.modules = arrUnique
    },
    getDataErros(){
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const apiListErros = process.env.VUE_APP_API_URL+`/catalogo`
      axios.get(apiListErros,config)
      .then(function (response){
        const data = response.data
        $this.tableData = data
        document.querySelector(".form_laive").classList.remove("validateClient")
      }).catch(errors => {
        // react on errors.
        $this.emptyText = "No se encontraron resultados"
        document.querySelector(".form_laive").classList.remove("validateClient")
      })
    },
  },
  data() {
    return {
      initload: true,
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["modulo"],
      tableColumns: [
        {
          prop: "modulo",
          label: "Módulo",
          minWidth: 120,
        },
        {
          prop: "respuesta",
          label: "Respuesta",
          minWidth: 120,
        },
        {
          prop: "codigo",
          label: "Código",
          width: 120,
        },
        {
          prop: "mensaje",
          label: "Respuesta",
          minWidth: 140,
        },
        {
          prop: "descripcion",
          label: "Descripción",
          width: 320,
        },
        {
          prop: "criticidad",
          label: "Criticidad",
          minWidth: 140,
        },
      ],
      tableData: [],
      modules: [],
      emptyText: "No se encontraron resultados",
      searchedData: [],
    };
  },
};
</script>
