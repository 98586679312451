<template>
  <div class="content bonificacion">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div :class="{ validateClient: !all_loaded }" class="form_laive">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card bg-white"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
          headerClasses="border-0 px-0"
        >
          <template v-slot:header>
            <div class="row p-0 mx-0">
              <div class="col-12 col-md-6 d-flex align-items-center px-3">
                <span class="mb-0 title"
                  ><b>Administración Usuarios Trade</b></span
                >
              </div>
            </div>
          </template>

          <div class="row mx-0 px-4" v-if="mensaje">
            <div class="alert w-100 p-2 font-10" :class="mensaje.class" role="alert">
              {{ mensaje.texto }}
            </div>
          </div>

          <div class="row py-4 mx-0 px-0" v-if="all_loaded">
            <div class="col-12 px-0">
              <div class="row mt-4 mx-0 w-100 thead-light font-11 py-3">
                <div class="col-2 px-1 text-center">
                  <b class="font-11">Usuario</b>
                </div>
                <div class="col-2 px-1 text-center">
                  <b class="font-11">Rol</b>
                </div>
                <div class="col-3 px-1 text-center">
                  <b class="font-11">Distribuidoras</b>
                </div>
                <div class="col-4 px-1"></div>
              </div>
              <UsuarioRow
                v-for="usuario in usuarios_trade"
                :key="usuario"
                :usuario_raw="usuario"
              ></UsuarioRow>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script setup>
import "@/assets/sass/laive/bonificaciones.scss";
import UsuarioRow from "./components/UsuarioRow.vue";
import { ref, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
const store = useStore();

store.dispatch("bonificacionUtil/cargarLista", "distribuidoras");
store.dispatch("usuarioTrade/cargarLista");

const roles = ref([
  {
    value: "5",
    label: "Jefe de operaciones",
  },
  {
    value: "6",
    label: "Sub Gerente",
  },
  {
    value: "7",
    label: "Gerente",
  },
]);
const mensaje = computed(() => store.state.usuarioTrade.mensaje);
const usuarios_trade = computed(() =>
  store.state.usuarioTrade.usuarios.map((item) => {
    return {
      idUsuario: item.idUsuario,
      nombre: item.nombre,
      userName: item.userName,
      codigoRol: item.codigoRol,
      rol: roles.value.find((rol) => rol.value == item.codigoRol)?.label,
      email: item.email,
      distribuidoras: item.distribuidoras ? item.distribuidoras.split(",") : [],
    };
  })
);

const all_loaded = computed(
  () => store.state.bonificacionUtil.distribuidoras.length
);

onMounted(() => {});
</script>
<style lang="scss" scoped>
.thead-light {
  background: #f7f9fc;
  font-size: 11px;
  font-weight: 600;
}
</style>
