import { mapActions, mapGetters } from 'vuex';
import NotificationService from '../services/NotificationService';
import axios from 'axios';
export default { 
    namespaced: true,
    state:{},
    getters:{},
    mutations:{},
    methods:{ },
    actions:{
        async getList({state,commit},data) {
            return await NotificationService.getList(axios,data);
        },
        async getDetalle({state,commit},data) {
            return await NotificationService.getDetalle(axios,data);
        },
        async getDetalleCliente({state,commit},data) {
            return await NotificationService.getDetalleCliente(axios,data);
        },
    }
}