import setting from './setting';
export default {
    getListByDistribuidoraSupervisor: ($axios,data)=>{
        return $axios.post(`${setting.http.base_url}/vendedor/lista`,data, {headers:setting.http.headers()});
    },
    getListByDistribuidora: ($axios,data)=>{
        return $axios.post(`${setting.http.base_url}/vendedor/lista`,data, {headers:setting.http.headers()});
    },
//
    getListByMesa: ($axios,data)=>{
        return $axios.post(`${setting.http.base_url}/vendedor/lista/pormesa`,data, {headers:setting.http.headers()});
    },

    getLocationTimeRoute: ($axios,data)=>{
        return $axios.post(`${setting.http.base_url}/supervision/ruta/vendedores`,data, {headers:setting.http.headers()});
    },

    getSupervisionVendedorDetalle: ($axios,data)=>{
        return $axios.post(`${setting.http.base_url}/supervision/ruta/vendedores/detalle/vendedor`,data, {headers:setting.http.headers()});
    },
    getSupervisionClientDetalle: ($axios,data)=>{
        return $axios.post(`${setting.http.base_url}/supervision/ruta/vendedores/detalle/cliente`,data, {headers:setting.http.headers()});
    },
    getFiltroAvanzadoParameter: ($axios,data)=>{
        return $axios.post(`${setting.http.base_url}/supervision/ruta/vendedores/filtro/avanzado`,data, {headers:setting.http.headers()});
    },
    getGeocerca: ($axios,data)=>{
        return $axios.post(`${setting.http.base_url}/supervision/geocerca/vendedores`,data, {headers:setting.http.headers()});
    },

    
}