import { mapActions, mapGetters } from 'vuex';
import BonificacionService from '../services/BonificacionService';
import axios from 'axios';
import constantes from '../model/constantes';

const mapearBonificaciones = (elementos) => {
    const resultado = elementos.map(item => {

        const costos_raw = item.costos_raw.flatMap(
            (producto) => producto.costos
        );

        const vendedores = item.vendedores.map(vendedor => {
            const valores = vendedor.split('-');
            return {
                idbonidistribuidora: valores[0].trim(),
                idvendedor: valores[1].trim(),
            }
        });

        let costo_regalo_soles = null;

        if (item.tipo == constantes.TIPO_COMBO) {
            const regalo = item.prods_regalo[0];
            if (regalo.tipo == constantes.TIPO_REGALO_SOLES) {
                costo_regalo_soles = regalo.valor;
            }
        }

        return {
            nombre: item.descripcion,
            tipo: item.tipo,
            distribuidor: item.carga_masiva
                ? mapearDistribuidorasDesdeExcel(item, costos_raw, costo_regalo_soles)
                : item.distribuidoras.map(distribuidora => mapearDistribuidora(distribuidora, item, vendedores, costos_raw, costo_regalo_soles)),
            grupoProducto: item.prods_promo.map(elemento => {
                return {
                    cantidad: parseInt(elemento.unidades),
                    productos: elemento.productos?.map(item => { return { codigoproducto: item } }),
                    tipo: elemento.tipo,
                    idcategoria: elemento.categorias?.join(','),
                    idfamilia: elemento.familias?.join(','),
                    idpresentacion: elemento.presentaciones?.join(','),
                    proposito: elemento.proposito,
                    escala: item.tipo == constantes.TIPO_ESCALA
                        ? elemento.detalles.map((detalle, index) => {
                            return {
                                correlativo: index,
                                bultos: parseInt(detalle.bultos),
                                descuento: parseInt(detalle.descuento)
                            }
                        })
                        : []

                }
            }),
            regalo: item.prods_regalo.map(regalo => {
                return {
                    cantidad: parseInt(regalo.unidades),
                    tipo: regalo.tipo,
                    valor: regalo.valor,
                    regaloProductos: regalo.productos.map(producto => {
                        return {
                            codigoproducto: producto
                        }
                    })
                }
            }),
            codigo: item.codigo,
            relaciones_ids: item.relaciones_ids?.join(',')
        }
    });

    return {
        bonificacion: resultado
    };
}

const mapearDistribuidora = (distribuidora, item_bonificacion, vendedores, costos_raw = null, costo_regalo_soles = null) => {
    const costos = costos_raw.filter(item => item.idDistribuidor == distribuidora.toString());
    const costo_distribuidora = item_bonificacion.costos_por_dex?.find(item => item.distribuidora.toString() == distribuidora.toString());

    return {
        iddistribuidora: distribuidora,
        fechainicio: item_bonificacion.inicio,
        fechafin: item_bonificacion.fin,
        giro: item_bonificacion.giros_negocio.join(','),
        segmento: item_bonificacion.segmentos.join(','),
        tipocliente: item_bonificacion.tipos_cliente.join(','),
        tipovendedor: item_bonificacion.tipos_vendedor?.join(','),
        idvendedor: vendedores.filter(item => item.idbonidistribuidora == distribuidora).map(item => item.idvendedor)?.join(','),
        precios: costos.map(item => {
            return {
                codigoproducto: item.productoId,
                costobodega: item.costoBodega,
                costodist: item.costoDist,
                isc: item.isc
            }
        }),
        totalcliente: item_bonificacion.clientes_por_distribuidora?.find(item => item.iddistribuidora == distribuidora).totalCliente,
        costoproductos: costo_distribuidora ? costo_distribuidora.costo_productos : null,
        costoproductosund: costo_distribuidora ? costo_distribuidora.costo_productos_und : null,

        costoregalos: costo_regalo_soles == null ? (costo_distribuidora ? costo_distribuidora.costo_regalos : null) : costo_regalo_soles,
        costoregalosund: costo_regalo_soles == null ? (costo_distribuidora ? costo_distribuidora.costo_regalos_und : null) : costo_regalo_soles,

        costoregalosdist: costo_regalo_soles == null ? (costo_distribuidora ? costo_distribuidora.costo_regalos_dist : null) : costo_regalo_soles,
        costoregalosunddist: costo_regalo_soles == null ? (costo_distribuidora ? costo_distribuidora.costo_regalos_und_dist : null) : costo_regalo_soles,

    };
};

const mapearDistribuidorasDesdeExcel = (item_bonificacion, costos_raw, costo_regalo_soles = null) => {
    const distribuidoras = [];
    item_bonificacion.datos_extraidos.forEach(element => {
        const excel_id = element[0];
        const existe = distribuidoras.find(
            (distribuidora) => distribuidora.iddistribuidora == excel_id
        );
        if (!existe) {

            const costos = costos_raw.filter(item => item.idDistribuidor == excel_id.toString());
            const costo_distribuidora = item_bonificacion.costos_por_dex.find(item => item.distribuidora.toString() == excel_id)

            distribuidoras.push(
                {
                    iddistribuidora: excel_id,
                    cliente: [],
                    documentpath: item_bonificacion.excel_path,
                    documentpathoriginal: item_bonificacion.excel_name,
                    fechainicio: item_bonificacion.inicio,
                    fechafin: item_bonificacion.fin,
                    precios: costos.map(item => {
                        return {
                            codigoproducto: item.productoId,
                            costobodega: item.costoBodega,
                            costodist: item.costoDist,
                            isc: item.isc,
                        }
                    }),
                    totalcliente: 0,
                    costoproductos: costo_distribuidora ? costo_distribuidora.costo_productos : null,
                    costoproductosund: costo_distribuidora ? costo_distribuidora.costo_productos_und : null,

                    costoregalos: costo_regalo_soles == null ? (costo_distribuidora ? costo_distribuidora.costo_regalos : null) : costo_regalo_soles,
                    costoregalosund: costo_regalo_soles == null ? (costo_distribuidora ? costo_distribuidora.costo_regalos_und : null) : costo_regalo_soles,

                    costoregalosdist: costo_regalo_soles == null ? (costo_distribuidora ? costo_distribuidora.costo_regalos_dist : null) : costo_regalo_soles,
                    costoregalosunddist: costo_regalo_soles == null ? (costo_distribuidora ? costo_distribuidora.costo_regalos_und_dist : null) : costo_regalo_soles,

                })
        }
    });
    item_bonificacion.datos_extraidos.forEach(item => {
        distribuidoras.forEach(distribuidora => {
            if (distribuidora.iddistribuidora == item[0]) {
                distribuidora.totalcliente = distribuidora.totalcliente + 1;
                distribuidora.cliente.push({
                    codigocliente: `${item[1]}`,
                    objetivo: item[2] ?? null
                })
            }
        });
    });

    return distribuidoras;
}

const tipos = [
    { value: constantes.TIPO_PROMOCION, label: constantes.PROMOCION_LABEL },
    { value: constantes.TIPO_COMBO, label: constantes.COMBO_LABEL },
    { value: constantes.TIPO_ESCALA, label: constantes.ESCALA_LABEL },
    { value: constantes.TIPO_REBATE, label: constantes.REBATE_LABEL },
];
const mapearBonificacionesPorAprobarDesdeServicio = (modelos) => {

    const cards = [];
    modelos.forEach(modelo => {
        const agrupador = [];
        const label = tipos.find(item => item.value == modelo.tipo).label;
        modelo.distribuidor.forEach(distribuidora => {

            const existente = agrupador.find(elemento =>
                elemento.inicio == distribuidora.fechainicio
                && elemento.fin == distribuidora.fechafin
            );

            const vendedores = distribuidora.idvendedor?.split(',').map(vendedor => {
                return `${distribuidora.iddistribuidora}-${vendedor}`;
            });
            if (!existente) {
                agrupador.push(mapearBonificacionPorAprobarDesdeServicio(modelo, distribuidora, vendedores, label));
            } else {

                existente.distribuidoras.push(distribuidora.iddistribuidora);
                existente.relaciones_ids.push(distribuidora.idbonificacionbonhistoricodistribuidora);
                if (existente.vendedores) {
                    existente.vendedores.push(...vendedores);
                } else {
                    existente.vendedores = vendedores;
                }
                existente.total_clientes += distribuidora.totalcliente
            }
        })
        cards.push(...agrupador);
    })

    return cards;

};

const mapearBonificacionesDesdeServicio = (modelos) => {
    const cards = [];
    modelos.forEach(modelo => {
        const agrupador = [];
        const label = tipos.find(item => item.value == modelo.tipo).label;
        modelo.distribuidor.forEach(distribuidora => {
            const existente = agrupador.find(elemento => elemento.id == modelo.idbonificacionbon);
            const vendedores = distribuidora.idvendedor == null ? [] : distribuidora.idvendedor?.split(',').map(vendedor => {
                return `${distribuidora.iddistribuidora}-${vendedor}`;
            });
            if (!existente) {
                agrupador.push(mapearBonificacionDesdeServicio(modelo, distribuidora, vendedores, label));
            } else {
                existente.distribuidoras.push({
                    id: distribuidora.iddistribuidora,
                    inicio: distribuidora.fechainicio,
                    fin: distribuidora.fechafin,
                    totalclientes: distribuidora.totalcliente
                });
                if (existente.vendedores) {
                    existente.vendedores.push(...vendedores);
                } else {
                    existente.vendedores = vendedores;
                }
                existente.relaciones_ids.push(distribuidora.idbonificacionbonhistoricodistribuidora);
                existente.total_clientes += distribuidora.totalcliente
            }
        })
        cards.push(...agrupador);
    })
    return cards;
}

const mapearBonificacionPorAprobarDesdeServicio = (modelo, distribuidora, vendedores, label) => {
    return {
        excel_path: distribuidora.documentpath,
        excel_name: distribuidora.documentpathoriginal,
        distribuidoras: [distribuidora.iddistribuidora],
        relaciones_ids: [distribuidora.idbonificacionbonhistoricodistribuidora],
        inicio: distribuidora.fechainicio,
        fin: distribuidora.fechafin,
        id: modelo.idbonificacionbon,
        codigo: modelo.codigo,
        nombre: modelo.nombre,
        descripcion: modelo.nombre,
        tipo: modelo.tipo,
        costo_producto_total: distribuidora.costo_producto_total,
        tipo_label: label,
        giros_negocio: distribuidora.giro?.split(',').map(item => `${item}`),
        segmentos: distribuidora.segmento?.split(',').map(item => `${item}`),
        tipos_cliente: distribuidora.tipocliente?.split(',').map(item => `${item}`),
        tipos_vendedor: distribuidora.tipovendedor?.split(','),
        vendedores: vendedores,
        prods_promo: modelo.grupoProducto.map(grupo_producto => mapearProductosDesdeServicio(grupo_producto)),
        prods_regalo: modelo.regalo.map(grupo_regalo => mapearRegalosDesdeServicio(grupo_regalo)),
        total_clientes: distribuidora.totalcliente ?? 0,
        usuario: distribuidora.usuario,
        idusuario: distribuidora.idusuario
    }
}
const mapearBonificacionDesdeServicio = (modelo, distribuidora, vendedores, label) => {
    return {
        excel_path: distribuidora.documentpath,
        excel_name: distribuidora.documentpathoriginal,
        distribuidoras: [{
            id: distribuidora.iddistribuidora,
            inicio: distribuidora.fechainicio,
            fin: distribuidora.fechafin,
        }],
        relaciones_ids: [distribuidora.idbonificacionbonhistoricodistribuidora],
        total_clientes: distribuidora.totalcliente ?? 0,
        inicio: distribuidora.fechainicio,
        fin: distribuidora.fechafin,
        id: modelo.idbonificacionbon,
        codigo: modelo.codigo,
        nombre: modelo.nombre,
        descripcion: modelo.nombre,
        tipo: modelo.tipo,
        costo_producto_total: distribuidora.costo_producto_total,
        tipo_label: label,
        giros_negocio: distribuidora.giro?.split(',').map(item => `${item}`),
        segmentos: distribuidora.segmento?.split(',').map(item => `${item}`),
        tipos_cliente: distribuidora.tipocliente?.split(',').map(item => `${item}`),
        tipos_vendedor: distribuidora.tipovendedor?.split(','),
        vendedores: vendedores,
        prods_promo: modelo.grupoProducto.map(grupo_producto => mapearProductosDesdeServicio(grupo_producto)),
        prods_regalo: modelo.regalo.map(grupo_regalo => mapearRegalosDesdeServicio(grupo_regalo))
    }
}

const mapearProductosDesdeServicio = (grupo_producto) => {
    return {
        unidades: grupo_producto.cantidad,
        productos: grupo_producto.productos.map(producto => producto.codigoproducto),
        productos_nombres: grupo_producto.productos.map(producto => `${producto.codigoproducto} - ${producto.nombreproducto}`),
        tipo: grupo_producto.tipo,
        categorias: grupo_producto.idcategoria?.split(','),
        familias: grupo_producto.idfamilia?.split(','),
        presentaciones: grupo_producto.idpresentacion?.split(','),
        proposito: grupo_producto.proposito,
        detalles: grupo_producto.escala
    };
};

const mapearRegalosDesdeServicio = (grupo_regalo) => {
    return {
        productos: grupo_regalo.regaloProductos?.map(producto => producto.codigoproducto),
        productos_nombres: grupo_regalo.regaloProductos?.map(producto => `${producto.codigoproducto} - ${producto.nombreproducto}`),
        unidades: grupo_regalo.cantidad,
        tipo: grupo_regalo.tipo,
        valor: grupo_regalo.valor,
    }
};


export default {
    namespaced: true,
    state: {
        bonificaciones: [],
        bonificaciones_por_aprobar: [],
        bonificaciones_activas: [],
        bonificaciones_inactivas: [],
    },
    getters: {},
    mutations: {
        limpiar(state) {
            state.bonificaciones = []
        },
        remover(state, index) {
            state.bonificaciones.splice(index, 1);
        },
        removerDePorAprobar(state, relaciones) {
            state.bonificaciones_por_aprobar = state.bonificaciones_por_aprobar.filter(item => item.relaciones_ids != relaciones);
        },
    },
    methods: {},
    actions: {

        async obtenerBonificacionesPorAprobar({ state, commit }, request = {}) {
            request.tlista = 'poraprobar';
            const data = await BonificacionService.getList(axios, request);
            state.bonificaciones_por_aprobar = mapearBonificacionesPorAprobarDesdeServicio(data.result);
        },
        async obtenerBonificacionesActivas({ state, commit }, request = {}) {
            request.tlista = 'activas';
            const data = await BonificacionService.getList(axios, request);
            state.bonificaciones_activas = mapearBonificacionesDesdeServicio(data.result);
        },
        async obtenerBonificacionesInactivas({ state, commit }, request = {}) {
            request.tlista = 'inactivas';
            const data = await BonificacionService.getList(axios, request);
            state.bonificaciones_inactivas = mapearBonificacionesDesdeServicio(data.result);
        },
        async create({ state, commit }) {
            const payload = mapearBonificaciones(state.bonificaciones);
            return await BonificacionService.crearBonificacion(axios, payload);
        },
        async edit({ state, commit }, bonificaciones) {
          const payload = mapearBonificaciones(bonificaciones);
            return await BonificacionService.editarBonificacion(axios, payload);
        },
        async subiArchivo({ state }, payload) {
            return await BonificacionService.subiArchivo(axios, payload);
        },
        async validarClientes({ state }, payload) {
            return await BonificacionService.validarClientes(axios, payload);
        },
        async cambiarEstado({ state }, payload) {
            return await BonificacionService.cambiarEstado(axios, payload);
        },
        async cambiarfecha({ state }, payload) {
            return await BonificacionService.cambiarfecha(axios, payload);
        }


    }
}
