import { mapActions, mapGetters } from 'vuex';
import SupervisorService from '../services/SupervisorService';
import axios from 'axios';
export default { 
    namespaced: true,
    state:{},
    getters:{},
    mutations:{},
    methods:{ },
    actions:{
        async getListById({state,commit},data) {
            return await SupervisorService.getListById(axios,data);
        },
    }
}