<template>
  <div class="col-4 text-center">
    <span class="text-muted font-11">Descuento {{ simbolo }}</span>
    <div class="row mx-0 px-3">
      <b class="m-auto py-2 font-11 add-py0-export">
        {{ parseFloat(grupo.valor).toFixed(2) }}
      </b>
    </div>
  </div>
  <div class="col-4 text-center" v-if="grupo.tipo == constantes.TIPO_REGALO_SOLES">
    <span class="text-muted font-11">Precio Normal {{ simbolo }}</span>
    <div class="row mx-0 px-3">
      <b class="m-auto py-2 font-11 add-py0-export">
        {{ parseFloat(costo).toFixed(2) }}
      </b>
    </div>
  </div>
  <div class="col-4 text-center" v-if="grupo.tipo == constantes.TIPO_REGALO_SOLES">
    <span class="text-muted font-11">Precio {{tipo}} {{ simbolo }}</span>
    <div class="row mx-0 px-3">
      <b class="m-auto py-2 font-11 add-py0-export">
        {{ parseFloat(costo - parseFloat(grupo.valor)).toFixed(2) }}
      </b>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";
import constantes from "../../../model/constantes";
const { grupo, tipo } = defineProps({
  grupo: {
    type: Object,
  },
  tipo: {
    type: String,
  },
  costo: {
    costo: Number,
  },
});

const simbolo = computed(() => {
  return grupo.tipo == constantes.TIPO_REGALO_SOLES ? "S/." : "%"
});

const precio_normal = computed(() => {
  return grupo.tipo == constantes.TIPO_REGALO_PORCENTAJE ? "100" : "-"
});

const precio_final = computed(() => {
  return grupo.tipo == constantes.TIPO_REGALO_PORCENTAJE ? 100-grupo.valor : "-"
});

</script>
