export const TRACKING_STEPS = {
  REGISTRO_INCIDENCIA: "1",
  DERIVO_EQUIPO_MANTENIMIENTO: "2",
  ATENCION_INCIDENCIA: "3",
  CIERRE_INCIDENCIA: "4",
};

export const TRACKING_STATUS = {
  PENDIENTE: "1",
  DERIVO_ST: "2",
  VISTA_PROGRAMADA: "3",
  EQUIPO_EN_TALLER: "4",
  OPERATIVO: "5",
  NO_OPERATIVO: "6",
};

export const REQUEST = {
  RECOJO: "01",
  REPORTAR: "02",
  NUEVO_EQUIPO: "03",
};

export const REQUEST_TYPE = {
  DEFAULT: "-",
  CAMBIO_QR: "01",
  EQUIPO_PERDIDO: "02",
  EQUIPO_ENCONTRADO: "03",
};

export const REQUEST_STATUS = {
  PENDIENTE: "0",
  ATENDIDO: "1",
  RECHAZADO: "3",
};

export const APPROVE_TRADE_STATUS = {
  PENDING: 0,
  YES: 1,
  NO: 2,
};

export const MODAL_REQUEST_EQUIPMENT_REQUEST_TYPE = {
  EQUIPMENT_FOUND: "0",
  NEW_COLD_EQUIPMENT: "1",
  EQUIPMENT_CHANGE_QR: "2",
  EQUIPMENT_NOTFOUND: "3",
  EQUIPMENT_PICKUP: "4",
};

export const TYPE_STATUS_DROPDOWN = [
  { text: "Pendiente", value: REQUEST_STATUS.PENDIENTE },
  { text: "Atendido", value: REQUEST_STATUS.ATENDIDO },
  { text: "Rechazado", value: REQUEST_STATUS.RECHAZADO },
];

export const getStatusText = (requestStatusCode = REQUEST_STATUS.PENDIENTE) => {
  const status = TYPE_STATUS_DROPDOWN.find(
    (t) => t.value === String(requestStatusCode)
  );
  if (status) return status.text;

  return TYPE_STATUS_DROPDOWN.find((t) => t.value === REQUEST_STATUS.PENDIENTE)
    .text;
};

export const getApproveTradeText = (status) => {
  if (status === APPROVE_TRADE_STATUS.YES) return "Sí";
  if (status === APPROVE_TRADE_STATUS.NO) return "No";

  return "-";
};
