import { createStore } from "vuex";
import axios from "axios";
import auth from "./auth";
import distribuidora from "./distribuidora";
import supervisor from "./supervisor";
import vendedor from "./vendedor";
import user from "./user";
import parameter from "./parameter";
import notification from "./notification";
import mesa from "./mesa";
import encuesta from "./encuesta";
import maestros from "./maestros";
import bonificacion from "./bonificacion";
import bonificacionUtil from "./bonificacionUtil";
import usuarioTrade from "./usuarioTrade";
import liquidacion from "./liquidacion";

const axiosP = (store) => {
  store.$axios = axios;
};

export const store = createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    distribuidora,
    supervisor,
    vendedor,
    user,
    mesa,
    parameter,
    notification,
    encuesta,
    maestros,
    bonificacion,
    bonificacionUtil,
    usuarioTrade,
    liquidacion
  },
  plugins: [axiosP],
});
