<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive validateClient">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
          >
          <template v-slot:header>
            <div class="row">
              <div class="col-12 col-md-6">
                <h3 class="mb-0">Promociones</h3>
              </div>
              <div class="col-12 col-md-6 text-right">
                <button type="button" @click="exportPromociones" class="btn base-button btn-primary btn-sm mr-2" role="button"><i class="fas fa-file-export"></i>Exportar </button>
                <router-link to="promociones/crear"><base-button size="sm" type="info">Crear de Promoción</base-button></router-link>
              </div>
            </div>
          </template>
          <div>
            <div class="row px-3">
              <div class="col-12 mb-3">
                <div class="row">
                  <div class="col-12 col-md-4">
                    <el-input
                      type="search"
                      clearable
                      prefix-icon="el-icon-search"
                      placeholder="Buscar producto"
                      v-model="searchQuery"
                      aria-controls="datatables"
                    >
                    </el-input>
                  </div>
                  <div class="col-12 col-md-4 d-flex align-items-center">
                    <template v-if="distribuidoraIdCurrent">
                      <span class="title_filter mr-2">Distribuidora:  <strong>{{distribuidoraNameCurrent}}</strong></span>
                    </template>
                    <template v-else>
                      <span class="title_filter mr-2">Distribuidoras:  </span>
                      <select class="form-control" style="display: inline-block;height: 43px;" name="distributor_select" @change="changeDistributor">
                        <option value="0">Todos</option>
                        <option
                          v-for="item in distribuidoras"
                          :value="item.idDistribuidora"
                          :key="item.idDistribuidora"
                          >
                          {{ item.razonSocial }}
                        </option>
                      </select>
                    </template>
                  </div>
                  <div class="col-12 col-md-4 d-flex align-items-center">
                    <span class="title_filter mr-2" style="width:200px;">Grupos de Venta:  </span>
                    <select class="form-control" style="display: inline-block;height: 43px;" name="grupoventa_select" @change="changeGrupoVenta">
                      <option value="">Todos</option>
                      <option
                        v-for="item in gruposventa"
                        :value="item.codigoDato"
                        :key="item.idRegistro"
                        >
                        {{ item.nombre }}
                      </option>
                    </select>
                  </div>
                  <div class="col-12 col-md-3 align-items-center d-none">
                    <span class="title_filter mr-2">Categorias:  </span>
                    <select class="form-control" style="display: inline-block;height: 43px;" name="category_select" @change="changeCategory">
                      <option value="" :selected="searchQuery == ''">Todos</option>
                      <option
                        v-for="item in categories"
                        :value="item.nombre"
                        :key="item.codigo"
                        >
                        {{ item.nombre }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <!-- Real Table -->
            <el-table
              :data="queriedData"
              row-key="idProducto"
              empty-text="No se encontraron resultados"
              header-row-class-name="thead-light"
            >
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                v-bind="column"
              >
              </el-table-column>
              <el-table-column min-width="250px" align="right">
                <template v-slot:default="props">
                    <base-button type="info" class="detail" size="sm" role="button" @click="openDetail(props.$index, props.row)" aria-pressed="true">Detalle</base-button>
                    <router-link :to="`./promociones/editar/${props.row.idProductoPromocion}`" class="btn base-button btn-success btn-sm edit">Editar</router-link>
                    <base-button type="info" class="delete" size="sm" role="button" @click="deletePromotion(props.row.idProductoPromocion)" aria-pressed="true">Eliminar</base-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <template v-slot:footer>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category d-none">
                  Mostrando {{ from + 1 }} a {{ to }} de {{ total }} productos
                </p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.newcurrentPage"
                :per-page="pagination.perPage"
                :total="total"
                @click="changePaginate"
              >
              </base-pagination>
            </div>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
let fixClickOpenModal = 1
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import swal from "sweetalert2";
import axios from "axios";
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BasePagination from "@/components/BasePagination";
import {mapGetters} from 'vuex';
import DashboardNavbar from "../Layout/DashboardNavbar";
export default {
  components: {
    DashboardNavbar,
    BasePagination,
    RouteBreadCrumb,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  computed: {
    pagedData() {
      this.getDistributor
      this.getGruposVenta
      this.getDataPromociones;
      if(this.tableData.length){
        this.searchedData = this.tableData
        return this.tableData
        // return this.tableData.slice(this.from, this.to);
      }
    },
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString().toLowerCase();
          if (rowValue.includes && rowValue.includes(this.searchQuery.toLowerCase())) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });
      console.log(result.length);
      this.searchedData = result
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    }),
    async getDataPromociones(){
      const $this = this;
      if(this.gruposventa.length && this.distribuidoras.length){
        const selectDistribuodra = ($this.distribuidoraIdCurrent) ? $this.distribuidoraIdCurrent : Number(document.querySelector("[name='distributor_select']").value)
        const selectGruposVenta = document.querySelector("[name='grupoventa_select']").value
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        const parameters = {
          "iddistribuidora": selectDistribuodra,
          "codigogrupoventa": selectGruposVenta,
          "page": 1
        }
        axios.post(process.env.VUE_APP_API_URL+"/promocion/lista", parameters,config)
        .then(function (response){
          document.querySelector(".form_laive").classList.remove("validateClient")
          $this.pagination.perPage = response.data.data.length
          $this.pagination.total = response.data.totalPage
          return $this.tableData = response.data.data
        })
      }
    },
    async getDistributor(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let urlApiDistribuidoras = process.env.VUE_APP_API_URL+"/distribuidoras/lista"
      const requestDistribuidoras = axios.get(urlApiDistribuidoras, config);
      await axios.all([requestDistribuidoras]).then(axios.spread((...responses) => {
        const responseDistribuidoras = responses[0].data
        if($this.distribuidoraIdCurrent){
          let dataDistibuitorCurrent =  responseDistribuidoras.filter((item)=>{return item.idDistribuidora == $this.distribuidoraIdCurrent})
          $this.distribuidoraNameCurrent = dataDistibuitorCurrent[0].razonSocial
        }
        $this.distribuidoras = responseDistribuidoras.filter((item)=>{return item.estado == "1"})
        return $this.distribuidoras
      })).catch(errors => {
        // react on errors.
      })
    },
    async getGruposVenta(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let urlApiGruposVentas = process.env.VUE_APP_API_URL+"/maestro/lista/grupoventa"
      const requestGruposVentas = axios.get(urlApiGruposVentas, config);
      await axios.all([requestGruposVentas]).then(axios.spread((...responses) => {
        const responseGruposVentas = responses[0].data
        $this.gruposventa = responseGruposVentas
        return $this.gruposventa
      })).catch(errors => {
        // react on errors.
      })
    }
  },
  methods: {
    openDetail(index, row) {
      const swalWithBootstrapButtons1 = swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          container: "swal-wide",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons1.fire({
        title: `Detalle de Promoción`,
        html: `
              <table class="table_laive">
                <tr>
                  <th>Grupo Objetivo:</th>
                  <td>${row.nombreGrupo}</td>
                </tr>
                <tr>
                  <th>Producto Principal:</th>
                  <td>${row.productoPrincipal}</td>
                </tr>
                <tr>
                  <th>Código Producto Principal:</th>
                  <td>${row.codigoProductoPrincipal}</td>
                </tr>
                <tr>
                  <th>Producto Asociado:</th>
                  <td>${row.productoAsociado}</td>
                </tr>
                <tr>
                  <th>Código Producto Asociado:</th>
                  <td>${row.codigoProductoAsociado}</td>
                </tr>
                <tr>
                  <th>Descripción:</th>
                  <td>${row.descripcion}</td>
                </tr>
                <tr>
                  <th>Distribuidora:</th>
                  <td>${row.distribuidora}</td>
                </tr>
                <tr>
                  <th>Cantidad Minima:</th>
                  <td>${row.cantidadMinima}</td>
                </tr>
                <tr>
                  <th>Cantidad Máxima:</th>
                  <td>${row.cantidadMaxima}</td>
                </tr>
                <tr>
                  <th>Tipo de promoción:</th>
                  <td>${row.obligatorio}</td>
                </tr>
                <tr>
                  <th>Fecha de Inicio:</th>
                  <td>${row.fechaInicio}</td>
                </tr>
                <tr>
                  <th>Fecha de Fin:</th>
                  <td>${row.fechaFin}</td>
                </tr>
              </table>`,
        confirmButtonText: "Cerrar"
      });
    },
    deletePromotion(id){
      const $this = this;
      const idProducto = id
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const parameters = {
        "idPromocion": idProducto,
      }
      document.querySelector(".form_laive").classList.add("validateClient")
      if(fixClickOpenModal == 1){
        ++fixClickOpenModal
        axios.post(process.env.VUE_APP_API_URL+"/promocion/eliminar", parameters,config)
        .then(function (response){
          fixClickOpenModal == 1
          document.querySelector(".form_laive").classList.remove("validateClient")
          const text = (response.status == 200) ? "Se eliminó la Promoción" : "Hubo un error";
          const icon = "ni ni-bell-55";
          const content = {
            component: Notification,
            props: {
              icon: icon,
              text: text,
              type: (response.status == 200) ? "success" : "danger",
            },
          };
          const toast = useToast();
          toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
          console.log(response);
          setTimeout(() => {
            location.reload();
          }, 2000);
        })
      }
    },
    changeDistributor(event) {
      const $this = this;
      const select = event.target
      const value = Number(select.value);
      const selectGruposVenta = document.querySelector("[name='grupoventa_select']").value
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const parameters = {
        "iddistribuidora": value,
        "grupoventa": selectGruposVenta,
        "codigogrupoventa": selectGruposVenta,
        "page": 1
      }
      document.querySelector(".form_laive").classList.add("validateClient")
      axios.post(process.env.VUE_APP_API_URL+"/promocion/lista", parameters,config)
      .then(function (response){
        document.querySelector(".form_laive").classList.remove("validateClient")
        $this.pagination.perPage = response.data.data.length
        $this.pagination.total = response.data.totalPage
        return $this.tableData = response.data.data
      })
    },
    changeGrupoVenta(event) {
      const $this = this;
      const select = event.target
      const value = select.value
      const selectDistribuodra = Number(document.querySelector("[name='distributor_select']").value)
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const parameters = {
        "iddistribuidora": selectDistribuodra,
        "codigogrupoventa": value,
        "page": 1
      }
      document.querySelector(".form_laive").classList.add("validateClient")
      axios.post(process.env.VUE_APP_API_URL+"/promocion/lista", parameters,config)
      .then(function (response){
        document.querySelector(".form_laive").classList.remove("validateClient")
        $this.pagination.newcurrentPage = 1
        $this.pagination.perPage = response.data.data.length
        $this.pagination.total = response.data.totalPage
        return $this.tableData = response.data.data
      })
    },
    changePaginate(evn){
      const $this = this
      const listPaginator = document.querySelectorAll("ul.pagination .page-item")
      const idDistribuidora = (this.distribuidoraIdCurrent) ? this.distribuidoraIdCurrent : Number(document.querySelector("select[name='distributor_select']").value)
      const selectGrupoVenta = document.querySelector("select[name='grupoventa_select']").value
      const selectCategoria = document.querySelector("select[name='category_select']").value
      let elm = evn.target;
      let sendAjax = false;
      let parentEl = elm.parentNode
      if(elm.classList.contains("prev-page")){
        if(!elm.classList.contains("disabled")){
          let linkActivePrev = document.querySelectorAll("ul.pagination .page-item.active")
          linkActivePrev[0].classList.remove("active")
          linkActivePrev[0].previousSibling.classList.add("active")
          sendAjax = true
        }
      }else if(elm.classList.contains("next-page")){
      }else{
        if(elm.getAttribute("aria-label") == "Previous"){
          let linkActivePrev = document.querySelectorAll("ul.pagination .page-item.active")
          linkActivePrev[0].classList.remove("active")
          linkActivePrev[0].previousSibling.classList.add("active")
          sendAjax = true
        }else if(elm.getAttribute("aria-label") == "Next"){
          let linkActiveNext = document.querySelectorAll("ul.pagination .page-item.active")
            linkActiveNext[0].classList.remove("active")
            linkActiveNext[0].nextSibling.classList.add("active")
            sendAjax = true
        }else{
          if(!parentEl.classList.contains("active")){
            listPaginator.forEach(item => {
              item.classList.remove("active")
            });
            parentEl.classList.add("active")
            sendAjax = true
          }
        }
      }
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const parameters = {
          "page": this.pagination.newcurrentPage,
          "idDistribuidora": idDistribuidora,
          "codigogrupoventa": selectGrupoVenta,
      }
      if(sendAjax){
        document.querySelector(".form_laive").classList.add("validateClient")
        axios.post(process.env.VUE_APP_API_URL+"/promocion/lista",parameters,config)
        .then(function (response){
          document.querySelector(".form_laive").classList.remove("validateClient")
          console.log(response.data.data)
          sendAjax = false
          $this.pagination.currentPage = 1
          return $this.tableData = response.data.data
        })
      }
    },
    exportPromociones(){
      const selectDistribuidra = (this.distribuidoraIdCurrent) ? this.distribuidoraIdCurrent : Number(document.querySelector("select[name='distributor_select']").value)
      const selectGrupoVenta = document.querySelector("select[name='grupoventa_select']").value
      const token = localStorage.getItem("token")
      const urlDomain = process.env.VUE_APP_API_URL.replace("api", "")
      const configExport = {
        // responseType: 'blob',
        headers: {
          "Authorization": `Bearer ${token}`,
        }
      };
      const parameters = {
        "idDistribuidora": selectDistribuidra,
        "codigogrupoventa": selectGrupoVenta,
      }
      document.querySelector(".form_laive").classList.add("validateClient")
      axios.post(process.env.VUE_APP_API_URL+`/promocion/exportar`,parameters,configExport)
      .then(function (response){
        document.querySelector(".form_laive").classList.remove("validateClient")
        const urlFile = urlDomain + response.data.fileName
        // const url = window.URL.createObjectURL(new Blob([response.data], {type:'application/vnd.ms-excel'}));
        const link = document.createElement('a');
        link.href = urlFile;
        // link.setAttribute('download',`productos-promocion-${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
    }
  },
  mounted() {
    if(this.user.rol == 3){
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
    if(this.user.rol == 4){
      this.supervisorIdCurrent = this.user.codigo
      this.supervisorNameCurrent = this.user.nombre
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
  },
  data() {
    return {
      distribuidoraIdCurrent: null,
      distribuidoraNameCurrent: null,
      supervisorIdCurrent: null,
      supervisorNameCurrent: null,
      pagination: {
        perPage: 25,
        currentPage: 1,
        newcurrentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      distribuidoras:[],
      gruposventa:[],
      categories:[],
      searchQuery: "",
      propsToSearch: ["nombre", "codigoPromocion"],
      tableColumns: [
        {
          prop: "nombre",
          label: "Nombre",
          minWidth: 200,
        },
        {
          prop: "codigoPromocion",
          label: "Código",
          minWidth: 125,
        },
        {
          prop: "obligatorio",
          label: "Tipo",
          minWidth: 150,
        },
        {
          prop: "fechaInicio",
          label: "Inicio",
          minWidth: 140,
        },
        {
          prop: "fechaFin",
          label: "Fin",
          minWidth: 140,
        },
      ],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      expands: [],
    };
  },
};
</script>
<style>
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
.no-border-card .card-footer {
  border-top: 0;
}
/* .card-body{
  padding-top: 0 !important;
} */
.card-body .title_filter{
  font-size: 14px;
  font-weight:500;
}
.swal2-container .swal2-header{
  padding: 0;
}
.swal2-container .swal2-content{
  padding: 0;
}
.swal2-container .swal2-popup #swal2-title{
  font-size: 1.2rem;
}
.base-button i{
  position: relative;
  top: 1px;
}
</style>
