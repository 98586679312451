import axios from "axios";

export async function downloadFile(srcFile, fileName) {
	if (!srcFile) return;
	const res = await axios.get(srcFile, {
		responseType: "blob",
	});
	const href = URL.createObjectURL(res.data);
	const link = document.createElement("a");
	link.href = href;
	if (fileName) {
		link.download = fileName;
	} else {
		link.download = true;
	}
	document.body.appendChild(link);
	link.click();

	// limpiar
	document.body.removeChild(link);
	URL.revokeObjectURL(href);
}
