import { request } from "../util/request";

const getParameterByKey = async (keyName) => {
  const { data } = await request.get(`parametro/lista/${keyName}`);
  return data;
};

const updateParemeter = async (body) => {
  const { data } = await request.put(`parametro/actualizar`, body);
  return data;
};

export const serviceParametro = {
  getParameterByKey,
  updateParemeter,
};
