
import setting from './setting';

const API_URL = setting.http.base_url;
const GP_API_URL = setting.http.gp_url;
const urls = {
    distribuidoras: `${API_URL}/DistribuidorBon/GetDistribuidora`,
    productos: `${API_URL}/ProductoBon/GetProductos`,
    vendedores: `${API_URL}/VendedorBon/GetVendedor`,
    tiposCliente: `${API_URL}/TablaGeneralBon/GetTipoCliente`,
    segmentos: `${API_URL}/TablaGeneralBon/GetSegmento`,
    girosNegocio: `${API_URL}/TablaGeneralBon/GetGiroNegocio`,
    tiposVendedor: `${API_URL}/TablaGeneralBon/GetTipoVendedor`,
    mesas: `${API_URL}/BonificacionBon/ListarMesas`,

    categorias: `${API_URL}/TablaGeneralBon/GetCategoria`,
    familias: `${API_URL}/TablaGeneralBon/GetFamilia`,
    presentaciones: `${API_URL}/TablaGeneralBon/GetPresentacion`,
};


export default {
    cargarLista: async ($axios, lista, parent_id = null) => {
        try {
            let data = [];
            if (urls[lista]) {
                data = (await $axios.get(`${urls[lista]}`, {headers:setting.http.headers()})).data;
            }
            return data;
        } catch (err) {
            console.log(err);
        }
    },
    contarCientes: async ($axios, payload) => {
        try {
            let data = null;
            data = (await $axios.post(`${API_URL}/BonificacionBon/GetClientesCount`, payload, {headers:setting.http.headers()})).data;            
            return data;
        } catch (err) {
            console.log(err);
        }
    },
    obtenerCostos: async ($axios, productoId) => {
        try {

            const {data} = (await $axios.get(`${GP_API_URL}/api/Product/productPriceList`, {
				params: {
					productCode: productoId,
				},
			}));
            
            return data;
        } catch (err) {
            console.log(err);
        }
    },
}
