<template>
  <header
    aria-label="breadcrumb"
    class="justify-content-between align-items-center d-none d-md-flex"
  >
    <route-bread-crumb></route-bread-crumb>
    <dashboard-navbar></dashboard-navbar>
  </header>
  <box-loader
    containerClass="main-content-laive container-fluid"
    :loading="loading"
    style="height: 80vh"
  >
    <section v-if="data !== null" class="p-3 pt-4">
      <h2 class="text-dark">Incidencias Reportadas</h2>

      <div class="data-group">
        <div class="data-group-item">
          <h4>Datos Generales</h4>
          <div class="data-group-columns">
            <div>
              <h6>Placa</h6>
              <p>{{ data.placa }}</p>
            </div>
            <div>
              <h6>Modelo</h6>
              <p>{{ data.modelo }}</p>
            </div>
            <div>
              <h6>Lote</h6>
              <p>{{ data.lote }}</p>
            </div>
            <div>
              <h6>Fecha de actualización</h6>
              <p>{{ data.fechaActualizacion }}</p>
            </div>
            <div>
              <h6>Estado</h6>
              <p>{{ data.estado }}</p>
            </div>
            <div>
              <h6>Condición</h6>
              <p>{{ data.condicion }}</p>
            </div>
            <div>
              <h6>DEX</h6>
              <p>{{ data.distribuidora }}</p>
            </div>
          </div>
        </div>
        <div class="divider"></div>
        <div class="data-group-item">
          <h4>Datos del Cliente</h4>
          <div class="data-group-columns">
            <div>
              <h6>Fecha de asignación</h6>
              <p>{{ data.fechaAsignacion }}</p>
            </div>
            <div>
              <h6>Asignado</h6>
              <p>{{ data.clienteAsignado }}</p>
            </div>
            <div>
              <h6>Nombre</h6>
              <p>{{ data.clienteNombre }}</p>
            </div>
            <div>
              <h6>Segmento</h6>
              <p>{{ data.clienteSegmento }}</p>
            </div>
            <div>
              <h6>Dirección</h6>
              <p>{{ data.clienteDireccion }}</p>
            </div>
            <div>
              <h6>Ubicación</h6>
              <a :href="data.ubication" target="_blank" class="text-truncate">
                <i class="fa fa-map-marker-alt" />
                Ir a Google Maps
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="incidencias-accordion" v-if="data.incidencias.length > 0">
        <Accordion
          v-for="(incidencia, i) in data.incidencias"
          v-model:isOpen="incidencia.show"
          @update:isOpen="handleOpenInc($event, i)"
        >
          <template v-slot:title>
            {{ incidencia.title }}
          </template>
          <template v-slot:content>
            <box-loader :loading="incidencia.loading" minHeight="150px">
              <div class="mt-4" v-if="incidencia.data">
                <h3>{{ incidencia.data.header }}</h3>

                <TrackingIndicendia
                  :data="incidencia.data"
                  :disable-edit="true"
                />
              </div>
            </box-loader>
          </template>
        </Accordion>
      </div>
      <h2 v-else class="text-dark mt-5">No hay incidencias para mostrar</h2>
    </section>
    <section v-else class="p-3 pt-5">
      <h2 v-if="!loading" class="text-dark">
        Este equipo no tiene asignado un cliente
      </h2>
    </section>
  </box-loader>
</template>

<script>
import "@/assets/sass/laive/equipos/incidencia/incidence-reported.scss";

import { useToast } from "vue-toastification";

import ListCard from "@/components/Shared/ListCard";
import Accordion from "@/components/Shared/Accordion";
import RouteBreadCrumb from "../../../components/Breadcrumb/RouteBreadcrumb.vue";
import DashboardNavbar from "../../Layout/DashboardNavbar.vue";
import TrackingIndicendia from "../Incidencias/TrackingIndicendia.vue";

import { getCapitalizeWords } from "../../../util";
import { parseNormalDate } from "../../../util";

import { Incidencias, Equipos } from "../../../services";

export default {
  components: {
    RouteBreadCrumb,
    DashboardNavbar,
    ListCard,
    Accordion,
    TrackingIndicendia,
  },
  data() {
    return {
      data: null,
      loading: false,
    };
  },
  async mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const token = this.$store.state.auth.token;
        const equipoId = this.$route.query["equipo"];

        if (!equipoId || !token) return;

        this.loading = true;

        const details = await Equipos.getDetails(token, equipoId);
        const incidencias = await Incidencias.listByEquipo(token, equipoId);

        if (details.clienteAsignado === null) {
          this.data = null;
          return;
        }

        const incidenciasInfo = incidencias.data.map((inc, i) => ({
          id: inc.idIncidencia,
          title: `Incidencia Reportada ${i + 1} - ${String(
            inc.idIncidencia
          ).padStart(6, "0")}`,
          data: null,
        }));

        const ubication = `https://www.google.com/maps/search/?api=1&query=${details.clienteLatitud}%2C${details.clienteLongitud}`;
        const fechaActualizacion = new Date(
          details.fechaActualizacion
        ).toLocaleDateString(window.navigator.language, {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
        const fechaAsignacion = parseNormalDate(
          details.fechaAsignacion.split(" ")[0]
        );

        const [estado] = getCapitalizeWords(details.estado);
        const [condicion] = getCapitalizeWords(details.condicion);

        this.data = {
          ...details,
          incidencias: incidenciasInfo,
          ubication,
          fechaActualizacion,
          fechaAsignacion,
          estado,
          condicion,
        };
      } catch (error) {
        this.toast("No se pudo obtener las incidencias.", "error");
      } finally {
        this.loading = false;
      }
    },
    toast(title, type = "success") {
      const toast = useToast();
      if (type == "success") {
        toast.success(title, {
          position: "top-right",
          closeOnClick: true,
          hideProgressBar: true,
          timeout: 6000,
          toastClassName: "th-alert th-alert-pry",
          showCloseButtonOnHover: true,
        });
      } else if (type == "error") {
        toast.error(title, {
          position: "top-right",
          closeOnClick: true,
          hideProgressBar: true,
          timeout: 6000,
          toastClassName: "th-alert th-alert-error",
          showCloseButtonOnHover: true,
        });
      }
    },
    async handleOpenInc(show, i) {
      const inc = this.data.incidencias.at(i);

      try {
        if (!show) return;
        if (!inc || inc.data != null) return;

        const token = this.$store.state.auth.token;

        inc.loading = true;

        const detail = await Incidencias.getDetail(token, inc.id);
        const codigoIncidencia = String(detail.idIncidencia).padStart(6, "0");
        inc.data = { ...detail, codigoIncidencia };
      } catch (error) {
        this.toast("No se pudo obtener el detalle.", "error");
      } finally {
        inc.loading = false;
      }
    },
  },
};
</script>
<style scoped>
header {
  padding-inline: 1em;
  padding-block: 1.2em;
}

header :deep(nav:last-child) {
  padding: 0 !important;
}

h3 {
  color: black;
  font-size: 0.96rem !important;
}

h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1.3em;
}

h4 {
  font-size: 1rem;
  color: black;
}

header + section {
  background-color: #f8f9fe;
}

.incidencias-accordion {
  margin-top: 3em;
}
</style>
