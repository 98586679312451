<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive validateClient">
        <div class="box_loader">
        </div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template v-slot:header>
            <div class="row">
              <div class="col-12 col-md-6">
                <h3 class="mb-0">Reporte de Efectividad</h3>
              </div>
              <div class="col-12 col-md-6 text-right">
                <button type="button" class="btn base-button btn-primary btn-sm" role="button" @click="exportReport"><i class="fas fa-file-export"></i>Exportar</button>
              </div>
            </div>
          </template>
          <div>
            <div class="row px-3">
              <div class="col-12 col-md-10 mb-3">
                <div class="row">
                  <div class="col-12 col-md-3 ">
                    <template v-if="distribuidoraIdCurrent">
                      <div class="title_filter mr-2"><div>Distribuidora:</div>  <strong>{{distribuidoraNameCurrent}}</strong></div>
                    </template>
                    <template v-else>
                      <span class="title_filter mr-2">Distribuidoras:  </span>
                      <select class="form-control" style="display: inline-block;height: 43px;" name="distributor_select" @change="changeListbyDistribuidora">
                        <option value="0">Todos</option>
                        <option
                          v-for="item in distribuidoras"
                          :value="item.idDistribuidora"
                          :key="item.idDistribuidora"
                          >
                          {{ item.razonSocial }}
                        </option>
                      </select>
                    </template>
                  </div>
                  <div class="col-12 col-md-3 d-none">
                    <span class="title_filter mr-2">Mesa:  </span>
                    <select class="form-control" style="display: inline-block;height: 43px;" name="mesa_select">
                      <option value="0">Todos</option>
                      <option
                          v-for="item in mesas"
                          :value="item.codigoDato"
                          :key="item.codigoDato"
                          >
                          {{ item.nombre }}
                        </option>
                    </select>
                  </div>
                  <div class="col-12 col-md-3 ">
                    <span class="title_filter mr-2">Mes:  </span>
                    <select class="form-control" style="display: inline-block;height: 43px;" name="order_select" @change="changeMonth">
                      <option value="" disabled style="display:none" selected>Seleccionar</option>
                      <option
                          v-for="(item, index) in meses"
                          :value="index+1"
                          :key="item"
                          :selected="index+1 == dataReport.month"
                          >
                          {{ item }}
                        </option>
                    </select>
                  </div>
                  <div class="col-12 col-md-3 ">
                    <span class="title_filter mr-2">Ordenar por:  </span>
                    <select class="form-control" style="display: inline-block;height: 43px;" name="order_select" @change="changeOrder">
                      <option value="" disabled style="display:none" selected>Seleccionar</option>
                      <option value="1">Ordenar de mayor a menor</option>
                      <option value="2">Ordenar de menor a mayor</option>
                    </select>
                  </div>

                </div>
              </div>

            </div>
            <!-- Real Table -->
            <div class="el-table table_report pesos notoverflow">
              <table width="100%" border="0">
                <thead class="el-table__header-wrapper">
                  <th v-for="(item) in tableColumns" :key="item.prop">
                    {{ item.label }}
                  </th>
                  <th></th>
                </thead>
                <tbody>
                  <template v-if="queriedData">
                    <tr v-for="value in queriedData" :key="value.monto">
                      <td v-if="value.nombreVendedor" >{{value.nombreVendedor}}</td><td v-else>---</td>
                      <td v-if="value.real">{{formatNumber(value.real)}}</td><td v-else>---</td>
                      <td v-if="value.programado">{{formatNumber(value.programado)}}</td><td v-else>---</td>
                      <td v-if="value.avance">{{value.avance}}%</td><td v-else>---</td>
                      <td v-if="value.objetivo">{{value.objetivo}}%</td><td v-else>---</td>
                      <td v-if="value.diferencia">{{value.diferencia}}</td><td v-else>---</td>
                      <td v-if="value.cumplimiento">{{value.cumplimiento}}%</td><td v-else>---</td>
                      <td v-if="value.semaforo">
                        <template v-if="value.semaforo == 'verde'">
                          <div class="circle_state green"></div>
                        </template>
                        <template v-else-if="value.semaforo == 'amarillo'">
                          <div class="circle_state yellow"></div>
                        </template>
                        <template v-else-if="value.semaforo == 'rojo'">
                          <div class="circle_state red"></div>
                        </template>
                      </td>
                      <td v-if="value.fecha">{{value.fecha}}</td><td v-else>---</td>
                    </tr>
                  </template>
                  <tr v-else><td>No se encontraron Resultados</td></tr>
                </tbody>
              </table>
            </div>
          </div>
          <template v-slot:footer>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category d-none">
                  Mostrando {{ from + 1 }} a {{ to }} de {{ total }} Depositos
                </p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="dataReport.page"
                :per-page="pagination.perPage"
                :total="total"
                @click="changePaginate"
              >
              </base-pagination>
            </div>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import axios from "axios";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import TabPane from "@/components/Tabs/TabPane";
import BasePagination from "@/components/BasePagination";
import {mapGetters} from 'vuex';
import swal from "sweetalert2";
import DashboardNavbar from "../../Layout/DashboardNavbar";
function mayorToMenor( a, b ) {
  if (a.cumplimiento < b.cumplimiento) {
    return 1;
  }
  if (a.cumplimiento > b.cumplimiento) {
    return -1;
  }
  // a must be equal to b
  return 0;
}
function menorToMayor( a, b ) {
  if (a.cumplimiento > b.cumplimiento) {
    return 1;
  }
  if (a.cumplimiento < b.cumplimiento) {
    return -1;
  }
  // a must be equal to b
  return 0;
}
export default {
  components: {
    DashboardNavbar,
    BasePagination,
    RouteBreadCrumb,
    TabPane,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  computed: {
    pagedData() {
      this.getDistributor
      this.getMesas
      this.getDataVisitasbyVendedor
      return this.tableData
    },
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString().toLowerCase();
          if (rowValue.includes && rowValue.includes(this.searchQuery.toLowerCase())) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.pagination.total;
    },
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    }),
    async getDistributor(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let urlApiDistribuidoras = process.env.VUE_APP_API_URL+"/distribuidoras/lista"
      const requestDistribuidoras = axios.get(urlApiDistribuidoras, config);
      await axios.all([requestDistribuidoras]).then(axios.spread((...responses) => {
        const responseDistribuidoras = responses[0].data
        if($this.distribuidoraIdCurrent){
          let dataDistibuitorCurrent =  responseDistribuidoras.filter((item)=>{return item.idDistribuidora == $this.distribuidoraIdCurrent})
          $this.distribuidoraNameCurrent = dataDistibuitorCurrent[0].razonSocial
        }
        $this.distribuidoras = responseDistribuidoras.filter((item)=>{return item.estado == "1"})
        return $this.distribuidoras;
      })).catch(errors => {
        // react on errors.
      })
    },
    async getMesas(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const urlApiFechas = process.env.VUE_APP_API_URL+"/maestro/lista/mesa"
      if($this.mesas.length == 0){
        await axios.get(urlApiFechas,config)
        .then(function (response){
          console.log(response.data, " ====lista de mesas");
          return $this.mesas = response.data
        })
      }
    },
    getDataVisitasbyVendedor() {
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      if(this.dataReport.year && this.dataReport.month && this.initload){
        if(this.distribuidoraIdCurrent){
          this.dataReport.iddistribuidora = Number(this.distribuidoraIdCurrent)
        }
        axios.post(process.env.VUE_APP_API_URL+"/reporte/visita/efectiva/mes",this.dataReport,config)
        .then(function (response){
          console.log(response.data, " ====lista de reporte");
          document.querySelector(".form_laive").classList.remove("validateClient")
          $this.emptyText = "No se encontraron resultados"
          $this.pagination.perPage = response.data.data.length
          $this.pagination.total = response.data.totalPage
          $this.initload = false
          return $this.tableData = response.data.data
        })
      }
    },
  },
  methods:{
    changeListbyDistribuidora(evn){
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      this.dataReport.page = 1
      this.dataReport.iddistribuidora = Number(evn.target.value)
      document.querySelector(".form_laive").classList.add("validateClient")
      const urlApiReport = process.env.VUE_APP_API_URL+`/reporte/visita/efectiva/mes`
      const dataReport = axios.post(urlApiReport,this.dataReport,config)
      axios.all([dataReport]).then(axios.spread((...responses) => {
        const resDataByDistribuidora = responses[0].data
        $this.tableData = resDataByDistribuidora.data
        $this.pagination.perPage = resDataByDistribuidora.data.length
        $this.pagination.total = resDataByDistribuidora.totalPage
        document.querySelector(".form_laive").classList.remove("validateClient")
      })).catch(errors => {
        // react on errors.
      })
    },
    changeMonth(evn){
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      this.dataReport.page = 1
      this.dataReport.month = Number(evn.target.value)
      document.querySelector(".form_laive").classList.add("validateClient")
      axios.post(process.env.VUE_APP_API_URL+"/reporte/visita/efectiva/mes",this.dataReport,config)
      .then(function (response){
        document.querySelector(".form_laive").classList.remove("validateClient")
        $this.pagination.perPage = response.data.data.length
        $this.pagination.total = response.data.totalPage
        return $this.tableData = response.data.data
      })
    },
    changePaginate(evn){
      const $this = this
      const listPaginator = document.querySelectorAll("ul.pagination .page-item")
      let elm = evn.target;
      let sendAjax = false;
      let parentEl = elm.parentNode
      if(elm.classList.contains("prev-page")){
        if(!elm.classList.contains("disabled")){
          let linkActivePrev = document.querySelectorAll("ul.pagination .page-item.active")
          linkActivePrev[0].classList.remove("active")
          linkActivePrev[0].previousSibling.classList.add("active")
          sendAjax = true
        }
      }else if(elm.classList.contains("next-page")){
      }else{
        if(elm.getAttribute("aria-label") == "Previous"){
          let linkActivePrev = document.querySelectorAll("ul.pagination .page-item.active")
          linkActivePrev[0].classList.remove("active")
          linkActivePrev[0].previousSibling.classList.add("active")
          sendAjax = true
        }else if(elm.getAttribute("aria-label") == "Next"){
          let linkActiveNext = document.querySelectorAll("ul.pagination .page-item.active")
            linkActiveNext[0].classList.remove("active")
            linkActiveNext[0].nextSibling.classList.add("active")
            sendAjax = true
        }else{
          if(!parentEl.classList.contains("active")){
            listPaginator.forEach(item => {
              item.classList.remove("active")
            });
            parentEl.classList.add("active")
            sendAjax = true
          }
        }
      }
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      if(sendAjax){
        document.querySelector(".form_laive").classList.add("validateClient")
        axios.post(process.env.VUE_APP_API_URL+"/reporte/visita/efectiva/mes",this.dataReport,config)
        .then(function (response){
          document.querySelector(".form_laive").classList.remove("validateClient")
          sendAjax = false
          $this.dataReport.page = response.data.page
          return $this.tableData = response.data.data
        })
      }
    },
    changeOrder(evn){
      let getDataTable = this.tableData;
      if(evn.target.value == 1){
        getDataTable.sort(mayorToMenor)
      }else if(evn.target.value == 2){
        getDataTable.sort(menorToMayor)
      }
      this.tableData = getDataTable
    },
    exportReport(){
      const token = localStorage.getItem("token")
      const urlDomain = process.env.VUE_APP_API_URL.replace("api", "")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
        }
      };
      document.querySelector(".form_laive").classList.add("validateClient")
      axios.post(process.env.VUE_APP_API_URL+"/reporte/visita/efectiva/mes/export",this.dataReport,config)
      .then(function (response){
        document.querySelector(".form_laive").classList.remove("validateClient")
        const urlFile = urlDomain + response.data.fileName
        const link = document.createElement('a');
        link.href = urlFile;
        document.body.appendChild(link);
        link.click();
      })
    },
    formatNumber(num) {
      var nf = new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 1
      });
      return nf.format(num)
		  // return new Intl.NumberFormat("en-IN").format(num)
	  }
  },
  mounted() {
    if(this.user.rol == 3){
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
    if(this.user.rol == 4){
      this.supervisorIdCurrent = this.user.codigo
      this.supervisorNameCurrent = this.user.nombre
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
    let currentDate = new Date();
    let month = currentDate.getMonth() + 1
    this.dataReport.year = currentDate.getFullYear()
    this.dataReport.month = month
  },
  data() {
    return {
      initload: true,
      distribuidoraIdCurrent: null,
      distribuidoraNameCurrent: null,
      supervisorIdCurrent: null,
      supervisorNameCurrent: null,
      emptyText: "Debe Selecionar una distribuidora",
      pagination: {
        perPage: 2,
        currentPage: 1,
        newcurrentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 1,
      },
      dataReport:{
        page : 1,
        iddistribuidora: 0,       // Obligatorio
        year: null,               // Obligatorio
        month: null,                 // Obligatorio
        cumplimientoorden: "asc" // asc | desc Default asc
      },
      meses:["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio",
            "Agosto","Setiembre","Octubre","Noviembre","Diciembre"],
      distribuidoras:[],
      supervisores:[],
      vendedores:[],
      mesas:[],
      fechas:[],
      fechaSelected: "1",
      searchQuery: "",
      tableData: [],
      tableColumns: [
        {
          prop: "nombreVendedor",
          label: "Vendedor",
        },
        {
          prop: "real",
          label: "Real",
        },
        {
          prop: "programado",
          label: "Programados",
        },
        {
          prop: "avance",
          label: "% Avance",
        },
        {
          prop: "objetivo",
          label: "% Objetivo",
        },
        {
          prop: "diferencia",
          label: "Diferencia",
        },
        {
          prop: "cumplimiento",
          label: "% Cumplimiento",
        },
        {
          prop: "semaforo",
          label: "Estado",
        },
        {
          prop: "fecha",
          label: "Fecha",
        },
      ],
      fuseSearch: null,
      searchedData: [],
      expands: [],
    };
  },
};
</script>
<style lang="scss">
  .swal-wide{
    .swal2-popup{
      width: 52.5em;
    }
  }
  .page-item .page-link .fa{
    z-index: 0;
  }
</style>
