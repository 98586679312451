import clickOutside from "../directives/click-ouside.js";

const focusEsc = {
	beforeMount(el, binding) {
		el.focusEscEvent = function (event) {
			if (event.key == "Escape") {
				binding.value(event, el);
			}
		};
		document.body.addEventListener("keydown", el.focusEscEvent);
	},
	unmounted(el) {
		document.body.removeEventListener("keydown", el.focusEscEvent);
	},
};

/**
 * You can register global directives here and use them as a plugin in your main Vue instance
 */

const GlobalDirectives = {
	install(app) {
		app.directive("click-outside", clickOutside);
		app.directive("focus-esc", focusEsc);
	},
};

export default GlobalDirectives;
