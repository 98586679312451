import axios from "axios";
import setting from "./setting";

const URL = `${setting.http.base_url}/distribuidoras`;

export default {
	async getAll(token) {
		const { data } = await axios.get(`${URL}/lista`, {
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
		});
		return data;
	},
};
