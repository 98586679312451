import axios from "axios";
import setting from "./setting";

const ENDPOINT_URL = `${setting.http.base_url}/pedidominimo`;

export default {
	async listAll(token) {
		const { data } = await axios.get(`${ENDPOINT_URL}/listar-frios`, {
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
		});
		return data;
	},
	async loadDataByFile(token, formData) {
		const { data } = await axios.post(`${ENDPOINT_URL}/cargar`, formData, {
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
		});
		return data;
	},
	async downloadList(token) {
		const { data } = await axios.get(`${ENDPOINT_URL}/exportar`, {
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
		});
		const link = document.createElement("a");
		const domainApi = new URL(setting.http.base_url).origin;
		link.href = `${domainApi}/${data.message}`;
		link.click();
		return data;
	},
};
