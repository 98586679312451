import axios from "axios";
export default {
  namespaced: true,
  state: {
    isLogged: false,
    token: null,
    user: null,
    expiration: null
  },
  getters: {
      authenticated (state) {
          return state.isLogged
      },
      user (state) {
          return state.user
      },
      accessTrade (state) {
          return state.access_trade
      }
      
  },
  mutations: {
    SET_LOGGED(state, data) {
      if (data) {
        state.isLogged = true
        state.token = data.token
        state.expiration = new Date(data.expiration).getTime()
      }
    },
    SET_USER(state, data) {
        state.user = data
    },
    SET_ACCESS_TRADE (state, data) {
      state.access_trade = data
    }
  },
  actions: {
    async signIn({commit, state}, credentials) {
      let dataResp = "";
      await axios.post(process.env.VUE_APP_API_URL+"/usuarios/login", JSON.stringify(credentials),{
        headers: {
            "Content-Type": "application/json",
      }})
      .then(function (response) {
        // console.log({response});
        dataResp = {
          status: response.status,
          data: response.data
        };
        commit('SET_LOGGED', response.data);
        commit('SET_USER', response.data.userInfo);
        localStorage.setItem('token', response.data.token)
        localStorage.setItem('maxAge', new Date(response.data.expiration).getTime())
        localStorage.setItem('userinfo', JSON.stringify(response.data.userInfo))
      })
      .catch(function (error) {
        dataResp = {
          status: error.response.status,
          message: error.response.data.message[0]
        };
        commit('SET_LOGGED', null);
        commit('SET_USER', null);
        state.isLogged = false;
        localStorage.removeItem('token');
        localStorage.removeItem('userinfo');
      });
      return dataResp;
    },
    async validToken({ commit, state }, data) {
      const maxAge = (new Date().getTime() <= Number(data.expiration)) ? true : false
      console.log({data, maxAge});
      if (data.token && data.user && maxAge) {
        commit('SET_LOGGED', data);
        commit('SET_USER', data.user);
      } else {
        commit('SET_LOGGED', null);
        commit('SET_USER', null);
        localStorage.removeItem('token');
        localStorage.removeItem('userinfo');
        localStorage.removeItem('maxAge');
        state.isLogged = false;
        console.log("not data")
      }
    },
    async accessTrade({commit, state}, credentials) {
      const data = {
        usuario : credentials.codigo
      };

      await axios.post(process.env.VUE_APP_API_URL+"/usuarios/login/trade", JSON.stringify(data),{
        headers: {
            "Content-Type": "application/json",
      }})
      .then(function (response) {
        console.log("response trade:", response.data)
        commit('SET_ACCESS_TRADE', response.data.acceso);
        localStorage.setItem('accessTrade', JSON.stringify(response.data.acceso))
      }).catch(function (error) {

      });
    },
  }
}
