<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template v-slot:header>
            <div class="row">
              <div class="col-12 col-md-6">
                <h3 class="mb-0">Jerarquía de Productos</h3>
              </div>
              <div class="col-12 col-md-6 text-right">
                <router-link :to="`${this.$route.href}`+`/crear/`"><base-button size="sm" type="info">Crear Jerarquía</base-button></router-link>
              </div>
            </div>
          </template>
          <div>
            <div class="row px-3">
              <div class="col-12 col-md-4 mb-3">
                <div class="title_filter mb-2">Buscador:  </div>
                <el-input
                  type="search"
                  class="mb-3"
                  clearable
                  prefix-icon="el-icon-search"
                  placeholder="Buscar Jerarquía"
                  v-model="searchQuery"
                  aria-controls="datatables"
                >
                </el-input>
              </div>
              <div class="col-12 col-md-4 mb-3">
                <div class="title_filter mb-2">Jerarquías:  </div>
                <select class="form-control" style="text-transform: capitalize;;height: 43px;" name="jerarquias_select" @change="changeJerarquia">
                  <option value="" disabled selected style="display:none">Seleccionar</option>
                  <option value="marca">marcas</option>
                  <option value="categoria">categorias</option>
                  <option value="presentacion">presentaciones</option>
                  <option value="familia">familias</option>
                </select>
              </div>
              <div class="col-12 col-md-4 d-none">
                <div class="mr-2">
                  <span class="title_filter">Paginación:  </span>
                  <el-select
                    class="select-primary pagination-select"
                    v-model="pagination.perPage"
                    style="width:80px"
                    placeholder="Per page"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <!-- Real Table -->
            <el-table
              :data="queriedData"
              row-key="idProducto"
              header-row-class-name="thead-light"
              empty-text="Debe seleccionar una jerarquía"
            >
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                v-bind="column"
              >
              </el-table-column>
              <el-table-column min-width="100px" align="right">
                <template v-slot:default="props">
                  <div class="d-flex">
                    <router-link :to="`${this.$route.href}`+`/editar/`+`${props.row.idRegistro}`" class="btn base-button btn-success btn-sm edit">Editar</router-link>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <template v-slot:footer>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category d-none">
                  Mostrando {{ from + 1 }} a {{ to }} de {{ total }} productos
                </p>
              </div>
              <default-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              >
              </default-pagination>
            </div>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import axios from "axios";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BasePagination from "@/components/BasePagination";
import DashboardNavbar from "../Layout/DashboardNavbar";
export default {
  components: {
    DashboardNavbar,
    BasePagination,
    RouteBreadCrumb,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  created(){
    console.log(this.$route.href);
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          console.log(row);
          let rowValue = row[key].toString().toLowerCase();
          if (rowValue.includes && rowValue.includes(this.searchQuery.toLowerCase())) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      console.log(result.slice(this.from, this.to));
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
    async gerJerarquias() {
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      await axios.get(process.env.VUE_APP_API_URL+"/producto/maestros",config)
      .then(function (response){
        console.log(response.data, " ====jerarquias real");
        document.querySelector(".form_laive").classList.remove("validateClient")
        var keyNames = Object.keys(response.data);
        return $this.jerarquias = keyNames
      })
    },
  },
  data() {
    return {
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      jerarquias: [],
      propsToSearch: ["codigoDato", "nombre"],
      tableColumns: [
        {
          prop: "codigoDato",
          label: "Código",
          minWidth: 84,
        },
        {
          prop: "nombre",
          label: "Categoria",
          minWidth: 255,
        },
      ],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      expands: [],
    };
  },
  methods: {
    changeJerarquia(evn){
      const $this = this
      const select = evn.target
      const value = select.value;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      if(value){
        document.querySelector(".form_laive").classList.add("validateClient")
        axios.get(process.env.VUE_APP_API_URL+`/maestro/lista/${value}`,config)
        .then(function (response){
          document.querySelector(".form_laive").classList.remove("validateClient")
          console.log(response.data, `====${value} real`);
          return $this.tableData = response.data
        })
      }

    },
    handleEdit(index, row) {
      this.$router.replace({
        path:`editar/${row.codigo}`,
      })
    },
  },
};
</script>
<style>
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
.no-border-card .card-footer {
  border-top: 0;
}
</style>
