<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div
      class="container-fluid mt--6"
      :class="{
        validateClient: !all_loaded,
        form_laive: !all_loaded,
      }"
    >
      <div class="box_loader">
        <div class="loader"></div>
      </div>
      <div class="form_laive" v-if="all_loaded && detalle_mapeado">
        <div
          v-for="(bonificacion, index) in bonificaciones"
          :key="bonificacion"
        >
          <CustomCard
            :bonificacion="bonificacion"
            :tipos="tipos"
            :index="index"
            :es_edicion="true"
          ></CustomCard>
        </div>
      </div>
    </div>

    <div
      class="row px-4 py-2 mx-0 mt-2 w-100 position-fixed bg-white footer-buttons"
    >
      <base-button
        type="success"
        :outline="true"
        class="ml-auto px-4 py-3 submit-btns d-flex"
        @click.prevent="regresar"
        :disabled="esta_enviando"
      >
        <span class="">Cancelar</span>
      </base-button>
      <base-button
        type="success"
        class="px-4 border-0 submit-btns d-flex"
        :class="!esta_enviando ? 'py-3' : 'py-0'"
        @click.prevent="actualizarBonificaciones"
      >
        <span class="" v-if="!esta_enviando">Enviar</span>
        <div v-else class="laive-loader"></div>
      </base-button>
    </div>
  </div>
</template>

<script setup>
import "@/assets/sass/laive/bonificaciones.scss";

import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import DashboardNavbar from "../Layout/DashboardNavbar";
import CustomCard from "./components/CustomCard.vue";
import { ref, computed, watch, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import * as moment from "moment";

const route = useRoute();

import constantes from "@/model/constantes.js";
import Swal from "sweetalert2";

import { useStore } from "vuex";
const store = useStore();
store.dispatch("bonificacionUtil/cargarLista", "distribuidoras");
store.dispatch("bonificacionUtil/cargarLista", "productos");

store.dispatch("bonificacionUtil/cargarLista", "tiposCliente");
store.dispatch("bonificacionUtil/cargarLista", "segmentos");
store.dispatch("bonificacionUtil/cargarLista", "girosNegocio");
store.dispatch("bonificacionUtil/cargarLista", "tiposVendedor");
store.dispatch("bonificacionUtil/cargarLista", "vendedores");

store.dispatch("bonificacionUtil/cargarLista", "categorias");
store.dispatch("bonificacionUtil/cargarLista", "familias");
store.dispatch("bonificacionUtil/cargarLista", "presentaciones");

const all_loaded = computed(
  () =>
    store.state.bonificacionUtil.distribuidoras.length &&
    store.state.bonificacionUtil.productos.length &&
    store.state.bonificacionUtil.tiposCliente.length &&
    store.state.bonificacionUtil.segmentos.length &&
    store.state.bonificacionUtil.girosNegocio.length &&
    store.state.bonificacionUtil.tiposVendedor.length &&
    store.state.bonificacionUtil.vendedores.length
);

const tipos = ref([
  {
    nombre: "Seleccionar Tipo",
  },
  {
    nombre: "Promoción",
    valor: "promocion",
  },
  {
    nombre: "Combo",
    valor: "combo",
  },
  {
    nombre: "Escala",
    valor: "escala",
  },
  {
    nombre: "Rebate",
    valor: "rebate",
  },
]);

const bonificaciones = ref([]);

onMounted(() => {
  const lista = route.params.lista;
  const codigo = route.params.codigo;
  const ids = route.params.ids;
  //obtener lo que vamos a editar
  const request = {
    identificadores: ids,
    codigo,
    conclientes: true
  };
  if (lista == "activas") {
    store
      .dispatch("bonificacion/obtenerBonificacionesActivas", request)
      .then((result) => {
        const bonificacion = bonificaciones_raw.value[0];
        bonificacion.inicio = moment(
          bonificacion.inicio,
          "MM/DD/YYYY HH:mm:ss"
        ).toDate();
        bonificacion.fin = moment(
          bonificacion.fin,
          "MM/DD/YYYY HH:mm:ss"
        ).toDate();
        bonificacion.puede_modificar_inicio = bonificacion.inicio > new Date();
        bonificacion.puede_modificar_fin = bonificacion.fin >= new Date();
        bonificacion.carga_masiva = !!bonificacion.excel_path;
        bonificacion.distribuidoras = bonificacion.distribuidoras.map((item) =>
          parseInt(item.id)
        );
        bonificacion.indice = 0;
        bonificaciones.value = [bonificacion];
        detalle_mapeado.value = true;
        detalle_cargado.value = true;
      });
  } else {
    store
      .dispatch("bonificacion/obtenerBonificacionesInactivas", request)
      .then((result) => {
        const bonificacion = bonificaciones_raw.value[0];
        bonificacion.inicio = moment(
          bonificacion.inicio,
          "MM/DD/YYYY HH:mm:ss"
        ).toDate();
        bonificacion.fin = moment(
          bonificacion.fin,
          "MM/DD/YYYY HH:mm:ss"
        ).toDate();
        bonificacion.puede_modificar_inicio = true;
        bonificacion.puede_modificar_fin = true;
        bonificacion.carga_masiva = !!bonificacion.excel_path;
        bonificacion.distribuidoras = bonificacion.distribuidoras.map((item) =>
          parseInt(item.id)
        );
        bonificaciones.value = [bonificacion];
        detalle_mapeado.value = true;
        detalle_cargado.value = true;
      });
  }
});

const detalle_cargado = ref(false);
const detalle_mapeado = ref(false);

const bonificaciones_raw = computed(() =>
  route.params.lista == "activas"
    ? store.state.bonificacion.bonificaciones_activas
    : store.state.bonificacion.bonificaciones_inactivas
);

const regresar = () => {
  location.href = "/bonificaciones/listado";
};
const esta_enviando = ref(false);

const actualizarBonificaciones = async () => {
  esta_enviando.value = true;
  const bonificacion = bonificaciones.value[0];
  const payload = {
    idbonificacionbonhistoricodistribuidora: bonificacion.relaciones_ids.join(
      ","
    ),
    fechainicio: bonificacion.inicio,
    fechafin: bonificacion.fin,
  };
  const mensaje_validacion = verificarFechas();
  if (mensaje_validacion) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: mensaje_validacion,
    });
    esta_enviando.value = false;

    return;
  }
  const result = await store.dispatch("bonificacion/edit", bonificaciones.value);
  //const result = await store.dispatch("bonificacion/cambiarfecha", payload);
  esta_enviando.value = false;

  if (result.status == 200) {
    Swal.fire({
      title: "OK",
      text: "Datos guardados correctamente",
      icon: "success",
    }).then((result) => {
      if (result.isDismissed || result.isConfirmed) {
        location.href = "/bonificaciones/listado";
      }
    });
  } else {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Algo salió mal.",
    });
  }
};

const verificarFechas = () => {
  if (detalle_cargado == false) {
    return true;
  }
  const bonificacion = bonificaciones.value[0];
  const primerDiaMesActual = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  );
  let fin_en_mes_actual = true;
  if (bonificacion.puede_modificar_fin) {
    fin_en_mes_actual = bonificacion.fin >= primerDiaMesActual;
  }
  let rango_valido = true;
  if (bonificacion.puede_modificar_inicio) {
    rango_valido = bonificacion.inicio < bonificacion.fin;
  }
  let mensaje = null;
  if (!fin_en_mes_actual) {
    mensaje =
      "No se puede cambiar la fecha antes del mes actual debido a que la liquidacion del mes anterior para esta promocion ya se creó.";
  }
  if (!rango_valido) {
    mensaje = "La fecha inicio tiene que ser menor a la fecha fin.";
  }
  return mensaje;
};
</script>

<style lang="scss" scoped>
.footer-buttons {
  bottom: 0px;
  right: 0;
}
.bonificacion {
  &-tipo {
    border-radius: 5px;
    border: 1px solid #02a54f;
    color: #02a54f;
    font-weight: bold;
    font-size: 1rem;
    border-radius: 8px;
  }
}
.text-laive {
  color: #02a54f;
}
.text-laive:hover {
  background-color: white !important;
  color: #02a54f;
}
.btn {
  border-radius: 10px !important;
}
</style>
