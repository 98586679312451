<template>
  <span class="ui-badge" :style="[cssProps]">
    <slot>
      {{ text }}
    </slot>
  </span>
</template>

<script>
import { BADGE_TYPE } from "./constants";
import { ThemeProps } from "./utils";

export default {
  props: {
    type: {
      type: String,
      default: BADGE_TYPE.solid,
      validator(tp) {
        return Object.keys(BADGE_TYPE).includes(tp);
      }
    },
    text: String,
    radius: String,
    ...ThemeProps.props
  },
  computed: {
    cssProps() {
      return {
        ...ThemeProps.style(this.$props),
        "--ui-badge-radius": this.type == "chip" ? "9999px" : (this.radius ?? "5px"),
      };
    }
  }
}

</script>
<style scoped>
.ui-badge {
  display: inline-block;
  white-space: nowrap;
  border-radius: var(--ui-badge-radius);
  background-color: var(--ui-bg);
  color: var(--ui-text-color);
  font-size: var(--ui-fs);
  font-weight: var(--ui-fw);
  padding-inline: var(--ui-px);
  padding-block: var(--ui-py);
  height: max-content;
  cursor: default;
  text-align: center;
  width: var(--ui-w);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
