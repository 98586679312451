<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <!-- HTML5 inputs -->
            <div class="card form_laive validateClient">
              <div class="box_loader">
                <div class="loader"></div>
              </div>
              <!-- Card header -->
              <div class="card-header">
                <h3 class="mb-0">Semáfonos - Reportes</h3>
              </div>
              <!-- Card body -->
              <div class="card-body">
                <div class="row">
                  <label class="col-md-12 col-form-label form-control-label"
                    >Seleccione una Distribuidora</label
                  >
                  <div class="col-md-4">
                    <select class="form-control" style="display: inline-block;height: 43px;" name="distributor_select" @change="changeListbyDistribuidora">
                      <option disabled selected style="display:none" value="0">Seleccionar</option>
                      <option
                        v-for="item in distribuidoras"
                        :value="item.idDistribuidora"
                        :key="item.idDistribuidora"
                        >
                        {{ item.razonSocial }}
                      </option>
                    </select>
                  </div>
                </div>
                <template v-for="report in tableData" :key="report.nombre">
                  <form @submit.prevent="submitUpdatedParameter" class="form_laive semaforo" style="margin-top:1.3rem">
                    <div class="box_loader">
                      <div class="loader"></div>
                    </div>
                    <template v-if="report.item.length && report.nombre !== 'Peso - Cobertura simple' && report.nombre !== '% Cartera - Cobertura simple'">
                      <h2>{{report.nombre}}</h2>
                      <template v-for="item in report.item" :key="item.id">
                        <template v-if="item.codigo == 'verde'">
                          <div class="row" :data-id="item.id" :data-tabla="item.tabla" :data-nombre="item.codigo">
                            <label class="col-md-12 col-form-label form-control-label">Verde</label>
                            <div class="col-md-6">
                              <div class="form-group">
                                <input
                                  required
                                  name="verde"
                                  placeholder="Porcentaje"
                                  class="form-control green"
                                  :value="item.valor1"
                                  @keypress="isNumber($event)"
                                >
                              </div>
                            </div>
                          </div>
                        </template>
                        <template v-if="item.codigo == 'amarillo'">
                          <div class="row" :data-id="item.id" :data-tabla="item.tabla" :data-nombre="item.codigo">
                            <label class="col-md-12 col-form-label form-control-label">Amarillo</label>
                            <div class="col-md-6">
                              <div class="form-group">
                                <input
                                  required
                                  placeholder="Porcentaje"
                                  class="form-control yellow"
                                  name="amarillo_1"
                                  :value="item.valor1"
                                  @keypress="isNumber($event)"
                                >
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <input
                                  required
                                  placeholder="Porcentaje"
                                  class="form-control yellow"
                                  name="amarillo_2"
                                  :value="item.valor2"
                                  @keypress="isNumber($event)"
                                >
                              </div>
                            </div>
                          </div>
                        </template>
                        <template v-if="item.codigo == 'rojo'">
                          <div class="row" :data-id="item.id" :data-tabla="item.tabla" :data-nombre="item.codigo">
                            <label class="col-md-12 col-form-label form-control-label">Rojo</label>
                            <div class="col-md-6">
                              <div class="form-group">
                                <input
                                  required
                                  placeholder="Porcentaje"
                                  class="form-control red"
                                  name="rojo"
                                  :value="item.valor1"
                                  @keypress="isNumber($event)"
                                >
                              </div>
                            </div>
                          </div>
                        </template>
                      </template>
                      <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar Objetivo</base-button>
                    </template>
                  </form>
                </template>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from "axios";
  import { useToast } from "vue-toastification";
  import Notification from "@/components/Notification";
  import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
  import DashboardNavbar from "../Layout/DashboardNavbar";
  export default {
    components: {
      DashboardNavbar,
      RouteBreadCrumb,
    },
    created(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let urlApiDistribuidoras = process.env.VUE_APP_API_URL+"/distribuidoras/lista"
      const requestDistribuidoras = axios.get(urlApiDistribuidoras, config);
      axios.all([requestDistribuidoras]).then(axios.spread((...responses) => {
        const responseDistribuidoras = responses[0].data
        $this.distribuidoras = responseDistribuidoras.filter((item)=>{return item.estado == "1"})
        document.querySelector(".form_laive").classList.remove("validateClient")
        return $this.distribuidoras;
      })).catch(errors => {
        // react on errors.
      })
    },
    methods: {
      changeListbyDistribuidora(evn){
        let selected = Number(document.querySelector("select[name='distributor_select']").value)
        const $this = this;
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        const parameters = {
          "iddistribuidora": selected,
        }
        if(selected){
          document.querySelector(".form_laive").classList.add("validateClient")
          axios.post(process.env.VUE_APP_API_URL+"/parametro/reporte",parameters,config)
          .then(function (response){
            console.log(response.data, " ====lista by distribuidora");
            $this.tableData = response.data
            // En caso no tenga parámetros, se crea
            if($this.tableData[0].item.length == 0){
              axios.post(process.env.VUE_APP_API_URL+"/parametro/reporte/crear/parametros",parameters,config)
              .then(function (response){
                $this.changeListbyDistribuidora()
              })
            }
            document.querySelector(".form_laive").classList.remove("validateClient")

          })
        }else{
          $this.tableData = []
        }
      },
      testmet(){
        alert("test trigger")
      },
      isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault();;
        } else {
          return true;
        }
      },
      submitUpdatedParameter(evn) {
        const target = evn.target;
        const allItems = target.querySelectorAll(".row")
        const getID = evn.target.getAttribute("id")
        const token = localStorage.getItem("token")
        let messageSuccess = ""
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        let dataSend = []
        allItems.forEach(item => {
          let dataID = item.getAttribute("data-id")
          let dataTabla = item.getAttribute("data-tabla")
          let dataCodigo = item.getAttribute("data-nombre").toLowerCase()
          let dataNombre = item.getAttribute("data-nombre")
          let dataValor1 = (dataCodigo == 'amarillo') ? item.querySelector("input[name='amarillo_1']").value : item.querySelector("input").value
          let dataValor2 = (dataCodigo == 'amarillo') ? item.querySelector("input[name='amarillo_2']").value : "0"
          dataSend.push(
            {
              "id": dataID,
              "tabla": dataTabla,
              "codigo": dataCodigo,
              "nombre": dataNombre,
              "valor1": dataValor1,
              "valor2": dataValor2
            }
          )
        });
        console.log({target, allItems, dataSend})
        const urlApiUpdated = process.env.VUE_APP_API_URL+"/parametro/reporte/guardar"
        target.classList.add("validateClient")
        axios.post(urlApiUpdated,dataSend,config)
        .then(function (response){
          target.classList.remove("validateClient")
          const text = (response.status == 200) ? "Se actualizaron los Parámetros" : "Hubo un error";
          const icon = "ni ni-bell-55";
          const content = {
            component: Notification,
            props: {
              icon: icon,
              text: text,
              type: (response.status == 200) ? "success" : "danger",
            },
          };
          const toast = useToast();
          toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
        })
      },
    },
    data() {
      return {
        distribuidoras: [],
        tableData:[],
      };
    },
  };
</script>

