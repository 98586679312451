<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive validateClient">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template v-slot:header>
            <div class="row">
              <div class="col-12 col-md-6">
                <h3 class="mb-0">Por Recomendación</h3>
              </div>
            </div>
          </template>
          <div>
            <div class="row px-4">
              <div class="col-12 mb-3">
                <div class="row">
                  <div class="col-12 col-md-3">
                    <template v-if="distribuidoraIdCurrent">
                      <div class="title_filter mr-2"><div>Distribuidora:</div>  <strong>{{distribuidoraNameCurrent}}</strong></div>
                    </template>
                    <template v-else>
                      <span class="title_filter mr-2">Distribuidoras:</span>
                      <select class="form-control" style="display: inline-block;height: 43px;" name="distributor_select" @change="changeListbyDistribuidora">
                        <option value="0">Selecciona una opción</option>
                        <option
                          v-for="item in distribuidoras"
                          :value="item.idDistribuidora"
                          :key="item.idDistribuidora"
                          >
                          {{ item.razonSocial }}
                        </option>
                      </select>
                    </template>
                  </div>
                  <div class="col-12 col-md-3">
                    <template v-if="supervisorIdCurrent">
                      <span class="title_filter mr-2">Supervisor:<br><strong>{{supervisorNameCurrent}}</strong></span>
                    </template>
                    <template v-else>
                      <span class="title_filter mr-2">Supervisor:  </span>
                      <select class="form-control" id="supervisor_select" style="display: inline-block;height: 43px;" name="supervisor_select" @change="changeListbySupverisor">
                        <option value="">Selecciona una opción</option>
                        <option
                          v-for="item in supervisores"
                          :value="item.codigoSupervisor"
                          :key="item.codigoSupervisor+item.distribuidora"
                          :selected="item.codigoSupervisor == parameters.supervisor"
                          >
                          {{ item.nombre }}
                        </option>
                      </select>
                    </template>
                  </div>
                  <div class="col-12 col-md-3">
                    <span class="title_filter mr-2">Fechas:</span>
                    <select class="form-control" id="fecha_select" style="display: inline-block;height: 43px;" name="fecha_select" @change="changeListbyFechas">
                      <option value="">Selecciona una opción</option>
                      <option
                        v-for="item in fechas"
                        :value="item.codigoDato"
                        :key="item.codigoDato"
                        :selected="item.codigoDato == fechaSelected"
                        >
                        {{ item.nombre }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="row px-1">
              <div class="col-12">
                  <template v-if="queriedData.length">
                    <template v-for="table in queriedData" :key="table.title">
                      <div class="border-0 card-header">
                        <h3 class="mb-0">{{table.title}}</h3>
                      </div>
                      <div class="el-table table_report pesos">
                        <table>
                          <thead class="el-table__header-wrapper">
                            <template v-for="(item, i) in headerTable" :key="item+table.title">
                              <template v-if="i > 0">
                                <th :style="(i == 1) ? `min-width:350px;display:block;` : ``">{{item}}</th>
                              </template>
                            </template>
                          </thead>
                          <tbody>
                            <tr v-for="(elm, k) in table.data" :key="`${elm.vendedor}-${k}`" :class="(table.data.length - 1 == k) ? 'footer' : ''">
                              <template v-for="(value, j) in Object.values(elm)" :key="`${value}-${j}`">
                                <td v-if="j > 0">
                                  {{ value }}
                                </td>
                                <td v-if="value == 'SubTotal'">
                                  {{ value }}
                                </td>
                              </template>
                              <td>
                                <base-button v-if="table.data.length - 1 != k" type="info" class="detail" size="sm" role="button" @click="detailReport(elm)">Detalle</base-button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </template>
                  </template>
                  <template v-else>
                    <div class="col-12 text-center">
                      <el-table
                        :data="tableReporte"
                        row-key="totalreporte"
                        header-row-class-name="thead-light"
                        :empty-text="emptyText"
                      >
                      </el-table>
                    </div>
                  </template>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import axios from "axios";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import TabPane from "@/components/Tabs/TabPane";
import BasePagination from "@/components/BasePagination";
import {mapGetters} from 'vuex';
import swal from "sweetalert2";
import DashboardNavbar from "../Layout/DashboardNavbar";
export default {
  components: {
    DashboardNavbar,
    BasePagination,
    RouteBreadCrumb,
    TabPane,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  computed: {
    pagedData() {
      this.getDistributor
      this.getSupervisor
      this.getFechas
      this.getDataEstadisticas
      return this.tableReporte;
    },
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
    },
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    }),
    getDistributor(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let urlApiDistribuidoras = process.env.VUE_APP_API_URL+"/distribuidoras/lista"
      const requestDistribuidoras = axios.get(urlApiDistribuidoras, config);
      if($this.distribuidoras.length == 0){
        console.log("debo cargar distruiwiqeqw")
        axios.all([requestDistribuidoras]).then(axios.spread((...responses) => {
          const responseDistribuidoras = responses[0].data
          if($this.distribuidoraIdCurrent){
            let dataDistibuitorCurrent =  responseDistribuidoras.filter((item)=>{return item.idDistribuidora == $this.distribuidoraIdCurrent})
            $this.distribuidoraNameCurrent = dataDistibuitorCurrent[0].razonSocial
          }
          $this.distribuidoras = responseDistribuidoras.filter((item)=>{return item.estado == "1"})
          return $this.distribuidoras;
        })).catch(errors => {
          // react on errors.
        })
      }
    },
    getSupervisor(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const selectDistribuidora = ($this.distribuidoraIdCurrent) ? $this.distribuidoraIdCurrent : 0
      const urlApiSupervisores = process.env.VUE_APP_API_URL+`/supervisor/lista/${selectDistribuidora}`
      axios.get(urlApiSupervisores,config)
      .then(function (response){
        let dataSupervisores= response.data
        if(Number($this.distribuidoraIdCurrent) == 0){
          document.querySelector(".form_laive").classList.remove("validateClient")
        }
        return $this.supervisores = dataSupervisores
      })
    },
    async getFechas(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const urlApiFechas = process.env.VUE_APP_API_URL+"/maestro/lista/listafecha"
      if($this.fechas.length == 0){
        await axios.get(urlApiFechas,config)
        .then(function (response){
          return $this.fechas = response.data
        })
      }
    },
    getDataEstadisticas(){
      const $this = this
      let idDistribuidora = Number(this.distribuidoraIdCurrent)
      if(idDistribuidora){
        let idPeriodo = document.querySelector("select[name='fecha_select']").value
        let idSupervisor = ($this.supervisorIdCurrent) ? $this.supervisorIdCurrent : document.querySelector("select[name='supervisor_select']").value
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        const parameters = {
          "iddistribuidora": idDistribuidora,
          "codigosupervisor": idSupervisor,
          "codigomesa": "",
          "codigovendedor": "",
          "periodo": "1",
        }
        //console.log({parameters})
        const urlApiResumen = process.env.VUE_APP_API_URL+`/producto/estadistica`
        const dataResumen = axios.post(urlApiResumen,parameters,config)
        axios.all([dataResumen]).then(axios.spread((...responses) => {
          const resDataByDistribuidora = responses[0].data
          document.querySelector(".form_laive").classList.remove("validateClient")
          const orgData = resDataByDistribuidora.filter((item)=>{return item.resumen.length > 0})
          const dataTable = (orgData.length) ?  $this.formatDataTable(orgData) : []
          console.log({dataTable})
          $this.tableReporte = dataTable
          // (orgData.length) ? "" : $this.emptyText = "No se encontraron resultados"
        })).catch(errors => {
          // react on errors.
        })
      }
    },
  },
  methods:{
    changeListbyDistribuidora(evn){
      const $this = this
      let selected = Number(evn.target.value)
      if(selected){
        let idSupervisor = document.querySelector("select[name='supervisor_select']").value
        let idPeriodo = document.querySelector("select[name='fecha_select']").value
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        const parameters = {
          "iddistribuidora": selected,
          "codigosupervisor": idSupervisor,
          "codigomesa": "",
          "codigovendedor": "",
          "periodo": idPeriodo,
        }
        document.querySelector(".form_laive").classList.add("validateClient")
        const urlApiSupervisores = process.env.VUE_APP_API_URL+`/supervisor/lista/${selected}`
        const urlApiResumen = process.env.VUE_APP_API_URL+`/producto/estadistica`
        const dataResumen = axios.post(urlApiResumen,parameters,config)
        const getSupervisores = axios.get(urlApiSupervisores,config)
        axios.all([dataResumen, getSupervisores]).then(axios.spread((...responses) => {
          const resDataByDistribuidora = responses[0].data
          const resListSupervisores = responses[1].data
          $this.supervisores = resListSupervisores
          const orgData = resDataByDistribuidora.filter((item)=>{return item.resumen.length > 0})
          const dataTable = (orgData.length) ?  $this.formatDataTable(orgData) : []
          $this.tableReporte = dataTable
          document.querySelector(".form_laive").classList.remove("validateClient")
          console.log({dataTable})
          // $this.tableReporte = resDataByDistribuidora.filter((item)=>{return item.resumen.length > 0})
          //($this.tableReporte.length) ? "" : $this.emptyText = "No se encontraron resultados"
        })).catch(errors => {
          // react on errors.
        })
      }else{
        document.querySelector("select[name='supervisor_select']").value = ""
        this.tableReporte = []
        const swalWithBootstrapButtons1 = swal.mixin({
          customClass: {
            confirmButton: "btn btn-success btn-fill",
          },
          buttonsStyling: false,
        });
        swalWithBootstrapButtons1.fire({
          title: `Seleccionar una distribuidora`,
          confirmButtonText: "Ok",
        });
      }
    },
    changeListbySupverisor(evn){
      const $this = this
      let selected = evn.target.value
      let idDistribuidora = ($this.distribuidoraIdCurrent) ? $this.distribuidoraIdCurrent : Number(document.querySelector("select[name='distributor_select']").value)
      let idPeriodo = document.querySelector("select[name='fecha_select']").value
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      if(idDistribuidora != 0){
        const parameters = {
          "iddistribuidora": idDistribuidora,
          "codigosupervisor": selected,
          "periodo": idPeriodo,
          "codigomesa": "",
          "codigovendedor": "",
        }

        document.querySelector(".form_laive").classList.add("validateClient")
        const urlApiResumen = process.env.VUE_APP_API_URL+`/producto/estadistica`
        const dataResumen = axios.post(urlApiResumen,parameters,config)
        axios.all([dataResumen]).then(axios.spread((...responses) => {
          const resDataBySupervisor = responses[0].data
          const orgData = resDataBySupervisor.filter((item)=>{return item.resumen.length > 0})
          const dataTable = (orgData.length) ?  $this.formatDataTable(orgData) : []
          $this.tableReporte = dataTable
          document.querySelector(".form_laive").classList.remove("validateClient")
        })).catch(errors => {
          this.tableReporte = []
          ($this.tableReporte.length) ? "" : $this.emptyText = "No se encontraron resultados"
        })
      }else{
        const swalWithBootstrapButtons1 = swal.mixin({
          customClass: {
            confirmButton: "btn btn-success btn-fill",
          },
          buttonsStyling: false,
        });
        swalWithBootstrapButtons1.fire({
          title: `Seleccionar una distribuidora`,
          confirmButtonText: "Ok",
        });
      }
    },
    changeListbyFechas(evn){
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let selected = evn.target.value
      let idDistribuidora = ($this.distribuidoraIdCurrent) ? $this.distribuidoraIdCurrent : Number(document.querySelector("select[name='distributor_select']").value)
      let idSupervisor = document.querySelector("select[name='supervisor_select']").value
      if(idDistribuidora != 0){
        const parameters = {
          "iddistribuidora": idDistribuidora,
          "codigosupervisor": idSupervisor,
          "codigomesa": "",
          "codigovendedor": "",
          "periodo": selected,
          "page": 1
        }
        document.querySelector(".form_laive").classList.add("validateClient")
        const urlApiResumen = process.env.VUE_APP_API_URL+`/producto/estadistica`
        axios.post(urlApiResumen,parameters,config)
        .then(function (response){
          const resDataByFechas = response.data
          const orgData = resDataByFechas.filter((item)=>{return item.resumen.length > 0})
          const dataTable = (orgData.length) ?  $this.formatDataTable(orgData) : []
          $this.tableReporte = dataTable
          document.querySelector(".form_laive").classList.remove("validateClient")
        })
      }else{
        const swalWithBootstrapButtons1 = swal.mixin({
          customClass: {
            confirmButton: "btn btn-success btn-fill",
          },
          buttonsStyling: false,
        });
        swalWithBootstrapButtons1.fire({
          title: `Seleccionar una distribuidora`,
          confirmButtonText: "Ok",
        });
      }
    },
    detailReport(data){
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const swalWithBootstrapButtons = swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          container: "swal-wide",
        },
        buttonsStyling: false,
      });
      let tableHtML = ""
      let idDistribuidora = (this.distribuidoraIdCurrent) ? this.distribuidoraIdCurrent : Number(document.querySelector("select[name='distributor_select']").value)
      let idPeriodo = document.querySelector("select[name='fecha_select']").value
      const parameters = {
        "iddistribuidora": idDistribuidora,
        "codigovendedor": data["codigovendedor"],
        "periodo": idPeriodo
      }
      const urlDetalle = process.env.VUE_APP_API_URL+`/producto/estadistica/detalle`
      document.querySelector(".form_laive").classList.add("validateClient")
      axios.post(urlDetalle,parameters,config)
      .then(function (response){
        const dataResponse = response.data
        if(dataResponse.length){
          dataResponse.forEach(item => {
          tableHtML += `
                    <tr>
                      <td>${item.tipoProducto}</td>
                      <td>S/ ${item.ventaTotal}</td>
                      <td>${item.porcVentaTotal}%</td>
                      <td>${item.posiciones}</td>
                      <td>${item.porcPosiciones}%</td>
                    </tr>`
          })
          document.querySelector(".form_laive").classList.remove("validateClient")
          swalWithBootstrapButtons.fire({
            title: `Detalle de ${data["vendedor"]}`,
            html: `
                <table class="table_laive">
                  <tr>
                    <th>Tipo de producto</th>
                    <th>Ventas Totales</th>
                    <th>% Ventas Totales</th>
                    <th>Posiciones</th>
                    <th>% Posiciones</th>
                  </tr>
                  ${tableHtML}
                </table>`,
            confirmButtonText: "Cerrar",
            showConfirmButton: true,
          });

        }
      });
    },
    formatNumber(num) {
      var nf = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      return nf.format(num)
	  },
    formatDataTable(data){
      let dataTable = [];
      let headers = Object.keys(data[0].resumen[0])
      headers.shift();
      headers.shift();
      data.forEach((item, i) =>{
        let resumen = item.resumen.map(({codigomesa,iddistribuidora, ...rest}) => {return rest;});
        dataTable.push({
            "title": item.mesa,
            "data" : resumen
          }
        )
      });
      console.log({dataTable})
      this.headerTable = headers
      return dataTable
    }
  },
  mounted() {
    if(this.user.rol == 3){
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
    if(this.user.rol == 4){
      this.supervisorIdCurrent = this.user.codigo
      this.supervisorNameCurrent = this.user.nombre
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
  },
  data() {
    return {
      initload: true,
      distribuidoraIdCurrent: null,
      distribuidoraNameCurrent: null,
      supervisorIdCurrent: null,
      supervisorNameCurrent: null,
      emptyText: "Debe Seleccionar una distribuidora",
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      parameters:{
        distribuidora: "",
        supervisor: "",
        vendedor: ""
      },
      searchQuery: "",
      distribuidoras:[],
      supervisores:[],
      vendedores:[],
      fechas:[],
      fechaSelected: "1",
      estados:[],
      headerTable: [],
      tableReporte:[],
      propsToSearch: ["montoTotal", "totalpedidos"],
      fuseSearch: null,
      searchedData: [],
      expands: [],
    };
  },
};
</script>
