<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <div class="card form_laive" :class="{ 'validateClient' : loading}">
            <div class="box_loader">
              <div class="loader"></div>
            </div>
            <div class="card-header">
              <h3 class="mb-0">Notificación de nuevos clientes</h3>
            </div>
            <div class="card-body">
              <div class="el-table table_report pesos notoverflow">
                <h4 class="mb-1 form-control-label">Configuración de correos por distribuidora</h4>
                <table width="100%" border="0">
                  <thead class="el-table__header-wrapper">
                    <th v-for="(item) in tableColumns" :key="item.prop">
                      {{ item.label }}
                    </th>
                    <th></th>
                  </thead>
                  <tbody>
                    <template v-if="distribuidoras.length">
                      <template v-for="value in distribuidoras" :key="value.idDistribuidora">
                        <tr>
                          <td style="width:180px">{{value.razonSocial}}</td>
                          <td>
                            <base-button type="info" class="edit" size="sm" role="button" @click="openEmail(value)" aria-pressed="true">Configurar correos</base-button>
                          </td>
                        </tr>
                      </template>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CustomModal
    :show="horarioNuevoCliente.showModal"
    :isHeader="true"
    :isFooter="false"
    :width="640"
    :title="'Configurar correos de '+horarioNuevoCliente.distribuidora"
    v-on:modal:close="horarioNuevoCliente.showModal = $event" >
    <template v-slot:body>
      <form @submit.prevent="submitHorarioEmail" class="form_laive" :class="{ 'validateClient' : horarioNuevoCliente.loading}">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="col-form-label form-control-label">Asunto:</label>
              <input v-model="horarioNuevoCliente.data.asunto" name="cronjob_asunto" class="form-control" type="text" placeholder="Agregar asunto" required>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="col-form-label form-control-label">Intervalo de envío:</label>
              <select v-model.number="horarioNuevoCliente.data.intervalo" class="form-control" name="interval_select" id="interval_select">
                <option value="" selected disabled style="display:none">Seleccionar</option>
                <option
                  v-for="item in intervalos"
                  :value="item.id"
                  :key="item.id"
                >
                  {{ item.value }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label class="col-form-label form-control-label">Horario de envío:</label>
          </div>
          <div class="col-md-6">
            <base-input name="devolucion_horario_inicio">
              <template v-slot:addonLeft>Inicio</template>
              <flat-picker :config="configDate" v-model="horarioNuevoCliente.data.inicio" placeholder="Horario de Inicio" class="form-control datepicker"></flat-picker>
            </base-input>
          </div>
          <div class="col-md-6">
            <base-input name="devolucion_horario_inicio">
              <template v-slot:addonLeft>Cierre</template>
              <flat-picker :config="configDate" v-model="horarioNuevoCliente.data.cierre" placeholder="Horario Cierre" class="form-control datepicker"></flat-picker>
            </base-input>
          </div>
        </div>
        <div class="form-group">
          <label class="col-form-label form-control-label">Agregar correos en copia:</label>
          <div class="row">
            <template v-for="(elm, index) in horarioNuevoCliente.data.listemail" :key="index">
              <div class="col-md-6">
                <div class="box__input">
                  <input v-model="horarioNuevoCliente.data.listemail[index]" :name="'cronjob_'+index" class="form-control mb-2" type="email" placeholder="Agregar correo Electronico" required>
                  <i class="fa fa-times" @click="removeEmail(horarioNuevoCliente.data.listemail[index])"></i>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="box_addphone pt-1" v-if="horarioNuevoCliente.data.listemail.length < 5"><span @click="addEmail">+ Agregar otro correo</span></div>
        <base-button type="primary" native-type="submit" class="btn-default my-0 mt-2">Actualizar envio de correos</base-button>
      </form>
    </template>
  </CustomModal>
</template>
<script>
  import axios from "axios";
  import {mapGetters, mapActions} from 'vuex';
  import { useToast } from "vue-toastification";
  import Notification from "@/components/Notification";
  import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
  import DashboardNavbar from "../Layout/DashboardNavbar";
  import flatPicker from "vue-flatpickr-component";
  import {Spanish} from 'flatpickr/dist/l10n/es.js';
  import "flatpickr/dist/flatpickr.css";
  import CustomModal from "../Components/CustomModal.vue";
  export default {
    components: {
      DashboardNavbar,
      RouteBreadCrumb,
      flatPicker,
      CustomModal
    },
    created(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };

      this.loading = true
      let urlDistribuidoras = process.env.VUE_APP_API_URL+"/distribuidoras/lista"
      const getDistribuidoras = axios.get(urlDistribuidoras, config);

      axios.all([getDistribuidoras]).then(axios.spread((...responses) => {
        const resDistribuidoras = responses[0].data
        $this.distribuidoras = resDistribuidoras ? resDistribuidoras.filter((item)=>{return item.estado == "1"}) : []
        $this.loading = false
      })).catch(errors => {
        $this.loading = false
      })
    },
    computed:{
      ...mapGetters({
        authenticated: 'auth/authenticated',
        user: 'auth/user',
      }),
    },
    mounted() {
      if(this.user.rol == 3){
        this.distribuidoraIdCurrent = this.user.idDistribuidora
      }
    },
    methods: {
      ...mapActions({
        getDistributorList: 'distribuidora/getList',
      }),
      isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault();;
        } else {
          return true;
        }
      },
      submitHorario(evn){
        const keyArray = evn.target.getAttribute("data-key")
        const $parent = evn.target.parentNode
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        this.registroPedidos.map((item, indice, arr) => {
          let newValue = `${item.inicio}|${item.cierre}`
          item.valor = newValue
          return item
        });
        const dataSend = this.registroPedidos[keyArray]
        const urlApiUpdated = process.env.VUE_APP_API_URL+"/parametro/actualizar"
        $parent.classList.add("validateClient")
        axios.put(urlApiUpdated,dataSend,config)
        .then(function (response){
          $parent.classList.remove("validateClient")
          const text =(response.status == 200) ? "Se actualizó el horario de pedido" : "Hubo un error";
          const icon ="ni ni-bell-55";
          const content={
            component: Notification,
            props: {
              icon: icon,
              text: text,
              type: (response.status == 200) ? "success" : "danger",
            },
          };
          const toast = useToast();
          toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
        }).catch(function(error){
          console.log({error});
        })
      },
      addEmail(evn){
        const countEmails = this.horarioNuevoCliente.data.listemail.length
        if(countEmails < 5) this.horarioNuevoCliente.data.listemail.push("")
      },
      removeEmail(data){
        const index = this.horarioNuevoCliente.data.listemail.indexOf(data);
        if (index > -1) this.horarioNuevoCliente.data.listemail.splice(index, 1)
      },
      openEmail(data){
        const $this = this
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        this.horarioNuevoCliente.loading = true
        this.horarioNuevoCliente.showModal = true
        this.horarioNuevoCliente.idDistribuidora = Number(data.idDistribuidora)
        this.horarioNuevoCliente.distribuidora = data.razonSocial
        const dataSend = this.horarioNuevoCliente
        const urlApiCronjob = process.env.VUE_APP_API_URL+"/parametro/cronjob/nuevosclientes"
        axios.post(urlApiCronjob,dataSend,config)
        .then(function (response){
          const resData = response.data[0]
          resData["listemail"] = resData.email.split(";")
          const newHour = resData.horario.split("-")
          resData["inicio"] = newHour[0].trim()
          resData["cierre"] = newHour[1].trim()
          $this.horarioNuevoCliente.data = resData
          $this.horarioNuevoCliente.loading = false
        }).catch(function(error){
          console.log({error});
          $this.horarioNuevoCliente.loading = false
          $this.horarioNuevoCliente.showModal = false
        })
      },
      submitHorarioEmail(evn){
        const $this = this
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        this.horarioNuevoCliente.loading = true
        const newEmails = this.horarioNuevoCliente.data.listemail.join(";")
        this.horarioNuevoCliente.data.email = newEmails
        this.horarioNuevoCliente.data.horario = `${this.horarioNuevoCliente.data.inicio} - ${this.horarioNuevoCliente.data.cierre}`
        const dataSend = this.horarioNuevoCliente.data
        const urlApiUpdatedHorario = process.env.VUE_APP_API_URL+"/parametro/cronjob/nuevosclientes/actualizar"
        axios.post(urlApiUpdatedHorario,dataSend,config)
        .then(function (response){
          const text =(response.status == 200) ? "Se actulizaron las correos" : "Hubo un error";
          const icon ="ni ni-bell-55";
          const content={
            component: Notification,
            props: {
              icon: icon,
              text: text,
              type: (response.status == 200) ? "success" : "danger",
            },
          };
          const toast = useToast();
          toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
          $this.horarioNuevoCliente.loading = false
          $this.horarioNuevoCliente.showModal = false
        }).catch(function(error){
          console.log({error});
        })
      },
    },
    data() {
      return {
        distribuidoraIdCurrent: null,
        distribuidoras: [],
        loading: false,
        configDate: {
          altInput: false,
          allowInput: true,
          noCalendar: true,
          enableTime: true,
          locale: Spanish, // locale for this instance only
        },
        tableColumns: [
          {
            prop: "razonSocial",
            label: "Distribuidora",
          },
        ],
        horarioNuevoCliente:{
          distribuidora: "",
          idDistribuidora: 0,
          showModal: false,
          loading: true,
          data: {
            id: "",
            asunto: "",
            email: "",
            horario: "",
            intervalo: 2,
            ultimaEjecucion: "",
            siguienteEjecucion: "",
            listemail: []
          }
        },
        intervalos:[
          {
            id: 1,
            value: "30m"
          },
          {
            id: 2,
            value: "1h"
          },
          {
            id: 3,
            value: "1h 30m"
          },
          {
            id: 4,
            value: "2h"
          },
          {
            id: 5,
            value: "2h 30m"
          },
          {
            id: 6,
            value: "3h"
          },
          {
            id: 7,
            value: "3h 30m"
          },
          {
            id: 8,
            value: "4h"
          },
        ],
      };
    },
  };
</script>

