<template>
  <div class="ui-select" v-click-outside="close" :style="[cssProps]" :class="{ disabled }">
    <button class="ui-select-val" aria-expanded="true" aria-haspopup="listbox" @click="toggleShow" :disabled="disabled"
      v-if="type == 'dropdown'" aria-controls="ui-listbox" :class="[`view-${view}`]">
      <span class="text-truncate">
        {{ selectedOption ?? (view == "btn" ? placeholder : "") }}
      </span>
      <slot name="icon" v-if="view == 'btn'">
        <i class="laive-i icon-arrow-down" :class="{ open: show }"></i>
      </slot>
    </button>
    <button class="ui-select-val" aria-expanded="true" aria-haspopup="listbox" @click="toggleShow" :disabled="disabled"
      v-if="type == 'input'">
      <input :placeholder="placeholder" :value="value" ref="input-select" @input="changeValue" />
      <slot name="icon">
        <i class="laive-i icon-search" />
      </slot>
    </button>
    <Transition name="list">
      <ul v-if="show && options.length > 0" className="ui-select-list scroller" role="listbox" aria-orientation="vertical"
        tabindex="0">
        <li v-for="option in options" @click.stop="handleSelect(option)" role="option" tabindex="0"
          :aria-selected="option.value == selected">
          {{ option.text }}
        </li>
      </ul>
    </Transition>
  </div>
</template>
<script>
import { PropsHandler, ThemeProps } from "./utils"
export default {
  props: {
    type: {
      type: String,
      default: "dropdown",
      validator(typ) {
        return ["dropdown", "input"].includes(typ);
      }
    },
    value: String,
    isOpen: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: []
    },
    placeholder: {
      type: String,
      default: "Selecciona..."
    },
    view: {
      type: String,
      default: "btn"
    },
    automatic: {
      type: Boolean,
      default: true
    },
    z: [String, Number],
    hvBg: String,
    hvTextColor: String,
    selected: [String, Number],
    ...ThemeProps.props,
  },
  emits: ["update:selected", "update:isOpen", "update:value"],
  data() {
    return {
      internalOpen: this.isOpen
    }
  },
  computed: {
    show() {
      return this.automatic ? this.internalOpen : this.isOpen
    },
    selectedOption() {
      return this.options.find((opt) => opt.value == this.selected)?.text
    },
    cssProps() {
      return {
        ...ThemeProps.style(this.$props),
        "--ui-hv-bg-color": PropsHandler.color(this.hvBg ?? (this.bg ?? "inherit")),
        "--ui-hv-text-color": PropsHandler.color(this.hvTextColor ?? (this.textColor ?? "inherit")),
        "--ui-select-zindex": this.z ?? 9999,
      }
    }
  },
  methods: {
    handleSelect(opt) {
      if (this.type == "input") {
        this.$emit("update:value", opt.text);
      }
      this.$emit("update:selected", opt.value);
      this.close();
    },
    close() {
      if (this.automatic) {
        this.internalOpen = false;
      } else {
        this.$emit("update:isOpen", false);
      }
    },
    changeValue(e) {
      this.$emit("update:value", e.target.value);
    },
    toggleShow() {
      if (this.disabled) return;
      if (this.type == "input") {
        this.$refs["input-select"]?.focus();
      }
      if (this.automatic) {
        this.internalOpen = !this.internalOpen;
      } else {
        this.$emit("update:isOpen", !this.show);
      }
    }
  }
}

</script>


<style scoped>
/* Transition behavior */
.list-enter-active,
.list-leave-active {
  transition: transform 0.1s ease, opacity 0.1s ease;
}

.list-enter-from,
.list-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

i {
  transition: transform 0.3s ease;
}

i.open {
  transform: rotate(180deg);
}

.ui-select {
  background-color: var(--ui-bg);
  width: var(--ui-w);
  height: var(--ui-h);
  position: relative;
}

.ui-select.disabled {
  background-color: #F6F6F6 !important;
}

.ui-select-val,
.ui-select-list li {
  padding-inline: var(--ui-px);
  padding-block: var(--ui-py);
  font-size: var(--ui-fs);
  font-weight: var(--ui-fw);
}

.ui-select-val {
  border: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.8em;
  width: 100%;
  height: 100%;
  font-size: inherith;
  font-weight: inherit;
  background-color: var(--ui-bg);
  color: var(--ui-text-color);
}

.ui-select-val.view-input {
  align-items: end;
  min-height: inherit;
  border-bottom: 1px solid #E3E3E3;
}

.ui-select-val.view-btn {
  border: 1px solid #E3E3E3;
  border-radius: 4px;
}

.ui-select-val input {
  border: 0;
  outline: 0;
  border: 0;
  color: var(--ui-text-color);
  background-color: transparent;
}

.ui-select-list {
  position: absolute;
  width: 100%;
  margin: 0;
  border: 1px solid #E3E3E3;
  border-radius: 0.25em;
  box-shadow: 2px 5px 4px 0px rgba(0, 0, 0, 0.1);
  transform: translateY(100.5%);
  background-color: var(--ui-bg);
  z-index: var(--ui-select-zindex);
  color: var(--ui-text-color);
  list-style: none;
  padding: 0;
  bottom: 0;
  max-height: 10em;
  background-color: inherit;
  font-size: inherith;
  font-weight: inherit;
}

.ui-select-list li {
  cursor: pointer;
  font-size: inherith;
  font-weight: inherit;
}

.ui-select-list li:not(:last-of-type) {
  border-bottom: 1px solid #E3E3E3;
}

.ui-select-list li:hover {
  background-color: var(--ui-hv-bg-color);
  color: var(--ui-hv-text-color);
}
</style>
