<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive validateClient card p-4 mb-0">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <h3>{{principal.nombre}}</h3>
        <p>Código de cliente: <strong>{{principal.codigoCliente}}</strong></p>
        <h3 class="d-flex align-items-center mb-3 mt-3">
          <i class="ni ni-bullet-list-67 mr-2"></i>
          Basicos
        </h3>
        <div class="description">
          <div class="row">
            <div class="col-12 col-md-3">
              <h4 class="title_head">Teléfonos</h4>
              <p class="text">{{datosBasicos.telefono}}</p>
            </div>
            <div class="col-12 col-md-3">
              <h4 class="title_head">RUC</h4>
              <p class="text">{{datosBasicos.ruc}}</p>
            </div>
            <div class="col-12 col-md-3">
              <h4 class="title_head">Tipo de cliente</h4>
              <p class="text">{{datosBasicos.tipoCliente}}</p>
            </div>
            <div class="col-12 col-md-3">
              <h4 class="title_head">Giro</h4>
              <p class="text">{{datosBasicos.giro}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-3">
              <h4 class="title_head">Nombre del mercado</h4>
              <p class="text">{{datosBasicos.mercado}}</p>
            </div>

            <template v-if="principal.isCumple">
              <div class="col-12 col-md-3">
                <h4 class="title_head">Cumpleaños</h4>
                <p class="text">{{datosBasicos.fechaNac}}</p>
              </div>
            </template>

            <div class="col-12 col-md-3">
              <h4 class="title_head">direccion</h4>
              <p class="text">{{datosBasicos.direccion}}</p>
            </div>

            <div class="col-12 col-md-3">
              <h4 class="title_head">E-mail</h4>
              <p class="text">{{datosBasicos.email}}</p>
            </div>
          </div>
        </div>

        <hr>

        <h3 class="d-flex align-items-center mb-3">
          <i class="ni ni-briefcase-24 mr-2"></i>
          Gestion
        </h3>
        <div class="description">
          <div class="row">
            <div class="col-12 col-md-3">
              <h4 class="title_head">Segmento</h4>
              <p class="text">{{principal.segmento}}</p>
            </div>
            <div class="col-12 col-md-3">
              <h4 class="title_head">Clúster</h4>
              <p class="text">{{principal.cluster}}</p>
            </div>
            <div class="col-12 col-md-3">
              <h4 class="title_head">Ruta</h4>
              <p class="text">{{datosGestion.ruta}}</p>
            </div>
            <div class="col-12 col-md-3">
              <h4 class="title_head">Mesa</h4>
              <p class="text">{{datosGestion.mesa}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-3">
              <h4 class="title_head">Frencuencia de visitas</h4>
              <p class="text">{{datosGestion.frecuenciaVisita}}</p>
            </div>
            <div class="col-12 col-md-3">
              <h4 class="title_head">Día de atención</h4>
              <p class="text">{{datosGestion.diaAtencion}}</p>
            </div>
            <div class="col-12 col-md-3">
              <h4 class="title_head">Vendedor/ra a cargo</h4>
              <p class="text">{{datosGestion.vendedor}}</p>
            </div>
            <div class="col-12 col-md-3">
              <h4 class="title_head">Tenencia y datos de equipos</h4>
              <p class="text">Cantidad de equipos: {{datosGestion.equipos.length}}</p>
              <div class="row">
                <template v-for="team in datosGestion.equipos" :key="team.placa">
                  <div class="col-12 col-md-6">
                    <div class="box_team">
                      <p>{{team.nombre}}</p>
                      <p>
                        Modelo: {{team.modelo}}<br>
                        Lote: {{team.lote}}<br>
                        Placa: {{team.placa}}
                      </p>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>

        <hr>

        <h3 class="d-flex align-items-center mb-3">
          <i class="ni ni-briefcase-24 mr-2"></i>
          Caracteristicas del Cluster
        </h3>
        <template v-if="datosGestion.detalleCluster">
          <div class="row">
            <div class="col-12 col-md-3">
              <h4 class="title_head">¿Acepta pago con crédito o débito?</h4>
              <p class="text">{{(datosGestion.detalleCluster.aceptaDebitoCredito.trim().length) ? datosGestion.detalleCluster.aceptaDebitoCredito.trim().length : "No"}}</p>
            </div>
            <div class="col-12 col-md-3">
              <h4 class="title_head">¿Cliente permite que active su bodega?</h4>
              <p class="text">{{(datosGestion.detalleCluster.permiteActivarBodega.trim().length) ? datosGestion.detalleCluster.permiteActivarBodega.trim().length : "No"}}</p>
            </div>
            <div class="col-12 col-md-3">
              <h4 class="title_head">El negocio se encuentra en</h4>
              <p class="text">{{datosGestion.detalleCluster.negocioUbicadoEn}}</p>
            </div>
            <div class="col-12 col-md-3">
              <h4 class="title_head">Estructura del negocio</h4>
              <p class="text">{{datosGestion.detalleCluster.estructuraNegocio}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-3">
              <h4 class="title_head">¿Existe otro negocio con un mejor mix cerca?</h4>
              <p class="text">{{(datosGestion.detalleCluster.negocioMejorMix.trim().length) ? datosGestion.detalleCluster.negocioMejorMix.trim().length : "No"}}</p>
            </div>
            <div class="col-12 col-md-3">
              <h4 class="title_head">Mix de producto que vende</h4>
              <p class="text">{{datosGestion.detalleCluster.mixProducto}}</p>
            </div>
            <div class="col-12 col-md-3">
              <h4 class="title_head">Nivel de mix de productos (No solo Laive)</h4>
              <p class="text">{{datosGestion.detalleCluster.nivelMix}}</p>
            </div>
            <div class="col-12 col-md-3">
              <h4 class="title_head">Tamaño del negocio</h4>
              <p class="text">{{datosGestion.detalleCluster.tamanioNegocio}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-3">
              <h4 class="title_head">¿Tiene buena relacion con el cliente?</h4>
              <p class="text">{{(datosGestion.detalleCluster.buenaRelacionCliente.trim().length) ? datosGestion.detalleCluster.buenaRelacionCliente.trim().length : "No"}}</p>
            </div>
          </div>
        </template>

      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import TabsLayout from "@/components/Tabs/TabsLayout";
import Tabs from "@/components/Tabs/Tabs";
import Tab from "@/components/Tabs/Tab";
import TabPane from "@/components/Tabs/TabPane";
import BasePagination from "@/components/BasePagination";
export default {
  components: {
    BasePagination,
    RouteBreadCrumb,
    TabsLayout,
    Tabs,
    Tab,
    TabPane,
  },
  created(){
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      axios.get(process.env.VUE_APP_API_URL+"/cliente/detalle/"+this.$route.params.id,config)
      .then(function (response){
        document.querySelector(".form_laive").classList.remove("validateClient")
        let data = response.data
        $this.principal = data.principal
        $this.datosBasicos = data.datosBasicos
        $this.datosGestion = data.datosGestion
      })
  },
  data() {
    return {
      principal: "",
      datosBasicos: "",
      datosGestion: {
        segmento: "",
        cluster: "",
        detalleCluster: "",
        ruta: "",
        mesa: "",
        frecuenciaVisita: "",
        diaAtencion: "",
        vendedor: "",
        cantidadEquipos: " ",
        equipos: []
      }
    }
  },
};
</script>
<style lang="scss">
  b, strong{
    font-weight:bold !important;
  }
  .form_laive{
    .nav-pills{
      .nav-link{
        color: #02A54F;
        background-color: white;
        &:hover{
          color: white;
          background-color: #02A54F;
        }
        &.active{
          color: white;
          background-color: #02A54F;
        }
      }
    }
    .description{
      .box_team{
        p{
          font-size: 14px;
          margin:0;
          &:first-child{
            margin-bottom: 0.2rem;
          }
        }
      }
    }
  }
</style>
