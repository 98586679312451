<template>
  <div class="date-picker input-group">
    <DatePicker v-model="modelValue" :config="parsedConfig" @input="handleSelectDate" @on-open="calendarHandler(true)"
      @on-close="calendarHandler(false)" ref="datePicker" :placeholder="placeholder" />
    <template v-if="showIcon">
      <i class="laive-i icon-calendar" @click="openCalendar" v-if="!isOpen" />
      <i class="laive-i icon-x-close" @click="clearDate" v-else />
    </template>
  </div>
</template>
<script>
import { Spanish } from "flatpickr/dist/l10n/es.js"
import DatePicker from "vue-flatpickr-component";
export default {
  components: {
    DatePicker
  },
  emits: ["update:modelValue"],
  props: {
    modelValue: String,
    placeholder: {
      type: String,
      default: "Selecciona..."
    },
    config: {
      type: Object,
      default: {}
    },
    calendarClass: String,
    showIcon: Boolean,
    defaultDate: String,
    altFormat: {
      type: String,
      default: "d/m/Y"
    },
    dateFormat: {
      type: String,
      default: "Y-m-d"
    },
  },
  data() {
    return {
      isOpen: false
    }
  },
  mounted() {
    if (this.calendarClass) {
      this.$refs["datePicker"].fp.calendarContainer.classList.add(this.calendarClass);
    }
  },
  computed: {
    parsedConfig() {
      return {
        altFormat: this.altFormat,
        dateFormat: this.dateFormat,
        defaultDate: this.defaultDate,
        altInput: true,
        locale: Spanish,
        ...this.config,
      }
    }
  },
  methods: {
    handleSelectDate(ev) {
      this.$emit("update:modelValue", ev.target.value)
    },
    calendarHandler(open) {
      if (open) this.isOpen = open
      else {
        setTimeout(() => {
          this.isOpen = open;
        }, 100);
      }
    },
    openCalendar() {
      this.$refs["datePicker"].fp.open();
    },
    clearDate() {
      const fp = this.$refs["datePicker"].fp;
      fp.clear();
    },
  }
}
</script>

<style scoped>
.date-picker {
  border: 1px solid #E5E5E5;
  border-radius: 5px;
  font-size: inherit;
  overflow: hidden;
  padding-inline: 0.6em;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
}

.date-picker :deep(input::placeholder) {
  color: black;
  font-weight: inherit;
}

.date-picker :deep(input) {
  outline: none;
  height: 100%;
  border: 0;
  flex: 1;
  padding-block: 0.625em;
  border: 0;
  font-weight: inherit;
  color: black;
  background-color: inherit;
  padding-inline: 0;
}

i {
  cursor: pointer;
}
</style>
