<template>
  <card
    class="no-border-card"
    body-classes="px-0 pb-1 pt-0 mt-0"
    footer-classes="pb-2"
  >
    <template v-slot:header>
      <div class="row">
        <div class="col-12 col-md-6">
          <h3 class="mb-0">{{title}}</h3>
        </div>
        <div class="col-12 col-md-6 text-right">
          <router-link to="/" @click.prevent="createItem"><base-button size="sm" type="info">Crear {{title}}</base-button></router-link>
        </div>
      </div>
    </template>
    <div class="card-body">
      <p v-if="this.$route.query.type == 'preguntaCluster'" class="text" style="font-size: 14px;">{{this.$route.query.name}}</p>
      <el-table
        :data="dataList"
        row-key="idListMaestros"
        header-row-class-name="thead-light"
        empty-text=""
      >
        <el-table-column
          v-for="column in tableColumns"
          :key="column.label"
          v-bind="column"
        >
        </el-table-column>
        <el-table-column min-width="100px" align="right">
          <template v-slot:default="props">
            <div class="d-flex">
              <router-link to="/" @click.prevent="editItem(props.row)" class="btn base-button btn-success btn-sm edit">Editar</router-link>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </card>
  <!-- Edit -->
  <CustomModal
    :show="dataModal.showModal"
    :isHeader="true"
    :isFooter="false"
    :width="600"
    :title="dataModal.title"
    v-on:modal:close="dataModal.showModal = $event" >
    <template v-slot:body>
      <form @submit.prevent="submitEdit" :class="'form_laive '+dataModal.active">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <div class="row mb-0">
          <div class="col-md-12">
            <label class="form-control-label">Nombre:</label>
            <base-input
              name="name_category"
              placeholder="Nombre"
              required="required"
              :value="dataSend.nombre"
              v-model="dataSend.nombre"
            >
            </base-input>
          </div>
          <div v-if="typeMaestro == 'tipocliente'" class="col-md-12">
            <div class="form-group">
              <label class="form-control-label">Giro del Negocio:</label>
              <select v-model="dataSend.valorAsociado" class="form-control" name="giro_edit_select">
                <option selected disabled value="">Todos</option>
                <option
                  v-for="item in gironegocio"
                  :value="item.codigoDato"
                  :key="item.nombre"
                  >
                  {{ item.nombre }}
                </option>
              </select>
            </div>
          </div>
          <div v-if="typeMaestro == 'mes'" class="col-md-12">
            <label class="form-control-label">Días del Mes:</label>
            <base-input
              name="days_create_maestro"
              placeholder="Días"
              required="required"
              :value="dataSend.valorAsociado"
              v-model="dataSend.valorAsociado"
            ></base-input>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <base-checkbox @change="changeStateEdit($event)" :modelValue="dataModal.estadoEdit"><strong>Activo</strong></base-checkbox>
            </div>
          </div>
        </div>
        <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar Maestro</base-button>
      </form>
    </template>
  </CustomModal>
  <!-- Create -->
  <CustomModal
    :show="dataModal.showCreate"
    :isHeader="true"
    :isFooter="false"
    :width="600"
    :title="'Crear '+ title"
    v-on:modal:close="dataModal.showCreate = $event"
  >
    <template v-slot:body>
      <form @submit.prevent="submitCreate" :class="'form_laive '+dataModal.activeCreate">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <div class="row mb-0">
          <div class="col-md-12">
            <label class="form-control-label">Código:</label>
            <base-input
              name="code_create_maestro"
              placeholder="Nombre"
              required="required"
              :value="dataCreate.codigoDato"
              v-model="dataCreate.codigoDato"
            ></base-input>
          </div>
          <div class="col-md-12">
            <label class="form-control-label">Nombre:</label>
            <base-input
              name="name_create_maestro"
              placeholder="Nombre"
              required="required"
              :value="dataCreate.nombre"
              v-model="dataCreate.nombre"
            ></base-input>
          </div>
          <div v-if="typeMaestro == 'tipocliente'" class="col-md-12">
            <div class="form-group">
              <label class="form-control-label">Giro del Negocio:</label>
              <select v-model="dataCreate.valorAsociado" class="form-control" name="giro_create_select">
                <option selected disabled value="">Todos</option>
                <option
                  v-for="item in gironegocio"
                  :value="item.codigoDato"
                  :key="item.nombre"
                  >
                  {{ item.nombre }}
                </option>
              </select>
            </div>
          </div>
          <div v-if="typeMaestro == 'mes'" class="col-md-12">
            <label class="form-control-label">Días del Mes:</label>
            <base-input
              name="days_create_maestro"
              placeholder="Días"
              required="required"
              :value="dataCreate.valorAsociado"
              v-model="dataCreate.valorAsociado"
            ></base-input>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <base-checkbox @change="changeStateCreate($event)" :modelValue="dataModal.estadoCreate"><strong>Activo</strong></base-checkbox>
            </div>
          </div>
        </div>
        <base-button type="primary" native-type="submit" class="btn-default my-0">Crear</base-button>
      </form>
    </template>
  </CustomModal>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import axios from "axios";
import CustomModal from "./CustomModal.vue";
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
export default {
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    CustomModal,
    useToast,
    Notification
  },
  props:{
      dataList:{
        type: [Array],
      },
  },
  created(){
    this.title = (this.$route.query.type == "preguntaCluster") ? "Pregunta del cluster" : this.$route.query.name
  },
  methods: {
    changeStateEdit(evn){
      this.dataSend.estado = Number(evn.target.checked)
    },
    changeStateCreate(evn){
      this.dataCreate.estado = Number(evn.target.checked)
    },
    getGiros(){
      if(this.gironegocio.length > 0) return
      const $this = this
      const id = "gironegocio"
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      axios.get(process.env.VUE_APP_API_URL+"/maestro/lista/"+id,config)
      .then(function (response){
        $this.gironegocio = response.data
      })
    },
    editItem(data){
      this.dataModal.title = 'Editar: ' + data.nombre
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      if(this.typeMaestro == "tipocliente"){
        this.getGiros()
      }
      let datailMaestro = process.env.VUE_APP_API_URL+"/maestro/detalle/"+data.idRegistro
      document.querySelector(".form_laive").classList.add("validateClient")
      axios.get(datailMaestro,config)
      .then(function (response){
        $this.dataSend = response.data
        $this.dataModal.estadoEdit = Boolean(response.data.estado)
        $this.dataSend.estado = Number(response.data.estado)
        $this.dataModal.showModal = true
        document.querySelector(".form_laive").classList.remove("validateClient")
      }).catch(errors => {
        document.querySelector(".form_laive").classList.remove("validateClient")
      })
    },
    submitEdit() {
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let updatedMaestro = process.env.VUE_APP_API_URL+"/maestro/actualizar"
      this.dataModal.active = "validateClient"
      axios.post(updatedMaestro, this.dataSend,config)
      .then(function (response){
        const text = (response.status == 200) ? "Se actualizó el maestro" : "Hubo un error";
        const icon = "ni ni-bell-55";
        const content = {
          component: Notification,
          props: {
            icon: icon,
            text: text,
            type: (response.status == 200) ? "success" : "danger",
          },
        };
        const toast = useToast();
        toast(content, {
          hideProgressBar: true,
          icon: false,
          closeButton: false,
          position: "top-right",
        });
        $this.dataModal.active = ""
        setTimeout(() => {
          location.reload();
        }, 500);
      }).catch(errors => {
        $this.dataModal.active = ""
      })
    },
    createItem(){
      this.dataModal.showCreate = true
      console.log(this.typeMaestro)
      if(this.typeMaestro == "tipocliente"){
        this.getGiros()
      }
    },
    submitCreate() {
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let createUbigeo = process.env.VUE_APP_API_URL+"/maestro/crear"
      this.dataModal.activeCreate = "validateClient"
      axios.post(createUbigeo, this.dataCreate,config)
      .then(function (response){
        const text = (response.status == 200) ? "Se creó el maestro" : "Hubo un error";
        const icon = "ni ni-bell-55";
        const content = {
          component: Notification,
          props: {
            icon: icon,
            text: text,
            type: (response.status == 200) ? "success" : "danger",
          },
        };
        const toast = useToast();
        toast(content, {
          hideProgressBar: true,
          icon: false,
          closeButton: false,
          position: "top-right",
        });
        $this.dataModal.activeCreate = ""
        setTimeout(() => {
          location.reload();
        }, 500);
      }).catch(errors => {
        $this.dataModal.activeCreate = ""
      })
    },
  },
  data() {
    return {
      tableColumns: [
        {
          prop: "nombre",
          label: "Nombre",
          width: 270,
        },
        {
          prop: "estado",
          label: "Estado",
          width: 120,
        }
      ],
      typeMaestro: this.$route.params.id,
      gironegocio: [],
      dataModal:{
        showModal: false,
        title: "",
        active: "",
        showCreate: false,
        activeCreate: "",
        estadoEdit: "",
        estadoCreate: true
      },
      dataSend: {
        codigoDato: "",
        codigoTipo: "",
        idRegistro: "",
        nombre: "",
        valorAsociado: "",
        estado: ""
      },
      dataCreate:{
        codigoDato: "",
        codigoTipo: this.$route.params.id,
        nombre: "",
        valorAsociado: "",
        estado: 1
      }
    };
  },
}
</script>
