<template>
  <button class="ui-button" :style="[cssProps]">
    <template v-if="iconPosition == 'left'">
      <slot name="icon">
        <i class="laive-i" :class="[icon]" :style="{ marginRight: '0.350em' }" />
      </slot>
    </template>
    <slot>
      {{ text }}
    </slot>
    <template v-if="iconPosition == 'right'">
      <slot name="icon">
        <i class="laive-i" :class="[icon]" :style="{ marginLeft: '0.350em' }" />
      </slot>
    </template>
  </button>
</template>

<script>
import { BTN_TYPE } from "./constants";
import { PropsHandler, ThemeProps } from "./utils";

export default {
  props: {
    icon: String,
    iconPosition: {
      type: String,
      default: "none",
      validator(pos) {
        return ["left", "right", "none"].includes(pos);
      }
    },
    type: {
      type: String,
      default: BTN_TYPE.solid,
      validator(tp) {
        return Object.keys(BTN_TYPE).includes(tp);
      }
    },
    text: String,
    color: String,
    hvTextColor: String,
    hvBg: String,
    borderW: String,
    radius: String,
    ...ThemeProps.textProps,
    ...ThemeProps.containerProps,
  },
  computed: {
    cssProps() {
      const buttonProps = {
        "--ui-border-w": this.borderW ?? "1px",
        "--ui-border-radius": this.radius ?? "5px",
        ...ThemeProps.styleText(this.$props),
        ...ThemeProps.styleContainer(this.$props),
      }

      let cssColor = "unset";
      if (this.color) cssColor = PropsHandler.color(this.color);

      const hvBgColor = PropsHandler.color(this.hvBg ?? cssColor);
      const hvTextColor = PropsHandler.color(this.hvTextColor ?? cssColor);

      // Append the parsed styles
      if (this.type == BTN_TYPE.solid) {
        buttonProps["--ui-text-color"] = "white";
        buttonProps["--ui-button-bg"] = cssColor;
        buttonProps["--ui-button-bg-hover"] = hvBgColor;
        buttonProps["--ui-button-text-hover"] = hvTextColor;
      }
      if (this.type == BTN_TYPE.outline) {
        buttonProps["--ui-text-color"] = cssColor;
        buttonProps["--ui-button-bg"] = "unset";
        buttonProps["--ui-button-border"] = cssColor;
        buttonProps["--ui-button-color"] = cssColor;
        buttonProps["--ui-button-bg-hover"] = hvBgColor;
        buttonProps["--ui-button-text-hover"] = hvTextColor;
      }
      if (this.type == BTN_TYPE.link) {
        buttonProps["--ui-text-color"] = cssColor;
        buttonProps["--ui-button-bg"] = "unset";
        buttonProps["--ui-button-line"] = "underline";
        buttonProps["--ui-button-color"] = cssColor;
        buttonProps.textUnderLineOffset = "2px";
        buttonProps["--ui-button-text-hover"] = hvTextColor;
      }
      return buttonProps;
    }
  }
}


</script>
<style scoped>
.ui-button {
  border-radius: var(--ui-border-radius);
  border-width: var(--ui-border-w);
  border-style: solid;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.1s, color 0.1s, text-decoration-line 0.1s;
  border-color: var(--ui-button-border, transparent);
  background-color: var(--ui-button-bg, unset);
  color: var(--ui-text-color);
  text-decoration-line: var(--ui-button-line, none);
  /* theme styles */
  font-size: var(--ui-fs);
  font-weight: var(--ui-fw);
  padding-inline: var(--ui-px);
  padding-block: var(--ui-py);
}

.ui-button:hover {
  background-color: var(--ui-button-bg-hover, unset);
  color: var(--ui-button-text-hover, unset);
  text-decoration-line: none;
}
</style>
