<template>    
    <VistaJefeOperaciones v-if="usuario.rol ==5"></VistaJefeOperaciones>
    <VistaSubGerente v-if="usuario.rol ==6"></VistaSubGerente>
    <VistaGerente v-if="usuario.rol ==7"></VistaGerente>

  </template>
  <script setup>

import "@/assets/sass/laive/bonificaciones.scss";
import VistaJefeOperaciones from "./JefeOperaciones/Index.vue"
import VistaSubGerente from "./SubGerente/Index.vue"
import VistaGerente from "./Gerente/Index.vue"



  import { ref, computed, watch, onMounted } from "vue";
  
  import { useStore } from "vuex";
  const store = useStore();
    
  const usuario = computed(() => {
  return store.state.auth.user;
})
  
  
  onMounted(() => {
  //obtener usuario y verificar su rol para mostrar la vista correspondiente
  });
  
  
  
  </script>
  <style lang="scss" scoped>
  </style>
  