<template>
  <div
    class="row mx-0 mt-2"
    id="nueva-actividad"
    v-if="liquidacion.esta_creando"
  >
    <div class="col-6 px-0">
      <div class="row mx-0">
        <div class="col-4 px-0">
          <div class="row mx-0 h-100">
            <div
              class="col-3 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              {{ liquidacion.codigo_bonificacion }}
            </div>
            <div
              class="col-3 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              {{ tipo_bonificacion }}
            </div>
            <div
              class="col-6 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              <input
                :type="text"
                :placeholder="placeholder"
                class="form-control pt-0 px-0 py-1 w-100 text-center h-auto font-10 mh-35px"
                v-model="liquidacion.nombre_bonificacion"
              />
            </div>
          </div>
        </div>
        <div class="col-4 px-0">
          <div class="row mx-0 h-100">
            <div class="col-12 py-2 px-1">
              <div class="row mx-0 h-100">
                <div
                  class="col-10 px-1 font-9 d-flex align-items-center justify-content-center"
                >
                  <GeneralDropdown
                    v-model="liquidacion.codigoproductopr"
                    aditional_class="bg-white mh-35px w-100"
                    :options="productos_options"
                    :is_multiple="false"
                    :collapse_tags="true"
                    :puede_seleccionar_todos="false"
                    :resumir_tags="false"
                    :fit_content="true"
                  ></GeneralDropdown>
                </div>
                <div
                  class="col-2 px-0 font-10 text-center d-flex align-items-center justify-content-center"
                >
                  <input
                    type="number"
                    :placeholder="placeholder"
                    class="form-control pt-0 px-0 py-1 w-100 text-center h-auto font-10 mh-35px"
                    v-model="liquidacion.cantidadbhgr"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4 px-0">
          <div class="row mx-0 h-100">
            <div
              class="col-6 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              -
            </div>
            <div
              class="col-6 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              -
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 px-0">
      <div class="row mx-0">
        <div class="col-2 px-0">
          <div class="row mx-0 h-100">
            <div
              class="col-12 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              -
            </div>
          </div>
        </div>

        <div class="col-5 px-0">
          <div class="row mx-0 h-100">
            <div
              class="col-3 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              -
            </div>
            <div
              class="col-3 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              -
            </div>
            <div
              class="col-3 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              -
            </div>
            <div
              class="col-3 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              -
            </div>
          </div>
        </div>
        <div class="col-1 px-0">
          <div class="row mx-0 h-100">
            <div
              class="col-12 px-0 pr-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              <input
                type="number"
                :placeholder="placeholder"
                class="form-control pt-0 px-0 py-1 w-100 text-center h-auto font-10 mh-35px"
                v-model="liquidacion.costo_total_ajustado"
              />
            </div>
          </div>
        </div>

        <div class="col-4 px-0">
          <div class="row mx-0 h-100">
            <div
              class="col-5 px-0 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              <div v-if="liquidacion.estado == constantes.POR_REVISAR">
                <div class="row mx-0 text-color-blue font-weight-600">
                  <div class="col-3 px-0 pr-1 d-flex align-items-center">
                    <i class="far fa-clock font-12"></i>
                  </div>
                  <div class="col-9 px-0 text-left line-height-1">
                    Por revisar
                  </div>
                </div>
              </div>
              <div v-else>
                <Chip
                  :texto="estado_liquidacion"
                  :aditional_class="`chip-message-${estado_liquidacion} text-capitalize`"
                ></Chip>
              </div>
            </div>
            <div
              v-if="!subgerente"
              class="col-7 px-0 pr-1 py-2 text-center d-flex align-items-center justify-content-center"
            >
              <button
                type="button"
                class="btn circle-btn-sm text-danger mx-auto p-0 d-flex align-items-center justify-content-center"
                @click.prevent="liquidacion.esta_creando = false"
              >
                <i class="fas fa-trash-alt font-11"></i>
              </button>
              <button
                type="button"
                @click.prevent="guardarActividad"
                class="btn circle-btn-sm bonificacion-primary-text mx-auto p-0 d-flex align-items-center justify-content-center"
                :disabled="!habilitar_guardado"
              >
                <i class="fas fa-save font-11"></i>
              </button>
              <button
                v-if="!esta_actualizando"
                @click.prevent="confirmarEnvio"
                type="button"
                :disabled="true"
                class="btn circle-btn-sm bonificacion-primary-text mx-auto p-0 d-flex align-items-center justify-content-center"
              >
                <i class="fas fa-check font-11"></i>
              </button>
              <button
                v-if="esta_actualizando"
                type="button"
                class="btn circle-btn-sm bonificacion-primary-text mx-auto p-0 d-flex align-items-center justify-content-center"
              >
                <div class="laive-loader"></div>
              </button>
            </div>

            <div
              v-if="subgerente"
              class="col-5 px-2 font-10 py-2 text-center d-flex align-items-center justify-content-center bonificacion-primary-text"
            >
              {{ liquidacion.codigo_memo }}
            </div>
            <div
              v-if="subgerente"
              class="col-2 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              <button
                type="button"
                @click.prevent="observarLiquidacion"
                class="btn circle-btn-sm text-danger mx-auto p-0 d-flex align-items-center justify-content-center"
                :disabled="!puede_observar"
              >
                <i class="fas fa-times font-11"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Chip from "../../../Bonificaciones/components/Chip.vue";
import GeneralDropdown from "../../../Bonificaciones/components/GeneralDropdown.vue";
import { ref, computed, watch, onMounted } from "vue";
import constantes from "@/model/constantes.js";
import { useStore } from "vuex";
import Swal from "sweetalert2";

const store = useStore();
const { liquidacion, subgerente } = defineProps({
  liquidacion: {
    type: Object,
  },
  subgerente: {
    type: Boolean,
    default: false,
  },
});

store.dispatch("bonificacionUtil/cargarLista", "productos");

const productos_options = computed(() =>
  store.state.bonificacionUtil.productos.map((item) => {
    return {
      value: item.codigofabrica,
      label: `${item.codigofabrica} - ${item.descripcion}`,
    };
  })
);

const tipo_bonificacion = computed(() => {
  switch (liquidacion.tipo_bonificacion) {
    case constantes.TIPO_PROMOCION:
      return "Prom.";

    case constantes.TIPO_COMBO:
      return constantes.COMBO_LABEL;

    case constantes.TIPO_ESCALA:
      return constantes.ESCALA_LABEL;

    case constantes.TIPO_REBATE:
      return constantes.REBATE_LABEL;

    case constantes.TIPO_ACTV_VENTA:
      return "Actv. de Venta";

    default:
      return "-";
  }
});

const puede_observar = computed(() => {
  return liquidacion.estado == constantes.ENVIADO;
});
const estado_liquidacion = computed(() => {
  let resultado = liquidacion.estado;

  if (subgerente && liquidacion.estado == constantes.ENVIADO) {
    resultado = "pendiente";
  }
  return resultado;
});
const habilitar_edicion = ref(false);
const habilitar_guardado = computed(() => {
  const string_costo = `${liquidacion.costo_total_ajustado}`;
  const string_cantidad = `${liquidacion.cantidadbhgr}`;
  const tiene_nombre = liquidacion.nombre_bonificacion?.trim() != "";
  const tiene_costo = string_costo?.trim() != "";
  const tiene_cantidad = string_cantidad?.trim() != "";
  const tiene_producto = liquidacion.codigoproductopr?.trim() != "";
  return tiene_nombre && tiene_costo && tiene_cantidad && tiene_producto;
});
onMounted(() => {});
const esta_actualizando = ref(false);

const guardarActividad = () => {
  esta_actualizando.value = true;
  const mensaje = inicializarMensaje();
  store
    .dispatch("liquidacion/crearActividadVenta", liquidacion)
    .then((result) => {
      if (result.status == 200) {
        mensaje.clase = "chip-message-success";
        mensaje.texto = "Cambios guardados exitosamente.";
      } else {
        mensaje.clase = "chip-message-error";
        mensaje.texto = "Ocurrió un error.";
      }
      habilitar_edicion.value = false;
      esta_actualizando.value = false;
      mostrarMensaje(mensaje);
      reiniciarLiquidacion();
    })
    .catch((err) => {
      mensaje.clase = "chip-message-error";
      mensaje.texto = "La actividad de venta ya se encuentra agregada";
      mostrarMensaje(mensaje);
      esta_actualizando.value = false;
    });
};

const reiniciarLiquidacion = () => {
  liquidacion.nombre_bonificacion = "";
  liquidacion.codigo_bonificacion = "nuevo";
  liquidacion.costo_total_ajustado = "";
  liquidacion.cantidadbhgr = "1";
  liquidacion.codigoproductopr = "";
  liquidacion.esta_creando = false;
};

const inicializarMensaje = () => {
  return {
    id: `msj_${new Date().getTime()}`,
    opacidad: 1,
  };
};

const mostrarMensaje = (mensaje) => {
  store.commit("liquidacion/agregarMensaje", mensaje);
  setTimeout(() => {
    store.commit("liquidacion/cambiarOpacity", mensaje.id);
    store.commit("liquidacion/eliminarMensaje", mensaje.id);
  }, 2000);
};

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-success",
    cancelButton: "btn btn-danger",
  },
  buttonsStyling: false,
});

const confirmarEnvio = () => {
  swalWithBootstrapButtons
    .fire({
      title: "Enviar",
      text: `¿Esta seguro de enviar la liquidacion?`,
      showCancelButton: true,
      confirmButtonText: "Enviar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    })
    .then((result) => {
      if (result.isConfirmed) {
        enviarLiquidacion();
      }
    });
};

//enviarLiquidacion
const enviarLiquidacion = () => {
  actualizarEstado(constantes.ENVIADO);
};
const observarLiquidacion = () => {
  actualizarEstado(constantes.OBSERVADO);
};

const actualizarEstado = (estado) => {
  esta_actualizando.value = true;
  const mensaje = inicializarMensaje();

  const payload = {
    idbonificacionbonhistoricodistribuidora:
      liquidacion.idbonificacionbonhistoricodistribuidora,
    rango_promo_inicio: liquidacion.rango_promo_inicio,
    rango_promo_fin: liquidacion.rango_promo_fin,
    estado: estado,
  };

  store.dispatch("liquidacion/actualizarEstado", payload).then((result) => {
    if (result.status == 200) {
      mensaje.clase = "chip-message-success";
      mensaje.texto = "Cambios guardados exitosamente.";
      liquidacion.estado = estado;
    } else {
      mensaje.clase = "chip-message-error";
      mensaje.texto = "Ocurrió un error.";
    }
    esta_actualizando.value = false;
    mostrarMensaje(mensaje);
  });
};
</script>

<style scoped>
.btn:disabled {
  color: #b8b8b8 !important;
  background: transparent !important;
}
</style>
