<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1 pt-0 mt-0"
          footer-classes="pb-2"
        >
          <template v-slot:header>
            <h3 class="mb-0">Definir última versión del APP</h3>
          </template>
          <div class="card-body">
            <form @submit.prevent="submiCreate">
              <div class="form-group row mb-0">

                <div class="col-md-4">
                  <label class="col-md-12 col-form-label form-control-label">Nombre:</label>
                  <base-input
                    name="name_app"
                    placeholder="Nombre del APP"
                    required
                    :value="dataSend.nombre"
                    v-model="dataSend.nombre"
                  ></base-input>
                </div>

                <div class="col-md-4">
                  <label class="col-md-12 col-form-label form-control-label">Versión:</label>
                  <base-input
                    name="version_app"
                    placeholder="Versión del APP"
                    required
                    :value="dataSend.version"
                    v-model="dataSend.version"
                  ></base-input>
                </div>

                <div class="col-md-4">
                  <label class="col-form-label form-control-label">Fecha de despliegue:</label>
                  <base-input addon-left-icon="ni ni-calendar-grid-58" name="date_app">
                    <flat-picker :config="configDate" class="form-control datepicker" required v-model="dataSend.fecha"></flat-picker>
                  </base-input>
                </div>

                <div class="col-md-4">
                  <label class="col-form-label form-control-label mb-4"> </label>
                  <base-checkbox :modelValue="dataSend.forceUpdate" v-model="dataSend.forceUpdate"><strong>¿Requiere Actualización?</strong></base-checkbox>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label form-control-label">Tipo de aplicación:</label>
                    <select class="form-control" style="text-transform:capitalize;;height: 43px;" name="type_app" required v-model="dataSend.codigoTipo">
                      <option value="" disabled selected>Seleccionar</option>
                      <option
                        v-for="item in typesApp"
                        :value="item.codigoDato"
                        :key="item.idRegistro"
                        >
                        {{ item.nombre }}
                      </option>
                    </select>
                  </div>
                </div>

              </div>
              <base-button type="primary" native-type="submit" class="btn-default my-0">Establecer nueva versión</base-button>
            </form>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
import flatPicker from "vue-flatpickr-component";
import {Spanish} from 'flatpickr/dist/l10n/es.js';
import "flatpickr/dist/flatpickr.css";
export default {
  components: {
    RouteBreadCrumb,
    flatPicker
  },
  created(){
    const $this = this;
    const token = localStorage.getItem("token")
    const config = {
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    };
    let urlApiTypesVersion = process.env.VUE_APP_API_URL+"/maestro/lista/tipoaplicacion"
    const requestTypesVersion = axios.get(urlApiTypesVersion, config);
    axios.all([requestTypesVersion]).then(axios.spread((...responses) => {
      const data = responses[0].data
      $this.typesApp = data
      console.log({data})
      document.querySelector(".form_laive").classList.remove("validateClient")
    })).catch(errors => {
      // react on errors.
      document.querySelector(".form_laive").classList.remove("validateClient")
    })
  },
  methods: {
    submiCreate(values) {
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const validForceUpdate = Number(this.dataSend.forceUpdate);
      this.dataSend.forceUpdate = validForceUpdate
      let createVersion = process.env.VUE_APP_API_URL+"/appversion/crear"
      document.querySelector(".form_laive").classList.add("validateClient")
      axios.post(createVersion, this.dataSend,config)
      .then(function (response){
        document.querySelector(".form_laive").classList.remove("validateClient")
        const text = (response.status == 200) ? "Se estableció la nueva versión" : "Hubo un error";
        const icon = "ni ni-bell-55";
        const content = {
          component: Notification,
          props: {
            icon: icon,
            text: text,
            type: (response.status == 200) ? "success" : "danger",
          },
        };
        const toast = useToast();
        toast(content, {
          hideProgressBar: true,
          icon: false,
          closeButton: false,
          position: "top-right",
        });
        setTimeout(() => {
          $this.$router.replace({name: 'Versiones del App Listado'})
        }, 2000);
      })
      // alert(JSON.stringify(values, null, 2));
    }
  },
  data() {
    return {
      configDate: {
        altInput: true,
        allowInput: true,
        locale: Spanish, // locale for this instance only
      },
      typesApp:[],
      dataSend: {
        nombre: "",
        version: "",
        fecha: "",
        forceUpdate: false,
        codigoTipo: "",
      },
    };
  },
}
</script>
<style lang="scss">
  .dropzone{
    width:200px;
  }
  .dz-message{
    padding: 1rem 1rem !important;
  }
  .dropzone-single.dz-max-files-reached .dz-message{
    background-color: rgba(219, 219, 219, 0.349) !important;
    border: 1px dashed #dee2e6 !important;
    opacity: 1 !important;
  }
  #product_prewview{
    .dz-preview-cover{
      position: relative;
      width:200px;
      padding-bottom: 0.5rem;
    }
  }
</style>
