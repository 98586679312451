<template>
  <div class="row mx-0 mt-2">
    <div class="col-6 px-0">
      <div class="row mx-0 h-100">
        <div class="col-4 px-0">
          <div class="row mx-0 h-100">
            <div
              class="col-3 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              {{ liquidacion.codigo_bonificacion }}
            </div>
            <div
              class="col-3 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              {{ tipo_bonificacion }}
            </div>
            <div
              class="col-6 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              {{ liquidacion.nombre_bonificacion }}
            </div>
          </div>
        </div>
        <div class="col-4 px-0">
          <div class="row mx-0 h-100">
            <div class="col-12 py-2">
              <div class="row mx-0 h-100">
                <div
                  class="col-11 px-1 font-9 text-center d-flex align-items-center justify-content-center"
                >
                {{ liquidacion.codigoproductopr }} - {{ liquidacion.nombreproductocomercialppr ? liquidacion.nombreproductocomercialppr: '-' }}
                </div>
                <div
                  class="col-1 px-1 font-10 text-center d-flex align-items-center justify-content-center"
                >
                  {{ liquidacion.cantidadbhgr>0 ? liquidacion.cantidadbhgr : '' }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4 px-0">
          <div class="row mx-0 h-100">
            <div
              class="col-6 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              {{ es_actvventa ? "-" : cant_bonificable }}
            </div>
            <div
              class="col-6 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              <span v-if="!liquidacion.cant_bonificada_ajustada">
                {{ es_actvventa ? "-" : cant_bonificada }}
              </span>
              <span v-else class="text-muted">
                <del>
                  {{ cant_bonificada }}
                </del>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 px-0">
      <div class="row mx-0 h-100">
        <div class="col-2 px-0">
          <div class="row mx-0 h-100">
            <div
              class="col-12 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              <span
                v-if="!habilitar_edicion || es_actvventa"
                class="text-color-blue font-weight-600"
              >
                {{ cant_bonificada_ajustada }}
              </span>
              <input
                v-else
                type="number"
                :placeholder="placeholder"
                :class="{
                  'border-danger text-danger': !puede_guardar_ajuste,
                }"
                class="form-control pt-0 px-0 py-1 w-100 text-center h-auto font-10"
                v-model="liquidacion.cant_bonificada_ajustada"
              />
            </div>
          </div>
        </div>

        <div class="col-5 px-0">
          <div class="row mx-0 h-100">
            <div
              class="col-3 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              {{ es_actvventa ? "-" : `${eficacia}%` }}
            </div>
            <div
              class="col-3 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              {{ es_actvventa ? "-" : por_revisar }}
            </div>
            <div
              class="col-3 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
            {{ es_actvventa ? "-" : (liquidacion.costo_regalo_und == null ? "-" :`S/${liquidacion.costo_regalo_und}`) }}
            </div>
            <div
              class="col-3 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              <span v-if="!liquidacion.costo_total_ajustado">
                {{ es_actvventa ? "-" : `S/${costo_total}` }}
              </span>
              <span v-else class="text-muted">
                <del>{{ es_actvventa ? "-" : `S/${costo_total}` }} </del>
              </span>
            </div>
          </div>
        </div>
        <div class="col-1 px-0">
          <div class="row mx-0 h-100">
            <div
              class="col-12 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
              v-if="!es_actvventa"
            >
              <span class="text-color-blue font-weight-600">
                {{ costo_total_ajustado }}
              </span>

            </div>
            <div
              class="col-12 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
              v-else
            >
              <span class="text-color-blue font-weight-600" v-if="!habilitar_edicion">
                {{ costo_total_ajustado }}
              </span>
              <input
                v-else
                :type="number"
                class="form-control pt-0 px-0 py-1 w-100 text-center h-auto font-10"
                v-model="liquidacion.costo_total_ajustado"
              />

            </div>
          </div>
        </div>

        <div class="col-4 px-0">
          <div class="row mx-0 h-100">
            <div
              class="col-5 px-0 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              <div v-if="liquidacion.estado == constantes.POR_REVISAR">
                <div class="row mx-0 text-color-blue font-weight-600">
                  <div class="col-3 px-0 pr-1 d-flex align-items-center">
                    <i class="far fa-clock font-12"></i>
                  </div>
                  <div class="col-9 px-0 text-left line-height-1">
                    Por revisar
                  </div>
                </div>
              </div>
              <div v-else>
                <Chip
                  :texto="estado_liquidacion"
                  :aditional_class="`chip-message-${estado_liquidacion} text-capitalize`"
                ></Chip>
              </div>
            </div>
            <div
              v-if="!subgerente"
              class="col-7 px-0 pr-1 py-2 text-center d-flex align-items-center justify-content-center"
            >
              <button
                type="button"
                class="btn circle-btn-sm bonificacion-primary-text mx-auto p-0 d-flex align-items-center justify-content-center"
                v-if="!es_actvventa"
                @click.prevent="descargarExcel"
                :disabled="procesando_descarga"
              >
                <i class="fas fa-download font-11" v-if="!procesando_descarga"></i>
                <div v-else class="laive-loader"></div>
              </button>


              <button
                type="button"
                class="btn circle-btn-sm text-danger mx-auto p-0 d-flex align-items-center justify-content-center"
                v-else
                @click.prevent="confirmarEliminar"
                :disabled="
                  ![constantes.POR_REVISAR, constantes.OBSERVADO].includes(
                    liquidacion.estado
                  ) || habilitar_edicion
                "
              >
                <i class="fas fa-trash-alt font-11"></i>
              </button>

              <button
                v-if="!esta_actualizando && !habilitar_edicion"
                type="button"
                :disabled="
                  ![constantes.POR_REVISAR, constantes.OBSERVADO].includes(
                    liquidacion.estado
                  )
                "
                @click.prevent="habilitarEdicion"
                class="btn circle-btn-sm bonificacion-primary-text mx-auto p-0 d-flex align-items-center justify-content-center"
              >
                <i class="fas fa-edit font-11"></i>
              </button>

              <button
                v-if="habilitar_edicion"
                type="button"
                @click.prevent="ajustarLiquidacion"
                :disabled="!puede_guardar_ajuste"
                class="btn circle-btn-sm bonificacion-primary-text mx-auto p-0 d-flex align-items-center justify-content-center"
              >
                <i class="fas fa-save font-11"></i>
              </button>

              <button
                v-if="habilitar_edicion && !esta_actualizando"
                type="button"
                @click.prevent="cancelarAjuste"
                class="btn circle-btn-sm text-danger mx-auto p-0 d-flex align-items-center justify-content-center"
              >
              <i class="fas fa-times font-11"></i>
              </button>


              <button
                v-if="!esta_actualizando && !habilitar_edicion"
                @click.prevent="confirmarEnvio"
                type="button"
                :disabled="
                  ![constantes.POR_REVISAR, constantes.OBSERVADO].includes(
                    liquidacion.estado
                  ) || habilitar_edicion
                "
                class="btn circle-btn-sm bonificacion-primary-text mx-auto p-0 d-flex align-items-center justify-content-center"
              >
                <i class="fas fa-check font-11"></i>
              </button>
              <button
                v-if="esta_actualizando"
                type="button"
                class="btn circle-btn-sm bonificacion-primary-text mx-auto p-0 d-flex align-items-center justify-content-center"
              >
                <div class="laive-loader"></div>
              </button>
            </div>

            <div
              v-if="subgerente"
              class="col-5 px-2 font-10 py-2 text-center d-flex align-items-center justify-content-center bonificacion-primary-text"
            >
              {{ liquidacion.codigo_memo }}
            </div>
            <div
              v-if="subgerente"
              class="col-2 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              <button
                type="button"
                @click.prevent="observarLiquidacion"
                class="btn circle-btn-sm text-danger mx-auto p-0 d-flex align-items-center justify-content-center"
                :disabled="!puede_observar"
              >
                <i class="fas fa-times font-11"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Chip from "../../../Bonificaciones/components/Chip.vue";
import { ref, computed, watch, onMounted } from "vue";
import constantes from "@/model/constantes.js";
import { useStore } from "vuex";
import Swal from "sweetalert2";

const store = useStore();
const { liquidacion, subgerente } = defineProps({
  liquidacion: {
    type: Object,
  },
  subgerente: {
    type: Boolean,
    default: false,
  },
});
const es_actvventa = computed(() => {
  return liquidacion.tipo_bonificacion == constantes.TIPO_ACTV_VENTA;
});

const tipo_bonificacion = computed(() => {
  switch (liquidacion.tipo_bonificacion) {
    case constantes.TIPO_PROMOCION:
      return "Prom.";

    case constantes.TIPO_COMBO:
      return constantes.COMBO_LABEL;

    case constantes.TIPO_ESCALA:
      return constantes.ESCALA_LABEL;

    case constantes.TIPO_REBATE:
      return constantes.REBATE_LABEL;

    case constantes.TIPO_ACTV_VENTA:
      return "Actv. de Venta";

    default:
      return "-";
  }
});

const valor_ajuste_original = ref(null);

const habilitarEdicion =() =>{
  valor_ajuste_original.value = liquidacion.cant_bonificada_ajustada;  
  habilitar_edicion.value = true;
}

const cancelarAjuste =() =>{
  liquidacion.cant_bonificada_ajustada = valor_ajuste_original.value;  
  habilitar_edicion.value = false;
}

const puede_guardar_ajuste = computed(() => {

  if (liquidacion.cant_bonificada_ajustada!= null && liquidacion.cant_bonificada_ajustada!= undefined && liquidacion.cant_bonificada_ajustada != "") {    
    return liquidacion.cant_bonificada_ajustada <= liquidacion.cant_bonificada;
  }

});

const cant_bonificable = computed(() => {
  return formatearCantidades(liquidacion.cant_bonificable ?? 0);
});

const cant_bonificada = computed(() => {
  return formatearCantidades(liquidacion.cant_bonificada ?? 0);
});

const cant_bonificada_ajustada = computed(() => {
  return liquidacion.cant_bonificada_ajustada
    ? formatearCantidades(liquidacion.cant_bonificada_ajustada)
    : "-";
});
const costo_total_ajustado = computed(() => {
  if (es_actvventa.value) {
    return liquidacion.costo_total_ajustado;
  }
  if (liquidacion.cant_bonificada_ajustada) {
    liquidacion.costo_total_ajustado =
      parseFloat(liquidacion.cant_bonificada_ajustada) *
      parseFloat(liquidacion.costo_regalo_und);
    return formatearCantidades(liquidacion.costo_total_ajustado);
  } else {
    return "-";
  }
});

const por_revisar = computed(() => {
  const cant_bonificable = parseInt(liquidacion.cant_bonificable);
  const cant_bonificada = parseInt(liquidacion.cant_bonificada ?? 0);
  const resultado = cant_bonificada - cant_bonificable;
  return resultado < 0 ? 0 : resultado;
});

const eficacia = computed(() => {
  let resultado = 0;
  const cant_bonificable = parseInt(liquidacion.cant_bonificable);
  const cant_bonificada = parseInt( liquidacion.cant_bonificada_ajustada ?? liquidacion.cant_bonificada);
  if (cant_bonificable > 0) {
    if (cant_bonificada > 0) {
      if (cant_bonificada >= cant_bonificable) {
        resultado = 100;
      } else {
        resultado = ((cant_bonificada * 100) / cant_bonificable).toFixed(2);
      }
    }
  }
  return resultado;
});

const costo_total = computed(() => {
  const resultado = liquidacion.costo_total
    ? liquidacion.costo_total
    : parseInt(liquidacion.cant_bonificada ?? 0) *
      liquidacion.costo_regalo_und;
  return formatearCantidades(resultado);
});

const formatearCantidades = (param) => {
  const cantidad = parseFloat(param);
  return cantidad.toLocaleString("en-US");
};
const puede_observar = computed(() => {
  return liquidacion.estado == constantes.ENVIADO;
});
const estado_liquidacion = computed(() => {
  let resultado = liquidacion.estado;

  if (subgerente && liquidacion.estado == constantes.ENVIADO) {
    resultado = "pendiente";
  }
  return resultado;
});
const habilitar_edicion = ref(false);
onMounted(() => {
  
});

const esta_actualizando = ref(false);

const ajustarLiquidacion = () => {
  esta_actualizando.value = true;
  const mensaje = inicializarMensaje();

  const payload = {
    cant_bonificada_ajustada: liquidacion.cant_bonificada_ajustada??0,
    costo_total_ajustado: liquidacion.costo_total_ajustado,
    idbonificacionbonhistoricodistribuidora:
      liquidacion.idbonificacionbonhistoricodistribuidora,
    rango_promo_inicio: liquidacion.rango_promo_inicio,
    rango_promo_fin: liquidacion.rango_promo_fin,
  };

  store
    .dispatch("liquidacion/actualizarLiquidacion", payload)
    .then((result) => {
      if (result.status == 200) {
        mensaje.clase = "chip-message-success";
        mensaje.texto = "Cambios guardados exitosamente.";
        valor_ajuste_original.value = liquidacion.cant_bonificada_ajustada;
      } else {
        mensaje.clase = "chip-message-error";
        mensaje.texto = "Ocurrió un error.";
      }

      habilitar_edicion.value = false;
      esta_actualizando.value = false;
      mostrarMensaje(mensaje);
    });
};

const inicializarMensaje = () => {
  return {
    id: `msj_${new Date().getTime()}`,
    opacidad: 1,
  };
};

const mostrarMensaje = (mensaje) => {
  store.commit("liquidacion/agregarMensaje", mensaje);
  setTimeout(() => {
    store.commit("liquidacion/cambiarOpacity", mensaje.id);
    store.commit("liquidacion/eliminarMensaje", mensaje.id);
  }, 2000);
};

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-success",
    cancelButton: "btn btn-danger",
  },
  buttonsStyling: false,
});

const procesando_descarga = ref(false);
const descargarExcel = () =>{
  procesando_descarga.value = true;
  const payload = {
    idbonificacionbonhistoricodistribuidora:
      liquidacion.idbonificacionbonhistoricodistribuidora,
    rango_promo_inicio: liquidacion.rango_promo_inicio,
    rango_promo_fin: liquidacion.rango_promo_fin,
  };

  store.dispatch("liquidacion/DescargarLiquidacionesJOP", payload).then((result) => {
    procesando_descarga.value = false;
        const link = document.createElement('a');
        link.href = result;
        link.target = '_blank';
        link.style.display = 'none';
        link.click();
  });
}

const confirmarEnvio = () => {
  swalWithBootstrapButtons
    .fire({
      title: "Enviar",
      text: `¿Esta seguro de enviar la liquidacion?`,
      showCancelButton: true,
      confirmButtonText: "Enviar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    })
    .then((result) => {
      if (result.isConfirmed) {
        enviarLiquidacion();
      }
    });
};
const confirmarEliminar = () => {
  swalWithBootstrapButtons
    .fire({
      title: "Eliminar",
      text: `¿Esta seguro de eliminar esta actividad de venta?`,
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    })
    .then((result) => {
      if (result.isConfirmed) {
        eliminarActVenta();
      }
    });
};

//enviarLiquidacion
const enviarLiquidacion = () => {
  actualizarEstado(constantes.ENVIADO);
};
const observarLiquidacion = () => {
  actualizarEstado(constantes.OBSERVADO);
};

const actualizarEstado = (estado) => {
  esta_actualizando.value = true;
  const mensaje = inicializarMensaje();

  const payload = {
    idbonificacionbonhistoricodistribuidora:
      liquidacion.idbonificacionbonhistoricodistribuidora,
    rango_promo_inicio: liquidacion.rango_promo_inicio,
    rango_promo_fin: liquidacion.rango_promo_fin,
    estado: estado,
  };

  store.dispatch("liquidacion/actualizarEstado", payload).then((result) => {
    if (result.status == 200) {
      mensaje.clase = "chip-message-success";
      mensaje.texto = "Cambios guardados exitosamente.";
      liquidacion.estado = estado;
    } else {
      mensaje.clase = "chip-message-error";
      mensaje.texto = "Ocurrió un error.";
    }
    esta_actualizando.value = false;
    mostrarMensaje(mensaje);
  });
};

const eliminarActVenta = () => {
  esta_actualizando.value = true;
  const mensaje = inicializarMensaje();

  const payload = {
    idbonificacionbonhistoricodistribuidora:
      liquidacion.idbonificacionbonhistoricodistribuidora,
    rango_promo_inicio: liquidacion.rango_promo_inicio,
    rango_promo_fin: liquidacion.rango_promo_fin
  };

  store.dispatch("liquidacion/eliminarActVenta", payload).then((result) => {
    if (result.status == 200) {
      mensaje.clase = "chip-message-success";
      mensaje.texto = "Cambios guardados exitosamente.";
    } else {
      mensaje.clase = "chip-message-error";
      mensaje.texto = "Ocurrió un error.";
    }
    esta_actualizando.value = false;
    mostrarMensaje(mensaje);
  });
};
</script>

<style scoped>
.btn:disabled {
  color: #b8b8b8 !important;
  background: transparent !important;
}
</style>
