<template>
<div class='right-sidebar show' v-if='show'>
    <!-- filtros avanzados -->
    <div class='card no-border info-client'>
        <div class='card-header d-flex position-relative'>
            <h4 class="card-title m-0">Información del cliente</h4>
            <a class="btn-close ml-auto" @click='close()'><i class='fa fa-times'></i></a>
        </div>
        <div class='card-body'>
            <div class='row'>
                <div class='col-12' >
                    <div class ='top-panel'>
                        <div :tipo="current.iconType" class='order' ><span>{{(suggest ? current?.orden : current?.cvOrden) }}</span></div>
                        <img class='icono' :src='current?.giroImagen' />
                        <span class='type'>{{current?.giro}}</span>
                        <span :color='current?.segmentoColor' class='right-button'><span>{{current?.segmento}}</span></span>
                    </div>
                    <h4 class='client-title'>{{current?.nombre}}</h4>
                    <h5 class='client-code'><strong>Cód</strong>{{current?.codigo}}</h5>
                    <div class='client-direction'>
                        <label>Dirección</label>
                        <p>{{current?.direccion}}</p>
                    </div>
                    <div class='client-compra'>
                        <div class='left'>
                            <label class='title'>Compras mes actual</label>
                            <p class='amount'>S/{{current?.venta?.mesActual}}</p>
                        </div>
                        <div class='right'>
                            <label class='title' >Compras mes anterior</label>
                            <p class='amount' >S/{{current?.venta?.mesAnterior}}</p>
                        </div>
                    </div>
                    <h4 class='client-title'>Actividad acumulada del mes</h4>
                    <div class='client-deuda'>
                        <span class='text-left'>Deuda total</span>
                        <span class='text-right'>S/{{current?.resumen?.deuda}}</span>
                    </div>
                    <div class='client-card'>
                        <div class='row'>
                            <div class='col-3 pr-1'>
                                <div class='item'>
                                    <label class='title'>Número pedidos</label>
                                    <p class='valor'>{{current?.resumen?.pedidos}}</p>
                                </div>
                            </div>
                            <div class='col-3 pl-1 pr-1'>
                                <div class='item'>
                                    <label class='title'>Total cobranzas</label>
                                    <p class='valor'>{{current?.resumen?.cobranza}}</p>
                                </div>
                            </div>
                            <div class='col-3 pl-1 pr-1'>
                                <div class='item'>
                                    <label class='title'>Total devolución</label>
                                    <p class='valor'>{{current?.resumen?.devoluciones}}</p>
                                </div>
                            </div>
                            <div class='col-3 pl-1'>
                                <div class='item'>
                                    <label class='title'>Total no pedidos</label>
                                    <p class='valor'>{{current?.resumen?.noPedidos}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class='overflow-right-sidebar' @click='close()' v-if='show'></div>
</template>

<script>
import SelectButton from './SelectButton';
import * as moment from 'moment';
export default {
    props: {
        show: false,
        suggest:false,
        current:{
            resumen:{},
            venta:{}
        }
    },
    components: {SelectButton},
    created(){
    },
    methods: {
        close() {
            this.$emit("change", 'close',false);
        },
        clear() {
            this.$emit("change", 'clear', true);
        },
    },
    filters:{
        capitalize:function(s){
            if(s.length > 1){
                return s.charAt(0).toUpperCase() + s.slice(1)
            } else { return s;}
        }
    },
    watch: {
      	show: function(a, b) {

          if(a == true){

          }
        }
    },
    data: () => {
        return {
        }
    }
};
</script>

<style lang="scss">
.info-client {
    .top-panel {
        display: flex;
        align-items: center;
        .order {


            width: 24px;
            height: 24px;

            border-radius: 100px;
            display: flex;
            align-items: center;
            text-align: center;
            margin-right: 20px;
            span {
                font-family: Signika;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 26px;
                letter-spacing: 0.2px;
                color: #FFFFFF;
                opacity: 0.9;
                margin-left: auto;
                margin-right: auto;
            }

            &[tipo="red"]{
                background:#ed1f27;
            }
            &[tipo="orange"]{
                background:#f78e2e;
            }
            &[tipo="green"]{
                background:#007841;
            }
            &[tipo="gray"]{
                background:#b8b8b8;
            }
        }

        .icono {
           width:18px;
           height:18px;

        }

        .type {
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 8px;
            letter-spacing: 0.2px;
            color: #6E6E6E;
            opacity: 0.9;
        }
        .right-button {
            padding: 3px 8px;
            margin-left: auto;
            height: 22px;

            border-radius: 10px;
            display: flex;

            &[color="#C1D42F"]{
                color:#C1D42F;
                border: 1px solid #C1D42F;
                span {
                    color: #C1D42F;
                }
            }
            &[color="#02A54F"]{
                color:#02A54F;
                border: 1px solid #02A54F;
                span {
                    color: #02A54F;
                }
            }
            &[color="#FFB053"]{
                color:#FFB053;
                border: 1px solid #FFB053;
                span {
                    color: #FFB053;
                }
            }
            &[color="#337AD0"]{
                color:#337AD0;
                border: 1px solid #337AD0;
                span {
                    color: #337AD0;
                }
            }
            span {
                font-family: Poppins;
                font-style: normal;
                font-weight: 500;
                font-size: 11px;
                line-height: 16px;
                letter-spacing: 0.2px;
                text-transform: uppercase;

            }
        }
    }
    .client-title {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #1A1818;
        margin-top: 14px;
    }
    .client-code {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #1A1818;

        strong {
            margin-right:5px;
        }
    }

    .client-direction {
        label {
            font-weight: 500;
            color: #6E6E6E;
            font-family: Poppins;
            font-style: normal;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.2px;
        }
        p {
            font-family: Poppins;
            font-style: normal;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.2px;
            margin-bottom:0px;
            font-weight: 300;
            color: #1A1818;
            text-transform: lowercase;
        }
    }

    .client-compra {
        display:flex;
        align-items:center;
        margin-top: 20px;
        .left {
            width: 50%;
            border-right: 1px solid #B8B8B8;
            .title {
                font-family: Poppins;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 12px;
                letter-spacing: 0.2px;
                color: #6E6E6E;
            }
            .amount {
                font-family: Poppins;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                display: flex;
                align-items: center;
                letter-spacing: 0.2px;
                color: #1A1818;
            }
        }
        .right {
            width:50%;
            padding-left: 22px;
            .title {
                font-family: Poppins;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 12px;
                letter-spacing: 0.2px;
                color: #6E6E6E;
            }
            .amount {
                font-family: Poppins;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.2px;
                color: #B8B8B8;
            }
        }


    }

    .client-deuda {
        border: 1px solid #E5E5E5;
        box-sizing: border-box;
        border-radius: 10px;
        width :100%;
        height : 48px;
        display:flex;
        align-items:center;
        padding-left:15px;
        padding-right:15px;
        .text-left {
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.2px;
            color: #1A1818;
        }
        .text-right {
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #ED1C24;
            margin-left:auto;
        }
    }

    .client-card {
        margin-top:10px;
        .item {
            background: #FFFFFF;
            border: 1px solid #E5E5E5;
            box-sizing: border-box;
            border-radius: 10px;
            align-items: center;
            vertical-align: middle;
            padding: 13px 3px;
            height: 83px;
            .title {
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 13px;
                text-align: center;
                letter-spacing: 0.2px;
                color: #6E6E6E;
            }
            .valor {
                margin-left: auto;
                margin-right: auto;
                font-family: Poppins;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #1A1818;
            }
        }
    }
}

.overflow-right-sidebar {
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: #00000054;
    position: fixed;
    left: 0px;
    top: 0px;
}
.right-sidebar {
    position: fixed;
    top: 0px;
    right: 0px;
    width: 390px;
    height: 100%;
    background: white;
    z-index: 10000;
    border-left: 1px solid #dee2e6;
    display: none;
    .space {
        margin-top: 110px;
    }

    &.show {
        display: block !important;
    }

    .btn-close {
        i {
            background: #F3F3F3!important;
            padding: 7px 9px!important;
            border-radius: 100px!important;
            cursor: pointer!important;
            color: #1A1818!important;
        }
    }
}

[aria-expanded="true"] .fa-angle-down {
    transform: rotate(180deg);
}

.card-header {

    i.fa-angle-down {
        position: absolute !important;
        right: 25px !important;
        top: 40% !important;
    }
}

</style>
