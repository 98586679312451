<template>
    <card
      class="no-border-card"
      body-classes="px-0 pb-1 pt-0 mt-0"
      footer-classes="pb-2"
    >
      <template v-slot:header>
        <div class="row">
          <div class="col-12 col-md-6">
            <h3 class="mb-0">{{title}}</h3>
          </div>
          <div class="col-12 col-md-6 text-right">
            <router-link to="/" @click.prevent="createItem"><base-button size="sm" type="info">Crear {{title}}</base-button></router-link>
          </div>
        </div>
      </template>
      <div class="card-body">
        <div class="row mb-3">
          <div class="col-md-3">
            <span class="title_filter mr-2">Departamentos:  </span>
            <select v-model="dataUbigeo.departamento" class="form-control" name="deparment_select" @change="changeDeparment">
              <option selected disabled value="">Todos</option>
              <option
                v-for="item in departamentos"
                :value="item.codigo"
                :key="item.nombre"
                >
                {{ item.nombre }}
              </option>
            </select>
          </div>
          <div class="col-md-3">
            <span class="title_filter mr-2">Provincias:  </span>
            <select v-model="dataUbigeo.provincia" class="form-control" name="province_select" @change="changeProvince">
              <option selected disabled value="">Todos</option>
              <option
                v-for="item in provincias"
                :value="item.codigo"
                :key="item.nombre"
                >
                {{ item.nombre }}
              </option>
            </select>
          </div>
        </div>
        <el-table
          :data="dataTable"
          row-key="idListMaestros"
          header-row-class-name="thead-light"
          empty-text="Debe seleccionar una Provincia"
        >
          <el-table-column
            v-for="column in tableColumns"
            :key="column.label"
            v-bind="column"
          >
          </el-table-column>
          <el-table-column min-width="100px" align="right">
            <template v-slot:default="props">
              <div class="d-flex">
                <router-link to="/" @click.prevent="editItem(props.row)" class="btn base-button btn-success btn-sm edit">Editar</router-link>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </card>
    <!-- Editar -->
    <CustomModal
      :show="dataModal.showModal"
      :isHeader="true"
      :isFooter="false"
      :width="600"
      :title="dataModal.title"
      v-on:modal:close="dataModal.showModal = $event" >
      <template v-slot:body>
        <form @submit.prevent="submitEdit" :class="'form_laive '+dataModal.active">
          <div class="box_loader">
            <div class="loader"></div>
          </div>
          <div class="row mb-0">
            <div class="col-md-6">
              <label class="col-form-label form-control-label">Ubigeo:</label>
              <base-input
                name="ubigeo_category"
                placeholder="Nombre"
                required="required"
                :value="dataSend.NuevoCodigo"
                v-model="dataSend.NuevoCodigo"
              ></base-input>
            </div>
            <div class="col-md-6">
              <label class="col-form-label form-control-label">Nombre:</label>
              <base-input
                name="name_category"
                placeholder="Nombre"
                required="required"
                :value="dataSend.distrito"
                v-model="dataSend.distrito"
              ></base-input>
            </div>
          </div>
          <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar distrito</base-button>
        </form>
      </template>
    </CustomModal>
    <!-- Create -->
    <CustomModal
      :show="dataModal.showCreate"
      :isHeader="true"
      :isFooter="false"
      :width="800"
      title="Crear Mercado"
      v-on:modal:close="dataModal.showCreate = $event"
    >
      <template v-slot:body>
        <form @submit.prevent="submitCreate" :class="'form_laive '+dataModal.activeCreate">
          <div class="box_loader">
            <div class="loader"></div>
          </div>
          <div class="row mb-0">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label">Departamento:</label>
                <select v-model="dataCreate.departamento" class="form-control" name="deparment_create_select" @change="changeDeparment">
                  <option selected disabled value="">Todos</option>
                  <option
                    v-for="item in departamentos"
                    :value="item.codigo"
                    :key="item.nombre"
                    >
                    {{ item.nombre }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label">Provincia:</label>
                <select v-model="dataCreate.provincia" class="form-control" name="province_create_select" @change="changeProvince">
                  <option selected disabled value="">Todos</option>
                  <option
                    v-for="item in dataModal.provincias"
                    :value="item.codigo"
                    :key="item.nombre"
                    >
                    {{ item.nombre }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <label class="form-control-label">Distrito:</label>
              <base-input
                name="name_ubigeo_create"
                placeholder="Nombre"
                required="required"
                :value="dataCreate.distrito"
                v-model="dataCreate.distrito"
              ></base-input>
            </div>
            <div class="col-md-6">
              <label class="form-control-label">Ubigeo:</label>
              <base-input
                name="name_ubigeo_create"
                placeholder="Nombre"
                required="required"
                :value="dataCreate.ubigeo"
                v-model="dataCreate.ubigeo"
              ></base-input>
            </div>
          </div>
          <base-button type="primary" native-type="submit" class="btn-default my-0">Crear Ubigeo</base-button>
        </form>
      </template>
    </CustomModal>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import axios from "axios";
import CustomModal from "./CustomModal.vue";
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
export default {
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    CustomModal,
    useToast,
    Notification
  },
  props:{
  },
  created(){
    const $this = this;
    const token = localStorage.getItem("token")
    const config = {
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    };
    this.title = this.$route.query.name
    let apiDepartamentos = process.env.VUE_APP_API_URL+"/ubigeo/departamentos"
    axios.get(apiDepartamentos,config)
    .then(function(response){
      let data = response.data
      $this.departamentos = data
      document.querySelector(".form_laive").classList.remove("validateClient")
    }).catch(errors => {
      // react on errors.
      document.querySelector(".form_laive").classList.remove("validateClient")
    })
  },
  methods: {
    changeUbigeo(evt){
      console.log(evt.target.value)
      this.dataSend.NuevoCodigo = evt.target.value
    },
    editItem(data){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      document.querySelector(".form_laive").classList.add("validateClient")
      let detailDistrit = process.env.VUE_APP_API_URL+"/ubigeo/detalle/"+data.codigo
      this.dataModal.title = 'Editar distrito: ' + data.nombre
      axios.get(detailDistrit,config)
      .then(function(response){
        let data = response.data
        data.NuevoCodigo = data.ubigeo
        $this.dataModal.showModal = true
        $this.dataSend = data
        console.log({data})
        document.querySelector(".form_laive").classList.remove("validateClient")
      }).catch(errors => {
        // react on errors.
        document.querySelector(".form_laive").classList.remove("validateClient")
      })
    },
    changeDeparment(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let apiProvincias = process.env.VUE_APP_API_URL+"/ubigeo/provincias"
      let dataSend;
      if($this.dataModal.showCreate){
        dataSend = {
          departamento: this.dataCreate.departamento,
          provincia: this.dataCreate.provincia
        }
      }else{
        dataSend = this.dataUbigeo
      }
      document.querySelector(".form_laive").classList.add("validateClient")
      axios.post(apiProvincias,dataSend,config)
      .then(function(response){
        let data = response.data
        $this.provincias = data
        $this.dataUbigeo.provincia = ""
        $this.dataTable = []
        if($this.dataModal.showCreate) $this.dataModal.provincias = data
        document.querySelector(".form_laive").classList.remove("validateClient")
      }).catch(errors => {
        document.querySelector(".form_laive").classList.remove("validateClient")
      })
    },
    changeProvince(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let apiProvincias = process.env.VUE_APP_API_URL+"/ubigeo/distritos"
      let dataSend;
      if($this.dataModal.showCreate){
        dataSend = {
          departamento: this.dataCreate.departamento,
          provincia: this.dataCreate.provincia
        }
      }else{
        dataSend = this.dataUbigeo
      }
      document.querySelector(".form_laive").classList.add("validateClient")
      axios.post(apiProvincias,dataSend,config)
      .then(function(response){
        let data = response.data
        if($this.dataModal.showCreate == false) $this.dataTable = data
        document.querySelector(".form_laive").classList.remove("validateClient")
      }).catch(errors => {
        document.querySelector(".form_laive").classList.remove("validateClient")
      })
    },
    submitEdit(submitEvent) {
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let updatedMaestro = process.env.VUE_APP_API_URL+"/ubigeo/actualizar"
      this.dataModal.active = "validateClient"
      axios.post(updatedMaestro, this.dataSend,config)
      .then(function (response){
        const text = (response.status == 200) ? "Se actualizó el maestro" : "Hubo un error";
        const icon = "ni ni-bell-55";
        const content = {
          component: Notification,
          props: {
            icon: icon,
            text: text,
            type: (response.status == 200) ? "success" : "danger",
          },
        };
        const toast = useToast();
        toast(content, {
          hideProgressBar: true,
          icon: false,
          closeButton: false,
          position: "top-right",
        });
        $this.dataModal.active = ""
        setTimeout(() => {
          location.reload();
        }, 1500);
      }).catch(errors => {
        $this.dataModal.active = ""
      })
    },
    createItem(){
      this.dataModal.showCreate = true
    },
    submitCreate() {
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let createUbigeo = process.env.VUE_APP_API_URL+"/ubigeo/crear"
      this.dataModal.activeCreate = "validateClient"
      axios.post(createUbigeo, this.dataCreate,config)
      .then(function (response){
        const text = (response.status == 200) ? "Se creó el Ubigeo" : "Hubo un error";
        const icon = "ni ni-bell-55";
        const content = {
          component: Notification,
          props: {
            icon: icon,
            text: text,
            type: (response.status == 200) ? "success" : "danger",
          },
        };
        const toast = useToast();
        toast(content, {
          hideProgressBar: true,
          icon: false,
          closeButton: false,
          position: "top-right",
        });
        $this.dataModal.activeCreate = ""
        setTimeout(() => {
          location.reload();
        }, 500);
      }).catch(errors => {
        $this.dataModal.activeCreate = ""
      })
    },
  },
  data() {
    return {
      title: "",
      departamentos: [],
      provincias: [],
      distritos: [],
      dataUbigeo: {
        departamento: "",
        provincia: ""
      },
      tableColumns: [
        {
          prop: "nombre",
          label: "Distritos",
          width: 300,
        }
      ],
      dataTable: [],
      dataModal:{
        showModal: false,
        title: "",
        active: "",
        showCreate: false,
        activeCreate: "",
        provincias: [],
      },
      dataSend:{
        codigo: "",
        nombre: ""
      },
      dataCreate:{
        ubigeo: "",
        distrito: "",
        provincia: "",
        departamento: ""
      },
    };
  },
}
</script>
