<template>
<div class="content">
    <base-header class="pb-6">
        <div class="row align-items-center pb-4">
            <div class="col-lg-6 col-7">
                <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                    <route-bread-crumb></route-bread-crumb>
                </nav>
            </div>
            <div class="col-lg-6 col-5 d-none d-md-inline-block">
                <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
            </div>
        </div>
    </base-header>
    <div class="container-fluid mt--6">
        <div class="form_laive " :class="{validateClient:isLoading}">
            <!--validateClient-->
            <div class="box_loader">
                <div class="loader"></div>
            </div>
            <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
                <template v-slot:header>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <h3 class="mb-0">Listado de Notificaciones</h3>
                        </div>
                        <div class="col-12 col-md-6 text-right">

                        </div>
                    </div>
                </template>
                <div>
                    <div class="row px-3">
                        <div class="col-12 mb-3">
                            <div class="row">
                                <div class="col">
                                    <template v-if="this.user.rol == 3 || this.user.rol == 4">
                                        <div class="title_filter mr-2">
                                            <div>Distribuidora:</div> <strong>{{distribuidoraName}}</strong>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <span class="title_filter mr-2">Distribuidoras: </span>
                                        <select v-model='filter.idDistribuidora' class="form-control" style="display: inline-block;height: 43px;" name="distributor_select" @change="selectChangeDistribuidora">
                                            <option value="0" selected>Todos</option>
                                            <option v-for="item in distribuidoras" :value="item.idDistribuidora" :key="item.idDistribuidora">
                                                {{ item.razonSocial }}
                                            </option>
                                        </select>
                                    </template>
                                </div>
                                <div class="col">
                                    <template v-if="this.user.rol == 4">
                                        <span class="title_filter mr-2">Supervisor:<br><strong>{{supervisorName}}</strong></span>
                                    </template>
                                    <template v-else>
                                        <span class="title_filter mr-2">Supervisor: </span>
                                        <select v-model='filter.idSupervisor' class="form-control" id="supervisor_select" style="display: inline-block;height: 43px;" name="supervisor_select" @change="selectChangeSupervisor">
                                            <option value="">Todos</option>
                                            <option v-for="item in supervisores" :value="item.codigoSupervisor" :key="item.codigoSupervisor">
                                                {{ item.nombre }}
                                            </option>
                                        </select>
                                    </template>
                                </div>
                                <div class="col">
                                    <span class="title_filter mr-2">Vendedor: </span>
                                    <select v-model='filter.idVendedor' class="form-control" id="vendedor_select" style="display: inline-block;height: 43px;" name="vendedor_select" @change="selectChangeVendedor">
                                        <option value="">Todos</option>
                                        <option v-for="item in vendedores" :value="item.codigoVendedor" :key="item.codigoVendedor">
                                            {{ item.nombre }} {{ item.apellidos }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-2 mb-3 d-none">
                            <div class="mr-2">
                                <span class="title_filter">Paginación: </span>
                                <el-select class="select-primary pagination-select" v-model="lTable.pagination.perPage" style="width:80px" placeholder="Per page">
                                    <el-option class="select-primary" v-for="item in lTable.pagination.perPageOptions" :key="item" :label="item" :value="item">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <!-- Real Table -->
                    <div class="el-table notoverflow table-bell">
                        <table width="100%" border="0">
                            <thead class="el-table__header-wrapper">
                                <th v-for="(item, i) in lTable.columns" :key="item.prop" :style="(i == 1) ? `` : ``">
                                    {{ item.label }}
                                </th>
                            </thead>
                            <tbody>
                                <template v-if="lTable.data">
                                  <template v-for="(value) in lTable.data" :key="value.razonSocial">
                                      <tr >
                                          <td class='font-weight-bold'>{{value.codigoVendedor}}</td>
                                          <td>{{value.nombreCompleto}}</td>
                                          <td >
                                              <div class='bell-action' @click='showNotification(value)'>
                                                  <span class='button' >
                                                      {{value.total}}
                                                      <i class='fa fa-bell'></i>
                                                  </span>
                                                  <span class='dropdown'>
                                                      <i class="fas fa-angle-down"></i>
                                                  </span>
                                              </div>
                                          </td>
                                      </tr>
                                      <tr class='form_laive' v-if="value.show" :class="{validateClient:value?.loading === true}">
                                          <td colspan='3' :show='value.show' :loading="value?.loading === true" class='pt-0 pb-0 td-bell-container'>
                                              <div class="box_loader"> <div class="loader"></div></div>
                                              <div class='table-bell-detail-container' >
                                                  <table width="100%">
                                                      <thead></thead>
                                                      <tbody>
                                                          <tr v-for="(item) in value.detalle" :key="item.tipoMensaje">
                                                              <td class='td-bell-detalle'>
                                                                  <p>
                                                                    <div :class="{danger:item.tipoMensaje == 2,warning:item.tipoMensaje == 1}"></div>
                                                                    <span>{{item.mensaje}}</span>
                                                                  </p>
                                                                  <a v-if="item.tieneListadoClientes == 'SI'" @click="showDetail(item)">Revísalo aquí</a>
                                                              </td>
                                                              <td class='td-bell-hora'>{{item.hora}}</td>
                                                          </tr>
                                                      </tbody>
                                                  </table>
                                              </div>
                                          </td>
                                      </tr>
                                  </template>
                                </template>
                                <tr v-else>
                                    <td>No se encontraron notificaciones</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <template v-slot:footer>
                    <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                        <div class="">
                            <p class="card-category d-none">
                                Mostrando {{ lTable.from + 1 }} a {{ lTable.to }} de {{ lTable.total }} Notificaciones
                            </p>
                        </div>
                        <default-pagination class="pagination-no-border" v-model="lTable.pagination.currentPage" :per-page="lTable.pagination.perPage" :total="lTable.total" @click="changePaginate">
                        </default-pagination>
                    </div>
                </template>
            </card>
        </div>
    </div>
</div>
</template>

<script>
let fixClickOpenModal = 1
import {
    ElTable,
    ElTableColumn,
    ElSelect,
    ElOption,
    ElInput,
} from "element-plus";
import axios from "axios";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import TabPane from "@/components/Tabs/TabPane";
import BasePagination from "@/components/BasePagination";
import swal from "sweetalert2";
import DashboardNavbar from "../Layout/DashboardNavbar";
import * as moment from 'moment';
import {
    LiquidTable,
    LiquidAlert
} from "../../util/liquid";
import {
    mapActions,
    mapGetters
} from 'vuex';
export default {
    components: {
        DashboardNavbar,
        BasePagination,
        RouteBreadCrumb,
        TabPane,
        [ElSelect.name]: ElSelect,
        [ElOption.name]: ElOption,
        [ElTable.name]: ElTable,
        [ElInput.name]: ElInput,
        [ElTableColumn.name]: ElTableColumn,
    },
    async created() {
        this.lTable = new LiquidTable();
        this.lTable.pagination = {
            perPage: 2,
            currentPage: 1,
            newcurrentPage: 1,
            perPageOptions: [5, 10, 25, 50],
            total: 1,
        };
        this.lTable.columns = [{
                prop: "codigo",
                label: "CÓDIGO",
                minWidth: 120,
            },
            {
                prop: "nombre",
                label: "VENDEDOR",
                minWidth: 160,
            },
            {
                prop: "apellido",
                label: "NOTIFICACIONES",
                minWidth: 160,
            }
        ];

    },
    computed: {
        ...mapGetters({
            authenticated: 'auth/authenticated',
            user: 'auth/user',
        }),
        async getDistribuidora() {

            try{
                let result = await this.getDistributorList();
                this.distribuidoras = result.data ? result.data : [];
            }
            catch(ex){ex}
        },

        async getSupervisor() {

            try{
                this.supervisores =[];
                if(this.filter.idDistribuidora != 0){
                    let result = await this.getSupervisorList(this.filter.idDistribuidora != 0 ? this.filter.idDistribuidora :undefined);
                    this.supervisores = result.data ? result.data : [];
                }
            }
            catch(ex){ex}
        },

        async getVendedor() {
            try{
                this.vendedores=[];
                if(this.filter.idSupervisor  != '' ){
                    let result = await this.getVendedorByDistSuperList({
                        iddistribuidora: this.filter.idDistribuidora,
                        codigosupervisor: this.filter.idSupervisor
                    });
                    this.vendedores = result.data ? result.data : [];
                }
            }
            catch(ex){console.log(ex)}
        },

        async getNotificacion() {
            try{
                this.isLoading = true;
                let result = await this.getNotificacionList({
                    page: this.lTable.pagination.currentPage,
                    idDistribuidora: this.filter.idDistribuidora != 0 ? this.filter.idDistribuidora :undefined,
                    codigoSupervisor: this.filter.idSupervisor,
                    codigoVendedor: this.filter.idVendedor
                });
                if (result.data) {
                    for(let item of result.data.data){
                        item.nombreCompleto = `${item.nombre}${item.apellido}`;
                        item.detalle = [];
                        item.show = false;
                    }
                    this.lTable.pagination.perPage =  20;
                    this.lTable.data = result.data.data;
                }
                this.isLoading = false;
            }
            catch(ex){console.log(ex);this.isLoading = false;}
        },

        distribuidoraName() {
            let x = this.distribuidoras.find(x => x.idDistribuidora == this.filter.idDistribuidora);
            return x ? x.razonSocial : '---';
        },
        supervisorName() {
            let x = this.supervisores.find(x => x.codigoSupervisor == this.filter.idSupervisor);
            /*return x ? x.nombre: 'loading...';*/
            return this.user.nombre;
        },

    },
    methods: {
        ...mapActions({
            getNotificacionList: 'notification/getList',
            getNotificacionDetalleList: 'notification/getDetalle',
            getDistributorList: 'distribuidora/getList',
            getSupervisorList: 'supervisor/getListById',
            getVendedorByDistSuperList: 'vendedor/getListByDistribuidoraSupervisor',
            getDetalleClienteList:'notification/getDetalleCliente'
        }),
        async selectChangeDistribuidora() {
            if (this.filter.idDistribuidora == 0) {
                this.filter.idSupervisor = '';
                this.filter.idVendedor = '';
            }
            await this.getSupervisor;
            await this.getVendedor;
            await this.getNotificacion;

        },

        async selectChangeSupervisor() {
            if (this.filter.idSupervisor == '') this.filter.idVendedor = '';
            await this.getVendedor;
            await this.getNotificacion;

        },

        async selectChangeVendedor() {
            await this.getNotificacion;
        },

        capitalize(s) { if (s.length > 1) { s = s.toLowerCase(); return s.charAt(0).toUpperCase() + s.slice(1)} else { return s;} },

        async showDetail(row) {
            LiquidAlert.loading('Mis notificaciones');
            console.log({ row })

            /*clasificamos los tipos */
            if((''+row.id).indexOf('ap_fuera_ruta') != -1)  {row.title = 'Ventas fuera de la ruta programada'; row.type = 1;}
            if((''+row.id).indexOf('ap_fuera_coordenada') != -1) {row.title = 'Ventas realizadas sin visitar a sus clientes'; row.type = 2; }
            if((''+row.id).indexOf('ap_cliente_top_oro_nopedido') != -1) {row.title = 'Clientes top u oro registrados con “No pedido”'; row.type = 3;}
            if((''+row.id).indexOf('aa_cliente_no_gestionado') != -1)  {row.title = 'Clientes no visitados'; row.type = 4;}
            if (('' + row.id).indexOf('aa_cliente_bajo_ticket_promedio') != -1) { row.title = 'Clientes con ventas bajas'; row.type = 5; }
            if((''+row.id).indexOf('aa_cliente_con_recencia') != -1) {row.title = 'Clientes con recencia'; row.type = 6; }

            //* pruebas => 1-001-ap_fuera_ruta, 1-001-ap_fuera_coordenada,  1-001-aa_cliente_no_gestionado, 1-001-ap_cliente_top_oro_nopedido*/

            let result = await this.getDetalleClienteList({id:row.id});
            console.log(result);
            if(!result.data) return;

            /*construir el contenido de la tabla*/
            let rows = ``;
                for (let item of result.data) {
                    item.hora = item.hora ? moment(item.hora,'HH:mm:ss').format('hh:mm a') : '';
                    rows += `<tr>
                        <td>${this.capitalize(item.nombre)}</td>
                        <td class='${(row.type == 1 ? '':'d-none')}'>${item.hora}</td>
                        <td class='${(row.type == 2 ? '':'d-none')}'>${item.codigo1}</td>
                        <td class='${(row.type == 3 ? '':'d-none')}'>${this.capitalize(item.nombre1)}</td>
                        <td class='${(row.type == 3 ? '':'d-none')}'>${this.capitalize(item.nombre2)}</td>
                        <td class='text-uppercase ${(row.type == 4 ? '':'d-none')}'>${item.nombre1}</td>
                        <td class='${(row.type == 5 ? 'text-center':'d-none')}'>S/${item.codigo2}</td>
                        <td  class='${(row.type == 5 ? 'text-center' : 'd-none')}'><span class="text-danger" style='font-weight:500;'>-S/${item.nombre2}</span><br>Venta S/${item.codigo1}</td>
                        <td class='${(row.type == 6 ? 'text-center' : 'd-none')}'>Hace ${item.nombre1} días</td>
                        <td  class='${(row.type == 6 ? 'text-center' : 'd-none')}'><span class="text-${item.nombre2 == 'PERDIDO' ? 'danger' : 'warning'}" style='font-weight:500;'>${item.nombre2}</td>

                    </tr>`
                }

            let html = `
                <div class='container-bell-modal'>
                    <div class='header'><div class='circle ${(row.tipoMensaje == 1 ? 'warning' : 'danger')}'></div><span class='title'>${row.title}</span></div>
                    <div class='sub-title'>
                      <p>${(row.type == 5 || row.type == 6) ? 'El vend. ' + row.codigoVendedor + '. ' + row.mensaje : row.mensaje}</p>
                    </div>
                    <table class="table-bell-modal ${((result.data.length > 0) ? '':'d-none')}" >
                        <thead>
                            <th>Cliente</th>
                            <th class='${(row.type == 1 ? '':'d-none')}'>Hora</th>
                            <th class='${(row.type == 2 ? '':'d-none')}'>Distancia</th>
                            <th class='${(row.type == 3 ? '':'d-none')}'>Segmento</th>
                            <th class='${(row.type == 3 ? '':'d-none')}'>Motivo</th>
                            <th class='mw-100px ${(row.type == 4 ? '':'d-none')}'>Tipo</th>
                            <th class='${(row.type == 5 ? 'mw-100px':'d-none')}'>Ticket prom.</th>
                            <th class='${(row.type == 5 ? 'mw-120px' : 'd-none')}'>Diferencia.S/</th>
                            <th class='${(row.type == 6 ? 'mw-120px' : 'd-none')}'>Última compra</th>
                            <th class='${(row.type == 6 ? 'mw-120px' : 'd-none')}'>Recencia</th>
                        </thead>
                        <tbody> ${rows} </tbody>
                    </table>
                </div>
                `;

            LiquidAlert.htmlNotTitle(html);
        },

         /*muestra la notificación el expandir */
        async showNotification(row){
            if(row.show){
                row.detalle = [];
                row.show = false;
            } else {
                row.show = true;
                row.loading = true;
                let result = await this.getNotificacionDetalleList({ id: row.id, horaOrden: 'desc'});
                row.detalle = result.data ? result.data : [];
                console.log(row.detalle)
                for(let item of row.detalle){
                    item.hora = moment(item.hora,'HH:mm:ss').format('hh:mm a');
                }
                row.loading = false;
            }
        }
    },

    async mounted() {

        try{
            if (this.user.rol == 3) {
                this.filter.idDistribuidora = this.user.idDistribuidora;
                await this.getDistribuidora;
                await this.getSupervisor;
                await this.getVendedor;
            }
            if (this.user.rol == 4) {
                this.filter.idDistribuidora = this.user.idDistribuidora
                this.filter.idSupervisor = this.user.codigo
                await this.getDistribuidora;
                await this.getSupervisor;
                await this.getVendedor;
            }
            else{
                await this.getDistribuidora;
                await this.getSupervisor;
                await this.getVendedor;
            }
            await this.getNotificacion;
        }
        catch(ex){ }
    },
    data() {
        return {
            isLoading: false,
            lTable: LiquidTable,
            distribuidoras: [],
            supervisores: [],
            vendedores: [],
            notificacionesRow:[],
            filter: {
                idDistribuidora: 0,
                idSupervisor: '',
                idVendedor: ''
            }
        };
    },
};
</script>

<style lang="scss">
.mw-100px{
    min-width: 100px;
}
.mw-120px{
    min-width: 120px;
}
.table-bell {
    .td-bell-container {
        border-top: 2px solid #337AD0;

        &[loading = "true"]{
            height: 52px!important;
        }
    }
    .table-bell-detail-container {
        width:100%;
    }

    .bell-action {
        cursor: pointer;
        .button {
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 2px;
            background: #cfe7ff;
            border-radius: 20px;
            color: #409eff;
            padding-bottom: 2px;
            cursor: pointer;
        }

        .dropdown {
            margin-left: 15px;
            color: #2dce89;
            cursor: pointer;
        }
    }


    .td-bell-detalle {
        padding: 5px 15px!important;
        p {

            font-family: Poppins;
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.2px;
            margin-bottom:0px;
            color: #1A1818;
            display:flex;
            align-items: center;
            div {
                    border-radius: 100px;
                    width: 10px;
                    height: 10px;
                    margin-right: 10px;

                    &.danger {
                        background: #ED1C24;
                    }
                    &.warning {
                        background: #F78D2C;
                    }
            }
            span {

            }
        }
        a {
            margin-left:20px;
            font-family: Poppins;
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.2px;
            color:#2dce89!important;
            cursor:pointer;
        }
        a:hover {
            font-weight: 500;
        }
    }
    .td-bell-hora {
        font-family: Poppins;
        font-style: normal;
        font-weight: 300;
        font-size: 10px;
        line-height: 16px;
        text-align: left;
        letter-spacing: 0.2px;
        color: #6E6E6E;
        width: 28%;

    }
}
.container-bell-modal {
    padding: 0px 26px;
    .header {
        display: flex;
        align-items: center;
        .circle {
            width: 15px;
            height: 15px;
            margin-bottom: auto;
            border-radius: 20px;
            margin-left: auto;
            margin-top: 2px;
            &.danger {
                background: #ED1C24;
            }
            &.warning {
               background: #F78D2C;
            }
        }
        .title {
            margin-left: 10px;
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 19px;
            display: flex;
            align-items: center;
            text-align: center;
            color: rgba(0, 0, 0, 0.87);
            margin-right: auto;
            margin-bottom: 10px;
        }

    }
    .sub-title {
        margin-bottom: 10px;
        p {
            font-family: Poppins;
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 14px;
            text-align: center;
            letter-spacing: 0.2px;
            color: #1A1818;
            margin-bottom: 0px;
        }
    }
    .table-bell-modal {
        width:100%;
        thead {
            tr{
                border-bottom: 2px solid white;
                border-left: 4px solid white;
            }
            th {
                background: #029C4D;
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
                margin: 1px;
                font-family: Poppins;
                font-style: normal;
                font-weight: 500;
                font-size: 11px;
                line-height: 16px;
                text-align: center;
                letter-spacing: 0.2px;
                color: #FFFFFF;
                padding-top: 7px;
                padding-bottom: 7px;
                border-right: 4px solid white;
            }
        }
        tbody {
            tr {
                border-bottom: 2px solid white;
                border-left: 4px solid white;
                td {
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: 300!important;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 0.2px;
                    color: #1A1818;
                    border-right: 4px solid white;
                    text-align: left;
                    padding: 10px;
                    background: #f3f3f3;

                }
            }
        }
    }
}




.table_laive {
    border-collapse: collapse;
    width: 100%;
    margin: 0;

    td,
    th {
        border: 1px solid #f3f3f3;
        text-align: left;
        padding: 8px;
    }

    tr:nth-child(even) {
        background-color: #f3f3f3;
    }

    td {
        a : {
            cursor: pointer !important
        }
    }
}

.swal-wide {
    .swal2-popup {
        width: 56em;
    }
}

.el-table .text-warning {
    color: #fb7f00 !important;
}
</style>
