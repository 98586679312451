<template>
  <div class="ui-input" :class="{ disabled }" @click="focusInput" :style="[cssProps]">
    <input :placeholder="placeholder" :disabled="disabled" ref="input-search" type="text" :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)" />
    <slot v-if="showInput">
      <i class="laive-i icon-search" />
    </slot>
  </div>
</template>

<script>
import { PropsHandler, ThemeProps } from "./utils"
export default {
  props: {
    modelValue: String,
    placeholder: String,
    disabled: Boolean,
    showInput: {
      type: Boolean,
      default: true
    },
    disableBg: String,
    ...ThemeProps.textProps,
    ...ThemeProps.colorProps,
    ...ThemeProps.containerProps
  },
  emits: ["update:modelValue"],
  computed: {
    cssProps() {
      return {
        "--ui-disable-bg": PropsHandler.color(this.disableBg ?? (this.bg ?? "inherit")),
        ...ThemeProps.styleText(this.$props),
        ...ThemeProps.styleContainer(this.$props),
        ...ThemeProps.styleColor(this.$props),
      }
    }
  },
  methods: {
    focusInput() {
      this.$refs["input-search"]?.focus();
    }
  }
}
</script>

<style scoped>
.ui-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.8em;
  border: 1px solid #E3E3E3;
  border-radius: 4px;
  height: var(--ui-h);
  width: var(--ui-w);
  padding-inline: var(--ui-px);
  padding-block: var(--ui-py);
  background-color: var(--ui-bg);
}

.ui-input.disabled {
  background-color: var(--ui-disable-bg);
}

.ui-input input {
  border: 0;
  outline: 0;
  border: 0;
  width: 100%;
  flex: 1;
  background-color: transparent;
  color: var(--ui-text-color);
  font-size: var(--ui-fs);
  font-weight: var(--ui-fw);
}

.ui-input input::placeholder {
  color: var(--ui-text-color);
  font-size: var(--ui-fs);
  font-weight: var(--ui-fw);
}
</style>
