import { mapActions, mapGetters } from 'vuex';
import VendedorService from '../services/VendedorService';
import axios from 'axios';
export default { 
    namespaced: true,
    state:{},
    getters:{},
    mutations:{},
    methods:{ },
    actions:{
        async getListByDistribuidoraSupervisor({state,commit},data) {
            return await VendedorService.getListByDistribuidoraSupervisor(axios,data);
        },
        async getListByDistribuidora({state,commit},data) {
            return await VendedorService.getListByDistribuidora(axios,data);
        },
        
        async getListByMesa({state,commit},data) {
            return await VendedorService.getListByMesa(axios,data);
        },
        async getLocationTimeRoute({state,commit},data) {
            return await VendedorService.getLocationTimeRoute(axios,data);
        },
        async getSupervisionVendedorDetalle({state,commit},data) {
            return await VendedorService.getSupervisionVendedorDetalle(axios,data);
        },
        async getSupervisionClientDetalle({state,commit},data) {
            return await VendedorService.getSupervisionClientDetalle(axios,data);
        },
        async getFiltroAvanzadoParameter({state,commit},data) {
            return await VendedorService.getFiltroAvanzadoParameter(axios,data);
        },
        async getGeocerca({state,commit},data) {
            return await VendedorService.getGeocerca(axios,data);
        }
        
        
        
    }
}