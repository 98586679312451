<template>
  <div
    v-if="tracking.length > 0"
    class="w-100 d-flex flex-column"
    style="gap: 2rem"
  >
    <h1 class="mb-0 title">
      Tracking de incidencia reportada ({{ data.diasTranscurridos }} días
      transcurrido)
    </h1>

    <div class="w-100 tracking">
      <div class="d-flex flex-column tracking-item" style="gap: 1.5rem">
        <div class="step-group">
          <div class="step">1</div>
          <div class="step-line"></div>
        </div>
        <div class="w-100 d-flex flex-column" style="gap: 1rem">
          <h3 class="text-dark tracking-title mb-0">Registro incidencia</h3>
          <div class="tracking-detail-group">
            <div>
              <h4>Estado</h4>
              <p>Pendiente</p>
            </div>
            <div>
              <h4>Fecha:</h4>
              <p>{{ tracking[0].fecha }}</p>
            </div>
            <div>
              <h4>Hora:</h4>
              <p>{{ tracking[0].hora }}</p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="d-flex flex-column tracking-item"
        :class="{ 'tracking-disabled': currentStep < 2 }"
        style="gap: 1.5rem"
      >
        <div class="step-group">
          <div class="step">2</div>
          <div class="step-line"></div>
        </div>
        <div class="w-100 d-flex flex-column" style="gap: 1rem">
          <div class="w-100 d-flex justify-content-between align-items-center">
            <h3 class="tracking-title mb-0">
              Derivo a equipo de mantenimiento
            </h3>
            <button
              v-if="
                (user.rol === 1 || user.rol === 2 || user.rol === 8) &&
                currentStep === 1 &&
                !disableEdit
              "
              @click="editSecondStepToogle"
              class="btn-edit"
            >
              <i class="laive-i icon-edit" />
            </button>
          </div>
          <div class="tracking-detail-group">
            <div>
              <h4>Estado</h4>
              <p>Derivo a ST</p>
            </div>
            <div>
              <h4>Fecha:</h4>
              <p>
                {{ currentStep === 1 ? "--/--/--" : tracking[1].fecha }}
              </p>
            </div>
            <div>
              <h4>Hora:</h4>
              <p>{{ currentStep === 1 ? "--:--:--" : tracking[1].hora }}</p>
            </div>
            <button
              v-if="editSecondStep"
              class="th-btn-float th-btn-pry w-100"
              :disabled="isLoading"
              @click="saveEdit"
            >
              <span>Guardar</span>
            </button>
          </div>
        </div>
      </div>
      <div
        class="d-flex flex-column tracking-item"
        :class="{ 'tracking-disabled': currentStep < 3 }"
        style="gap: 1.5rem"
      >
        <div class="step-group">
          <div class="step">3</div>
          <div class="step-line"></div>
        </div>
        <div class="w-100 d-flex flex-column" style="gap: 1rem">
          <div class="w-100 d-flex justify-content-between align-items-center">
            <h3 class="tracking-title mb-0">Atención de incidencia</h3>
            <button
              v-if="
                (user.rol === 1 || user.rol === 2 || user.rol === 8) &&
                (currentStep === 2 || currentStep === 3) &&
                !disableEdit
              "
              @click="editThirdStepToogle"
              class="btn-edit"
            >
              <i class="laive-i icon-edit" />
            </button>
          </div>
          <div class="tracking-detail-group">
            <div :class="{ 'mb-2': editThirdStep }">
              <h4>Estado</h4>
              <select ref="selectAtencionIndicencia" v-if="editThirdStep">
                <option value="3">Visita programada</option>
                <option value="4">Equipo en taller</option>
              </select>
              <p v-else class="text-break">
                {{
                  tracking.length < 3
                    ? "-------"
                    : tracking.length === 3 && tracking[2].codigoEstado === 4
                    ? "Equipo en taller"
                    : "Visita programada"
                }}
              </p>
            </div>
            <div>
              <h4>Fecha:</h4>
              <p>
                {{
                  editThirdStep
                    ? "--/--/--"
                    : tracking.length >= 3
                    ? tracking[2].fecha
                    : "--/--/--"
                }}
              </p>
            </div>
            <div>
              <h4>Hora:</h4>
              <p>
                {{
                  editThirdStep
                    ? "--:--:--"
                    : tracking.length >= 3
                    ? tracking[2].hora
                    : "--:--:--"
                }}
              </p>
            </div>
            <div v-if="editThirdStep" class="attach">
              <label
                for="input-attach-atencion"
                class="name-input text-truncate"
              >
                <input
                  id="input-attach-atencion"
                  type="file"
                  accept="image/jpeg, image/png"
                  :multiple="false"
                  ref="inputAttachAtencion"
                  @click="resetInputFile"
                  @change="handleChangeFile"
                  :disabled="isLoading"
                />
                <span ref="labelAttachAtencion">{{
                  editThirdStep
                    ? tracking.length === 3
                      ? tracking[2].evidencia
                      : ""
                    : "-------"
                }}</span>
              </label>
              <label for="input-attach-atencion" class="btn-attach"
                >Adjuntar archivo</label
              >
            </div>
            <div v-else>
              <h4>Adjunto:</h4>
              <p
                v-if="tracking.length >= 3 && tracking[2].evidencia !== null"
                class="text-truncate"
              >
                <a :href="tracking[2].evidenciaUrl" target="_blank">
                  {{ tracking[2].evidencia }}
                </a>
              </p>
              <p v-else>-------</p>
            </div>
            <button
              v-if="editThirdStep"
              class="th-btn-float th-btn-pry w-100"
              :disabled="isLoading"
              @click="saveEdit"
            >
              <span>Guardar</span>
            </button>
          </div>
        </div>
      </div>
      <div
        class="d-flex flex-column tracking-item"
        :class="{ 'tracking-disabled': currentStep < 4 }"
        style="gap: 1.5rem"
      >
        <div class="step-group">
          <div class="step">4</div>
          <div class="step-line"></div>
        </div>
        <div class="w-100 d-flex flex-column" style="gap: 1rem">
          <div class="w-100 d-flex justify-content-between align-items-center">
            <h3 class="tracking-title mb-0">Cierre de incidencia</h3>
            <button
              v-if="
                (user.rol === 1 || user.rol === 2 || user.rol === 8) &&
                currentStep === 3 &&
                !disableEdit
              "
              @click="editFourStepToogle"
              class="btn-edit"
            >
              <i class="laive-i icon-edit" />
            </button>
          </div>
          <div class="tracking-detail-group">
            <div :class="{ 'mb-2': editFourStep }">
              <h4>Estado</h4>
              <select ref="selectCierreIndicencia" v-if="editFourStep">
                <option value="5">Operativo</option>
                <option value="6">No Operativo</option>
              </select>
              <p v-else>
                {{
                  tracking.length < 4
                    ? "-------"
                    : tracking.length === 4 && tracking[3].codigoEstado === 5
                    ? "Operativo"
                    : "No Operativo"
                }}
              </p>
            </div>
            <div>
              <h4>Fecha:</h4>
              <p>
                {{
                  editFourStep
                    ? "--/--/--"
                    : tracking.length === 4
                    ? tracking[3].fecha
                    : "--/--/--"
                }}
              </p>
            </div>
            <div>
              <h4>Hora:</h4>
              <p>
                {{
                  editFourStep
                    ? "--:--:--"
                    : tracking.length === 4
                    ? tracking[3].hora
                    : "--:--:--"
                }}
              </p>
            </div>
            <div v-if="editFourStep" class="attach">
              <label for="input-attach-cierre" class="name-input text-truncate">
                <input
                  id="input-attach-cierre"
                  type="file"
                  accept="image/jpeg, image/png"
                  :multiple="false"
                  ref="inputAttachCierre"
                  @click="resetInputFile"
                  @change="handleChangeFile"
                  :disabled="isLoading"
                />
                <span ref="labelAttachCierre">{{
                  editFourStep
                    ? tracking.length === 4
                      ? tracking[3].evidencia
                      : ""
                    : "-------"
                }}</span>
              </label>
              <label for="input-attach-cierre" class="btn-attach"
                >Adjuntar archivo</label
              >
            </div>
            <div v-else>
              <h4>Adjunto:</h4>
              <p
                v-if="tracking.length === 4 && tracking[3].evidencia !== null"
                class="text-truncate"
              >
                <a :href="tracking[3].evidenciaUrl" target="_blank">
                  {{ tracking[3].evidencia }}
                </a>
              </p>
              <p v-else>-------</p>
            </div>
            <button
              v-if="editFourStep"
              class="th-btn-float th-btn-pry w-100"
              :disabled="isLoading"
              @click="saveEdit"
            >
              <span>Guardar</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="divider"></div>

  <section class="details">
    <div>
      <h3>Código de Incidencia:</h3>
      <p>{{ data.codigoIncidencia }}</p>
    </div>
    <div>
      <h3>Tipo de Incidencia:</h3>
      <p>{{ data.tipoIncidencia }}</p>
    </div>
    <div>
      <h3>Sub tipo de incidencia:</h3>
      <p>{{ data.subtipoIncidencia }}</p>
    </div>
    <div>
      <h3>Fecha:</h3>
      <p>{{ data.fecha }}</p>
    </div>
    <div>
      <h3>Hora:</h3>
      <p>{{ data.hora }}</p>
    </div>
    <div>
      <h3>Nombre Vendedor:</h3>
      <p>
        {{ data.nombreVendedor === null ? "Call Center" : data.nombreVendedor }}
      </p>
    </div>
    <div>
      <h3>Nombre Supervisor:</h3>
      <p>{{ data.nombreSupervisor }}</p>
    </div>
    <div>
      <h3>Dirección del cliente:</h3>
      <p>{{ data.direccion }}</p>
    </div>
    <div>
      <h3>Referencia de la dirección:</h3>
      <p>{{ data.referenciaDireccion }}</p>
    </div>
    <div>
      <h3>Coordenadas del negocio:</h3>
      <p>Latitud: {{ data.latitud }}, Longitud: {{ data.longitud }}</p>
    </div>
    <div>
      <h3>Persona de contacto:</h3>
      <p>{{ data.personaContacto }}</p>
    </div>
    <div>
      <h3>Número de contacto:</h3>
      <p>{{ data.numeroContacto }}</p>
    </div>
    <div>
      <h3>Horario de atención:</h3>
      <p>
        Inicio: {{ data.horaInicioDisponibilidad }}, Fin:
        {{ data.horaFinDisponibilidad }}
      </p>
    </div>
  </section>
</template>

<script>
import "@/assets/sass/laive/equipos/incidencia/tracking.scss";

import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";

import { Incidencias } from "../../../services";
import { TRACKING_STEPS, TRACKING_STATUS } from "../../../model/equipos";

export default {
  components: {},
  props: {
    data: Object,
    fetchData: Function,
    isLoading: {
      type: Boolean,
      default: false,
      required: false,
    },
    disableEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    currentStep: 0,
    editSecondStep: false,
    editThirdStep: false,
    editFourStep: false,
    tracking: [],
  }),
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  emits: ["change-loading"],
  mounted() {
    this.tracking = this.data.incidenciaTracking.sort(
      (a, b) => a.idVendedorIncidenciaTracking - b.idVendedorIncidenciaTracking
    );
    this.currentStep = this.data.incidenciaTracking.length;
  },
  methods: {
    toast(title, type = "success") {
      const toast = useToast();
      if (type == "success") {
        toast.success(title, {
          position: "top-right",
          closeOnClick: true,
          hideProgressBar: true,
          timeout: 6000,
          toastClassName: "th-alert th-alert-pry",
          showCloseButtonOnHover: true,
        });
      } else if (type == "error") {
        toast.error(title, {
          position: "top-right",
          closeOnClick: true,
          hideProgressBar: true,
          timeout: 6000,
          toastClassName: "th-alert th-alert-error",
          showCloseButtonOnHover: true,
        });
      }
    },
    editSecondStepToogle() {
      this.editSecondStep = !this.editSecondStep;
    },
    editThirdStepToogle() {
      this.editThirdStep = !this.editThirdStep;
      this.editFourStep = false;

      if (this.editThirdStep && this.tracking.length === 3)
        setTimeout(() => {
          const stateCode = this.tracking[2].codigoEstado;
          this.$refs.selectAtencionIndicencia.value = stateCode.toString();
        }, 1);
    },
    editFourStepToogle() {
      this.editFourStep = !this.editFourStep;
      this.editThirdStep = false;

      if (this.editFourStep && this.tracking.length === 4)
        setTimeout(() => {
          const stateCode = this.tracking[3].codigoEstado;
          this.$refs.selectCierreIndicencia.value = stateCode.toString();
        }, 1);
    },
    resetInputFile() {
      if (this.$refs.inputAttachAtencion) {
        this.$refs.inputAttachAtencion.value = "";
      }

      if (this.$refs.inputAttachCierre) {
        this.$refs.inputAttachCierre.value = "";
      }
    },
    handleChangeFile(event) {
      const file = event.target.files[0];

      if (!file) {
        this.toast("Tiene que seleccionar un archivo", "error");
        return;
      }

      const [extension] = file.name.split(".").reverse();

      if (!["png", "jpg", "jpeg"].includes(extension)) {
        this.toast("Solo se admite archivos de tipo: png, jgp, jpeg", "error");
        return;
      }

      if (this.$refs.inputAttachAtencion) {
        this.$refs.labelAttachAtencion.innerText = file.name;
      }

      if (this.$refs.inputAttachCierre) {
        this.$refs.labelAttachCierre.innerText = file.name;
      }
    },
    async handleUploadFile({ file, id }) {
      try {
        const token = this.$store.state.auth.token;
        const formData = new FormData();
        formData.append("file", file);

        await Incidencias.uploadFileTracking(token, id, formData);
      } catch (error) {
        this.toast("Hubo un problema al subir la evidencia", "error");
      }
    },
    async saveEdit() {
      try {
        if (this.$parent.loading) return;

        const token = this.$store.state.auth.token;
        const idIncidencia = this.$route.query.incidenciaId;
        if (!token | !idIncidencia) {
          this.toast("Hubo un error en el registro del tracking", "error");
          return;
        }

        this.$emit("change-loading", true);

        if (this.currentStep === 1) {
          const trackingCode = TRACKING_STEPS.DERIVO_EQUIPO_MANTENIMIENTO;
          const trackingStatusCode = TRACKING_STATUS.DERIVO_ST;
          const body = { idIncidencia, trackingCode, trackingStatusCode };

          await Incidencias.registerTracking(token, body);
          await this.fetchData();

          this.editSecondStep = false;
        }

        if (this.editThirdStep) {
          const trackingCode = TRACKING_STEPS.ATENCION_INCIDENCIA;
          const trackingStatusCode = this.$refs.selectAtencionIndicencia.value;

          let idIncidenciaTracking = null;
          if (this.tracking.length === 2) {
            const body = { idIncidencia, trackingCode, trackingStatusCode };
            const { data: res } = await Incidencias.registerTracking(
              token,
              body
            );

            idIncidenciaTracking = res.data.idVendedorIncidenciaTracking;
          } else {
            const id = this.tracking[2].idVendedorIncidenciaTracking;
            const body = { id, trackingStatusCode };
            await Incidencias.editTracking(token, body);

            idIncidenciaTracking = id;
          }

          // Upload file, if the user selected a file
          const files = this.$refs.inputAttachAtencion.files;
          if (files.length > 0 && idIncidenciaTracking) {
            const file = files[0];
            await this.handleUploadFile({ file, id: idIncidenciaTracking });
          }

          await this.fetchData();
          this.editThirdStep = false;
        }

        if (this.editFourStep) {
          const trackingCode = TRACKING_STEPS.CIERRE_INCIDENCIA;
          const trackingStatusCode = this.$refs.selectCierreIndicencia.value;

          const body = { idIncidencia, trackingCode, trackingStatusCode };
          const { data: res } = await Incidencias.registerTracking(token, body);
          const idIncidenciaTracking = res.data.idVendedorIncidenciaTracking;

          // Upload file, if the user selected a file
          const files = this.$refs.inputAttachCierre.files;
          if (files.length > 0) {
            const file = this.$refs.inputAttachCierre.files[0];
            await this.handleUploadFile({ file, id: idIncidenciaTracking });
          }

          await this.fetchData();
          this.editFourStep = false;
        }

        this.tracking = this.data.incidenciaTracking.sort(
          (a, b) =>
            a.idVendedorIncidenciaTracking - b.idVendedorIncidenciaTracking
        );
        this.currentStep = this.data.incidenciaTracking.length;
      } catch (error) {
        console.log(error);
        this.toast("Hubo un error al registrar el tracking", "error");
      } finally {
        this.$emit("change-loading", false);
      }
    },
  },
};
</script>

<style scoped>
p {
  margin-bottom: 0;
}

.divider {
  width: 100%;
  height: 1px;
  margin-top: 2.875rem;
  margin-bottom: 2rem;
  background: #d9d9d9;
}
</style>
