import { format } from "date-fns";
import { es } from "date-fns/locale";

export * from "./liquid";
export * from "./throttle";
export * from "./download";
export * from "./editable";
export * from "./liquidacion";
export * from "./pdf";

export function randomData(obj, size = 15) {
  const random = (array) => array[Math.floor(Math.random() * array.length)];
  return Array.from({ length: size }, () =>
    Object.entries(obj).reduce(
      (acc, [k, v]) => ({ ...acc, [k]: random(v) }),
      {}
    )
  );
}
/**
 * @function
 * @param {Function} func - callback to debounce
 * @param {number} timout - timpo que deberia tomar antes de llamar al callback
 */
export function debounce(func, timeout = 300) {
  let timer;
  // Usar funciones, no funciones flecha para obtener el contexto(this)
  return function (...args) {
    clearTimeout(timer);
    const context = this;
    // Insertar el contexto a la funcion
    timer = setTimeout(() => func.apply(context, args), timeout);
  };
}

/**
 * @function
 * @param {string} date - Fecha que sigue: `MES/DIA/AÑO`
 * @param {string} [separator="/"] - Separador de los componentes de la fecha
 * @returns {string} Retorna la fecha con un formato `normal` `DIA/MES/AÑO`
 */
export function parseNormalDate(date, separator = "/") {
  const [month, day, year] = date.split("/");
  return `${day}${separator}${month}${separator}${year}`;
}

export function currentDate(separator = "/") {
  const date = new Date();
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  return `${day}${separator}${month}${separator}${year}`;
}

export const getCapitalizeWords = (str) => {
  return str
    .toLowerCase()
    .split(" ")
    .map((s) => `${s.charAt(0).toUpperCase()}${s.substring(1)}`);
};

export const formatDateToYYYYMMDD = (date) => {
  let year = date.getFullYear();
  let month = (date.getMonth() + 1).toString().padStart(2, '0');
  let day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

/**
 *
 * @param {Date | number} date
 * @param {String} customFormat Revisar los tipos de formats: https://date-fns.org/v2.29.3/docs/format
 * @returns {String}
 */
export function formatDate(date, customFormat = "dd/MM/yyyy") {
  const options = {
    locale: es,
  };

  const customDate = format(new Date(date), customFormat, options);

  return customDate;
}
