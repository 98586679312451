<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <router-link to="/maestro-productos/crear/"><base-button size="sm" type="info">Crear Producto</base-button></router-link>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive validateClient">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1 pt-0 mt-0"
          footer-classes="pb-2"
        >
          <template v-slot:header>
            <h3 class="mb-0">Edición de: {{title}}</h3>
          </template>
          <div class="card-body">
            <form @submit.prevent="submitEdit" >
              <div class="form-group row mb-0">
                <label class="col-md-12 col-form-label form-control-label">Código:</label>
                <div class="col-md-12">
                  <base-input
                    name="code_category"
                    placeholder="Código"
                    required="required"
                    :value="category.codigoDato"
                    v-model="category.codigoDato"
                  ></base-input>
                </div>
              </div>
              <div class="form-group row mb-0">
                <label class="col-md-12 col-form-label form-control-label">Nombre:</label>
                <div class="col-md-12">
                  <base-input
                    name="name_category"
                    placeholder="Nombre"
                    required="required"
                    :value="category.nombre"
                    v-model="category.nombre"
                  ></base-input>
                </div>
              </div>
              <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar Categoria</base-button>
            </form>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
export default {
  components: {
    RouteBreadCrumb,
  },
  created(){
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      axios.get(process.env.VUE_APP_API_URL+"/maestro/detalle/"+this.$route.params.id,config)
      .then(function (response){
        document.querySelector(".form_laive").classList.remove("validateClient")
        $this.title = response.data.nombre
        return $this.category = response.data
      })
  },
  methods: {
    submitEdit(submitEvent) {
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let updatedProduct = process.env.VUE_APP_API_URL+"/maestro/actualizar"
      this.category.idregistro = Number(this.$route.params.id)
      document.querySelector(".form_laive").classList.add("validateClient")
      this.title = this.category.nombre
      axios.put(updatedProduct, this.category,config)
      .then(function (response){
        document.querySelector(".form_laive").classList.remove("validateClient")
        const text = (response.status == 200) ? "Se actualizó la categoria" : "Hubo un error";
        const icon = "ni ni-bell-55";
        const content = {
          component: Notification,
          props: {
            icon: icon,
            text: text,
            type: (response.status == 200) ? "success" : "danger",
          },
        };
        const toast = useToast();
        toast(content, {
          hideProgressBar: true,
          icon: false,
          closeButton: false,
          position: "top-right",
        });
      })
      // alert(JSON.stringify(values, null, 2));
    }
  },
  data() {
    return {
      title: "",
      category: [],
    };
  },
}
</script>
<style lang="scss">
  .dropzone{
    width:200px;
  }
  .dz-message{
    padding: 1rem 1rem !important;
  }
  .dropzone-single.dz-max-files-reached .dz-message{
    background-color: rgba(219, 219, 219, 0.349) !important;
    border: 1px dashed #dee2e6 !important;
    opacity: 1 !important;
  }
  #product_prewview{
    .dz-preview-cover{
      position: relative;
      width:200px;
      padding-bottom: 0.5rem;
    }
  }
</style>
