import { mapActions, mapGetters } from 'vuex';
import UsuarioTradeService from '../services/UsuarioTradeService';
import axios from 'axios';



export default {
    namespaced: true,
    state: {
        mensaje: null,
        usuarios: []
    },
    getters: {},
    mutations: {
        actualizarLista(state, payload) {
            state.usuarios = payload.datos;
        },
        actualizarMensaje(state, mensaje) {
            state.mensaje = mensaje;
        },
    },
    methods: {},
    actions: {
        async cargarLista({ state, commit }) {
            let data = await UsuarioTradeService.cargarLista(axios);
            commit("actualizarLista", { datos: data });
        },
        async asociarDistribuidoras({ state, commit }, payload) {
            return await UsuarioTradeService.asociarDistribuidoras(axios, payload);
        }

    }
}