<template>
  <div class="col-auto my-1 px-1">
    <div
      class="alert alert-secondary my-0 py-1 px-2 h-auto chip add-px0-export"
      :class="{
        [aditional_class]: aditional_class,
      }"
    >
      <b v-if="etiqueta">{{ etiqueta }}</b> {{ texto }}
      <span v-if="texto_posterior" class="ml-2">{{ texto_posterior }}</span>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, watch, onMounted } from "vue";

const { bonificacion } = defineProps({
  etiqueta: {
    type: String,
  },
  texto: {
    type: [String, Number],
  },
  texto_posterior: {
    type: String,
  },
  aditional_class: {
    type: String,
  },
});

onMounted(() => {});
</script>
<style lang="scss" scoped>
.chip {
  font-size: 10px;
  background: #f7f9fc;
  padding: 4px, 8px, 4px, 8px;
  border-radius: 8px;

  &-primary {
    background: #eef9ec;
  }
}
</style>
