<template>
  <bread-crumb list-classes="breadcrumb-links breadcrumb-dark">
    <BreadCrumbItem>
      <li class="breadcrumb-item">
        <router-link to="/">
          <i class="fas fa-home"></i> Home
        </router-link>
      </li>
    </BreadCrumbItem>
    <BreadCrumbItem v-for="(route, index) in currentRoutes" :key="route.name" :active="index === currentRoutes.length - 1"
      style="display: inline-block">
      <router-link :to="route.path" v-if="index < currentRoutes.length - 1">
        {{ route.refName }}
      </router-link>
      <span v-else>{{ route.refName }}</span>
    </BreadCrumbItem>
  </bread-crumb>
</template>

<script>
import BreadCrumb from "./Breadcrumb";
import BreadCrumbItem from "./BreadcrumbItem";

export default {
  name: "route-breadcrumb",
  components: {
    BreadCrumb,
    BreadCrumbItem,
  },
  computed: {
    currentRoutes() {
      const routes = [];
      this.$route.matched.forEach(({ name, meta, path }) => {
        if (!name && !meta.refName) return;
        routes.push({ refName: meta.refName ?? name, path });
      })
      return routes;
    }
  },
};
</script>

<style scoped></style>
