export default {
    tokenData() {
        return {
            token: localStorage.getItem("token"),
            expiration: localStorage.getItem("maxAge"),
            user: JSON.parse(localStorage.getItem("userinfo")),
        }
    },
    http: {
        base_url: process.env.VUE_APP_API_URL ,
        gp_url: process.env.VUE_APP_GP_API ,
        headers: () => {
            return {
                "Authorization": `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json"
            }
        },
        multipart_headers: () => {
            return {
                "Authorization": `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "multipart/form-data"
            }
        },
    }

}