<script setup>
import "@/assets/sass/laive/equipos/solicitudes/modal.scss";

import { ref, defineProps, defineEmit, reactive, computed, watch } from "vue";
import { useStore } from "vuex";
import CONFIG from "@/config";

import { useAppToast } from "@/composables";
import TimeInput from "../../../components/Inputs/TimeInput.vue";

import { useRequests } from "../../../composables/equipos/useRequests";

import { Solicitudes } from "../../../services";

import {
  REQUEST_TYPE,
  TYPE_STATUS_DROPDOWN,
  getStatusText,
  MODAL_REQUEST_EQUIPMENT_REQUEST_TYPE,
  APPROVE_TRADE_STATUS,
  REQUEST_STATUS,
  getApproveTradeText,
} from "../../../model/equipos";

const YES_OR_NO_DROPDOWN = [
  { text: "Sí", value: "0" },
  { text: "No", value: "1" },
];

const store = useStore();

const props = defineProps({
  show: {
    type: Boolean,
    required: true,
    default: false,
  },
  idSolicitud: {
    type: Number,
    required: true,
  },
  request: {
    type: [String, null],
    required: false,
    default: null,
  },
  requestType: {
    type: String,
    required: false,
  },
});

const toast = useAppToast();

const loadingDetail = ref(false);
const loadingUpdated = ref(false);
const isEdit = ref(false);
const data = ref(null);
const file = ref(null);

const {
  idSolicitud,
  isFoundEquipment,
  isLossEquipment,
  isNewEquipment,
  isQrChange,
  isRecojo,
  isReport,
  requestText,
  requestTypeText,
  titleModal,
} = useRequests({ props });

const updateData = reactive({
  reference: "",
  contactPerson: "",
  contactNumber: "",
  startHour: "",
  endHour: "",
  equipmentModelName: "",
  equipmentPlaque: "",
  requestStatusCode: REQUEST_STATUS.PENDIENTE,
  equipmentIsLaive: "0",
  approveTrade: APPROVE_TRADE_STATUS.PENDING,
  reasonReject: "",
});

const user = computed(() => store.state.auth.user);
const isProvider = computed(() => user.value.rol === 8);
const isSuperAdmin = computed(() => user.value.rol === 1);
const isAdminLaive = computed(() => user.value.rol === 2);

const isNewEquipmentOrRecojo = computed(
  () => isNewEquipment.value || isRecojo.value
);
const canApprobeTrade = computed(
  () => isAdminLaive.value || isSuperAdmin.value
);

const isDisableEditButton = computed(
  () =>
    isProvider.value &&
    parseInt(data.value.approveTrade) === APPROVE_TRADE_STATUS.NO
);

/**
 * This happens when the "Proveedor" rejected or attended the request having the approve of admin.
 */
const providerAttendsOrRejectsRequest = computed(() => {
  return (
    parseInt(data.value.approveTrade) === APPROVE_TRADE_STATUS.YES &&
    (String(data.value.requestStatusCode) === REQUEST_STATUS.RECHAZADO ||
      String(data.value.requestStatusCode) === REQUEST_STATUS.ATENDIDO)
  );
});

const emit = defineEmit(["onClose", "onUpdated"]);

watch(
  () => props.show,
  async (isShow) => {
    if (isShow) {
      try {
        loadingDetail.value = true;
        await fetchData();
      } catch (error) {
        toast.error("Hubo un problema para mostrar la información");
      } finally {
        loadingDetail.value = false;
      }
    }
  }
);

const setModeEdit = (value) => {
  isEdit.value = value;
};

const onClose = () => {
  emit("onClose");

  isEdit.value = false;
  loadingDetail.value = false;
  loadingUpdated.value = false;
  data.value = null;
};

const initializedUpdateData = () => {
  updateData.requestStatusCode = data.value.requestStatusCode;

  if (isNewEquipment.value || isRecojo.value) {
    updateData.contactPerson = data.value.contactPerson;
    updateData.contactNumber = data.value.contactNumber;
    updateData.reference = data.value.reference;
    updateData.startHour = data.value.startHour;
    updateData.endHour = data.value.endHour;
    updateData.approveTrade = data.value.approveTrade;
    updateData.reasonReject =
      data.value.reasonReject === null ? "" : data.value.reasonReject;
  }

  if (isReport.value && isFoundEquipment.value) {
    updateData.equipmentModelName = data.value.equipmentModelName;
    updateData.equipmentPlaque = data.value.equipmentPlaque;
    updateData.equipmentIsLaive = data.value.equipmentIsLaive ? "0" : "1";
  }
};

const onCancel = () => {
  isEdit.value = false;

  initializedUpdateData();
};

const fetchData = async () => {
  if (isReport.value && (isQrChange.value || isLossEquipment.value)) {
    const response = await Solicitudes.getDetailRequestReport(
      idSolicitud.value
    );
    data.value = response;
  } else {
    const response = await Solicitudes.getDetail(idSolicitud.value);
    const mutatedResponse = {
      customerCode: response.customerCode,
      customerName: response.customerName,
      segmentName: response.segmentName,
      distributorName: response.distributorName,
      addressName: response.addressName,
      requestTypeName: response.requestTypeName,
      requestTypeReportName: REQUEST_TYPE.DEFAULT,
      requestStatusCode: response.statusCode.toString(),
      requestStatusName: getStatusText(String(response.statusCode)),
      equipmentModelName: response.model,
      equipmentPlaque: response.plaque,
      dni: response.dni,
      receipt: response.receipt,
      reference: response.addressReference,
      contactPerson: response.contactPerson,
      contactNumber: response.contactNumber,
      startHour: response.startHour,
      endHour: response.endHour,
      equipmentIsLaive: response.equipmentIsLaive,
      approveTrade: response.approveTrade,
      reasonReject: response.reasonReject,
    };

    data.value = mutatedResponse;
  }

  initializedUpdateData();
};

const handleEdit = async () => {
  try {
    loadingUpdated.value = true;

    let requestType = null;

    let requestEquipmentUpdateWhenIsNewEquipment = null;
    let requestEquipmentUpdateWhenIsPickUpEquipment = null;
    let requestEquipmentUpdateWhenIsFoundEquipment = null;
    let requestEquipmentUpdateWhenIsChangeQrEquipment = null;
    let requestEquipmentUpdateWhenIsNotFoundEquipment = null;

    let statusCode = parseInt(updateData.requestStatusCode);

    if (isNewEquipment.value || isRecojo.value) {
      requestType = isNewEquipment.value
        ? MODAL_REQUEST_EQUIPMENT_REQUEST_TYPE.NEW_COLD_EQUIPMENT
        : MODAL_REQUEST_EQUIPMENT_REQUEST_TYPE.EQUIPMENT_PICKUP;

      const approveTrade = parseInt(updateData.approveTrade);
      let reasonReject = "";

      if (isProvider.value) {
        if (updateData.requestStatusCode === REQUEST_STATUS.RECHAZADO) {
          reasonReject = updateData.reasonReject.trim();
        } else reasonReject = "";

        statusCode = parseInt(updateData.requestStatusCode);
      } else {
        // This happens when the "Proveedor" rejected or attended the request having the approve of admin.
        if (providerAttendsOrRejectsRequest.value) {
          if (approveTrade === APPROVE_TRADE_STATUS.NO) {
            reasonReject = updateData.reasonReject.trim();
            statusCode = parseInt(REQUEST_STATUS.RECHAZADO);
          } else if (approveTrade === APPROVE_TRADE_STATUS.YES) {
            reasonReject = "";
            statusCode = parseInt(updateData.requestStatusCode);
          }
        } else {
          if (approveTrade === APPROVE_TRADE_STATUS.NO) {
            reasonReject = updateData.reasonReject.trim();
            statusCode = parseInt(REQUEST_STATUS.RECHAZADO);
          } else if (approveTrade === APPROVE_TRADE_STATUS.YES) {
            reasonReject = "";
            statusCode = parseInt(REQUEST_STATUS.PENDIENTE);
          }
        }
      }

      const obj = {
        addressReference: updateData.reference,
        startHour: updateData.startHour,
        endHour: updateData.endHour,
        contactPerson: updateData.contactPerson,
        contactNumber: updateData.contactNumber,
        statusCode,
        approveTrade,
        reasonReject,
      };

      if (isNewEquipment.value) requestEquipmentUpdateWhenIsNewEquipment = obj;
      else requestEquipmentUpdateWhenIsPickUpEquipment = obj;
    }

    if (isReport.value) {
      if (isFoundEquipment.value) {
        const model = updateData.equipmentModelName;
        const plaque = updateData.equipmentPlaque;
        const equipmentIsLaive = updateData.equipmentIsLaive === "0";
        requestType = MODAL_REQUEST_EQUIPMENT_REQUEST_TYPE.EQUIPMENT_FOUND;

        requestEquipmentUpdateWhenIsFoundEquipment = {
          statusCode,
          model,
          plaque,
          equipmentIsLaive,
        };
      }

      if (isQrChange.value || isLossEquipment.value) {
        requestType = isQrChange.value
          ? MODAL_REQUEST_EQUIPMENT_REQUEST_TYPE.EQUIPMENT_CHANGE_QR
          : MODAL_REQUEST_EQUIPMENT_REQUEST_TYPE.EQUIPMENT_NOTFOUND;

        const obj = { statusCode };

        if (isQrChange.value)
          requestEquipmentUpdateWhenIsChangeQrEquipment = obj;
        else requestEquipmentUpdateWhenIsNotFoundEquipment = obj;
      }
    }

    const body = {
      requestType,
      requestEquipmentUpdateWhenIsNewEquipment,
      requestEquipmentUpdateWhenIsPickUpEquipment,
      requestEquipmentUpdateWhenIsFoundEquipment,
      requestEquipmentUpdateWhenIsChangeQrEquipment,
      requestEquipmentUpdateWhenIsNotFoundEquipment,
    };

    await Solicitudes.update(idSolicitud.value, body);
    onClose();
    emit("onUpdated");
  } catch (error) {
    toast.error("Hubo un problema para actualizar la solicitud");
  } finally {
    loadingUpdated.value = false;
  }
};

const getUrlStorage = (url) => {
  return CONFIG.storage("/solicitudes/documentos/", url);
};

const handleUpload = async (event, type) => {
  try {
    if (loadingUpdated.value) return;

    loadingUpdated.value = true;

    const files = event.target.files;
    if (files.length === 0) {
      toast.error("Seleccione un archivo");
      return;
    }

    file.value = files[0];

    const typeFile = file.value.name.split(".").pop();

    if (!["jpeg", "jpg", "png"].includes(typeFile)) {
      toast.error("Solo se admite archivos de tipo: jpeg, jpg y png");
      return;
    }

    let fileDniType = "";
    let fileDni = "";
    let fileReceiptType = "";
    let fileReceipt = "";

    if (type === "dni") {
      fileDniType = "dni";
      fileDni = file.value;
    }

    if (type === "recibo") {
      fileReceiptType = "recibo";
      fileReceipt = file.value;
    }

    const formData = new FormData();
    formData.append("fileDniType", fileDniType);
    formData.append("fileDni", fileDni);
    formData.append("fileReceiptType", fileReceiptType);
    formData.append("fileReceipt", fileReceipt);

    await Solicitudes.uploadImages({
      idSolicitud: idSolicitud.value,
      body: formData,
    });
    toast.success("Plantilla cargada");
    await fetchData();
  } catch (error) {
    toast.error("Hubo un problema al subir el archivo: " + type);
  } finally {
    loadingUpdated.value = false;
    event.target.value = null;
    file.value = null;
  }
};
</script>

<template>
  <div v-if="show" class="modal-request" @click="onClose">
    <div class="wrapper" @click.stop>
      <header>
        <button class="btn-close" @click="onClose">
          <i class="fa fa-times"></i>
        </button>
        <h1>{{ titleModal }}</h1>
      </header>
      <box-loader :loading="loadingDetail" style="height: 100%">
        <div v-if="loadingDetail" style="height: 9.375rem"></div>
        <template v-else-if="!loadingDetail && !!data">
          <box-loader :loading="loadingUpdated" style="height: 100%">
            <section>
              <h3>Datos Generales</h3>
              <div class="grid-container">
                <div>
                  <h5>Código de cliente</h5>
                  <p>{{ data.customerCode }}</p>
                </div>
                <div>
                  <h5>Cliente</h5>
                  <p>{{ data.customerName }}</p>
                </div>
                <div>
                  <h5>Segmento</h5>
                  <p>{{ data.segmentName }}</p>
                </div>
                <div>
                  <h5>DEX</h5>
                  <p>{{ data.distributorName }}</p>
                </div>
                <div>
                  <h5 class="mb-1">Dirección</h5>
                  <p>{{ data.addressName }}</p>
                </div>
                <div v-if="isNewEquipment || isRecojo">
                  <h5 class="mb-1">Ref. de ubicación</h5>
                  <ui-input
                    v-if="isEdit"
                    v-model="updateData.reference"
                    fs="-xs"
                    fw="-light"
                    px="0.4em"
                    py="0.3em"
                    :show-input="false"
                    spellcheck="false"
                  />
                  <p v-else>{{ data.reference }}</p>
                </div>
                <div v-if="isNewEquipment || isRecojo">
                  <h5>Horario</h5>
                  <div v-if="isEdit" class="group-horario">
                    <div>
                      <h6>Desde</h6>
                      <TimeInput
                        v-model="updateData.startHour"
                        :config="{ minuteIncrement: 1 }"
                      />
                    </div>
                    <div>
                      <h6>Hasta</h6>
                      <TimeInput
                        v-model="updateData.endHour"
                        :config="{ minuteIncrement: 1 }"
                      />
                    </div>
                  </div>
                  <p v-else>{{ data.startHour }} a {{ data.endHour }}</p>
                </div>
                <div v-if="isNewEquipment || isRecojo">
                  <h5>Persona de contacto</h5>
                  <ui-input
                    v-if="isEdit"
                    v-model="updateData.contactPerson"
                    fs="-xs"
                    fw="-light"
                    px="0.4em"
                    py="0.3em"
                    :show-input="false"
                    spellcheck="false"
                  />
                  <p v-else>{{ data.contactPerson }}</p>
                </div>
                <div v-if="isNewEquipment || isRecojo">
                  <h5>Número de contacto</h5>
                  <ui-input
                    v-if="isEdit"
                    v-model="updateData.contactNumber"
                    fs="-xs"
                    fw="-light"
                    px="0.4em"
                    py="0.3em"
                    :show-input="false"
                    spellcheck="false"
                  />
                  <p v-else>{{ data.contactNumber }}</p>
                </div>
                <div>
                  <h5>Solicitud</h5>
                  <p>{{ requestText }}</p>
                </div>
                <div>
                  <h5>Tipo de solicitud</h5>
                  <p>{{ requestTypeText }}</p>
                </div>
                <div>
                  <h5>Estado</h5>
                  <template v-if="isEdit">
                    <template
                      v-if="
                        isNewEquipmentOrRecojo &&
                        canApprobeTrade &&
                        providerAttendsOrRejectsRequest
                      "
                    >
                      <div
                        v-if="parseInt(updateData.approveTrade) === 2"
                        class="disable-status"
                      >
                        Rechazado
                      </div>
                      <ui-select
                        v-else
                        type="dropdown"
                        :options="TYPE_STATUS_DROPDOWN"
                        v-model:selected="updateData.requestStatusCode"
                        class="fs-xs fw-light text-dark"
                        px="-xs"
                        py="-xxs"
                        bg="white"
                        hv-bg="-gray-hover"
                      />
                    </template>
                    <template v-else>
                      <div
                        v-if="
                          isNewEquipmentOrRecojo &&
                          (canApprobeTrade ||
                            (isProvider &&
                              parseInt(updateData.approveTrade) === 0))
                        "
                        class="disable-status"
                      >
                        {{
                          parseInt(updateData.approveTrade) === 0 ||
                          parseInt(updateData.approveTrade) === 1
                            ? "Pendiente"
                            : "Rechazado"
                        }}
                      </div>
                      <p
                        v-else-if="
                          !isAdminLaive &&
                          !isSuperAdmin &&
                          !isProvider &&
                          isNewEquipmentOrRecojo
                        "
                      >
                        {{ getStatusText(data.requestStatusCode) }}
                      </p>
                      <ui-select
                        v-else
                        type="dropdown"
                        :options="TYPE_STATUS_DROPDOWN"
                        v-model:selected="updateData.requestStatusCode"
                        class="fs-xs fw-light text-dark"
                        px="-xs"
                        py="-xxs"
                        bg="white"
                        hv-bg="-gray-hover"
                      />
                    </template>
                  </template>
                  <p v-else>
                    {{ getStatusText(data.requestStatusCode) }}
                  </p>
                </div>

                <div v-if="isReport">
                  <h5>Modelo</h5>
                  <ui-input
                    v-if="isEdit && isFoundEquipment"
                    v-model="updateData.equipmentModelName"
                    fs="-xs"
                    fw="-light"
                    px="0.4em"
                    py="0.3em"
                    :show-input="false"
                    spellcheck="false"
                  />
                  <p v-else>{{ data.equipmentModelName }}</p>
                </div>

                <div v-if="isReport">
                  <h5>Placa</h5>
                  <ui-input
                    v-if="isEdit && isFoundEquipment"
                    v-model="updateData.equipmentPlaque"
                    fs="-xs"
                    fw="-light"
                    px="0.4em"
                    py="0.3em"
                    :show-input="false"
                    spellcheck="false"
                  />
                  <p v-else>{{ data.equipmentPlaque }}</p>
                </div>

                <div v-if="isReport && isFoundEquipment">
                  <h5>¿Cliente acepta si el equipo es de Laive?</h5>
                  <ui-select
                    v-if="isEdit"
                    type="dropdown"
                    :options="YES_OR_NO_DROPDOWN"
                    v-model:selected="updateData.equipmentIsLaive"
                    class="fs-xs fw-light text-dark"
                    px="-xs"
                    py="-xxs"
                    bg="white"
                    hv-bg="-gray-hover"
                  />
                  <p v-else>{{ data.equipmentIsLaive ? "Sí" : "No" }}</p>
                </div>

                <div v-if="isNewEquipment">
                  <h5>Modelo solicitado</h5>
                  <p>-</p>
                </div>

                <div v-if="isNewEquipment || isRecojo">
                  <h5>Aprobación Trade</h5>
                  <div v-if="isEdit && canApprobeTrade" class="group-radio">
                    <label>
                      <input
                        type="radio"
                        name="radios"
                        value="1"
                        :checked="updateData.approveTrade === 1"
                        v-model="updateData.approveTrade"
                      />
                      <span></span>
                      Sí
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="radios"
                        value="2"
                        :checked="updateData.approveTrade === 2"
                        v-model="updateData.approveTrade"
                      />
                      <span></span>
                      No
                    </label>
                  </div>
                  <p v-else>{{ getApproveTradeText(data.approveTrade) }}</p>
                </div>

                <div v-if="isNewEquipment"></div>

                <div v-if="isNewEquipment" class="group-attach">
                  <label
                    v-if="data.receipt === null"
                    for="receipt-input"
                    class="d-flex align-items-center mb-0"
                  >
                    <input
                      id="receipt-input"
                      hidden
                      type="file"
                      accept="image/jpeg, image/png, image/jpg"
                      @change="handleUpload($event, 'recibo')"
                    />
                    <i class="laive-i icon-download" />
                    <span>Cargar Recibo</span>
                  </label>
                  <a
                    v-else-if="data.receipt !== '-'"
                    target="_blank"
                    :href="getUrlStorage(data.receipt)"
                  >
                    <i class="laive-i icon-attach-green" />
                    Ver Recibo adjunto
                    <i class="laive-i icon-eye" />
                  </a>

                  <label
                    v-if="data.dni === null"
                    for="dni-input"
                    class="d-flex align-items-center mb-0"
                  >
                    <input
                      id="dni-input"
                      hidden
                      type="file"
                      accept="image/jpeg, image/png, image/jpg"
                      @change="handleUpload($event, 'dni')"
                    />
                    <i class="laive-i icon-download" />
                    <span>Cargar DNI</span>
                  </label>
                  <a
                    v-else-if="data.dni !== '-'"
                    target="_blank"
                    :href="getUrlStorage(data.dni)"
                  >
                    <i class="laive-i icon-attach-green" />
                    Ver DNI adjunto
                    <i class="laive-i icon-eye" />
                  </a>
                </div>

                <div
                  v-if="
                    isNewEquipmentOrRecojo &&
                    parseInt(updateData.approveTrade) === 2
                  "
                  :style="`grid-column: span ${
                    (isNewEquipment && (!!data.receipt || !!data.dni)) ||
                    isRecojo
                      ? 2
                      : 3
                  }`"
                >
                  <h5>Motivo</h5>
                  <textarea
                    v-if="isEdit && canApprobeTrade"
                    placeholder=""
                    v-model="updateData.reasonReject"
                    maxlength="250"
                  ></textarea>
                  <template v-else>
                    <textarea
                      v-if="updateData.reasonReject !== ''"
                      v-model="data.reasonReject"
                      readonly
                    ></textarea>
                    <p v-else>-</p>
                  </template>
                </div>
              </div>
            </section>
            <footer>
              <span class="divider"></span>

              <div v-if="isEdit" class="group-btns">
                <th-btn
                  type="outline"
                  fs="-sm"
                  border-w="2"
                  fw="-smBold"
                  px="2.5em"
                  @click="onCancel"
                >
                  Cancelar
                </th-btn>
                <th-btn
                  type="solid"
                  fs="-sm"
                  fw="-smBold"
                  border-w="2"
                  px="1.8em"
                  @click="handleEdit"
                >
                  Guardar
                </th-btn>
              </div>
              <th-btn
                v-else
                type="outline"
                fs="-sm"
                border-w="2"
                fw="-smBold"
                px="2.5em"
                :style="{
                  borderColor: isDisableEditButton ? '#6e6e6e' : '02A54F',
                  color: isDisableEditButton ? '#6e6e6e' : '02A54F',
                }"
                :disabled="isDisableEditButton"
                @click="setModeEdit(true)"
              >
                Editar
              </th-btn>
            </footer>
          </box-loader>
        </template>
      </box-loader>
    </div>
  </div>
</template>
