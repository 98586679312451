<template>
  <div class="row mt-4 mx-0 w-100 font-11 py-3" v-if="usuario">
    <div class="col-2 px-1 text-center">{{ usuario.nombre }}</div>
    <div class="col-2 px-1 text-center">{{ usuario.rol }}</div>
    <div
      class="col-3 px-1 text-center"
      :class="{
        'laive-disabled': !puede_editar,
      }"
    >
      <GeneralDropdown
        v-model="usuario.distribuidoras"
        aditional_class="bg-white mh-35px"
        :options="distribuidoras_options"
        :is_multiple="true"
        :puede_seleccionar_todos="true"
        :collapse_tags="true"
        :resumir_tags="true"
        :fit_content="true"
        :disabled="!puede_editar"
      ></GeneralDropdown>
    </div>
    <div class="col-3 px-2">
      <Chip
        v-for="item in distribuidoras_text"
        :key="item"
        :etiqueta="`${item.oficina}:`"
        :texto="item.distribuidoras.join(', ')"
      ></Chip>
    </div>
    <div class="col-1 px-1">
      <button
        type="button"
        v-if="!puede_editar"
        @click.prevent="puede_editar = true"
        class="btn circle-btn-sm bonificacion-primary-text mx-auto p-0 d-flex align-items-center justify-content-center"
      >
        <i class="fas fa-pen"></i>
      </button>
      <button
        type="button"
        v-if="puede_editar"
        @click.prevent="guardarUsuario"
        class="btn circle-btn-sm bonificacion-primary-text mx-auto p-0 d-flex align-items-center justify-content-center"
      >
        <i class="fas fa-save"></i>
      </button>
    </div>
  </div>
</template>
<script setup>
import GeneralDropdown from "./GeneralDropdown.vue";
import Chip from "./Chip.vue";
import { ref, computed, watch, onMounted } from "vue";

import { useStore } from "vuex";
import { dispatch } from "d3";
const store = useStore();

const { usuario_raw } = defineProps({
  usuario_raw: {
    type: Object,
  },
});

const usuario = ref(null);

const puede_editar = ref(false);

const distribuidoras_options = computed(() =>
  store.state.bonificacionUtil.distribuidoras.map((item) => {
    return {
      value: item.iddistribuidora,
      label: `${item.oficinaventa} - ${item.codigodistribuidora}`,
    };
  })
);

const distribuidoras_text = computed(() => {
  
  const oficinas_set = new Set();

  const distribuidoras_seleccionadas = usuario.value.distribuidoras.map(
    (distribuidora) => {
      const seleccionada = store.state.bonificacionUtil.distribuidoras.find(
        (option) => option.iddistribuidora == distribuidora
      );
      if (seleccionada) {
        oficinas_set.add(seleccionada.oficinaventa);
        return seleccionada;        
      }
    }
  ).filter(item => item!=null);
  return [...oficinas_set].map((oficina) => {
    const distribuidoras = distribuidoras_seleccionadas
      .filter((item) => item.oficinaventa == oficina)
      .map((item) => `${item.codigodistribuidora}`);
    return {
      oficina,
      distribuidoras,
    };
  });
});

onMounted(() => {
  usuario.value = usuario_raw;
});

const mensaje = ref("");
const guardarUsuario = async () => {
  const item = usuario.value;
  
  const payload = {
    idUsuario: item.idUsuario,
    nombre: item.nombre,
    userName: item.userName,
    codigoRol: item.codigoRol,
    email: item.email,
    distribuidoras: item.distribuidoras.join(","),
  };
  const resultado = await store.dispatch(
    "usuarioTrade/asociarDistribuidoras",
    payload
  );
  if (resultado.status == 200) {
    store.commit("usuarioTrade/actualizarMensaje", {
      class: "alert-success",
      texto: "Guardado correctamente",
    });
  } else {
    store.commit("usuarioTrade/actualizarMensaje", {
      class: "alert-danger",
      texto: "Ocurrió un error al intentar guardar",
    });
  }
  setTimeout(() => {
    store.commit("usuarioTrade/actualizarMensaje", null);
  }, 3000);

  puede_editar.value = false;
};
</script>
<style lang="scss" scoped></style>
