<template>
  <td style="max-width: 240px; min-width: 240px; width: 240px" class="p-1">
    <span for="" class="w-100 line-limit-2 px-1 bonificacion-text">
      {{ producto }}
    </span>
  </td>
  <td class="p-1 px-0 text-center align-middle" :class="escala.proposito">
    <GeneralDropdown
      v-if="can_edit"
      v-model="escala.proposito"
      tipo="date"
      label=""
      :options="[
        { value: 'material', label: 'Material' },
        { value: 'descuento', label: 'Dscto %' },
      ]"
      :is_multiple="false"
      :collapse_tags="false"
      placeholder="Selecciona"
      :aditional_class="`mh-auto`"
    ></GeneralDropdown>
    <span v-else class="font-12 proposito">{{ proposito }}</span>
  </td>
  <td class="p-1 px-0 align-middle" v-for="detalle in escala.detalles" :key="detalle">
    <div class="row mx-0">
      <div class="col-6 px-1 text-center">
        <GeneralInput
          v-model="detalle.bultos"
          tipo="number"
          label="Bultos"
          placeholder="00"
          wrapper_class="text-center pb-1"
          :fit_content="true"
          v-if="can_edit"
        ></GeneralInput>
        <span v-else class="font-12">{{ detalle.bultos }}</span>
      </div>
      <div class="col-6 px-1 text-center">
        <GeneralInput
          v-model="detalle.descuento"
          tipo="number"
          label="Descuento"
          placeholder="00"
          wrapper_class="text-center pb-1"
          :fit_content="true"
          v-if="escala.proposito == 'descuento' && can_edit"
        ></GeneralInput>
        <GeneralInput
          v-model="detalle.descuento"
          tipo="number"
          label="Regalo"
          placeholder="00"
          wrapper_class="text-center pb-1"
          :fit_content="true"
          v-if="escala.proposito == 'material' && can_edit"
        ></GeneralInput>
        <span v-if="!can_edit" class="font-12">{{ detalle.descuento ?? 99 }}</span>
      </div>
    </div>
  </td>
  <td v-if="can_edit">
    <button
      type="button"
      @click.prevent="eliminarFila"
      class="btn circle-btn-sm bg-danger text-white mx-auto p-0 d-flex align-items-center justify-content-center"
    >
      <i class="fas fa-times" style="font-size: 10px"></i>
    </button>
  </td>
</template>
<script>
import GeneralInput from "./GeneralInput.vue";
import GeneralDropdown from "./GeneralDropdown.vue";
import constantes from "@/model/constantes.js";
export default {
  props: {
    escala: Object,
    can_edit: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    GeneralDropdown,
    GeneralInput,
  },
  methods: {
    eliminarFila() {
      this.$emit("eliminar-fila");
    },
  },
  computed: {
    producto: function () {
      if (this.escala.tipo == constantes.INDIVIDUAL) {
        const item = this.$store.state.bonificacionUtil.productos.find(
          (item) => item.codigofabrica == this.escala.productos[0]
        );
        return `${item.codigofabrica} - ${item.descripcion}`;
      } else if (this.escala.tipo == constantes.COMBINACION) {
        return `${this.categorias.join(",")} | ${this.familias.join(
          ","
        )} | ${this.presentaciones.join(",")}`;
      }
      return "-";
    },
    categorias: function () {
      const fuente = this.$store.state.bonificacionUtil.categorias;
      return fuente
        .filter((objeto) => this.escala.categorias.includes(objeto.codigodato))
        .map((item) => item.nombre);
    },
    familias: function () {
      const fuente = this.$store.state.bonificacionUtil.familias;
      return fuente
        .filter((objeto) => this.escala.familias.includes(objeto.codigodato))
        .map((item) => item.nombre);
    },
    presentaciones: function () {
      const fuente = this.$store.state.bonificacionUtil.presentaciones;

      const result = [];
      fuente.forEach((item) => {
        const existe = result.find(
          (elemento) => elemento.codigodato == item.codigodato
        );
        if (!existe) {
          result.push(item);
        }
      });
      return result
        .filter((objeto) =>
          this.escala.presentaciones.includes(objeto.codigodato)
        )
        .map((item) => item.nombre);
    },
    proposito: function () {
      return this.escala.proposito == constantes.MATERIAL ? 'Material' : 'Descuento %';
    },
  },
};
</script>
<style lang="scss" scoped></style>
