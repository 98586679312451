import swal from 'sweetalert2';
class LiquidTable {
    database = [];
    pagination = {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
    };
    searchedData = [];
    searchQuery = '';

    columns=[];
    constructor(_data = []) {
        this.database = _data;
    }

    get to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
            highBound = this.total;
        }
        return highBound;
    };
    get from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
    };
    get total() {
        return this.searchedData.length > 0
            ? this.searchedData.length
            : this.database.length;
    };
    set data(_data){
        this.database = _data;
    }
    get data() {
        let propS = this.columns.map(x=>x.prop);
        
        if (this.database.constructor.name == 'Array') { 
            if (!this.searchQuery) {
                return this.database.slice(this.from, this.to);
            }
            let result = this.database.filter((row) => {
                let isIncluded = false;
                for (let key of propS) {
                    let rowValue = row[key].toString().toLowerCase();
                    if (rowValue.includes && rowValue.includes(this.searchQuery.toLowerCase())) {
                        isIncluded = true;
                    }
                }
                return isIncluded;
            });
            return result.slice(this.from, this.to);
        } else {
            return [];
        }
        
    }
}

class LiquidAlert {
    static sw = swal.mixin({});
    static success(message,title,buttonText='Ok'){
        let alert = swal.mixin({
            customClass: {
              confirmButton: "btn btn-success btn-fill",
            },
            buttonsStyling: false
        });
        alert.fire({
            title: title,
            confirmButtonText: buttonText,
        });
    }

    static error(message,title,buttonText='Cerrar'){
        let alert = swal.mixin({
            customClass: {
              confirmButton: "btn btn-success btn-fill",
            },
            buttonsStyling: false
        });
        alert.fire({
            title: title,
            text:message,
            icon: 'error',
            confirmButtonText: buttonText,
        });
    }

    static close(){
        LiquidAlert.sw.close();
    }

    static loading(title='Titulo'){
        LiquidAlert.sw = swal.mixin({
            customClass: {
                confirmButton: "btn btn-success btn-fill",
                container: "swal-wide",
            },
            buttonsStyling: false,
            allowOutsideClick: false
        });
        LiquidAlert.sw.fire({
            title: `${title}`,
            html: `
          <div class="form_laive validateClient">
            <div class="box_loader">
              <div class="loader"></div>
            </div>
            <br><br><br><br><br>
          </div>`,
            showConfirmButton: false,
        });
    }

    static html(title='Titulo',html=''){
        LiquidAlert.sw = swal.mixin({
            customClass: {
                confirmButton: "btn btn-success btn-fill",
                container: "swal-wide",
            },
            buttonsStyling: false,
            allowOutsideClick: false
        });
        LiquidAlert.sw.fire({
            title: `${title}`,
            html: `${html} `,
            confirmButtonText: "Cerrar",
            showConfirmButton: true,
        });
    }

    static htmlNotTitle(html=''){
        LiquidAlert.sw = swal.mixin({
            customClass: {
                confirmButton: "btn btn-success btn-fill",
                container: "",
            },
            buttonsStyling: false,
            allowOutsideClick: false
        });
        LiquidAlert.sw.fire({
            html: `${html} `,
            confirmButtonText: "Cerrar",
            showConfirmButton: true,
        });
    }
}
export { LiquidTable,LiquidAlert }


