<template>
  <div class="row mx-0 mt-0 header">
    <div class="col-6 px-0">
      <div class="row mx-0 h-100">
        <div class="col-4 px-0">
          <div class="row mx-0 h-100">
            <div
              class="col-3 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              Cód.
            </div>
            <div
              class="col-3 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              Tipo
            </div>
            <div
              class="col-6 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              Nombre
            </div>
          </div>
        </div>
        <div class="col-4 px-0">
          <div class="row mx-0 h-100">
            <div
              class="col-12 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              Pro. Bonificado y Unds
            </div>
          </div>
        </div>
        <div class="col-4 px-0">
          <div class="row mx-0 h-100">
            <div
              class="col-6 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              Cant. Bonificable
            </div>
            <div
              class="col-6 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              Cant. Bonificada
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 px-0">
      <div class="row mx-0">
        <div class="col-2 px-0">
          <div class="row mx-0 h-100">
            <div
              class="col-12 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              Cant. Ajustada
            </div>
          </div>
        </div>

        <div class="col-5 px-0">
          <div class="row mx-0 h-100">
            <div
              class="col-3 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              Eficacia
            </div>
            <div
              class="col-3 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              Por revisar
            </div>
            <div
              class="col-3 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              Costo Unit. S/
            </div>
            <div
              class="col-3 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              Costo Total S/
            </div>
          </div>
        </div>
        <div class="col-1 px-0">
          <div class="row mx-0 h-100">
            <div
              class="col-12 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              Costo Ajustado
            </div>
          </div>
        </div>

        <div class="col-4 px-0">
          <div class="row mx-0 h-100">
            <div
              class="col-5 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >
              Estado
            </div>
            <div v-if="!subgerente"
              class="col-7 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            ></div>
            <div v-if="subgerente"
              class="col-5 px-2 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            >Nro. Memo</div>
            <div v-if="subgerente"
              class="col-2 px-1 font-10 py-2 text-center d-flex align-items-center justify-content-center"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Row
    v-for="liquidacion in liquidaciones"
    :liquidacion="liquidacion"
    :key="liquidacion"
    :subgerente="subgerente"
  ></Row>
</template>

<script setup>
import "@/assets/sass/laive/bonificaciones.scss";
import { ref, computed, watch, onMounted } from "vue";
import Row from "./Row.vue";
import { useStore } from "vuex";
import constantes from "@/model/constantes.js";
const store = useStore();

store.dispatch("bonificacionUtil/cargarLista", "distribuidoras");

const { liquidaciones, subgerente } = defineProps({
  liquidaciones: {
    type: Object,
  },
  subgerente: {
    type: Boolean,
    default: false
  },
});
onMounted(() => {});
</script>

<style scoped>
.submit-btns {
  height: fit-content !important;
}
</style>
