<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <!-- HTML5 inputs -->
            <div class="card">
              <!-- Card header -->
              <div class="card-header">
                <h3 class="mb-0">Estos campos se reflejarán en la aplicación</h3>
              </div>
              <!-- Card body -->
              <div class="card-body form_laive validateClient">
                <div class="box_loader">
                  <div class="loader"></div>
                </div>
                <form @submit.prevent="submitUpdatedParameter" id="objetivo_visitas_efectivas">
                  <div class="form-group row">
                      <label class="col-md-12 col-form-label form-control-label">Objetivo de visitas efectivas (%)</label>
                      <div class="col-md-3">
                        <span class="title_filter mr-2">Seleccione una Distribuidora:  </span>
                        <select class="form-control" name="distributor_select" id="distributor_select" style="display: inline-block;height:43px;max-width: 280px;" @change="changeDistribuidora">
                          <option value="" selected disabled style="display:none">Seleccionar</option>
                          <option
                            v-for="item in distribuidoras"
                            :value="item.idDistribuidora"
                            :key="item.idDistribuidora"
                            >
                            {{ item.razonSocial }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <span class="title_filter">&nbsp;</span>
                        <base-input
                          required
                          name="objetivo_visitas_efectivas"
                          placeholder="Indicar Objetivo de visitas efectivas"
                          v-model="seleted_visitas_efectivas.valor"
                          @keypress="isNumber($event)"
                        ></base-input>
                      </div>
                      <div class="col-md-12">
                        <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar Objetivo de visitas efectivas</base-button>
                      </div>
                  </div>
                </form>
              </div>
              <div class="card-body form_laive validateClient">
                <div class="box_loader">
                  <div class="loader"></div>
                </div>
                <form @submit.prevent="submitUpdatedParameter" id="objetivo_mensual_cobertura">
                  <div class="form-group row">
                    <label class="col-md-12 col-form-label form-control-label">Objetivo mensual de Cobranza (%)</label>
                    <div class="col-md-3">
                      <span class="title_filter mr-2">Seleccione una Distribuidora:  </span>
                      <select class="form-control" name="distributor_select_cobranza" id="distributor_select_cobranza" style="display: inline-block;height:43px;max-width: 280px;" @change="changeDistribuidora">
                        <option value="" selected disabled style="display:none">Seleccionar</option>
                        <option
                          v-for="item in distribuidoras"
                          :value="item.idDistribuidora"
                          :key="item.idDistribuidora"
                          >
                          {{ item.razonSocial }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-4">
                      <span class="title_filter">&nbsp;</span>
                      <base-input
                        required
                        name="objetivo_mensual_cobertura"
                        placeholder="Indicar Objetivo mensual de Cobranza"
                        v-model="seleted_mensual_cobertura.valor"
                        @keypress="isNumber($event)"
                      ></base-input>
                    </div>
                    <div class="col-md-12">
                      <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar Objetivo mensual de Cobranza</base-button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from "axios";
  import { useToast } from "vue-toastification";
  import Notification from "@/components/Notification";
  import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
  import DashboardNavbar from "../Layout/DashboardNavbar";
  export default {
    components: {
      DashboardNavbar,
      RouteBreadCrumb,
    },
    created(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const urlApiParameters = process.env.VUE_APP_API_URL+"/parametro/lista"
      axios.post(urlApiParameters,"",config)
      .then(function (response){
        const data = response.data;
        // Tope de efectivo
        let objetivo_visitas_efectivas = data.filter((item)=>{return item.codigoParametro == "objetivo_visitas_efectivas"})
        $this.objetivo_visitas_efectivas = objetivo_visitas_efectivas
        // $this.objetivo_visitas_efectivas.codigoParametro = objetivo_visitas_efectivas[0].codigoParametro
        // $this.objetivo_visitas_efectivas.valor = objetivo_visitas_efectivas[0].valor
        // Monto de efectivo para cambio
        let objetivo_mensual_cobertura = data.filter((item)=>{return item.codigoParametro == "objetivo_mensual_cobertura"})
        $this.objetivo_mensual_cobertura = objetivo_mensual_cobertura
        // $this.objetivo_mensual_cobertura.codigoParametro = objetivo_mensual_cobertura[0].codigoParametro
        // $this.objetivo_mensual_cobertura.valor = objetivo_mensual_cobertura[0].valor
        console.log({objetivo_visitas_efectivas, objetivo_mensual_cobertura});
      })

      let urlApiDistribuidoras = process.env.VUE_APP_API_URL+"/distribuidoras/lista"
      const requestDistribuidoras = axios.get(urlApiDistribuidoras, config);
      axios.all([requestDistribuidoras]).then(axios.spread((...responses) => {
        const responseDistribuidoras = responses[0].data
        $this.distribuidoras = responseDistribuidoras.filter((item)=>{return item.estado == "1"})
        const getFormsLaive = document.querySelectorAll(".form_laive")
        getFormsLaive.forEach(element => {
          element.classList.remove("validateClient")
        });
        return $this.distribuidoras;
      })).catch(errors => {
        // react on errors.
      })

    },
    methods: {
      isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault();;
        } else {
          return true;
        }
      },
      changeDistribuidora(evn){
        const $this = this;
        const $parent = evn.target.parentNode.parentNode.parentNode;
        const getID = $parent.getAttribute("id")
        const selected = Number(evn.target.value);
        let dataArray = ''
        let selectData = ''
        if(getID == 'objetivo_visitas_efectivas'){
          dataArray = this.objetivo_visitas_efectivas
          selectData = this.seleted_visitas_efectivas
        }
        if(getID == 'objetivo_mensual_cobertura'){
          dataArray = this.objetivo_mensual_cobertura
          selectData = this.seleted_mensual_cobertura
        }
        let dataFilter = dataArray.filter((item)=>{return item.idDistribuidora == selected})
        if(getID == 'objetivo_visitas_efectivas'){
          this.seleted_visitas_efectivas = dataFilter[0]
        }
        if(getID == 'objetivo_mensual_cobertura'){
          this.seleted_mensual_cobertura = dataFilter[0]
        }
        console.log({dataArray, selectData});
      },
      submitUpdatedParameter(evn) {
        const $parent = evn.target.parentNode;
        const getID = evn.target.getAttribute("id")
        const token = localStorage.getItem("token")
        let messageSuccess = ""
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        let dataSend = ""
        if(getID == "objetivo_visitas_efectivas"){
          dataSend = this.seleted_visitas_efectivas
          messageSuccess = "Se actualizó el Objetivo de Visitas efectivas"
        }else if(getID == "objetivo_mensual_cobertura"){
          dataSend = this.seleted_mensual_cobertura
          messageSuccess = "Se actualizó el Objetivo Mensual de cobertura"
        }
        const urlApiUpdated = process.env.VUE_APP_API_URL+"/parametro/actualizar"
        $parent.classList.add("validateClient")
        axios.put(urlApiUpdated,dataSend,config)
        .then(function (response){
          $parent.classList.remove("validateClient")
          const text = (response.status == 200) ? messageSuccess : "Hubo un error";
          const icon = "ni ni-bell-55";
          const content = {
            component: Notification,
            props: {
              icon: icon,
              text: text,
              type: (response.status == 200) ? "success" : "danger",
            },
          };
          const toast = useToast();
          toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
        })
      },
    },
    data() {
      return {
        distribuidoras: [],
        seleted_visitas_efectivas:{
          idParametro: "",
          codigoParametro: "",
          valor: ""
        },
        objetivo_visitas_efectivas: {
          idParametro: "",
          codigoParametro: "",
          valor: ""
        },
        seleted_mensual_cobertura:{
          idParametro: "",
          codigoParametro: "",
          valor: ""
        },
        objetivo_mensual_cobertura: {
          idParametro: "",
          codigoParametro: "",
          valor: ""
        },
      };
    },
  };
</script>

