import { mapActions, mapGetters } from 'vuex';
import DistribuidoraService from '../services/DistribuidoraService';
import axios from 'axios';
export default { 
    namespaced: true,
    state:{},
    getters:{},
    mutations:{},
    methods:{ },
    actions:{
        async getList({state,commit},data) {
            return await DistribuidoraService.getList(axios,data);
        },
    }
}