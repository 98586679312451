<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class=" d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive validateClient">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <DefaultMaestro :dataList="dataTable"></DefaultMaestro>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import DefaultMaestro from "../Components/DefaultMaestro.vue";
export default {
  components: {
    RouteBreadCrumb,
    DefaultMaestro,
  },
  created(){
    const $this = this
    const id = this.$route.params.id
    const token = localStorage.getItem("token")
    const config = {
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    };
    this.type = this.$route.query.type
    this.title = this.$route.query.name
    axios.get(process.env.VUE_APP_API_URL+"/maestro/lista/"+id,config)
    .then(function (response){
      document.querySelector(".form_laive").classList.remove("validateClient")
      $this.dataTable = response.data
    })
  },
  data() {
    return {
      type: "",
      title : "",
      dataTable: []
    };
  },
}
</script>
