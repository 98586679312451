<template>
  <div
    class="th-select"
    :class="[customClass ? customClass : 'default-select', className]"
    :style="style"
    v-click-outside="hidden"
  >
    <button
      class="th-select-option"
      aria-expanded="true"
      aria-haspopup="listbox"
      :disabled="disabled"
    >
      <input
        type="search"
        @input="handleSearch"
        :placeholder="placeholder"
        :disabled="disabled"
        :value="modelValue"
        @focus="show"
      />
      <div class="pr-2">
        <span class="loader" v-if="loading">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        <i v-else class="laive-i icon-search"></i>
      </div>
    </button>
    <ul
      v-if="showOptions"
      class="th-select-listbox scroller"
      role="listbox"
      aria-orientation="vertical"
      tabindex="0"
    >
      <li v-for="option in options" @click.stop="handleSelect(option.value)">
        {{ option.text }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    customClass: [String, Object],
    className: [String, Object],
    options: {
      type: Array,
      default: [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    style: [Object, String],
    placeholder: String,
    disabled: Boolean,
    modelValue: [String, Number],
  },
  emits: ["update:modelValue", "selectOption"],
  data() {
    return {
      isOpen: true,
    };
  },
  computed: {
    showOptions() {
      return this.isOpen && this.options.length > 0;
    },
  },
  methods: {
    handleSelect(value) {
      const opt = this.options.find((opt) => opt.value == value);
      if (opt == undefined) return;
      this.$emit("selectOption", opt);
      this.hidden();
    },
    handleSearch(ev) {
      const value = ev.target.value;
      this.$emit("update:modelValue", value);
    },
    show() {
      this.isOpen = true;
    },
    hidden() {
      this.isOpen = false;
    },
  },
};
</script>

<style scoped>
button:disabled {
  background: #f6f6f6;
  cursor: auto;
}

input {
  background-color: inherit;
  flex: 1;
  font-weight: inherit;
  appearance: none;
  -webkit-appearance: none;
  border: 0;
  outline: 0;
  padding-inline-start: 0.7em;
  padding-block: 0.3em;
}

.loader {
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.laive-i {
  width: 18px !important;
  height: 18px !important;
  /* margin-inline-end: 0.7em;
  margin-block: 0.3em; */
}

/* Default Select   */

.default-select > .th-select-option {
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  padding-inline: 0em;
  padding-block: 0em;
}

.default-select .th-select-option input {
  font-weight: 300;
}

.default-select > .th-select-listbox {
  border: 1px solid #e3e3e3;
  border-radius: 0.25em;
  box-shadow: 2px 5px 4px 0px rgba(0, 0, 0, 0.1);
}

.default-select > .th-select-listbox li {
  padding-inline: 1em;
  padding-block: 0.6em;
  cursor: pointer;
}

.default-select > .th-select-listbox li:not(:last-of-type) {
  border-bottom: 1px solid #e3e3e3;
}

.default-select > .th-select-listbox li:hover {
  background-color: #f1f1f1;
}
</style>
