<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <!-- HTML5 inputs -->
            <div class="card">
              <!-- Card header -->
              <div class="card-header">
                <h3 class="mb-0">Estos campos se reflejarán en la aplicación</h3>
              </div>
              <!-- Card body -->
              <div class="card-body form_laive validateClient">
                <div class="box_loader">
                  <div class="loader"></div>
                </div>
                <form @submit.prevent="submitUpdatedParameter" id="maximo_productos_frecuentes">
                  <div class="form-group row">
                    <label class="col-md-12 col-form-label form-control-label"
                      >Máximo de productos frecuentes</label
                    >
                    <div class="col-md-12">
                      <base-input
                        required
                        name="maximo_productos_frecuentes"
                        placeholder="Máximo de productos frecuentes"
                        v-model="maximo_productos_frecuentes.valor"
                        @keypress="isNumber($event)"
                      ></base-input>
                      <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar Productos frecuentes</base-button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from "axios";
  import { useToast } from "vue-toastification";
  import Notification from "@/components/Notification";
  import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
  import DashboardNavbar from "../Layout/DashboardNavbar";
  export default {
    components: {
      DashboardNavbar,
      RouteBreadCrumb,
    },
    created(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const urlApiParameters = process.env.VUE_APP_API_URL+"/parametro/lista"
      axios.post(urlApiParameters,"",config)
      .then(function (response){
        const data = response.data;
        // Máximo de productos frecuentes
        let maximo_productos_frecuentes = data.filter((item)=>{return item.codigoParametro == "maximo_productos_frecuentes"})
        $this.maximo_productos_frecuentes.idParametro = maximo_productos_frecuentes[0].idParametro
        $this.maximo_productos_frecuentes.codigoParametro = maximo_productos_frecuentes[0].codigoParametro
        $this.maximo_productos_frecuentes.valor = maximo_productos_frecuentes[0].valor
        const getFormsLaive = document.querySelectorAll(".form_laive")
        getFormsLaive.forEach(element => {
          element.classList.remove("validateClient")
        });
      })
    },
    methods: {
      isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault();;
        } else {
          return true;
        }
      },
      submitUpdatedParameter(evn) {
        const $parent = evn.target.parentNode;
        const getID = evn.target.getAttribute("id")
        const token = localStorage.getItem("token")
        let messageSuccess = ""
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        let dataSend = ""
        if(getID == "maximo_productos_frecuentes"){
          dataSend = this.maximo_productos_frecuentes
          messageSuccess = "Se actualizó el máximo de Productos frecuentes"
        }
        const urlApiUpdated = process.env.VUE_APP_API_URL+"/parametro/actualizar"
        $parent.classList.add("validateClient")
        axios.put(urlApiUpdated,dataSend,config)
        .then(function (response){
          $parent.classList.remove("validateClient")
          const text = (response.status == 200) ? messageSuccess : "Hubo un error";
          const icon = "ni ni-bell-55";
          const content = {
            component: Notification,
            props: {
              icon: icon,
              text: text,
              type: (response.status == 200) ? "success" : "danger",
            },
          };
          const toast = useToast();
          toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
        })
      },
    },
    data() {
      return {
        maximo_productos_frecuentes: {
          idParametro: "",
          codigoParametro: "",
          valor: ""
        },
      };
    },
  };
</script>

