<template>
  <div class="row" v-if="bonificacion.prods_regalo.length > 0">
    <div class="col-lg-11 px-0">
      <div class="row mx-0">
        <div class="col-md-5 px-1">
          <h3 for="">Datos generales</h3>
        </div>
        <div class="col-md-7 px-1">
          <div class="row">
            <div class="col-md-6"></div>
            <div class="col-md-6 px-1">
              <Toggle
                @click="resetearRegalos"
                v-model="bonificacion.prods_regalo[0].tipo"
                :true_value="constantes.TIPO_REGALO_SOLES"
                :false_value="constantes.TIPO_REGALO_PRODUCTO"
                :disabled="es_edicion"
              ></Toggle>
              <span class="section-title">Bonificar soles</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="row"
    v-if="bonificacion.prods_regalo.length > 0"
    :class="bonificacion.tipo ? '' : 'laive-disabled'"
  >
    <div class="col-lg-11 px-0">
      <div class="row mx-0">
        <div class="col-md-5 col-sm-12 col-12 col-lg-5 pr-2">
          <div class="row">
            <div class="col-md-7 my-1 px-1">
              <GeneralInput
                v-model="bonificacion.descripcion"
                tipo="text"
                label="Descripción"
              ></GeneralInput>
            </div>
            <div class="col-md-5">
              <div class="row h-100">
                <div class="col-md-6 my-1 px-1">
                  <DateInput
                    v-model="bonificacion.inicio"
                    tipo="date"
                    label="Inicio"
                    :disabled-date="disabledDate"
                    :disabled="es_edicion && !bonificacion.puede_modificar_inicio"
                  ></DateInput>
                </div>
                <div class="col-md-6 my-1 px-1">
                  <DateInput
                    v-model="bonificacion.fin"
                    tipo="date"
                    label="Fin"
                    :disabled-date="disabledDate"
                    :disabled="es_edicion && !bonificacion.puede_modificar_fin"
                  ></DateInput>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-7 col-sm-12 col-12 col-lg-7">
          <div class="row">
            <div class="col-md-6">
              <div
                class="row"
                v-for="(prod_promo, index) in bonificacion.prods_promo"
                :key="prod_promo"
              >
                <div class="col-md-10 col-sm-8 col-8 my-1 px-0 pl-3">
                  <GeneralDropdown
                    v-model="prod_promo.productos"
                    tipo="date"
                    :label="`Producto ${index > 0 ? 'carrier' : 'a Impulsar'}`"
                    :options="productos_options"
                    :is_multiple="true"
                    :collapse_tags="false"
                    :filterable="true"
                    :disabled="es_edicion"
                    placeholder="Selecciona productos"
                  ></GeneralDropdown>
                </div>
                <div class="col-md-2 col-sm-4 col-4 my-1 px-1">
                  <GeneralInput
                    v-model="prod_promo.unidades"
                    tipo="number"
                    label="Unds"
                    placeholder="00"
                    wrapper_class="text-center"
                    :disabled="es_edicion"
                  ></GeneralInput>
                </div>
              </div>
              <div class="row d-flex justify-content-between px-0 pl-3" v-if="!es_edicion">
                <button
                  type="button"
                  class="bonificacion-producto-btn px-0 bonificacion-primary-text"
                  @click.prevent="agregarGrupoProducto"
                >
                  <i class="fas fa-plus-circle"></i>
                  <span class=""> Agregar Producto</span>
                </button>
                <button
                  type="button"
                  v-if="bonificacion.prods_promo.length > 1"
                  class="bonificacion-producto-btn px-1 text-danger"
                  @click.prevent="eliminarGrupoProducto"
                >
                  <i class="far fa-times-circle"></i>
                  <span class=""> Eliminar</span>
                </button>
              </div>
            </div>
            <div class="col-md-6">
              <div
                class="row"
                :class="{
                  'laive-disabled':
                    bonificacion.prods_regalo[0].tipo != 'producto',
                }"
              >
                <div class="col-md-10 col-sm-8 col-8 my-1 px-0 pl-2">
                  <GeneralDropdown
                    v-model="bonificacion.prods_regalo[0].productos"
                    label="Prod. Bonificacion"
                    :options="productos_options"
                    :is_multiple="true"
                    :collapse_tags="false"
                    :filterable="true"
                    placeholder="Selecciona un producto"
                    :disabled="bonificacion.prods_regalo[0].tipo != 'producto'|| es_edicion"
                  ></GeneralDropdown>
                </div>
                <div class="col-md-2 col-sm-4 col-4 my-1 px-1">
                  <GeneralInput
                    v-model="bonificacion.prods_regalo[0].unidades"
                    tipo="number"
                    label="Unds"
                    placeholder="00"
                    wrapper_class="text-center"
                    :disabled="bonificacion.prods_regalo[0].tipo != 'producto' || es_edicion"
                  ></GeneralInput>
                </div>
              </div>
              <div
                class="row"
                v-if="bonificacion.prods_regalo[0].tipo != 'producto'"
              >
                <div class="col-12" v-if="!es_edicion">
                  <div class="row mx-0">
                    <div class="col-auto"><b>Precio Normal S/.</b></div>
                    <div class="col-4 px-1 bonificacion-primary-text font-weight-600">{{precio_distribuidora_mayor.toFixed(2)}}</div>
                  </div>
                  <div class="row mx-0">
                    <div class="col-auto"><b>Precio Combo S/.</b></div>
                    <div class="col-4 px-1 bonificacion-primary-text font-weight-600">{{(precio_distribuidora_mayor-bonificacion.prods_regalo[0].valor).toFixed(2)}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="col-lg-1 my-1 px-1"
      :class="{
        'laive-disabled': bonificacion.prods_regalo[0].tipo != 'soles',
      }"
    >
      <GeneralInput
        v-model="bonificacion.prods_regalo[0].valor"
        tipo="number"
        label="Dscto S/"
        placeholder="00"
        wrapper_class="text-center"
        :disabled="bonificacion.prods_regalo[0].tipo != 'soles' || es_edicion"
      ></GeneralInput>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import GeneralInput from "./GeneralInput.vue";
import DateInput from "./DateInput.vue";
import GeneralDropdown from "./GeneralDropdown.vue";
import Toggle from "./Toggle.vue";
const store = useStore();
import constantes from "@/model/constantes.js";

const disabledDate = (time) => {
  let today = new Date().setHours(0, 0, 0, 0)
  return time.getTime() < today
}

const { bonificacion, es_edicion } = defineProps({
  bonificacion: {
    type: Object,
  },
  productos_options: Array,
  es_edicion: {
    type: Boolean,
    default: false,
  },
});
const agregarGrupoProducto = () => {
  bonificacion.prods_promo.push({});
};
const eliminarGrupoProducto = () => {
  bonificacion.prods_promo.pop();
};

const resetearRegalos = () => {
  if (es_edicion) {
    return;
  }
  bonificacion.prods_regalo[0].productos = [];
  bonificacion.prods_regalo[0].unidades = 0;
  bonificacion.prods_regalo[0].valor = 0;
};

const precio_distribuidora_mayor = computed(() => {
  if (es_edicion) {
    return 0;
  }
  const mayor_costo = bonificacion.costos_por_dex.reduce((maxObjeto, objetoActual) => {
    return objetoActual.costo_productos > maxObjeto.costo_productos
      ? objetoActual
      : maxObjeto;
  }, bonificacion.costos_por_dex[0]);

  return mayor_costo?.costo_productos ?? 0;
});

onMounted(() => {
  if (!es_edicion) {
    bonificacion.prods_promo = [{ productos: [], unidades: null }];
    bonificacion.prods_regalo = [
      {
        productos: [],
        unidades: null,
        tipo: constantes.TIPO_REGALO_PRODUCTO,
        valor: null,
      },
    ];

  }
});
</script>
<style lang="scss" scoped></style>
