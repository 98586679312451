<template>
  <div class="card">
    <div class="border-0 card-header">
      <h3 class="mb-0">Lista de Vendedores</h3>
    </div>

    <el-table
      class="table-responsive table-flush"
      header-row-class-name="thead-light"
      :data="projects"
    >
      <el-table-column label="Nombre" min-width="310px" prop="name" sortable>
        <template v-slot="{ row }">
          <div class="media align-items-center">
            <!-- <a href="#" class="avatar rounded-circle mr-3">
              <img alt="Image placeholder" :src="row.img" />
            </a> -->
            <div class="media-body">
              <span class="font-weight-600 name mb-0 text-sm">{{
                row.title
              }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="TOTAL DE VENTAS" prop="budget" min-width="140px" sortable></el-table-column>

      <el-table-column label="TOTAL DE PENDIENTES" min-width="170px" prop="status" sortable>
        <template v-slot="{ row }">
          <badge class="badge-dot mr-4" :type="row.statusType">
            <i :class="`bg-${row.statusType}`"></i>
            <span class="status">{{ row.status }}</span>
          </badge>
        </template>
      </el-table-column>

      <!-- <el-table-column label="Users" min-width="190px">
        <div class="avatar-group">
          <a
            href="#"
            class="avatar avatar-sm rounded-circle"
            data-toggle="tooltip"
            data-original-title="Ryan Tompson"
          >
            <img alt="Image placeholder" src="img/theme/team-1.jpg" />
          </a>
          <a
            href="#"
            class="avatar avatar-sm rounded-circle"
            data-toggle="tooltip"
            data-original-title="Romina Hadid"
          >
            <img alt="Image placeholder" src="img/theme/team-2.jpg" />
          </a>
          <a
            href="#"
            class="avatar avatar-sm rounded-circle"
            data-toggle="tooltip"
            data-original-title="Alexander Smith"
          >
            <img alt="Image placeholder" src="img/theme/team-3.jpg" />
          </a>
          <a
            href="#"
            class="avatar avatar-sm rounded-circle"
            data-toggle="tooltip"
            data-original-title="Jessica Doe"
          >
            <img alt="Image placeholder" src="/img/theme/team-4.jpg" />
          </a>
        </div>
      </el-table-column> -->

      <el-table-column label="PORCENTAJE DE AVANCE" prop="completion" min-width="240px" sortable>
        <template v-slot="{row}">
          <div class="d-flex align-items-center">
            <span class="completion mr-2">{{row.completion}}%</span>
            <div>
              <base-progress :type="row.statusType" :value="row.completion" />
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column min-width="180px">
        <el-dropdown trigger="click" class="dropdown">
          <span class="btn btn-sm btn-icon-only text-light">
            <i class="fas fa-ellipsis-v mt-2"></i>
          </span>
          <template #dropdown>
            <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show">
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <a class="dropdown-item" href="#">Something else here</a>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </el-table-column>
    </el-table>

    <div class="card-footer py-4 d-flex justify-content-end">
      <base-pagination v-model="currentPage" :total="50"></base-pagination>
    </div>
  </div>
</template>
<script>
import projects from "./../projects";
import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
} from "element-plus";
export default {
  name: "light-table",
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
  },
  data() {
    return {
      projects,
      currentPage: 1,
    };
  },
};
</script>
