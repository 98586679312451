<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1 pt-0 mt-0"
          footer-classes="pb-2"
        >
          <template v-slot:header>
            <h3 class="mb-0">Creación de Jerarquía</h3>
          </template>
          <div class="card-body">
            <form @submit.prevent="submiCreate">
              <div class="form-group row mb-0">
                <div class="col">
                  <label class="col-form-label form-control-label">Jerarquías:</label>
                  <select class="form-control" style="text-transform: capitalize;;height: 43px;" name="jerarquias_select" required @change="changeJerarquia">
                    <option value="" disabled selected style="display:none">Seleccionar</option>
                    <option value="marca">marcas</option>
                    <option value="categoria">categorias</option>
                    <option value="presentacion">presentaciones</option>
                    <option value="familia">familias</option>
                  </select>
                </div>
                <div class="col" v-if="showCategories">
                  <label class="col-form-label form-control-label">Categorias:</label>
                  <select class="form-control" required style="display: inline-block;height: 43px;" name="category_select" @change="changeCategory">
                    <option value="" selected disabled="true">Todos</option>
                    <option
                      v-for="item in categories"
                      :value="item.codigo"
                      :key="item.codigo"
                    >
                      {{ item.nombre }}
                    </option>
                  </select>
                </div>
                <div class="col">
                  <label class="col-form-label form-control-label">Código:</label>
                  <base-input
                    name="code_category"
                    placeholder="Código"
                    required="required"
                    :value="dataSend.codigoDato"
                    v-model="dataSend.codigoDato"
                  ></base-input>
                </div>
                <div class="col">
                  <label class="col-md-12 col-form-label form-control-label">Nombre:</label>
                  <base-input
                    name="name_category"
                    placeholder="Nombre"
                    required="required"
                    :value="dataSend.nombre"
                    v-model="dataSend.nombre"
                  ></base-input>
                </div>
              </div>
              <base-button type="primary" native-type="submit" class="btn-default my-0">Crear Jerarquía</base-button>
            </form>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {mapActions, mapGetters} from 'vuex';
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
export default {
  components: {
    RouteBreadCrumb,
  },
  methods: {
    ...mapActions({
      maestrosGetList: 'maestros/getList',
    }),
    changeJerarquia(evn){
      const select = evn.target
      const value = select.value;
      this.dataSend.codigoTipo = value
      if (value == "familia") {
        this.showCategories = true
      }
    },
    changeCategory(evn){
      const select = evn.target
      const value = select.value;
      this.dataSend.valor2 = value
      this.dataSend.valorAsociado = select.options[select.selectedIndex].text
    },
    submiCreate(values) {
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let createProduct = process.env.VUE_APP_API_URL+"/maestro/crear"
      document.querySelector(".form_laive").classList.add("validateClient")
      axios.post(createProduct, this.dataSend,config)
      .then(function (response){
        document.querySelector(".form_laive").classList.remove("validateClient")
        const text = (response.status == 200) ? "Se creó la Jearquia" : "Hubo un error";
        const icon = "ni ni-bell-55";
        const content = {
          component: Notification,
          props: {
            icon: icon,
            text: text,
            type: (response.status == 200) ? "success" : "danger",
          },
        };
        const toast = useToast();
        toast(content, {
          hideProgressBar: true,
          icon: false,
          closeButton: false,
          position: "top-right",
        });
        setTimeout(() => {
          $this.$router.replace({name: 'Jerarquía de Productos'})
        }, 2000);
      })
      // alert(JSON.stringify(values, null, 2));
    }
  },
  async mounted() {
    let result = await this.maestrosGetList();
    if(result.data) {this.categories = result.data.categorias}
  },
  data() {
    return {
      dataSend: {
        codigoTipo: "",
        codigoDato: "",
        nombre: "",
        valorAsociado: "",
        valor2: "",
      },
      showCategories:false,
      categories:[],
    };
  },
}
</script>
<style lang="scss">
  .dropzone{
    width:200px;
  }
  .dz-message{
    padding: 1rem 1rem !important;
  }
  .dropzone-single.dz-max-files-reached .dz-message{
    background-color: rgba(219, 219, 219, 0.349) !important;
    border: 1px dashed #dee2e6 !important;
    opacity: 1 !important;
  }
  #product_prewview{
    .dz-preview-cover{
      position: relative;
      width:200px;
      padding-bottom: 0.5rem;
    }
  }
</style>
