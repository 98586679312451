<template>
  <label class="switch">
    <input type="checkbox" v-model="value" :disabled="disabled" />
    <span class="slider round" :class="always_enabled ? 'enabled' : ''"></span>
  </label>
</template>

<script>
export default {
  props: {
    modelValue: String,
    true_value: { type: String, default: "1" },
    false_value: { type: String, default: "0" },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      value: this.modelValue == this.true_value,
    };
  },
  methods: {},
  watch: {
    value(nuevo, antiguo) {
      if(!this.disabled){
        const result = nuevo == true ? this.true_value : this.false_value;
        this.$emit("update:modelValue", result);
      }
    },
  },
};
</script>
<style scoped>
.laive-toggle-state {
  font-size: 12px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
  margin: 0px 5px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 1px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #02a54f;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.slider.enabled {
  background-color: #02a54f;
}
</style>
