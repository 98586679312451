<template>
  <component :is="as" class="ui-text" :style="[cssProps]">
    <slot>
      {{ text }}
    </slot>
  </component>
</template>

<script>
import { ThemeProps } from './utils';
export default {
  props: {
    as: {
      type: String,
      default: "span",
      validator(as) {
        return ["p", "span"].includes(as);
      }
    },
    text: String,
    ...ThemeProps.textProps
  },
  computed: {
    cssProps() {
      return ThemeProps.styleText(this.$props);
    }
  }
}
</script>

<style scoped>
.ui-text {
  font-size: var(--ui-fs);
  font-weight: var(--ui-fw);
  color: var(--ui-text-color);
}
</style>




