<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div :class="{validateClient:isLoading}" class="form_laive">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template v-slot:header>
            <div class="row">
              <div class="col-12 col-md-6">
                <h3 class="mb-0">Reporte de usuarios</h3>
              </div>
              <div class="col-12 col-md-6 text-right">
                <router-link :to="`${this.$route.matched[0].path}`+`/crear/`"><base-button size="sm" type="info">Crear Usuario</base-button></router-link>
              </div>
            </div>
          </template>
          <div>
            <div class="row px-3">
              <div class="col-12 col-md-4 d-none">
                <div class="mr-2">
                  <span class="title_filter">Paginación:  </span>
                  <el-select
                    class="select-primary pagination-select"
                    v-model="lTable.pagination.perPage"
                    style="width:80px"
                    placeholder="Per page"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in lTable.pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <!-- Real Table -->
            <el-table
              :data="lTable.data"
              row-key="idProducto"
              header-row-class-name="thead-light"
              empty-text="No se encontraron resultados"
            >
              <el-table-column
                v-for="column in lTable.columns"
                :key="column.label"
                v-bind="column"
              >
              </el-table-column>
              <el-table-column min-width="100px" align="right">
                <template v-slot:default="props">
                  <div class="d-flex" v-if="props.row.rol !== '4'">
                    <router-link :to="`./editar/${props.row.idUsuario}/${props.row.codigo}/${props.row.idDistribuidora}/${props.row.rol}`" class="btn base-button btn-success btn-sm edit">Editar</router-link>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <template v-slot:footer>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category d-none">
                  Mostrando {{ lTable.from + 1 }} a {{ lTable.to }} de {{ lTable.total }} productos
                </p>
              </div>
              <default-pagination
                class="pagination-no-border"
                v-model="lTable.pagination.currentPage"
                :per-page="lTable.pagination.perPage"
                :total="lTable.total"
              >
              </default-pagination>
            </div>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import axios from "axios";
import { mapActions, mapGetters } from 'vuex';
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BasePagination from "@/components/BasePagination";
import { LiquidTable } from "../../util/liquid";
import DashboardNavbar from "../Layout/DashboardNavbar";
export default {
  components: {
    DashboardNavbar,
    BasePagination,
    RouteBreadCrumb,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  methods:{
    ...mapActions({getListUsers:'user/getList'}),
  },
  created(){
      this.lTable = new LiquidTable();
      this.lTable.pagination =  {
        perPage: 1,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      };
      this.lTable.columns = [
        { prop: "codigo",  label: "Usuario",  minWidth: 90 },
        { prop: "nombre",  label: "Nombre",  minWidth: 255 },
        { prop: "nombreRol",  label: "Rol",  minWidth: 255 }
      ];
  },
  mounted(){
    this.getUsuarios
  },
  computed: {
    async getUsuarios() {
      this.isLoading = true;
      let result = await this.getListUsers();
      if(result.data){
        this.lTable.data = result.data;
        this.lTable.pagination.perPage =  result.data.length;
      }
      this.isLoading = false;
    },
  },

  data() {
    return {
      searchQuery: "",
      jerarquias: [],
      fuseSearch: null,
      searchedData: [],
      expands: [],
      isLoading:false,
      lTable:LiquidTable
    };
  },
};
</script>
<style>
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
.no-border-card .card-footer {
  border-top: 0;
}
</style>
