import axios from "axios";
import setting from "./setting";
import { request } from "../util/request";

const URL = `${setting.http.base_url}/cliente`;

const getAddressByClientId = async ({ customerCode, idDistributor }) => {
  const path = "/cliente/list/dropdown/customer/addresses";
  return await request.post(path, { customerCode, idDistributor });
};

const getClientInfo = async ({
  customerCode,
  idDistributor,
  addressCode,
}) => {
  const path = "/cliente/list/dropdown/customer/info";
  return await request.post(path, {
    customerCode,
    idDistributor,
    addressCode,
  });
};

const listByFilters = async (token, filters) => {
  const { data } = await axios.post(`${URL}/lista`, filters, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return data;
};

const searchByName = async (dex, nameOrCode) => {
  const isCode = !isNaN(nameOrCode);
  const params = {
    idDistributor: dex,
    customerCode: isCode ? nameOrCode : null,
    customerName: !isCode ? nameOrCode : null,
  };
  const { data } = await request.post(
    `/cliente/list/dropdown/customer`,
    params
  );
  return data;
};

export default {
  getAddressByClientId,
  listByFilters,
  getClientInfo,
  async uploadComodatoFile(token, codeClient, formData) {
    const { data } = await axios.post(
      `${URL}/subir/${codeClient}/comodato`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  },
  searchByName,
};
