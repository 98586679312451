<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <!-- HTML5 inputs -->
            <div class="card">
              <!-- Card header -->
              <div class="card-header">
                <h3 class="mb-0">Notificaciones Operativas</h3>
              </div>
              <div class="card-body form_laive">
                <div class='row'>
                  <h5 class='col-12 text-muted mb-0'>Cualquier valor ingresado como 0, desactivará la notificación en el APP</h5>
                </div>
                <div class="row">
                  <div v-for="item in parameterData" class='form_laive col-md-4' :key="item.titulo" :class="{ 'validateClient':item.await}">
                      <div class="box_loader">
                        <div class="loader"></div>
                      </div>
                      <div class="form-group row">
                        <label class="col-md-12 col-form-label form-control-label">{{item.titulo}}</label>
                        <div v-if='!item.inputs'  class='col-md-12'>
                          <!--NUMBER or Text-->
                          <base-input v-if="(item.type == 'number' || item.type == 'text')"
                            required
                            :name="item.codigo"
                            :placeholder="item.placeholder"
                            v-model ='item.valor1'
                            @keypress="isNumber($event)"
                          >
                            <template v-slot:addonLeft>{{item.addon ? item.addon : ''}}</template>
                          </base-input>

                          <!-- SELECT TYPE -->
                          <select v-if="(item.type == 'select')" class="form-control mb-4" v-model='item.valor1'>
                            <option
                              v-for="st in statesData"
                              :key="st.value"
                              :value="st.value"
                              >
                              {{ st.name }}
                            </option>
                          </select>
                          <!-- picker-->
                          <base-input :name="item.codigo"  v-if="(item.type == 'time')">
                            <flat-picker :config="configDate" :placeholder="item.placeholder" class="form-control datepicker" v-model ='item.valor1'></flat-picker>
                          </base-input>

                          <a  @click='updateParameter(item)' class="btn btn-default my-0">Actualizar</a>
                        </div>

                        <!-- cuando el parametro tiene mas de 2 campos -->
                        <div v-else v-for="subitem in item.inputs" class='col-md-6' :key="subitem.value">
                          <!--NUMBER or Text-->
                          <base-input v-if="(subitem.type == 'number' || subitem.type == 'text')"
                            required
                            :name="item.codigo"
                            :placeholder="subitem.placeholder"
                            v-model ='subitem.value'
                            @keypress="isNumber($event)"
                          >
                            <template v-slot:addonLeft>{{subitem.addon ? subitem.addon : ''}}</template>
                          </base-input>

                          <!-- picker hour - min -->
                          <base-input :name="subitem.codigo"  v-if="(subitem.type == 'time')">
                            <flat-picker :config="subitem.date_config" :placeholder="subitem.placeholder" class="form-control datepicker" v-model ='subitem.value'></flat-picker>
                          </base-input>

                          <a v-if='(subitem.index == 1)' @click='updateParameterInputs(item)' class="btn btn-default my-0">Actualizar</a>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from "axios";
  import { mapActions, mapGetters } from 'vuex';
  import { useToast } from "vue-toastification";
  import Notification from "@/components/Notification";
  import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
  import DashboardNavbar from "../Layout/DashboardNavbar";
  import flatPicker from "vue-flatpickr-component";
  import {Spanish} from 'flatpickr/dist/l10n/es.js';
  import "flatpickr/dist/flatpickr.css";
  import {LiquidAlert } from '../../util/liquid';
  export default {
    components: {
      DashboardNavbar,
      RouteBreadCrumb,
      flatPicker,
    },
    created(){
      this.getListParametersData;
    },
    computed:{
      async getListParametersData(){
        const result = await this.getListParameters();
        this.parameterData = result.data;
        this.parameterData.map(p=>{
          switch (p.codigo){
            case 'aa_cliente_no_gestionado' :
              p.titulo = p.titulo ? p.titulo :'Cliente no gestionado';
              p.placeholder = 'Número de clientes';
              p.addon = 'N°';
              p.type = 'number';
            break;
            case 'aa_maximo_hora_inicio' :
              p.titulo =p.titulo = p.titulo ? p.titulo :p.titulo = p.titulo ? p.titulo :'Alerta hora de inicio de operaciones';
              p.inputs = [
                {
                  index:1,
                  placeholder:'Indicar numero de clientes',
                  addon:'MM',
                  type:'time',
                  date_config:this.configDateMin,
                  value : p.valor1
                },
                {
                  index:2,
                  placeholder:'Indicar distancia en metros',
                  addon:'MM',
                  type:'time',
                  date_config:this.configDate24H,
                  value : p.valor2
                }
              ];
            break;
            case 'aa_maximo_tiempo_mismo_lugar' :

              let cnf = this.configDateMin;
              cnf.maxTime = '02:00';
              p.titulo =p.titulo = p.titulo ? p.titulo :p.titulo = p.titulo ? p.titulo :'Alerta de exceso de tiempo en el mismo lugar';
              p.inputs = [
                {
                  index:1,
                  placeholder:'',
                  addon:'MM',
                  type:'time',
                  date_config:cnf,
                  value : p.valor1
                },
                {
                  index:2,
                  placeholder:'',
                  addon:'Mt',
                  type:'number',
                  date_config:this.configDateMin,
                  value : p.valor2
                }
              ];
            break;
            case 'aa_no_leida' :
              p.titulo =p.titulo = p.titulo ? p.titulo :'Notificaciones no leídas por los vendedores';
              p.placeholder = 'Indicar porcentaje';
              p.addon='%';
              p.type = 'number';
            break;
            case 'ap_cliente_top_oro_nopedido' :
              p.titulo =p.titulo = p.titulo ? p.titulo :'Cliente top u otro, tienen No pedido';
              p.placeholder = 'Número de clientes';
              p.addon='N°';
              p.type = 'text';
            break;
            case 'ap_fuera_coordenada' :
              p.titulo =p.titulo = p.titulo ? p.titulo :'Operación fuera de la coordenada del cliente';
              p.inputs = [
                {
                  index:1,
                  placeholder:'Indicar numero de clientes',
                  addon:'N°',
                  type:'number',
                  value : p.valor1
                },
                {
                  index:2,
                  placeholder:'Indicar distancia en metros',
                  addon:'Mt',
                  type:'number',
                  value : p.valor2
                }
              ];
            break;
            case 'ap_fuera_ruta' :
                p.titulo =p.titulo = p.titulo ? p.titulo :'Operación fuera de ruta';
                p.placeholder = 'indicar número de clientes';
                p.addon='N°';
                p.type = 'number';
              break;
            case 'tiempo_leido_minutos':
                p.titulo =p.titulo = p.titulo ? p.titulo :'Tiempo leído en minutos';
                p.placeholder = 'ingrese un valor entre 0 y dos horas';
                p.addon='MM';
                p.type = 'time';
              break;
            case 'aa_cliente_bajo_ticket_promedio' :
                p.titulo =p.titulo = p.titulo ? p.titulo :'Clientes por debajo del ticket promedio';
                p.placeholder = 'Número de clientes';
                p.addon='N°';
                p.type = 'text';
              break;
            case 'aa_cliente_con_recencia' :
                p.titulo = p.titulo = p.titulo ? p.titulo :'Clientes con recencia';
                p.placeholder = 'Número de clientes';
                p.addon='N°';
                p.type = 'text';
              break;
            default :{ console.log(p);}
          }
          console.log(p)
          return p;
        });
      }
    },
    methods: {
      ...mapActions({
        getListParameters:'parameter/getList',
        setNotificacionParameter:'parameter/setNotificacionParameter'
      }),
      isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault();;
        } else {
          return true;
        }
      },
      async updateParameter(element){
        element.await = true;
        let body = { id:element.id,codigo:element.codigo,valor1:element.valor1,valor2:element.valor2};
        let result = await this.setNotificacionParameter(body);
        console.log(result)
        if(result.data.status == 200){LiquidAlert.success('Guardado con éxito','Guardado con éxito')}
        else {LiquidAlert.error(result.data.message,'Error de validación')}
        element.await = false;
      },
      async updateParameterInputs(element){
        element.await = true;
        let valor1 = element.inputs.find(x=>x.index==1);
        let valor2 = element.inputs.find(x=>x.index==2);
        if(valor1 && valor2){
          let body = { id:element.id,codigo:element.codigo,valor1:valor1.value,valor2:valor2.value};
          console.log(body)
          let result = await this.setNotificacionParameter(body);
          if(result.data.status == 200){LiquidAlert.success('Guardado con éxito','Guardado con éxito')}
          else {LiquidAlert.error(result.data.message,'Error de validación')}
        }

        element.await = false;
      }
    },
    data() {
      return {
        configDate: {
          altInput: true,
          allowInput: true,
          noCalendar: true,
          enableTime: true,
          dateFormat: "H:i",
          altFormat:'H:i',
          time_24hr: true,
          // time_24hr: true,
          locale: Spanish, // locale for this instance only
        },
        configDate24H: {
          altInput: true,
          allowInput: true,
          noCalendar: true,
          enableTime: true,
          time_24hr: false,
          // time_24hr: true,
          locale: Spanish, // locale for this instance only
        },
        configDateMin: {
          altInput: true,
          allowInput: true,
          noCalendar: true,
          enableTime: true,
          dateFormat: "i",
          altFormat:'i',
          time_24hr: true,
          minTime: "00:00",
          maxTime: "00:59",
          minuteIncrement:1,
          // time_24hr: true,
          locale: Spanish, // locale for this instance only
        },
        parameterData:[],
        statesData:[
          {name:'Activo',value:"1"},
          {name:'Inactivo',value:"0"}
        ]
      };
    },
  };
</script>

