<template>
  <Incidencias v-if="currentTab == 'incidencias'" />
  <CargaEquipos v-else-if="currentTab == 'carga'" />
  <MaestroActivos v-else />
</template>
<script>
import MaestroActivos from "./MaestroActivos.vue"
import CargaEquipos from "./CargaEquipos.vue"
import Incidencias from "./Incidencias.vue";
export default {
  components: {
    MaestroActivos,
    CargaEquipos,
    Incidencias
  },
  computed: {
    currentTab() {
      return this.$route.query["tab"] ?? "maestro"
    }
  }
}
</script>
