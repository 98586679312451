<template>
  <component :is="as" :class="['ui-flex']" :style="[cssProps]">
    <slot></slot>
  </component>
</template>

<script>
import { PropsHandler } from '../utils';


const JUSTIFY = {
  center: "center",
  start: "flex-start",
  end: "flex-end",
  between: "space-between",
  around: "space-around",
  normal: "normal",
  evenly: "space-evenly"
}

const ALIGN = {
  center: "center",
  normal: "normal",
  start: "flex-start",
  end: "flex-end",
}

export default {
  props: {
    as: {
      type: String,
      default: "div"
    },
    dir: {
      type: String,
      default: "row",
      validator(dir) {
        return ["row", "column"].includes(dir);
      }
    },
    align: {
      type: String,
      default: "normal",
      validator(align) {
        return Object.keys(ALIGN).includes(align);
      }
    },
    justify: {
      type: String,
      default: "normal",
      validator(justify) {
        return Object.keys(JUSTIFY).includes(justify);
      }
    },
    gap: {
      type: String,
      default: '1em',
    }
  },
  computed: {
    cssProps() {
      return {
        "--dir": this.dir,
        "--align": ALIGN[this.align],
        "--justify": JUSTIFY[this.justify],
        gap: PropsHandler.spacing(this.gap),
      }
    }
  }
}
</script>

<style scoped>
.ui-flex {
  display: flex;
  flex-direction: var(--dir);
  align-items: var(--align);
  justify-content: var(--justify);
  gap: var(--gap);
}
</style>
