<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive validateClient">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <template v-if="type == 'default' && this.$route.params.id != 'preguntacluster'">
          <DefaultMaestro :dataList="dataTable"></DefaultMaestro>
        </template>
        <template v-else-if="type == 'default' && this.$route.params.id == 'preguntacluster'">
          <PreguntaClusterMaestro :dataList="dataTable" ></PreguntaClusterMaestro>
        </template>
        <template v-else-if="type == 'ubigeo'">
          <UbigeoMaestro></UbigeoMaestro>
        </template>
        <template v-else-if="type == 'mercados'">
          <MercadoMaestro></MercadoMaestro>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import DefaultMaestro from "../Components/DefaultMaestro.vue";
import UbigeoMaestro from "../Components/UbigeoMaestro.vue";
import MercadoMaestro from "../Components/MercadoMaestro.vue";
import PreguntaClusterMaestro from "../Components/PreguntaClusterMaestro.vue";
export default {
  components: {
    RouteBreadCrumb,
    DefaultMaestro,
    UbigeoMaestro,
    MercadoMaestro,
    PreguntaClusterMaestro
  },
  created(){
    const $this = this
    const id = this.$route.params.id
    const token = localStorage.getItem("token")
    const config = {
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    };
    this.type = this.$route.query.type
    if(this.type == 'default'){
      axios.get(process.env.VUE_APP_API_URL+"/maestro/lista/"+id,config)
      .then(function (response){
        document.querySelector(".form_laive").classList.remove("validateClient")
        $this.dataTable = response.data
      })
    }
  },
  data() {
    return {
      type: "",
      dataTable: [],
      showModal: false
    };
  },
}
</script>
