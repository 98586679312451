import { computed } from "vue";

import { REQUEST, REQUEST_TYPE } from "../../model/equipos";

export const useRequests = ({ props }) => {
  const idSolicitud = computed(() => props.idSolicitud);
  const isNewEquipment = computed(() => props.request === REQUEST.NUEVO_EQUIPO);
  const isRecojo = computed(() => props.request === REQUEST.RECOJO);
  const isReport = computed(() => props.request === REQUEST.REPORTAR);

  const isQrChange = computed(
    () => props.requestType === REQUEST_TYPE.CAMBIO_QR
  );
  const isLossEquipment = computed(
    () => props.requestType === REQUEST_TYPE.EQUIPO_PERDIDO
  );
  const isFoundEquipment = computed(
    () => props.requestType === REQUEST_TYPE.EQUIPO_ENCONTRADO
  );

  const titleModal = computed(() => {
    if (isReport.value) {
      if (isFoundEquipment.value) return "DETALLE - EQUIPO ENCONTRADO";
      else if (isQrChange.value) return "DETALLE - CAMBIO QR";
      else if (isLossEquipment.value) return "DETALLE - EQUIPO PERDIDO";
    } else if (isNewEquipment.value) {
      return "DETALLE - NUEVO EQUIPO DE FRÍO";
    } else if (isRecojo.value) return "DETALLE - RECOJO DE EQUIPO";

    return "";
  });

  const requestText = computed(() => {
    if (isNewEquipment.value) return "Nuevo equipo de frío";
    if (isRecojo.value) return "Recojo de equipo";
    if (isReport.value) return "Reportar";

    return "";
  });

  const requestTypeText = computed(() => {
    if (isReport.value) {
      if (isFoundEquipment.value) return "Equipo encontrado";
      if (isQrChange.value) return "Cambiar QR";
      if (isLossEquipment.value) return "Equipo perdido";
    }

    return "-";
  });

  return {
    idSolicitud,
    isFoundEquipment,
    isLossEquipment,
    isNewEquipment,
    isQrChange,
    isRecojo,
    isReport,
    requestText,
    requestTypeText,
    titleModal,
  };
};
