<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive validateClient">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template v-slot:header>
            <div class="row">
              <div class="col-12 col-md-6">
                <h3 class="mb-0">Por Vendedor</h3>
              </div>
              <!-- <div class="col-12 col-md-6 text-right">
                <button type="button" class="btn base-button btn-primary btn-sm" role="button"><i class="fas fa-file-export"></i>Exportar Pedidos </button>
              </div> -->
            </div>
          </template>
          <div>
            <div class="row px-3">
              <div class="col-12 mb-3">
                <div class="row">
                  <div class="col-12 col-md-3">
                    <template v-if="distribuidoraIdCurrent">
                      <div class="title_filter mr-2"><div>Distribuidora:</div>  <strong>{{distribuidoraNameCurrent}}</strong></div>
                    </template>
                    <template v-else>
                      <span class="title_filter mr-2">Distribuidoras:  </span>
                      <select class="form-control" style="display: inline-block;height: 43px;" name="distributor_select" @change="changeListbyDistribuidora">
                        <option value="0">Todos</option>
                        <option
                          v-for="item in distribuidoras"
                          :value="item.idDistribuidora"
                          :key="item.idDistribuidora"
                          >
                          {{ item.razonSocial }}
                        </option>
                      </select>
                    </template>
                  </div>
                  <div class="col-12 col-md-3">
                    <template v-if="mesaCurrent">
                      <div class="title_filter mr-2"><div>Mesa:</div>  <strong>{{mesaCurrent}}</strong></div>
                    </template>
                    <template v-else>
                      <span class="title_filter mr-2">Mesa:  </span>
                      <select class="form-control" style="display: inline-block;height: 43px;" name="mesa_select" @change="changeListMesa">
                        <option value="">Todos</option>
                        <option
                            v-for="item in mesas"
                            :value="item.codigoDato"
                            :key="item.codigoDato"
                            >
                            {{ item.nombre }}
                          </option>
                      </select>
                    </template>
                  </div>
                  <div class="col-12 col-md-3">
                    <span class="title_filter mr-2">Vendedor:  </span>
                    <select class="form-control" id="vendedor_select" style="display: inline-block;height: 43px;" name="vendedor_select" @change="changeListbyVendedor">
                      <option value="">Todos</option>
                      <option
                        v-for="item in vendedores"
                        :value="item.codigoVendedor"
                        :key="item.codigoVendedor"
                        >
                        {{ item.nombre }} {{ item.apellidos }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <!-- Real Table -->
            <div class="row px-3">
              <div class="col-12">
                <template v-if="queriedData.length">
                  <template v-for="table in queriedData" :key="table.codigoMesa">
                    <div class="border-0 card-header">
                      <h3 class="mb-0">{{table.nombreMesa}}</h3>
                    </div>
                    <el-table
                      :data="table.detalle"
                      row-key="reportDetail"
                      header-row-class-name="thead-light"
                      empty-text="No se encontraron resultados"
                    >
                      <el-table-column label="Vendedor" min-width="300px" prop="nombreVendedor">
                        <template v-slot="{ row }">
                          {{ row.nombreVendedor }}
                        </template>
                      </el-table-column>
                      <el-table-column label="Monto Cobrado" min-width="200px" prop="montoCobrado">
                        <template v-slot="{ row }">
                          <template v-if="row.montoCobrado == 0">0</template>
                          <template v-else>S/{{formatNumber(row.montoCobrado)}}</template>
                        </template>
                      </el-table-column>
                    </el-table>
                  </template>
                </template>
                <template v-else>
                  <div class="col-12 text-center">
                    <el-table
                      :data="tableReporte"
                      row-key="totalreporte"
                      header-row-class-name="thead-light"
                      empty-text="Debe Seleccionar una distribuidora"
                    >
                    </el-table>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import axios from "axios";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import TabPane from "@/components/Tabs/TabPane";
import BasePagination from "@/components/BasePagination";
import {mapGetters} from 'vuex';
import swal from "sweetalert2";
import DashboardNavbar from "../Layout/DashboardNavbar";
export default {
  components: {
    DashboardNavbar,
    BasePagination,
    RouteBreadCrumb,
    TabPane,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  computed: {
    pagedData() {
      this.getDistributor
      this.getFechas
      //this.getMesas
      this.getDataReporte
      return this.tableReporte;
    },
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
    },
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    }),
    getDistributor(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let urlApiDistribuidoras = process.env.VUE_APP_API_URL+"/distribuidoras/lista"
      const requestDistribuidoras = axios.get(urlApiDistribuidoras, config);
      axios.all([requestDistribuidoras]).then(axios.spread((...responses) => {
        const responseDistribuidoras = responses[0].data
        if($this.distribuidoraIdCurrent){
          let dataDistibuitorCurrent =  responseDistribuidoras.filter((item)=>{return item.idDistribuidora == $this.distribuidoraIdCurrent})
          $this.distribuidoraNameCurrent = dataDistibuitorCurrent[0].razonSocial
        }
        $this.distribuidoras = responseDistribuidoras.filter((item)=>{return item.estado == "1"})
        document.querySelector(".form_laive").classList.remove("validateClient")
        return $this.distribuidoras;
      })).catch(errors => {
        // react on errors.
      })
    },
    getMesas(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const urlApiFechas = process.env.VUE_APP_API_URL+"/maestro/lista/mesa"
      if($this.mesas.length == 0){
        axios.get(urlApiFechas,config)
        .then(function (response){
          console.log(response.data, " ====lista de mesas");
          return $this.mesas = response.data
        })
      }
    },
    async getFechas(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const urlApiFechas = process.env.VUE_APP_API_URL+"/maestro/lista/listafecha"
      if($this.fechas.length == 0){
        await axios.get(urlApiFechas,config)
        .then(function (response){
          console.log(response.data, " ====lista de fechas");
          return $this.fechas = response.data
        })
      }
    },
    getDataReporte(){
      const $this = this
      let idDistribuidora = Number(this.distribuidoraIdCurrent)
      if(idDistribuidora){
        let idVendedor = document.querySelector("select[name='vendedor_select']").value
        let idMesa = (this.mesaCurrent) ? this.mesaCurrent : document.querySelector("select[name='mesa_select']").value
        console.log({idMesa})
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        const parameters = {
          "iddistribuidora": idDistribuidora,
          "codigosupervisor": "",
          "codigomesa": idMesa,
          "codigovendedor": idVendedor,
        }
        const urlApiReporte = process.env.VUE_APP_API_URL+`/cobranza/report/cobranzamesa`
        const dataReporte = axios.post(urlApiReporte,parameters,config)
        const urlApiVendedores = process.env.VUE_APP_API_URL+`/vendedor/lista/pormesa`
        const dataVendedorbyMesa = axios.post(urlApiVendedores,parameters,config)
        axios.all([dataReporte, dataVendedorbyMesa]).then(axios.spread((...responses) => {
          const resDataByDistribuidora = responses[0].data
          const resDataByVendedor = responses[1].data
          $this.vendedores = resDataByVendedor
          $this.tableReporte = resDataByDistribuidora
          document.querySelector(".form_laive").classList.remove("validateClient")
        })).catch(errors => {
          // react on errors.
        })
      }
    },
  },
  methods:{
    changeListbyDistribuidora(evn){
      const $this = this
      let selected = Number(evn.target.value)
      if(selected){
        let idMesa = document.querySelector("select[name='mesa_select']").value
        let idVendedor = document.querySelector("select[name='vendedor_select']").value
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        const parameters = {
          "iddistribuidora": selected,
          "codigoMesa": idMesa,
          "codigoVendedor": idVendedor,
          "codigoSupervisor": ""
        }
        const paramsMesa = {
          "iddistribuidora": selected,
          "tipo": "mesas",
        }
        document.querySelector(".form_laive").classList.add("validateClient")
        const urlApiReporte = process.env.VUE_APP_API_URL+`/cobranza/report/cobranzamesa`
        const dataReporte = axios.post(urlApiReporte,parameters,config)
        const urlApiVendedores = process.env.VUE_APP_API_URL+`/vendedor/lista`
        const getVendedores = axios.post(urlApiVendedores,parameters,config)
        const urlApiMesa = process.env.VUE_APP_API_URL+`/maestro/lista/pordistribuidora`
        const getMesas = axios.post(urlApiMesa,paramsMesa,config)
        axios.all([dataReporte,getVendedores, getMesas]).then(axios.spread((...responses) => {
          const resDataByDistribuidora = responses[0].data
          const resListVendedores = responses[1].data
          const dataMesas = responses[2].data
          $this.tableReporte = resDataByDistribuidora
          $this.vendedores = resListVendedores
          $this.mesas = dataMesas
          console.log({dataMesas})
          document.querySelector(".form_laive").classList.remove("validateClient")
        })).catch(errors => {
          // react on errors.
        })
      }else{
        document.querySelector("select[name='supervisor_select']").value = ""
        this.tableReporte = []
        const swalWithBootstrapButtons1 = swal.mixin({
          customClass: {
            confirmButton: "btn btn-success btn-fill",
          },
          buttonsStyling: false,
        });
        swalWithBootstrapButtons1.fire({
          title: `Seleccionar una distribuidora`,
          confirmButtonText: "Ok",
        });
      }
    },
    changeListMesa(evn){
      const selected = evn.target.value
      const $this = this
      const token = localStorage.getItem("token")
      let idDistribuidora = ($this.distribuidoraIdCurrent) ? $this.distribuidoraIdCurrent : Number(document.querySelector("select[name='distributor_select']").value)
      let idVendedor = document.querySelector("select[name='vendedor_select']").value
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const parameters = {
        "iddistribuidora": idDistribuidora,
        "codigomesa": selected,
        "codigoVendedor": ""
      }
      if(idDistribuidora){
        document.querySelector(".form_laive").classList.add("validateClient")
        const urlApiVendedores = process.env.VUE_APP_API_URL+`/vendedor/lista/pormesa`
        const urlApiReporte = process.env.VUE_APP_API_URL+`/cobranza/report/cobranzamesa`
        const dataVendedorbyMesa = axios.post(urlApiVendedores,parameters,config)
        const dataReporte = axios.post(urlApiReporte,parameters,config)
        axios.all([dataVendedorbyMesa, dataReporte]).then(axios.spread((...responses) => {
          const resVendedorbyMesa = responses[0].data
          const resDataReport = responses[1].data
          $this.vendedores = resVendedorbyMesa
          $this.tableReporte = resDataReport
          document.querySelector(".form_laive").classList.remove("validateClient")
        })).catch(errors => {
          // react on errors.
        })
      }else{
        const swalWithBootstrapButtons1 = swal.mixin({
          customClass: {
            confirmButton: "btn btn-success btn-fill",
          },
          buttonsStyling: false,
        });
        swalWithBootstrapButtons1.fire({
          title: `Seleccionar una distribuidora`,
          confirmButtonText: "Ok",
        });
      }
    },
    changeListbyVendedor(evn){
      const selected = evn.target.value
      const $this = this
      const token = localStorage.getItem("token")
      let idDistribuidora = ($this.distribuidoraIdCurrent) ? $this.distribuidoraIdCurrent : Number(document.querySelector("select[name='distributor_select']").value)
      let idMesa = document.querySelector("select[name='mesa_select']").value
      if(idDistribuidora){
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        const parameters = {
          "iddistribuidora": idDistribuidora,
          "codigomesa": idMesa,
          "codigoVendedor": selected
        }
        axios.post(process.env.VUE_APP_API_URL+"/cobranza/report/cobranzamesa",parameters,config)
        .then(function (response){
          console.log(response.data, " ====lista de reporte");
          document.querySelector(".form_laive").classList.remove("validateClient")
          $this.emptyText = "No se encontraron resultados"
          $this.initload = false
          return $this.tableReporte = response.data
        })
      }
    },
    formatNumber(num) {
      var nf = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      return nf.format(num)
		  // return new Intl.NumberFormat("en-IN").format(num)
	  }
  },
  mounted() {
    if(this.user.rol == 3){
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
    if(this.user.rol == 4){
      this.supervisorIdCurrent = this.user.codigo
      this.supervisorNameCurrent = this.user.nombre
      this.distribuidoraIdCurrent = this.user.idDistribuidora
      this.mesaCurrent = this.user.mesa
    }
  },
  data() {
    return {
      initload: true,
      distribuidoraIdCurrent: null,
      distribuidoraNameCurrent: null,
      supervisorIdCurrent: null,
      supervisorNameCurrent: null,
      mesaCurrent : null,
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      distribuidoras:[],
      supervisores:[],
      vendedores:[],
      mesas:[],
      fechas:[],
      fechaSelected: "1",
      estados:[],
      tableColumns: [
        {
          prop: "nombreVendedor",
          label: "Vendedor",
          minWidth: 300,
        },
        {
          prop: "montoCobrado",
          label: "Monto Cobrado",
          minWidth: 200,
        },
      ],
      tableReporte: [],
      propsToSearch: [],
      fuseSearch: null,
      searchedData: [],
      expands: [],
    };
  },
};
</script>
