<template>
  <table class="table">
            <thead>
              <tr>
                <th scope="col" class="text-center align-middle add-py0-export">Productos</th>
                <th scope="col" class="text-center align-middle add-py0-export">Bonificacion</th>
                <th scope="col" class="text-center align-middle add-py0-export" v-for="escala in [1,2,3,4]" :key="`header-${escala}`">
                  <span v-if="can_edit">
                    Escala {{ escala }}
                  </span >

                  <div v-else class="row mx-0">
                    <div class="col-12 p-0">
                      Escala {{ escala }}
                    </div>
                    <div class="col-6 p-0 font-10">
                      Bultos
                    </div>
                    <div class="col-6 p-0 font-10">
                      Dcto./Reg.
                    </div>
                  </div>
                </th>
                <th scope="col" v-if="can_edit"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(escala, index) in bonificacion.prods_promo"
                :key="escala"
              >
                <EscalaRow
                  :escala="escala"
                  @eliminar-fila="eliminarFila(index)"
                  :can_edit="can_edit"
                ></EscalaRow>
              </tr>
              <tr v-if="bonificacion.prods_promo.length == 0">
                <td colspan="7" class="text-center">
                  Seleccione un tipo de producto (CombinacIón o Individual) y
                  agrégalos para definir la escala.
                </td>
              </tr>
            </tbody>
          </table>
</template>
<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import GeneralInput from "./GeneralInput.vue";
import DateInput from "./DateInput.vue";
import GeneralDropdown from "./GeneralDropdown.vue";
import EscalaRow from "./EscalaRow.vue";
import constantes from '@/model/constantes.js';

const store = useStore();

const { bonificacion } = defineProps({
  bonificacion: {
    type: Object,
  },
  can_edit: {
    type: Boolean,
    default: false
  },
});


const eliminarFila = (index) => {
  bonificacion.prods_promo.splice(index, 1);
};

onMounted(() => {

});


</script>
<style lang="scss" scoped>
.bonificacion-producto-btn {
  font-size: 10px;
  font-weight: lighter;
  background: transparent;
  border: none;
  span {
    text-decoration: underline;
  }
}
.circle-btn:hover {
  box-shadow: none !important;
  transform: none !important;
  color: white !important;
}

thead {
  background: #f7f9fc;
}
.table thead th {
  text-transform: none;
  font-size: 11px;
  border: none;
}
.table {
  border-radius: 10px;
  overflow: hidden;
}
th {

    padding: 10px 12px!important;
  }
</style>
