<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block only_hidden_after">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive validateClient">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template v-slot:header>
            <div class="row">
              <div class="col-12 col-md-6">
                <h3 class="mb-0">Logs del APP</h3>
              </div>
              <div class="col-12 col-md-6 text-right">
                <button type="button" class="btn base-button btn-primary btn-sm" role="button" @click="exportLogs"><i class="fas fa-file-export"></i>Exportar</button>
              </div>
            </div>
          </template>
          <div>
            <div class="row px-3">
              <div class="col-12 mb-3">
                <div class="row">
                  <div class="col">
                    <span class="title_filter mr-2">Módulos:  </span>
                    <select v-model="filter.modulo" class="form-control" style="display: inline-block;height: 43px;" name="typeapp_select" @change="changeSection">
                      <option value="" selected>Todos</option>
                      <option
                        v-for="item in typesApp"
                        :value="item.codigoDato"
                        :key="item.idRegistro"
                        >
                        {{ item.nombre }}
                      </option>
                    </select>
                  </div>
                  <div class="col">
                    <span class="title_filter mr-2">Distribuidoras:</span>
                    <select v-model.number="filter.iddistribuidora" class="form-control" name="deparment_select" @change="changeDistribuidora">
                      <option selected disabled value="0">Todos</option>
                      <option
                        v-for="item in distribuidoras"
                        :value="item.idDistribuidora"
                        :key="item.idDistribuidora"
                      >
                        {{ item.razonSocial }}
                      </option>
                    </select>
                  </div>
                  <div class="col">
                    <span class="title_filter mr-2">Supervisor: </span>
                    <select v-model='filter.codigosupervisor' class="form-control" id="supervisor_select" style="display: inline-block;height: 43px;" name="supervisor_select" @change="changeSupervisor">
                        <option value="">Todos</option>
                        <option v-for="item in supervisores" :value="item.codigoSupervisor" :key="item.codigoSupervisor">
                            {{ item.nombre }}
                        </option>
                    </select>
                  </div>
                  <div class="col">
                    <span class="title_filter mr-2">Vendedor: </span>
                    <select v-model='filter.codigovendedor' class="form-control" id="vendedor_select" style="display: inline-block;height: 43px;" name="vendedor_select" @change="changeVendedor">
                      <option value="">Todos</option>
                      <option v-for="item in vendedores" :value="item.codigoVendedor" :key="item.codigoVendedor">
                        {{ item.nombre }} {{ item.apellidos }}
                      </option>
                    </select>
                  </div>
                  <div class="col">
                    <span class="title_filter mr-2">Tipo de Log:</span>
                    <select v-model='filter.tipo' class="form-control" id="fecha_select" style="display: inline-block;height: 43px;" name="fecha_select" @change="changeListbyType">
                      <option value="">Todas</option>
                      <option value="Exito">Éxito</option>
                      <option value="Error">Error</option>
                    </select>
                  </div>
                  <div class="col">
                    <span class="title_filter mr-2">Fechas:</span>
                    <select v-model='filter.periodo' class="form-control" id="fecha_select" style="display: inline-block;height: 43px;" name="fecha_select" @change="changeListbyFechas">
                      <option value="">Todas</option>
                      <option
                        v-for="item in fechas"
                        :value="item.codigoDato"
                        :key="item.codigoDato"
                        :selected="item.codigoDato == fechaSelected"
                        >
                        {{ item.nombre }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <!-- Real Table -->
            <el-table
              :data="queriedData"
              row-key="versionapp"
              :empty-text="emptyText"
              class=""
              header-row-class-name="thead-light"
            >
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                v-bind="column"
              >
              </el-table-column>
              <el-table-column prop="date" label="Cliente" width="140" align="left">
                <template v-slot:default="props">
                  <template v-if="props.row.codigoCliente.length">
                    {{props.row.codigoCliente}}
                  </template>
                  <template v-else>
                    --
                  </template>
                </template>
              </el-table-column>
              <el-table-column width="140" align="left">
                <template v-slot:default="props">
                  <base-button type="info" tag="a" class="detail" size="sm" role="button" @click="openDetail(props.row)" >Detalle</base-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <template v-slot:footer>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category" style="display:none">
                  Mostrando {{ from + 1 }} a {{ to }} de {{ total }} Supervisores
                </p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
                @click="changePaginate"
              >
              </base-pagination>
            </div>
          </template>
        </card>
      </div>
    </div>
  </div>
  <CustomModal
    :show="dataModal.showModal"
    :isHeader="true"
    :isFooter="false"
    :width="800"
    :title="'Detalle de: '+ dataModal.title"
    v-on:modal:close="dataModal.showModal = $event"
  >
    <template v-slot:body>
      <div class="form_group">
        <h4>Request:</h4>
        <pre style="padding:0 0 0.5rem;">{{dataModal.request}}</pre>
        <template v-if="dataModal.response">
          <h4>Response:</h4>
          <pre style="padding:0 0 0.5rem;">{{dataModal.response}}</pre>
        </template>
        <template v-if="dataModal.uniflexResponse">
          <h4>Uniflex response:</h4>
          <pre style="padding:0 0 0.5rem;">{{dataModal.uniflexResponse}}</pre>
        </template>
      </div>
    </template>
  </CustomModal>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import axios from "axios";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BasePagination from "@/components/BasePagination";
import CustomModal from "../Components/CustomModal.vue";
import {mapGetters, mapActions} from 'vuex';
import DashboardNavbar from "../Layout/DashboardNavbar";
export default {
  name: "ListLogs",
  components: {
    DashboardNavbar,
    BasePagination,
    RouteBreadCrumb,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    CustomModal
  },
  computed: {
    pagedData() {
      this.getDistribuidoras
      this.getFechas
      this.getTypeApp
      this.getDataLogs
      if(this.tableData.length){
        return this.tableData;
      }
    },
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString().toLowerCase();
          if (rowValue.includes && rowValue.includes(this.searchQuery.toLowerCase())) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      let firstPagination = document.querySelector("ul.pagination .page-item.number")
      if(this.pagination.total == 1 && firstPagination){
        firstPagination.classList.add("active")
      }
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.pagination.total;
    },
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    }),
    getTypeApp(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let urlApiTypesVersion = process.env.VUE_APP_API_URL+"/maestro/lista/logmobile"
      const requestTypesVersion = axios.get(urlApiTypesVersion, config);
      axios.all([requestTypesVersion]).then(axios.spread((...responses) => {
        const data = responses[0].data
        $this.typesApp = data
        console.log({data})
      })).catch(errors => {
        // react on errors.
        // document.querySelector(".form_laive").classList.remove("validateClient")
      })
    },
    async getDistribuidoras() {
        try{
            let result = await this.getDistributorList();
            this.distribuidoras = result.data ? result.data.filter((item)=>{return item.estado == "1"}) : [];
        }
        catch(ex){ex}
    },
    async getSupervisor() {
      try{
        this.supervisores =[];
        if(this.filter.iddistribuidora != 0){
          let result = await this.getSupervisorList(this.filter.iddistribuidora != 0 ? this.filter.iddistribuidora : 0);
          this.supervisores = result.data ? result.data : [];
          return result
        }
      }
      catch(ex){ex}
    },
    async getVendedor() {
      try{
        this.vendedores=[];
        if(this.filter.codigosupervisor  != '' ){
          let result = await this.getVendedorByDistSuperList({
            iddistribuidora: this.filter.iddistribuidora,
            codigosupervisor: this.filter.codigosupervisor
          });
          this.vendedores = result.data ? result.data : [];
        }
      }
      catch(ex){console.log(ex)}
    },
    getFechas(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const urlApiFechas = process.env.VUE_APP_API_URL+"/maestro/lista/listafecha"
      if(this.fechas.length == 0){
        axios.get(urlApiFechas,config)
        .then(function (response){
          return $this.fechas = response.data
        })
      }
    },
    getDataLogs(){
      let textNotify = "";
      let errorFilters = true
      if(this.filter.iddistribuidora == 0){
        textNotify = "Debe selecionar una Distribuidora"
        errorFilters = true
      }else{
        errorFilters = false
      }
      // console.log(this.filter)
      if(errorFilters){
        this.emptyText = textNotify
        // document.querySelector(".form_laive").classList.remove("validateClient")
        // return
      }
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      // document.querySelector(".form_laive").classList.add("validateClient")
      const apiListLogs = process.env.VUE_APP_API_URL+`/logmobile/lista`
      axios.post(apiListLogs,this.filter,config)
      .then(function (response){
        const data = response.data.data
        console.log(response.data.totalPage)
        $this.tableData = data
        $this.pagination.perPage = data.length
        $this.pagination.total = response.data.totalPage
        document.querySelector(".form_laive").classList.remove("validateClient")
      }).catch(errors => {
        // react on errors.
        $this.emptyText = "No se encontraron resultados"
        document.querySelector(".form_laive").classList.remove("validateClient")
      })
    },
  },
  methods:{
    ...mapActions({
        getDistributorList: 'distribuidora/getList',
        getSupervisorList: 'supervisor/getListById',
        getVendedorByDistSuperList: 'vendedor/getListByDistribuidoraSupervisor',
    }),
    changeSection(){
      document.querySelector(".form_laive").classList.add("validateClient")
      this.pagination.currentPage = 1
      this.filter.page = 1
      this.getDataLogs
    },
    changeDistribuidora(){
      if (this.filter.iddistribuidora == 0) {
        this.filter.codigosupervisor = '';
        this.filter.codigovendedor = '';
      }
      document.querySelector(".form_laive").classList.add("validateClient")
      this.pagination.currentPage = 1
      this.filter.page = 1
      this.getSupervisor
      this.getDataLogs
    },
    changeSupervisor() {
      if (this.filter.idSupervisor == '') this.filter.idVendedor = '';
      document.querySelector(".form_laive").classList.add("validateClient")
      this.getVendedor.then(function(response) {
        document.querySelector(".form_laive").classList.remove("validateClient")
      });
    },
    changeVendedor() {
      document.querySelector(".form_laive").classList.add("validateClient")
      this.pagination.currentPage = 1
      this.filter.page = 1
      this.getDataLogs;
    },
    changeListbyType(){
      document.querySelector(".form_laive").classList.add("validateClient")
      this.pagination.currentPage = 1
      this.filter.page = 1
      this.getDataLogs;
    },
    changeListbyFechas(){
      document.querySelector(".form_laive").classList.add("validateClient")
      this.pagination.currentPage = 1
      this.filter.page = 1
      this.getDataLogs;
    },
    openDetail(data){
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      this.dataModal.title = data.vendedor
      document.querySelector(".form_laive").classList.add("validateClient")
      const apiDetailLog =`${process.env.VUE_APP_API_URL}/logmobile/detalle/${data.idLogMobile}`
      axios.get(apiDetailLog,config)
      .then(function (response){
        const data = response.data
        document.querySelector(".form_laive").classList.remove("validateClient")
        $this.dataModal.showModal = true
        $this.dataModal.request = data.request
        $this.dataModal.response = data.response
      }).catch(errors => {
        // react on errors.
        document.querySelector(".form_laive").classList.remove("validateClient")
      })
    },
    changePaginate(evn){
      const $this = this
      const listPaginator = document.querySelectorAll("ul.pagination .page-item")
      let elm = evn.target;
      let sendAjax = false;
      let parentEl = elm.parentNode
      if(elm.classList.contains("prev-page")){
        if(!elm.classList.contains("disabled")){
          let linkActivePrev = document.querySelectorAll("ul.pagination .page-item.active")
          linkActivePrev[0].classList.remove("active")
          linkActivePrev[0].previousSibling.classList.add("active")
          sendAjax = true
        }
      }else if(elm.classList.contains("next-page")){
      }else{
        if(elm.getAttribute("aria-label") == "Previous"){
          let linkActivePrev = document.querySelectorAll("ul.pagination .page-item.active")
          linkActivePrev[0].classList.remove("active")
          linkActivePrev[0].previousSibling.classList.add("active")
          sendAjax = true
        }else if(elm.getAttribute("aria-label") == "Next"){
          let linkActiveNext = document.querySelectorAll("ul.pagination .page-item.active")
            linkActiveNext[0].classList.remove("active")
            linkActiveNext[0].nextSibling.classList.add("active")
            sendAjax = true
        }else{
          if(!parentEl.classList.contains("active")){
            listPaginator.forEach(item => {
              item.classList.remove("active")
            });
            parentEl.classList.add("active")
            sendAjax = true
          }
        }
      }
      if(sendAjax){
        document.querySelector(".form_laive").classList.add("validateClient")
        $this.filter.page = $this.pagination.currentPage
        $this.getDataLogs;
      }
    },
    exportLogs(){
      const token = localStorage.getItem("token")
      const urlDomain = process.env.VUE_APP_API_URL.replace("api", "")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
        }
      };
      const urlApiExport = process.env.VUE_APP_API_URL+"/logmobile/exportar"
      document.querySelector(".form_laive").classList.add("validateClient")
      axios.post(urlApiExport,this.filter,config)
      .then(function (response){
        document.querySelector(".form_laive").classList.remove("validateClient")
        const urlFile = urlDomain + response.data.fileName
        const link = document.createElement('a');
        link.href = urlFile;
        document.body.appendChild(link);
        link.click();
      })
    }
  },
  mounted() {
    if(this.user.rol == 3){
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
  },
  data() {
    return {
      initload: true,
      distribuidoraIdCurrent: null,
      distribuidoraNameCurrent: null,
      distribuidoras: [],
      supervisores: [],
      vendedores: [],
      fechaSelected: "1",
      fechas:[],
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      filter: {
          iddistribuidora: 0,
          modulo: '',
          codigosupervisor: '',
          codigovendedor: '',
          tipo: '',
          periodo: "1",
          page: 1
      },
      dataModal:{
        showModal: false,
        title: "",
        active: "",
        request: "",
        response: "",
      },
      searchQuery: "",
      propsToSearch: [],
      tableColumns: [
        {
          prop: "codigoVendedor",
          label: "Vendedor",
          width: 140,
        },
        {
          prop: "modulo",
          label: "Módulo",
          width: 170,
        },
        {
          prop: "resultado",
          label: "Resultado",
          width: 140,
        },
        {
          prop: "fechaRegistro",
          label: "Registro",
          width: 150,
        },
        {
          prop: "horaRegistro",
          label: "Hora de Registro",
          width: 185,
        },
        {
          prop: "codigoProducto",
          label: "Producto",
          width: 160,
        },
        {
          prop: "numeroDocumento",
          label: "Documento",
          width: 150,
        },
      ],
      tableData: [],
      emptyText: "Debe selecionar una Distribuidora",
      fuseSearch: null,
      searchedData: [],
      expands: [],
      typesApp:[]
    };
  },
};
</script>
