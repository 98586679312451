<template>
  <div v-if="totalPages > 0" class="pagination">
    <div class="wrapper">
      <button v-if="currentPage > 1" @click="changePage(currentPage - 1)">
        &lt;
      </button>
      <button
        v-for="page in pages"
        :key="page"
        :class="{ active: page === currentPage, dots: page === '...' }"
        @click="changePage(page)"
      >
        {{ page }}
      </button>
      <button
        v-if="currentPage < totalPages"
        @click="changePage(currentPage + 1)"
      >
        &gt;
      </button>
    </div>
  </div>
</template>

<script>
import "../assets/sass/laive-components/pagination.scss";

export default {
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
  },
  computed: {
    pages() {
      const pages = [];
      const total = this.totalPages;
      const current = this.currentPage;
      const delta = 2; // Number of pages to display on each side of the current page

      if (total <= 1) {
        return [1];
      }

      // Pages before the current page
      for (let i = Math.max(2, current - delta); i < current; i++) {
        pages.push(i);
      }

      // The current page
      pages.push(current);

      // Pages after the current page
      for (
        let i = current + 1;
        i <= Math.min(total - 1, current + delta);
        i++
      ) {
        pages.push(i);
      }

      if (current < total - delta - 1) {
        pages.push("...");
      }

      // It will always show the last button if you didn't reach the end
      if (total > 1 && current !== total) {
        pages.push(total);
      }

      return pages;
    },
  },
  emits: ["page-changed"],
  methods: {
    changePage(page) {
      if (page === "...") return;
      if (page === this.currentPage) return;

      this.$emit("page-changed", page);
    },
  },
};
</script>
