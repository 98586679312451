<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <!-- HTML5 inputs -->
            <div class="card">
              <!-- Card header -->
              <div class="card-header">
                <h3 class="mb-0">Notificaciones de Control</h3>
              </div>
              <!-- Card body -->
              <div class="card-body form_laive validateClient">
                <div class="box_loader">
                  <div class="loader"></div>
                </div>
                <form @submit.prevent="submitUpdatedParameter" id="alertas_horas_envio">
                  <div class="form-group row">
                    <label class="col-md-12 col-form-label form-control-label">Horas de envío de alertas</label>
                    <div class="col-md-4">
                      <base-input name="alertas_horas_envio_01">
                        <flat-picker :config="configDate" placeholder="Horario de Inicio" class="form-control datepicker" v-model="alertas_horas_envio_arr[0]"></flat-picker>
                      </base-input>
                    </div>
                    <div class="col-md-4">
                      <base-input name="alertas_horas_envio_02">
                        <flat-picker :config="configDate" placeholder="Horario de Inicio" class="form-control datepicker" v-model="alertas_horas_envio_arr[1]"></flat-picker>
                      </base-input>
                    </div>
                    <div class="col-md-4">
                      <base-input name="alertas_horas_envio_03">
                        <flat-picker :config="configDate" placeholder="Horario de Inicio" class="form-control datepicker" v-model="alertas_horas_envio_arr[2]"></flat-picker>
                      </base-input>
                    </div>
                    <div class="col-md-12">
                      <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar</base-button>
                    </div>
                  </div>
                </form>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4">
                    <form @submit.prevent="submitUpdatedParameter" id="alertas_porc_venta" class="form_laive validateClient">
                      <div class="box_loader">
                        <div class="loader"></div>
                      </div>
                      <div class="form-group row">
                        <label class="col-md-12 col-form-label form-control-label">% de diferencia mínimo de ventas</label>
                        <div class="col-md-12">
                          <base-input
                            required
                            name="alertas_porc_venta"
                            placeholder="Indicar Porcentaje mínimo de ventas"
                            v-model="alertas_porc_venta.valor"
                            @keypress="isNumber($event)"
                          >
                          <template v-slot:addonLeft>%</template>
                          </base-input>
                          <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar</base-button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="col-md-4">
                    <form @submit.prevent="submitUpdatedParameter" id="alertas_porc_cobranzas" class="form_laive validateClient">
                      <div class="box_loader">
                        <div class="loader"></div>
                      </div>
                      <div class="form-group row">
                        <label class="col-md-12 col-form-label form-control-label">% de diferencia mínimo de cobranzas</label>
                        <div class="col-md-12">
                          <base-input
                            required
                            name="alertas_porc_cobranzas"
                            placeholder="Indicar Porcentaje mínimo de cobranzas"
                            v-model="alertas_porc_cobranzas.valor"
                            @keypress="isNumber($event)"
                          >
                          <template v-slot:addonLeft>%</template>
                          </base-input>
                          <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar</base-button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="col-md-4">
                    <form @submit.prevent="submitUpdatedParameter" id="alertas_porc_efectividad" class="form_laive validateClient">
                      <div class="box_loader">
                        <div class="loader"></div>
                      </div>
                      <div class="form-group row">
                        <label class="col-md-12 col-form-label form-control-label">% de diferencia mínimo de efectividad</label>
                        <div class="col-md-12">
                          <base-input
                            required
                            name="alertas_porc_efectividad"
                            placeholder="Indicar Porcentaje mínimo de efectividad"
                            v-model="alertas_porc_efectividad.valor"
                            @keypress="isNumber($event)"
                          >
                          <template v-slot:addonLeft>%</template>
                          </base-input>
                          <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar</base-button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="col-md-4">
                    <form @submit.prevent="submitUpdatedParameter" id="alertas_porc_devolucion" class="form_laive validateClient">
                      <div class="box_loader">
                        <div class="loader"></div>
                      </div>
                      <div class="form-group row">
                        <label class="col-md-12 col-form-label form-control-label">% de diferencia mínimo de devolución</label>
                        <div class="col-md-12">
                          <base-input
                            required
                            name="alertas_porc_devolucion"
                            placeholder="Indicar Porcentaje mínimo de devolución"
                            v-model="alertas_porc_devolucion.valor"
                            @keypress="isNumber($event)"
                          >
                          <template v-slot:addonLeft>%</template>
                          </base-input>
                          <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar</base-button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="col-md-4">
                    <form @submit.prevent="submitUpdatedParameter" id="alertas_porc_surtido" class="form_laive validateClient">
                      <div class="box_loader">
                        <div class="loader"></div>
                      </div>
                      <div class="form-group row">
                        <label class="col-md-12 col-form-label form-control-label">% de diferencia mínimo de surtido</label>
                        <div class="col-md-12">
                          <base-input
                            required
                            name="alertas_porc_surtido"
                            placeholder="Indicar Porcentaje mínimo de surtido"
                            v-model="alertas_porc_surtido.valor"
                            @keypress="isNumber($event)"
                          >
                          <template v-slot:addonLeft>%</template>
                          </base-input>
                          <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar</base-button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="col-md-4">
                    <form @submit.prevent="submitUpdatedParameter" id="alertas_horas_laborales" class="form_laive validateClient">
                      <div class="box_loader">
                        <div class="loader"></div>
                      </div>
                      <div class="form-group row">
                        <label class="col-md-12 col-form-label form-control-label">Horas Laborales</label>
                        <div class="col-md-12">
                          <base-input
                            required
                            name="alertas_horas_laborales"
                            placeholder="Indicar Horas laborales"
                            v-model="alertas_horas_laborales.valor"
                            @keypress="isNumber($event)"
                          >
                          <template v-slot:addonLeft>Horas</template>
                          </base-input>
                          <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar</base-button>
                        </div>
                      </div>
                    </form>
                  </div>

                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from "axios";
  import { useToast } from "vue-toastification";
  import Notification from "@/components/Notification";
  import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
  import DashboardNavbar from "../Layout/DashboardNavbar";
  import flatPicker from "vue-flatpickr-component";
  import {Spanish} from 'flatpickr/dist/l10n/es.js';
  import "flatpickr/dist/flatpickr.css";
  export default {
    components: {
      DashboardNavbar,
      RouteBreadCrumb,
      flatPicker,
    },
    created(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const urlApiParameters = process.env.VUE_APP_API_URL+"/parametro/lista"
      axios.post(urlApiParameters,"",config)
      .then(function (response){
        const data = response.data;
        // Horarios de Envío
        let alertas_horas_envio = data.filter((item)=>{return item.codigoParametro == "alertas_horas_envio"})
        $this.alertas_horas_envio = alertas_horas_envio[0]
        alertas_horas_envio[0].valor.split(',').forEach(item => {
          $this.alertas_horas_envio_arr.push(item)
        });
        // Porcentaje mínimo de ventas
        let alertas_porc_venta = data.filter((item)=>{return item.codigoParametro == "alertas_porc_venta"})
        $this.alertas_porc_venta = alertas_porc_venta[0]
        // Porcentaje mínimo de cobranzas
        let alertas_porc_cobranzas = data.filter((item)=>{return item.codigoParametro == "alertas_porc_cobranzas"})
        $this.alertas_porc_cobranzas = alertas_porc_cobranzas[0]
        // Porcentaje mínimo de efectividad
        let alertas_porc_efectividad = data.filter((item)=>{return item.codigoParametro == "alertas_porc_efectividad"})
        $this.alertas_porc_efectividad = alertas_porc_efectividad[0]
        // Porcentaje mínimo de devolución
        let alertas_porc_devolucion = data.filter((item)=>{return item.codigoParametro == "alertas_porc_devolucion"})
        $this.alertas_porc_devolucion = alertas_porc_devolucion[0]
        // Porcentaje mínimo de surtido
        let alertas_porc_surtido = data.filter((item)=>{return item.codigoParametro == "alertas_porc_surtido"})
        $this.alertas_porc_surtido = alertas_porc_surtido[0]

        // Porcentaje mínimo de Horas Laborales
        let alertas_horas_laborales = data.filter((item)=>{return item.codigoParametro == "alertas_horas_laborales"})
        $this.alertas_horas_laborales = alertas_horas_laborales[0]

        console.log({alertas_porc_efectividad, alertas_porc_cobranzas, alertas_porc_devolucion})
        const getFormsLaive = document.querySelectorAll(".form_laive")
        getFormsLaive.forEach(element => {
          element.classList.remove("validateClient")
        });
      })
    },
    methods: {
      isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault();;
        } else {
          return true;
        }
      },
      submitUpdatedParameter(evn) {
        const $parent = evn.target.parentNode;
        const getID = evn.target.getAttribute("id")
        const token = localStorage.getItem("token")
        let messageSuccess = ""
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        let dataSend = ""
        if(getID == "alertas_horas_envio"){
          this.alertas_horas_envio.valor = this.alertas_horas_envio_arr.join(',')
          dataSend = this.alertas_horas_envio
          messageSuccess = "Se actualizó las Horas de Envío"
        }else if(getID == "alertas_porc_venta"){
          dataSend = this.alertas_porc_venta
          messageSuccess = "Se actualizó el Porcentaje mínimo de ventas"
        }else if(getID == "alertas_porc_cobranzas"){
          dataSend = this.alertas_porc_cobranzas
          messageSuccess = "Se actualizó el Porcentaje mínimo de cobranzas"
        }else if(getID == "alertas_porc_efectividad"){
          dataSend = this.alertas_porc_efectividad
          messageSuccess = "Se actualizó el Porcentaje mínimo de efectividad"
        }else if(getID == "alertas_porc_devolucion"){
          dataSend = this.alertas_porc_devolucion
          messageSuccess = "Se actualizó el Porcentaje mínimo de devolución"
        }else if(getID == "alertas_porc_surtido"){
          dataSend = this.alertas_porc_surtido
          messageSuccess = "Se actualizó el Porcentaje mínimo de surtido"
        }else if(getID == "alertas_horas_laborales"){
          dataSend = this.alertas_porc_surtido
          messageSuccess = "Se actualizó la alerta de Horas Laborales"
        }
        const urlApiUpdated = process.env.VUE_APP_API_URL+"/parametro/actualizar"
        $parent.classList.add("validateClient")
        axios.put(urlApiUpdated,dataSend,config)
        .then(function (response){
          $parent.classList.remove("validateClient")
          const text = (response.status == 200) ? messageSuccess : "Hubo un error";
          const icon = "ni ni-bell-55";
          const content = {
            component: Notification,
            props: {
              icon: icon,
              text: text,
              type: (response.status == 200) ? "success" : "danger",
            },
          };
          const toast = useToast();
          toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
        })
      },
    },
    data() {
      return {
        configDate: {
          altInput: true,
          allowInput: true,
          noCalendar: true,
          enableTime: true,
          // time_24hr: true,
          locale: Spanish, // locale for this instance only
        },
        alertas_horas_envio_arr: [],
        alertas_horas_envio:{
          idParametro: "",
          codigoParametro: "",
          valor: ""
        },
        alertas_porc_venta:{
          idParametro: "",
          codigoParametro: "",
          valor: ""
        },
        alertas_porc_cobranzas:{
          idParametro: "",
          codigoParametro: "",
          valor: ""
        },
        alertas_porc_efectividad:{
          idParametro: "",
          codigoParametro: "",
          valor: ""
        },
        alertas_porc_devolucion:{
          idParametro: "",
          codigoParametro: "",
          valor: ""
        },
        alertas_porc_surtido:{
          idParametro: "",
          codigoParametro: "",
          valor: ""
        },
        alertas_horas_laborales:{
          idParametro: "",
          codigoParametro: "",
          valor: ""
        }
      };
    },
  };
</script>

