<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template v-slot:header>
            <div class="row">
              <div class="col-12 col-md-6">
                <h3 class="mb-0">Carga de objetivos - Máximo de Devoluciones</h3>
              </div>
            </div>
          </template>
          <div>
            <div class="row px-3">
              <div class="col-12 col-md-10 mb-3">
                <form @submit.prevent="submitExcel">
                  <file-input required accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"></file-input>
                  <br>
                  <br>
                  <div class="notes_editor">
                    <p><strong>Consideraciones del Archivo</strong></p>
                    <ul>
                      <li>Los datos de la Columna Fecha debe estar en el formato <strong>dd/mm/yyyy</strong>. El archivo debe ser un Excel simple sin formato</li>
                    </ul>
                  </div>
                  <base-button type="primary" native-type="submit" class="btn-default my-0">Subir objetivos</base-button>
                </form>
              </div>

            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BasePagination from "@/components/BasePagination";
import {mapGetters} from 'vuex';
import FileInput from "@/components/Inputs/FileInput";
import swal from "sweetalert2";
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
import DashboardNavbar from "../../Layout/DashboardNavbar";
export default {
  components: {
    DashboardNavbar,
    BasePagination,
    RouteBreadCrumb,
    FileInput,
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    }),
  },
  methods:{
    submitExcel() {
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        }
      };
      var formData = new FormData();
      var excelfile = document.querySelector('#customFileLang');
      formData.append("file", excelfile.files[0]);
      const urlUploadExcel = process.env.VUE_APP_API_URL+`/import/maximo/devolucion`
      document.querySelector(".form_laive").classList.add("validateClient")
      axios.post(urlUploadExcel, formData, config)
      .then(function (response){
        console.log(response.data)
        const text = (response.data.status == 200) ? "Se Cargo el archivo" : response.data.message;
        const icon = "ni ni-bell-55";
        const content = {
          component: Notification,
          props: {
            icon: icon,
            text: text,
            type: (response.data.status == 200) ? "success" : "danger",
          },
        };
        const toast = useToast();
        toast(content, {
          hideProgressBar: true,
          icon: false,
          closeButton: false,
          position: "top-right",
        });
        excelfile.value = ""
        document.querySelector(".custom-file-label").innerText = "Seleccionar Archivo"
        document.querySelector(".form_laive").classList.remove("validateClient")
      }).catch(errors => {
        const content = {
          component: Notification,
          props: {
            icon: "ni ni-bell-55",
            text: "Error en la carga",
            type: "danger",
          },
        };
        const toast = useToast();
        toast(content, {
          hideProgressBar: true,
          icon: false,
          closeButton: false,
          position: "top-right",
        });
      })
    }
  },
  mounted() {
    if(this.user.rol == 3){
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
    if(this.user.rol == 4){
      this.supervisorIdCurrent = this.user.codigo
      this.supervisorNameCurrent = this.user.nombre
      this.distribuidoraIdCurrent = this.user.idDistribuidora
    }
  },
  data() {
    return {
      files: []
    };
  },
};
</script>
<style lang="scss">
  .swal-wide{
    .swal2-popup{
      width: 52.5em;
    }
  }
  .page-item .page-link .fa{
    z-index: 0;
  }
</style>
