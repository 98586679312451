
import setting from './setting';
export default {
    getList: ($axios,data)=>{
        return $axios.post(`${setting.http.base_url}/notificacion`,data, {headers:setting.http.headers()});
    },

    getDetalle: ($axios,data)=>{
        return $axios.post(`${setting.http.base_url}/notificacion/detalle`,data, {headers:setting.http.headers()});
    },

    getDetalleCliente: ($axios,data)=>{
        return $axios.post(`${setting.http.base_url}/notificacion/detalle/clientes`,data, {headers:setting.http.headers()});
    },
    
}