<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <div class="card form_laive" :class="{ 'validateClient' : loading}">
            <div class="box_loader">
              <div class="loader"></div>
            </div>
            <div class="card-header">
              <div class="row">
                <div class="col-12 col-md-6">
                  <h3 class="mb-0">Listado de Pedído mínimo</h3>
                </div>
                <div class="col-12 col-md-6 text-right">
                  <router-link :to="{name: 'Crear Pedido minimo'}" ><base-button size="sm" type="info">Crear pedido mínimo</base-button></router-link>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row pb-4">
                <div class="col-md-3">
                  <template v-if="distribuidoraIdCurrent">
                    <span class="title_filter mr-2">Distribuidora:  <strong>{{distribuidoraNameCurrent}}</strong></span>
                  </template>
                  <template v-else>
                    <span class="title_filter mr-2">Distribuidoras:  </span>
                    <select v-model.number="filter.iddistribuidora" class="form-control" style="display: inline-block;height: 43px;" name="distributor_select" @change="changeData">
                      <option value="0">Todos</option>
                      <option
                        v-for="item in distribuidoras"
                        :value="item.idDistribuidora"
                        :key="item.idDistribuidora"
                        >
                        {{ item.razonSocial }}
                      </option>
                    </select>
                  </template>
                </div>
                <div class="col-md-3">
                  <span class="title_filter mr-2">Tipo:</span>
                  <select v-model.number="filter.tipo" class="form-control" style="display: inline-block;height: 43px;" name="distributor_select" @change="changeData">
                    <option value="0">Todos</option>
                    <option value="1">Opcional</option>
                    <option value="2">Obligatorio</option>
                  </select>
                </div>
              </div>
              <!-- Real Table -->
              <el-table
                :data="queriedData"
                row-key="idPedidoMinimo"
                empty-text="No se encontraron resultados"
                header-row-class-name="thead-light"
              >
                <el-table-column
                  v-for="column in tableColumns"
                  :key="column.label"
                  v-bind="column"
                >
                </el-table-column>
                <el-table-column label="Monto Minimo" prop="montoMinimo" width="170">
                  <template v-slot="{ row }">
                    S/{{ row.montoMinimo }}
                  </template>
                </el-table-column>
                <el-table-column label="Tipo" prop="nombreTipo" width="160"/>
                <el-table-column label="Creacion" prop="fechaCrea" width="150"/>
                <el-table-column width="204" align="left">
                  <template v-slot:default="props">
                    <router-link :to="{name: 'Editar Pedido minimo', params: { id: props.row.idPedidoMinimo }}" class="btn base-button btn-button btn-sm mr-3 edit">Editar</router-link>
                    <base-button class="delete" type="button" size="sm" role="button" @click="deletePedido(props.row.idPedidoMinimo)">Eliminar</base-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div class="">
                <p class="card-category d-none">
                  Mostrando {{ from + 1 }} a {{ to }} de {{ total }} productos
                </p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="filter.page"
                :per-page="pagination.perPage"
                :total="total"
                @click="changePaginate"
              >
              </base-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CustomModal
    :show="detail.showModal"
    :isHeader="true"
    :isFooter="false"
    :width="480"
    :title="`Detalle de ${detail.title}`"
    v-on:modal:close="detail.showModal = $event">
    <template v-slot:body>
      <form @submit.prevent="submitUpdate" class="form_laive" :class="{ 'validateClient' : detail.loading}">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <label class="form-control-label">Monto mínimo</label>
        <base-input
          required
          name="name_montominimo"
          placeholder="Ingrese el monto"
          v-model.number="detail.montoMinimo"
        >
          <template v-slot:addonLeft>S/</template>
        </base-input>
        <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar monto mínimo</base-button>
      </form>
    </template>
  </CustomModal>
</template>
<script>
  import axios from "axios";
  import {
    ElTable,
    ElTableColumn,
    ElSelect,
    ElOption,
    ElInput,
  } from "element-plus";
  import {mapGetters, mapActions} from 'vuex';
  import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
  import DashboardNavbar from "../Layout/DashboardNavbar";
  import { useToast } from "vue-toastification";
  import Notification from "@/components/Notification";
  import CustomModal from "../Components/CustomModal.vue";
  export default {
    components: {
      DashboardNavbar,
      RouteBreadCrumb,
      [ElSelect.name]: ElSelect,
      [ElOption.name]: ElOption,
      [ElTable.name]: ElTable,
      [ElInput.name]: ElInput,
      [ElTableColumn.name]: ElTableColumn,
      CustomModal
    },
    computed:{
      ...mapGetters({
        authenticated: 'auth/authenticated',
        user: 'auth/user',
      }),
      pagedData() {
        this.getDistributor
        this.getDataPedidoMinimo
        return this.tableData
      },
      queriedData() {
        if (!this.searchQuery) {
          return this.pagedData;
        }
        let result = this.tableData.filter((row) => {
          let isIncluded = false;
          for (let key of this.propsToSearch) {
            let rowValue = row[key].toString().toLowerCase();
            if (rowValue.includes && rowValue.includes(this.searchQuery.toLowerCase())) {
              isIncluded = true;
            }
          }
          return isIncluded;
        });
        this.searchedData = result
        return result.slice(this.from, this.to);
      },
      to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      },
      total() {
        return this.pagination.total;
      },
      async getDistributor(){
        try{
            let result = await this.getDistributorList();
            this.distribuidoras = result.data ? result.data.filter((item)=>{return item.estado == "1"}) : [];
        }
        catch(ex){ex}
      },
      getDataPedidoMinimo(){
        const $this = this
        if(this.tableData.length == 0 && this.initLoad){
          this.loading = true
          const token = localStorage.getItem("token")
          const config = {
            headers: {
              "Authorization": `Bearer ${token}`,
              "Content-Type": "application/json"
            }
          };
          axios.post(process.env.VUE_APP_API_URL+"/pedidominimo/listar", this.filter,config)
          .then(function (response){
            $this.loading = false
            $this.initLoad = false
            $this.pagination.perPage = response.data.data.length
            $this.pagination.total = response.data.totalPage
            return $this.tableData = response.data.data
          })
        }else{
          this.loading = false
        }
      },
    },
    mounted() {
      if(this.user.rol == 3){
        this.distribuidoraIdCurrent = this.user.idDistribuidora
        this.filter.iddistribuidora = this.user.idDistribuidora
      }
    },
    methods: {
      ...mapActions({
        getDistributorList: 'distribuidora/getList',
      }),
      isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault();;
        } else {
          return true;
        }
      },
      changeData(){
        const $this = this;
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        this.loading = true
        this.filter.page = 1
        this.resetPagination(this.filter.page);
        axios.post(process.env.VUE_APP_API_URL+"/pedidominimo/listar",this.filter,config)
        .then(function (response){
          $this.loading = false
          $this.pagination.perPage = response.data.data.length
          $this.pagination.total = response.data.totalPage
          return $this.tableData = response.data.data
        })
      },
      openDetail(idPëdido, name){
        const $this = this;
        const id = idPëdido
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        this.loading = true
        this.detail.title = name
        this.detail.idPedidoMinimo = Number(id)
        const urlDetail = `${process.env.VUE_APP_API_URL}/pedidominimo/detalle/${id}`;
        axios.get(urlDetail,config)
        .then(function (response){
          $this.loading = false
          $this.detail.showModal = true
          const data = response.data
          $this.detail.montoMinimo = data.montoMinimo
          console.log({data})
        })
      },
      submitUpdate(evn){
        const $this = this;
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        const newDataTable = this.tableData.map(item => {
          if(item.idPedidoMinimo == this.detail.idPedidoMinimo) item.montoMinimo = this.detail.montoMinimo
          return item
        });
        this.detail.loading = true
        const dataSend = this.detail
        const urlUpdate = `${process.env.VUE_APP_API_URL}/pedidominimo/actualizar`;
        axios.post(urlUpdate,dataSend,config)
        .then(function (response){
          const text =(response.status == 200) ? "Se actualizó el pedido mínimo" : "Hubo un error";
          const icon ="ni ni-bell-55";
          const content={
            component: Notification,
            props: {
              icon: icon,
              text: text,
              type: (response.status == 200) ? "success" : "danger",
            },
          };
          const toast = useToast();
          toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
          $this.detail.loading = false
          $this.tableData = newDataTable
          $this.detail.showModal = false
        }).catch(function(error){
          console.log({error});
          $this.detail.loading = false
          $this.detail.showModal = false
        })
      },
      deletePedido(idPëdido){
        const $this = this;
        const id = idPëdido
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        this.loading = true
        const urlDetail = `${process.env.VUE_APP_API_URL}/pedidominimo/eliminar/${id}`;
        axios.get(urlDetail,config)
        .then(function (response){
          const data = response.data
          console.log({data})
          setTimeout(() => {
            $this.loading = false
            location.reload();
          }, 350);
        })
      },
      changePaginate(evn){
        const $this = this
        const listPaginator = document.querySelectorAll("ul.pagination .page-item")
        let elm = evn.target;
        let sendAjax = false;
        let parentEl = elm.parentNode
        if(elm.classList.contains("prev-page")){
          if(!elm.classList.contains("disabled")){
            let linkActivePrev = document.querySelectorAll("ul.pagination .page-item.active")
            linkActivePrev[0].classList.remove("active")
            linkActivePrev[0].previousSibling.classList.add("active")
            sendAjax = true
          }
        }else if(elm.classList.contains("next-page")){
        }else{
          if(elm.getAttribute("aria-label") == "Previous"){
            let linkActivePrev = document.querySelectorAll("ul.pagination .page-item.active")
            linkActivePrev[0].classList.remove("active")
            linkActivePrev[0].previousSibling.classList.add("active")
            sendAjax = true
          }else if(elm.getAttribute("aria-label") == "Next"){
            let linkActiveNext = document.querySelectorAll("ul.pagination .page-item.active")
              linkActiveNext[0].classList.remove("active")
              linkActiveNext[0].nextSibling.classList.add("active")
              sendAjax = true
          }else{
            if(!parentEl.classList.contains("active")){
              listPaginator.forEach(item => {
                item.classList.remove("active")
              });
              parentEl.classList.add("active")
              sendAjax = true
            }
          }
        }
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        if(sendAjax){
          document.querySelector(".form_laive").classList.add("validateClient")
          axios.post(process.env.VUE_APP_API_URL+"/producto/refrigerado/lista",this.filter,config)
          .then(function (response){
            document.querySelector(".form_laive").classList.remove("validateClient")
            console.log(response.data.data)
            sendAjax = false
            $this.filter.page = response.data.page
            $this.pagination.perPage = response.data.data.length
            $this.pagination.total = response.data.totalPage
            return $this.tableData = response.data.data
          })
        }
      },
      resetPagination(value){
        const paginate = document.querySelectorAll(".page-item.number")
        let index = value - 1
        paginate.forEach(item => {
          if(item.classList.contains("active")){
            item.classList.remove("active")
          }
        });
        paginate[index].classList.add("active");
      }
    },
    data() {
      return {
        initLoad: true,
        loading: true,
        showModal: false,
        filter: {
          iddistribuidora: 0,
          nombreCombinacion: "",
          tipo: 0,
          page: 1
        },
        detail:{
          showModal: false,
          loading: false,
          title: "",
          idPedidoMinimo: "",
          montoMinimo: ""
        },
        distribuidoraIdCurrent: null,
        distribuidoras: [],
        pagination: {
          perPage: 25,
          currentPage: 1,
          newcurrentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0,
        },
        searchQuery: "",
        propsToSearch: ["codigoGrupo"],
        tableColumns: [
          {
            prop: "distribuidora",
            label: "Distribuidora",
            width: 160,
          },
          {
            prop: "nombreCombinacion",
            label: "Combinacion",
            width: 180,
          },
        ],
        tableData: [],
        searchedData: [],
      };
    },
  };
</script>

