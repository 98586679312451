<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 d-none d-md-inline-block">
          <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <div class="card form_laive" :class="{ 'validateClient' : loading}">
            <div class="box_loader">
              <div class="loader"></div>
            </div>
            <div class="card-header">
              <h3 class="mb-0">Parametros Cobranza fuera de Ruta</h3>
            </div>
            <div class="card-body">
              <label class="col-form-label form-control-label pt-0">Si deseas permitir el cobro de facturas fuera de la ruta del día, puedes activarlos desde aquí:</label>
              <div class="row">
                <div class="col-md-4">
                  <template v-for="(item, k) in cobranzaFueraDeRuta" :key="item.idParametro">
                    <div class="row" v-if="item.show">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-6">
                            <p class="title form-control-label">{{item.distribuidora}}</p>
                          </div>
                          <div class="col-md-6">
                            <base-switch
                              class="mr-1"
                              on-text="Activado"
                              off-text="Desactivado"
                              type="laive"
                              v-model="item.active"
                              @returnValue="changeSwitch($event, k)"
                            >
                            </base-switch>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from "axios";
  import {mapGetters, mapActions} from 'vuex';
  import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
  import DashboardNavbar from "../Layout/DashboardNavbar";
  import { useToast } from "vue-toastification";
  import Notification from "@/components/Notification";
  export default {
    components: {
      DashboardNavbar,
      RouteBreadCrumb,
    },
    created(){
      const $this = this;
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      const urlCobranzaFueraRuta = process.env.VUE_APP_API_URL+"/parametro/lista/cobranza_fuera_ruta"
      const getCobranzaFueraRuta = axios.get(urlCobranzaFueraRuta,config)
      let urlDistribuidoras = process.env.VUE_APP_API_URL+"/distribuidoras/lista"
      const getDistribuidoras = axios.get(urlDistribuidoras, config);

      axios.all([getCobranzaFueraRuta, getDistribuidoras]).then(axios.spread((...responses) => {
        const resCobranza = responses[0].data
        const resDistribuidoras = responses[1].data
        $this.distribuidoras = resDistribuidoras ? resDistribuidoras.filter((item)=>{return item.estado == "1"}) : []
        const newDataFormat = resCobranza.map((item, indice, arr) => {
          let distribuidora = resDistribuidoras.filter((dis)=>{return dis.idDistribuidora == item.idDistribuidora})
          let nameDist = distribuidora[0].razonSocial
          arr[indice]["distribuidora"] = nameDist
          arr[indice]["active"] = Boolean(Number(item.valor))
          arr[indice]["show"] = ($this.distribuidoraIdCurrent) ? ($this.distribuidoraIdCurrent == distribuidora[0].idDistribuidora) ? true : false : true
          return item
        });
        $this.cobranzaFueraDeRuta = newDataFormat
        $this.loading = false
        console.log($this.cobranzaFueraDeRuta)
      })).catch(errors => {
      })
    },
    computed:{
      ...mapGetters({
        authenticated: 'auth/authenticated',
        user: 'auth/user',
      }),
    },
    mounted() {
      if(this.user.rol == 3){
        this.distribuidoraIdCurrent = this.user.idDistribuidora
      }
    },
    methods: {
      ...mapActions({
        getDistributorList: 'distribuidora/getList',
      }),
      isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault();;
        } else {
          return true;
        }
      },
      changeSwitch(value, key){
        console.log({value, key})
        const $this = this
        const newValue = (value) ? "1" : "0";
        this.cobranzaFueraDeRuta[key].valor = newValue;
        this.cobranzaFueraDeRuta[key].active = value
        const dataSend = this.cobranzaFueraDeRuta[key]
        this.loading = true
        console.log({dataSend})
        const token = localStorage.getItem("token")
        const config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        };
        const urlApiUpdated = process.env.VUE_APP_API_URL+"/parametro/actualizar"
        axios.put(urlApiUpdated,dataSend,config)
        .then(function (response){
          const text =(response.status == 200) ? "Se actualizó la cobranza fuera de ruta" : "Hubo un error";
          const icon ="ni ni-bell-55";
          const content={
            component: Notification,
            props: {
              icon: icon,
              text: text,
              type: (response.status == 200) ? "success" : "danger",
            },
          };
          const toast = useToast();
          toast(content, {
            hideProgressBar: true,
            icon: false,
            closeButton: false,
            position: "top-right",
          });
          $this.loading = false
        }).catch(function(error){
          console.log({error});
        })
      }
    },
    data() {
      return {
        loading: true,
        distribuidoraIdCurrent: null,
        distribuidoras: [],
        cobranzaFueraDeRuta: [],
      };
    },
  };
</script>

