import { useToast } from "vue-toastification";

const TOAST_CLASS = {
	success: "th-alert-pry",
	error: "th-alert-error",
};

export function useAppToast() {
	const toast = useToast();
	//type
	function execute(type, title) {
		if (!Object.keys(TOAST_CLASS).includes(type)) return;
		toast[type](title, {
			position: "top-right",
			closeOnClick: true,
			hideProgressBar: true,
			timeout: 6000,
			toastClassName: `th-alert ${TOAST_CLASS[type]}`,
			showCloseButtonOnHover: true,
		});
	}
	function success(title) {
		execute("success", title);
	}
	function error(title) {
		execute("error", title);
	}
	return { execute, success, error, toast };
}
