
import setting from './setting';
const API_URL = setting.http.base_url;




export default {
    obtenerLiquidacionesJOP: async ($axios, request) => {
        try {
            const {data} = (await $axios.get(`${API_URL}/Liquidacion/Listar`, {
				params: request,
                headers: setting.http.headers()
			}));            
            return data;
        } catch (err) {
            console.log(err);
        }
    },
    obtenerLiquidacionesGerente: async ($axios, request) => {
        try {
            const {data} = (await $axios.get(`${API_URL}/Memo/Listar`, {
				params: request,
                headers: setting.http.headers()
			}));            
            return data;
        } catch (err) {
            console.log(err);
        }
    },
    actualizarLiquidacion: async($axios, payload) => {
        try {
            const data = (await $axios.post(`${API_URL}/Liquidacion/Actualizar`, payload, { headers: setting.http.headers() }));            
            return data;            
        } catch (err) {
            console.log(err);
        }
    },
    actualizarEstado: async($axios, payload) => {
        try {
            const data = (await $axios.post(`${API_URL}/Liquidacion/ActualizarEstado`, payload, { headers: setting.http.headers() }));
            return data;
        } catch (err) {
            console.log(err);
        }
    },
    crearActividadVenta: async($axios, payload) => {
        try {
            const data = (await $axios.post(`${API_URL}/Liquidacion/CrearActividadVenta`, payload, { headers: setting.http.headers() }));            
            return data;
        } catch (err) {
            console.log(err);
        }
    },
    eliminarActVenta: async($axios, payload) => {
        try {            
            const data = (await $axios.delete(`${API_URL}/Liquidacion/EliminarActividadVenta`, { headers: setting.http.headers(), data: payload }));            
            return data;
        } catch (err) {
            console.log(err);
        }
    },
    CrearMemo: async($axios, payload) => {
        try {
            const data = (await $axios.post(`${API_URL}/Memo/Crear`, payload, { headers: setting.http.headers() }));
            return data;
        } catch (err) {
            console.log(err);
        }
    },
    actualizarEstadoMemo: async($axios, payload) => {
        try {
            const data = (await $axios.post(`${API_URL}/Memo/ActualizarEstado`, payload, { headers: setting.http.headers() }));
            return data;
        } catch (err) {
            console.log(err);
        }
    },
    DescargarLiquidacionesJOP: async($axios, payload) => {
        try {
            const {data} = (await $axios.post(`${API_URL}/Liquidacion/Descargar`, payload, { headers: setting.http.headers() }));
            return `${API_URL.replace("api", "")}${data.message}`;
        } catch (err) {
            console.log(err);
        }
    },
    DescargarMemoExcel: async ($axios, request) => {
        try {
            const {data} = (await $axios.get(`${API_URL}/Memo/Descargar`, {
				params: request,
                headers: setting.http.headers()
			}));
            return `${API_URL.replace("api", "")}${data.message}`;
        } catch (err) {
            console.log(err);
        }
    },
    DescargarMemoWord: async ($axios, request) => {
        try {
            const {data} = (await $axios.get(`${API_URL}/Memo/DescargarWord`, {
				params: request,
                headers: setting.http.headers()
			}));
            return `${API_URL.replace("api", "")}${data.message}`;
        } catch (err) {
            console.log(err);
        }
    },
}
