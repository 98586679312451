<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center pb-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="form_laive validateClient">
        <div class="box_loader">
          <div class="loader"></div>
        </div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1 pt-0 mt-0"
        >
          <template v-slot:header>
            <h3 class="mb-0">Detalle de Promoción</h3>
          </template>
          <div class="card-body">
            <form @submit.prevent="submiCreate">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label form-control-label">Grupo Objetivo:</label>
                    <Select2 v-model.number="dataSend.idGrupo" class="select2_laive" name="producto_objetivo" id="producto_objetivo" required :options="listGroup" placeholder="Seleccione un Grupo"/>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label form-control-label">Producto:</label>
                    <Select2 v-model="dataSend.codigoProducto" class="select2_laive" name="product_principal" id="product_principal" required :options="listProducts" placeholder="Seleccione un Producto" @select="mySelectEvent($event)" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label form-control-label">Producto Asociado:</label>
                    <Select2 v-model="dataSend.codigoProductoAsociado" class="select2_laive" name="product_asociado" id="product_asociado" :required="productoAsociado" :options="listProducts" placeholder="Seleccione un Producto Asociado" @select="mySelectEvent($event)" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <label class="col-form-label form-control-label">Código:</label>
                  <base-input
                    name="code_promo"
                    required="required"
                    placeholder="Código Promocion"
                    :value="dataSend.codigoPromocion"
                    v-model="dataSend.codigoPromocion"
                  ></base-input>
                </div>
                <div class="col-md-4">
                  <label class="col-form-label form-control-label">Nombre:</label>
                  <base-input
                    name="name_promo"
                    required="required"
                    placeholder="Nombre Promocion"
                    :value="dataSend.nombre"
                    v-model="dataSend.nombre"
                  ></base-input>
                </div>
                <div class="col-md-4">
                  <label class="col-form-label form-control-label">Descripción:</label>
                  <base-input
                    name="descripcion"
                    required="required"
                    placeholder="Descripción"
                    :value="dataSend.descripcion"
                    v-model="dataSend.descripcion"
                  ></base-input>
                </div>
              </div>
              <div class="row">

                <div class="col-md-2">
                  <div class="form-group">
                    <label class="col-form-label form-control-label">Cantidad Mínima:</label>
                    <base-input
                    name="cantidad_minima"
                    required="required"
                    placeholder="Cantidad Mínima"
                    :value="dataSend.cantidadMinima"
                    v-model.number="dataSend.cantidadMinima"
                    @keypress="isNumber($event)"
                  ></base-input>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label class="col-form-label form-control-label">Cantidad Máxima:</label>
                    <base-input
                    name="cantidad_máxima"
                    required="required"
                    placeholder="Cantidad Máxima"
                    :value="dataSend.cantidadMaxima"
                    v-model.number="dataSend.cantidadMaxima"
                    @keypress="isNumber($event)"
                  ></base-input>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label form-control-label">Condición</label>
                    <select class="form-control" name="distributor_select" v-model.number="dataSend.obligatorio">
                      <option value="" disabled>Seleccionar</option>
                      <option value="0">Flexible</option>
                      <option value="1">Obligatoria</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label form-control-label">Fecha de Inicio:</label>
                    <base-input addon-left-icon="ni ni-calendar-grid-58" name="fechaInicio">
                      <flat-picker :config="configDate" class="form-control datepicker" v-model="dataSend.fechaInicio"></flat-picker>
                    </base-input>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="col-form-label form-control-label">Fecha Fin:</label>
                    <base-input addon-left-icon="ni ni-calendar-grid-58" name="fechaFin">
                      <flat-picker :config="configDate" class="form-control datepicker" v-model="dataSend.fechaFin"></flat-picker>
                    </base-input>
                  </div>
                </div>
                <div class="col-md-4">
                  <label class="col-form-label form-control-label">Cant. productos por Promoción:</label>
                  <base-input
                    name="products_cant"
                    required="required"
                    placeholder="Productos por promoción"
                    :value="dataSend.cantidadPromocion"
                    v-model.number="dataSend.cantidadPromocion"
                    @keypress="isNumber($event)"
                  ></base-input>
                </div>
                <div class="col-md-2">
                  <label class="col-form-label form-control-label">Productos Disponibles:</label>
                  <base-input
                    name="products_dispo"
                    required="required"
                    placeholder="Cantidad Máxima"
                    :value="dataSend.maximoDisponible"
                    v-model.number="dataSend.maximoDisponible"
                    @keypress="isNumber($event)"
                  ></base-input>
                </div>
              </div>
              <base-button type="primary" native-type="submit" class="btn-default my-0">Actualizar Promoción</base-button>
            </form>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
import Select2 from 'vue3-select2-component';
import flatPicker from "vue-flatpickr-component";
import {Spanish} from 'flatpickr/dist/l10n/es.js';
import "flatpickr/dist/flatpickr.css";
export default {
  components: {
    RouteBreadCrumb,
    Select2,
    flatPicker,
  },
  created(){
    const $this = this
    const token = localStorage.getItem("token")
    const idProduct = this.$route.params.id;
    console.log({idProduct});
    const config = {
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    };
    const parameters = {
      "iddistribuidora": 0,
      "codigocategoria": "",
      "grupoventa": ""
    }
    const urlProductApi = process.env.VUE_APP_API_URL+"/producto/lista"
    const urlPromocionDetail = process.env.VUE_APP_API_URL+"/promocion/detalle/"+idProduct
    const urlListGroup = process.env.VUE_APP_API_URL+"/promocion/listar-grupo"
    const requestProductApi = axios.post(urlProductApi, parameters, config);
    const requestPromocionDetail = axios.get(urlPromocionDetail, config);
    const requestListGroup = axios.get(urlListGroup,config)
    axios.all([requestProductApi, requestPromocionDetail, requestListGroup]).then(axios.spread((...responses) => {
      document.querySelector(".form_laive").classList.remove("validateClient")
      const responseAllProducts = responses[0].data
      const responsePromotionDetail = responses[1].data
      const requestListGroup = responses[2].data
      console.log({responseAllProducts, responsePromotionDetail})
      const dataProducts = responseAllProducts
      dataProducts.forEach(item => {
        $this.listProducts.push({
          id: item.codigoProducto,
          text: item.descripcionComercial,
        })
      });
      requestListGroup.forEach(item => {
        $this.listGroup.push({
          id: item.idGrupo,
          text: item.nombre,
        })
      });
      // Detail Promo
      $this.dataSend = responsePromotionDetail
    })).catch(errors => {
      // react on errors.
    })
  },
  methods: {
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    submiCreate(values) {
      const $this = this
      const token = localStorage.getItem("token")
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      };
      let updatedPromotion  = process.env.VUE_APP_API_URL+"/promocion/actualizar"
      this.dataSend.obligatorio = Number(this.dataSend.obligatorio)
      axios.post(updatedPromotion, this.dataSend,config)
      .then(function (response){
        document.querySelector(".form_laive").classList.remove("validateClient")
        const text = (response.status == 200) ? "Se actualizó la Promoción" : "Hubo un error";
        const icon = "ni ni-bell-55";
        const content = {
          component: Notification,
          props: {
            icon: icon,
            text: text,
            type: (response.status == 200) ? "success" : "danger",
          },
        };
        const toast = useToast();
        toast(content, {
          hideProgressBar: true,
          icon: false,
          closeButton: false,
          position: "top-right",
        });
        console.log(response);
        setTimeout(() => {
          $this.$router.replace({name: 'Promociones'})
        }, 2000);
      })
      // alert(JSON.stringify(values, null, 2));
    },
    mySelectEvent({id, text}){
      console.log({id, text})
    }
  },
  data() {
    return {
      listProducts: [],
      listGroup: [],
      configDate: {
        altInput: true,
        allowInput: true,
        locale: Spanish, // locale for this instance only
      },
      dataSend:{
        idGrupo: "",
        codigoProducto: "",
        codigoPromocion: "",
        nombre: "",
        codigoProductoAsociado: "",
        descripcion: "",
        obligatorio: "",
        cantidadMinima: "",
        cantidadMaxima: "",
        fechaInicio : "",
        fechaFin : "",
        maximoDisponible: "",
        cantidadPromocion: ""
      }
    };
  },
}
</script>
<style lang="scss">
  .select2_laive{
    .select2-selection{
      height:46px;
      border: 1px solid #dee2e6;
      .select2-selection__rendered{
        line-height:46px;
        font-family: "Poppins", sans-serif;
        font-size: 0.875rem;
      }
      .select2-selection__arrow{
        height:46px;
      }
    }
  }
  .select2-results__option{
    font-size: 0.875rem;
    font-family: "Poppins", sans-serif;
  }
</style>
