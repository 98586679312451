<template>
  <div class="card-table-container th-bg-slate py-1 px-3">
    <CardTable headerTitle="Incidencias" :showButton="false" :loading="loading">
      <template v-if="user.rol === 8" #buttons>
        <div
          class="d-flex justify-content-center align-items-center"
          style="gap: 0.5rem"
        >
          <a
            class="th-btn th-btn-border-pry text-sm align-items-center d-flex flex-row"
            href="/plantillas/carga-masiva-incidencias-plantilla.xlsx"
            download
            @click="download"
          >
            Descargar plantilla
            <i class="laive-i icon-download ml-2" />
          </a>
          <label
            for="upload-input"
            class="th-btn-pry th-btn-float d-flex align-items-center mb-0"
            style="gap: 0.5rem; cursor: pointer"
          >
            <input
              id="upload-input"
              hidden
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              @change="handleUpload"
            />
            <span>Carga masiva</span>
            <i class="laive-i icon-excel-download" />
          </label>
        </div>
      </template>
      <template #filters>
        <div class="filter">
          <span class="title_filter"> Fecha de inicio </span>
          <DateSelect
            v-model="filters.fechaInicio"
            showIcon
            calendarClass="th-calendar"
            :config="{ maxDate: 'today' }"
          />
        </div>
        <div class="filter">
          <span class="title_filter"> Fecha de fin </span>
          <DateSelect
            v-model="filters.fechaCierre"
            showIcon
            calendarClass="th-calendar"
            :config="{ minDate: filters.fechaInicio, maxDate: 'today' }"
          />
        </div>
        <SelectButton
          label="Oficina de ventas"
          name="oficinaVentas"
          v-model="filters.oficinaVentas.selected"
          :options="filters.oficinaVentas.options"
          :keyFilter="'nombre'"
          :hasId="true"
        />
        <SelectButton
          label="DEX"
          name="dex"
          v-model="filters.dexs.selected"
          :options="filters.dexs.options"
          :keyFilter="'nombre'"
          :hasId="true"
          @update:modelValue="fetchListByDex"
        />
        <TextInput
          type="number"
          label="Buscar"
          placeholder="Ingresa el código..."
          name="uniflex"
          v-model="filters.codigo"
          :isSearch="true"
        />
      </template>
      <div class="b-table sticky-header scroller">
        <div class="t-header">
          <div class="t-row">
            <div
              class="t-cell justify-content-center"
              v-for="header in tabla.headers"
              :key="header.label"
              :style="{ minWidth: header.minWidth }"
            >
              <span>{{ header.label }}</span>
            </div>
          </div>
        </div>
        <div v-if="tabla.rows.length > 0" class="t-body text-uppercase">
          <div class="t-row" v-for="row in tabla.rows">
            <div
              class="t-cell justify-content-center"
              v-for="{ key } in tabla.headers.slice(0, -1)"
            >
              <ui-badge
                type="chip"
                :bg="getStatusColor(row['estadoIncidencia']).bg"
                :text-color="getStatusColor(row['estadoIncidencia']).text"
                class="text-uppercase"
                fw="500"
                px="-xxs"
                py="0.4em"
                fs="-xxs"
                w="80%"
                v-if="key == 'estadoIncidencia'"
              >
                {{ getStatusText(row["estadoIncidencia"]) }}
              </ui-badge>
              <template v-else>
                {{ row[key] }}
              </template>
            </div>
            <div class="t-cell justify-content-center">
              <a
                class="th-link-pry see-details-btn"
                role="button"
                :href="`/equipos-frio/incidencias?tab=detalle&incidenciaId=${row.idIncidencia}`"
                style="--style: none"
              >
                Ver detalle
              </a>
            </div>
          </div>
        </div>
        <div v-if="tabla.rows.length == 0" class="t-nt-found">
          <img src="/img/shared/search.svg" alt="No encontrado" />
          <p class="text">No hay datos que mostrar</p>
        </div>
      </div>
      <Pagination
        :currentPage="currentPage"
        :totalPages="totalPages"
        @page-changed="changePage"
      />
    </CardTable>
    <div class="mt-3 d-flex justify-content-end">
      <button
        v-if="
          user.rol === 1 ||
          user.rol === 2 ||
          user.rol === 3 ||
          user.rol === 4 ||
          user.rol === 8
        "
        class="app-btn-pry-icon"
        @click="exportList"
        :disabled="loading"
      >
        Descargar lista
        <i class="laive-i icon-download-white" />
      </button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";

import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import SelectButton from "@/components/Shared/SelectButton.vue";
import TextInput from "@/components/Shared/TextInput.vue";
import { DateSelect } from "@/components/selects";
import DashboardNavbar from "../../Layout/DashboardNavbar";
import { CardTable } from "../../../components/Cards";
import Pagination from "../../../components/Pagination.vue";

import { Distribuidoras, Incidencias } from "../../../services";

import { debounce, parseNormalDate } from "../../../util";

const STATUS_COLORS = {
  pendiente: { bg: "#FFEDDC", text: "#FE7B03" },
  inoperativo: { bg: "#EDEDED", text: "#B1B0B0" },
  operativo: { bg: "#CAFDE0", text: "#02A54F" },
  "visita programada": { bg: "#F7E6FD", text: "#A061DF" },
  "equipo en taller": { bg: "#F7E6FD", text: "#A061DF" },
  "derivado a st": { bg: "#A8E8FF", text: "#2882A2" },
};
const OFICINAS = {
  ALL: -1,
  LIMA: 1,
  PROV: 2,
};

const OFICINAS_CODES = {
  LIMA: 1001,
  PROV: 1002,
};

export default {
  components: {
    CardTable,
    DashboardNavbar,
    RouteBreadCrumb,
    SelectButton,
    TextInput,
    DateSelect,
    Pagination,
  },
  data: () => ({
    loading: false,
    currentPage: 1,
    totalPages: 0,
    file: null,
    filters: {
      fechaInicio: "",
      fechaCierre: "",
      totalDexs: [],
      codigo: "",
      oficinaVentas: {
        selected: OFICINAS.ALL,
        options: [
          { texto: "Todas (Lima y Provincias) ", valor: OFICINAS.ALL },
          { texto: "Lima", valor: OFICINAS.LIMA },
          { texto: "Provincias", valor: OFICINAS.PROV },
        ],
      },
      dexs: {
        selected: -1,
        options: [{ texto: "Todas", valor: -1 }],
      },
    },
    tabla: {
      headers: [
        { key: "codigo", label: "Código", minWidth: "150" },
        { key: "dex", label: "DEX", minWidth: "120" },
        { key: "placa", label: "Placa", minWidth: "120" },
        { key: "modelo", label: "Modelo", minWidth: "180" },
        { key: "lote", label: "Lote", minWidth: "120" },
        { key: "fecha", label: "Fecha Reporte", minWidth: "120" },
        { key: "estadoIncidencia", label: "Estado", minWidth: "130" },
        { key: "tipoIncidencia", label: "Tipo de Incidencia", minWidth: "130" },
        { key: "dias", label: "Días Transcurridos", minWidth: "130" },
        { key: "detalle", label: "", minWidth: "130" },
      ],
      rows: [],
    },
  }),
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true;
        if (!this.$store.state.auth.token) return;
        const { token } = this.$store.state.auth;

        const dataDexs = await Distribuidoras.getAll(token);
        const dataIncidencias = await Incidencias.listByFilters(token, {
          page: this.currentPage,
        });

        // Distribuidoras
        const dexs = dataDexs
          .filter(({ estado }) => estado == "1")
          .map((dx) => ({
            texto:
              dx.razonSocial.charAt(0).toUpperCase() +
              dx.razonSocial.slice(1).toLowerCase(),
            id: dx.idDistribuidora,
            valor: dx.idDistribuidora,
          }));
        this.filters.totalDexs = dexs;

        this.filters.dexs = {
          selected: -1,
          options: [{ texto: "Todas", valor: -1 }, ...dexs],
        };
        const { data: incidencias, page, totalPage } = dataIncidencias;
        this.parseIncidencias(incidencias);
        this.currentPage = page;
        this.totalPages = totalPage;
      } catch (error) {
        this.toast("No fue posible obtener la informacion", "error");
      } finally {
        this.loading = false;
      }
    },
    getStatusText(st) {
      const lower = st.toLowerCase().trim();
      if (lower == "visita programada") return "visita prog.";
      return lower;
    },
    getStatusColor(st) {
      return (
        STATUS_COLORS[st.toLowerCase().trim()] ?? {
          bg: "#F6F7F2",
          text: "#D0D5AB",
        }
      );
    },
    performSearch: debounce(function (
      fechaInicio,
      fechaCierre,
      oficina,
      dex,
      codigo
    ) {
      this.resetPagination();
      this.fetchList(fechaInicio, fechaCierre, oficina, dex, codigo);
    },
    450),
    fetchListByDex(selected) {
      this.resetPagination();

      this.fetchList(
        this.filters.fechaInicio,
        this.filters.fechaCierre,
        this.filters.oficinaVentas.selected,
        selected,
        this.filters.codigo
      );
    },
    filtersToSend(
      fechaInicio,
      fechaCierre,
      selectedOficina,
      seletedDex,
      idCodigo
    ) {
      const codigoOficinaVenta =
        selectedOficina === OFICINAS.LIMA
          ? OFICINAS_CODES.LIMA
          : OFICINAS_CODES.PROV;
      const idDistribuidora = seletedDex === -1 ? 0 : seletedDex;

      const oficina =
        selectedOficina === OFICINAS.ALL ? null : codigoOficinaVenta.toString();

      const idIncidencia = !!idCodigo ? Number(idCodigo) : 0;

      const filters = {
        page: this.currentPage,
        oficina,
        idDistribuidora,
        idIncidencia,
        fechaInicio: !!fechaInicio ? fechaInicio : null,
        fechaFin: !!fechaCierre ? fechaCierre : null,
      };

      return filters;
    },
    async fetchList(inicio, cierre, oficina, dexSelected, codigo) {
      try {
        const token = this.$store.state.auth.token;
        this.loading = true;

        const response = await Incidencias.listByFilters(
          token,
          this.filtersToSend(inicio, cierre, oficina, dexSelected, codigo)
        );
        const { data: incidencias, page, totalPage } = response;

        this.parseIncidencias(incidencias);
        this.currentPage = page;
        this.totalPages = totalPage;
      } catch (error) {
        this.toast("Error al obtener la información", "error");
      } finally {
        this.loading = false;
      }
    },
    parseIncidencias(incidencias) {
      if (Array.isArray(incidencias)) {
        const parseds = incidencias.map(({ fecha, distribuidora, ...rest }) => {
          let fechaReport = "--";
          if (fecha) {
            const date = parseNormalDate(fecha.split(" ")[0]);
            fechaReport = date;
          }

          return {
            ...rest,
            dex: distribuidora,
            fecha: fechaReport,
            codigo: String(rest.idIncidencia).padStart(6, "0"),
            dias: rest.diasTranscurridos ?? "--",
          };
        });
        this.tabla.rows = parseds;
      } else {
        this.tabla.rows = [];
      }
    },
    changePage(page) {
      this.currentPage = page;

      this.fetchList(
        this.filters.fechaInicio,
        this.filters.fechaCierre,
        this.filters.oficinaVentas.selected,
        this.filters.dexs.selected,
        this.filters.codigo
      );
    },
    resetPagination() {
      this.currentPage = 1;
      this.totalPages = 0;
    },
    exportList() {
      const token = this.$store.state.auth.token;
      if (!token) return;
      const filtersData = this.filtersToSend(
        this.filters.fechaInicio ? this.filters.fechaInicio : null,
        this.filters.fechaCierre ? this.filters.fechaCierre : null,
        this.filters.oficinaVentas.selected
          ? this.filters.oficinaVentas.selected
          : null,
        this.filters.dexs.selected ? this.filters.dexs.selected : null,
        this.filters.codigo ? this.filters.codigo : null
      );

      Incidencias.downloadList(token, filtersData)
        .then(() => {
          this.toast("Se descargo la lista");
        })
        .catch(() => this.toast("Error al descargar la información", "error"));
    },
    toast(title, type = "success") {
      const toast = useToast();
      if (type == "success") {
        toast.success(title, {
          position: "top-right",
          closeOnClick: true,
          hideProgressBar: true,
          timeout: 6000,
          toastClassName: "th-alert th-alert-pry",
          showCloseButtonOnHover: true,
        });
      } else if (type == "error") {
        toast.error(title, {
          position: "top-right",
          closeOnClick: true,
          hideProgressBar: true,
          timeout: 6000,
          toastClassName: "th-alert th-alert-error",
          showCloseButtonOnHover: true,
        });
      }
    },
    async handleUpload(event) {
      try {
        if (this.loading) return;

        this.loading = true;

        const files = event.target.files;
        if (files.length === 0) {
          this.toast("Seleccione un archivo", "error");
          return;
        }

        this.file = files[0];

        const typeFile = this.file.name.split(".").pop();

        if (!["xlsx", "xls"].includes(typeFile)) {
          this.toast("Solo se admite archivos de tipo: xlx o xlsx", "error");
          return;
        }

        const formData = new FormData();
        formData.append("file", this.file);

        await Incidencias.uploadBulk(formData);
        this.toast("Plantilla cargada", "success");
        this.fetchList(
          this.filters.fechaInicio,
          this.filters.fechaCierre,
          this.filters.oficinaVentas.selected,
          this.filters.dexs.selected,
          this.filters.codigo
        );
      } catch (error) {
        const message = error.response.data.message;
        if (!!message) {
          this.toast(message[0], "error");
        } else {
          this.toast("Hubo un problema al subir el archivo excel", "error");
        }
      } finally {
        this.loading = false;
        event.target.value = null;
        this.file = null;
      }
    },
  },
  watch: {
    "filters.oficinaVentas.selected"(selectedOf) {
      let dexs = [...this.filters.totalDexs];
      if (selectedOf == OFICINAS.PROV) {
        dexs = this.filters.totalDexs.filter(({ id }) => id > 6);
      } else if (selectedOf === OFICINAS.LIMA) {
        dexs = this.filters.totalDexs.filter(({ id }) => id <= 6);
      }

      dexs.push({ texto: "Todas", valor: -1 });
      dexs.unshift(dexs.pop());

      this.filters.dexs.options = dexs;
      this.filters.dexs.selected = -1;

      this.resetPagination();

      // Fetch all
      const { fechaInicio, fechaCierre, dexs: dx, codigo } = this.filters;
      this.fetchList(fechaInicio, fechaCierre, selectedOf, dx.selected, codigo);
    },
    "filters.codigo"(codigo) {
      const {
        fechaInicio,
        fechaCierre,
        dexs: dx,
        oficinaVentas,
      } = this.filters;
      if (isNaN(Number(codigo))) {
        this.tabla.rows = [];
        return;
      }
      this.performSearch(
        fechaInicio,
        fechaCierre,
        oficinaVentas.selected,
        dx.selected,
        codigo
      );
    },
    "filters.fechaInicio"(fechaInicio) {
      this.resetPagination();
      const { fechaCierre, dexs: dx, oficinaVentas, codigo } = this.filters;
      this.fetchList(
        fechaInicio,
        fechaCierre,
        oficinaVentas.selected,
        dx.selected,
        codigo
      );
    },
    "filters.fechaCierre"(fechaCierre) {
      this.resetPagination();
      const { fechaInicio, dexs: dx, oficinaVentas, codigo } = this.filters;
      this.fetchList(
        fechaInicio,
        fechaCierre,
        oficinaVentas.selected,
        dx.selected,
        codigo
      );
    },
  },
};
</script>

<style scoped>
.card-table-container :deep(.card-table) {
  margin-top: -3.5em;
}

@media screen and (max-width: 760px) {
  .card-table-container {
    margin-top: 4.5em;
  }
}

.filter {
  display: flex;
  flex-direction: column;
}

.filter :deep(.date-picker) {
  flex: 1;
  height: 100%;
}

.filter :deep(input) {
  font-size: 0.75rem;
  font-weight: 300;
}

.b-table {
  --columns: 1.3fr 1.3fr 1.3fr 1.2fr 1.4fr 1.3fr 1.6fr 1.3fr 1fr 1.8fr;
  --columns-sm: 0.8fr 0.8fr 1.2fr 1fr 0.8fr 1.1fr 1.4fr 1.4fr 1.2fr 1fr;
}

.see-details-btn {
  text-transform: none;
  font-weight: 500;
}

.card-table-container :deep(.error-message) {
  display: none;
}

.card-table-container :deep(.card-table-filters) {
  display: grid;
  grid-template-columns: 1fr 1fr 1.4fr 1fr 1fr;
}
</style>
