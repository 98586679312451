export const formatearCantidades = (param) => {
  const cantidad = parseFloat(param);
  return cantidad.toLocaleString("en-US");
};

export const calcularCostoTotal = (data) => {
  return data.reduce(
    (acumulador, objeto) =>
      acumulador +
      parseFloat(objeto.costo_total_ajustado ?? objeto.costo_total ?? 0),
    0
  );
};
