import { request } from "../util/request";

const uploadProductosImperdonables = async (body) => {
  const { data } = await request.post("/v2/Product/LoadNewFocusProducts", body);
  return data;
};

export const serviceMaestroCarga = {
  uploadProductosImperdonables,
};
