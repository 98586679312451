export default [
  {
    img: "img/theme/bootstrap.jpg",
    title: "Vendedor 01",
    budget: "2500 PEN",
    status: "7",
    statusType: "warning",
    completion: 60,
  },
  {
    img: "img/theme/angular.jpg",
    title: "Vendedor 02",
    budget: "2500 PEN",
    status: "Meta del día cumplida",
    statusType: "success",
    completion: 100,
  },
  {
    img: "img/theme/sketch.jpg",
    title: "Vendedor 03",
    budget: "2500 PEN",
    status: "14",
    statusType: "danger",
    completion: 72,
  },
  // {
  //   img: "img/theme/react.jpg",
  //   title: "React Material Dashboard",
  //   budget: "2500 PEN",
  //   status: "on schedule",
  //   statusType: "info",
  //   completion: 90,
  // },
  {
    img: "img/theme/vue.jpg",
    title: "Vendedor 04",
    budget: "2500 PEN",
    status: "Meta del día cumplida",
    statusType: "success",
    completion: 100,
  },
  {
    img: "img/theme/bootstrap.jpg",
    title: "Vendedor 01",
    budget: "2500 PEN",
    status: "7",
    statusType: "warning",
    completion: 60,
  },
  {
    img: "img/theme/angular.jpg",
    title: "Vendedor 02",
    budget: "2500 PEN",
    status: "Meta del día cumplida",
    statusType: "success",
    completion: 100,
  },
  {
    img: "img/theme/sketch.jpg",
    title: "Vendedor 03",
    budget: "2500 PEN",
    status: "14",
    statusType: "danger",
    completion: 72,
  },
  {
    img: "img/theme/vue.jpg",
    title: "Vendedor 04",
    budget: "2500 PEN",
    status: "Meta del día cumplida",
    statusType: "success",
    completion: 100,
  },
];
